import { Autocomplete, Box, Button, Checkbox, Drawer, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import CommonGridHeader, { CommonGridToolBarWithFilterText, CommonPatientDetails, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { formatDate, timeOnlyConvert } from '../../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../../Components/Loader';

class TATReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            appointmentType: [],
            dateIndex: 1,
            appointmentPatientData: [],
            TatHours: 0,
            TotalAppiontments: 0,
            appointmentTypeList: [],
            Loader: false,
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            AppointmentStatus: 'all',
            FilterDatas: {
                "startTime":null,
                "endTime":null,
                "onError":false,
            },
            timeError: false,
            printLoading: false,
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getAppointmentPatientdata()
        this.getAppointmentTypeList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, ()=> {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas:this.state.FilterDatas
            })
        })
    }

    getAppointmentPatientdata = () => {
        try {
            this.LoaderFunction(true)
            let appoinmentType = [];
            this.state.appointmentType.forEach(element => appoinmentType.push(element.appoinment_type))
            this.setState({ Loader: true })
            var {FilterDatas} = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.getAll(Serviceurls.FO_REPORTS_TAT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&appointment_type=${appoinmentType}&appointment_status=${this.state.AppointmentStatus}&start_time=${setStartTime}&end_time=${setEndTime}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            appointmentPatientData: response.data.data,
                            TatHours: response.data?.total_hours ? response.data?.total_hours : 0,
                            TotalAppiontments: response.data?.total_appointment_count ? response.data?.total_appointment_count : 0,
                            Loader: false,
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate,
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.setState({ Loader: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ Loader: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getAppointmentPatientdataPrint = () => {
        try {
            let appoinmentType = [];
            this.state.appointmentType.forEach(element => appoinmentType.push(element.appoinment_type))
            var {FilterDatas} = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            this.setState({disableBtn: true, printLoading: true})
            RestAPIService.getAll(Serviceurls.FO_REPORTS_TAT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&appointment_type=${appoinmentType}&appointment_status=${this.state.AppointmentStatus}&export_type=pdf&start_time=${setStartTime}&end_time=${setEndTime}`)
                .then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                        this.setState({disableBtn: false, printLoading: false})
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({disableBtn: false, printLoading: false})
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({disableBtn: false, printLoading: false})
        }
    }


    getAppointmentTypeList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPAPPOINTMENT)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            appointmentTypeList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    selectedTime = (start, end,error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
    }
    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button  emed_tid = {"LabCloseButton_testID"} onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            emed_tid = {"FilterDate_testID"} 
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box sx={{width: '22vw'}}>
                    <CommonTimeRangePicker
                        startTime={this.state.FilterDatas?.startTime}
                        endTime={this.state.FilterDatas?.endTime}
                        selectedTime={this.selectedTime.bind(this)}
                        emed_tid = {"CommonTimeRange_testID"} 
                        FromDate={this.state.fromDate}
                        ToDate={this.state.toDate}
                        onError={this.state.FilterDatas.onError}
                    />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.appointmentTypeList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.appoinment_type)}
                            value={this.state.appointmentType}
                            onChange={(event, newValue) => {
                                this.setState({ appointmentType: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'appointment_type' }}
                                    label={t('selectAppointType')}
                                    emed_tid = {"selectAppointType_testID"} 
                                />
                            )}
                        />
                    </Box>
                    <Box className="eMed_Filter_Header">
                        <Typography marginLeft={"1vw"}>{t("Appointment Status")}</Typography>
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <FormControl sx={{ marginLeft: "1vw" }}>
                            <RadioGroup
                                value={this.state.AppointmentStatus}
                                sx={{ display: 'flex', flexDirection: 'row' }}
                                onChange={(e) => { this.setState({ AppointmentStatus: e.target.value }) }}
                            >
                                <FormControlLabel value="all" control={<Radio size='small'inputProps={{ emed_tid: 'radio_all' }} />} label="All" />
                                <FormControlLabel value="completed" control={<Radio size='small' inputProps={{ emed_tid: 'radio_completed' }} />} label="Completed" />
                                <FormControlLabel value="others" control={<Radio size='small' inputProps={{ emed_tid: 'radio_others' }} />} label="Others" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small" emed_tid = {"getAppointmentPatientdata_testID"} 
                        onClick={() => {
                            this.setState({
                                AppointmentStatus: 'all',
                                appointmentType: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime":null,
                                    "endTime":null,
                                    "onError": false
                                },
                                timeError: false,
                            }, () => this.getAppointmentPatientdata())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid = {"getAppointmentPatientdata_testID001"} 
                    disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getAppointmentPatientdata()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
            { label: "To Date", value: formatDate(this.state.FilteredToDate) },
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    render() {
        this.state.appointmentPatientData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "patient_name", headerName: t("PatientDetails"), flex: 0.17,
                renderCell: (params) => (<Box component={'div'}>
                    <CommonPatientDetails disablePhoto={true} data={params?.row} />
                </Box>)
            },
            {
                field: "appoinment_types", headerName: t("AppointmentType"), flex: 0.17, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.appoinment_types ? params?.row?.appoinment_types : '-'}</Box>)
            },
            {
                field: "appointment_date_time", headerName: t("AppointmentDate&Time"), flex: 0.17, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.appointment_date_time ? params?.row?.appointment_date_time : '-'}</Box>)
            },
            {
                field: "patient_arrived_time", headerName: t("ArrivedTime"), flex: 0.15, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_arrived_time ? params?.row?.patient_arrived_time?.length > 20 ?
                    <div><Tooltip placement='top' title={params?.row?.patient_arrived_time} arrow><div>{params?.row?.patient_arrived_time.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.patient_arrived_time : "-"}</Box>)
            },
            {
                field: "patient_completed_time", headerName: t("CompletedTime"), flex: 0.15, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_completed_time ? params?.row?.patient_completed_time?.length > 20 ?
                    <div><Tooltip placement='top' title={params?.row?.patient_completed_time} arrow><div>{params?.row?.patient_completed_time.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.patient_completed_time : "-"}</Box>)
            },
            {
                field: "turn_around_time", headerName: t("TurnAroundTime"), flex: 0.15, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.turn_around_time ? params?.row?.turn_around_time?.length > 20 ?
                    <div><Tooltip placement='top' title={params?.row?.turn_around_time} arrow><div>{params?.row?.turn_around_time.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.turn_around_time : "-"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("Avg TAT"), `${this.state.TatHours}`, true)}
                        {AmountsCard(t("Total Appointments"), `${this.state.TotalAppiontments}`, true)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' emed_tid = {"eMed_action_img_testID"} onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' emed_tid = {"LabPrintIcon_testID"}  disabled={this.state.disableBtn} onClick={() => { this.getAppointmentPatientdataPrint() }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        {/* <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Typography fontSize={"0.7vw"} marginTop={"-0.3vw"} marginLeft={"2vw"}>TAT : Turn Around Time</Typography>
                <Box component={'div'} className='eMed_rts_table' sx={{ height: "64vh" }}>
                    <DataGrid
                        rows={this.state.appointmentPatientData}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.Loader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.printLoading} forPrint={true} label={"Building PDF..."} />
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}


export default withTranslation()(TATReport)