import { Box, Button, Grid, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup } from '../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import '../Configuration.css';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
class ReferralDoctor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            doctorName: "",
            hospitalName: "",
            specialization: "",
            mobileNumber: "",
            regNumber: "",
            qualification: "",
            refDoctorData: [],
            deletePopup: false,
            rowValue: null,
            selectedId: null,
            commission: ""
        }
    }

    componentDidMount() {
        this.getReferralDctorData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    inputTextBox(label, value, name, fullWidth, rows, widths) {
        const { t } = this.props
        return (
            <TextField
                size='small'
                multiline
                rows={rows}
                fullWidth={fullWidth}
                label={label === "Commission" ? `${t(label)} (%)` : `${t(label)} *`}
                inputProps={{ emed_tid: `cb_${label}` }}
                name={name}
                value={value}
                onChange={(e) => this.onChangeHandler(e)}
                className="eMed_refdoc_txtbox"
                sx={{ width: widths, margin: "0.3vw" }}
            />
        )
    }

    onChangeHandler = (event) => {
        try {
            const { name, value } = event.target
            let alpha = CommonValidation.alphabetOnly(value);
            let number = CommonValidation.numberOnly(value);
            let regNum = CommonValidation.alphanumValidation(value);
            switch (name) {
                case "doctorName":
                    if (alpha || value === "") {
                        this.setState({ doctorName: value })
                    }
                    break;
                case "qualification":
                    if (alpha || value === "") {
                        this.setState({ qualification: value })
                    }
                    break;
                case "specialization":
                    if (alpha || value === "") {
                        this.setState({ specialization: value })
                    }
                    break;
                case "hospitalName":
                    if (alpha || value === "") {
                        this.setState({ hospitalName: value })
                    }
                    break;
                case "regNumber":
                    if (regNum && value.length <= 30 || value === "") {
                        this.setState({ regNumber: value })
                    }
                    break;
                case "mobileNumber":
                    if ((number && value.length <= 10) || value === "") {
                        this.setState({ mobileNumber: value })
                    }
                    break;
                case "commission":
                    if (value <= 100) {
                        this.setState({ commission: value })
                    }
                    break;
                default:
                    this.setState({ [name]: value })
                    break
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    onEditHandler = (value) => {
        const { t } = this.props
        try {
            this.setState({
                isEdit: true,
                doctorName: value?.row?.name,
                mobileNumber: value?.row?.mobile_no,
                regNumber: value?.row?.reg_no,
                qualification: value?.row?.qualification,
                specialization: value?.row?.specialization,
                hospitalName: value?.row?.clinic_name,
                selectedId: value?.row?.id,
                commission: value?.row?.commission_percentage
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (value) => {
        if (!this.state.isEdit) {
            this.setState({
                deletePopup: true,
                rowValue: value?.row,
                delSelectedId: value?.row?.id
            })
        }
    }

    popupClose = () => {
        this.setState({ deletePopup: false })
    }

    onClearHandler = () => {
        this.setState({
            doctorName: "", qualification: "",
            hospitalName: "", specialization: "",
            regNumber: "", mobileNumber: "", commission: ""
        })
    }

    getReferralDctorData = () => {
        const { t } = this.props
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_REF_DOCTOR).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            refDoctorData: response.data.data
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    postReferralDoctorData = () => {
        const { t } = this.props
        try {
            let data = {
                "name": CommonValidation.removeSpace(this.state.doctorName),
                "mobile_no": this.state.mobileNumber,
                "reg_no": this.state.regNumber,
                "qualification": CommonValidation.removeSpace(this.state.qualification),
                "specialization": CommonValidation.removeSpace(this.state.specialization),
                "clinic_name": CommonValidation.removeSpace(this.state.hospitalName),
                "commission_percentage": this.state.commission ? +this.state.commission : null
            }
            if (this.state.isEdit) {
                data['id'] = this.state.selectedId
            }

            if (data.name === "" || data.mobile_no === "" || data.reg_no === "" ||
                data.qualification === "" || data.specialization === "" || data.clinic_name === "") {
                this.errorMessage(t("ManitoryFields"))
            } else if (data.mobile_no.length < 10) { this.errorMessage(t("EnterMobile")) }
            else {
                this.LoaderFunction(true)
                RestAPIService.create(data, Serviceurls.FO_CONFIG_REF_DOCTOR).
                    then((response) => {
                        if (response.data.status === 'success') {
                            this.onClearHandler()
                            this.getReferralDctorData()
                            this.setState({ isEdit: false })
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    deleteReferrralDoctorData = () => {
        const { t } = this.props
        try {
            RestAPIService.delete(Serviceurls.FO_CONFIG_REF_DOCTOR + "?id=" + this.state.delSelectedId).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.getReferralDctorData()
                        this.setState({ deletePopup: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderRefDoctorLeft = () => {
        this.state.refDoctorData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            { field: "sno", sortable: false, flex: 0.05, headerName: t("SNo") },
            {
                field: "name", flex: 0.111, headerName: t("DoctorName"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.name ? params?.row?.name?.length > 10 ?
                    <Tooltip placement="top" title={params?.row?.name} arrow><div>{params?.row?.name.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.name : "-"}</Box>)
            },
            {
                field: "mobile_no", flex: 0.141, headerName: t("MobileNumber"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.mobile_no ? params?.row?.mobile_no : "-"}</Box>)
            },
            {
                field: "reg_no", flex: 0.111, headerName: t("RegistrationNumber"), type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.reg_no ? params?.row?.reg_no : "-"}</Box>)
            },
            {
                field: "qualification", flex: 0.111, headerName: t("Qualification"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.qualification ? params?.row?.qualification : "-"}</Box>)
            },
            {
                field: "specialization", flex: 0.111, headerName: t("Specialization"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.specialization ? params?.row?.specialization : "-"}</Box>)
            },
            {
                field: "clinic_name", flex: 0.111, headerName: t("Hospital/ClinicName"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.clinic_name ? params?.row?.clinic_name : "-"}</Box>)
            },
            {
                field: "commission_percentage", flex: 0.111, headerName: t("Commission"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.commission_percentage ? params?.row?.commission_percentage :
                    params?.row?.commission_percentage === 0 ? "0" : "-"}</Box>)
            },
            {
                field: "action", flex: 0.142, headerName: t("Action"), headerAlign: 'center', align: 'center', sortable: false, hideable: false,
                renderCell: (params) => <div>
                    <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "Referral Configuration",  "Referral Doctor", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onEditHandler.bind(this, params)} />
                    <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings", "Referral Configuration",  "Referral Doctor", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onDeleteHandler.bind(this, params)} />
                </div>
            }
        ]
        return (
            <Stack component={"div"}>
                <Paper className='eMed_refdoc_left' >
                    <DataGrid
                        rows={this.state.refDoctorData}
                        columns={columns}
                        getRowId={(row) => row['id']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    clinic_name: false
                                }
                            }
                        }}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Paper>
            </Stack>
        )
    }

    renderRefDoctorRight = () => {
        const { t } = this.props
        return (
            <Stack component={"div"}>
                <Paper className='eMed_refdoc_right' elevation={1}>
                    <Typography className='eMed_refdoc_title'><strong>{t("refDoctor")}</strong></Typography>
                    <Stack height={"60vh"}>
                        <Grid container className='eMed_refdoc_txt_card'>
                            <Grid item xs={12}>
                                {this.inputTextBox("DoctorName", this.state.doctorName, "doctorName", false, 1, "27vw")}
                            </Grid>
                            <Grid item xs={6}>
                                {this.inputTextBox("MobileNumber", this.state.mobileNumber, "mobileNumber", false, 1)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.inputTextBox("RegistrationNumber", this.state.regNumber, "regNumber", false, 1)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.inputTextBox("Qualification", this.state.qualification, "qualification", false, 1)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.inputTextBox("Specialization", this.state.specialization, "specialization", false, 1)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.inputTextBox("Hospital/ClinicName", this.state.hospitalName, "hospitalName", false, 1)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.inputTextBox("Commission", this.state.commission, "commission", false, 1)}
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack spacing={2} direction="row" className='eMed_ref_btn' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                        <Button emed_tid ={"onClearHandler_testID001"} variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.onClearHandler() }}>{t("Clear")}</Button>
                        <Button emed_tid ={"onAddHandler_testID001"} variant='contained' size="small" id="eMed_Config_btn"
                        disabled={!CheckAccessFunc("front_office", "Settings", "Referral Configuration",  "Referral Doctor", "SubTab")?.editAccess}
                            onClick={() => { this.postReferralDoctorData() }}>{this.state.isEdit ? t("Update") : t("Add")}</Button>
                    </Stack>
                </Paper>
            </Stack>
        )
    }
    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: ''
        })
      }
    renderMessageFields = () => {
        const { t } = this.props
        return (
            <>
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`${t("deleteMsg")}  ${this.state.rowValue?.name ? this.state.rowValue?.name : "-"} ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deleteReferrralDoctorData.bind(this)}
                    DeleteNotify={t("effectEntireConfig")}
                /> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </>
        )
    }

    render() {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_refdoc_container'>
                <Grid container spacing={2} className='eMed_refdoc_content' >
                    <Grid item xs={8}>
                        {this.renderRefDoctorLeft()}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderRefDoctorRight()}
                    </Grid>
                </Grid>
                {this.renderMessageFields()}
            </Box>
        )
    }
}

export default withTranslation()(ReferralDoctor)