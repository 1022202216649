import React, { Component } from 'react';
import {
    Paper, Table, TableBody, TableCell, TableContainer, Typography, TableHead, TableRow, TablePagination, IconButton, Stack,
    InputLabel, FormHelperText, Tooltip, Box, Collapse, InputAdornment, OutlinedInput, Checkbox, ListItemText,
    Stepper,
    Step,
    StepLabel, Drawer
} from '@mui/material'
import './home.css'
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Autocomplete from '@mui/material/Autocomplete';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import { Trans, withTranslation } from 'react-i18next';
import { t } from 'i18next';
import CommonGridHeader, { BillSuccessPoPUp, CommonDeleteButton } from "../../../Components/Common Components/CommonComponents";
import CreditBillPopUp from '../../FrontOffice/BillingTransaction/CreditBillPopUp';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../Styles/Colors';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import SearchIcon from '@mui/icons-material/Search';
import EditHistoryPopup from '../../FrontOffice/BillingTransaction/EditHistoryPopup';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import CreditBillCompletePoP from '../../FrontOffice/BillingTransaction/CreditBillCompletePoP';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Loader from '../../../Components/Loader';

const ITEM_HEIGHT = 48;
const specimanList = [
    { "service_name": "calcium", "lab_type": "Self" },
    { "service_name": "glucose", "lab_type": "Self" },
    { "service_name": "sodium", "lab_type": "Self" },
    { "service_name": "pottassium", "lab_type": "Self" },
    { "service_name": "chloride", "lab_type": "Self" },
    { "service_name": "blood urea nitrogen(BUN)", "lab_type": "Self" },
    { "service_name": "creatinine", "lab_type": "Self" }
]
const multi_option = [
    { "label": "ViewPreviousReport", "value": "View Previous Report" },
    { "label": "ReceivePayment", "value": "Receive Payment" },
    { "label": "BarCode", "value": "Common Barcode" },
    { "label": "Cancel Bill", "value": "Cancel Bill" },
    { "label": "Delete Bill", "value": "Delete Bill" }

]


//New Sample 
const collect_sample = [
    {
        "sample_type": null,
        "is_outsource": false,
        "outsource_list": [{ out_source__outsource_laboratory_name: 'self', out_source_id: null }],
        "collected_date": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd HH:mm:ss'),
        "test_collected": [],
        "test_ids": [],
        "outsource_data": {out_source__outsource_laboratory_name: 'self', out_source_id: null}
    }
]

const Steps = ['Sample Collection', 'Consumables']

const Consumables = [
    { label: 'Cotton', value: 'Cotton' },
    { label: 'Tube', value: 'Tube' },
    { label: 'Glows', value: 'Glows' },
]

const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;
class Status extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            statusSummaryList: [],
            isSpecimanClick: false,
            specimanList: specimanList,
            consumablesName: "",
            consumableValue: "",
            consumableUnit: "",
            consumableList: [],
            sampleCollectedIn: [],
            isFilterOption: false,
            selectedIndex: "",
            isMultiOPtion: false,
            selectedOption: "",
            anchorEl: null,
            billSummaryID: "",
            reason: "",
            isErrorMsg: false,
            isErrorMsgText: "",
            pageSize: 10,
            selectedTableRow: {},
            outSourceOptionList: [],
            isViewReport: false,
            specimanSuggessionList: [],
            selectConsumables: null,
            outSourceList: [],
            isErrorMsg: false,
            isErrorMsgText: "",
            outSourceList: [],
            preReportList: [],
            search_key: "",
            patientName: "",
            mobileNumber: "",
            QueryFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            QueryToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateFilter: 1,
            isSpecimanComplete: false,
            isSelected: false,
            isCancelBill: false,
            CreditBillPopUp: false,
            amountData: {},
            disableBtn: false,

            //New Table
            tableSelectedIndex: null,
            tblPage: 0,
            tblPageSize: 10,
            TableSearchText: "",
            resetSourceOptionList: [],
            BillModifyHistoryItem: {},
            newSampleCollected: collect_sample,
            DateTimerOpen: false,
            sampleType_Data: [],
            outSourceOptionListNew: [],
            outSourceData: [],
            StepNumber: 0,
            consumablesType: Consumables,
            consumableValue: null,
            isNotPost: false,
            isSample: false,
            isLoader: false,
            newSampleCollDuplicate: [],

            // filter 
            FilterOpen: false,
            departmentData: [],
            groupData: [],
            testNameData: [],
            departmentName: null,
            groupName: null,
            testName: null,
            patientSearch: ""
        }
    }
    componentDidMount() {
        this.getStatusSummaryList()
        this.getStatusSpecimanList()
        this.getSampleTypeData()
        this.getDeptList()
        this.getGroupNameList()
        this.getTestNameList()
    }
    componentDidUpdate(PrevProps) {
        if (PrevProps.search_key != this.props.search_key) {
            var { search_key } = this.props
            this.setState({
                search_key: search_key ? search_key : ""
            }, () => {
                var data = parseInt(this.state.search_key)
                let type = typeof (data ? data : "")

                if (type == "number") {
                    this.setState({
                        mobileNumber: this.state.search_key ? this.state.search_key : "",
                        patientName: ""
                    }, () => {
                        this.getStatusSummaryList()
                    })
                }
                else {
                    this.setState({
                        patientName: this.state.search_key ? this.state.search_key : "",
                        mobileNumber: ""
                    }, () => {
                        this.getStatusSummaryList()
                    })
                }
            })
        }
    }

    getSampleTypeData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_SAMPLE_TYPE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            sampleType_Data: response?.data?.data ? response?.data?.data : []
                        })
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: error?.response?.data?.message
                        })
                    } else {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: error?.message
                        })
                    }
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    getLabSampleCollect = (id) => {
        try {
            this.LoaderFunction(true)
            let defaultData = [
                {
                    "sample_type": null,
                    "is_outsource": false,
                    "outsource_list": [{ out_source__outsource_laboratory_name: 'self', out_source_id: null }],
                    "collected_date": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd HH:mm:ss'),
                    "test_collected": [],
                    "test_ids": [],
                    "outsource_data": {out_source__outsource_laboratory_name: 'self', out_source_id: null}
                }
            ]
            RestAPIService.getAll(Serviceurls.LAB_SAMPLE_GET + "?result_summary_id=" + id)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.setState({
                            isSpecimanClick: true,
                            newSampleCollected: response?.data?.data.length > 0 ? response?.data?.data : defaultData,
                            newSampleCollDuplicate: response?.data?.data.length > 0 ? response?.data?.data : [],
                            consumableList: response?.data?.consumables ? response?.data?.consumables : [],
                        }, () => {
                            let modifiedConsumables = response?.data?.consumables.length > 0 ? response?.data?.consumables.map(list => {
                                return { ...list, ismaped: true };
                            }) : [];
                            let modifiedextraConsumables = response?.data?.extra_consumables.length > 0 ? response?.data?.extra_consumables.map(list => {
                                return { ...list, isextramaped: true };
                            }) : [];
                            this.setState({
                                sampleCollectedIn: [...modifiedConsumables, ...modifiedextraConsumables],
                            })
                        })
                    }
                }).catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: error?.response?.data?.message
                        })
                    } else {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: error?.message
                        })
                    }
                })
        } catch (e) {
            this.LoaderFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    getReceivePaymentAmountData = (cellvalues) => {
        try {
            let params = ""
            let ipAdmissionId = cellvalues?.ip_admission_id
            let op_appointment_id = cellvalues?.appointment_id
            let lab_bill_summary_id = cellvalues?.lab_bill_summary_id

            if ((ipAdmissionId !== null && lab_bill_summary_id !== null)) {
                params = "?ip_admission_id=" + ipAdmissionId
            } else if ((ipAdmissionId === null && op_appointment_id !== null && lab_bill_summary_id !== null)) {
                params = "?appointment_id=" + op_appointment_id
            } else if ((ipAdmissionId === null && op_appointment_id === null && lab_bill_summary_id !== null)) {
                params = "?lab_bill_summary=" + lab_bill_summary_id
            }

            RestAPIService.getAll(Serviceurls.LAB_STATUS_AND_TEST_REPORT_AMOUNT_GET + params).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            amountData: response.data.data
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error?.response?.data?.message
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    getCommonBarCodePrint = (params) => {
        try {
            let admissionId = this.state.SelectedBill?.ip_admission_id ? this.state.SelectedBill?.ip_admission_id : ''
            let appointmentId = this.state.SelectedBill?.appointment_id ? this.state.SelectedBill?.appointment_id : ''
            RestAPIService.getAll(Serviceurls.COMMON_BARCODE_PRINT + "?patient_id=" + this.state.SelectedBill?.patient_id + "&appointment_id=" + appointmentId + "&admission_id=" + admissionId)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    PrintBarcode = (ids) => {
        try {
            var details = this.state.selectedTableRow
            let PrintID = ids.map(item => item.id)
            this.setState({ disableBtn: true })
            RestAPIService.getAll(Serviceurls.SPECIMEN_BARCODE_PRINT + `?patient_id=${details.patient_id}&result_summary_id=${details.id}&result_item_id=${PrintID}`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: error.response.data.message,
                            disableBtn: false,
                        })
                    } else {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: error.message,
                            disableBtn: false,
                        })
                    }

                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("PrintError"),
                disableBtn: false,
            })
        }
    }

    getDeptList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_DEPT_LIST + "?dept_name=")
                .then((response) => {
                    if (response.data.status === 'success' && response?.data?.data?.length > 0) {
                        this.setState({
                            departmentData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getGroupNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET)
                .then((response) => {
                    if (response.data.status === 'success' && response?.data?.data?.length > 0) {
                        this.setState({
                            groupData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getTestNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET+ "?only_test_name=true")
                .then((response) => {
                    if (response.data.status === 'success' && response?.data?.data?.length > 0) {
                        this.setState({
                            testNameData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    removeDuplicate(data) {
        let nameListJsonObject = data.map(JSON.stringify);
        let nameListUniqueSet = new Set(nameListJsonObject);
        let nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    msgClose(){
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            isSuccessMsg: false,
            isSuccessMsgText: "",
        })
    }
    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            CreditBillPopUp: false,
            anchorEl: false
        })
    }

    CustomDates = (FromDate, ToDate) => {
        this.setState({
            QueryFromDate: FromDate,
            QueryToDate: ToDate,
            datePickerPop: false
        }, () => this.getStatusSummaryList())
    }
    PopClose = () => {
        this.setState({
            datePickerPop: false,
            QueryFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            QueryToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateFilter: 1,
        }, () => this.getStatusSummaryList())
    }
    DateHelperText = () => {
        let fromDate = this.dateConvertion(this.state.QueryFromDate)
        let toDate = this.dateConvertion(this.state.QueryToDate)
        if (this.state.dateFilter === 0) {
            return ""
        }
        else if (this.state.dateFilter === 1) {
            return DateTime.now().toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 2) {
            return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 3) {
            // return `${fromDate}  to  ${toDate}`
            const startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week');
            const endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week');
            return `${startOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')} to ${endOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')}`

        }
        else if (this.state.dateFilter === 4) {
            // return `${fromDate}  to  ${toDate}`
            const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month');
            const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month');
            return `${startOfPreviousMonth.toFormat('dd-MM-yyyy')} to ${endOfPreviousMonth.toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.dateFilter === 5) {
            return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
        }
    }

    handleDate = () => {
        var today = DateTime.now().toFormat('yyyy-MM-dd')
        var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
        // var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
        // var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')
        var startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
        var endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
        const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month').toFormat('yyyy-MM-dd');
        const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month').toFormat('yyyy-MM-dd');
    


        if (this.state.dateFilter === 0) {
            this.setState({
                QueryFromDate: "",
                QueryToDate: "",
            }, () => this.getStatusSummaryList())
        }
        else if (this.state.dateFilter === 1) {
            this.setState({
                QueryFromDate: today,
                QueryToDate: today,
            }, () => this.getStatusSummaryList()
            )

        }
        else if (this.state.dateFilter === 2) {
            this.setState({
                QueryFromDate: yesterday,
                QueryToDate: yesterday,
            }, () => this.getStatusSummaryList())
        }
        else if (this.state.dateFilter === 3) {
            this.setState({
                QueryFromDate: startOfPreviousWeek,
                QueryToDate: endOfPreviousWeek,
            }, () => this.getStatusSummaryList())
        }
        else if (this.state.dateFilter === 4) {
            this.setState({
                QueryFromDate: startOfPreviousMonth,
                QueryToDate: endOfPreviousMonth,
            }, () => this.getStatusSummaryList())
        }
        else if (this.state.dateFilter === 5) {
            this.setState({ datePickerPop: true })
        }
    }

    getSpecimanCollectedValue = (id) => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.LAB_PATENT_SPECIMAN_COLLECTED + "?result_summary_id=" + id)
            .then(response => {
                if (response.data.status == "success") {
                    this.LoaderFunction(false)
                    var data = response.data.data
                    this.setState({
                        outSourceOptionList: data.out_source,
                        outSourceOptionListNew: data.out_source_new,
                        consumableList: data.consumables,
                        isSample: data?.is_all_sample_collected
                    }, () => {
                        // let modifiedConsumables = data.consumables.map(list => {
                        //     return { ...list, ismaped: true };
                        // });

                        this.state.outSourceOptionList && this.state.outSourceOptionList.forEach(data => { data.out_source_id = "" })
                        this.setState({
                            resetSourceOptionList: JSON.stringify(this.state.outSourceOptionList),
                            // sampleCollectedIn: [...this.state.sampleCollectedIn, ...modifiedConsumables],
                        })
                    })
                } else {
                    this.LoaderFunction(false)
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message
                    })
                }
            }).catch((error) => {
                this.LoaderFunction(false)
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
        }
        catch(e){
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
        
    }
    getPreviousReport = () => {
        RestAPIService.getAll(Serviceurls.LAB_PREVIOUS_REPORT + "?patient_id=" + this.state.selectedTableRow?.patient_id)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        preReportList: response.data.data
                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
    }
    getStatusSpecimanList = () => {
        RestAPIService.getAll(Serviceurls.LAB_CONSUMABLES_LIST_GET)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        specimanSuggessionList: response.data.data
                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
    }
    getStatusSummaryList = () => {
        this.setState({
            statusSummaryList: []
        })
        let {departmentName, groupName, testName, patientSearch} = this.state;
        let dept_name = departmentName?.dept_name ? departmentName?.dept_name : "";
        let grp_name = groupName?.name ? groupName?.name : "";
        let test_name = testName?.test_name ? testName?.test_name : "";
        let uhid = "", patient_names = "";
        
        if(patientSearch && alphanumericRegex.test(patientSearch)){
            uhid = patientSearch;
        } else {
            patient_names = patientSearch;
        }

        RestAPIService.getAll(Serviceurls.LAB_PATINET_STATUS + "?result_status=Result Inprogress" + "&search_key1=" + patient_names + "&search_key2=" + uhid + "&from_date=" + this.state.QueryFromDate + "&to_date=" + this.state.QueryToDate 
        +"&dept_name=" + dept_name + "&grp_name=" + grp_name + "&test_name=" + test_name)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        statusSummaryList: response.data.data
                    }, () => {
                        var { statusSummaryList } = this.state
                        statusSummaryList.forEach(data => { data.is_multi_option = false; });

                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response?.data?.message
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error?.response?.data?.message
                })
            })
    }
    onClickSavedChanges = () => {
        var { selectedTableRow } = this.state
        try {
            var data = {
                "result_summary_id": selectedTableRow.id ? selectedTableRow.id : null,
                "out_source": this.state.newSampleCollected ? this.state.newSampleCollected : []
            }
            RestAPIService.create(data, Serviceurls.LAB_PATENT_SPECIMAN_COLLECTED)
                .then(response => {
                    if (response.data.status == "success") {
                        var data = response.data.data
                        this.getLabSampleCollect(selectedTableRow.id)
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.message,
                            isNotPost: false,
                        })
                    }

                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                })
        }
        catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    postConsumables = () => {
        var { selectedTableRow, sampleCollectedIn } = this.state
        try {
            let filteredItems = sampleCollectedIn.filter(item => !item.ismaped);
            var data = {
                "result_summary_id": selectedTableRow.id ? selectedTableRow.id : null,
                "extra_consumables": filteredItems ? filteredItems : [],
                "consumables": this.state.consumableList ? this.state.consumableList : []
            }
            RestAPIService.create(data, Serviceurls.LAB_PATENT_SPECIMAN_COLLECTED)
                .then(response => {
                    if (response.data.status == "success") {
                        var data = response.data.data
                        this.getStatusSummaryList()
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.message,
                            isSpecimanClick: false,
                            consumableList: [],
                            sampleCollectedIn: [],
                            StepNumber: 0,
                        })
                    }

                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                })
        }
        catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    onCancelPost = () => {
        try {
            var data = {
                "bill_summary_id": this.state.billSummaryID,
                "cancel_reason": this.state.reason,
                "appointment_id": this.state.selectedTableRow.appointment_id ? this.state.selectedTableRow.appointment_id : null,
                "ip_admission_id": this.state.selectedTableRow.ip_admission_id ? this.state.selectedTableRow.ip_admission_id : null,
                "ip_number": this.state.selectedTableRow.ip_number ? this.state.selectedTableRow.ip_number : null
            }
            if (this.state.reason == "") {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: "Please enter the bill cancel reason"
                })
            } else {
                RestAPIService.create(data, Serviceurls.LAB_CANCEL_BILL)
                    .then(response => {
                        if (response.data.status == "success") {
                            var datas = response.data
                            this.setState({
                                isSuccessMsgText: datas.message,
                                isSuccessMsg: true,
                                isCancelBill: false,
                                anchorEl: false,
                                reason: ""
                            }, () => {
                                this.getStatusSummaryList()
                            })
                        }
                    }).catch(e => {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: e.message
                        })
                    })
            }
        }
        catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    onDeleteBill = () => {
        try {
            let serviceUrl = Serviceurls.LAB_CANCEL_BILL + "?bill_summary_id=" + parseInt(this.state.billSummaryID)
            RestAPIService.delete(serviceUrl, {})
                .then(response => {
                    if (response.data.status == "success") {
                        var datas = response.data
                        this.setState({
                            isSuccessMsgText: datas.message,
                            isSuccessMsg: true,

                        }, () => {
                            this.props.getSavedBillStatus(true)
                            this.props.getTransmitBillCount(true)
                            this.getStatusSummaryList()
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })
        }
        catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    renderSpecimanBottomView = (header, value) => {
        return (
            <div className='Lab_status_speciman_bottom'>
                <p className='Lab_Status_Header1'>{header}</p>
                <p className='Lab_Status_split'>{" : "}</p>
                <p className='Lab_Status_Value'>{value}</p>
            </div>
        )
    }
    renderTextField = (title, value, key) => {
        const { t } = this.props
        return (
            <Box component={'div'}>
                {key == "consumablesName" ?
                    <Autocomplete
                        options={this.state.specimanSuggessionList}
                        getOptionLabel={(options) => options.product_name}
                        onChange={(e, value) => {

                            this.setState({
                                consumableUnit: value ? value.unit : value,
                                selectConsumables: value,
                                consumableValue: ""
                            })
                        }}
                        value={this.state.selectConsumables}
                        id="combo-box-demo"
                        sx={{ width: "15vw" }}
                        renderInput={(params) => <TextField {...params} label={"Select Consumables"} size='small' emed_tid = "Consumables" />}
                    /> :
                    < TextField
                        disabled={this.state.selectConsumables == null ? true : false}
                        name={title}
                        sx={{ width: '13vw' }}
                        size="small"
                        inputProps={{
                            emed_tid : "consumablesField_Test_ID"
                        }}
                        placeholder={t(title)}
                        value={value}
                        onChange={(e) => {
                            var states = this.state
                            if (key == "consumableValue") {
                                var { selectConsumables } = this.state
                                let data = e.target.value
                                if (selectConsumables.stock_in_qty && (+data <= selectConsumables?.stock_in_qty) || data == "") {
                                    states[key] = e.target.value
                                    this.setState({
                                        states
                                    })
                                }
                            } else {
                                states[key] = e.target.value
                                this.setState({ states })
                            }
                        }}
                    />
                }
            </Box>

        )
    }
    clearData = () => {
        this.setState({
            outSourceOptionList: JSON.parse(this.state.resetSourceOptionList),
            sampleCollectedIn: [],
            consumablesName: "",
            consumableValue: "",
            consumableUnit: "",
            selectConsumables: null
        })
    }
    validCheck = (data) => {
        var { sampleCollectedIn } = this.state
        var duplicate = false
        if (sampleCollectedIn?.length > 0) {
            for (let i = 0; i < sampleCollectedIn.length; i++) {
                if (sampleCollectedIn[i].product_ms_id == data.product_ms_id) {
                    duplicate = true
                }
                if (sampleCollectedIn[i].product_ms_id == data.product_ms_id) {
                    duplicate = true
                }
            }
        }
        return duplicate
    }
    dateConvertion = (d) => {
        var dateForm = new Date(d)
        let data = DateTime.fromJSDate(dateForm).toFormat("dd-MM-yyyy")
        return data
    }
    testReportPrint = () => {
        RestAPIService.getAll(Serviceurls.LAB_TEST_REPORT_PRINT + "?export_type=pdf").
            then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.reponse.data.message
                })
            })
    }
    individualReportPrint = (id) => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + "?result_summary_id=" + id)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    CrebillPosted(message = "", receiptNO = null, phrReceipt = null, error = false) {
        this.setState({
            CreditBillPopUp: false,
            receiptID: receiptNO,
            pharmareceipt: phrReceipt

        }, () => {
            if (error) {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: message,
                    anchorEl: false
                })
            } else {
                this.setState({
                    isSuccessMsg: true,
                    isSuccessMsgText: message,
                    anchorEl: false
                })
            }
            this.getStatusSummaryList()
            this.getStatusSpecimanList()
        })
    }

    ClosesuccessPopUp = () => {
        this.setState({
            receiptID: null,
            pharmareceipt: null
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            isSuccessMsg: true,
            isSuccessMsgText: message
        })
    }

    renderStatusTable = () => {
        try {
            let filtereddata = this.state.statusSummaryList.filter((data) => {
                return (
                    (data?.patient_name === null ? null : data?.patient_name.toLowerCase().includes(this.state.TableSearchText.toLowerCase()))
                );
            });
            filtereddata?.slice(
                this.state.tblPage * this.state.tblPageSize,
                this.state.tblPage * this.state.tblPageSize +
                this.state.tblPageSize)?.forEach((element, index) => {
                    let page = this.state.tblPage
                    let pageSize = this.state.tblPageSize
                    let serial = (page * pageSize) + (index + 1)
                    element.serial = serial
                })
            return (
                <Box component={'div'} border={'1px solid lightgray'} height={'67vh'} overflow={'auto'}>
                    <Box component={'div'} height={'57vh'} overflow={'auto'}>
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>S.No</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Patient Details</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Mobile Number</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill Type</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Total Test</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }} />
                            </TableHead>
                            <TableBody>
                                {filtereddata?.length > 0 && filtereddata?.slice(
                                    this.state.tblPage * this.state.tblPageSize,
                                    this.state.tblPage * this.state.tblPageSize +
                                    this.state.tblPageSize)?.map((item, index) => (
                                        <><TableRow key={index}
                                            emed_tid = {"tableSelectedIndex_TestID"}
                                            onClick={() => {
                                                this.setState({
                                                    tableSelectedIndex: this.state.tableSelectedIndex === index ? null : index
                                                })
                                            }}
                                        >
                                            <TableCell>{item?.serial}</TableCell>
                                            <TableCell>
                                                <div style={{ fontSize: '0.9vw', fontWeight: '600' }}>{`${item?.patient_name} ${item?.patient_age}`}</div>
                                                <div style={{ fontSize: '0.8vw', color: Colors.grayShade }}>{`${item?.patient_account_number ? item?.patient_account_number : '-'} | ${item?.patient_tag_name ? item?.patient_tag_name : '-'}`}</div>
                                            </TableCell>
                                            <TableCell>{item?.patient_mobile}</TableCell>
                                            <TableCell>{item?.bill_type}</TableCell>
                                            <TableCell>{`${item?.completed_test} / ${item?.total_test}`}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    size='small'
                                                    emed_tid = {"tableSelectedIndex02_TestID"}
                                                    onClick={() => {
                                                        this.setState({
                                                            tableSelectedIndex: this.state.tableSelectedIndex === index ? null : index
                                                        })
                                                    }}
                                                >
                                                    {this.state.tableSelectedIndex === index ?
                                                        <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                            <TableRow>
                                                {this.renderStatusSecondTable(item, index)}
                                            </TableRow></>
                                    ))}
                            </TableBody>
                        </Table>
                    </Box>

                    <TablePagination
                        className='eMed_tbl_pagination'
                        sx={{ borderTop: '1px solid lightgray' }}
                        component='div'
                        rowsPerPageOptions={[10, 20, 30]}
                        count={filtereddata?.length}
                        rowsPerPage={this.state.tblPageSize}
                        page={this.state.tblPage}
                        onPageChange={(event, newPage) => { this.setState({ tblPage: newPage }) }}
                        onRowsPerPageChange={(event) => { this.setState({ tblPageSize: event.target.value, tblPage: 0 }) }}
                    />
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    disableMoreOptions(item) {
        let { selectedTableRow } = this.state
        switch (item?.value) {
            case "ReceivePayment":
                if (selectedTableRow?.payment_status === 'paid') {
                    return true
                } else {
                    return false
                }
            case "ModifyBill":
                if (selectedTableRow?.bill_type === "credit" && selectedTableRow?.payment_status === "not paid" && selectedTableRow?.status !== "Specimen Collected") {
                    return false
                } else {
                    return true
                }
            case "CancelBill":
                if (selectedTableRow.can_cancel){
                    return false
                }else{
                    return true
                }
            default: return false
        }
    }

    renderMenu = (secTblItem) => {
        try {
            let { selectedTableRow } = this.state
            let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
            let ModifyBillAccess = RoleData?.is_user ? (RoleData?.permission_access?.common?.modify_credit_bill ? RoleData?.permission_access?.common?.modify_credit_bill : false) : true
            let cancelBillApproval = RoleData?.is_user ? (RoleData?.permission_access?.common?.cancel_bill_approval ? RoleData?.permission_access?.common?.cancel_bill_approval : false) : true
            let menuList = [
                { "value": "ViewPreviousReport", "label": "View Previous Report" },
                { "value": "ReceivePayment", "label": "Receive Payment" },
                { "value": "BarCode", "label": "Common Barcode" },
                { "value": "ModifyBill", "label": "Modify Bill" },  // will be sliced when no access
                { "value": "ModifyHistory", "label": "Modify History" },
                { "value": "CancelBill", "label": "Cancel Bill" },
                // { "value": "DeleteBill", "label": "Delete Bill" }
            ]
            if (!ModifyBillAccess) {
                menuList.splice(3, 1);
            }
            if (!cancelBillApproval) {
                menuList.pop();
            }
            return (
                <Menu
                    emed_tid = "disableMoreOptions_Test_Id"
                    id='long-menu'
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={(e) => {
                        this.setState({
                            anchorEl: null
                        })
                    }}
                    elevation={2}
                >
                    {menuList?.map((item, index) => (
                        <MenuItem key={index}
                            disabled={this.disableMoreOptions(item)}
                            onClick={() => {
                                this.setState({
                                    selectedOption: item.value
                                }, () => {
                                    if (this.state.selectedOption == "ReceivePayment") {
                                        // this.props.selectedTab(0, this.state.selectedTableRow, "isReceivePayment", false)
                                        this.setState({ SelectedBill: this.state.selectedTableRow, CreditBillPopUp: true })
                                    }
                                    if (this.state.selectedOption == "ViewPreviousReport") {
                                        this.setState({
                                            isViewReport: true
                                        })
                                    }
                                    if (this.state.selectedOption == "BarCode") {
                                        this.setState({
                                            SelectedBill: this.state.selectedTableRow
                                        }, () => this.getCommonBarCodePrint(secTblItem))
                                    }
                                    if (this.state.selectedOption == "CancelBill") {
                                        let Data = {
                                            id: this.state.selectedTableRow?.lab_bill_summary_id,
                                            patient_id: this.state.selectedTableRow?.patient_id,
                                            laboratory_id: null,
                                            amount_net: this.state.selectedTableRow?.amount_net,
                                            appointment_id: this.state.selectedTableRow?.appointment_id,
                                            ip_admission_id: this.state.selectedTableRow?.ip_admission_id
                                        }

                                        this.props.cancelBillClick(Data)
                                    }
                                    if (this.state.selectedOption == "DeleteBill") {
                                        this.onDeleteBill()
                                    }
                                    if (this.state.selectedOption == "ModifyBill") {
                                        let Data = {
                                            id: this.state.selectedTableRow?.lab_bill_summary_id,
                                            patient_id: this.state.selectedTableRow?.patient_id,
                                            laboratory_id: null,
                                            amount_net: this.state.selectedTableRow?.amount_net,
                                            appointment_id: this.state.selectedTableRow?.appointment_id,
                                            ip_admission_id: this.state.selectedTableRow?.ip_admission_id,
                                            isForEditBill: true
                                        }
                                        this.props.cancelBillClick(Data)
                                    }
                                    if (this.state.selectedOption == "ModifyHistory") {
                                        this.setState({ BillModifyHistoryItem: this.state.selectedTableRow })
                                    }
                                })
                            }}
                        >{item?.label}</MenuItem>
                    ))}
                </Menu>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderStatusSecondTable = (item, index) => {
        try {
            return (
                <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={this.state.tableSelectedIndex === index} timeout={'auto'}>
                        <Box component={'div'} margin={1} className='eMed_Lab_Sts_Tbl_Two'>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Date</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill No</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Barcode Number</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Result Status</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Total Test</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item?.data?.map((secTblItem, secTblIndex) => (
                                        <TableRow key={secTblIndex} sx={{ backgroundColor: secTblItem?.bill_type === "cancelled" ? Colors.disableComponentColor : "white" }}>
                                            <TableCell>{secTblItem?.created_date__date}</TableCell>
                                            <TableCell>{secTblItem?.bill_number}</TableCell>
                                            <TableCell>{secTblItem?.barcode}</TableCell>
                                            <TableCell sx={{ color: secTblItem?.bill_type === "cancelled" ? Colors.red : "black" }}>{secTblItem?.bill_type === "cancelled" ? "Cancelled" : secTblItem?.status}</TableCell>
                                            <TableCell>{` ${secTblItem?.completed_test} / ${secTblItem?.total_test}`}</TableCell>
                                            <TableCell>
                                                <Box component={'div'} display={'flex'} alignItems={'center'}>
                                                    <Button className='Common_Btn_Min_Width' emed_tid = "cancelled__test_ID"
                                                        disabled={!CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess || secTblItem?.bill_type === "cancelled"}
                                                        onClick={() => {
                                                            if (secTblItem?.bill_type === "cancelled") {

                                                            } else {
                                                                this.setState({
                                                                    // isSpecimanClick: true,
                                                                    selectedTableRow: secTblItem
                                                                }, () => {
                                                                    this.getSpecimanCollectedValue(secTblItem?.id)
                                                                    this.getLabSampleCollect(secTblItem?.id)
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <Tooltip placement='top' title={'Specimen'}>
                                                            <Box component={'img'} src={secTblItem?.status !== "Specimen Collected" ? ImagePaths.Speciman.default : ImagePaths.ResultDisable.default} height={'1.8vw'} width={'1.8vw'} />
                                                        </Tooltip>
                                                    </Button>
                                                    <Button className='Common_Btn_Min_Width' emed_tid = "Specimen__Collected0__test_ID"
                                                        disabled={!CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess || secTblItem?.bill_type === "cancelled"}
                                                        onClick={() => {
                                                            if (secTblItem?.status !== "Specimen Collected") {
                                                                if (secTblItem?.bill_type !== "cancelled") {
                                                                    this.errorMessage('Kindly collect the specimen before entering the result')
                                                                }
                                                            } else {
                                                                this.props.selectedTab()
                                                            }
                                                        }}
                                                    >
                                                        {secTblItem?.status === "Specimen Collected" ?
                                                            <Link  emed_tid = "Specimen__Collected001__test_ID" to={{ pathname: "/Results", state: { resultList: secTblItem } }}>
                                                                <Box component={'img'} src={ImagePaths.Result.default} height={'1.8vw'} width={'1.8vw'} position={'relative'} top={'0.2vw'} />
                                                            </Link> :
                                                            <Box component={'div'}>
                                                                <Box emed_tid = "Specimen__Collected002__test_ID" component={'img'} src={ImagePaths.Result.default} height={'1.8vw'} width={'1.8vw'} position={'relative'} top={'0.2vw'} />
                                                            </Box>
                                                        }
                                                    </Button>
                                                    <Button className='Common_Btn_Min_Width'
                                                        aria-label="more"
                                                        id="long-button"
                                                        aria-controls={this.state.isSelected ? 'long-menu' : undefined}
                                                        aria-expanded={this.state.isSelected ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        emed_tid = "getPreviousReport__test_ID"
                                                        disabled={CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess ? secTblItem?.bill_type == "cancelled" ? true : false : true}
                                                        onClick={(e) => {
                                                            this.setState({
                                                                anchorEl: e.currentTarget,
                                                                billSummaryID: secTblItem?.lab_bill_summary,
                                                                selectedTableRow: secTblItem,
                                                                isSelected: true
                                                            }, () => {
                                                                this.getPreviousReport()
                                                                // this.getReceivePaymentAmountData(secTblItem)
                                                            })

                                                        }}
                                                    >
                                                        <MoreVertIcon sx={{ color: 'black' }} />
                                                    </Button>
                                                </Box>
                                                {this.renderMenu(secTblItem)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell></>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    HistoryPopClose() { this.setState({ BillModifyHistoryItem: {} }) }


    renderSpecimanCollection = () => {
        return (
            <Box component={'div'} sx={{ width: '80vw', height: '35vw', }}>
                <Box component={'div'} sx={{ width: '78vw', height: '33vw', margin: '1vw' }}>
                    <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid lightgray' }}>
                        <Typography sx={{ fontSize: '1vw', fontWeight: 'bold' }}>Sample Collection</Typography>
                        <IconButton size='small'
                            onClick={() => {
                                this.setState({
                                    isSpecimanClick: false,
                                    newSampleCollected: collect_sample,
                                    sampleCollectedIn: [],
                                    consumableList: [],
                                    StepNumber: 0,
                                    newSampleCollDuplicate: [],
                                }, ()=>{
                                    this.getStatusSummaryList()
                                })
                            }}>
                            <img src={ImagePaths.LabCloseButton.default} style={{ width: '1vw', height: '1vw' }} alt='close' />
                        </IconButton>
                    </Box>
                    <Box sx={{ height: '3vw', marginTop: '1vw', display: 'flex', justifyContent: 'center', alignItems: 'center', borderBottom: '1px solid lightgray', }}>
                        <Stepper activeStep={this.state.StepNumber} sx={{ width: "50%" }}>
                            {Steps.map((label, index) => (
                                <Step key={index}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    {this.state.StepNumber === 0 ? this.specimanStep() : this.state.StepNumber === 1 ? this.consumablesStep() : null}
                </Box>
            </Box>
        )
    }

    specimanStep = () => {
        let states = this.state
        
        let name = states?.selectedTableRow?.patient_name ? states?.selectedTableRow?.patient_name : '-'
        let age = states?.selectedTableRow?.patient_age ? states?.selectedTableRow?.patient_age : '-'
        let gender = states?.selectedTableRow?.patient__gender === 'f' ? 'F' :
            states?.selectedTableRow?.patient__gender === 'm' ? 'M' : states?.selectedTableRow?.patient__gender === 't' ? "T" : ''
        let uhid = states?.selectedTableRow?.patient__patient_account_number ? states?.selectedTableRow?.patient__patient_account_number : '-'
        let phone = states?.selectedTableRow?.patient__mobile_number ? states?.selectedTableRow?.patient__mobile_number : '-'

        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: '25vw',
                },
            },
        };
        return (
            <Box>
                <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgray', marginTop: '1vw' }}>
                    <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{`${name} ${age}/${gender}`}</Typography>
                    <Typography sx={{ fontSize: '0.8vw', marginLeft: '1vw', marginBottom: '.5vw' }}>{`${uhid} | ${phone}`}</Typography>
                </Box>
                <Box component={'div'} sx={{ height: '21vw', marginTop: '.5vw', borderBottom: '1px solid lightgray', }}>
                    <Typography sx={{ color: '#616161' }}>Collect Samples</Typography>
                    <Box component={'div'} sx={{ maxHeight: '15vw', overflow: 'auto', marginTop: '.3vw', }}>
                        {states.newSampleCollected?.length > 0 ?
                            states.newSampleCollected.map((item, index) => {
                                return (
                                    <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '.3vw' }}>
                                        <Box component={'div'} sx={{ width: '3vw' }}>
                                            <Tooltip placement='top' title={'Delete'}>
                                                <IconButton size='small' disabled={item?.is_specimen_collected}
                                                    onClick={() => {
                                                        if (states.newSampleCollected.length > 1) {
                                                            states.newSampleCollected.splice(index, 1)
                                                            this.setState({
                                                                states
                                                            })
                                                        } else {
                                                            this.errorMessage("At least one value has been filled")
                                                        }
                                                    }}>
                                                    <img src={ImagePaths.DeleteOutline.default} alt='delete' style={{ width: '1.2vw', height: '1.2vw' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Box component={'div'} sx={{ width: '21vw' }}>
                                            <Autocomplete
                                                size='small'
                                                freeSolo
                                                disabled={item?.is_specimen_collected}
                                                sx={{ width: '14.5vw' }}
                                                options={states.sampleType_Data}
                                                getOptionLabel={(option) => (option.specimen_name)}
                                                value={item.sample_type}
                                                onChange={(event, newValue) => {
                                                    states.newSampleCollected[index]["sample_type"] = newValue
                                                    this.setState({ states })
                                                }}
                                                onInputChange={(e, newValue) => {
                                                    states.newSampleCollected[index]["sample_type"] = {
                                                        specimen_name: newValue,
                                                        id: null,
                                                    }
                                                    this.setState({
                                                        states
                                                    })
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box component={'div'} sx={{ width: '21vw' }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    disabled
                                                    open={states.DateTimerOpen}
                                                    onOpen={() => { this.setState({ DateTimerOpen: true }) }}
                                                    onClose={() => { this.setState({ DateTimerOpen: false }) }}
                                                    maxDateTime={new Date(Date.now())}
                                                    minDate={new Date(Date.now())}
                                                    value={item.collected_date}
                                                    inputFormat='DD-MM-YYYY & hh:mmA'
                                                    onChange={(value) => {
                                                        if (value === null) {
                                                            states.newSampleCollected[index]['collected_date'] = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd HH:mm:ss')
                                                        }
                                                        this.setState({ states })
                                                    }}
                                                    renderInput={(params) => <TextField
                                                        size="small" {...params}
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        onClick={() => { this.setState({ DateTimerOpen: true }) }}
                                                        sx={{ width: "14.5vw" }}
                                                    />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <Box component={'div'} sx={{ width: '35vw' }}>
                                            <FormControl sx={{ width: '25.4vw' }} size='small'>
                                                <Select
                                                    disabled={item?.is_specimen_collected}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    multiple
                                                    value={Array.isArray(item.test_collected) ? item.test_collected : []}
                                                    onChange={(e) => {
                                                        const selectedValues = e.target.value;
                                                        const updatedSampleCollected = [...states.newSampleCollected];
                                                        updatedSampleCollected[index]["test_collected"] = selectedValues;
                                                        updatedSampleCollected[index]["outsource_data"] = { out_source__outsource_laboratory_name: 'self', out_source_id: null }

                                                        this.setState({ newSampleCollected: updatedSampleCollected }, () => {
                                                            //-->Old lines
                                                            // let selectedList = states.newSampleCollected[index]['test_collected']?.map(name => (
                                                            //     states.outSourceOptionList?.find(item => item.set_name === name || item.test_name === name)
                                                            // )) 
                                                            
                                                            //-->New lines
                                                            let selectedList = []
                                                            states.newSampleCollected[index]['test_collected']?.map(name => (
                                                                states.outSourceOptionList?.forEach(item => {
                                                                    if(item.set_name === name || item.test_name === name){
                                                                        selectedList.push(item)
                                                                    }
                                                                })
                                                            ))
                                                            let outsourceList = [];
                                                            let outSourceIds = new Set();
                                                            let outsourceData = [{ out_source__outsource_laboratory_name: 'self', out_source_id: null }]
                                                            selectedList?.forEach(element => {
                                                                element?.out_source_list?.forEach(list => {
                                                                    if (!outSourceIds.has(list.out_source_id)) {
                                                                        outSourceIds.add(list.out_source_id);
                                                                        outsourceList.push(list);
                                                                    }
                                                                });
                                                            });
                                                            outsourceList?.forEach(element => { outsourceData?.push(element) })
                                                            states.newSampleCollected[index]['outsource_list'] = outsourceData

                                                            let test_collected_ids = [];
                                                            states.outSourceOptionList.forEach(option => {
                                                                if (selectedValues.includes(option.set_name || option.test_name)) {
                                                                    test_collected_ids.push({"id": option.id, "outsource_list": option.out_source_list, 'out_source_id': null, 'outsource_laboratory_name': 'self', 'is_outsource': false});
                                                                }
                                                            });
                                                            states.newSampleCollected[index]['test_ids'] = test_collected_ids
                                                            this.setState({ newSampleCollected: states.newSampleCollected })
                                                        });
                                                    }}
                                                    input={<OutlinedInput />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    {states.outSourceOptionListNew.map((optionItem) => {
                                                         const isOutSourced = !optionItem?.set_name 
                                                         ? optionItem.out_source_list && optionItem.out_source_list.length > 0 
                                                         : optionItem.data.some((item) => item.out_source_list && item.out_source_list.length > 0);
                                                        return (
                                                            <MenuItem key={optionItem.id} value={optionItem.set_name || optionItem.test_name}>
                                                                <Checkbox size='small' checked={this.state.newSampleCollected[index].test_collected?.includes(optionItem.set_name || optionItem.test_name)} />
                                                                <ListItemText
                                                                    sx={{
                                                                        color: isOutSourced ? '#c74242' : 'inherit',
                                                                        fontWeight: isOutSourced ? 'bold' : 'normal',
                                                                        fontSize: '.8vw'
                                                                    }}
                                                                    primary={optionItem.set_name || optionItem.test_name}
                                                                />
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box component={'div'} sx={{ width: '17vw' }}>
                                            <Autocomplete
                                                size='small'
                                                disabled={states.newSampleCollected[index]["test_collected"]?.length === 0 || item?.is_specimen_collected}
                                                sx={{ width: '13vw' }}
                                                options={states.newSampleCollected[index]['outsource_list'] ? states.newSampleCollected[index]['outsource_list'] : []}
                                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.out_source__outsource_laboratory_name}
                                                value={states.newSampleCollected[index]['outsource_data']?.out_source__outsource_laboratory_name}
                                                onChange={(event, newValue) => {
                                                    const updatedSampleCollected = [...states.newSampleCollected];
                                                    updatedSampleCollected[index]["outsource_data"] = newValue;
                                                    updatedSampleCollected[index]["is_outsource"] = newValue?.out_source_id === null ? false : true;
                                                    let Tests = updatedSampleCollected[index].test_ids
                                                    Tests.forEach((val, idx) => {
                                                        if (val?.outsource_list?.length > 0) {
                                                            for (let i = 0; i < val.outsource_list.length; i++) {
                                                                let item = val.outsource_list[i];
                                                                if (item?.out_source_id == newValue?.out_source_id) {
                                                                    updatedSampleCollected[index]["test_ids"][idx].out_source_id = newValue?.out_source_id;
                                                                    updatedSampleCollected[index]["test_ids"][idx].outsource_laboratory_name = newValue?.out_source__outsource_laboratory_name;
                                                                    updatedSampleCollected[index]["test_ids"][idx].is_outsource = true;
                                                                    break; // Stop the loop once the condition is met
                                                                } else {
                                                                    updatedSampleCollected[index]["test_ids"][idx].out_source_id = null;
                                                                    updatedSampleCollected[index]["test_ids"][idx].outsource_laboratory_name = 'self';
                                                                    updatedSampleCollected[index]["test_ids"][idx].is_outsource = false;
                                                                }
                                                            }
                                                        } else {
                                                            updatedSampleCollected[index]["test_ids"][idx].out_source_id = null;
                                                            updatedSampleCollected[index]["test_ids"][idx].outsource_laboratory_name = 'self';
                                                            updatedSampleCollected[index]["test_ids"][idx].is_outsource = false;
                                                        }
                                                    })
                                                    this.setState({ newSampleCollected: updatedSampleCollected });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} />
                                                )}
                                            />
                                        </Box>
                                        <Box component={'div'} sx={{ width: '3vw' }}>
                                            <Tooltip placement='top' title={'Print Barcode'}>
                                                <IconButton size='small' disabled={states.newSampleCollected[index]['is_specimen_collected'] === true ? false : true} onClick={() => { this.PrintBarcode(states.newSampleCollected[index]['test_ids']) }}>
                                                    <img src={states.newSampleCollected[index]['is_specimen_collected'] === true ? ImagePaths.PrintIcons.default : ImagePaths.LabPrintIconOff.default} alt='delete' style={{ width: '1.5vw', height: '1.5vw' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>

                                    </Box>
                                )
                            })
                            : null}
                    </Box>
                    <Box component={'div'}>
                        <Button
                            variant='outlined'
                            size='small'
                            disabled={this.state.isSample}
                            sx={{ textTransform: 'capitalize', width: '14vw', marginTop: '.5vw' }}
                            startIcon={<AddCircleIcon />}
                            onClick={() => {
                                const lastIndex = states.newSampleCollected.length - 1;
                                const previousTestCollected = states.newSampleCollected[lastIndex]?.test_collected;

                                if (previousTestCollected && previousTestCollected.length > 0) {
                                    states.newSampleCollected.push({
                                        "sample_type": null,
                                        "is_outsource": false,
                                        "collected_date": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd HH:mm:ss'),
                                        "test_collected": [],
                                        "test_ids": [],
                                        "outsource_list": [{ out_source__outsource_laboratory_name: 'self', out_source_id: null }],
                                        "outsource_data": {out_source__outsource_laboratory_name: 'self', out_source_id: null}
                                    });
                                    this.setState({ states });
                                } else {
                                    this.setState({
                                        isErrorMsg: true,
                                        isErrorMsgText: 'Previous Line is empty. Cannot add new sample type.'
                                    })
                                }
                            }}
                        >
                            Add New Sample Type
                        </Button>
                        {this.state.isNotPost ? <Typography sx={{ fontSize: '0.7vw', color: 'red', fontWeight: 'bold' }}>{`Before proceeding, ensure you've clicked 'Collect Sample' to avoid any potential data loss.`}</Typography> : null}
                    </Box>
                </Box>
                <Box component={'div'} sx={{ marginTop: '.5vw', display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box sx={{ border: '1px solid #c74242', backgroundColor: '#c74242', height: '.8vw', width: '.8vw' }}></Box>
                        <Typography sx={{ fontSize: '0.6vw', marginLeft: '0.5vw' }}>Outsource Tests</Typography>
                    </Box>
                    <Stack direction={'row'} spacing={2} justifyContent={'flex-end'}>
                        <Button size='small' variant='outlined' sx={{ textTransform: 'capitalize', width: '8vw' }}
                            onClick={() => {
                                const defaultData = [
                                    {
                                        "sample_type": {id:null, specimen_name: ""},
                                        "is_outsource": false,
                                        "collected_date": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd HH:mm:ss'),
                                        "test_collected": [],
                                        "test_ids": [],
                                        "outsource_list": [{ out_source__outsource_laboratory_name: 'self', out_source_id: null }],
                                        "outsource_data": {out_source__outsource_laboratory_name: 'self', out_source_id: null}
                                    }
                                ];
                                if (this.state.newSampleCollected && this.state.newSampleCollected.length > 1) {
                                    const updatedData = this.state.newSampleCollected.filter(sample => sample.is_specimen_collected);
                                    if (updatedData.length > 0) {
                                        this.setState({ newSampleCollected: updatedData });
                                    } else {
                                        this.setState({ newSampleCollected: defaultData });
                                    }
                                } else {
                                    this.setState({ newSampleCollected: defaultData });
                                }
                            }} >
                            Clear
                        </Button>
                        <Button size='small' variant='contained' sx={{ textTransform: 'capitalize', width: '12vw' }}
                            disabled={this.state.isSample}
                            onClick={() => {
                                if (this.testDuplicate()) {
                                    this.setState({
                                        isErrorMsg: true,
                                        isErrorMsgText: 'Already Test Selected For Speciman'
                                    })
                                } else {
                                    this.onClickSavedChanges()
                                }
                            }}>
                            Collect Sample
                        </Button>
                        <Button size='small' variant='contained' sx={{ textTransform: 'capitalize', width: '10vw' }}
                            onClick={() => {
                                let isSpecimenCollectedAvailable = this.state.newSampleCollected.every(sample => sample.hasOwnProperty('is_specimen_collected'));
                                if (isSpecimenCollectedAvailable || this.state.isNotPost) {
                                    this.setState({
                                        StepNumber: 1,
                                        isNotPost: false
                                    })
                                } else {
                                    this.setState({
                                        isNotPost: true
                                    })
                                }
                            }}>
                            Next
                        </Button>
                    </Stack>
                </Box>
            </Box>
        )
    }

    consumablesStep = () => {
        let states = this.state
        var { selectConsumables } = this.state
        return (
            <Box component={'div'} sx={{ height: '27vw' }}>
                <Box component={'div'} sx={{ height: '3vw', marginTop: '.5vw', display: 'flex', flexDirection: 'row', width: '50vw', justifyContent: 'space-between', alignItems: 'center' }}>
                    {this.renderTextField(t("EnterConsumables"), this.state.consumablesName, "consumablesName")}
                    {this.renderTextField("Quantity", this.state.consumableValue, "consumableValue")}
                    {this.renderTextField("Units", this.state.consumableUnit, "consumableUnit")}
                    <Button
                        variant="contained"
                        size='small' sx={{ height: '2vw', textTransform: 'capitalize', width: '6vw' }}
                        // disabled={this.state.isSample}
                        onClick={() => {
                            var { sampleCollectedIn } = this.state
                            if (this.state.consumableValue !== "") {
                                var valid = this.validCheck(selectConsumables)
                                // if (valid) {
                                //     this.setState({
                                //         isErrorMsg: true,
                                //         isErrorMsgText: 'The consumable has already been added to the list.'
                                //     })
                                // }
                                if (selectConsumables == null) {
                                    this.setState({
                                        isErrorMsg: true,
                                        isErrorMsgText: "Please select the consumables"
                                    })
                                } else if (selectConsumables && Object.keys(selectConsumables).length > 0 && this.state.consumableValue == "") {
                                    this.setState({
                                        isErrorMsg: true,
                                        isErrorMsgText: "Please enter the consumable quantity"
                                    })
                                }
                                else if (selectConsumables && Object.keys(selectConsumables).length > 0 && this.state.consumableValue && this.state.consumableUnit) {
                                    sampleCollectedIn.push({ "brand_name": selectConsumables.product_name, "quantity": this.state.consumableValue, "unit": this.state.consumableUnit, "product_ms_id": selectConsumables.product_ms_id })
                                    this.setState({
                                        sampleCollectedIn
                                    }, () => {
                                        this.setState({
                                            consumablesName: "",
                                            consumableValue: "",
                                            consumableUnit: "",
                                            selectConsumables: null
                                        })
                                    })
                                }
                            } else {
                                this.setState({
                                    isErrorMsg: true,
                                    isErrorMsgText: "Please Enter Quantity"
                                })
                            }
                        }}>Add</Button>
                </Box>
                <Box component={'div'} sx={{ height: '21vw' }}>
                    <TableContainer component={Paper} sx={{ height: '20vw', overflow: 'scroll' }}>
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow'>Consumables</TableCell>
                                    <TableCell id='eMed_config_TableRow'>Quantity</TableCell>
                                    <TableCell id='eMed_config_TableRow'>Unit</TableCell>
                                    <TableCell id='eMed_config_TableRow'>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.sampleCollectedIn && this.state.sampleCollectedIn.length > 0 ?
                                this.state.sampleCollectedIn.map((item, index) => {
                                    return (
                                        <TableRow sx={{ height: "4vh" }}>
                                            <TableCell align="left">{item.brand_name}</TableCell>
                                            <TableCell align="left" >
                                                <div className="Lab_Extra_Speciman">
                                                    <Typography>{item.quantity}</Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{item.unit ? item.unit : 0}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton disabled={item.ismaped || item.isextramaped ? true : false} onClick={() => {

                                                    var { sampleCollectedIn } = this.state
                                                    sampleCollectedIn.splice(index, 1)
                                                    this.setState({
                                                        sampleCollectedIn
                                                    })
                                                }}>
                                                    <img className='eMed_TestConfig_DeleteIcon eMed_cursor' src={item.ismaped || item.isextramaped ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default}
                                                        alt="Delete"
                                                    />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell align="center">{t("NoRecordsFound")}</TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>}

                        </Table>
                    </TableContainer>
                </Box>
                <Box component={'div'} sx={{ height: '3vw', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Stack spacing={2} direction="row">
                        <Button
                            variant="outlined"
                            size='small'
                            sx={{ height: '2vw', textTransform: 'capitalize', width: '8vw' }}
                            onClick={() => {
                                this.setState({
                                    StepNumber: 0
                                })
                            }}>Back</Button>
                        <Button variant="contained" size='small' sx={{ height: '2vw', textTransform: 'capitalize', width: '12vw' }}
                            // disabled={this.state.isSample}
                            onClick={() => {
                                this.postConsumables()
                            }}>Save</Button>
                    </Stack>
                </Box>
            </Box>
        )
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    testDuplicate = () => {
        let states = this.state
        const testIdSet = new Set();
        let hasDuplicate = false;

        states.newSampleCollected?.forEach(sample => {
            sample.test_ids?.forEach(testId => {
                if (testIdSet.has(testId.id)) {
                    hasDuplicate = true;
                } else {
                    testIdSet.add(testId.id);
                }
            });
        });

        return hasDuplicate;
    }


    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            label={"Patient name/UHID"}
                            size="small"
                            value={this.state.patientSearch}
                            onChange={(e) => { this.setState({ patientSearch: e.target.value.trimStart() }) }}
                            sx={{ width: '21vw' }} />
                    </Box>

                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon={false}
                            options={this.state.departmentData}
                            getOptionLabel={(option) => option?.dept_name}
                            value={this.state.departmentName}
                            onChange={(e, newValue) => {
                                this.setState({ departmentName: newValue })
                            }}
                            sx={{ width: "21vw" }}
                            renderInput={(params) => (<TextField {...params} size='small' label='Select Department' />)}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon
                            options={this.state.groupData?.length > 0 ? this.state.groupData : []}
                            getOptionLabel={(option) => option?.name}
                            value={this.state.groupName ? this.state.groupName : null}
                            onChange={(e, newValue) => {
                                this.setState({ groupName: newValue }, () => {
                                    let { groupName } = this.state;
                                    if (groupName) {
                                        this.setState({ testNameData: groupName?.grp_test_data?.length > 0 ? groupName.grp_test_data : [] });
                                    }

                                })
                            }}
                            sx={{ width: '21vw' }}
                            renderInput={(params) => (<TextField {...params} label='Select Group Name' />)}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon
                            options={this.state.testNameData?.length > 0 ? this.state.testNameData : []}
                            getOptionLabel={(option) => option?.test_name}
                            value={this.state?.testName ? this.state?.testName : null}
                            onChange={(e, newValue) => {
                                this.setState({ testName: newValue })
                            }}
                            sx={{ width: '21vw' }}
                            renderInput={(params) => (<TextField {...params} label='Select Test Name' />)}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                patientSearch: "",
                                groupData: [],
                                testNameData: [],
                                departmentName: null,
                                groupName: null,
                                testName: null,
                            },
                                () => this.getStatusSummaryList())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        onClick={() => {
                            this.getStatusSummaryList()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    render() {
        const { t } = this.props
        var { history } = this.props
        var { selectedTableRow, selectConsumables, outSourceOptionList } = this.state
        // const columns = [
        //     {
        //       field: 'S.No', headerName: 'S.No', sortable: false, flex: 0.05,
        //       renderCell: (index) => index.api.getRowIndex(index.row.id) + 1
        //     },
        //     { field: 'patient_name', headerName: t('PatientName'), sortable: false, flex: 0.2, 
        //     renderCell: (cellvalues) => ( 
        //         <Box>
        //             {cellvalues?.row?.patient_name ? 
        //             cellvalues?.row?.patient_name?.length > 10 ? 
        //             <Tooltip placement='top' title={cellvalues?.row?.patient_name}>
        //                 <div>{`${cellvalues?.row?.patient_name.slice(0,10)}... (${cellvalues.row.patient__gender == "m" ? "M" : cellvalues.row.patient__gender == "f" ? "F" : cellvalues.row.patient__gender == "t" ? "T" : null }) (${cellvalues.row.patient_age ? `${cellvalues.row.patient_age}` : "-"})`}</div>
        //             </Tooltip> :
        //             `${cellvalues?.row?.patient_name} (${cellvalues.row.patient__gender == "m" ? "M" : cellvalues.row.patient__gender == "f" ? "F" : cellvalues.row.patient__gender == "t" ? "T" : null }) (${cellvalues.row.patient_age ? `${cellvalues.row.patient_age}` : "-"})` : "-"
        //         }
        //         <div style={{display: 'flex', flexDirection: 'row'}}>
        //         <Typography id='eMed_Com_Patient_UhidText'>{(cellvalues?.row?.patient__patient_account_number) + ' | ' }</Typography>
        //             {cellvalues?.row?.ip_number?.length > 15 ?
        //                 <Tooltip title={cellvalues?.row?.ip_number} placement={'top'} arrow>
        //                     <Typography id='eMed_Com_Patient_UhidText'>{cellvalues?.row?.ip_number ? '...' + cellvalues?.row?.ip_number.slice(14) : '-'}</Typography>
        //                 </Tooltip> : <Typography id='eMed_Com_Patient_UhidText'>{cellvalues?.row?.ip_number ? cellvalues?.row?.ip_number : cellvalues?.row?.appointment_id ? cellvalues?.row?.appointment_id : '-'}</Typography>}
        //         </div>
        //         </Box>
        //      )},
        //     { field: 'patient__mobile_number', headerName: t('MobileNumber'), sortable: false, flex: 0.15,
        //     renderCell:(params)=>(<div>{params?.row?.patient__mobile_number ? params?.row?.patient__mobile_number : "-"}</div>) },
        //     { field: 'invoice_number', headerName: t('BillNo'), sortable: false, flex: 0.15, headerAlign: "center", align: "center",
        //     renderCell:(params)=>(<div>{params?.row?.invoice_number ? params?.row?.invoice_number : params?.row?.temp_bill_number ? params?.row?.temp_bill_number : "-"}</div>) },
        //     { field: 'created_date__date', headerName: t('Date'), sortable: false, flex: 0.15, headerAlign: "center", align: "center",
        //       renderCell: (cellvalues) => {
        //          var date = this.dateConvertion(cellvalues.row.created_date__date)
        //          return(
        //             <p>{date ? date : "-"}</p>
        //          )
        //       } 

        //     },
        //     { field: 'barcode', headerName: "Barcode Number", sortable: false, flex: 0.2 ,headerAlign: "center", align: "center",
        //     renderCell:(params)=>(<div >{params?.row?.barcode ? params?.row?.barcode : "-"}</div>)},
        //     { field: 'status', headerName: t('ResultStatus'), sortable: false, flex: 0.2 ,headerAlign: "center", align: "center",
        //     renderCell:(params)=>(<div className={params.row.bill_type == "cancelled" ? "eMed_status_pending" : ""} >{params?.row?.status ? params?.row?.status : "-"}</div>)},
        //     { field: '', headerName: 'Action', sortable: false, flex: 0.15, headerAlign: "center", align: "center",
        //     renderCell: (cellvalues) => {
        //         return (
        //             <Stack direction="row" alignItems={'center'}>
        //                     {cellvalues.row.status != "Specimen Collected" ?
        //                       <Tooltip placement="top" title={"Specimen"} arrow>
        //                         <IconButton className='eMed_sts_spc'
        //                         disabled={!CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess}
        //                             onClick={() => {
        //                               if(cellvalues.row.bill_type == "cancelled") {

        //                               } else { 
        //                                 this.setState({
        //                                     isSpecimanClick: true,
        //                                     selectedTableRow: cellvalues.row
        //                                 }, () => {
        //                                     var data = cellvalues.row
        //                                     this.getSpecimanCollectedValue(data.id)
        //                                 })
        //                               }
        //                             }}

        //                         ><img src={ImagePaths.Speciman.default} className="labImageStyle" alt='specimen'/></IconButton>
        //                        </Tooltip> 
        //                      :
        //                      <Tooltip placement="top" title={"Specimen"} arrow>
        //                         <IconButton className='eMed_sts_spc'
        //                          disabled={!CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess}
        //                          onClick={() => {
        //                             if(cellvalues.row.bill_type == "cancelled") {

        //                             } else {  
        //                              this.setState({
        //                                 isSpecimanClick: true,
        //                                 selectedTableRow: cellvalues.row,
        //                                 isSpecimanComplete: true
        //                              }, () => {
        //                                 var data = cellvalues.row
        //                                 this.getSpecimanCollectedValue(data.id)
        //                              })
        //                             } 
        //                          }}
        //                         ><img src={ImagePaths.ResultDisable.default} className="labImageStyle" alt='disable'/></IconButton>
        //                       </Tooltip>  
        //                     }
        //                     <Tooltip placement="top" title={"Result"} arrow>
        //                     <IconButton className='eMed_cursor'
        //                      sx={{marginTop:"0.3vw"}}
        //                      disabled={!CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess}
        //                     onClick={() => { 
        //                             if(cellvalues.row.status != "Specimen Collected") {
        //                               if(cellvalues.row.bill_type != "cancelled") {   
        //                                this.setState({
        //                                    isErrorMsg: true,
        //                                    isErrorMsgText: "Kindly collect the specimen before entering the result"
        //                                })
        //                               }
        //                             } else {
        //                                 this.props.selectedTab() 
        //                             }
        //                         }}>
        //                             {cellvalues.row.status == "Specimen Collected" ?
        //                             <Link to={{ pathname: "/Results",  state: { resultList: cellvalues.row } }}>
        //                                 <div><img src={ImagePaths.Result.default} className="labImageStyle" alt='result'></img></div>
        //                             </Link> : <div><img src={ImagePaths.Result.default} className="labImageStyle" alt='result'></img></div>
        //                             }
        //                     </IconButton> 
        //                     </Tooltip>    
        //                    <div>
        //                     <IconButton
        //                         disabled = { CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess ? cellvalues.row.bill_type == "cancelled" ? true : false : true}
        //                         aria-label="more"
        //                         id="long-button"
        //                         aria-controls={cellvalues.is_multi_option}
        //                         aria-expanded={cellvalues.is_multi_option}
        //                         aria-haspopup="true"
        //                         onClick={(e) => {
        //                             this.setState({
        //                                 anchorEl: e.currentTarget,
        //                                 billSummaryID: cellvalues.row.lab_bill_summary,
        //                                 selectedTableRow: cellvalues.row,
        //                                 isSelected: true
        //                             }, () => {
        //                                 this.getPreviousReport()
        //                                 this.getReceivePaymentAmountData(cellvalues)
        //                             })

        //                         }}
        //                     >
        //                         <MoreVertIcon />
        //                     </IconButton>
        //                     {(this.state.isSelected && this.state.selectedTableRow.id ===  cellvalues.row.id) ?
        //                     <Menu
        //                         id="long-menu"
        //                         MenuListProps={{
        //                             'aria-labelledby': 'long-button',
        //                         }}

        //                         anchorEl={this.state.anchorEl}
        //                         open={Boolean(this.state.anchorEl)}
        //                         onClose={(e) => {
        //                             this.setState({
        //                                 anchorEl: null
        //                             })
        //                         }}
        //                         PaperProps={{
        //                             style: {
        //                                 maxHeight: ITEM_HEIGHT * 4.5,
        //                                 width: '20ch',
        //                             },
        //                         }}
        //                     >
        //                         {multi_option.map((option) => (
        //                             <MenuItem disabled={((this.state.amountData?.total_balance_amount === 0 && option.value == "Receive Payment") || (selectedTableRow.bill_type == "saved" && option.value == "Cancel Bill") || selectedTableRow.status == "Specimen Collected" ) || ((selectedTableRow.bill_type == "regular" && option.value == "Delete Bill") || selectedTableRow.status == "Specimen Collected") || (selectedTableRow.bill_type == "cancelled") || (selectedTableRow.bill_type == "credit" && option.value == "Delete Bill") ? true : false} key={option} selected={option === 'Pyxis'} onClick={() => {
        //                                 this.setState({
        //                                     selectedOption: option.label
        //                                 }, () => {
        //                                     if (this.state.selectedOption == "ReceivePayment") {
        //                                         // this.props.selectedTab(0, this.state.selectedTableRow, "isReceivePayment", false)
        //                                         this.setState({ SelectedBill: this.state.selectedTableRow ,CreditBillPopUp:true})
        //                                     }
        //                                     if (this.state.selectedOption == "ViewPreviousReport") {
        //                                         this.setState({
        //                                             isViewReport: true
        //                                         })
        //                                     }
        //                                     if(this.state.selectedOption == "BarCode"){
        //                                         this.setState({
        //                                             SelectedBill: this.state.selectedTableRow
        //                                         },() => this.getCommonBarCodePrint(cellvalues))
        //                                     }
        //                                     if (this.state.selectedOption == "Cancel Bill") {
        //                                         let Data = {
        //                                             id: this.state.selectedTableRow?.lab_bill_summary,
        //                                             patient_id: this.state.selectedTableRow?.patient,
        //                                             laboratory_id: null,
        //                                             amount_net: this.state.selectedTableRow?.amount_net,
        //                                             appointment_id: this.state.selectedTableRow?.appointment_id,
        //                                             ip_admission_id: this.state.selectedTableRow?.ip_admission_id
        //                                         }

        //                                         this.props.cancelBillClick(Data)
        //                                     }
        //                                     if(this.state.selectedOption == "Delete Bill") {
        //                                         this.onDeleteBill()
        //                                     }    
        //                                 })
        //                             }}>
        //                                 {t(option.label)}
        //                             </MenuItem>
        //                         ))
        //                         }
        //                     </Menu>
        //                     :null}
        //                 </div>
        //             </Stack>
        //         )
        //     }
        //     }

        //   ]
        return (
            <div className='Lab_Status_Container'>
                <div className='lab_status_sub_header'>
                    <div className='eMed_sts_date'>
                        <Box className="eMed_Search_Box_">
                            <TextField
                                variant='standard'
                                placeholder='Search'
                                emed_tid = "Search_Test_ID"
                                value={this.state.TableSearchText}
                                onChange={(e) => { this.setState({ TableSearchText: e.target.value }) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start"   emed_tid = "SearchIcon_Test_ID01">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ width: '15vw' }} />
                        </Box>
                        <div>
                        <FormControl >
                            <InputLabel>{t("SelectDate")}</InputLabel>
                            <Select sx={{ width: "10vw" }}
                                emed_tid = "SelectDate_TestID"
                                size='small'
                                onChange={(event) => { this.setState({ dateFilter: event.target.value }, () => this.handleDate()) }}
                                value={this.state.dateFilter}
                                labelId="demo-simple-select-required-label"
                                label={t("SelectDate")}
                            >
                                {/* <MenuItem value={0}>{t("All")}</MenuItem> */}
                                <MenuItem value={1}>{t("Today")}</MenuItem>
                                <MenuItem value={2}>{t("Yesterday")}</MenuItem>
                                <MenuItem value={3}>{t("LastWeek")}</MenuItem>
                                <MenuItem value={4}>{t("LastMonth")}</MenuItem>
                                <MenuItem value={5} onClick={() => { this.setState({ datePickerPop: true }) }} >{t("Custom")}</MenuItem>
                            </Select>
                            <FormHelperText id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
                        </FormControl>
                        <IconButton size= "small" sx={{marginLeft: "0.3vw"}} onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img'  />
                        </IconButton>
                        </div>
                    </div>
                    {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}
                    {/* <button className= "lab_status_printBtn" ><img className="Lab_Status_Subcontainer_Img"src={ImagePaths.PrintOff.default} alt={"print"}
                     onClick={() => {
                        this.testReportPrint()
                     }} 
                    /></button>
                    <button className= "lab_status_printBtn" ><img className="Lab_Status_Subcontainer_Img"src={ImagePaths.ExportOff.default} alt={"export"} /></button> */}
                </div>
                <div className='labDataGrid'>
                    {/* <DataGrid
                        rows={this.state.statusSummaryList}
                        columns={columns}
                        hideFooterSelectedRowCount
                        getRowId={(row) => row.id}
                        // getRowClassName={(params) =>  params.row.lab_bill_summary__status == "Cancelled" ? 'Lab_report_hightlight' : null}
                        pageSize={this.state.pageSize}
                        onPageSizeChange={(newPageSize) => {
                            this.setState({ pageSize: newPageSize })
                        }}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        headerHeight={40}
                    /> */}
                    {this.renderStatusTable()}
                    {this.state.selectedOption == "Cancel Bill" ?
                        <Dialog
                            className="emed_DialogboxOrder"
                            open={this.state.isCancelBill}
                            onClose={() => {
                                this.setState({
                                    isCancelBill: false,
                                    reason: ""
                                })
                            }}
                            maxWidth={"md"}
                        >

                            <div className='Lab_cancel_bill'>
                                <div className='Lab_Status_Close_Button'>
                                    <IconButton size='small'
                                     emed_tid = "Lab_Status_Close_Button_TestID"
                                        onClick={() => {
                                            this.setState({
                                                isCancelBill: false,
                                                reason: ""

                                            })
                                        }}
                                    > <img width="2vw" height="2vw" src={ImagePaths.Close.default} className="labCancelStyle" alt='close'></img></IconButton>
                                </div>
                                <h1>{t("Conformation")}</h1>
                                <Typography>{t("ToRemoveBill")}</Typography>
                                <TextField id="outlined-basic" sx={{ height: "10vh", width: "56.5vw" }} inputProps={{
                                    style: {
                                        height: "5vh"
                                    },emed_tid :"ToRemoveBill_Test_Id"
                                }} value={this.state.reason} placeholder='Enter the reason' variant="outlined" className='CancelBillComment' onChange={(e) => {
                                    this.setState({
                                        reason: e.target.value
                                    })
                                }} />
                                {/* <h1>{"Refund"}</h1>
                            <div className="Lab_Cancel_Bill_Radio">
                                <p className="Lab_Status_Radio_Text">{t("ModeOfPayment")}</p>    
                                <div className="Lab_Home_Radio_Button1">
                                    <RadioGroup
                                        row
                                        value={this.state.selectedPaymentType}
                                        onClick={(e) => {
                                           
                                        }}
                                    >
                                        <FormControlLabel value={1} control={<Radio size="small" />} label={t("Cash")} />
                                        <FormControlLabel value={2} control={<Radio size="small" />} label={t("Card")} />
                                        <FormControlLabel value={3} control={<Radio size="small" />} label={t("CashandCard")} />
                                        <FormControlLabel value={4}control={<Radio size="small" />} label={t("UPI")} />
                                        <FormControlLabel value={5} control={<Radio size="small" />} label={t("BankTransfer")} />
                                        <FormControlLabel value={6} control={<Radio size="small" />} label={t("Insurance")} />
                                    </RadioGroup>
                                </div>
                            </div> */}
                                <div className="Lab_Cancel_Popup">
                                    <div>
                                        <p className="emedhub_popup_footer_Text emedhub_popup_footer_Text2">{t("CancelHelpMessage1")}</p>
                                        <p className="emedhub_popup_footer_Text emedhub_popup_footer_Text2">{t("CancelHelpMessage2")}</p>
                                    </div>
                                    <Button emed_tid = "Lab_Cancel_Popup_Test_Id" size="small" sx={{ backgroundColor: "red" }} variant={this.props.buttonTwo ? "outlined" : "contained"} onClick={() => { this.onCancelPost() }}>{"Cancel Bill"}</Button>

                                </div>
                            </div>
                        </Dialog> : null}
                </div>
                {this.state.isSpecimanClick ?
                    <Dialog
                        className="emed_DialogboxOrder"
                        open={this.state.isSpecimanClick}
                        // onClose={this.specimanClose}
                        maxWidth={"100vw"}
                    >
                        {this.renderSpecimanCollection()}
                    </Dialog> : null
                }
                <div>
                    {this.state.selectedOption == "ViewPreviousReport" ?
                        < Dialog
                            className="emed_DialogboxOrder"
                            open={this.state.isViewReport}
                            onClose={() => {
                                this.setState({
                                    isViewReport: false
                                })
                            }}
                            maxWidth={"md"}
                        >
                            <div className='PreviousReportPopUp'>
                                <div className='sample_collect_popup'>
                                    <p>{t("PreviousReport")}</p>
                                    <Button variant="text" emed_tid = "selectedOption_Test_Id" onClick={() => {
                                        this.setState({
                                            selectedOption: "",
                                            isViewReport: false
                                        })
                                    }}>X</Button>
                                </div>
                                <TableContainer className='Lab_Previous_Report_Con' component={Paper}>
                                    <Table stickyHeader size="small">
                                        <TableHead id=' ' sx={{ height: "4vh" }} >
                                            <TableRow>
                                                <TableCell id="lab_home_table_Head" sx={{ flex: 0.4 }} align="left" >{t("InvoiceNumber")}</TableCell>
                                                <TableCell id="lab_home_table_Head" sx={{ flex: 0.3 }} align="left" >{t("Invoice Date")}</TableCell>
                                                <TableCell id="lab_home_table_Head" align="left" sx={{ flex: 0.3 }}> {t("Action")} </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="Lab_Status_Table_body" sx={{ height: "7vh" }}>
                                            {this.state.preReportList && this.state.preReportList.length > 0 ?
                                                this.state.preReportList.map((item, index) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell sx={{ flex: 0.4 }} align="left">{item.bill_number}</TableCell>
                                                            <TableCell align="left" sx={{ flex: 0.3 }} >{item.bill_date}</TableCell>
                                                            <TableCell align="left" sx={{ flex: 0.3 }}>
                                                                <IconButton size='small' emed_tid = "individualReportPrint_Test_Id"
                                                                    onClick={() => {
                                                                        this.individualReportPrint(item.id)
                                                                    }}
                                                                > <img width="30vw" height="30vh" src={ImagePaths.PrintIcons.default} className="labImageStyle" alt='print'></img></IconButton>
                                                            </TableCell>

                                                        </TableRow>)
                                                }) :
                                                <TableRow>
                                                    <TableCell align="center">{t("NoRecordsFound")}</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Dialog> : null
                    }
                </div>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : this.state.isSuccessMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.isSuccessMsgText}
                            msgPop={this.msgClose.bind(this)}
                        /> : null
                }
                {
                    this.state.CreditBillPopUp ?
                        <CreditBillCompletePoP
                            modules={['lab']}
                            Module_ID_Key_Name={"bill_id"}
                            Module_ID_Key_Value={this.state.SelectedBill?.lab_bill_summary_id}
                            POST_Module_ID_Key_Name={this.state.SelectedBill?.ip_admission_id ? "ip_admission_id" : this.state.SelectedBill?.appointment_id ? "appointment_id" : "bill_id"}
                            POST_Module_ID_Key_Value={this.state.SelectedBill?.ip_admission_id ? this.state.SelectedBill?.ip_admission_id : this.state.SelectedBill?.appointment_id ? this.state.SelectedBill?.appointment_id : this.state.SelectedBill?.lab_bill_summary_id}
                            BillData={this.state.SelectedBill}
                            CrebillPosted={this.CrebillPosted.bind(this)}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            CollectedIn={"LAB"}
                        /> : null}
                {
                    Object.keys(this.state.BillModifyHistoryItem).length > 0 ? <EditHistoryPopup BillType={"LAB"} BillId={this.state.BillModifyHistoryItem?.lab_bill_summary_id} SelectedBill={this.state.BillModifyHistoryItem} HistoryPopClose={this.HistoryPopClose.bind(this)} sendClinicId={true} /> : null
                }
                {this.state.receiptID || this.state.pharmareceipt ?
                    <BillSuccessPoPUp creditreceipt={this.state.receiptID} phrma_creditreceipt={this.state.pharmareceipt} ClosePopUp={this.ClosesuccessPopUp.bind(this)} /> : null}
                    <Loader loaderOpen={this.state.isLoader}/>
            </div>
        );
    }
    specimanClose = () => {
        this.setState({
            isSpecimanClick: false,
            resetSourceOptionList: []
        })
    }
    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: +event.target.value,
            page: 0
        });
    };
}
export default withTranslation()(Status);