import React, { Component } from 'react'
import '../Configuration.css';
import {
  Box, Grid, Paper, InputLabel, MenuItem, FormControl, Select, TextField, Button,
  Typography, FormControlLabel, Checkbox, Tooltip, IconButton, InputAdornment,
  Autocomplete, List, ListItem, ListItemButton, ListItemText, Divider, Dialog, DialogTitle,
  DialogContent, DialogActions
} from '@mui/material'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { Stack } from '@mui/system';
import { withTranslation } from 'react-i18next';
import { Colors } from '../../../Styles/Colors';
import { Surgey_Notes_Layout_JSON, Speciality_Item } from '../../../Utility/Constants'
import { ImagePaths } from '../../../Utility/ImagePaths';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { ConfirmPopup } from '../../../Components/Common Components/CommonComponents';
import { ReasonPopup } from '../../../Components/Common Components/CommonComponents';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../Components/Loader';

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '15vw',
    },
  },
};
class SurgeryNotesLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      surgerNotesField: Surgey_Notes_Layout_JSON,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      selectTemplate: '',
      selectSpeciality: null,
      dragLabel: null,
      addFieldName: '',
      addLayoutName: '',
      surgeryNotesData: [],
      selectedID: null,
      alertPop: false,
      splItem: Speciality_Item,
      Open: true,
      AddnewLayType: false,
      editLayName: false,
      layoutFinData: [],
      deleteLayout: false,
      renameLayout: false,
    }
  }
  componentDidMount() {
    this.getSurgeyLayout()
    this.resetSurgeryLayout()
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getSurgeyLayout = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.SUR_NOTES_LAYOUT)
        .then((response => {
          if (response.data.status === 'success') {
            this.setState({
              surgeryNotesData: response.data.data ? response.data.data : []
            },()=>{this.LoaderFunction(false)})
          }
        })).catch(e => {
          this.LoaderFunction(false)
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })

    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)

    }
  }
  postSurgeryLayout = () => {
    try {
      var states = this.state
      var data = {
        "speciality_name": states.selectSpeciality.value,
        "layout_name": states.addLayoutName,
        "surgery_json": states.surgerNotesField,
      }
      if (states.selectTemplate) {
        data['surgery_id'] = states.selectTemplate
      }
      this.setState({
        alertPop: false,
      })
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.SUR_NOTES_LAYOUT)
        .then((response => {
          if (response.data.status === 'success') {
            this.getSurgeyLayout()
            this.resetSurgeryLayout()
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
          }
        })).catch((e => {
          if (e?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(e.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
          }

        }))
    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  deleteSurLay = (cmt) => {
    try {
      var data = {
        'id': this.state.selectedID,
        'cancel_reason': cmt
      }
      RestAPIService.delete(Serviceurls.SUR_NOTES_LAYOUT, data)
        .then((response => {
          if (response.data.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getSurgeyLayout()
            this.resetSurgeryLayout()
            this.setState({
              deleteLayout: false
            })
          }
        })).catch(e => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })

    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }
  renderSurNotesLayoutLeft = () => {
    try {
      const { t } = this.props
      var states = this.state
      var Active_Array = (states.surgerNotesField).filter((item) => {
        return item.is_active == true
      })
      return (
        <Stack component={'div'}>
          <Paper className='eMed_refdoc_left'>
            <Box className='eMed_DisSum_Lay_LeftHead' sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
              <Typography>{t('SurgeryNotes') + (states.selectSpeciality ? ' (' + this.state.selectSpeciality.value + ')' : '(-)')}</Typography>
            </Box>
            <Box className='eMed_DisSum_Lay_leftCon'>
              {Active_Array.map((item) => {
                if (item.label === 'Reason for Hospital Stay') {
                  return (
                    this.renderTextBox('Reason for Hospital Stay', '57.6vw', true, item))
                } else if (item.label === 'Patient Admits to Having') {
                  return (
                    this.renderTextBox('Patient Admits to Having', '57.6vw', true, item))
                } else if (item.label === 'Patient Denies Having') {
                  return (
                    this.renderTextBox('Patient Denies Having', '57.6vw', true, item))
                } else if (item.label === 'Preop Diagnosis') {
                  return (
                    this.renderTextBox('Preop Diagnosis', '57.6vw', true, item)
                  )
                } else if (item.label === 'Postop Diagnosis') {
                  return (
                    this.renderTextBox('Postop Diagnosis', '57.6vw', true, item)
                  )
                } else if (item.label === 'Procedure') {
                  return (
                    this.renderTextBox('Procedure', '57.6vw', true, item)
                  )
                } else if (item.label === 'Surgeon') {
                  return (
                    this.renderTextBox('Surgeon', '57.6vw', true, item)
                  )
                } else if (item.label === 'Staff Nurse') {
                  return (
                    this.renderTextBox('Staff Nurse', '57.6vw', true, item)
                  )
                } else if (item.label === 'Assistant(s)') {
                  return (
                    this.renderTextBox('Assistant(s)', '57.6vw', true, item)
                  )
                } else if (item.label === 'Type of Anesthesia') {
                  return (
                    this.renderTextBox('Type of Anesthesia', '57.6vw', true, item)
                  )
                } else if (item.label === 'Fluids Given') {
                  return (
                    this.renderTextBox('Fluids Given', '57.6vw', true, item)
                  )
                } else if (item.label === 'Urine Output') {
                  return (
                    this.renderTextBox('Urine Output', '57.6vw', true, item)
                  )
                } else if (item.label === 'Estimated Blood Loss(EBL)') {
                  return (
                    this.renderTextBox('Estimated Blood Loss(EBL)', '57.6vw', true, item)
                  )
                } else if (item.label === 'Specimen') {
                  return (
                    this.renderTextBox('Specimen', '57.6vw', true, item)
                  )
                } else if (item.label === 'Drains') {
                  return (
                    this.renderTextBox('Drains', '57.6vw', true, item)
                  )
                } else if (item.label === 'Plan') {
                  return (
                    this.renderTextBox('Plan', '57.6vw', true, item)
                  )
                } else if (item.label === 'Date/Time, Name of Doctor, Signature') {
                  return (
                    <Box className='eMed_DisSum_Lay_Dietcon'>
                      {this.renderDoctorTextBox('Date/Time', '18vw', item, ImagePaths.Clander.default)}
                      {this.renderDoctorTextBox('Name of Doctor', '18vw', item)}
                      {this.renderDoctorTextBox('Signature', '18vw', item)}
                    </Box>
                  )
                } else {
                  return (
                    this.renderTextBox(item.label, '57.6vw', true, item)
                  )
                }
              })}
            </Box>
          </Paper>
        </Stack>
      )
    } catch (e) {
      this.errorMessage(e)
    }
  }

  renderTextBox = (label, width, isrow, item) => {
    return (
      <Box className='eMed_DisSum_Lay_CommonBox1'>
        <TextField
          sx={{ width: width }}
          id="icdcode"
          multiline={isrow}
          rows={3}
          label={item.is_default_mantatory ? label + '*' : label}
          defaultValue={' '}
          variant="outlined"
          InputProps={{
            readOnly: true 
          }}
          inputProps={{ emed_tid: `cb_${label}` }}
           />
      </Box>
    )
  }
  renderDoctorTextBox = (label, width, item, img) => {
    return (
      <Box className='eMed_DisSum_Lay_CommonBox1'>
        <TextField
          size='small'
          sx={{ width: width }}
          id="icdode"
          label={item.is_default_mantatory ? label + '*' : label}
          defaultValue={' '}
          variant="outlined"
          InputProps={{
            readOnly: true, emed_tid : label,
            endAdornment: label === "Date/Time" ? <InputAdornment><img src={img} alt='date' /></InputAdornment> : ''
          }} 
          inputProps={{ emed_tid: `cb_${label}` }}
          />
      </Box>
    )
  }

  renderSurNotesLayoutRight = () => {
    try {
      var states = this.state
      const { t } = this.props
      return (
        <Stack component={'div'}>
          <Paper className='eMed_refdoc_right'>
            <Box className='eMed_DisSum_Lay_Select'>
              <Typography paddingLeft={'1vw'}>{t('SurgeryNotes')}</Typography>
            </Box>
            <Box className='eMed_Dissum_lay_Con'>
              <Box className='eMed_DisSum_Lay_Spec'>
                <Autocomplete
                  noOptionsText={states.specTxt === '' ? t('PLS') : t('NDF')}
                  options={states.splItem}
                  // defaultValue={states.splItem.options[0]}
                  getOptionLabel={(options) => options.label}
                  onChange={(e, value) => {
                    this.setState({
                      selectSpeciality: value,
                      editLayName: false
                    }, () => {
                      var states = this.state
                      var final = states.surgeryNotesData ? states.surgeryNotesData.filter((item) => (
                        states.selectSpeciality?.value === item.speciality_name
                      )) : null
                      this.setState({ layoutFinData: final })
                    })
                  }}
                  value={states.selectSpeciality}
                  size='small'
                  className='eMed_DisSum_Lay_select2'
                  disablePortal
                  id="Spec"
                  renderInput={(params) => <TextField onChange={(event) => {
                    const value = event.target.value
                    if (value === "" || value != "") { this.setState({ specTxt: value }) }
                  }} {...params} label={t('Speciality')}  inputProps={{...params.inputProps,'emed_tid': "specTxt_TestID001", }}/>}
                />
                <FormControl size="small" className='eMed_DisSum_TxtBox'>
                  <InputLabel id="dis_sum_Layout">{t('LayoutName')}</InputLabel>
                  <Select
                    labelId="dis_sum_Layout"
                    inputProps={{ emed_tid: `cb_${"SelectLayout_TestID"}` }}
                    id="demo-simple-select"
                    value={states.selectTemplate}
                    label={t('SelectLayout')}
                    onChange={this.handleTemplate}
                    MenuProps={MenuProps}
                  >
                    {states.layoutFinData?.length > 0 ? states.layoutFinData.map((item) => (
                      <MenuItem value={item.id}>{item.layout_name}</MenuItem>
                    )) : <Typography align='center'>{t('NDF')}</Typography>}
                    <Divider />
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton inputProps={{ emed_tid: `cb_${"PSPL01_TestID"}` }}  onClick={() => {
                          var newData = states.surgeryNotesData.find((item) => item.id === 0)
                          if (newData) {
                            this.errorMessage(t('PSPL'))
                          } else {
                            this.setState({ AddnewLayType: true, addLayoutName: '', renameLayout: false, selectedID: null })
                          }
                        }
                        }>
                          <ListItemText primary={t('AddNewLN')} />
                        </ListItemButton>
                      </ListItem>
                      {this.state.editLayName ?
                        <List>
                          <ListItem disablePadding>
                            <ListItemButton inputProps={{ emed_tid: `cb_${"Rename01_TestID"}` }}   onClick={() => { this.setState({ AddnewLayType: true, renameLayout: true }) }}>
                              <ListItemText primary="Rename" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemButton inputProps={{ emed_tid: `cb_${"Delete01_TestID"}` }}    onClick={() => { this.setState({ deleteLayout: true }) }}>
                              <ListItemText primary="Delete" />
                            </ListItemButton>
                          </ListItem>
                        </List> : null}
                    </List>
                  </Select>
                </FormControl>
              </Box>
              <Box className='eMed_DisSum_Lay_right_Head'>
                <Box>
                  <Typography id='eMed_DisSum_Lay_right_HeadTxt'>{t('SurgeryNotesFields')}</Typography>
                  <Typography id='eMed_DisSum_Lay_right_HeadTxt2'>(Switch the fields according to your practice)</Typography>
                </Box>
                <Typography id='eMed_DisSum_Lay_right_HeadTxt2'>Mark as Important</Typography>
              </Box>
              <Box className='eMed_DisSum_Lay_right_Feilds'>
                {states.surgerNotesField.map((item, index) => (
                  this.rendersurgeryNotesFields(item, index)
                ))}
                <Box className='eMed_DisSum_AddIcon'>
                  <TextField
                    className='eMed_DisSum_TxtBox'
                    size='small'
                    id="outlined-bsic"
                    variant="outlined"
                    inputProps={{ emed_tid: "addFieldName_TestID" }}
                    value={states.addFieldName}
                    onChange={(event) => {
                      let alpha = CommonValidation.alphabetOnly(event.target.value);
                      if (alpha || event.target.value === '') {
                        this.setState({
                          addFieldName: event.target.value
                        })
                      }
                    }} />
                  <IconButton emed_tid ={"addSurgeryFields_testID"}>
                    <img className='eMed_DisSum_Lay_TblDel' onClick={() => { this.addSurgeryFields() }} src={ImagePaths.AddIcon.default} alt='Add'></img>
                  </IconButton>
                </Box>
              </Box>

            </Box>
            <Stack className='eMed_DisSum_right_Btn' spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
              <Button  emed_tid ={"resetSurgeryLayout_testID01"} variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.resetSurgeryLayout() }}>{t("Reset")}</Button>
              <Button emed_tid ={"SaveSurgeryLayout_testID01"} variant='contained' size="small" id="eMed_Config_btn" disabled={CheckAccessFunc("front_office", "Settings", "Layout Configuration", "Surgery Notes Layout", "SubTab")?.editAccess ? (states.selectSpeciality && (states.selectTemplate || states.selectTemplate === 0) ? false : true) : true} onClick={() => { this.addSurgeryNoteLayout() }}>{t("Save")}</Button>
            </Stack>
          </Paper>

        </Stack>
      )
    } catch (e) {
      this.errorMessage(e)
    }
  }
  resetSurgeryLayout = () => {
    var Data = [
      { id: 1, field_name: 'reason_for_hospital_stay', label: 'Reason for Hospital Stay', is_active: true, is_mantatory: true, is_default_mantatory: true, is_new: false },
      { id: 2, field_name: 'patient_admits_to_having', label: 'Patient Admits to Having', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 3, field_name: 'patient_denies_having', label: 'Patient Denies Having', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 4, field_name: 'preop_diagnosis', label: 'Preop Diagnosis', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 5, field_name: 'postop_diagnosis', label: 'Postop Diagnosis', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 6, field_name: 'procedure', label: 'Procedure', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 7, field_name: 'surgeon', label: 'Surgeon', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 8, field_name: 'staff_nurse', label: 'Staff Nurse', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 9, field_name: 'assistant', label: 'Assistant(s)', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 10, field_name: 'type_of_anaesthesia', label: 'Type of Anaesthesia', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 11, field_name: 'fluids_given', label: 'Fluids Given', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 12, field_name: 'urine_output', label: 'Urine Output', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 13, field_name: 'estimated_blood_loss', label: 'Estimated Blood Loss(EBL)', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 14, field_name: 'specimen', label: 'Specimen', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 15, field_name: 'drains', label: 'Drains', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 16, field_name: 'plan', label: 'Plan', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      { id: 17, field_name: 'date_time_name_of_doctor_signature', label: 'Date/Time, Name of Doctor, Signature', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false }
    ]
    this.setState({
      selectSpeciality: null,
      selectTemplate: '',
      addLayoutName: '',
      addFieldName: '',
      surgerNotesField: Data,
      selectedID: null,
    })
    this.getSurgeyLayout()
  }
  addSurgeryNoteLayout = () => {
    var states = this.state
    if (states.selectedID) {
      this.setState({
        alertPop: true,
        AddnewLayType: false,
      })
    } else if (states.selectSpeciality &&
      states.addLayoutName &&
      states.surgerNotesField) {
      this.postSurgeryLayout()
    } else if (states.selectSpeciality.value === '') {
      this.errorMessage('Please Select Speciality')
    } else {
      this.errorMessage('Please Add the Layout Name')
    }
  }
  addSurgeryFields = () => {
    var states = this.state
    if (states.addFieldName) {
      var search = states.surgerNotesField.find((item) => (
        item.label === states.addFieldName
      ))
      if (search) {

      } else {
        var ID = states.surgerNotesField.length
        var data = states.addFieldName.toLowerCase().replace(/\s+/g, '_')
        states['surgerNotesField'].push({
          'id': ID + 1,
          'field_name': data,
          'label': states.addFieldName,
          'is_active': true,
          'is_mantatory': false,
          'is_default_mantatory': false,
          'is_new': true

        })
        this.setState({
          states
        }, () => {
          this.setState({
            addFieldName: ''
          })
        })
      }
    } else {
      this.errorMessage('Please add Field Name')
    }
  }
  rendersurgeryNotesFields = (data, i) => {
    var states = this.state
    return (
      <Stack>
        <div
          draggable={true}
          onDragOver={(e) => e.preventDefault()}
          onDragStart={this.handleDrag}
          onDrop={this.handleDrop}
          id={data.label}
          className="eMed_demolayout_right2_field1">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img className="eMed_DisSum_Lay_DragImg" src={ImagePaths.DragIndicator.default} alt="Dot" />
            {data.label?.length > 25 ?
              <Tooltip title={data.is_default_mantatory ? data.label + '*' : data.label} placement='top' arrow>
                <FormControlLabel control={<Checkbox   inputProps={{ emed_tid: `cb_${data.label}` }} id={data.id} onChange={this.handleCheckClick} checked={data.is_active} />} label={data.label.slice(0, 25) + '...'} />
              </Tooltip> : <FormControlLabel control={<Checkbox  inputProps={{ emed_tid: `cb_${data.label}` }} id={data.id} onChange={this.handleCheckClick} checked={data.is_active} />} label={data.is_default_mantatory ? data.label + '*' : data.label} />
            }
          </div>
          <div>
            {data.is_new ?
              <IconButton emed_tid ={"surgerNotesField_TestID"} className='eMed_DisSum_Lay_StarBtn' onClick={() => {
                states.surgerNotesField.splice(i, 1)
                this.setState({
                  states
                })
              }}>
                <img className="eMed_DisSum_Lay_StarIcon" src={ImagePaths.Delete.default} alt="Delete" />
              </IconButton>
              : null}
            <IconButton emed_tid ={"Star_TestID"}  className='eMed_DisSum_Lay_StarBtn' onClick={() => { this.handleStarClick(data) }}>
              <img className="eMed_DisSum_Lay_StarIcon" src={data.is_mantatory ? ImagePaths.StarFill.default : ImagePaths.StarUnfill.default} alt="Star" />
            </IconButton>
          </div>

        </div>
      </Stack>
    )
  }

  handleDrag = (e) => {
    this.setState({
      dragLabel: e.currentTarget.id
    })
  }

  handleDrop = (e) => {
    var states = this.state
    const dragBox = (states.surgerNotesField).find((field) => field.label === states.dragLabel);
    const dropBox = (states.surgerNotesField).find((field) => field.label === e.currentTarget.id);

    const dragBoxID = dragBox.id;
    const dropBoxID = dropBox.id;

    const dragBoxField = dragBox.field_name;
    const dropBoxField = dropBox.field_name;

    const dragBoxCheck = dragBox.is_active;
    const dropBoxCheck = dropBox.is_active;

    const newBoxState = (states.surgerNotesField).map((field) => {
      if (field.label === states.dragLabel) {
        field.id = dropBoxID;
        field.field_name = dragBoxField;
        field.is_active = dragBoxCheck;
      }
      if (field.label === e.currentTarget.id) {
        field.id = dragBoxID;
        field.field_name = dropBoxField;
        field.is_active = dropBoxCheck;
      }
      return field;
    });

    const SortData = newBoxState.sort((a, b) => { return a.id - b.id })

    this.setState({
      surgerNotesField: SortData
    })
  }
  handleCheckClick = (e) => {
    var { surgerNotesField } = this.state

    const ID = e.target.id
    const Index = surgerNotesField.findIndex((data) => {
      return data.id == ID
    })

    if (surgerNotesField[Index].is_default_mantatory) {

    } else {
      surgerNotesField[Index].is_active = e.target.checked
    }

    this.setState({
      surgerNotesField
    })
  }
  handleStarClick = (item) => {
    var { surgerNotesField } = this.state
    const Index = surgerNotesField.findIndex((data) => {
      return data.id == item.id
    })
    if (surgerNotesField[Index].is_default_mantatory) {

    } else {
      surgerNotesField[Index].is_mantatory = !item.is_mantatory
    }

    this.setState({
      surgerNotesField
    })
  }
  handleTemplate = (event) => {
    this.setState({
      selectTemplate: event.target.value,
      editLayName: true,
    }, () => {
      var lay_data = this.state.surgeryNotesData.map((data) => {
        if (this.state.selectTemplate === data.id) {
          this.setState({
            addLayoutName: data.layout_name,
            surgerNotesField: data.surgery_json,
            selectedID: data.id,
          })
        }
        this.setState({
          lay_data
        })
      })
      if (this.state.layoutFinData.length > 0) {
        var layoutData = this.state.layoutFinData.map((data) => {
          if (this.state.selectTemplate === data.id) {
            this.setState({
              addLayoutName: data.layout_name,
              surgerNotesField: data.surgery_json,
              selectedID: data.id,
            })
          }
          this.setState({
            layoutData
          })
        })
      }
    })
  }
  handleSpeciality = (event) => {
    this.setState({
      selectSpeciality: event.target.value
    })
  }
  popClode = () => {
    this.resetSurgeryLayout()
    this.setState({
      alertPop: false
    })
  }

  confirmSave = () => {
    this.postSurgeryLayout()
  }

  renderLayAdd = () => {
    const { t } = this.props
    return (
      <Dialog open={this.state.Open} >
        <DialogTitle className='eMed_CreditPoP_TitleDiv' sx={{ borderColor: Colors.themeDark }}>
          <Typography marginLeft={"1vw"}>{t("AddLayName")}</Typography>
          <Button emed_tid ={"LabCloseButton_TestID"} onClick={() => { this.setState({ AddnewLayType: false }) }} >
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </DialogTitle>
        <DialogContent sx={{ padding: "2vw" }}>
          <TextField
            inputProps={{ maxLength: 30 , emed_tid :"LayoutName_TestID"}}
            sx={{ width: "20vw", marginTop: "2vw" }}
            size='small'
            label={t("LayoutName")}
            value={this.state.addLayoutName}
            onChange={(event) => {
              let alphanum = CommonValidation.alphanumValidation(event.target.value);
              if (alphanum || event.target.value === '') {
                this.setState({
                  addLayoutName: event.target.value
                })
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button  emed_tid ={"Clear_TestID"} variant='outlined' size='small' className='eMed_Filter_Btns' onClick={() => { this.setState({ addLayoutName: '' }) }}>{t("Clear")}</Button>
          <Button emed_tid ={"layoutFinData_TestID"}  variant='contained' size='small' className='eMed_Filter_Btns' onClick={() => {
            var states = this.state
            if (states.selectedID) {
              this.addSurgeryNoteLayout()
            } else if (states.addLayoutName != '') {
              states['layoutFinData'].push({
                "id": 0,
                "speciality_name": states.selectSpeciality?.value,
                "layout_name": states.addLayoutName,
                "surgery_json": states.surgerNotesField

              })
              this.setState({
                states,
                AddnewLayType: false,
              })
            } else {
              this.errorMessage(t('PALN'))
            }
          }}>{this.state.renameLayout ? t("Rename") : t("Add")}</Button>
        </DialogActions>
      </Dialog>
    )
  }
  popupClose() {
    this.setState({
      deleteLayout: false
    })
  }
  addCancelComments(comments) {
    this.deleteSurLay(comments)
  }
  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    const { t } = this.props
    try {
      return (
        <Box component={"div"} className='eMed_refdoc_container'>
          <Grid container spacing={2} className='eMed_refdoc_content' >
            <Grid item xs={8}>
              {this.renderSurNotesLayoutLeft()}
            </Grid>
            <Grid item xs={4}>
              {this.renderSurNotesLayoutRight()}
            </Grid>
            {this.state.AddnewLayType ? this.renderLayAdd() : null}
          </Grid>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.alertPop ?
            <ConfirmPopup
              cnfTitle={t('Confirmation')}
              cnfContent={t('Are you sure, Do you want to save the changes it will be affect the entire configuration')}
              cnfAlertPopupClose={this.popClode.bind(this)}
              cnfData={this.confirmSave.bind(this)}
            />
            : null}
          {this.state.deleteLayout ? <ReasonPopup
            title={t('ADDL')}
            AlertPopupClose={this.popupClose.bind(this)}
            label={t('ER')}
            btntext={t('Cancel')}
            btnvarient={'outlined'}
            btncolor={'error'}
            btntext1={t('Confirm')}
            DeleteNotify={t('ifDelete')}
            btnvarient1={'contained'}
            sendCmt={this.addCancelComments.bind(this)}
          /> : null}
          <Loader loaderOpen={this.state.isLoader} />
        </Box>
      )
    } catch (error) {
      this.errorMessage(error)
    }

  }
}

export default withTranslation()(SurgeryNotesLayout);