import React, { Component } from "react";
import './patientDetails.css';
import { Box, Paper, Button, Stack, Typography, Tooltip, Grid, FormControl, Select, MenuItem, } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonGridHeader, { CommonPatientDetails, CommonPatientCard } from "../../../../Components/Common Components/CommonComponents";
import { Colors } from "../../../../Styles/Colors";
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import { getCachevalue, localGetItem } from "../../../../Utility/Services/CacheProviderService";
import Loader from '../../../../Components/Loader';
class DoctorVisitReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visitReport: [],
            allDoctors: [],
            patientData: {},
            attenderData: {},
            fromDate: null,
            toDate: null,
            dateIndex: 0,
            doctor_id: null,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: "",
            clinic_id: null,
            common_uhid: false,
        }
    }

    componentDidMount() {
        this._getDoctorDetails();
        let patientDetails = this.props.fromOT ? getCachevalue("OTpatientData") : getCachevalue("patientData")
        let patients = JSON.parse(patientDetails)
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var clinic_id = myUser?.clinic_id ? myUser?.clinic_id : myUser ? myUser : null
        var selectedClinicId = JSON.parse(localGetItem("SelectedDoctorClinicId")) 
        this.setState({
            attenderData: patients,
            clinic_id: selectedClinicId ? selectedClinicId : clinic_id
        }, () => {
            this._getVisitReport();
        })
    }

    _getVisitReport = () => {
        let states = this.state;
        let url = "";
        this.setState({
            visitReport: [],
        })
        try {
            if ((states["fromDate"] && states["toDate"]) || states["doctor_id"]) {
                url = `${Serviceurls.FO_DOCTOR_VISIT_REPORT}?patient_id=${states["attenderData"]["patient_id"]}&clinic_id=${this.state.clinic_id}&from_date=${states["fromDate"] ? states["fromDate"] : ""}&to_date=${states["toDate"] ? states["toDate"] : ""}&doctor_id=${states["doctor_id"] ? states["doctor_id"] : ""}`
            } else {
                url = `${Serviceurls.FO_DOCTOR_VISIT_REPORT}?patient_id=${states["attenderData"]["patient_id"]}&clinic_id=${this.state.clinic_id}`
            }
            this.LoaderFunction(true)
            RestAPIService.getAll(url)
                .then(response => {
                    let data = response?.data?.data;
                    if (response.data.status === "success") {
                        this.setState({
                            visitReport: data,
                            patientData: response?.data["patient_data"] && response?.data["patient_data"][0],
                            common_uhid: response?.data?.common_uhid ? response?.data?.common_uhid : false,
                        });
                        this.LoaderFunction(false)
                    }
                })
                .catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.LoaderFunction(false)
                        this.responseSuccessErrorMessage(error.response?.data?.message, false);
                    } else {
                        this.LoaderFunction(false)
                        this.responseSuccessErrorMessage(error.message, false);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    _getDoctorDetails = () => {
        this.setState({
            allDoctors: [],
        })
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            allDoctors: response.data.data
                        });
                    }
                })
                .catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.responseSuccessErrorMessage(error.response?.data?.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }

    handleClear = () => {
        this.setState({
            fromDate: null,
            toDate: null,
            dateIndex: 0,
            doctor_id: null,
        })
    }


    renderTextBox(statekey, value, width) {
        const { t } = this.props;
        let states = this.state;
        return (
            <div id="eMed_DocRepo_divs">
                {statekey === "date" ?
                    <Box id="eMed_DocRepo_datePicker"     emed_tid = {statekey}  sx={{ width: width ? width : "14.5vw" }}>
                        <CommonDatePicker
                            emed_tid = {statekey} 
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} 
                        />                            
                    </Box>
                    :
                    <FormControl sx={{ width: width ? width : "14.5vw" }} size="small">
                        <Select
                            inputProps={{ emed_tid: `cb_${statekey}` }}
                            value={value ? value : 0}
                            name={statekey}
                            onChange={(event) => {
                                states["doctor_id"] = event.target.value;
                                this.setState({
                                    states
                                })
                            }}
                            MenuProps={{
                                style: { maxHeight: 350 },
                            }}
                        >
                            <MenuItem key={0} value={0} >{"All Doctors"}</MenuItem>
                            {
                                states["allDoctors"]?.length > 0 ? states["allDoctors"].map((list, index) => (
                                    <MenuItem key={index + 1} value={list.doctor_id} >{list.doctor_name}</MenuItem>
                                ))
                                    : null
                            }
                        </Select>
                    </FormControl>
                }
            </div>
        )
    }
    msgClose() {
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
    }

    getDoctorVisitReportPrint = (params) => {
        try {
            let APIUrl = ""
            if (params?.is_opt) {
                APIUrl = Serviceurls.OPT_PATIENT_DOCTOR_NOTES_PRINT + "&appointment_id=" + params?.appointment_id + "&patient_id=" + this.state.attenderData?.patient_id + "&doctor_id=" + params?.doctor_id + "&clinic_id=" + this.state.clinic_id
            } else {
                APIUrl = Serviceurls.DOCTOR_NOTES_PRINT + "?appointment_id=" + params?.appointment_id + "&patient_id=" + this.state.attenderData?.patient_id + "&doctor_notes=all" + "&doctor_id=" + params?.doctor_id + "&clinic_id=" + this.state.clinic_id
            }

            RestAPIService.getAll(APIUrl).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.responseSuccessErrorMessage(error.message, false);
                })
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false);
        }
    }

    LoaderFunction = (key = false) => {
        this.setState({
            isLoader: key
        })
    }

    render() {
        const { t } = this.props;
        let states = this.state;
        const columns = [
            {
                field: 'visit_date', headerAlign: "center", headerName: t('DateVisited'), align: "center", flex: 0.20, renderCell: (cellvalues) => {
                    return (<Typography>{cellvalues.row.visit_date ? cellvalues.row.visit_date : "-"}</Typography>)
                }
            },
            {
                field: 'visit_time', headerName: t('Time'), flex: 0.20, renderCell: (cellvalues) => {
                    return (<Typography>{cellvalues.row.visit_time ? cellvalues.row.visit_time : "-"}</Typography>)
                }
            },
            {
                field: 'doctor_name', headerName: t('DoctorName'), flex: 0.30, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues.row?.doctor_name?.length > 25 ? <Tooltip placement="top" title={cellvalues.row?.doctor_name} arrow><div>{cellvalues.row?.doctor_name.slice(0, 20) + "..."}</div></Tooltip> : cellvalues.row.doctor_name ? cellvalues.row.doctor_name : "-"}</div>
                    )
                }
            },
            {
                field: 'appointment_status', headerName: t('MedicalReport'), flex: 0.30, renderCell: (cellvalues) => {
                    let appointment_status = cellvalues.row.appointment_status;
                    return (
                        <Typography emed_tid = {"getDoctorVisitReportPrint_testID"} component={'div'} onClick={() => {
                            if (appointment_status === "View") {
                                this.getDoctorVisitReportPrint(cellvalues?.row)
                            }
                        }}
                            sx={{ color: appointment_status === "View" ? Colors.Primary : null, cursor: appointment_status === "View" ? "pointer" : null }}>
                            {appointment_status?.length > 25 ? <Tooltip placement="top" title={appointment_status} arrow><div>{appointment_status.slice(0, 20) + "..."}</div></Tooltip> :
                                appointment_status ? appointment_status : "-"}</Typography>
                    )
                }
            }
        ]
        let branchFeild = {
            field: 'branch_name', headerName: t('Branch Name'), flex: 0.20, renderCell: ({ row }) => {
                return (<div>{row?.branch_name?.length > 18 ? <Tooltip placement="top" title={row?.branch_name} arrow><div>{row?.branch_name.slice(0, 15) + "..."}</div></Tooltip> : row.branch_name ? row.branch_name : "-"}</div>)
            }
        }
        if (this.state.common_uhid) {
            columns.splice(2, 0, branchFeild)
        }
        let data = {
            lineOne: t("AttenderName"),
            lineTwo: `${this.state.patientData.attender_name ? this.state.patientData.attender_name : this.state.attenderData.attender_name ? this.state.attenderData.attender_name :  "-"}`,
            lineFour: t('ContactNumber'),
            LineFive: `${this.state.patientData.attender_mobile ? this.state.patientData.attender_mobile : this.state.attenderData.attender_mobile ? this.state.attenderData.attender_mobile :  "-"}`
        }
        return (
            <Box id="eMed_DocRepo_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container className="eMed_DocRepo_Box">
                    <Stack className="eMed_card_header" direction="row" justifyContent="space-evenly" alignItems="center" spacing={1} >
                        <Stack className="eMed_card_div" sx={{ width: "23vw", backgroundColor: Colors.white }} >
                            <CommonPatientDetails data={this.state.attenderData} />
                        </Stack>
                        <CommonPatientCard details={data} showDetailed={true} />
                    </Stack>
                    <Grid container item spacing={3.5} className="eMed_DocRepo_mainDiv">
                        <Grid item xs={8.5}>
                            <Paper className="eMed_DocRepo_Div" sx={{ height: this.props.fromOT ? "63vh" : "70vh" }} elevation={1}>
                                <DataGrid
                                    rows={states["visitReport"]}
                                    columns={columns}
                                    getRowId={(row) => row.appointment_id}
                                    disableSelectionOnClick
                                    localeText={{
                                        toolbarColumns: "",
                                        toolbarDensity: "",
                                        toolbarExport: "",
                                        toolbarFilters: "",
                                        toolbarExportPrint: ""
                                    }}
                                    components={{
                                        Toolbar: CommonGridHeader.CommonToolBar,
                                        NoRowsOverlay: () => {
                                            return (
                                                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                    {t("NoRecordsFound")}
                                                </Stack>
                                            )
                                        }
                                    }}
                                    headerHeight={40}
                                    hideFooter
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={3.5}>
                            <Paper className="eMed_DocRepo_Div" sx={{ height: this.props.fromOT ? "63vh" : "70vh" }} elevation={1}>
                                <div className='eMed_DocRepo_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                    <p className='eMed_DocRepo_rightHead_txt'>{t("Filter")}</p>
                                </div>
                                <div className="eMed_DocRepo_subDiv">
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        {this.renderTextBox("doctor_id", states["doctor_id"], "13.5vw")}
                                        {this.renderTextBox("date", "", "10vw")}
                                    </Stack>
                                </div>
                                <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                                    <Button emed_tid = {"handleClear_testID"}  variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                    <Button emed_tid = {"Filter_Search_btn_testID"}  disabled={this.state.isLoader} variant='contained' size="small"  id="eMed_Config_btn" onClick={() => { this._getVisitReport() }}>{t("Search")}</Button>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(DoctorVisitReport);
