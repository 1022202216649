import React, { Component } from 'react'
import { CommonPatientCard, CommonPatientDetails, CommonPopUp, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Colors } from '../../../../Styles/Colors'
import Loader from '../../../../Components/Loader';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { DateTime } from 'luxon';
import { DataGrid } from '@mui/x-data-grid';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import {Chart as ChartJS, CategoryScale,  LinearScale,PointElement,  LineElement,  Title,  Tooltip,  Legend,} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default class DocGlucouselog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientDetails: {},
      clinidId: '',
      appointmentId: '',
      patientId: '',
      glucouseData: [],
      loading: false,
      DatePickerOpen: false,
      selectedDate: null,
      fastingValue: '',
      breakfastValue: '',
      postGlucosevalue : '',
      lunchValue: '',
      postLunchValue: '',
      dinnerValue: '',
      postDinnerValue: '',
      postLoading: false,
      page: 0,
      pageSize: 10,
      selectedSearchDays: '',
      selectedId: null,
      highValue: '',
      lowValue: '',
      isEdit: false,
      deletePopup: false,
      isViewGraph : false,
      isViewBtn:false,
    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    this.setState({
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id
    }, () => {
      this.getPatientDetailData()
      this.getGlucouseData()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getGlucouseData = () => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_GLUCOUSE_LOG + `?patient_id=${this.state.patientId}&search_days=${this.state.selectedSearchDays}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              glucouseData: response?.data?.data,
              loading: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  postGlucouseData = () => {
    try {
      let date = new Date(this.state.selectedDate)
      let dataDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
      let data = {
        activity_date: dataDate,
        fasting: this.state.fastingValue,
        lunch: this.state.lunchValue,
        number_2_hrs_post_bfast: this.state.breakfastValue,
        number_2_post_glucose: this.state.postGlucosevalue,
        number_2_hrs_post_dinner: this.state.postDinnerValue,
        number_2_hrs_post_lunch: this.state.postLunchValue,
        patient_id: this.state.patientId,
        pre_dinner: this.state.dinnerValue,
        id: this.state.selectedId ? this.state.selectedId : null
      }
      let validation = this.checkValidation()
      if (validation === null) {
        this.setState({ postLoading: true })
        RestAPIService.create(data, Serviceurls.DOC_GLUCOUSE_LOG)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.onClearHandler()
              this.setState({ postLoading: false })
              this.getGlucouseData()
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.setState({ postLoading: false })
              this.errorMessage(error.response.data.message)
            } else {
              this.setState({ postLoading: false })
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage(validation)
      }
    } catch (error) {
      this.setState({ postLoading: false })
      this.errorMessage(error.message)
    }
  }

  deleteGlucouseData = () => {
    try {
      let data = {}
      this.setState({ removeData: true })
      RestAPIService.delete(Serviceurls.DOC_GLUCOUSE_LOG + `?id=${this.state.selectedId}`, data)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.onClearHandler()
            this.getGlucouseData()
            this.setState({ removeData: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  popupClose = () => {
    try {
      this.setState({
        deletePopup: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkValidation = () => {
    try {
      let message = null;
      let states = this.state
      if ((states.selectedDate === null) ||
        (states.fastingValue === '' ) ||
        (states.breakfastValue === '') ||
        (states.postGlucosevalue === '') ||
        (states.lunchValue === '') ||
        (states.postLunchValue === '') ||
        (states.dinnerValue === '') ||
        (states.postDinnerValue === '')) {
        message = 'Enter All Mandatory Fields'
      }
      else if((states.fastingValue <= 50) || (states.breakfastValue <= 50 ) || (states.postGlucosevalue <= 50 ) ||
      (states.lunchValue <= 50) ||  (states.postLunchValue <= 50) || (states.dinnerValue <= 50) || (states.postDinnerValue <= 50)
      ){
        message = "Please ensure that all field values are greater than 50"
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler = (item) => {
    try {
      this.setState({
        selectedDate: item?.activity_date,
        fastingValue: item?.fasting,
        breakfastValue: item?.number_2_hrs_post_bfast,
        postGlucosevalue : item?.number_2_post_glucose,
        lunchValue: item?.lunch,
        postLunchValue: item?.number_2_hrs_post_lunch,
        dinnerValue: item?.pre_dinner,
        postDinnerValue: item?.number_2_hrs_post_dinner,
        isEdit: true,
        selectedId: item?.id
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (item) => {
    try {
      if (this.state.isEdit) {
        this.errorMessage("You have clicked edit.So,Please Update the data")
      } else {
        this.setState({
          deletePopup: true,
          selectedId: item?.id
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearHandler = () => {
    try {
      this.setState({
        fastingValue: '',
        breakfastValue: '',
        postGlucosevalue : '',
        lunchValue: '',
        postLunchValue: '',
        dinnerValue: '',
        postDinnerValue: '',
        selectedDate: null,
        selectedSearchDays: '',
        selectedId: '',
        removeData: false,
        highValue: '',
        lowValue: '',
        isEdit: false,
        deletePopup: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderLeftSide = () => {
    try {
      let isAvailable = (this.state.highValue !== '') || (this.state.lowValue !== '') ? true : false;
      this.state.glucouseData?.forEach((element, index) => element.sno = index + 1)
      const columns = [
        {
          field: 'activity_date', headerName: 'Date', flex: 0.25,
          renderCell: (params) => (<Box>{params?.row?.activity_date ? formatDate(params?.row?.activity_date) : '-'}</Box>)
        },
        {
          field: 'fasting', headerName: 'Fasting Value', flex: 0.23,
          renderCell: (params) => (
            <>
              {isAvailable ?
                <Box component={'div'}
                  sx={{ backgroundColor: isAvailable ? parseFloat(params?.row?.fasting) > parseFloat(this.state.highValue) ? "#477B9E" : parseFloat(params?.row?.fasting) < parseFloat(this.state.lowValue) ? "#DB6565" : null : null }}
                  color={(parseFloat(params?.row?.fasting) > parseFloat(this.state.highValue)) ? "white" : null}>
                  {params?.row?.fasting}
                </Box > : <Box component={'div'}>{params?.row?.fasting}</Box>}
            </>
          )
        },
        {
          field: 'number_2_post_glucose', headerName: '2 Hours Post ', flex: 0.2,
          renderCell: (params) => (
            <>
              {isAvailable ?
                <Box component={'div'}
                  sx={{ backgroundColor: isAvailable ? parseFloat(params?.row?.number_2_post_glucose) > parseFloat(this.state.highValue) ? "#477B9E" : parseFloat(params?.row?.number_2_post_glucose) < parseFloat(this.state.lowValue) ? "#DB6565" : null : null }}
                  color={parseFloat(params?.row?.number_2_post_glucose) > parseFloat(this.state.highValue) ? "white" : null} >
                  {params?.row?.number_2_post_glucose}
                </Box> : <Box component={'div'}>{params?.row?.number_2_post_glucose}</Box>}
            </>
          )
        },
        {
          field: 'number_2_hrs_post_bfast', headerName: '2 Hours Post Breakfast', flex: 0.2,
          renderCell: (params) => (
            <>
              {isAvailable ?
                <Box component={'div'}
                  sx={{ backgroundColor: isAvailable ? parseFloat(params?.row?.number_2_hrs_post_bfast) > parseFloat(this.state.highValue) ? "#477B9E" : parseFloat(params?.row?.number_2_hrs_post_bfast) < parseFloat(this.state.lowValue) ? "#DB6565" : null : null }}
                  color={parseFloat(params?.row?.number_2_hrs_post_bfast) > parseFloat(this.state.highValue) ? "white" : null} >
                  {params?.row?.number_2_hrs_post_bfast}
                </Box> : <Box component={'div'}>{params?.row?.number_2_hrs_post_bfast}</Box>}
            </>
          )
        },
        {
          field: 'lunch', headerName: 'Pre Lunch', flex: 0.2,
          renderCell: (params) => (
            <>
              {isAvailable ?
                <Box component={'div'}
                  sx={{ backgroundColor: isAvailable ? parseFloat(params?.row?.lunch) > parseFloat(this.state.highValue) ? "#477B9E" : parseFloat(params?.row?.lunch) < parseFloat(this.state.lowValue) ? "#DB6565" : null : null }}
                  color={parseFloat(params?.row?.lunch) > parseFloat(this.state.highValue) ? "white" : null} >
                  {params?.row?.lunch}
                </Box> : <Box component={'div'}>{params?.row?.lunch}</Box>}
            </>
          )
        },
        {
          field: 'number_2_hrs_post_lunch', headerName: '2 Hours Post Lunch', flex: 0.2,
          renderCell: (params) => (
            <>
              {isAvailable ?
                <Box component={'div'}
                  sx={{ backgroundColor: isAvailable ? parseFloat(params?.row?.number_2_hrs_post_lunch) > parseFloat(this.state.highValue) ? "#477B9E" : parseFloat(params?.row?.number_2_hrs_post_lunch) < parseFloat(this.state.lowValue) ? "#DB6565" : null : null }}
                  color={parseFloat(params?.row?.number_2_hrs_post_lunch) > parseFloat(this.state.highValue) ? "white" : null} >
                  {params?.row?.number_2_hrs_post_lunch}
                </Box > : <Box component={'div'}>{params?.row?.number_2_hrs_post_lunch}</Box>}
            </>
          )
        },
        {
          field: 'pre_dinner', headerName: 'Pre Dinner', flex: 0.2,
          renderCell: (params) => (
            <>
              {isAvailable ?
                <Box component={'div'}
                  sx={{ backgroundColor: isAvailable ? parseFloat(params?.row?.pre_dinner) > parseFloat(this.state.highValue) ? "#477B9E" : parseFloat(params?.row?.pre_dinner) < parseFloat(this.state.lowValue) ? "#DB6565" : null : null }}
                  color={parseFloat(params?.row?.pre_dinner) > parseFloat(this.state.highValue) ? "white" : null} >
                  {params?.row?.pre_dinner}
                </Box > : <Box component={'div'}>{params?.row?.pre_dinner}</Box>}
            </>
          )
        },
        {
          field: 'number_2_hrs_post_dinner', headerName: '2 Hours Post Dinner', flex: 0.2,
          renderCell: (params) => (
            <>
              {isAvailable ?
                <Box component={'div'}
                  sx={{ backgroundColor: isAvailable ? parseFloat(params?.row?.number_2_hrs_post_dinner) > parseFloat(this.state.highValue) ? "#477B9E" : parseFloat(params?.row?.number_2_hrs_post_dinner) < parseFloat(this.state.lowValue) ? "#DB6565" : null : null }}
                  color={parseFloat(params?.row?.number_2_hrs_post_dinner) > parseFloat(this.state.highValue) ? "white" : null} >
                  {params?.row?.number_2_hrs_post_dinner}
                </Box > : <Box component={'div'}>{params?.row?.number_2_hrs_post_dinner}</Box>}
            </>
          )
        },
        {
          field: 'action', headerName: 'Action', flex: 0.3, align: 'center', headerAlign: 'center',
          renderCell: (params) => (
            <Box>
              <Button className='Common_Btn_Min_Width' emed_tid='act_edit_btn'
                onClick={() => this.onEditHandler(params?.row)}
              >
                <Box height={'1.5vw'} width={'1.5vw'} component={'img'} src={ImagePaths.Edit.default} />
              </Button>
              <Button className='Common_Btn_Min_Width' emed_tid='act_del_btn'
                onClick={() => this.onDeleteHandler(params?.row)}
              >
                <Box height={'1.5vw'} width={'1.5vw'} component={'img'} src={ImagePaths.Delete.default} />
              </Button>
            </Box>
          )
        }
      ]
      return (
        <Box>
          <Box component={'div'} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'} mb={'0.1vw'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Glucose Log</Typography>
            <Box>
              <FormControl >
                <RadioGroup
                  emed_tid='glu_radio'
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={this.state.selectedSearchDays}
                  onChange={(e) => this.onChangeHandler(e, "selectedSearchDays")}
                >
                  <FormControlLabel value="2 Weeks" control={<Radio size='small' emed_tid='two_week' />} label="2 Weeks" />
                  <FormControlLabel value="1 Month" control={<Radio size='small' emed_tid='one_mon' />} label="1 Month" />
                  <FormControlLabel value="2 Months" control={<Radio size='small' emed_tid='two_mon' />} label="2 Months" />
                  <FormControlLabel value="3 Months" control={<Radio size='small' emed_tid='three_mon' />} label="3 Months" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box component={'div'}>
              {this.renderTextBox('highValue', this.state.highValue, '', '10vw', '1vw', 'High Value')}
              {this.renderTextBox('lowValue', this.state.lowValue, '', '10vw', null, 'Low Value')}
            </Box>
          </Box>
          <DataGrid
            sx={{ height: '25vw', backgroundColor: Colors.white }}
            rows={this.state.glucouseData}
            columns={columns}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              // Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay: () => (
                <Box sx={{ display: 'flex', position: 'relative', top: '10vw', justifyContent: 'center', alignContent: 'center' }}>
                  {'No Records To Be Shown'}
                </Box>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
            loading={this.state.loading}
          />
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHandler = (e, names) => {
    try {
      let value = e.target.value
      let numericValue = Number(value);
      if (names === 'selectedSearchDays') {
        this.setState({
          selectedSearchDays: value
        }, () => this.getGlucouseData())
      } else {
        let isValid = CommonValidation.NumberWithDot(value)
        if ( value === ''  || isValid && numericValue <= 700) {
          this.setState({
            [names]: value
          })
        }
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTextBox = (names, value, label, width, mr, placeholder) => {
    try {
      return (
        <TextField
          size='small'
          sx={{ width: width, mr: mr }}
          label={label}
          name={names}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {this.onChangeHandler(e, names)
          }}
          InputProps={{
            endAdornment: <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>mg/dl</Typography>,
            emed_tid:names
          }}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }


  renderGraphComponent = () => {
    let grapharray = []
      let states = this.state
      let {minvalueGraph,maxvalueGraph } = this.state
    this.state.glucouseData.map((item)=>{
      let fasting =item.fasting ? item.fasting : 0
       let postBreakfast = item.number_2_hrs_post_bfast ? item.number_2_hrs_post_bfast :0
       let postglucose = item.number_2_post_glucose ? item.number_2_post_glucose : 0
       let Lunch =item.lunch ? item.lunch :0
       let PostLunch =item.number_2_hrs_post_lunch ? item.number_2_hrs_post_lunch :0
      let Pre_Dinner =item.pre_dinner ? item.pre_dinner :0
      let Dinner = item.number_2_hrs_post_dinner ? item.number_2_hrs_post_dinner : 0
      grapharray.push(fasting,postBreakfast,Lunch,PostLunch,Pre_Dinner,Dinner,postglucose)  
    })
    grapharray = grapharray.map(Number)

    this.state.minvalueGraph = Math.min(...grapharray);
     this.state.maxvalueGraph = Math.max(...grapharray);
  
  if(!this.state.isViewBtn){
this.setState({
  minvalueGraph,
  maxvalueGraph,
  isViewBtn: true,
});}
   
    var labels = []  
 const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'BLOOD GLUCOSE LEVEL',  
    },
  },
  scales: {
    y: {
      display: true,
      position: 'left',
      min: this.state.minvalueGraph,
      max:   this.state.maxvalueGraph ,

    },
    y1: {
      type: 'linear',
      display: false,
      position: 'right',
      min:  this.state.minvalueGraph,
      max:  this.state.maxvalueGraph,

      grid: {
        drawOnChartArea: false,
      },
    },
    y2: {
      type: 'linear',
      display: false,
      position: 'left',
      min: this.state.minvalueGraph,
      max: this.state.maxvalueGraph,

      grid: {
        drawOnChartArea: false,
      },
    },
    y3: {
      type: 'linear',
      display:false ,
      position: 'right',
      min:  this.state.minvalueGraph,
      max:  this.state.maxvalueGraph,

      grid: {
        drawOnChartArea: false,
      },
    },
    y4: {
      type: 'linear',
      display: false,
      position: 'left',
      min: this.state.minvalueGraph,
      max: this.state.maxvalueGraph,

      grid: {
        drawOnChartArea: false,
      },
    },
    y5: {
      type: 'linear',
      display: false,
      position: 'right',
      min: this.state.minvalueGraph,
      max: this.state.maxvalueGraph,

      grid: {
        drawOnChartArea: false,
      },
    },
  },
};
 
 this.state.ActiveDates = this.state.glucouseData.map((item)=>item.activity_date ? item.activity_date.split("-").reverse().join("-"): 0)
 
 labels.push(...this.state.ActiveDates)
 const data = {
  labels,
  datasets: [
    {
      label: "Fasting" ,
      data: this.state.glucouseData.map((item)=>item.fasting ? item.fasting :0),
      borderColor: '#1E972A',
      backgroundColor: '#1E972A',
      yAxisID: 'y',
    },
    {
      label:"2 Hours Post Glucose Blood Sugar",
      data: this.state.glucouseData.map((item)=>item.number_2_post_glucose ? item.number_2_post_glucose : 0),
      borderColor: '#DE7D24',
      backgroundColor: '#DE7D24',
      yAxisID: 'y1',
    },
    {
      label:"2 Hours Post Breakfast",
      data: this.state.glucouseData.map((item)=>item.number_2_hrs_post_bfast ? item.number_2_hrs_post_bfast :0),
      borderColor: '#202020',
      backgroundColor: '#202020',
      yAxisID: 'y1',
    },
    {
      label: "Pre Lunch",
      data: this.state.glucouseData.map((item)=>item.lunch ? item.lunch :0),
      borderColor: '#0461B7',
      backgroundColor: '#0461B7',
      yAxisID: 'y2',
    },
    {
      label:  "Post Lunch",
      data: this.state.glucouseData.map((item)=>item.number_2_hrs_post_lunch ? item.number_2_hrs_post_lunch :0) ,
      borderColor: 'rgba(98, 129, 91, 0.41)',
      backgroundColor: 'rgba(98, 129, 91, 0.41)',
      yAxisID: 'y3',
    },
    {
      label: "Pre Dinner",
      data: this.state.glucouseData.map((item)=>item.pre_dinner ? item.pre_dinner :0),
      borderColor: '#AF2591',
      backgroundColor: '#AF2591',
      yAxisID: 'y4',
    },
    {
      label:  "2 hours Post Dinner",
      data: this.state.glucouseData.map((item)=>item.number_2_hrs_post_dinner ? item.number_2_hrs_post_dinner :0),
      borderColor: '#29B6F6',
      backgroundColor: '#29B6F6',
      yAxisID: 'y5',
    },           
  ],
};
     return(
       <Box>
          { this.state.isViewGraph ? 
                   <Box sx={{ width: "70vw" }}>
                     <Line
                     data={data} 
                     options={options}
                      />
                   </Box> : null  
 
           }
       </Box>
     )
   }
  viewGraphPopupClose = () => {
    this.setState({
      isViewGraph: false,
      isViewBtn:false
    })
  }


  onPressViewGraphBtn = (flag) => {
    this.setState({
      isViewGraph: flag
    })   
  }

  renderRightSide = () => {
    try {
      return (
        <Box>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Glucose Log</Typography>
            <Button
              sx={{ height: '2vw', width: '8vw', textTransform: 'capitalize', mr: '1vw' }}
              variant='outlined'
              onClick={() => this.onPressViewGraphBtn(true)}
            >View Graph</Button>
          </Box>
          <Box component={'div'} height={'23vw'} sx={{ backgroundColor: Colors.white }} overflow={'scroll'} p={'0.5vw'}>
            <Box component={'div'} display={'flex'}>
              <Box mr={'1vw'} mb={'1vw'}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    emed_tid='glus_date_picker'
                    open={this.state.DatePickerOpen}
                    onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                    onClose={() => { this.setState({ DatePickerOpen: false }) }}
                    value={this.state.selectedDate}
                    name={"datePicker"}
                    inputFormat='DD-MM-YYYY'
                    maxDate={new Date()}
                    views={["year", "month", "day"]}
                    onChange={
                      (newDate) => {
                        this.setState({ selectedDate: newDate });
                      }}
                    renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '15vw' }} size='small' {...params} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box component={'div'} mb={'1vw'}>
              <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Fasting</Typography>
              {this.renderTextBox('fastingValue', this.state.fastingValue, 'Fasting Value *', '15vw')}
            </Box>
            <Box component={'div'} mb={'1vw'} display={'flex'}>
            <Box component={'div'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>2 Hrs Post Glucose blood Sugar</Typography>
                {this.renderTextBox('postGlucosevalue', this.state.postGlucosevalue, 'Post Glucose Value *', '15vw', '1vw')}
              </Box>
              <Box component={'div'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>2 Hours Post Breakfast</Typography>
                {this.renderTextBox('breakfastValue', this.state.breakfastValue, 'Breakfast Value *', '15vw', '1vw')}
              </Box>
             
            </Box>
            <Box component={'div'} mb={'1vw'} display={'flex'}>
            <Box component={'div'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Pre Lunch</Typography>
                {this.renderTextBox('lunchValue', this.state.lunchValue, 'Pre Lunch Value *', '15vw','1vw')}
              </Box>
              <Box component={'div'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>2 Hours Post Lunch</Typography>
                {this.renderTextBox('postLunchValue', this.state.postLunchValue, 'Post Lunch Value *', '15vw', '1vw')}
              </Box>
             
            </Box>
            <Box component={'div'} mb={'1vw'} display={'flex'}>
            <Box component={'div'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Pre Dinner</Typography>
                {this.renderTextBox('dinnerValue', this.state.dinnerValue, 'Pre Dinner Value *', '15vw','1vw')}
              </Box>
              <Box component={'div'}>
                <Typography mb={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>2 Hours Post Dinner</Typography>
                {this.renderTextBox('postDinnerValue', this.state.postDinnerValue, 'Post Dinner Value *', '15vw', '1vw')}
              </Box>
            </Box>
          </Box>
          <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
            <Button emed_tid='glu_clr_btn'
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
              variant='outlined'
              onClick={() => this.onClearHandler()}
            >Clear</Button>
            {this.state.postLoading ? <CircularProgress size={'small'} color='primary' /> :
              <Button emed_tid='glu_add_btn'
                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                variant='contained'
                onClick={() => this.postGlucouseData()}
              >Add</Button>
            }
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'61.5vh'}>
          <Box component={'div'} flex={0.65} overflow={'scroll'}>
            {this.renderLeftSide()}
          </Box>
          <Box component={'div'} flex={0.35} border={'1px solid lightgray'}>
            {this.renderRightSide()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.deletePopup ? <DeletePopup
          DeletTitle={`Are you sure you want to delete ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.deleteGlucouseData.bind(this)}
          disable={this.state.removeData}
        /> : null}
         {(this.state.isViewGraph) ?
                    <CommonPopUp  width={"75vw"}  height = { "90vh"}
                        backgroundColor={Colors.white}
                        popUpClose={this.viewGraphPopupClose.bind(this)}
                        title={""}
                        component={this.renderGraphComponent.bind(this)}
                    />
                    : null
                }
      </Box>

    )
  }
}
