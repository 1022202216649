import React, { Component } from 'react';
import './IPListTabs.css';
import { TextField, Tooltip, Box, Typography, Stack, Autocomplete, Button } from '@mui/material';
import { CommonPatientDetails, CommonGridToolBarWithFilterTextCustom } from '../../../Components/Common Components/CommonComponents';
import { DataGrid, gridStringOrNumberComparator } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { getCachevalue, localGetItem, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { formatDate } from "../../../Components/CommonFunctions/CommonFunctions";

const cardMapping = {
    Admission: 'Admitted',
    Discharge: 'Checked Out',
    All: '',
    "In IP": 'In Ip',
};

class IPDoctorHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            AptTblData: [],
            IpPatientDetails: {},
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            wardType: null,
            blockType: null,
            floorType: null,
            WardList: [],
            BlockList: [],
            FloorList: [],
            selectedDoctor: null,
            SelectedDoctorClinic: '',
            anchorEl: null,
            selectedRow: null,
            selectedOption: "",
            isSelected: false,
            selectedID: "",
            selectedCard: "In IP",
            page: 0,
            pageSize: 10,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            DoctorsList: [],
            loading: false,
            currentTime: new Date(),
        }
    }

    componentDidMount() {
        let DoctorClinicId = localGetItem('SelectedDoctorClinicId');
        if (DoctorClinicId !== this.state.SelectedDoctorClinic && DoctorClinicId) {
            this.setState({ SelectedDoctorClinic: DoctorClinicId }, () => {
                this.getPatientDetails();
                // this.getDoctorsList();
                this.GetBedList();
            })
        }
    }

    errorMsgFunc = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMsgFunc = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, () => {
            this.getPatientDetails()
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            loading: key
        })
    }

    getPatientDetails = () => {
        let { fromDate, toDate, wardType, blockType, floorType, SelectedDoctorClinic, selectedCard }  = this.state;
        let selected_card = selectedCard in cardMapping ? cardMapping[selectedCard] : selectedCard;
        this.setState({
            IpPatientDetails: {},
            AptTblData: []
        })
        try {
            this.LoaderFunction(true);
            setCachevalue(fromDate ? fromDate : "", 'FormDate');
            setCachevalue(toDate ? toDate : "", 'ToDate');
            setCachevalue(selected_card, 'Status');
            setCachevalue(wardType || "", 'wardName')
            setCachevalue(blockType || "", 'blockName')
            setCachevalue(floorType || "", 'floorNumber')
            RestAPIService.getAll(
                `${Serviceurls.FO_IP_HOME}?clinic_id=${SelectedDoctorClinic || ""}&from_date=${fromDate || ""}&to_date=${toDate || ""}&status=${selected_card || ""}&ward_name=${wardType || ""}&block_name=${blockType || ""}&floor_number=${floorType || ""}`)
                .then(response => {
                    let data = response?.data;
                    if (response.data.status === "success") {
                        this.LoaderFunction(false);
                        this.setState({
                            IpPatientDetails: data,
                            AptTblData: data.data
                        }, () => {
                            this.state["AptTblData"].forEach((item, i) => {
                                item["index"] = i
                                item.room_ward_block = `${item.bed_number ? item.bed_number : "-"}/${item.room_number ? item.room_number : "-"}/${item.ward_name ? item.ward_name : "-"}/${item.block_name ? item.block_name : "-"}`
                                item.patientDetails = `${CommonValidation.removeSpace(item?.patient_name)} ${item?.patient_account_number} ${item?.patient__mobile_number} ${item?.doctor_name} ${item?.patient_address_1} ${item?.patient_address_2} ${item?.patient_city} ${item?.father_name} ${item?.mother_name} ${item?.bed_number}`
                            })
                        });
                    }
                })
                .catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.errorMsgFunc(error.response?.data?.message);
                    } else {
                        this.errorMsgFunc(error.message);
                    }
                    this.LoaderFunction(false);
                });
        } catch (e) {
            this.LoaderFunction(false);
            this.errorMsgFunc(e.message);
        }
    }

    getDoctorsList() {
        try {
            let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME + `?clinic_id=${UserData?.clinic_id ? UserData?.clinic_id : this.state.SelectedDoctorClinic}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            DoctorsList: response.data.data.filter((item) => (item.specialization_active === true))
                        })
                    }
                    else {
                        this.errorMsgFunc(response.data.message)
                    }
                }).catch((e) => {
                    this.errorMsgFunc(e.message)
                })
        }
        catch (e) {
            this.errorMsgFunc(e.message)
        }
    }

    RemoveDuplicates(Array, key) {
        const FilteredArray = Array.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item[key] === current[key])) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);

        return FilteredArray
    }

    GetBedList = () => {
        try {
            RestAPIService.getAll(Serviceurls.BED_CONFIG + "?clinic_id=" + this.state.SelectedDoctorClinic).
                then((response) => {
                    if (response.data.status === "success") {
                        let BedData = response.data?.data ? response.data?.data : []
                        if (BedData.length > 0) {
                            const Block = BedData.map(item => item.block)
                            const uniqueBlock = [...new Set(Block)];
                            const Ward = BedData.map(item => item.ward__ward_name)
                            const uniqueWard = [...new Set(Ward)];
                            const Floor = BedData.map(item => item.floor)
                            const uniqueFloor = [...new Set(Floor)];
                            this.setState({
                                BlockList: uniqueBlock,
                                WardList: uniqueWard,
                                FloorList: uniqueFloor
                            })
                        }

                    }
                    else {
                        this.errorMsgFunc(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMsgFunc(error.message)
                })
        }
        catch (error) {
            this.errorMsgFunc(error.message)
        }
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        let ExportData = [];
        this.state.AptTblData?.length > 0 && this.state.AptTblData?.map((item) =>
            ExportData.push(
                {
                    "Patient Details": item.patientDetails,
                    "IP Records": item.complaints,
                    "Consultant/Tag": item.doctor_name,
                    "Bed/Room/Ward/Block": item.room_ward_block,
                })
        )
        return (
            <Box>
                <CommonGridToolBarWithFilterTextCustom
                    FilterTextArray={TextArray}
                    data={ExportData}
                    title={"Ip Patient List"}
                    filename={"IpPatientList"} />
            </Box>
        )
    }

    renderOPHeader = () => {
        const { t } = this.props
        return (
            <div className='eMed_IPDoc_AptHead'>
                <p>{t('Appointment')}</p>
                <div className='eMed_Header_OP_home_div'>
                    <Box className='eMed_IP_AptDateFlt'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true}
                            HideDate={true}
                            Future={true}
                            AllowAllDate={true}
                        />
                    </Box>
                </div>
            </div>
        )
    }

    renderStatusBtn = (label, color, count) => {
        return (<Button
            className={'eMed_Header_IP_Status_btn'}
            sx={{ borderBottom: `${this.state.selectedCard === label ? "0.4dvw" : "0.2dvw"} ${color} solid !important` }}
            size='small'
            onClick={() => { this.setState({ selectedCard: label }, () => { this.getPatientDetails() }) }}>
            {`${label} ( ${count ? count : 0} )`}
        </Button>)
    }

    renderFilter = () => {
        const { t } = this.props;
        let states = this.state;
        return (
            <div className='eMed_IPDocFlt_Con'>
                {/* <p className='eMed_IPDocFlt_txt'>{t('Filter')}</p> */}
                <div className='eMed_IPDocFlt_subCon'>
                    <Box className='eMed_IPDoc_autoComplete'>
                        <Autocomplete
                            size='small'
                            options={this.state?.WardList ? this.state?.WardList : []}
                            getOptionLabel={(option) => (option)}
                            value={this.state?.wardType}
                            onChange={(event, newValue) => {
                                this.setState({
                                    wardType: newValue,
                                }, () => {
                                    this.getPatientDetails();
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Ward')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_IPDoc_autoComplete'>
                        <Autocomplete
                            size='small'
                            disabled={this.state.BlockList?.length === 0}
                            options={this.state?.BlockList ? this.state?.BlockList : []}
                            getOptionLabel={(option) => (option)}
                            value={this.state.BlockList?.length > 0 ? this.state?.blockType : null}
                            onChange={(event, newValue) => {
                                this.setState({
                                    blockType: newValue,
                                }, () => {
                                    this.getPatientDetails();
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Block')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_IPDoc_autoComplete'>
                        <Autocomplete
                            size='small'
                            disabled={this.state.FloorList?.length === 0}
                            options={this.state?.FloorList ? this.state?.FloorList : []}
                            getOptionLabel={(option) => (option)}
                            value={this.state.FloorList?.length > 0 ? this.state?.floorType : null}
                            onChange={(event, newValue) => {
                                this.setState({
                                    floorType: newValue,
                                }, () => {this.getPatientDetails()})
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Floor')}
                                />
                            )}
                        />
                    </Box>
                    {/* <Box className='eMed_IPDoc_autoComplete'>
                        <Autocomplete
                            size='small'
                            options={this.state?.DoctorsList}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={this.state.selectedDoctor ? this.state?.selectedDoctor : null}
                            onChange={(event, newValue) => {
                                this.setState({
                                    selectedDoctor: newValue,
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Doctor')}
                                />
                            )}
                        />
                    </Box> */}
                    {/* <Button
                        sx={{ margin: "0dvw 0.3dvw !important" }}
                        variant='contained' size="small"
                        id="eMed_recPayment_btn"
                        onClick={() => { this.getPatientDetails() }}>
                        {t("Search")}</Button> */}
                </div>
                <div className='eMed_IPDocFlt_subCon'>
                {this.renderStatusBtn(t("All"), "#616161", states["IpPatientDetails"]["all"])}
                {this.renderStatusBtn(t("In IP"), "#616161", states["IpPatientDetails"]["overall_count"])}
                {this.renderStatusBtn(t("Admission"), "#04B7B1", states["IpPatientDetails"]["admitted"])}
                {this.renderStatusBtn(t("Discharge"), "#1E972A", states["IpPatientDetails"]["checked_out"])}
                {this.renderStatusBtn(t("Cancelled"), "#B8170D", states["IpPatientDetails"]["cancelled"] )}
                </div>
            </div>
        )
    }

    stringOrNumberSort = (v1, v2) => {
        let sorted = gridStringOrNumberComparator(v1, v2);
        return sorted;
    }

    routeToPatientDetails = (params) => {
        let { history } = this.props;
        if (params) { setCachevalue(JSON.stringify(params.row), "IppatientData"); }

        if (params.field === 'patientDetails' || params.field === "complaints" || params.field === "doctor_name" || params.field === "room_ward_block" || params.field === "advance_amount" || params.field === "balance_amount") {
            history.push({ pathname: "/IPDoctorHome/AdmissionDetails" })
        }
    }

    renderAptTable = () => {
        const { t } = this.props;
        let states = this.state;

        const columns = [
            {
                field: 'patientDetails', headerName: t('Patient Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.25, renderCell: ({ row }) => (
                    <Box component={'div'}>
                        <CommonPatientDetails data={row} showAdmission={true} />
                    </Box>
                ), density: 'compact'
            },
            {
                field: 'complaints', headerName: t('IPRecords'), headerClassName: 'eMed_Apt_TblHead', flex: 0.25, density: 'compact',
                renderCell: ({ row }) => (
                    <div>
                        <div className='eMed_OP_PatientType'> <Typography id='eMed_OP_AptTbl_AptText' sx={{ fontWeight: '600' }}>{'IP :' + (row.ip_number ? row.ip_number : '-')}</Typography></div>
                        <div>{row.complaints && row.complaints?.length > 35 ?
                            <Tooltip title={row.complaints ? row.complaints : null} placement={'top'} arrow>
                                <Typography id='eMed_OP_AptTbl_AptText1'>{'Complaints :' + (row.complaints ? row.complaints.slice(0, 35) + '...' : '-')}</Typography>
                            </Tooltip> : <Typography id='eMed_OP_AptTbl_AptText1'>{'Complaints :' + (row.complaints ? row.complaints : '-')}</Typography>} </div>
                        <Typography id='eMed_OP_AptTbl_AptText'>{'Medico-Legal :' + (row.medico_legal_case ? "Yes" : "No")}</Typography>
                    </div>),
            },
            {
                field: 'doctor_name', headerName: t('Consultant/Tag'), headerClassName: 'eMed_Apt_TblHead', flex: 0.25, density: 'compact',
                renderCell: ({ row }) => {
                    return (
                        <div>
                            {row?.doctor_name?.length > 35 ? <Tooltip placement="top" title={row?.doctor_name} arrow><div>{row?.doctor_name.slice(0, 35) + "..."}</div></Tooltip> : row.doctor_name ? row.doctor_name : "-"}
                            <div style={{ fontSize: '0.8vw', fontWeight: "bold", color: row.patient_tags__patient_tag_color ? row.patient_tags__patient_tag_color : "#000000" }}>
                                {`Tag : ${row?.patient_tags__patient_tag_name ? row?.patient_tags__patient_tag_name : '-'}`}
                            </div>
                        </div>
                    )
                }
            },
            {
                field: 'room_ward_block', headerName: t('Bed/Room/Ward/Block'), headerClassName: 'eMed_Apt_TblHead', flex: 0.25, density: 'compact',
                renderCell: ({ row }) => (<div>{row?.room_ward_block?.length > 38 ?
                    <Tooltip placement="top" title={row?.room_ward_block} arrow>
                        <div>{row?.room_ward_block?.slice(0, 38) + "..."}</div>
                    </Tooltip>
                    : row.room_ward_block ? row.room_ward_block : "-"}</div>)
            },
        ]
        return (
            <div>
                <Box className='eMed_IPDocAptTbl_Con'>
                    <DataGrid
                        rows={states["AptTblData"]}
                        columns={columns}
                        getRowId={(row) => row.id}
                        pageSize={this.state.pageSize}
                        page={this.state.page}
                        rowsPerPageOptions={[10, 20, 30, 50]}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        density={'compact'}
                        rowHeight={100}
                        onCellClick={(params) => {
                            if (params.field === "patientDetails" || params.field === "complaints" || params.field === "doctor_name" || params.field === "room_ward_block") {
                                this.routeToPatientDetails(params)
                            }
                        }}
                        pagination
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => { this.setState({ pageSize: newPageSize }) }}
                        disableSelectionOnClick
                        loading={this.state.loading}
                    />
                </Box>
            </div>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        return (
            <div className='eMed_OP_Appointment'>
                {this.renderOPHeader()}
                {this.renderFilter()}
                {this.renderAptTable()}

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>

        )
    }
}

export default withTranslation()(IPDoctorHome);