import { Box, Button, Divider, IconButton, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material"
import { ImagePaths } from "../../../Utility/ImagePaths"
import React, { Component } from "react"
import RestAPIService from "../../../Utility/Services/RestAPIService"
import { Serviceurls } from "../../../Utility/API/Serviceurls"
import { localGetItem } from "../../../Utility/Services/CacheProviderService"
import ToastMsg from "../../../Components/ToastMsg/ToastMsg"
import { DateTime } from "luxon"
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation"

export default class PharmaBranchTransitDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            contactName: '',
            mobileNo: '',
            is_Success: false,
            trackID: '',
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    inputChange = (event) => {
        var name = event.target.name
        var value = event.target.value
        let isNum = CommonValidation.mobileNumber(value)
        let isName = CommonValidation.alphabetOnly(value)
        var states = this.state
        if (((name === "mobileNo")) && (isNum || value === "")) {
            states[name] = value
        } else if (name === "contactName" && (isName || value === "")) {
           states[name] = value
        }else if(name === "trackID"){
            states[name] = value
        }

        this.setState({
            states
        })
    }


    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    renderTexBox = (label, value, name) => {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <TextField
                    size="small"
                    id="transit-details"
                    inputProps={{ maxLength: name === "mobileNo" ? 10 : name === "trackID" ? 150 : '' }}
                    value={value}
                    name={name}
                    label={label}
                    onChange={(event) => {this.inputChange(event)}}
                    sx={{ width: '20vw' }}
                    variant="outlined" />
            </Box>
        )
    }
    clearValue = () => {
        this.setState({
            contactName: '',
            mobileNo: '',
            is_Success: false,
        })
    }

    postTransitDetails = () => {
        try{
            var states = this.state
            var states = this.state
                let data = {
                    "request_id":this.props.id,
                    "transit_name": states.contactName.replace(/\s+/g, ' ').trim(),
                    "transit_number":states.mobileNo,
                    "transit_track_id": states.trackID,
                }
                this.setState({
                    is_Success: true
                })
            if(states.contactName.replace(/\s+/g, ' ').trim() != '' && (states.mobileNo != '' && states.mobileNo.length === 10) && (states.trackID.replace(/\s+/g, ' ').trim() != '')){
                RestAPIService.create(data, Serviceurls.PHA_BRANCH_TRANSIT)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.setState({is_Success: false})
                            this.successMessage(response.data.message)
                            setTimeout(() => {
                                this.props.transitClose()
                            }, 1000);
                        }
                    }).catch((error) => {
                        this.setState({is_Success: false})
                        if (error?.response?.data?.message) {
                            this.errorMessage(error?.response?.data?.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }else{
                this.setState({is_Success: false})
                if(states.contactName.replace(/\s+/g, ' ').trim() === ''){
                    this.errorMessage('Please Enter Contact Name')
                }else if(states.mobileNo === '' || states.mobileNo.length < 10 ){
                    this.errorMessage('Please Enter Valid MobilNo')
                }else if(states.trackID.replace(/\s+/g, ' ').trim() === '' ){
                    this.errorMessage('Please Enter Track ID')
                }
            }
        }catch(e){
            this.setState({is_Success: false})
            this.errorMessage(e.message)
        }
    }

    render() {
        return (
            <Box>
                <Modal open={true}>
                    <Paper elevation={3} id="emedhub_Transit_mainBox">
                        <div id="emedhub_vendorDetails_header">
                            <Typography>{'Transit Details'}</Typography>
                            <IconButton size="small" onClick={() => { this.props.transitClose() }}><img className="emedhub_transit_img" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                        </div>
                        <Box sx={{ marginTop: '1vw' }}>
                            {this.renderTexBox('Contact Person Name', this.state.contactName, 'contactName')}
                        </Box>
                        <Box sx={{ marginTop: '1vw' }}>
                            {this.renderTexBox('Mobile No', this.state.mobileNo, 'mobileNo')}
                        </Box>
                        <Box sx={{ marginTop: '1vw' }}>
                            {this.renderTexBox('Track ID', this.state.trackID, 'trackID')}
                        </Box>
                        <Box sx={{marginTop: '3vw', }}>
                            <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'flex-end' }}>
                                <Button size="small" sx={{width: '5vw', textTransform: 'capitalize'}} variant="outlined" onClick={() => {this.clearValue()}}>{'Clear'}</Button>
                                <Button size="small" sx={{width: '5vw', textTransform: 'capitalize'}} variant="contained" disabled={this.state.is_Success} onClick={() => { this.postTransitDetails() }}>{"Submit"}</Button>
                            </Stack>
                        </Box>
                    </Paper>
                </Modal>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}