import React, { Component } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { toast_Time_out } from '../../Utility/Constants';

class ToastMsg extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toastOpen: true,
            vertical: this.props.msgtop ? 'top' : 'bottom',
            horizontal: this.props.msgtop ? 'center' : this.props.rightside ? 'right' : 'left'
        }
    }

    handleClose = () => {
        this.setState({
            toastOpen: false
        })
    }

    render() {
        setTimeout(() => {
            // this.setState({
            //     toastOpen: false
            // })
            this.props.msgPop()
        }, toast_Time_out);
        var {vertical, horizontal} = this.state
        return (
            <div>
                <Snackbar open={this.state.toastOpen}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
                    <Alert invoice_number={this.props.testId} admission_No ={this.props.AdmissionId ? this.props.AdmissionId  : null} emed_tid={'toast'} onClose={this.handleClose} variant="filled" severity={this.props.severity} sx={{ width: '100%' }}>
                        {this.props.msg}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}

export default ToastMsg;