export class ABHAurls {

    static AUTH_URL = "v0.5/sessions"
    static GENERATE_AADHAR_OTP = "api/v3/enrollment/request/otp"
    static VERIFY_AADHAR_OTP = "api/v3/enrollment/enrol/byAadhaar"

    static RETRIEVE_ABHA_ACCOUNT_VIA_AADHAR = "api/v3/profile/login/request/otp"
    static VERIFY_ABHA_ACCOUNT_VIA_AADHAR = "api/v3/profile/login/verify"
    static VERIFY_ABHA_ACCOUNT_VIA_DEMOGRAPHICS = "api/v3/identity/authentication"
    static ABHA_ADDRESS_SUGGESTION = "api/v3/enrollment/enrol/suggestion"
    static ABHA_ADDRESS_POST = "api/v3/enrollment/enrol/abha-address"
    static GET_ABHA_CARD = "api/v3/profile/account/abha-card"
    static GET_ABHA_PROFILE_ACC = "api/v3/profile/account"
    static VERIFY_ABHA_USER = "api/v3/profile/login/verify/user"
    static DEMO_AUTHENTICATION = "api/v3/identity/authentication"
}