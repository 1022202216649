import React, { Component } from "react";
import { Box, Stack, TextField, IconButton, Tooltip, Autocomplete, Button } from "@mui/material";
import { ImagePaths } from '../../../Utility/ImagePaths';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import CommonGridHeader from '../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import Loader from '../../../Components/Loader';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';

let userAccess = null;
let RoleData = null;

class PharmacyOrderedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderListData: [],
      VendorList: [],
      page: 0,
      pageSize: 10,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      disableBtn: false,
    }
  }
  componentDidMount() {
    RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
    userAccess = CheckAccessFunc(RoleData?.pharmacy_type === 'Inhouse Pharmacy'? "Pharmacy" : "Standalone Pharmacy", "Purchase", 'Wanted List', 'Order List', "SubTab");
    this._getOrderList();
    this.getVendorList()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  _getOrderList = () => {
    try {
      this.LoaderFunction(true);
      RestAPIService.getAll(`${Serviceurls.PURCHASE_WANTED_LIST_CART}`)
        .then(response => {
          if (response.data.status == "success") {
            this.LoaderFunction(false);
            let list = response.data?.data?.length > 0 ? response.data.data : [];
            list.length > 0 && list?.forEach((item, i) => {
              item["sno"] = i + 1;
              if(item.order_quantity){
                let buyingPrice = parseInt(item.order_quantity) * parseFloat(item.mrp_per_quantity);
                item["price_per_quantity"] = Math.round(buyingPrice);
              }
            })
            this.setState({
              orderListData: list,
            });
          }
        })
        .catch(error => {
          if (error.response?.data?.status == "fail") {
            this.LoaderFunction(false);
            this.errorMessage(error.response?.data?.message);
          }
        });
    } catch (e) {
      this.errorMessage(e.message);
    }
  }

  getVendorList() {
    try {
      RestAPIService.getAll(Serviceurls.PURCHASE_VENDOR_LIST + `?from=purchase_order`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              VendorList: response.data?.data,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  handleTblChange = (e, params) => {
    let { name, value } = e.target;
    switch (name) {
      case "order_quantity":
        let isvalid = CommonValidation.numberOnly(value);
        if (isvalid && value > 0 || value === "") {
          let buyingPrice = (value ? parseInt(value) : 0) * parseFloat(this.state.orderListData[params.id - 1]["mrp_per_quantity"]);
          this.state.orderListData[params.id - 1]["order_quantity"] = +value.trimStart();
          this.state.orderListData[params.id - 1]["price_per_quantity"] = Math.round(buyingPrice);
        }
        break;
      default:
        let isNum = CommonValidation.numberOnly(value);
        if (isNum && value > 0 || value === "") {
          this.state.orderListData[params.id - 1][name] = +value.trimStart();
        }
        break;
    }

    this.setState({
      orderListData: this.state.orderListData
    })
  }

  renderTblTxtBox = (params, stateKey) => {
    let row = params.row;
    return (
      <TextField
        inputProps={{
          style: {
            height: "2vw",
            padding: '0 14px',
          },
          
        }}
        type="number"
        disabled={stateKey === "price_per_quantity" || !userAccess?.editAccess}
        autoComplete='off'
        emed_tid = {stateKey}
        name={stateKey}
        value={row[stateKey] ? row[stateKey] : null}
        onChange={(e) => { this.handleTblChange(e, params) }}
        className='eMed_OrdTxtField'
      />
    )
  }

  handleRemove = (id) => {
    if (id) {
      try {
        this.LoaderFunction(true);
        RestAPIService.delete(`${Serviceurls.PURCHASE_WANTED_LIST_CART}?id=${id}`)
          .then(response => {
            if (response.data.status == "Success") {
              this.LoaderFunction(false);
              this.successMessage(response.data.message);
              this._getOrderList();
            }
          })
          .catch(error => {
            if (error.response.data.status == "Fail") {
              this.LoaderFunction(false);
              this.errorMessage(error.response.data.message);
            }
          })
      } catch (e) {
        this.errorMessage(e.message);
      }
    }
  }

  postOrderList = () => {
    let { orderListData } = this.state;
    let isValid = this.checkAllDetailsAreAvailable(orderListData);
    if (isValid) {
      let datas = {
        order_list: []
      }
      orderListData.forEach(item => {
        let tempObj = {
          available_quantity: item.available_quantity,
          buying_price: item.price_per_quantity,
          discount_percentage: item.discount_percentage,
          dosagetype: item.dosagetype,
          drug_id: item.drug_id,
          drug_name: item.drug_name,
          drug_strength: item.drug_strength,
          estimated_epr: item.estimated_epr,
          expiry_date: item.expiry_date,
          free_quantity: item.free_quantity,
          generic_name: item.generic_name,
          id: item.id,
          is_added_to_cart: item.is_added_to_cart,
          mrp_per_quantity: item.mrp_per_quantity,
          order_quantity: item.order_quantity,
          stock_id: item.stock_id,
          tempPaginationId: 1,
          vendor_id: item.vendor_id,
          vendor_name: item.vendor_name
        }
        datas["order_list"].push(tempObj)
      })
      try {
        this.LoaderFunction(true);
        RestAPIService.create(datas, Serviceurls.PURCHASE_CONFIRM_ORDER_LIST)
          .then(response => {
            if (response.data.status === "Success") {
              this.setState({ disableBtn: false })
              this.LoaderFunction(false);
              this._getOrderList();
              this.successMessage(response.data.message);
            }
          })
          .catch(error => {
            this.setState({ disableBtn: false })
            if (error?.response?.data?.status === "fail") {
              this.LoaderFunction(false);
              this.errorMessage(error.response.data.message);
            }
          });
      } catch (e) {
        this.setState({ disableBtn: false })
        this.errorMessage(e.message);
      }
    }
  }

  checkAllDetailsAreAvailable(newEntry) {
    const { t } = this.props
    var defaultReturn = true
    for (const data of newEntry) {
      if (!data.order_quantity) {
        this.errorMessage(t("KindlyaddQuantity"));
        this.setState({ disableBtn: false })
        defaultReturn = false
      } else if (!data.vendor_id) {
        this.errorMessage(t("KindlyaddVendor"));
        this.setState({ disableBtn: false })
        defaultReturn = false
      }
    }
    return defaultReturn
  }

  render() {
    const { t } = this.props;
    let { orderListData } = this.state;

    const columns = [
      {
        field: "sno", flex: 0.04, headerName: t("SNo"), headerAlign: "center", align: "center"
      },
      {
        field: "drug_name", flex: 0.1, headerName: t("BrandName"),
        renderCell: (params) => (params?.row?.drug_name?.length > 15 ?
          <Tooltip placement="top" title={params?.row?.drug_name} arrow><div>{params?.row?.drug_name.slice(0, 14) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.drug_name ? params?.row?.drug_name : "-"}</Box>)
      },
      {
        field: "generic_name", flex: 0.09, headerName: t("GenericName"),
        renderCell: (params) => (params?.row?.generic_name?.length > 15 ?
          <Tooltip placement="top" title={params?.row?.generic_name} arrow><div>{params?.row?.generic_name.slice(0, 14) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.generic_name ? params?.row?.generic_name : "-"}</Box>)
      },
      {
        field: "dosagetype", flex: 0.1, headerName: t("Dosage Type / Str"),
        renderCell: (params) => {
          let dosage = `${params?.row?.dosagetype ? params?.row?.dosagetype : "-"} / ${params?.row?.drug_strength ? params?.row?.drug_strength : "-"}`
          return (dosage?.length > 19 ?
            <Tooltip placement="top" title={dosage} arrow><div>{dosage.slice(0, 18) + "..."}</div></Tooltip>
            : <Box component={'div'}>{dosage ? dosage : "-"}</Box>)
        }
      },
      {
        field: "expiry_date", flex: 0.08, headerName: t("Recent Exp. On"), headerAlign: "center", align: "center"
      },
      {
        field: "available_quantity", flex: 0.07, headerName: t("Avail.Qty"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.available_quantity ? params?.row?.available_quantity : "-"}</Box>)
      },
      {
        field: "mrp_per_quantity", flex: 0.08, headerName: t("Latest MRP/Qty"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.mrp_per_quantity ? params?.row?.mrp_per_quantity : "-"}</Box>)
      },
      {
        field: "vendor_name", flex: 0.1, headerName: t("VendorName"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}  emed_tid= {"ordvnd"}>
          <Autocomplete
            size='small'
            clearIcon={false}
            sx={{ width: "10vw" }}
            disabled = {!userAccess?.editAccess}
            componentsProps={{ popper: { style: { width: '12vw' } } }}
            options={this.state.VendorList}
            getOptionLabel={(option) => option?.name}
            value={this.state.VendorList.find((item) => (item.vendor_id === params.row.vendor_id)) ? this.state.VendorList.find((item) => (item.vendor_id === params.row.vendor_id)) : null}
            onChange={(event, newValue) => {
              this.state.orderListData[params.id - 1]["vendor_id"] = newValue?.vendor_id
              this.setState({
                orderListData: this.state.orderListData
              })
            }}
            renderInput={(params) => (<TextField {...params} variant='standard' />)}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )
            }}
          />
        </Box>)
      },
      {
        field: "order_quantity", flex: 0.07, headerName: t("Order Qty"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{this.renderTblTxtBox(params, "order_quantity")}</Box>)
      },
      {
        field: "free_quantity", flex: 0.07, headerName: t("Free Qty(Unit(s))"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{this.renderTblTxtBox(params, "free_quantity")}</Box>)
      },
      {
        field: "discount_percentage", flex: 0.07, headerName: t("Discount %"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{this.renderTblTxtBox(params, "discount_percentage")}</Box>)
      },
      {
        field: "price_per_quantity", flex: 0.07, headerName: t("PU Rate"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{this.renderTblTxtBox(params, "price_per_quantity")}</Box>)
      } 
    ]

    if (userAccess?.editAccess) {
      columns.push(
        {
          field: "action", flex: 0.06, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
          renderCell: ({ row }) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Tooltip title={t("Remove")} placement="top" arrow>
              <IconButton emed_tid= "ord_rmv" className='eMed_usrconf_btn' onClick={() => { this.handleRemove(row?.id) }}>
                <img src={ImagePaths.CrossIcon.default} alt="Remove" className='eMed_action_img' />
              </IconButton>
            </Tooltip>
          </Box>
        }
      );
    }

    return (
      <Box className="emedhub__purchase_page">
        { userAccess?.editAccess ? <div className='eMed_Purchase_Table_top' style={{ justifyContent: 'flex-end' }}>
          <Button emed_tid= "cnf_ord" className='eMed_Filter_Btns' variant='contained' size='small' sx={{ display: orderListData.length > 0 ? "block" : "none" }} disabled={this.state.disableBtn}
            onClick={() => { this.setState({ disableBtn: true },() => {this.postOrderList() })  }}
          >{t("Confirm Order")}</Button>
        </div> : null }
        <div className="eMed_Purcahse_Table_Container">
          <DataGrid
            rows={orderListData}
            columns={columns}
            disableColumnFilter
            initialState={{
              columns: {
                columnVisibilityModel: {
                  available_quantity: false,
                }
              },
            }}
            hideFooterSelectedRowCount
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay: () => {
                return (
                  <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }
            }}
            getRowId={(row) => row.sno}
            rowCount={orderListData.length}
            page={this.state.page}
            pageSize={this.state.pageSize}
            onPageChange={(newPage) => this.setState({ page: newPage })}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination />
        </div>
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(PharmacyOrderedList);