import { QuestionMark } from '@mui/icons-material'
import { Box, Button, Fab, Grid, Modal, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { ImagePaths } from '../../../Utility/ImagePaths'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import './reports.css'
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import { Colors } from '../../../Styles/Colors'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'

const reportsList = [
  {
    title: 'OP Service Reports', icon: ImagePaths.opConfig.default, Access: CheckAccessFunc("front_office", "Reports", "Service Reports", null, "Tab"),
    reports: [
      { report_names: 'OP Service Detailed', path: '/FOReports/ServiceReports/ServiceDetailed' },
      { report_names: 'OP Service Overview', path: '/FOReports/ServiceReports/ServiceOverview' },
      { report_names: 'OP Service Share',path:"/FOReports/ServiceReports/OPServiceShare"},
      { report_names: 'Consultation Wise Report',path:"/FOReports/ServiceReports/ConsultWiseReport"},
    ]
  },
  {
    title: 'IP Service Reports', icon: ImagePaths.opConfig.default, Access: CheckAccessFunc("front_office", "Reports", "IP Service Reports", null, "Tab"),
    reports: [
      { report_names: 'IP Service Detailed', path: '/FOReports/IpServiceReport/IPServiceDetailed' },
      { report_names: 'IP Service Overview', path: '/FOReports/IpServiceReport/IPServiceOverview' },
      { report_names: 'IP Service Share', path: '/FOReports/ServiceReports/IPServiceShare' },
      { report_names: 'Ward-wise Report', path: '/FOReports/IpServiceReport/WardwiseReport' },
      { report_names: 'IP Leger Report', path: '/FOReports/IpServiceReport/IPLegerReport' },
      { report_names: 'IP Admission List', path: '/FOReports/IpServiceReport/IPAdmissionList' },
      { report_names: 'IP Discharge List', path: '/FOReports/IpServiceReport/IPDischargeList' },
      { report_names: 'IP Occupancy Report', path: '/FOReports/IpServiceReport/IPOccupancyReport' }
    ]
  },
  {
    title: 'Corporate and Insurance Reports', icon: ImagePaths.corporateConfig.default, Access: CheckAccessFunc("front_office", "Reports", "Corporate Reports", null, "Tab"),
    reports: [
      { report_names: 'Corporate Patient Bill Report', path: '/FOReports/CorporateReports/BillReport' },
      { report_names: 'Corporate Payment Report', path: '/FOReports/CorporateReports/PaymentReport' },
      { report_names: 'Insurance Patient Bill Report', path: '/FOReports/CorporateReports/InsuranceReport' }
    ]
  },
  {
    title: 'Appointment Reports', icon: ImagePaths.Appointment.default, Access: CheckAccessFunc("front_office", "Reports", "Appointment Reports", null, "Tab"),
    reports: [
      { report_names: "Patients Appointment Report", path: '/FOReports/AppointmentReports/PatientReport' },
      { report_names: "Doctors Appointment Report", path: '/FOReports/AppointmentReports/DoctorReport' },
      { report_names: 'Specialization Appointment Report', path: '/FOReports/AppointmentReports/SpecializationReport' },
      { report_names: 'Appointment Type Report', path: '/FOReports/AppointmentReports/TypeBasedReport' },
      { report_names: 'TAT Report', path: '/FOReports/AppointmentReports/TATReport' },
      { report_names: 'No Show Report', path: '/FOReports/AppointmentReports/NoShowReport' },
      { report_names: 'Counsellor Surgery Conversion Report ', path: '/FOReports/AppointmentReports/CounsellorSurgeryReport' },
    ]
  },
  {
    title: 'Patient Reports', icon: ImagePaths.demographicConfig.default, Access: CheckAccessFunc("front_office", "Reports", "Patient Reports", null, "Tab"),
    reports: [
      { report_names: 'Patient Demographics', path: '/FOReports/PatientReport/PatientDemographicReport' },
      { report_names: 'Purpose Of Visit Report', path: '/FOReports/PatientReport/PurposeOfVisitReport' },
      { report_names: 'Reason / Diagnosis Report', path: '/FOReports/PatientReport/ReasonDiagnosisReport' },
      { report_names: 'Concession Report', path: '/FOReports/PatientReport/ConcessionReport' },
      { report_names: 'Credit Settlement Report', path: '/FOReports/PatientReport/CreditSettlementReport' },
    ]
  },
  {
    title: 'Referral Reports', icon: ImagePaths.referral.default, Access: CheckAccessFunc("front_office", "Reports", "Referral Reports", null, "Tab"),
    reports: [
      { report_names: 'Referral Doctor Report', path: '/FOReports/ReferralReports/DoctorReport' },
      { report_names: 'Referral Source Report', path: '/FOReports/ReferralReports/SourceReport' },
      { report_names: 'Reference Value Report', path: '/FOReports/ReferralReports/ReferalValueReport' }
    ]
  },
  {
    title: 'Ophthalmology Reports', icon: ImagePaths.referral.default, Access: CheckAccessFunc("front_office", "Reports", "Ophthalmology Reports", null, "Tab"),
    reports: [
      { report_names: 'Optical CRM Sales', path: '/FOReports/OptReports/OpticalCRMReport' },
      { report_names: 'Prescription Report', path: '/FOReports/OptReports/PrescriptionReport' }
    ]
  },
  {
    title: 'Optometrist Reports', icon: ImagePaths.referral.default, Access: CheckAccessFunc("front_office", "Reports", "Optometrist Reports", null, "Tab"),
    reports: [
      { report_names: 'Optometrist List', path: '/FOReports/OptometristReports/OptometristList' },
    ]
  },
]

class ReportsHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openPopUp: false,
      newReport: "",
      isOPT: false,
      isdoctor: true,
      isAccounts: true
    }
  }

  componentDidMount() {
    let userData = JSON.parse(localGetItem("loggedInUserInfo"))
    if (userData?.optical_crm_sales && reportsList[reportsList.length - 1]["title"] !== "Ophthalmology Reports") {
      reportsList[reportsList.length - 1] = {
        title: 'Ophthalmology Reports', icon: ImagePaths.referral.default, Access: CheckAccessFunc("front_office", "Reports", "Ophthalmology Reports", null, "Tab"),
        reports: [
          { report_names: 'Optical CRM Sales', path: '/FOReports/OptReports/OpticalCRMReport' },
        ]
      }
    }
    this.setState({
      isOPT : userData?.is_opt,
    },()=>{
      if (userData?.show_tally_report) {
        let tallyReportExists = reportsList.some(report => report.title === 'Tally Reports');
        if (!tallyReportExists) {
          reportsList.push({
            title: 'Tally Reports', icon: ImagePaths.opConfig.default, Access: CheckAccessFunc("front_office", "Reports", "Tally Reports", null, "Tab"),
            reports: [
              { report_names: 'Generate Report', path: '/FOReports/TallyReports/GenerateReport' },
            ]
          });
        }
    }
    })
    if (userData?.account_tally_report) {
      let showAccountReport = reportsList?.some(item => item.title === 'Accounts Reports')
      if (!showAccountReport) {
        reportsList.push({
          title: 'Accounts Reports', icon: ImagePaths.opConfig.default, Access: CheckAccessFunc("front_office", "Reports", "Account Reports", null, "Tab"),
          reports: [
            { report_names: 'Bill Wise Reports', path: '/FOReports/AccountReports/BillWiseReports' },
            { report_names: 'Advance Reports', path: '/FOReports/AccountReports/AdvanceReports' },
            { report_names: 'Refund/Cancelled Reports', path: '/FOReports/AccountReports/RefundorCancelledReports' },
            { report_names: 'Credit Service Reports', path: '/FOReports/AccountReports/CreditServiceReports' },
            { report_names: 'Credit Settlement Report', path: '/FOReports/AccountReports/AccountsCreditSettlementReport' },
            { report_names: 'Concession Reports', path: '/FOReports/AccountReports/ConcessionReports' },
          ]
        })
      }
    }
    if(userData?.doctor_share_report){
      reportsList?.forEach(element => {
        if(element.title === 'Accounts Reports'){
          let showDocShare = element.reports.some(item => item.report_names === 'Doctor Share Reports')
          if(!showDocShare){
            element.reports?.push({report_names:'Doctor Share Reports',path:'/FOReports/AccountReports/DoctorShareReports'})
          }
        }
      })
    }
  }

    errorMessage = (message) => {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: message
      })
    }

    successMessage = (message) => {
      this.setState({
        successMsg: true,
        successMsgText: message
      })
    }

    onChangeHandler = (e) => {
      const { name, value } = e.target
      this.setState({
        [name]: value
      })
    }

    reportsList = (item) => {
      return (
        <>
          <Box component={'div'} className='eMed_rts_card'>
            <Paper className='eMed_rts_content'>
              <Grid container className='eMed_rts_content_top'>
                <Grid item xs={12} className='eMed_rts_txt_one'>
                  <Stack display={'flex'} flexDirection='row'>
                    <img src={item.icon} alt="" className='eMed_rts_icon' />
                    <Typography fontWeight={'600'}>{item.title}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} className='eMed_rts_content_btm'>
                  {item.reports.map((list, index) => {
                    if ((list.report_names !== 'No Show Report' || localGetItem("noShowStatus") === "true")) {
                      return <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Typography display={index === 0 ? "none" : 'inline'} color={Colors.themeDark}>|</Typography>
                        <Button emed_tid={"report_names_TestID"} newReport key={index} className='eMed_reports_text_btn'
                          onClick={() => {
                            this.props.history.push({ pathname: list.path })
                          }}>{list.report_names}</Button>
                      </Box>
                    }
                  })}
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </>
      )
    }

    handleOpen = () => {
      this.setState({ openPopUp: true })
    }

    handleClose = () => {
      this.setState({ openPopUp: false, newReport: "" })
    }

    PostExtraReportText = () => {
      try {
        let data = { "new_report": this.state.newReport }
        RestAPIService.create(data, Serviceurls.FO_REPORTS_POST)
          .then((response) => {
            if (response.data.status === "success") {
              this.successMessage(response.data.message)
              this.setState({
                openPopUp: false
              })
            }
          }).catch((error) => {
            if (error?.response?.data?.status === "fail") {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } catch (error) {
        this.errorMessage(error.message)
      }
    }

    renderReportsPopup = () => {
      const { t } = this.props
      return (
        <>
          <Modal open={this.state.openPopUp} >
            <Box className='eMed_rts_popup'>
              <Box className='eMed_rts_home_header'>
                <Typography component={'label'} variant='h6' fontWeight={600}>{t("extraReports")}</Typography>
                <Typography component={'p'} className='eMed_rts_home_txt_one'>{t("reportsNeedType")}</Typography>
              </Box>
              <Box component={'div'} margin="1vw" >
                <TextField
                  inputProps={{ emed_tid: "newReport_testID002" }}
                  variant='standard'
                  rows={6}
                  multiline
                  fullWidth
                  placeholder='Write what type of report you need'
                  className='eMed_rts_home_txt'
                  value={this.state.newReport}
                  name='newReport'
                  emed_tid={"newReport_testID"}
                  onChange={(e) => this.onChangeHandler(e)}
                />
              </Box>
              <Box component='div' margin={'1vw'} display={'flex'} justifyContent='flex-end'>
                <Button emed_tid={"Back_testID"} variant='outlined'
                  className='eMed_rts_home_btn'
                  size='small'
                  onClick={() => { this.handleClose() }} >{t("Back")}</Button>
                <Button
                  emed_tid={"Send_testID"}
                  variant='contained'
                  className='eMed_rts_home_btn_two'
                  size='small'
                  onClick={() => { this.PostExtraReportText() }}
                >{t("Send")}</Button>
              </Box>
            </Box>
          </Modal>
        </>
      )
    }
    msgClose() {
      this.setState({
        isErrorMsg: false,
        isErrorMsgText: '',
        successMsg: false,
        successMsgText: ''
      })
    }
    render() {
      const { t } = this.props
      return (
        <>
          <Box component={'div'} className='eMed_rts_home_container' overflow={'scroll'}>
            <Box position={'static'}>
              <Typography fontWeight={'600'} padding='1vw' borderBottom={'1px solid lightgray'}>{t("Reports")}</Typography>
            </Box>
            <Box>
              {reportsList.map((item, index) => {
                if ((CheckAccessFunc("front_office", "Reports", item.title, null, "Tab").viewAccess && (item.title !== "Optometrist Reports") && (item.title !== "Ophthalmology Reports")) || (this.state.isOPT && item.title === "Optometrist Reports") || (this.state.isOPT && item.title === "Ophthalmology Reports")) {
                  return (<Stack key={index}>{this.reportsList(item)}</Stack>)
                }
              })}
            </Box>
            <Tooltip placement='top' title='Raise New Reports' arrow><Fab color="primary"
              size='medium'
              emed_tid={"handleOpen_TestID"}
              className='eMed_rts_fab'
              onClick={() => { this.handleOpen() }}
            >
              <QuestionMark />
            </Fab></Tooltip>
            {this.renderReportsPopup()}
            {this.state.isErrorMsg ?
              <ToastMsg
                severity={'error'}
                msg={this.state.isErrorMsgText}
                msgPop={this.msgClose.bind(this)}
              />
              : null}
            {this.state.successMsg ?
              <ToastMsg
                severity={'success'}
                msg={this.state.successMsgText}
                msgPop={this.msgClose.bind(this)}
              />
              : null}
          </Box>
        </>
      )
    }
  }

export default withTranslation()(ReportsHome)