import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import "./Billings.css"
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Box, Checkbox, Divider, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Tooltip, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { Colors } from '../../../Styles/Colors';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { getCachevalue, localGetItem } from '../../../Utility/Services/CacheProviderService';
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CircularProgress from '@mui/material/CircularProgress';
import './Billings.css';
import { CurrencySymbol } from '../../../Utility/Constants';

const IpCreditTypeList = [
    { value: "Patient credit", label: "Patient Credit" },
    { value: "Insurance credit", label: "Insurance Credit" },
    { value: "Corprate credit", label: "Corporate Credit" },
]

const CreditTypeList = [
    { value: "Patient credit", label: "Patient Credit" },
    { value: "Corprate credit", label: "Corporate Credit" },
]
class CreditTypeChangePop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Open: true,
            BillData: this.props.BillData ? this.props.BillData : {},
            CreditType: this.props?.BillData?.credit_type ? this.props?.BillData?.credit_type : "",
            disable:false
        }
    }
    handleClose = () => {
        this.setState({
            Open: false,
        })
        this.props.ClosePopUp()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    ClearData() {
        this.setState({
            CreditType: this.props?.BillData?.credit_type ? this.props?.BillData?.credit_type : "",
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    CreditTypePost() {
        try {
            let { t, BillData } = this.props
            let data = {
                "patient_id": BillData?.patient_id,
                "module_id": BillData?.module_id,
                "module_type": BillData?.bill_types,
                "credit_type": this.state.CreditType,
                "employer_id": null
            }
            if (this.state.CreditType === "Corprate credit") {
                data.employer_id = BillData?.employer_id_no ? BillData?.employer_id_no : BillData?.employer_id ? BillData?.employer_id : null;
                data["employee_id"] = BillData?.employee_id ? BillData?.employee_id : BillData?.employee_id_no ? BillData?.employee_id_no : "";
            }
            // this.setState({disable:true})
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.CREDIT_TYPE_CHANGE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({disable:false})
                        this.props.CrebillTypeChaged(response.data.message)
                    }
                    else {
                        this.setState({disable:false})
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.setState({disable:false})
                    if (error?.response?.data?.status) {
                        this.errorMessage(error.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch(e){
            this.setState({disable:false})
            this.errorMessage(e.message)
        }
    }

    render() {
        let { t, BillData } = this.props
        let BillAmount = BillData?.total_amount;
        let PendingAmount = BillData?.pending_amount;
        let PatientName = BillData?.patient_names ? BillData?.patient_names : BillData?.patient_name
        let PatientAge = BillData?.patient_age
        let PatientUHID = BillData?.uhid
        let PatientMobile = BillData?.mobile_number
        let isDisable = BillData?.is_final_bill_generated
        let creditList = BillData.bill_types === "In Patient" ? IpCreditTypeList : CreditTypeList
        return (
            <div>
                <Dialog open={this.state.Open} >
                    <DialogTitle className='eMed_CreditPoP_TitleDiv' sx={{ borderColor: Colors.themeDark }}>
                        <Typography fontWeight={600} marginLeft={"1vw"}>{t("Change Credit Type")}</Typography>
                        <Button onClick={() => { this.handleClose() }} >
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                        </Button>
                    </DialogTitle>
                    <DialogContent sx={{ paddingTop: 0, paddingBottom: 0, maxHeight: "38vw" }}>
                        <Box className="eMed_Crdtype_Header">
                            <Box>
                                <Typography color={Colors?.grayShade}>Patient Details</Typography>
                                {
                                    PatientName?.length > 18 ? <Tooltip placement='top' title={PatientName} arrow>
                                        <Typography fontSize={"0.9vw"}>{`${PatientName.slice(0, 15) + "..."}  ( ${PatientAge} )`}</Typography>
                                    </Tooltip> : <Typography fontSize={"0.9vw"}>{`${PatientName}  ${PatientAge ? `(${PatientAge})`: ""}`}</Typography>
                                }
                                <Typography fontSize={"0.8vw"}>{`${PatientUHID} | ${PatientMobile}`}</Typography>
                            </Box>
                            <Box>
                                <Typography color={Colors?.grayShade}>Bill Amount</Typography>
                                <Typography>{`${CurrencySymbol} ${BillAmount}`}</Typography>
                            </Box>
                            <Box>
                                <Typography color={Colors?.grayShade}>Pending Amount</Typography>
                                <Typography>{`${CurrencySymbol} ${PendingAmount}`}</Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <Box>
                            <FormControl disabled={isDisable} sx={{ width: "14vw", marginTop: '1vw' }} size="small">
                                <InputLabel>{"Select Credit Type"}</InputLabel>
                                <Select
                                    value={this.state.CreditType}
                                    label={"Select Credit Type"}
                                    onChange={(event) => {
                                        this.setState({
                                            CreditType: event.target.value
                                        })
                                    }}
                                >
                                    {
                                        creditList?.map((list, index) => (
                                            <MenuItem key={index} value={list.value} >{list.label}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='outlined' size='small' className='eMed_Filter_Btns' onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                        <Button
                            sx={{ width: "5vw" }}
                            variant='contained' size='small'
                            className='eMed_Filter_Btns'
                            disabled={this.state.disable}
                            onClick={() => {
                                this.setState({
                                    disable : true
                                },()=>{
                                    this.CreditTypePost()
                                })
                            }}>{t("Change")}</Button>
                    </DialogActions>
                </Dialog>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        )
    }
}

export default withTranslation()(CreditTypeChangePop)
