import React, { Component } from 'react';
import {
    Box, Paper, Stack, IconButton, TablePagination, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography,
    Tooltip,
    Collapse,
    Button
} from '@mui/material';
import { withTranslation } from 'react-i18next'
import './Billings.css';
import Drawer from '@mui/material/Drawer';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../Styles/Colors';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { CurrencySymbol } from '../../../Utility/Constants';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import CircularProgress from '@mui/material/CircularProgress';


class FOReceiptBills extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            BillList: [],
            FilterOpen: false,
            tblPage: 0,
            tblPageSize: 10,
            tableSelectedIndex: null,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            totalNetAmt: 0,
            isLoader: false,
        }
    }

    componentDidMount() {
        this.getReceiptBills()
        document.addEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        if (event.ctrlKey && event.keyCode === 80) {
            event.preventDefault()
            this.PrintReport()
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getReceiptBills = () => {
        try {
            var states = this.state
            console.log(states.fromDate, 'sdfdsdf');
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_RECEIPT_DETAIL + `?from_date=${states.fromDate}&to_date=${states.toDate}`)
                .then(response => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.setState({
                            BillList: response?.data?.data ? response?.data?.data : [],
                            totalNetAmt: response?.data?.total_net_amount ? response?.data?.total_net_amount : 0,
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(e => {
                    this.LoaderFunction(false)
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })

        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    getReceiptPrint = (data) => {
        try {
            let API_URL = ""
            if (data?.module === "OT") {
                API_URL = Serviceurls.GET_PRINT_PREVIEW + `?export=pdf&id=` + data?.ot_bill_id
            }
            else if (data?.module === "IP") {
                API_URL = Serviceurls.IP_INVOICE_PRINT + `?ip_bill_summary_id=` + data?.ip_bill_id
            }
            else if (data?.module === "OP") {
                API_URL = Serviceurls.OP_INVOICE_PRINT + "?invoce_id=" + data?.op_bill_id
            }
            else if (data?.module === "Pharmacy") {
                API_URL = Serviceurls.PHARMACY_INVOICE_PRINT + "?id=" + data?.ph_bill_id + "&export=pdf"
            }
            else if (data?.module === "Lab") {
                API_URL = Serviceurls.LAB_CREATE_BILL_COMPLETED_PRINT + "?bill_summary_id=" + data?.lab_bill_id + "&export_type=pdf" + "&laboratory_id=" + data?.lab_id + "";
            } 
            else if (data?.module === "Radiology") {
                API_URL = Serviceurls.RADIOLOGY_PRINT + "?bill_summary_id=" + data?.rad_bill_id + "&export_type=pdf";
            }

            RestAPIService.getAll(API_URL).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((e) => {
                    if (e.response?.data?.message) {
                        this.errorMessage(e.response?.data?.message);
                    } else {
                        this.errorMessage(e.message);
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }


    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
            ChangePaymentPopup: false,
        }, () => {
            if (Getapicall && message !== "") {
                this.successMessage(message)
                this.getReceiptBills()
            }
        })
    }

    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => { this.getReceiptBills() })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }


    clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    PaymentModeChanged(message) {
        this.setState({
            ChangePaymentPopup: false,
        }, () => {
            this.successMessage(message)
            this.getReceiptBills()
        })
    }

    renderTbl = () => {
        var { BillList } = this.state
        return (
            <Box component={'div'} border={'1px solid lightgray'} height={'62vh'} overflow={'auto'}>
                <Box component={'div'} height={'52.5vh'} overflow={'auto'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>S.No</TableCell>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Receipt Details</TableCell>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Patient Details</TableCell>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill Type</TableCell>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Receipt For</TableCell>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>{`${'Payment Details'} (${CurrencySymbol})`}</TableCell>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Collected By</TableCell>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }} />
                        </TableHead>
                        <TableBody>
                            {BillList?.length > 0 && BillList?.slice(
                                this.state.tblPage * this.state.tblPageSize,
                                this.state.tblPage * this.state.tblPageSize +
                                this.state.tblPageSize)?.map((item, index) => {
                                    var sno = (this.state.tblPage * this.state.tblPageSize) + (index + 1)
                                    return (
                                        <><TableRow key={index}
                                            onClick={() => {
                                                this.setState({
                                                    tableSelectedIndex: this.state.tableSelectedIndex === index ? null : index
                                                })
                                            }}
                                        >
                                            <TableCell>{sno}</TableCell>
                                            <TableCell>
                                                <Box component={'div'} emed_tid='emed_lab_rcpt_dtls'>
                                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.receipt_number ?
                                                        item?.receipt_number?.length > 18 ?
                                                            <Tooltip placement='top' title={item?.receipt_number}><div style={{ fontSize: '0.9vw', fontWeight: 600 }}>{'...' + item?.receipt_number?.slice(4)}</div></Tooltip> :
                                                            item?.receipt_number : '-'}</Typography>
                                                    <Typography color={Colors.grayShade} fontSize={'0.8vw'}>{item?.receipt_date ? item?.receipt_date : '-'}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box component={'div'} emed_tid='emed_fo_pat_dtls'>
                                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.patient_names ?
                                                        item?.patient_names?.length > 15 ?
                                                            <Tooltip placement='top' title={item?.patient_names}><div style={{ fontWeight: 600, fontSize: '0.9vw' }}>{item?.patient_names?.slice(0, 15) + '...'}</div></Tooltip> :
                                                            item?.patient_names : '-'}</Typography>
                                                    <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{item?.patient_uhid ? item?.patient_uhid : '-'}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box component={'div'} emed_tid='emed_fo_rcpt_type'>
                                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.receipt_type}</Typography>
                                                    <Typography fontSize={"0.8vw"} color={Colors?.grayShade}>{` Paid in : ${item?.collected_in ? item?.collected_in : "-"}`}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box component={'div'} emed_tid='emed_fo_rcpt_type'>
                                                    {item?.receipt_for ? (item?.receipt_for === "Bill Receipt" && item?.against_credit) ? <Box>
                                                        <Typography fontSize={"0.9vw"} fontWeight={600}>Bill Receipt</Typography>
                                                        <Typography fontSize={"0.8vw"} color={Colors?.grayShade}>Against Credit</Typography>
                                                    </Box> : <Typography fontWeight={600} fontSize={'0.9vw'}>{item?.receipt_for ? item?.receipt_for : '-'}</Typography> : "-"}
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box component={'div'} emed_tid='emed_fo_rcpt_type'>
                                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.amount_received ? AmountFormat(item?.amount_received)?.replace(`${CurrencySymbol}`, "") : "0"}</Typography>
                                                    <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{item?.payment_mode_types ? item?.payment_mode_types?.length > 14 ?
                                                        <Tooltip placement='top' title={item?.payment_mode_types} arrow>
                                                            <div>{item?.payment_mode_types.slice(0, 12) + "..."}</div></Tooltip> :
                                                        item?.payment_mode_types : "-"}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box component={'div'} emed_tid='emed_fo_coll_by'>
                                                    <Typography fontWeight={600} fontSize={'0.9vw'}>{item?.user_name ? item?.user_name?.length > 15 ?
                                                        <Tooltip placement='top' title={item?.user_name}><div style={{ fontWeight: 600, fontSize: '0.9vw' }}>{item?.user_name?.slice(0, 15) + '...'}</div></Tooltip> :
                                                        item?.user_name : '-'}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    size='small'
                                                    onClick={() => {
                                                        this.setState({
                                                            tableSelectedIndex: this.state.tableSelectedIndex === index ? null : index
                                                        })
                                                    }}
                                                >
                                                    {this.state.tableSelectedIndex === index ?
                                                        <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                            <TableRow>
                                                {this.renderSecondTbl(item, index)}
                                            </TableRow></>
                                    )
                                })}
                        </TableBody>
                    </Table>
                    {this.state.isLoader ?
                        <Box sx={{ height: '44vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </Box>
                        : BillList && BillList.length === 0 ?
                            <Box sx={{ height: '44vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {'No Records to be Shown'}
                            </Box>
                            : null}
                </Box>
                <TablePagination
                    className='eMed_tbl_pagination'
                    sx={{ borderTop: '1px solid lightgray' }}
                    component='div'
                    rowsPerPageOptions={[10, 20, 30]}
                    count={BillList?.length}
                    rowsPerPage={this.state.tblPageSize}
                    page={this.state.tblPage}
                    onPageChange={(event, newPage) => { this.setState({ tblPage: newPage }) }}
                    onRowsPerPageChange={(event) => { this.setState({ tblPageSize: event.target.value, tblPage: 0 }) }}
                />
            </Box>
        )
    }

    renderSecondTbl = (item, index) => {
        return (
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse in={this.state.tableSelectedIndex === index} timeout={'auto'}>
                    <Box component={'div'} margin={1} className='eMed_Lab_Sts_Tbl_Two'>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>S.No</TableCell>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Module</TableCell>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill Date</TableCell>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill No</TableCell>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill Type</TableCell>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill Amount</TableCell>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Paid Amount</TableCell>
                                    <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item.data && item?.data?.map((secTblItem, secTblIndex) => {
                                    const [datePart, timePart] = secTblItem.invoice_date ? secTblItem.invoice_date.split(" ") : [null, null];
                                    const [hour, minute] = secTblItem.invoice_date ? timePart.split(":") : [null, null];
                                    const formattedTimestamp = secTblItem.invoice_date ?  `${datePart} ${hour}:${minute}` : '-';
                                    return (
                                        <TableRow key={secTblIndex} sx={{ backgroundColor: secTblItem?.bill_type === "cancelled" ? Colors.disableComponentColor : "white" }}>
                                            <TableCell>{secTblIndex + 1}</TableCell>
                                            <TableCell>{secTblItem?.module ? secTblItem?.module : '-'}</TableCell>
                                            <TableCell>{formattedTimestamp ? formattedTimestamp : '-'}</TableCell>
                                            <TableCell>{secTblItem?.invoice_num ? secTblItem?.invoice_num : '-'}</TableCell>
                                            <TableCell>{secTblItem?.bill_type ? secTblItem?.bill_type : '-'}</TableCell>
                                            <TableCell>{secTblItem?.bill_amt ? secTblItem?.bill_amt : '-'}</TableCell>
                                            <TableCell>{secTblItem?.amount_paid ? secTblItem?.amount_paid : '-'}</TableCell>
                                            <TableCell>{
                                                <Tooltip title={"Print Bill"} placement="top" arrow>
                                                    <Button className='eMed_usrconf_btn' onClick={() => {
                                                        this.getReceiptPrint(secTblItem)
                                                    }}>
                                                        <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' />
                                                    </Button></Tooltip>
                                            }</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        )
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box className='eMed_Canteen_FilterBox' >
                <Box className='eMed_CanteenFilter_Head'>
                    <Typography variant='h6' fontWeight={600}>Filter</Typography>
                    <Tooltip>
                        <Button onClick={() => { this.setState({ FilterOpen: false }) }}>
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_Canteen_CloseImg' />
                        </Button>
                    </Tooltip>
                </Box>
                <Box className='eMed_Canteen_fil_content'>
                    <Box className='eMed_Canteen_Date'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                </Box>
                <Box>
                    <Stack spacing={2} direction="row" justifyContent={'center'}>
                        <Button size='small' variant='outlined' className='eMed_Canteen_BtnText' onClick={() => { this.clearFilter() }}>Clear</Button>
                        <Button size='small' variant='contained' className='eMed_Canteen_BtnText' onClick={() => { this.searchFilter() }}>Search</Button>
                    </Stack>
                </Box>
            </Box>
        )
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    clearFilter = () => {
        this.setState({
            FilterOpen: false,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
        }, () => {
            this.getReceiptBills()
        })
    }

    searchFilter = () => {
        this.getReceiptBills()
        this.setState({ FilterOpen: false })
    }

    render() {
        console.log(this.state.fromDate);
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_Main_container'>
                <Box component={"div"} height={'77vh'}>
                    <Box sx={{ width: '92vw', height: '14vh', marginLeft: '1vw', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                            {AmountsCard('Total Net Amount', this.state.totalNetAmt, true, "white", false, null, 'rec_detail_net')}
                        </Box>
                        <Box>
                            <Tooltip title="Filter" placement='top' arrow>
                                <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                    <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>

                    </Box>
                    <Stack component={"div"}>
                        <Paper sx={{ width: '92vw', height: '63vh', marginLeft: '1vw', backgroundColor: 'white' }} >
                            {this.renderTbl()}
                        </Paper>
                    </Stack>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(FOReceiptBills)


