import React, { Component } from 'react'
import './IpPatientDetails.css'
import { withTranslation } from 'react-i18next'
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, Button, FormControl, InputLabel, Select, MenuItem, TextField, Stack, Autocomplete,InputAdornment } from '@mui/material'
import { CommonPatientCard, CommonPatientDetails, ConsultantPatientCard } from '../../../../Components/Common Components/CommonComponents'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { Colors } from '../../../../Styles/Colors'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTime } from 'luxon';
import Loader from '../../../../Components/Loader';
import { CheckAccessFunc } from '../../../../Components/CommonFunctions/CommonFunctions'
import { Speciality_Item } from '../../../../Utility/Constants'
import VoiceToText from '../../../../Components/Common Components/VoiceToText';
import MicIcon from '@mui/icons-material/Mic';
import { grey } from '@mui/material/colors';

var add_Notes = {
  spl_id: null,
  specTxt: '',
  layout_id: '',
  template_id: '',
  template_data: {},
  date_time: null,
  name_of_doctor: '',
  signature: '',
  is_finished: null,
}
class SurgeryNotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ipPatientData: {},
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      surgeryLayout: [],
      surgeryTemplate: [],
      getSNData: [],
      isFinish: false,
      addSurNotes: [add_Notes],
      expanded: false,
      isLoader: false,
      withOutAppointment: {},
      splItem: Speciality_Item,
      surlayout: [],
      attenderName: '',
      attenderMobile: '',
      consultantName: null,
      ClinicID: "",
      OTDetails: [],
      FromOT: false,
      listenPopUp:false,
    }
  }
  componentDidMount() {
    let IpPatientDetails = getCachevalue('IppatientData')
    let patientData = JSON.parse(IpPatientDetails)
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    let patientDatas = getCachevalue("OTpatientData");
    let patients = JSON.parse(patientDatas);
    this.setState({
      OTDetails: patients
    })
    let myUser = JSON.parse(localGetItem("loggedInUserInfo"));
    if (myUser?.module_name === "ot_management") {
      if (myUser?.clinic_id) {
        this.setState({
          ClinicID: myUser?.clinic_id,
          FromOT: true
        })
      }
    }
    var selectedClinic = JSON.parse(localGetItem("SelectedDoctorClinicId"))
    if (selectedClinic) {
      this.setState({
        ClinicID: selectedClinic,
        FromOT: myUser?.module_name === "Doctor" ? false : true
      })
    }
    if (details?.patient_id === patientData?.patient_id) {
      this.setState({
        withOutAppointment: details
      }, () => {
        this.getSurgeyNotes()
        this.getSurgeryLayout()

      })
    }
    this.setState({
      ipPatientData: patientData
    }, () => {
      this.getSurgeyNotes()
      this.getSurgeryLayout()

    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message ? message : '-',
      isSave: false
    })
  }
  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message ? message : '-'
    })
  }

  lodaerFunction = (key) => {
    this.setState({
      isLoader: key
    }, () => {
      setTimeout(() => {
        this.setState({
          isLoader: false
        })
      }, 2000);
    })
  }
  getSurgeryLayout = (val, i) => {
    try {
      RestAPIService.getAll(Serviceurls.SUR_NOTES_LAYOUT + '?clinic_id=' + this.state.ClinicID + '&ip_admission_id=' + (this.state.ipPatientData?.id ? this.state.ipPatientData?.id : (this.state.OTDetails?.ip_admission ? this.state.OTDetails?.ip_admission : null)))
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              surgeryLayout: response?.data?.data,
              surlayout: response?.data?.data
            }, () => {
              if (val) {
                var filterLayout = response?.data?.data ? response?.data?.data.filter((item) => item?.speciality_name === this.state.addSurNotes[i]?.spl_id?.value) : null
                this.setState({
                  surgeryLayout: filterLayout
                })
              }
            })
          }
        }).catch(e => {
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  getSurgeryTemplate = (index) => {
    try {
      var states = this.state
      var layID = states.addSurNotes?.map((item) => (item.layout_id))
      var ID = states.addSurNotes[index].layout_id ? states?.addSurNotes[index]?.layout_id : layID ? layID : ''
      if (states.addSurNotes[index]?.layout_id) {
        RestAPIService.getAll(Serviceurls.IP_SUR_NOTE_TEM + '?id=' + ID + '&clinic_id=' + this.state.ClinicID + '&ip_admission_id=' + (this.state.ipPatientData?.id ? this.state.ipPatientData?.id : (this.state.OTDetails?.ip_admission ? this.state.OTDetails?.ip_admission : null)))
          .then(response => {
            this.lodaerFunction(true)
            if (response.data.status === 'success') {
              this.setState({
                surgeryTemplate: response?.data?.data
              })
            }
          }).catch(e => {
            if (e?.response?.data?.status === 'fail') {
              this.errorMessage(e.response.data.message)
            } else {
              this.errorMessage(e.message)
            }
          })
      }
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  postSurgeryNotesTemplate = (index) => {
    try {
      var states = this.state
      var SNdateTime = new Date(states.addSurNotes[index].date_time)
      var AptDate = DateTime.fromJSDate(SNdateTime).toFormat('yyyy-MM-dd')
      var Aptime = DateTime.fromJSDate(SNdateTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      var dateTime = states.addSurNotes[index].date_time ? `${AptDate} ${Aptime}` : null
      let patient_id = states.ipPatientData?.patient_id || this.state.OTDetails?.patient_id
      let admission_id = this.state.ipPatientData?.id || this.state.OTDetails?.ip_admission;
      var add_Notes = {
        spl_id: null,
        specTxt: '',
        layout_id: '',
        template_id: '',
        template_data: {},
        date_time: null,
        name_of_doctor: '',
        signature: '',
        is_finished: false
      }
      var data = {
        "patient_id": patient_id ? patient_id : null,
        "ip_admission_id": admission_id ? admission_id : null,
        "clinic_id": this.state.ClinicID ? this.state.ClinicID : null,
        // "specilalization": states.addSurNotes[index].spl_id ? states.addSurNotes[index].spl_id : null,
        "layout_id": states.addSurNotes[index]?.layout_id ? states.addSurNotes[index]?.layout_id : null,
        "template_id": states.addSurNotes[index]?.template_id ? states.addSurNotes[index]?.template_id : null,
        "template_data": states.addSurNotes[index]?.template_data ? states.addSurNotes[index]?.template_data : {},
        "date_time": dateTime,
        "name_of_doctor": states.addSurNotes[index]?.name_of_doctor ? states.addSurNotes[index]?.name_of_doctor : '',
        "signature": states.addSurNotes[index]?.signature ? states.addSurNotes[index]?.signature : '',
        "is_finished": states.addSurNotes[index]?.is_finished
      }
      if (states.addSurNotes[index].id) {
        data['surgery_note_id'] = states.addSurNotes[index]?.id
      }
      this.lodaerFunction(true)
      RestAPIService.create(data, Serviceurls.IP_SN_CREATE)
        .then(response => {
          if (response.data.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getSurgeyNotes()
            this.setState({
              addSurNotes: [add_Notes],
              isSave: false,
            })
          }
        }).catch(e => {
          this.setState({isSave: false})
          if (e.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.setState({isSave: false})
      this.errorMessage(e.message)
    }
  }

  printSurNotes(surId) {
    try {
      var states = this.state
      var patientID = states.ipPatientData?.patient_id ? states.ipPatientData?.patient_id : null
      var admissionID = this.state.OTDetails?.ip_admission || states?.ipPatientData?.id
      RestAPIService.PrintPDF(Serviceurls.SUR_NOTE_PRINT + "?ip_admission_id=" + admissionID + "&surgery_notes_id=" + surId + '&clinic_id=' + this.state.ClinicID)
        .then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
            this.setState({isPrint: false})
          }
        }).catch((e) => {
          this.setState({isPrint: false})
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.setState({isPrint: false})
      this.errorMessage(e.message)
    }
  }

  getSurgeyNotes = (i) => {
    try {
      var states = this.state
      let admission_id = this.state.ipPatientData?.id || this.props?.ip_admission_id || this.state.withOutAppointment?.ip_admission_id || this.state.OTDetails?.ip_admission;
      let patient_id = states.ipPatientData?.patient_id || this.state.OTDetails?.patient_id;
      var add_Notes = {
        spl_id: null,
        specTxt: '',
        layout_id: '',
        template_id: '',
        template_data: {},
        date_time: null,
        name_of_doctor: '',
        signature: '',
        is_finished: null,
      }
      RestAPIService.getAll(Serviceurls.IP_SN_CREATE + '?ip_admission_id=' + admission_id + '&patient_id=' + patient_id + '&clinic_id=' + this.state.ClinicID)
        .then(response => {
          if (response.data.status === 'success') {
            this.lodaerFunction(true)
            this.setState({
              attenderName: response?.data?.header_data ? response.data.header_data.attender_name : '',
              attenderMobile: response?.data?.header_data ? response.data.header_data.attender_mobile : '',
              consultantName: response?.data?.header_data ? response.data.header_data.doctor_name : '',
            })
            var item = response?.data?.data
            if (item?.data?.length > 0) {
              this.setState({
                getSNData: response.data.data ? response.data.data : [],
              })
              this.getSavedData(response?.data?.data ? response?.data?.data : [], i)
            } else {
              this.setState({
                addSurNotes: [add_Notes],
              })
            }
          }
        }).catch(e => {
          if (e.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  getSavedData = (data, index) => {
    if (data?.data?.length > 0) {
      var surgery = data.data.map((item) => (item))
      var states = this.state
      this.setState({
        addSurNotes: surgery
      }, () => {
        var SNNotes = states.addSurNotes.map((item) => (item))
        var data = states.surgeryLayout.filter((item) => (item.id == SNNotes?.layout_id))
        var keys = {}
        data[0]?.surgery_json?.forEach((element) => {
          if (surgery[index]?.template_data) {
            keys[element.field_name] = surgery[index]?.template_data[element?.field_name]
          }
        })
        SNNotes.template_data = keys
        this.setState({
          SNNotes,
        })
      })
      if (index >= 0 && index != null) {
        this.getSurgeryTemplate(index)
      }
    }
  }

  handleAcc = (i, id) => {
    var states = this.state
    return (event, isExpanded) => {
      this.setState({ expanded: isExpanded ? i : false }, () => {
        if (states.addSurNotes[i].layout_id && id) {
          this.getSurgeyNotes(i)
        }
      });
    };
  }

  renderAccordionView = () => {
    const { t, fromBillSummary } = this.props
    var states = this.state
    var { addSurNotes, expanded } = this.state
    var New = states.addSurNotes.length - 1
    return (
      <Box component={'div'} className={fromBillSummary ? 'eMed_SN_IpCon' : 'eMed_SN_Con'}>
        {addSurNotes.length > 0 ? addSurNotes.map((list, index) => {
          var lName = states.surlayout.filter((item) => (item.id === states.addSurNotes[index]?.layout_id))
          var tName = states.surgeryTemplate.filter((item) => (item.id === states.addSurNotes[index]?.template_id))
          return (
            <Accordion emed_tid ={"ExpandMoreIcon_TestID"} expanded={expanded === index} onChange={this.handleAcc(index, list.id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ backgroundColor: Colors.DataTblHeaderbg }}
                className='eMed_SN_Acc_Header'>
                <Box component={'div'} className='eMed_SN_Acc_HeadText'>

                  {/* <Typography>{typeof(expanded)!= "boolean" && expanded === index ? states.addSurNotes[expanded].layout_name : lName[0]?.layout_name + '-' + tName[0]?.template_name}</Typography> */}
                  {/* <Typography>{typeof(expanded)}</Typography> */}
                  <Typography>{`${t('SurgeryNotes')} ${'(' + (lName[0]?.layout_name && tName[0]?.template_name ? lName[0]?.layout_name + '-' + tName[0]?.template_name : lName[0]?.layout_name ? lName[0]?.layout_name : '-') + ')'}`}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ height: '59vh', overflow: 'scroll' }}>
                <Box component={'div'} className='eMed_SN_Acc_HeadLay'>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '42vw' }}>
                    <Autocomplete
                      noOptionsText={states.addSurNotes[index].spl_id === '' ? t('PLS') : t('NDF')}
                      options={states.splItem}
                      getOptionLabel={(options) => typeof (options) === 'string' ? options : options.label}
                      sx={{ backgroundColor: Colors.ThemeLightColor }}
                      onChange={(e, value) => {
                        var { addSurNotes } = this.state
                        addSurNotes[index]['spl_id'] = value
                        this.setState({
                          addSurNotes,
                        }, () => {
                          console.log(addSurNotes);
                          if (value != null && expanded === index) {
                            this.getSurgeryLayout(value, index)
                          } else {
                            this.getSurgeryLayout()
                          }
                        })
                      }}
                      value={states.addSurNotes[index].spl_id}
                      size='small'
                      className='eMed_refdoc_txtbox'
                      disablePortal
                      id="Spec"
                      renderInput={(params) => <TextField onChange={(event) => {
                        const value = event.target.value
                        if (value === "" || value != "") {
                          var { addSurNotes } = this.state
                          addSurNotes[index]['specTxt'] = value
                          this.setState({ addSurNotes })
                        }
                      }} {...params} label={t('selectSpecial')}  inputProps={{...params.inputProps,'emed_tid': 'selectSpecial_Testid', }}/>}
                    />
                    <FormControl size='small' className='eMed_IP_Discharge_Select'>
                      <InputLabel id="demo-simple-select-label">{`${t('SelectLayout')} ${'(' + (states.surgeryLayout.length > 0 ? states.surgeryLayout.length : 0) + ')'}`}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={addSurNotes[index].layout_id}
                        readOnly={states.addSurNotes[index].is_finished === true || fromBillSummary ? true : false}
                        sx={{ backgroundColor: Colors.ThemeLightColor }}
                        label={`${t('SelectLayout')} ${'(' + (states.surgeryLayout.length > 0 ? states.surgeryLayout.length : 0) + ')'}`}
                        onChange={(event) => { this.handleLayout(event, index) }}
                        inputProps={{ emed_tid: "SelectLayout_testID"}}
                      >
                        {states.surgeryLayout?.length > 0 ? states.surgeryLayout.map((item) => (
                          <MenuItem value={item.id}>{item.layout_name}</MenuItem>
                        )) : <Typography align='center'>{t('NDF')}</Typography>}

                      </Select>
                    </FormControl>
                    <FormControl size='small' className='eMed_IP_Discharge_Select'>
                      <InputLabel id="demo-simple-select-label">{`${t('SelectTemplate')} ${'(' + (states.surgeryTemplate.length > 0 ? states.surgeryTemplate.length : 0) + ')'}`}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        inputProps={{ emed_tid: `cb_${"SelectTemplate_testID"}` }}
                        disabled={addSurNotes[index].layout_id === '' || addSurNotes[index].layout_id === undefined ? true : false}
                        value={addSurNotes[index].template_id}
                        readOnly={states.addSurNotes[index].is_finished === true || fromBillSummary ? true : false}
                        label={`${t('SelectTemplate')} ${'(' + (states.surgeryTemplate.length > 0 ? states.surgeryTemplate.length : 0) + ')'}`}
                        sx={{ backgroundColor: Colors.ThemeLightColor }}
                        onChange={(event) => { this.handleTemplate(event, index) }}
                      >
                        {states.surgeryTemplate?.length > 0 ? states.surgeryTemplate.map((item) => (
                          <MenuItem value={item.id}>{item.template_name}</MenuItem>
                        )) : <Typography align='center'>{t('NDF')}</Typography>}

                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                {this.renderSNDesign(index)}
                <Box component={'div'} className='eMed_IP_Discharge_BtnGroup'>
                  <Stack spacing={2} direction="row">
                    <Button emed_tid ={"Clear_TestID001"} id='eMed_Config_btn' className='eMed_DisSum_Lay_select2' disabled={states.addSurNotes[index].is_finished === true || fromBillSummary || this.state.withOutAppointment?.is_ip ? true : false} variant='outlined' onClick={() => { this.ClearTemplateData(index) }}>{t('Clear')}</Button>
                    <Button emed_tid ={"Save_TestID001"} id='eMed_Config_btn' className='eMed_DisSum_Lay_select2' disabled={((CheckAccessFunc("front_office", "In Patients", "Surgery Notes", null, "Tab")?.editAccess)) ? (states.addSurNotes[index].layout_id === null || states.addSurNotes[index].layout_id === '' || states.addSurNotes[index].layout_id === undefined || states.addSurNotes[index].is_finished === true || fromBillSummary || this.state.withOutAppointment?.is_ip ? true : false || this.state.isSave) : true} variant='contained' onClick={() => { this.setState({isSave: true},()=>{this.saveTemplate(false, index)}) }}>{t('Save')}</Button>
                    <Button emed_tid ={"Finish_TestID001"} id='eMed_Config_btn' className='eMed_DisSum_Lay_select2' disabled={((CheckAccessFunc("front_office", "In Patients", "Surgery Notes", null, "Tab")?.editAccess)) ? (states.addSurNotes[index].layout_id === null || states.addSurNotes[index].layout_id === '' || states.addSurNotes[index].layout_id === undefined || states.addSurNotes[index].is_finished === true || fromBillSummary || this.state.withOutAppointment?.is_ip ? true : false) : true} variant='contained' onClick={() => { this.saveTemplate(true, index) }}>{t('Finish')}</Button>
                    <Button emed_tid ={"Print_TestID001"} id='eMed_Config_btn' className='eMed_DisSum_Lay_select2' disabled={(states.addSurNotes[index].is_finished === true || fromBillSummary || this.state.withOutAppointment?.is_ip ? false : true) || this.state.isPrint} variant='contained' onClick={() => { this.setState({isPrint: true},()=>{this.printSurNotes(list.id)}) }}>{t('Print')}</Button>
                  </Stack>
                </Box>
              </AccordionDetails>
            </Accordion>
          )
        }) : null}
        <Box className='eMed_SN_Add_Btn'>
          <Button emed_tid = {"AT_testID"} id='eMed_Config_btn' disabled={((CheckAccessFunc("front_office", "In Patients", "Surgery Notes", null, "Tab")?.editAccess)) ? (states.addSurNotes[New].is_finished === null || fromBillSummary || this.state.withOutAppointment?.is_ip ? true : false) : true} variant="contained" onClick={() => { this.addSurgeryNotes() }}>{t('AT')}</Button>
        </Box>
      </Box>
    )
  }
  addSurgeryNotes = () => {
    var states = this.state
    states['addSurNotes'].push({
      layout_id: '',
      template_id: '',
      template_data: {},
      date_time: null,
      name_of_doctor: '',
      signature: '',
      is_finished: null
    })
    this.setState({
      states
    })
  }
  saveTemplate = (isFinish, i) => {
    if (this.mantatoryFeilds(i)) {
      var states = this.state
      states.addSurNotes[i]['is_finished'] = isFinish
      this.setState({
        states
      })
      this.postSurgeryNotesTemplate(i)
    }
  }
  mantatoryFeilds = (index) => {
    var states = this.state
    var data = states.surgeryLayout.filter((item) => (item.id == states?.addSurNotes[index]?.layout_id))
    var ManFeild = data[0].surgery_json

    let flag = false;
    for (let i = 0; i < ManFeild.length; i++) {
      if (ManFeild[i].is_mantatory === true) {
        if(ManFeild[i].field_name === "date_time_name_of_doctor_signature"){
          if (isNaN(Date.parse(states?.addSurNotes?.[index]?.date_time)) || !states?.addSurNotes?.[index]?.name_of_doctor || !states?.addSurNotes?.[index]?.signature) {
            flag = false
            if(isNaN(Date.parse(states?.addSurNotes?.[index]?.date_time))){
              this.errorMessage(`Invalid DateTime`)
            }else{
              this.errorMessage(`${ManFeild[i].label} is Mantatory`)
            }
            this.setState({isSave: false})
            break
          } else {
            flag = true
          }
        }else{
          if (states?.addSurNotes?.[index]?.['template_data']?.[ManFeild[i].field_name] === '') {
            flag = false
            this.errorMessage(`${ManFeild[i].label} is Mantatory`)
            this.setState({isSave: false})
            break
          } else {
            flag = true
          }
        }
      }
    }
    return flag
  }
  ClearTemplateData = (i) => {
    var states = this.state
    var add_Notes = {
      spl_id: null,
      specTxt: '',
      layout_id: '',
      template_id: '',
      template_data: {},
      date_time: null,
      name_of_doctor: '',
      signature: '',
      is_finished: null,
    }
    var data = states.surgeryLayout.filter((item) => (item.id == states.addSurNotes[i].layout_id))
    var keys = {}
    data[0]?.surgery_json?.forEach((element) => {
      keys[element.field_name] = ''

    })
    states.addSurNotes[i].template_data = keys
    states.addSurNotes[i].date_time = null
    states.addSurNotes[i].name_of_doctor = ''
    states.addSurNotes[i].signature = ''
    this.setState({
      states,
      addSurgeryNotes: [add_Notes]
    })
    this.renderSNDesign(i)
  }

  handleLayout = (event, index) => {
    var { addSurNotes } = this.state
    addSurNotes[index]['layout_id'] = event.target.value
    this.setState({
      addSurNotes
    }, () => {
      this.getSurgeryTemplate(index)
      var states = this.state
      var data = states.surgeryLayout.filter((item) => (item.id == states.addSurNotes[index].layout_id))
      var keys = {}
      data[0]?.surgery_json?.forEach((element) => {
        keys[element.field_name] = ""
      })
      states.addSurNotes[index].template_data = keys
      this.setState({
        states
      })
    })
  }
  handleTemplate = (event, index) => {
    var { addSurNotes } = this.state
    addSurNotes[index]['template_id'] = event.target.value
    this.setState({
      addSurNotes
    }, () => {
      var states = this.state
      var tem_data = states?.surgeryTemplate?.map((item) => (
        item.template_data
      ))
      var data = states?.surgeryLayout?.filter((item) => (item.id == states.addSurNotes[index].layout_id))
      var keys = {}
      data[0]?.surgery_json?.forEach((element) => {
        if (tem_data.length > 0) {
          keys[element.field_name] = tem_data[0][element.field_name]
        }
      })
      states.addSurNotes[index]['template_data'] = keys
      this.setState({
        states,
      })
    })
  }


  renderSNDesign = (i) => {
    var states = this.state
    var datalabel = states.surgeryLayout.filter((item) => (item.id == states.addSurNotes[i].layout_id))
    return (
      <Box sx={{ height: '42vh', overflow: 'scroll' }}>
        {datalabel.length === 1 ? datalabel[0].surgery_json.map((item) => {
          if (item.label === "Date/Time, Name of Doctor, Signature" && item.is_active === true) {
            return (
              this.renderSNSignature(i, item?.is_mantatory)
            )
          } else {
            return (
              this.renderTextBoxs(item.label, '87vw', item.field_name, item.is_mantatory, true, i, item.is_active)
            )
          }
        }) : <Box className='eMed_SN_NoLayout'>
          <Typography color={'#888888'}>{'Please Select the Layout and Template'}</Typography>
        </Box>}
      </Box>
    )
  }
  renderSNSignature = (i, isMandatory = false) => {
    var states = this.state
    const { t, fromBillSummary } = this.props
    return (
      <Box className='eMed_SN_Sign_View'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={states.addSurNotes[i].date_time}
            name={"datePicker"}
            // readOnly={states.isFinish === true ? true : false}
            inputFormat='DD-MM-YYYY hh:mm:ss a'
            label={`${t('Date/Time')}${isMandatory ? "*" : ""}` }
            readOnly={states.addSurNotes[i].is_finished === true || fromBillSummary ? true : false}
            onChange={
              (newDate) => {
                states.addSurNotes[i]['date_time'] = newDate
                this.setState({
                  states,
                });
              }}
            renderInput={(params) => <TextField style={{ width: '27vw' }} size='small' {...params} inputProps={{...params.inputProps,'emed_tid': 'Date/Time_testID', }} />}
          />
        </LocalizationProvider>
        <TextField
          size='small'
          label={`${t('NameofDoctor')}${isMandatory ? "*" : ""}`}
          sx={{ width: '27vw' }}
          id="nameofdoctor"
          inputProps={{ readOnly: states.addSurNotes[i].is_finished === true || fromBillSummary ? true : false, emed_tid : "name_of_doctor_TestId"}}
          variant="outlined"
          value={states.addSurNotes[i].name_of_doctor}
          onChange={(event) => {
            states.addSurNotes[i]['name_of_doctor'] = event.target.value
            this.setState({
              states
            })
          }}
        />
        <TextField
          size='small'
          label={`${t('Signature')}${isMandatory ? "*" : ""}`}
          sx={{ width: '27vw' }}
          id="signature"
          inputProps={{ readOnly: states.addSurNotes[i].is_finished === true || fromBillSummary ? true : false ,emed_tid :"signature_testID"}}
          variant="outlined"
          value={states.addSurNotes[i].signature}
          onChange={(event) => {
            states.addSurNotes[i]['signature'] = event.target.value
            this.setState({
              states
            })
          }}
        />
      </Box>
    )
  }
  listenPopUp = ()=>{
    this.setState({
      listenPopUp : false
    })
  }
  voiceToTextgetter = (textStorage) => {
    if (textStorage != '') {
      let states = this.state
      let i = states.tempIndex
      let key = states.tempKey

      if (states.addSurNotes[i].template_data[key]?.length) {
        states.addSurNotes[i].template_data[key] = states.addSurNotes[i].template_data[key] + " " + textStorage
        this.setState({
          states
        })
      } else {
        states.addSurNotes[i].template_data[key] = textStorage
        this.setState({
          states
        })

      }
    }
    this.setState({
      listenPopUp: false
    })
  }
  renderTextBoxs = (label, width, key, mantatory, isrow, i, is_active) => {
    var states = this.state
    let { fromBillSummary } = this.props;
    if (is_active) {
      return (
        <Box className='eMed_DisSum_Lay_CommonBox1'>
          <TextField
            sx={{ width: width }}
            id="icd-code"
            multiline={isrow}
            // rows={3}
            name={key}
            inputProps={{ readOnly: states.addSurNotes[i].is_finished === true || fromBillSummary ? true : false,emed_tid : label}}
            label={mantatory ? label + "*" : label}
            variant="outlined"
            value={states.addSurNotes[i].template_data[key] ? states.addSurNotes[i].template_data[key] : ''}
            onChange={(event) => {
              states.addSurNotes[i].template_data[key] = event.target.value
              this.setState({
                states
              })
            }}
            InputProps={{
              endAdornment:<InputAdornment position="end">
                 <MicIcon sx={{ color: grey[1000] ,cursor:"pointer" }} onClick={()=>{this.setState({listenPopUp:true , tempIndex : i, tempKey : key }) }}></MicIcon>
               </InputAdornment>
            }}
          />
        </Box>
      )
    }
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    const { t, fromBillSummary } = this.props
    var { ipPatientData } = this.state
    let attender = {
      lineOne: t("AttenderName"),
      lineTwo: `${this.state.attenderName ? this.state.attenderName : '-'}`,
      lineFour: t('ContactNumber'),
      LineFive: `${this.state.attenderMobile ? this.state.attenderMobile : '-'}`
    }
    let consultant = {
      lineOne: t("ConsultantName"),
      lineTwo: `${this.state.consultantName ? this.state.consultantName : '-'}`,
    }
    
    return (
      <Box component={'div'} className='eMed_patient_container'>
        <Box component={'div'} marginLeft={'0.5vw'} className='eMed_patient_header' sx={{ display: fromBillSummary ? "none" : "block" }}>
          <Box display={'flex'} alignItems='center'>
            <Box className='eMed_Patient_Details_Long eMed_patient_box'>
              <CommonPatientDetails data={this.state.FromOT ? this.state.OTDetails : this.state.ipPatientData} showDetailed={true} />
            </Box>
            <CommonPatientCard details={attender} showDetailed={true} />
            <ConsultantPatientCard name={consultant} />
          </Box>
        </Box>
        {this.renderAccordionView()}
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.listenPopUp ?
          <VoiceToText
            voiceTextConverter={this.voiceToTextgetter.bind(this)}
            listenPopUp={this.listenPopUp.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(SurgeryNotes);