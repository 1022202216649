import { Box, Button, FormControl, FormHelperText, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import { calculateBMI , calculateWeeksAndDays} from '../../../../Components/CommonFunctions/CommonFunctions'
import { withTranslation } from 'react-i18next';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTime } from 'luxon';


class DocVitalsRight extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientId: this.props.PatientData?.patient_id,
            clinicId: this.props.clinicId,
            appointmentId: this.props.PatientData?.appointment_id,
            height: '',
            weight: '',
            bp_d: '',
            bp_s: '',
            temp: '',
            spo2: '',
            pulserate: '',
            bloodglucouse: '',
            postLoading: false,
            tempUnit: 'F',
            vitalsData: [],
            loading: false,
            isLocked: false,
            bmi: 0.00,
            headCircumference: '',
            obs_ExpectedDeliveryDate: null,
            obs_LastMenstrualPeriod: null,
            gestational_weeks: "",
            gestational_days: "",
        }
    }

    componentDidMount() {
        this.getDoctorNotesVitalData()
    }

    getDoctorNotesVitalData = () => {
        try {
            let appointmentId = this.props.PatientData?.appointment_id
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_VITALS + `?appointment_id=${appointmentId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        if (response.data.data?.length > 0) {
                            this.setState({
                                vitalsData: response.data.data,
                                height: response.data.data[0]?.height_cm,
                                weight: response.data.data[0]?.weight_kg,
                                bp_d: response.data.data[0]?.blood_pressure_diastolic,
                                bp_s: response.data.data[0]?.blood_pressure_systolic,
                                temp: response.data.data[0]?.temperature,
                                spo2: response.data.data[0]?.pulse_oximetry,
                                pulserate: response.data.data[0]?.pulse_rate,
                                bloodglucouse: response.data.data[0]?.blood_sugar,
                                tempUnit: response.data.data[0]?.temperature_unit,
                                bmi: response.data.data[0]?.bmi,
                                headCircumference: response.data.data[0]?.head_circumference,
                                obs_ExpectedDeliveryDate: response.data.data[0]?.expected_date_of_delivery,
                                obs_LastMenstrualPeriod: response.data.data[0]?.last_menstrual_period,
                                gestational_weeks: response.data.data[0]?.gestational_age_weeks,
                                gestational_days: response.data.data[0]?.gestational_age_days,
                                postLoading: true,
                                loading: false,
                            })
                        }
                    }
                }).catch(error => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    postDoctorNotesVitalData = () => {
        try {
            let states = this.state
            let data = {
                appointment_id: this.props.PatientData?.appointment_id,
                blood_pressure_diastolic: +states.bp_d,
                blood_pressure_systolic: +states.bp_s,
                blood_sugar: +states.bloodglucouse,
                bmi: states.height && states.weight ? states.bmi : null,
                height_cm: +states.height,
                pulse_rate: +states.pulserate,
                pulse_oximetry: +states.spo2,
                temperature: +states.temp,
                temperature_unit: states.tempUnit,
                weight_kg: +states.weight,
                dataDisable: true,
                head_circumference: this.state.headCircumference ? +this.state.headCircumference : null,
                gestational_age_days: this.state.gestational_days ? this.state.gestational_days : 0,
                gestational_age_weeks: this.state.gestational_weeks ? this.state.gestational_weeks : 0,
                last_menstrual_period: this.state.obs_LastMenstrualPeriod ? DateTime.fromJSDate(new Date(this.state.obs_LastMenstrualPeriod)).toFormat("yyyy-MM-dd") : null,
                expected_date_of_delivery: this.state.obs_ExpectedDeliveryDate ? DateTime.fromJSDate(new Date(this.state.obs_ExpectedDeliveryDate)).toFormat("yyyy-MM-dd") : null,
            }
            if (
                (this.state.height !== "") || (this.state.weight !== "") ||
                (this.state.bp_s !== "") || (this.state.bp_d !== "") ||
                (this.state.temp !== "") || (this.state.spo2 !== "") ||
                (this.state.bloodglucouse !== "") || (this.state.pulserate !== "") || (this.state.headCircumference !== "")||
                (this.state.gestational_days !== "") || (this.state.gestational_weeks !== "") || (this.state.obs_LastMenstrualPeriod !== null) ||
                (this.state.obs_ExpectedDeliveryDate !== null)
            ) {
                this.setState({ postLoading: true })
                RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_VITALS)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.setState({ postLoading: false })
                            this.successMessage(response.data.message)
                            this.getDoctorNotesVitalData()
                            this.ClearData()
                            this.props.CallgetApi('vitals')
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoading: false })
                            this.errorMessage(error?.response?.data?.message)
                        } else {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage('Enter atleast one data')
            }
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    ClearData = () => {
        try {
            this.setState({
                height: '',
                weight: '',
                bp_d: '',
                bp_s: '',
                temp: '',
                spo2: '',
                pulserate: '',
                bloodglucouse: '',
                tempUnit: 'F',
                obs_ExpectedDeliveryDate: null,
                obs_LastMenstrualPeriod: null,
                gestational_weeks: "",
                gestational_days: "",
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeHandler = (e, names) => {
        try {
            let value = e.target.value
            let num = CommonValidation.DecimalNumber(value)
            switch (names) {
                case "height":
                    if ((num && value >= 1 && value <= 230) || value === "") {
                        this.setState({ height: value }, () => {
                            let bmi = calculateBMI(this.state.weight, this.state.height)
                            this.setState({
                                bmi: bmi?.toFixed(2)
                            })
                        })
                    }
                    break;
                case "weight":
                    if ((num && value >= 1 && value <= 500) || value === "") {
                        this.setState({ weight: value }, () => {
                            let bmi = calculateBMI(this.state.weight, this.state.height)
                            this.setState({
                                bmi: bmi?.toFixed(2)
                            })
                        })
                    }
                    break;
                case "bp_d":
                    if ((num && value >= 1 && value <= 200) || value === "") {
                        this.setState({ bp_d: value })
                    }
                    break;
                case "bp_s":
                    if ((num && value >= 1 && value <= 250) || value === "") {
                        this.setState({ bp_s: value })
                    }
                    break;
                case "temp":
                    if ((this.state.tempUnit === "F" && num && value >= 1 && value <= 248) || value === "") {
                        this.setState({ temp: value })
                    } else if ((this.state.tempUnit === "C" && num && value >= 1 && value <= 200) || value === "") {
                        this.setState({ temp: value })
                    }
                    break;
                case "spo2":
                    if ((num && value >= 1 && value <= 100) || value === "") {
                        this.setState({ spo2: value })
                    }
                    break;
                case "pulserate":
                    if ((num && value >= 1 && value <= 150) || value === "") {
                        this.setState({ pulserate: value })
                    }
                    break;
                case "bloodglucouse":
                    if ((num && value >= 1 && value <= 500) || value === "") {
                        this.setState({ bloodglucouse: value })
                    }
                    break;
                case "headCircumference":
                    if ((num && value >= 1 && value <= 56) || value === "") {
                        this.setState({ headCircumference: value })
                    }
                    break;
                case "gestational_weeks":
                    if ((num && value >= 1 && value <= 45) || value === "") {
                        this.setState({ gestational_weeks: value })
                    }
                    break;
                case "gestational_days":
                    if ((num && value >= 1 && value <= 6) || value === "") {
                        this.setState({ gestational_days: value })
                    }
                    break;
                default:
                    this.setState({
                        [names]: value
                    })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderTextBox = (names, value, width, endAdornment, placeholder, color) => {
        try {
            return (
                <TextField
                    size='small'
                    sx={{ width: width }}
                    name={names}
                    autoComplete='off'
                    value={value}
                    placeholder={placeholder}
                    inputProps={{ style: { color: color, fontWeight: '600' } }}
                    onChange={(e) => this.onChangeHandler(e, names)}
                    InputProps={{
                        endAdornment: names === 'temp' ?
                            <FormControl size='small'>
                                <Select
                                    sx={{ width: '3vw' }}
                                    variant='standard'
                                    value={this.state.tempUnit}
                                    onChange={(e) => this.setState({ tempUnit: e.target.value })}
                                >
                                    <MenuItem value={'F'}>{'°F'}</MenuItem>
                                    <MenuItem value={'C'}>{'°C'}</MenuItem>
                                </Select>
                            </FormControl>
                            :
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{endAdornment}</Typography>
                    }}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    bmiHelperText = (bmi) => {
        try {
            let message = null
            if (bmi <= 18.5) {
                message = 'Mild Thinness'
            } else if (bmi > 18.5 && bmi <= 25) {
                message = 'Normal'
            } else if (bmi > 25 && bmi <= 30) {
                message = 'Overweight'
            } else if (bmi > 30) {
                message = 'Obesity'
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderObgynDatePicker = (stateKey, disable) => {
        let states = this.state
        return (
            <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={states[stateKey] ? states[stateKey] : null}
                        inputFormat='DD-MM-YYYY'
                        views={["year", "month", "day"]}
                        maxDate={stateKey === "obs_LastMenstrualPeriod" ? new Date() : null}
                        minDate={stateKey === "obs_ExpectedDeliveryDate" ? new Date() : null}
                        disabled={disable}
                        onChange={(newDate) => {
                            if (stateKey === "obs_LastMenstrualPeriod") {
                                states[stateKey] = newDate
                                states["obs_ExpectedDeliveryDate"] = new Date(new Date(newDate).getTime() + 40 * 7 * 24 * 60 * 60 * 1000);
                                // Calculate weeks and days between newDate and today
                                const { weeks, days } = calculateWeeksAndDays(new Date(newDate), new Date());
                                states["gestational_weeks"] = weeks;
                                states["gestational_days"] = days;
                            } else {
                                states[stateKey] = newDate
                            }
                            this.setState({ states })
                        }}
                        renderInput={(params) => <TextField
                            sx={{width : "13dvw"}}
                            autoComplete='off'
                            onKeyDown={(e) => e.preventDefault()}
                            size='small' {...params} />}
                    />
                </LocalizationProvider>
            </Box>)
    }

    render() {
        let bpsColor = this.state.bp_s <= 80 ? "green" : "red"
        let bpdColor = this.state.bp_d <= 120 ? "green" : "red"
        let tempColor = ((this.state.tempUnit === 'F' && (this.state.temp >= 97 && this.state.temp <= 99)) || (this.state.tempUnit === 'C' && (this.state.temp >= 36.1 && this.state.temp <= 37.2))) ? 'green' : 'red'
        let spo2Color = (this.state.spo2 >= 95 && this.state.spo2 <= 100) ? "green" : "red"
        let rateColor = (this.state.pulserate >= 60 && this.state.pulserate) ? 'green' : 'red'
        let bloodColor = this.state.bloodglucouse <= 200 ? 'green' : 'red'
        const { t } = this.props
        return (
            <Box>
                <Typography className='eMed_refdoc_title'>{"Vitals"}</Typography>
                <Box height={"56.3vh"} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflowY: 'scroll' }}>
                    <Box component={'div'} width={'28dvw'} sx={{ backgroundColor: Colors.white }} paddingTop={'1vw'}>
                        <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Height</Typography>
                            {this.renderTextBox('height', this.state.height, '13vw', 'cm')}
                        </Box>
                        <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Weight</Typography>
                            {this.renderTextBox('weight', this.state.weight, '13vw', 'kg')}
                        </Box>
                        {(this.state.height && this.state.weight) ?
                            <Box component={'div'} width={"100%"}
                                sx={{ backgroundColor: this.state.bmi < 18.5 ? '#FFCCCB' : ((this.state.bmi >= 18.5) && (this.state.bmi <= 25)) ? 'lightgreen' : ((this.state.bmi > 25) && (this.state.bmi <= 30)) ? '#FBB917' : '#FF6863' }}
                                display={'flex'} borderRadius={'5px'} mt={'0.5vw'} p={'0vw 0.5vw'} justifyContent={'space-between'} alignItems={'center'} height={'2.5vw'}>
                                <Typography fontWeight={600} fontSize={'0.9vw'}>BMI</Typography>
                                <FormHelperText>
                                    <Typography fontWeight={600} fontSize={'0.8vw'}>{this.bmiHelperText(this.state.bmi)}</Typography>
                                </FormHelperText>
                                <Typography fontWeight={600} fontSize={'0.9vw'}>{this.state.bmi}</Typography>
                            </Box> : null}
                        <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>{'BP | D > S'}</Typography>
                            {/* {this.renderTextBox('bp_s', this.state.bp_s, '2vw', 'mm/hg')} */}
                            <Box component={'div'}>
                                <TextField
                                    size='small'
                                    sx={{ width: '5vw' }}
                                    value={this.state.bp_d}
                                    placeholder='D'
                                    onChange={(e) => this.onChangeHandler(e, 'bp_d')}
                                    inputProps={{ style: { color: bpdColor, fontWeight: '600' } }}
                                />
                                {this.renderTextBox('bp_s', this.state.bp_s, '8vw', 'mm/Hg', 'S', bpsColor)}
                            </Box>
                        </Box>
                        {/* Commented for design change */}
                        {/* <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>BP_S</Typography>
            
          </Box> */}
                        <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Temp</Typography>
                            {this.renderTextBox('temp', this.state.temp, '13vw', '', '', tempColor)}
                        </Box>
                        <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>SpO2</Typography>
                            {this.renderTextBox('spo2', this.state.spo2, '13vw', '%', '', spo2Color)}
                        </Box>
                        <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Pulse Rate</Typography>
                            {this.renderTextBox('pulserate', this.state.pulserate, '13vw', '/min', '', rateColor)}
                        </Box>
                        <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Blood Glucose</Typography>
                            {this.renderTextBox('bloodglucouse', this.state.bloodglucouse, '13vw', 'mg/DL', '', bloodColor)}
                        </Box>
                        {this.props?.UserInfo?.service_type === "OBGYN" ?
                            <><Box component={'div'} display={'flex'} mt={'0.5dvw'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontWeight={600} fontSize={'0.9dvw'} color={Colors.SecondaryText}>Last Menstrual Period</Typography>
                                {this.renderObgynDatePicker("obs_LastMenstrualPeriod")}
                            </Box>
                                <Box component={'div'} display={'flex'} mt={'0.5dvw'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography fontWeight={600} fontSize={'0.9dvw'} color={Colors.SecondaryText}>Expected date of delivery</Typography>
                                    {this.renderObgynDatePicker("obs_ExpectedDeliveryDate")}
                                </Box>
                                <Box component={'div'} width={"100%"} display="flex" mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography fontWeight={600} fontSize={'0.9dvw'} color={Colors.SecondaryText}>Gestational Age</Typography>
                                    <Box component={'div'} display="flex" justifyContent={'space-between'} alignItems={'center'}>
                                        {this.renderTextBox("gestational_weeks", this.state.gestational_weeks, "6.5dvw", "Weeks")}
                                        {this.renderTextBox("gestational_days", this.state.gestational_days, "6.5dvw", "Days")}
                                    </Box>
                                </Box></> : null}
                        {this.props?.UserInfo?.service_type === "PD" ? <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Head Circumference</Typography>
                            {this.renderTextBox('headCircumference', this.state.headCircumference, '13vw', 'CM')}
                        </Box> : null}
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
                    <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                    <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.postDoctorNotesVitalData() }}>{t("Add")}</Button>
                </Stack>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(DocVitalsRight)
