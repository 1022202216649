import { Autocomplete, Box, Button, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import React, { Component } from "react";
import { Colors } from "../../../../Styles/Colors";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import MUIRichTextEditor from "mui-rte";
import ShowComponents from "./ShowComponent";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import { ImagePaths } from "../../../../Utility/ImagePaths";

export default class DocTreatmentPlan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientDetails: {},
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            isLocked: this.props.isLocked,
            TreatmentData: [],
            templateData: [],
            TemplateName: null,
            comments: "",
            EditedId: "",
            NewTemplate: "",
            Tempcomments: "",

            // edit States
            dupTempComments: '',
            dupTempData: '',
            editTempComments: '',
            editTempName: '',
            openEditPopup: false,
            isEdit: false,
            selectedId: null
        }
    }

    apiCalls = () => {
        this.getTreatmentPlanData()
        this.getTreatmentadvicetemplate()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getTreatmentPlanData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_TREATMENT_ADVICE + `?appointment_id=${this.props.appointmentId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            TreatmentData: response.data.data.treatment_advice ? response.data.data.treatment_advice : []
                        }, () => { this.setState({ comments: this.state?.TreatmentData?.comments }) })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getTreatmentadvicetemplate = (searchKey) => {
        try {
            let API_URL = ""
            if (searchKey) {
                API_URL = Serviceurls.DOC_TREAMENT_ADVICE_TEMPLATE + `?search_key=${searchKey}`
            } else {
                API_URL = Serviceurls.DOC_TREAMENT_ADVICE_TEMPLATE
            }
            RestAPIService.getAll(API_URL)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            templateData: response.data.data ? response.data.data : []
                        }, () => {
                            if (this.state.isEdit) {
                                let templateName = this.state.templateData?.find(item => (item.id === this.state.selectedId))
                                this.setState({
                                    TemplateName: templateName?.template_name,
                                    comments: templateName?.comments
                                }, () => this.setState({ isEdit: false }))
                            }
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getTreatmentTemplateselect = (template_id) => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_TREAMENT_ADVICE_TEMPLATE + `?template_id=${template_id}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = {
                            template_name: response.data.data?.template_name ? response.data.data?.template_name : [],
                            id: response.data.data?.id ? response.data.data?.id : null
                        }
                        let comments = response.data.data?.comments
                        this.setState({
                            TemplateName: data,
                            comments: comments ? comments : ""
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    // TreatmentPlanDelete = (Id) => {
    //     var data = []
    //     try {
    //         RestAPIService.create(data, Serviceurls.DOC_TREAMENT_ADVICE_DELETE + Id + "/")
    //             .then(response => {
    //                 if (response.data.status == "success") {
    //                     this.successMessage(response?.data?.message)
    //                     this.getTreatmentPlanData()
    //                 }
    //             }).catch((e => {
    //                 this.setState({
    //                     isErrorMsg: true,
    //                     isErrorMsgText: e.response.data.message
    //                 })
    //             }))

    //     } catch (e) {
    //         this.setState({
    //             isErrorMsg: true,
    //             isErrorMsgText: e.message
    //         })
    //     }
    // }

    PostTreatmentTemp = () => {
        let states = this.state
        try {
            let data = {
                "comments": this.state.isEdit ? this.state.editTempComments : this.state.Tempcomments,
                "template_name": this.state.isEdit ? this.state.editTempName : this.state.NewTemplate
            }
            if (this.state.isEdit) {
                data['id'] = this.state.selectedId
            }
            if ((states.NewTemplate != null && states.Tempcomments != "") || (states.isEdit)) {
                this.setState({ postLoading: true })
                RestAPIService.create(data, Serviceurls.DOC_TREAMENT_ADVICE_TEMPLATE)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            if (this.state.isEdit) {
                                this.setState({
                                    openEditPopup: false,
                                    // selectedId: null,
                                    editTempComments: '',
                                    editTempName: '',
                                    dupTempComments: '',
                                    dupTempData: ''
                                }, () => this.getTreatmentadvicetemplate())
                            } else {
                                this.setState({
                                    openPopUp: false,
                                    postLoading: false,
                                    Tempcomments: "",
                                    TemplateName: null,
                                    NewTemplate: ""
                                }, () => {
                                    this.getTreatmentadvicetemplate()
                                })
                            }
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                if (states?.Tempcomments == "") {
                    this.errorMessage("Enter Comments")
                } else {
                    this.errorMessage("Enter Template Name")
                }
            }
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }

    PostTreatmentPlanData = () => {
        let states = this.state
        try {
            let data = {
                "appointment_id": this.props.appointmentId,
                "comments": this.state.comments,
                "disease_name": ""
            }
            if (states.EditedId) { data["id"] = states.EditedId }
            if (states.comments) {
                this.setState({ postLoading: true })
                RestAPIService.create(data, Serviceurls.DOC_TREATMENT_ADVICE)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.setState({ postLoading: false, comments: "", TemplateName: null, NewTemplate: "" }, () => {
                                this.getTreatmentPlanData()
                            })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage("Enter Comments")
            }
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    // onEditHandler = () => {
    //     let item = this.state.TreatmentData
    //     this.setState({
    //         EditedId: item.id,
    //         openPopUp: true,
    //         TemplateName: { "template_name": item.disease_name, "id": item.id },
    //         comments: item.comments
    //     })
    // }

    renderPopUpScreen = () => {
        try {
            return (
                <Box>
                    <Modal open={this.state.openPopUp}>
                        <Box component={'div'} className='eMed_Doc_Notes_Pop_Up' style={{ width: "40vw", height: "26vw" }}>
                            <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>Treatment Plan</Typography>
                                <Button
                                    className='Common_Btn_Min_Width'
                                    onClick={() => this.setState({ openPopUp: false, EditedId: "", TemplateName: null, comments: "" })}
                                >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>
                            <Box component={'div'} p={'0.5vw'} style={{ marginLeft: "0.75vw" }}>
                                <div style={{ fontWeight: 600 }}>Template Name</div>
                                <div style={{ marginLeft: "1vw", marginTop: "0.65vw" }}>
                                    <TextField sx={{ backgroundColor: 'white' }}
                                        label='Add Template'
                                        size="small"
                                        onChange={(e) => {
                                            if (e.target.value != "") {
                                                this.setState({
                                                    NewTemplate: e.target.value
                                                })
                                            }
                                        }}
                                    />
                                </div>
                                <div style={{ fontWeight: 600, marginTop: "0.65vw" }}>Comments</div>
                                <div style={{ height: "10vw", width: "95%", paddingLeft: "1vw" }}>
                                    <TextField
                                        label={("Enter Comments")}
                                        style={{ height: "8vw", marginTop: "0.75vw", width: "95%" }}
                                        multiline={true}
                                        rows={4}
                                        variant="outlined"
                                        value={this.state.Tempcomments}
                                        onChange={(event) => {
                                            let value = event.target.value
                                            if (value?.length <= 350) {
                                                this.setState({ Tempcomments: value })
                                            } else {
                                                this.errorMessage('Allowed only 350 characters')
                                            }
                                        }}
                                    />
                                </div>
                            </Box>
                            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                                <Button
                                    sx={{ height: '2vw', width: '8.5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='contained'
                                    disabled={this.state.postLoading}
                                    onClick={() => this.PostTreatmentTemp()}
                                >Save Template</Button>
                                {/* <Button
                                    disabled={this.state.postLoading}
                                    sx={{ height: '2vw', width: '8.5vw', textTransform: 'capitalize' }}
                                    variant='contained'
                                    onClick={() => this.PostTreatmentPlanData()}
                                >Add</Button> */}
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderEditPopUp = () => {
        try {
            return (
                <Box component={'div'}>
                    <Modal open={this.state.openEditPopup}>
                        <Box className='eMed_Doc_Notes_Pop_Up' style={{ width: "40vw", height: "18.5vw" }}>
                            <Box component={'div'}>
                                <Box component={'div'} display={'flex'} justifyContent={'space-between'} p={'0.5vw'}>
                                    <Typography fontWeight={600}>Edit Template</Typography>
                                    <Button
                                        className='Common_Btn_Min_Width'
                                        onClick={() => this.setState({ openEditPopup: false })}
                                    >
                                        <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                    </Button>
                                </Box>
                            </Box>
                            <Box component={'div'} p={'0.5vw'}>
                                <TextField
                                    size="small"
                                    sx={{ width: '15vw' }}
                                    value={this.state.editTempName}
                                    label='Template Name'
                                    onChange={(e) => this.setState({ editTempName: e.target.value })}
                                />
                                <TextField
                                    sx={{ mt: '1vw' }}
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    value={this.state.editTempComments}
                                    label='Comments'
                                    onChange={(e) => this.setState({ editTempComments: e.target.value })}
                                />
                            </Box>
                            <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                                <Button variant="outlined" sx={{ height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                                    onClick={() => this.setState({ openEditPopup: false })}
                                >Cancel</Button>
                                <Button
                                    variant="contained" sx={{ height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                                    onClick={() => this.PostTreatmentTemp()}
                                >Update</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        let states = this.state
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} display={'flex'} alignItems={'center'} p={'0.5vw'} justifyContent={'space-between'} sx={{ backgroundColor: Colors.Background }} height={'3.3vw'}>
                        <Typography fontSize={"0.85vw"} fontWeight={600}>Treatment Plan</Typography>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {this.state.TemplateName ?
                                <Button
                                    className="Common_Btn_Min_Width"
                                    onClick={() => {
                                        this.setState({ isEdit: true }, () => {
                                            this.setState({
                                                openEditPopup: true,
                                                editTempName: this.state.TemplateName?.template_name ? this.state.TemplateName?.template_name : this.state.TemplateName,
                                                editTempComments: this.state.editTempComments ? this.state.editTempComments : JSON.parse(JSON.stringify(this.state.comments)),
                                                dupTempData: JSON.stringify(this.state.TemplateName),
                                                dupTempComments: JSON.stringify(this.state.comments),
                                                selectedId: this.state.selectedId ? this.state.selectedId : this.state.TemplateName?.id
                                            })
                                        })
                                    }}
                                >
                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button> : null
                            }
                            <Box component={'div'}>
                                <Autocomplete
                                    size='small'
                                    disabled={this.state.isLocked || this.props.isLocked}
                                    sx={{ width: '13vw', mr: '0.5vw' }}
                                    options={this.state.templateData}
                                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option.template_name}
                                    value={this.state.TemplateName}
                                    autoComplete='off'
                                    onChange={(e, newValue) => {
                                        states["TemplateName"] = newValue
                                        states['selectedId'] = newValue?.id
                                        states['editTempComments'] = newValue?.comments
                                        this.setState({
                                            states
                                        }, () => { if (newValue) { this.getTreatmentTemplateselect(newValue?.id) } })
                                    }}
                                    renderInput={(params) => <TextField {...params} me label='Search Template'
                                        onChange={(e) => {
                                            if (e.target.value != "") {
                                                this.setState({
                                                    SearchTemplate: e.target.value
                                                }, () => { this.getTreatmentadvicetemplate(e.target.value) })
                                            } else {
                                                this.setState({ TemplateName: null }, () => {
                                                    this.getTreatmentadvicetemplate()
                                                })
                                            }
                                        }} />}
                                    PaperComponent={({ children }) => {
                                        return (
                                            <Paper component={'div'}>
                                                {children}
                                                <Button
                                                    color="primary"
                                                    fullWidth
                                                    sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                                                    onMouseDown={(e) => {
                                                        this.setState({ openPopUp: true, TemplateName: null })
                                                    }}
                                                >
                                                    Add Template
                                                </Button>
                                            </Paper>
                                        )
                                    }}
                                />
                            </Box>
                            <Button
                                disabled={this.state.isLocked || this.props.isLocked}
                                variant='contained'
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mt: '0.2vw', ml: '0.5vw' }}
                                onClick={() => this.PostTreatmentPlanData()}
                            >Add</Button>
                        </div>
                    </Box>
                    <div style={{ height: "9.5vw", width: "100%", paddingLeft: "1vw" }}>
                        {/* <MUIRichTextEditor
                            controls={["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "numberList", "bulletList", "quote", "clear", "save"]}
                            label={` Type Description Here...`}
                            onSave={(data) => { this.setState({ comments: data }, () => { this.PostTreatmentPlanData() }) }}
                            inlineToolbar={true}
                            defaultValue={this.state.comments}
                        /> */}
                        <TextField
                            multiline
                            label='Description Here'
                            rows={4}
                            style={{ height: "8vw", marginTop: "0.75vw", width: "95%" }}
                            value={this.state.comments}
                            onChange={(e) => { this.setState({ comments: e.target.value }) }}
                        />
                    </div>
                    {/* <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray', padding: "1vw" }} component={'div'}>
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Date</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Disease Name</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Comments</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item?.disease_name ?
                                    <TableRow>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.date ? item?.date.split("-").reverse().join("-") : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.disease_name ? item?.disease_name : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.comments ? item?.comments : '-'}</TableCell>
                                        <TableCell>
                                            <Box component={'div'} display={'flex'}>
                                                <Button className='Common_Btn_Min_Width'
                                                    onClick={() => { this.onEditHandler() }}
                                                >
                                                    <Tooltip placement='top' title='Edit'>
                                                        <Box height={'1.2vw'} width={'1.2vw'} component={'img'} src={ImagePaths.UserEditOn.default} />
                                                    </Tooltip>
                                                </Button>
                                                <Button className='Common_Btn_Min_Width'
                                                    onClick={() => this.TreatmentPlanDelete(item?.id)}
                                                    disabled={this.state.isLocked || this.props.isLocked}
                                                >
                                                    <Tooltip placement='top' title='Delete'>
                                                        <Box height={'1.2vw'} width={'1.2vw'} component={'img'} src={ImagePaths.DeleteOutline.default} />
                                                    </Tooltip>
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    : null}
                            </TableBody>
                        </Table>
                    </Box> */}
                    {this.renderPopUpScreen()}
                    {this.renderEditPopUp()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                </Box>
            </ShowComponents>
        )
    }
}