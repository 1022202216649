import React, { Component } from "react";
import '../Configuration.css';
import { Box, Paper, TextField, Button, Stack, Typography, Tooltip, Grid, Checkbox, IconButton, Menu, MenuItem, InputAdornment, Autocomplete, FormControl, InputLabel, Select, FormControlLabel } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup, CommonPopUp } from "../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { ImagePaths } from "../../../Utility/ImagePaths";
import { Colors } from "../../../Styles/Colors";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { AmountFormat, CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import Loader from '../../../Components/Loader';
import { CurrencySymbol } from "../../../Utility/Constants";

const ITEM_HEIGHT = 30;
class IPServiceNameTypeConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceDetail: [],
      data: [],
      selectedIndex: -1,
      selectedLineItem: [],
      service_name: "",
      description: "",
      is_edit_front_office: false,
      data: [],
      service_type: "",
      Amount: null,
      editIndex: -1,
      editId: null,
      deletedPopup: false,
      isHistoryClicked: false,
      historyData: [],
      service_name1: "",
      anchorEl: null,
      anchorEl1: null,
      anchorEl2: null,
      historyIndex: -1,
      historyLineItem: [],
      selectedRow: null,
      isSelected: false,
      isEdit: false,
      deleted_ids:[],
      current_page: 0,
      rowsPerPage: 10,
      showSuccessResponse: false,
      showErrorResponse: false,
      responseMessage: "",
      serviceCategoryData:[],
      serviceCategory:'',
      hospitalShare:'',
      doctorShare:'',
      is_UnitEdit: false,
      is_AomuntEdit: false,
      is_DisEdit: false,
    }
  }

  componentDidMount() {
    this._getIpServiceDetails();
    this.getServiceCategoryData()
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getServiceCategoryData = () => {
    try{
      RestAPIService.getAll(Serviceurls.IP_SERVICE_CATEGORY_GET)
      .then((response)=>{
        if(response.data.status === 'success'){
          this.setState({
            serviceCategoryData:response.data.data
          })
        }
      }).catch((error) => {
        if(error?.response?.data?.message){
          this.responseSuccessErrorMessage(error.response.data.message, false);
        }else{
          this.responseSuccessErrorMessage(error.message, false);
        }
      })
    }catch(error){
      this.responseSuccessErrorMessage(error.message, false);
    }
  }

  _getIpServiceDetails = () => {
    this.setState({
      serviceDetail: [],
      current_page: 0,
      rowsPerPage: 10
    })
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_IPSRCNAME)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              serviceDetail: response.data.data,
            }, () => {
              this.state.serviceDetail.forEach((item, i) => {
                item.index = i
              })
            });
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error.response.data.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }

  handleAccordion = () => {
    const { t } = this.props;
    let { selectedLineItem } = this.state;
    const accordionColumn = [
      { field: 's.no', headerName: t('SNo'), flex: 0.07, sortable: false, hideable: false, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
      {
        field: 'service_type', headerAlign: "center", headerName: t('ServiceType'), sortable: false, hideable: false, flex: 0.30, align: "center", renderCell: (cellvalues) => {
          return (
            <div>{cellvalues.row?.service_type?.length > 40 ? <Tooltip placement="top" title={cellvalues.row?.service_type} arrow><div>{cellvalues.row?.service_type.slice(0, 35) + "..."}</div></Tooltip> : cellvalues.row?.service_type ? cellvalues.row?.service_type : "-"}</div>
          )
        }
      },
      {
        field: 'is_edit_unit', headerAlign: "center", headerName: t('editUnit'), sortable: false, hideable: false, align: "center", flex: 0.13, renderCell: (cellvalues) => {
            return (
                <div>{ cellvalues.row?.is_edit_unit === true ? "Yes" : "No"}</div>
            )
        }
    },
    {
      field: 'is_edit_amount', headerAlign: "center", headerName: t('editAmt'), sortable: false, hideable: false, align: "center", flex: 0.13, renderCell: (cellvalues) => {
          return (
              <div>{ cellvalues.row?.is_edit_amount === true ? "Yes" : "No"}</div>
          )
      }
  },
  {
    field: 'is_edit_discount', headerAlign: "center", headerName: t('editDis'), sortable: false, hideable: false, align: "center", flex: 0.17, renderCell: (cellvalues) => {
        return (
            <div>{ cellvalues.row?.is_edit_discount === true ? "Yes" : "No"}</div>
        )
    }
},
      { field: 'amount', headerAlign: "right", headerName: t('Amount') + ` (${CurrencySymbol})`, flex: 0.20, sortable: false, hideable: false, align: "center", renderCell : (cellvalues) =>{
      let amount = AmountFormat(cellvalues.row?.amount)
      return (<Typography>{amount ? amount.replace(`${CurrencySymbol}`, "") : "-"}</Typography>)
      }},
    ]
    return (
      <div id="eMed_srvConfig_accTbl">{
        selectedLineItem.length > 0 ?
          <DataGrid
            rows={selectedLineItem}
            columns={accordionColumn}
            getRowId={(row) => row.id}
            autoHeight
            disableColumnMenu
            hideFooter
            components={{
              NoRowsOverlay: () => ""
            }}
            headerHeight={40}
          />
          :
          null
      }
      </div>
    )
  }

  //start of history popup
  _getHistoryDetails = (data) => {
    if (data) {
      let service_name = data[0]["service_name"];
      let service_ids = data.map(item => item.id);
      this.setState({
        historyData: [],
      })
      try {
        RestAPIService.getAll(`${Serviceurls.FO_CONFIG_IPSRCHISTORY}?service_name=${service_name}&service_id=${service_ids}&condition=service`)
          .then(response => {
            if (response.data.status === "success") {
              this.setState({
                historyData: response.data.data,
              }, () => {
                this.state.historyData.forEach((item, i) => {
                  item.index = i
                })
              });
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.responseSuccessErrorMessage(error.response.data.message, false);
            }
          });
      } catch (e) {
        this.responseSuccessErrorMessage(e.message, false);
      }
    }
  }

  historyPopUpClose = () => {
    this.setState({
      anchorEl1: null,
      isHistoryClicked: false,
      historyData: []
    })
  }

  _getHistory(historydata) {
    const { t } = this.props;
    let { anchorEl2 } = this.state;
    let open = Boolean(anchorEl2);
    historydata?.forEach((element, index) => { element.sno = index + 1 })
    const historyColumn = [
      { field: 's.no', headerName: t('SNo'), width: 100, sortable: false, hideable: false, renderCell: (index) => index.row.sno },
      {
        field: 'modifiedDetails', headerName: `${t("User")} & ${t("ModifiedDateAndTime")}`,  sortable: false, hideable: false, width: 350, renderCell: (cellvalues) => {
          return (<Stack direction="column" spacing = {0.3}>
            <Typography><span className="eMed_txt_header">{`${t("User")} : `}</span>{cellvalues.row?.user?.length > 20 ? <Tooltip placement="top" title={cellvalues.row?.user} arrow><div>{cellvalues.row?.user.slice(0, 15) + "..."}</div></Tooltip> : cellvalues.row?.user ? cellvalues.row?.user : "-"}</Typography>
            <Typography sx={{fontSize: "0.8vw"}}><span className="eMed_txt_header">{`${t("Date")} & ${t("Time")} : `}</span> {cellvalues.row?.modified_date ? cellvalues.row?.modified_date : "-"}</Typography>
          </Stack>)
        }
      },
      {
        field: 'service_name_from', headerName: `${t('Previous')}  ${t('ServiceName')}`, width: 250, sortable: false, hideable: false, renderCell: (cellvalues) => {
          return (<div>{cellvalues.row?.service_name_from?.length > 20 ? <Tooltip placement="top" title={cellvalues.row?.service_name_from} arrow><div>{cellvalues.row?.service_name_from.slice(0, 15) + "..."}</div></Tooltip> : cellvalues.row?.service_name_from ? cellvalues.row?.service_name_from : "-"}</div>)
        }
      },
      {
        field: 'service_name_to', headerName: `${t('Current')}  ${t('ServiceName')}`, width: 250, sortable: false, hideable: false, renderCell: (cellvalues) => {
          return (
            <div>{cellvalues.row?.service_name_to?.length > 20 ? <Tooltip placement="top" title={cellvalues.row?.service_name_to} arrow><div>{cellvalues.row?.service_name_to.slice(0, 15) + "..."}</div></Tooltip> : cellvalues.row?.service_name_to ? cellvalues.row?.service_name_to : "-"}</div>
          )
        }
      },
      {
        field: 'service_type_from', headerName: `${t('Previous')} ${t('ServiceType')}`, sortable: false, hideable: false, width: 250, renderCell: (cellvalues) => {
          return (
            <div>{cellvalues.row?.service_type_from?.length > 20 ? <Tooltip placement="top" title={cellvalues.row?.service_type_from} arrow><div>{cellvalues.row?.service_type_from.slice(0, 15) + "..."}</div></Tooltip> : cellvalues.row?.service_type_from ? cellvalues.row?.service_type_from : "-"}</div>
          )
        }
      },
      {
        field: 'service_type_to', headerName: `${t('Current')} ${t('ServiceType')}`, sortable: false, hideable: false, width: 250, renderCell: (cellvalues) => {
          return (
            <div>{cellvalues.row?.service_type_to?.length > 20 ? <Tooltip placement="top" title={cellvalues.row?.service_type_to} arrow><div>{cellvalues.row?.service_type_to.slice(0, 15) + "..."}</div></Tooltip> : cellvalues.row?.service_type_to ? cellvalues.row?.service_type_to : "-"}</div>
          )
        }
      },
      {
        field: 'amount_from', headerAlign: "right", headerName: `${t('Previous')} ${t('Amount')} (${CurrencySymbol})`, width: 250, sortable: false, hideable: false, align: "right", renderCell: (cellvalues) => {
          let amountFrom = cellvalues.row?.amount_from !== "No changes" ? AmountFormat(cellvalues.row?.amount_from)?.replace(`${CurrencySymbol}`, "") : cellvalues.row?.amount_from;
          return (<Typography>{amountFrom !== `${CurrencySymbol}` ? amountFrom : "-"}</Typography>)
        }
      },
      {
        field: 'amount_to', headerAlign: "right", headerName: `${t('Current')} ${t('Amount')} (${CurrencySymbol})`, width: 250, sortable: false, hideable: false, align: "right", renderCell: (cellvalues) => {
          let amountTo = cellvalues.row?.amount_to !== "No changes" ? AmountFormat(cellvalues.row?.amount_to).replace(`${CurrencySymbol}`, "") : cellvalues.row?.amount_to;
          return (<Typography>{amountTo !== `${CurrencySymbol}` ? amountTo : "-"}</Typography>)
        }
      }];
    return (
      <Box id="eMed_ipsrvConfig_accTbl">
        <DataGrid
          rows={historydata ? historydata : []}
          columns={historyColumn}
          getRowId={(row) => row.sno}
          disableColumnMenu
          hideFooter
          components={{
            NoRowsOverlay: () => {
              return (
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "40vh" }}>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }
          }}
          headerHeight={40}
        />
      </Box>
    )
  }
  // End of history popup

  responseSuccessErrorMessage(message, type) {
    this.setState({
      showSuccessResponse: message ? type : false,
      showErrorResponse: message ? !type : false,
      responseMessage: message
    })
  }

  //Start of left table Function
  handleEdit = (item) => {
    let states = this.state
    item["data"].forEach((value, i) => {
      value.index = i
    })
    states["service_name"] = item.service_name;
    states["description"] = item.description;
    states["data"] = JSON.parse(JSON.stringify(item.data));
    states["service_type"]= "";
    states["Amount"]= null;
    states["editIndex"] = -1;
    states["editId"] = null;
    states["is_UnitEdit"] = false;
    states["is_AomuntEdit"] = false;
    states["is_DisEdit"] = false;
    this.setState({
      states,
      isEdit: true
    })
  }

  handleDelete = (value) => {
    if(!this.state.isEdit){
    this.setState({
      deletedPopup: true,
      service_name1: value.service_name
    })
  }}

  PopupClose = () => {
    this.setState({
      deletedPopup: false,
      service_name1: ""
    })
  }

  removeDataList = () => {
    try {
      RestAPIService.delete(`${Serviceurls.FO_CONFIG_IPSRCNAME}?service_name=${this.state.service_name1 ? this.state.service_name1 : ""}`)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              deletedPopup: false,
              service_name1: ""
            }, () => {
              this.responseSuccessErrorMessage(response.data.message, true);
              this._getIpServiceDetails();
            })
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error.response.data.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }
  // End of left table Function

  //Start of right div Function
  handleChange = (event) => {
    let states = this.state
    let name = event.target.name;
    let value = event.target.value.trimStart();
    if (name === "service_name" || name === "service_type") {
      let isValid = CommonValidation.alphanumValidation(value)
      if (value === "" || isValid && value.length <= 50) {
        states[name] = value;
      }
    } else if (name === "Amount") {
      let isValid = CommonValidation.numberOnly(value)
      if (value === "" || value >= 0 && value?.length <= 7 && isValid) {
        states["Amount"] = value;
        this.setState({
          hospitalShare:'',
          doctorShare:''
        })
      }
    } else if (name === "description"){
      if (value === "" || value.length <= 250) {
        states["description"] = value;
    }}
    this.setState({
      states
    })
  }

  onAdd = () => {
    let { service_type, Amount, data, editIndex, editId, is_UnitEdit, is_AomuntEdit, is_DisEdit , is_edit_front_office } = this.state;
    const { t } = this.props
    let exist = data?.find(item => item["service_type"].toLowerCase().trim() === service_type.toLowerCase().trim());
    let isCrt = true;
    let Message = "";
    let trimService_type = service_type.trim();
    if (!service_type && !Amount) { isCrt = false; Message = `${t("Enter")} ${t("ServiceType")} ${t("and")} ${t("Amount")}` }
    if (!service_type) { isCrt = false; Message = `${t("Enter")} ${t("ServiceType")}` }
    if (!Amount) { isCrt = false; Message = `${t("Enter")} ${t("Amount")}` }
    if (exist) {
      if(editIndex === -1 ) { isCrt = false;  Message = `${exist["service_type"]} ${t("AlreadyExist")}`; }
      if(exist["service_type"] !== trimService_type && exist["amount"] === Amount)  { isCrt = false; Message = `${exist["service_type"]} ${t("AlreadyExist")}`; }
      if (exist["id"] !== editId || exist["index"] !== editIndex) { isCrt = false; Message = `${exist["service_type"]} ${t("AlreadyExist")}`; }
      if (exist["index"] === editIndex && exist["id"] === editId && exist["service_type"] === trimService_type && exist["amount"] === Amount && exist["is_UnitEdit"] === is_UnitEdit && exist["is_AomuntEdit"] === is_AomuntEdit && exist["is_DisEdit"] === is_DisEdit && exist['hospital_share'] === +this.state.hospitalShare && exist['doctor_share'] === +this.state.doctorShare) { isCrt = false; Message = `${exist["service_type"]} ${t("AlreadyExist")}`; }
  }
    if (isCrt) {
      if (editIndex !== -1) {
        let temp = {
          id: editId ? editId : null,
          service_type: service_type,
          amount: +(Amount),
          index: editIndex,
          is_edit_front_office: is_edit_front_office,
          is_edit_unit: is_UnitEdit,  
          is_edit_amount: is_AomuntEdit,
          is_edit_discount: is_DisEdit,
          hospital_share:this.state.hospitalShare === '' ? null : +this.state.hospitalShare,
          doctor_share:this.state.doctorShare === '' ? null : +this.state.doctorShare,
        }
        let newList = data.map((item, index) => {
          return index === editIndex ? temp : item
        })
        data = JSON.parse(JSON.stringify(newList))
      } else {
        let temp = {
          id: null,
          service_type: service_type,
          amount: +(Amount),
          index: data.length,
          is_edit_front_office: is_edit_front_office,
          is_edit_unit: is_UnitEdit,  
          is_edit_amount: is_AomuntEdit,
          is_edit_discount: is_DisEdit,
          hospital_share:this.state.hospitalShare === '' ? null : +this.state.hospitalShare,
          doctor_share:this.state.doctorShare === '' ? null : +this.state.doctorShare,
        }
        data = [...data, temp]
      }
      this.setState({
        data,
        service_type: "",
        Amount: null,
        editIndex: -1,
        editId: null,
        is_edit_front_office: false,
        is_UnitEdit: false,
        is_AomuntEdit: false,
        is_DisEdit: false,
        doctorShare:'',
        hospitalShare:''
      })
    } else {
      this.responseSuccessErrorMessage(Message, false)
    }
  }

  handleEditTbl = (item) => {
    this.setState({
      service_type: item.service_type,
      Amount: item.amount,
      editIndex: item.index,
      editId: item.id,
      is_UnitEdit: item.is_edit_unit,
      is_AomuntEdit: item.is_edit_amount,
      is_DisEdit: item.is_edit_discount,
      hospitalShare:item.hospital_share,
      doctorShare:item.doctor_share
    })
  }

  handleDeleteTbl = (item) => {
    let { data, deleted_ids, editIndex, editId } = this.state;
    if (editIndex === -1 && editId === null) {
    let deletedArr = [...data]
    let index = deletedArr.indexOf(item)
    deleted_ids.push(item.id);
    deletedArr.splice(index, 1)
    this.setState({
      data: JSON.parse(JSON.stringify(deletedArr)),
      deleted_ids
    })}
  }
  handleClear = () => {
    this.setState({
      service_name: "",
      description: "",
      is_edit_front_office: false,
      service_type: "",
      Amount: null,
      data: [],
      deleted_ids:[],
      isEdit: false,
      editIndex: -1,
      editId: null,
      serviceCategory:'',
      hospitalShare:'',
      doctorShare: '',
      is_UnitEdit: false,
      is_AomuntEdit: false,
      is_DisEdit: false,
    })
  }
  renderTextBox(label, statekey, value, width) {
    const { t } = this.props;
    return (
      <div id="eMed_srvConfig_divs">
        <TextField
          sx={{ width: width ? width : "10vw" }}
          size="small"
          autoComplete="off"
          label={t(label)}
          name={statekey}
          value={value ? value : ""}
          required={statekey === "description" ? false : true}
          multiline={statekey === "description" ? true : false}
          rows={statekey === "description" ? 3 : null}
          InputProps={{
            endAdornment: statekey === "Amount" ? <InputAdornment position="end">{`${CurrencySymbol}`}</InputAdornment> : false
          }}
          onChange={this.handleChange}
        />
      </div>
    )
  }
  handleSave = () => {
    const { t } = this.props
    let { serviceCategory,service_name, description, data, deleted_ids, editIndex, editId } = this.state
    let isAvl = true;
    let Message = "";
    if (!serviceCategory && !service_name && data.length === 0) { isAvl = false; Message = t("Fillmandatory") }
    else if (!service_name) { isAvl = false; Message = `${t("Enter")} ${t("ServiceName")}` }
    else if (data.length === 0) { isAvl = false; Message = `${t("Enter")} ${t("ServiceType")} ${t("and")} ${t("Amount")}` }
    else if (!serviceCategory) { isAvl = false; Message = "Select Service Category" }
    data.forEach(element => element.service_category_id = +serviceCategory)
    if (isAvl && editIndex === -1 && editId === null) {
      // api call
      let datas = {
        // "service_category_id":+serviceCategory,
        "service_name": service_name,
        "description": description,
        "delete_id":deleted_ids.length > 0 ? JSON.parse(JSON.stringify(deleted_ids)) : [],
        "service_config": JSON.parse(JSON.stringify(data))
      }
      try {
        this.LoaderFunction(true)
        RestAPIService.create(datas, Serviceurls.FO_CONFIG_IPSRCNAME)
          .then(response => {
            if (response.data.status === "success") {
              this.handleClear();
              this._getIpServiceDetails();
              this.LoaderFunction(false)
              this.responseSuccessErrorMessage(response.data.message, true);
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.LoaderFunction(false)
              this.responseSuccessErrorMessage(error.response.data.message, false);
            }
          });
      } catch (e) {
        this.LoaderFunction(false)
        this.responseSuccessErrorMessage(e.message, false);
      }
    } else {
      this.responseSuccessErrorMessage(Message, false)
    }
  }
  // End of right div Function

  msgClose(){
    this.setState({
        showSuccessResponse: false,
        showErrorResponse: false,
        responseMessage: ''
    })
  }

  calculateShareAmount = (value,name) => {
    if(name === "hospitalShare"){
      this.setState({
        doctorShare:this.state.Amount - (+value)
      })
    } else if(name === "doctorShare"){
      this.setState({
        hospitalShare:this.state.Amount - (+value)
      })
    }
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target
    switch (name) {
      case 'hospitalShare':
        if (CommonValidation.NumberWithDot(value) && +value <= this.state.Amount || value === '') {
          this.setState({
            hospitalShare: value
          },()=> this.calculateShareAmount(value,name))
        }
        break;
      case 'doctorShare':
        if (CommonValidation.NumberWithDot(value) && +value <= this.state.Amount || value === '') {
          this.setState({
            doctorShare: value
          },()=>this.calculateShareAmount(value,name))
        }
        break;
      default:
        this.setState({
          [name]: value
        })
        break;
    }
  }

  inputTextBox = (state,key,label,margin) => {
    return (
        <TextField 
            sx={{width:"11vw",marginLeft:margin,marginBottom:"0.5vw"}}
            size="small"
            disabled={this.state.Amount === null}
            label={label}
            name={key}
            value={state}
            onChange={(e)=>this.onChangeHandler(e)}
        />
    )
  }

  render() {
    const { t } = this.props;
    let { rowsPerPage, current_page, serviceDetail, anchorEl, service_type, Amount, service_name, description, data, isHistoryClicked, historyData } = this.state;
    let open = Boolean(anchorEl);
    const multi_option = [
      { value: "History", label: t("History") },
    ]
    const columns = [
      { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.08, renderCell: (index) => index.row.index + 1 },
      {
        field: 'service_category', headerName: t('SelectServiceCategory'), flex: 0.23, renderCell: (cellvalues) => {
          return (<div>{cellvalues?.row?.data[0]?.service_category_name?.length > 25 ? <Tooltip placement="top" title={cellvalues?.row?.data[0]?.service_category_name} arrow><div>{cellvalues?.row?.data[0]?.service_category_name.slice(0, 20) + "..."}</div></Tooltip> : cellvalues?.row?.data[0]?.service_category_name ? cellvalues?.row?.data[0]?.service_category_name : "-"}</div>)
        }
      },
      {
        field: 'service_name', headerName: t('ServiceName'), flex: 0.23, renderCell: (cellvalues) => {
          return (<div>{cellvalues?.row?.service_name?.length > 25 ? <Tooltip placement="top" title={cellvalues?.row?.service_name} arrow><div>{cellvalues?.row?.service_name.slice(0, 20) + "..."}</div></Tooltip> : cellvalues?.row?.service_name ? cellvalues?.row?.service_name : "-"}</div>)
        }
      },
      { field: 'total_amounts', headerAlign: "right", headerName: t('Amount') + ` (${CurrencySymbol})`, flex: 0.29, align: "right", renderCell: (cellvalues) => {
          let amounts = AmountFormat(cellvalues.row?.total_amounts)
          return (<Typography>{amounts ? amounts.replace(`${CurrencySymbol}`, "") : "-"}</Typography>)
        }},
      {
        field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.23, sortable: false, hideable: false, align: "center", renderCell: (cellvalues) => {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Service Name & Type Config", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleEdit.bind(this, cellvalues.row)} />
                <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Service Name & Type Config", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleDelete.bind(this, cellvalues.row)} />
              </Stack>
              <div>
                <Tooltip title={t("More")} placement='top' arrow><IconButton size="small" onClick={(event) => { this.setState({ anchorEl1: event.currentTarget, selectedRow: cellvalues.row.index, isSelected: true }) }}><MoreVertIcon /></IconButton></Tooltip>
              {(this.state.isSelected && this.state.selectedRow == cellvalues.row.index) ?
                <Menu
                  anchorEl={this.state.anchorEl1}
                  open={Boolean(this.state.anchorEl1)}
                  onClose={(e) => {
                    this.setState({
                      anchorEl1: null
                    })
                  }}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '16ch',
                      marginLeft: "-4vw",
                    },
                  }}
                >
                   {multi_option.map((option) => (
                  <MenuItem key={option} onClick={() => { this.setState({ anchorEl1: null, isHistoryClicked: true }, () => { this._getHistoryDetails(cellvalues.row?.data) }) }}>{t(option.label)}</MenuItem>))}
                </Menu>
                : null}
              </div>
              <div>
                <IconButton size="small" onClick={(event) => { this.setState({ anchorEl: event.currentTarget, selectedIndex: cellvalues.row.index, selectedLineItem: cellvalues.row.data }) }}><img className="eMed_arrowBtn" src={ImagePaths.downArrow.default} style={{ transform: this.state.selectedIndex === cellvalues.row.index ? "rotate(180deg)" : null, }} alt="Arrow" /></IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={(e) => {
                    this.setState({
                      anchorEl: null,
                      selectedIndex: -1,
                      selectedLineItem: []
                    })
                  }}
                  PaperProps={{
                    style: {
                      height: "fitContent",
                      width: '55vw',
                      marginLeft: "-36.5vw",
                      marginTop: "0.69vw",
                      boxShadow: "none"
                    },
                  }}
                  MenuListProps={{
                    disablePadding: true
                  }}
                >
                  {this.handleAccordion()}
                </Menu>
              </div>
            </Stack>
          )
        }
      }
    ]
    const rightColumn = [
      {
        field: 'service_type', headerName: t('ServiceType'), sortable: false, hideable: false, flex: 0.35, renderCell: (cellvalues) => {
          return (<div>{cellvalues?.row?.service_type?.length > 25 ? <Tooltip placement="top" title={cellvalues?.row?.service_type} arrow><div>{cellvalues?.row?.service_type.slice(0, 23) + "..."}</div></Tooltip> : cellvalues?.row?.service_type ? cellvalues?.row?.service_type : "-"}</div>)
        }
      },
      { field: 'amount', headerAlign: "right", headerName: t('Amount') + ` (${CurrencySymbol})`, sortable: false, hideable: false, flex: 0.20, align: "right", renderCell: (cellvalues) => {
        let amounts = AmountFormat(cellvalues.row?.amount)
        return (<Typography>{amounts ? amounts.replace(`${CurrencySymbol}`, "") : "-"}</Typography>)
      }},
      {
        field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.15, sortable: false, hideable: false, align: "center", renderCell: (cellvalues) => {
          return (
            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
              <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Service Name & Type Config", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleEditTbl.bind(this, cellvalues.row)} />
              <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Service Name & Type Config", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleDeleteTbl.bind(this, cellvalues.row)} />
            </Stack>
          )
        }
      }
    ]
    return (
      <Box id="eMed_srvConfig_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
        <Grid container spacing={3} className="eMed_srvConfig_Box">
          <Grid item xs={7.6}>
            <Paper className="eMed_srvConfig_Div" >
              <DataGrid
                rows={serviceDetail}
                columns={columns}
                getRowId={(row) => row.index}
                localeText={{
                  toolbarColumns: "",
                  toolbarDensity: "",
                  toolbarExport: "",
                  toolbarFilters: "",
                  toolbarExportPrint: ""
                }}
                disableSelectionOnClick
                components={{
                  Toolbar: CommonGridHeader.CommonToolBar,
                  NoRowsOverlay: () => {
                    return (
                      <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                        {t("NoRecordsFound")}
                      </Stack>
                    )
                  }
                }}
                headerHeight={40}
                rowCount={serviceDetail.length}
                page={current_page}
                onPageChange={(newPage) => this.setState({ current_page: newPage })}
                pageSize={rowsPerPage}
                onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                rowsPerPageOptions={[10, 20, 30]}
                pagination
              />
            </Paper>
          </Grid>
          <Grid item xs={4.4}>
            <Paper className="eMed_srvConfig_Div">
              <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                <p className='eMed_srvConfig_rightHead_txt'>{t("ServiceName") + " & " + t("TypeConfiguration")}</p>
              </div>
              <div className="eMed_srvConfig_subDiv" style={{overflow:"scroll"}}>
                <Box component={'div'} display={'flex'}>
                  <FormControl size='small' sx={{width:"15vw",margin:"0.5vw"}}>
                    <InputLabel>{`${t("SelectServiceCategory")} *`}</InputLabel>
                    <Select
                      value={this.state.serviceCategory}
                      label={`${t("SelectServiceCategory")} *`}
                      onChange={(e) => {
                        this.state.serviceCategory = e.target.value
                        this.setState({ serviceCategory: this.state.serviceCategory })
                      }}
                    >
                      {
                        this.state.serviceCategoryData?.length > 0 ?
                          this.state.serviceCategoryData.map((list, index) => (
                            <MenuItem key={index} value={list.id}>{list.service_type}</MenuItem>
                          )) : null
                      }
                    </Select>
                  </FormControl>
                  {this.renderTextBox("ServiceName", "service_name", service_name, "15vw")}
                </Box>
                <Stack spacing={2}>
                  {this.renderTextBox("Description", "description", description, "29.8vw")}
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "29.8vw" }}>
                  {this.renderTextBox("ServiceType", "service_type", service_type,'11vw')}
                  {this.renderTextBox("Amount", "Amount", Amount,'11vw')}
                  <Button size="small" variant="contained" id="eMed_Config_btn" onClick={() => { this.onAdd() }}>{t("Add")}</Button>
                </Stack>
                <Box>
                {this.inputTextBox(this.state.hospitalShare,'hospitalShare','Hospital Share','0.5vw')}
                {this.inputTextBox(this.state.doctorShare,'doctorShare',"Doctor Share",'1.8vw')}
                </Box>
                <Stack spacing={1} direction="row" alignItems="center" mb={1} marginLeft={'0.5vw'}>
                  <FormControlLabel control={<Checkbox size='small' checked={this.state.is_UnitEdit} disabled={this.state.service_name && this.state.service_type && this.state.Amount !== null ? false : true} />} label={t("editUnit")}
                    onChange={(e) => { this.setState({ is_UnitEdit: e.target.checked }) }} />
                  <FormControlLabel control={<Checkbox size='small' checked={this.state.is_AomuntEdit} disabled={this.state.service_name && this.state.service_type && this.state.Amount !== null ? false : true} />} label={t("editAmt")}
                    onChange={(e) => { this.setState({ is_AomuntEdit: e.target.checked }) }} />
                  <FormControlLabel control={<Checkbox size='small' checked={this.state.is_DisEdit} disabled={this.state.service_name && this.state.service_type && this.state.Amount !== null ? false : true} />} label={t("editDis")}
                    onChange={(e) => { this.setState({ is_DisEdit: e.target.checked }) }} />
                </Stack>
                <div id="eMed_ipsrvConfig_rightTbl"  style={{overflowY:'scroll'}}>
                  <DataGrid
                    rows={data}
                    columns={rightColumn}
                    getRowId={(row) => row.index}
                    disableColumnMenu
                    hideFooter
                    headerHeight={40}
                    components={{
                      NoRowsOverlay: () => ""
                    }}
                  />
                </div>
              </div>
              <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                <Button disabled={!CheckAccessFunc("front_office", "Settings", "IP Service Configuration",   "IP Service Name & Type Config", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.handleSave() }}>{t("Save")}</Button>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
        {this.state.deletedPopup ?
          <DeletePopup DeletTitle={`${t("deleteMsg")}  ${this.state.service_name1 ? this.state.service_name1 : "-"} ?`}
            DeleteNotify={t("effectEntireSetting")}
            deleteAlertPopupClose={this.PopupClose.bind(this)}
            removeData={this.removeDataList.bind(this)}
          />
          : null}
        {
          this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
        }
        {
          this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
        }
        {
          isHistoryClicked ?
            <CommonPopUp width={"75vw"} backgroundColor={Colors.white} popUpClose={this.historyPopUpClose.bind(this)} title={t("History")} component={this._getHistory.bind(this, historyData)} />
            : null
        }
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}

export default withTranslation()(IPServiceNameTypeConfig);