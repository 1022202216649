import { Autocomplete, Box, Button, Chip, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '../../ClinicalNotes/DoctorNotes.css'
import { Colors } from '../../../../../../Styles/Colors';
import { DurationCounts, DurationsList } from '../../../../../../Utility/Constants';
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg';
import CommonValidation from '../../../../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import AddIcon from '@mui/icons-material/Add';
import { DeletePopup } from '../../../../../../Components/Common Components/CommonComponents';
import { CheckAccessFunc } from '../../../../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../../../../Utility/Services/CacheProviderService';

class SymptomsRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      SymptomsText: "",
      SymptomDate: new Date(),
      DurationType: "Day(s)",
      DurationCount: null,
      Description: "",
      FrequentData: [],
      SelectedID: null,
      SymSearchedKey: "",
      SymptomsList: [],
      isDeleteClicked: false,
      SelectedFreq: {},
      SelectedFreqIndex: null,
      withOutAppointment : this.props?.withOutAppointment,
    }
  }

  componentDidMount() {
    if (this.props.EditSymptomsData && Object.keys(this.props.EditSymptomsData).length !== 0) {
      let Data = { "symptoms_name": CommonValidation.removeSpace(this.props.EditSymptomsData?.symptoms_name), "symptom_count": "none" }
      let Filter = this.state.FrequentData.find((list) => (list.symptoms_name === CommonValidation.removeSpace(this.props.EditSymptomsData?.symptoms_name)));
      if (Filter === undefined) {
        this.state.FrequentData.push(Data)
        this.setState({ FrequentData: this.state.FrequentData })
      } else {
        this.setState({ SymptomsText: this.props.EditSymptomsData?.symptoms_name })
      }
      this.setState({
        SymptomsText: this.props.EditSymptomsData?.symptoms_name,
        SymptomDate: this.props.EditSymptomsData?.symptom_date ? new Date(this.props.EditSymptomsData?.symptom_date) : null,
        DurationType: this.props.EditSymptomsData?.duration_type,
        DurationCount: +this.props.EditSymptomsData?.duration,
        Description: this.props.EditSymptomsData?.description,
        SelectedID: this.props.EditSymptomsData?.id
      })
    }
    this.GetSymptomsSuggestions()
    this.GetSymptomsFrequents()
    this.props.ClearPropsData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.EditSymptomsData !== this.props.EditSymptomsData && this.props.EditSymptomsData && Object.keys(this.props.EditSymptomsData).length !== 0) {
      let Data = { "symptoms_name": CommonValidation.removeSpace(this.props.EditSymptomsData?.symptoms_name), "symptom_count": "none" }
      let Filter = this.state.FrequentData.find((list) => (list.symptoms_name === CommonValidation.removeSpace(this.props.EditSymptomsData?.symptoms_name)));
      if (Filter === undefined) {
        this.state.FrequentData.push(Data)
        this.setState({ FrequentData: this.state.FrequentData })
      } else {
        this.setState({ SymptomsText: this.props.EditSymptomsData?.symptoms_name })
      }
      this.setState({
        SymptomsText: this.props.EditSymptomsData?.symptoms_name,
        SymptomDate: this.props.EditSymptomsData?.symptom_date ? new Date(this.props.EditSymptomsData?.symptom_date) : null,
        DurationType: this.props.EditSymptomsData?.duration_type,
        DurationCount: +this.props.EditSymptomsData?.duration,
        Description: this.props.EditSymptomsData?.description,
        SelectedID: this.props.EditSymptomsData?.id
      })
      this.props.ClearPropsData()
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  ClearData() {
    this.setState({
      SymptomsText: "",
      SymptomDate: new Date(),
      DurationType: "Day(s)",
      DurationCount: null,
      Description: "",
      SelectedID: null,
      SymSearchedKey: "",
      isDeleteClicked: false,
      SelectedFreq: {},
      SelectedFreqIndex: null
    })
  }

  PostSymtoms = () => {
    try {
      var states = this.state
      let date = new Date(states.SymptomDate)
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      let newDate =  `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      const myUser = JSON.parse(localGetItem("loggedInUserInfo"));
      var data = {
        "symptoms_name": CommonValidation.removeSpace(states.SymptomsText),
        "clinic_id": this.props.clinic_id,
        "doctor_id": this.props.is_From_IpNotes ? myUser?.doctor_id : this.props?.IPadmissionDetails?.doctor_id,
        "ip_admission_id": this.props?.IpPatientData?.id,
        "duration": states.DurationCount,
        "duration_type": states.DurationType,
        "sym_date": newDate,
        "description": CommonValidation.removeSpace(states.Description)
      }
      if (this.state.SelectedID !== null) { data["symptoms_id"] = this.state.SelectedID }

      RestAPIService.create(data, Serviceurls.IP_DOCNOTES_SYMPTOMS).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.ClearData()
            this.GetSymptomsFrequents()
            this.props.CallgetApi('isSymptoms')
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  GetSymptomsSuggestions() {
    try {
      RestAPIService.getAll(Serviceurls.IP_DOCNOTES_SYMPTOMS_SUGGEST).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SymptomsList: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  GetSymptomsFrequents() {   
    let url = `${Serviceurls.IP_DOCNOTES_SYMPTOMS_FREQUENT}`;
    if (this.props.is_From_IpNotes && this.props.clinic_id) {
      url = `${url}?clinic_id=${this.props.clinic_id}`
    }
    try {
      RestAPIService.getAll(url).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              FrequentData: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  Validation() {
    var states = this.state
    let SymptomDate = new Date(states.SymptomDate)
    let date = states.SymptomDate ? DateTime.fromJSDate(SymptomDate).toFormat("yyyy-MM-dd hh:mm:ss") : ""
    if (CommonValidation.removeSpace(states.SymptomsText) !== "" && date !== "Invalid DateTime") {
      this.PostSymtoms()
    } else {
      if (CommonValidation.removeSpace(states.SymptomsText) === "") {
        this.errorMessage("Enter Symptom Name")
      }
      else if (date === "Invalid DateTime") {
        this.errorMessage("Invalid Symptom Date")
      }
    }
  }
  handleDelete(item, index) {
    this.state.FrequentData.splice(index, 1)
    this.setState({ FrequentData: this.state.FrequentData })
    if (this.state.SymptomsText === item.symptoms_name) { this.setState({ SymptomsText: "" }) }
  }
  popupClose() {
    this.setState({ isDeleteClicked: false })
  }
  DelectSymptomFreq() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.IP_DOCNOTES_SYMPTOMS_FREQUENT + "?symptoms_name=" + states.SelectedFreq.symptoms_name + "&clinic_id=" + this.props.clinic_id).
        then((response) => {
          if (response.data.status === "success") {
            this.state.FrequentData.splice(states.SelectedFreqIndex, 1)
            this.setState({ FrequentData: this.state.FrequentData, isDeleteClicked: false })
            if (this.state.SymptomsText === states.SelectedFreq.symptoms_name) { this.setState({ SymptomsText: "" }) }
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    const { t, IpPatientData, IPadmissionDetails } = this.props
    let states = this.state
    const checkuserAccess = this.props.is_From_IpNotes ? false :
      CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Doctor Notes", "SubTab")?.editAccess ?
        this.state.withOutAppointment?.is_ip : true;
    const currentDate = new Date();
    const admissionDate = IPadmissionDetails?.admitted_date ? new Date(IPadmissionDetails?.admitted_date)
      : IpPatientData?.admission_date ? new Date(IpPatientData?.admission_date)
        : currentDate;
    const dischargeDate = IpPatientData?.discharge_date && (new Date(IpPatientData?.discharge_date) <= currentDate) ?
      new Date(IpPatientData?.discharge_date) : currentDate;
      
    return (
      <Box>
        <Typography className='eMed_refdoc_title'>{t("Symptoms")}</Typography>
        <Box height={"52dvh"} sx={{ overflowY: 'scroll' }}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Autocomplete
              noOptionsText={states.SymSearchedKey === '' ? 'Please Search...' : 'No Data Found...'}
              options={states.SymptomsList}
              getOptionLabel={(options) => options}
              onChange={(e, value) => {
                let Data = { "symptoms_name": value, "symptom_count": "none" }
                let Filter = this.state.FrequentData.find((list) => (list.symptoms_name === value));
                if (Filter === undefined && value)  {
                  this.state.FrequentData.push(Data)
                  this.setState({ FrequentData: this.state.FrequentData, SymptomsText: value, SymSearchedKey: "" })
                } else {
                  this.setState({
                    SymptomsText: value,
                    SymSearchedKey: ""
                  })
                }
              }}
              clearIcon={false}
              size='small'
              sx={{ width: "26vw" }}
              className='eMed_DocNotes_TextFields'
              disablePortal
              id="combobox-demo"
              value={states.SymSearchedKey}
              renderInput={(params) => <TextField sx={{ width: "26vw" }} onChange={(event) => {
                this.setState({
                  SymSearchedKey: event.target.value
                })
              }}
                {...params}
                placeholder="Search Symptoms"
                inputProps={{...params.inputProps,'emed_tid': 'Search_Symptoms_TestID', }}

              />}
            />
            <Button emed_tid = {"symptom_count_TestID"} sx={{ minWidth: '2vw', marginLeft: '-1.5vw' }} disabled={CommonValidation.removeSpace(this.state.SymSearchedKey) === "" || this.state.withOutAppointment?.is_ip} variant='contained' size='small' color='primary' onClick={() => {
              let Data = { "symptoms_name": CommonValidation.removeSpace(this.state.SymSearchedKey), "symptom_count": "none" }
              let Filter = this.state.FrequentData.find((list) => (list.symptoms_name === CommonValidation.removeSpace(this.state.SymSearchedKey)));
              if (Filter === undefined) {
                this.state.FrequentData.push(Data)
                this.setState({ FrequentData: this.state.FrequentData, SymptomsText: CommonValidation.removeSpace(this.state.SymSearchedKey), SymSearchedKey: "" })
              } else {
                this.setState({ SymptomsText: CommonValidation.removeSpace(this.state.SymSearchedKey), SymSearchedKey: "" })
              }
            }} >
              <AddIcon />
            </Button>
          </Box>

          <Box className="eMed_DocNotes_FreqChips_Wrapp">
            {
              this.state.FrequentData.length > 0 ? this.state.FrequentData.map((item, index) => (
                <Chip
                  size='small'
                  className="eMed_DocNotes_FreqChip"
                  key={index}
                  label={item.symptoms_name}
                  emed_tid = {item.symptoms_name}
                  color={'primary'}
                  variant={this.state.SymptomsText === item.symptoms_name ? 'contained' : "outlined"}
                  onClick={() => { this.setState({ SymptomsText: item.symptoms_name }) }}
                  onDelete={() => { item.symptom_count === 'none' ? this.handleDelete(item, index) : this.setState({ isDeleteClicked: true, SelectedFreq: item, SelectedFreqIndex: index }) }}
                />
              )) : null
            }

          </Box>

          <Box className='emed_Duration_head'>
            <Typography fontWeight={600} color = {Colors.SecondaryText}>{t("Duration")}</Typography>
            <FormControl size='small' sx={{ width: '8vw' }}>
              <InputLabel>{t("Duration")}</InputLabel>
              <Select
                sx={{ backgroundColor: Colors.ThemeLightColor }}
                size='small'
                value={this.state.DurationType}
                label={t("Duration")}
                inputProps={{ emed_tid:  "Duration_TestID" }}
                onChange={(e) => {
                  this.setState({ DurationType: e.target.value })
                }}
              >
                {
                  DurationsList?.length > 0 ?
                    DurationsList.map((list, index) => (
                      <MenuItem key={index} value={list.label}>{list.label}</MenuItem>
                    )) : null
                }
              </Select>
            </FormControl>
          </Box>
          <Box className='eMed_DocNotes_Duration_Warpper'>
            {
              DurationCounts.map((list, index) => (
                <Box
                  key={index}
                  sx={{ backgroundColor: this.state.DurationCount === list ? Colors.themeDark : "#FAF9F9", color: this.state.DurationCount === list ? Colors.white : Colors.black, borderColor: this.state.DurationCount === list ? Colors.themeDark : Colors.lightGray }}
                  className='eMed_DocNotes_Duration_Counts' onClick={() => { this.setState({ DurationCount: list }) }}>{list}</Box>
              ))
            }
          </Box>
          <Box>
            <Typography className='eMed_refdoc_title'>{t("Description")}</Typography>
            <TextField
              size='small'
              className='eMed_DocNotes_TextFields'
              multiline={true}
              rows={5}
              label={t("Description")}
              inputProps={{ maxLength: 1000, emed_tid: "Description_testID" }}
              value={this.state.Description}
              onChange={(e) => {
                if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                  this.setState({ Description: e.target.value })
                }
              }}
            />
          </Box>
          <Box component={'div'} sx={{ backgroundColor: Colors.ThemeLightColor, marginLeft: "1.5vw", marginBottom: '0.5vw' }} className='eMed_DocNotes_DateTime' >
            <Typography marginLeft={'0.5vw'} color='#616161' fontWeight={'600'}>{t("SymptomsDate")}</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                inputFormat='DD-MM-YYYY & hh:mm A'
                value={this.state.SymptomDate}
                onChange={(newDate) => {
                  if (newDate === null) {
                    this.setState({ SymptomDate: new Date() })
                  }
                  else if (newDate > new Date()) {
                    this.setState({ SymptomDate: new Date() })
                  }
                  else {
                    this.setState({ SymptomDate: newDate })
                  }
                }}
                // minDate={this.props?.IPadmissionDetails?.admitted_date}
                minDate={admissionDate}
                maxDate={dischargeDate}
                renderInput={(params) => <TextField size='small'
                  {...params}
                  fullWidth
                  variant='standard'
                  className='eMed_DocNotes_datePic_Textfield'
                  inputProps={{ ...params.inputProps,'emed_tid': 'SymptomDate_testID'}}
                />}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
          <Button emed_tid = {"Clear_TestID001"} variant='outlined' size="small" id="eMed_Config_btn" disabled = {checkuserAccess} onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
          <Button emed_tid = {"Add_TestID001"} variant='contained' size="small" id="eMed_Config_btn" disabled = {checkuserAccess} onClick={() => { this.Validation() }}>{this.state.SelectedID === null ? t("Add") : t("Update")}</Button>
        </Stack>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.isDeleteClicked ? <DeletePopup
          DeletTitle={`${t("deleteMsg")}  ${t("Symptom")} ${this.state.SelectedFreq ? this.state.SelectedFreq?.symptoms_name : "-"} From Suggestion List ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.DelectSymptomFreq.bind(this)}
        /> : null}
      </Box>
    )
  }
}
export default withTranslation()(SymptomsRight)
