import React, { Component } from 'react'
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { ImagePaths } from '../../Utility/ImagePaths'
import './ABHA.css'
import ABHAapiServices from './ABHAapiServices';
import { ABHAurls } from './ABHAurls';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import CommonValidation from '../../Components/CommonFunctions/CommonValidation';


const BASE_DOMAIN = "https://abhasbx.abdm.gov.in/abha/"
// const BASE_DOMAIN = "https://dev.abdm.gov.in/gateway/"

const AbhaPublicKey = `-----BEGIN PUBLIC KEY-----MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9Nwgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1AayfZp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7irDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkNergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXuiwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAlsIzQpnSVDUVEzv17grVAw078CAwEAAQ==-----END PUBLIC KEY-----`;


const VerfifyOTPoptions = [
    { label: "Aadhar OTP", value: "AADHAR_OTP" },
    { label: "Mobile OTP", value: "MOBILE_OTP" },
    { label: "ABHA Number", value: "ABHA_NUMBER" },
    // { label: "Demographics", value: "DEMOGRAPHICS" },
]

const GenderList = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
]

const RecoveryOptions = [
    { label: "Aadhar OTP", value: "AADHAR_OTP" },
    { label: "Demographics", value: "DEMOGRAPHICS" },
]
export default class ABHAPopUP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHaveABHA: false,
            OpenTermsPopup: false,
            AadhaarTermsCondition: false,
            OpenVerifyTermsPopup: false,
            VerifyTermsCondition: false,
            ForgotABHAClicked: false,

            AccessToken: null,
            aadhar_number: "",
            aadhar_otp: "",
            abha_address_number: "",
            AbhaVerifyWith: "AADHAR_OTP",

            Fetched_FirstName: "",
            Fetched_LastName: "",
            Fetched_MiddleName: "",
            Fetched_Dob: "",
            Fetched_MobileNumber: "",
            Fetched_ABHA_Number: "",
            Fetched_ABHA_Address: "",

            abha_address: "",
        }
    }

    componentDidMount() {
        this.GetAuthDetails()
    }

    // Function to convert PEM to ArrayBuffer
    pemToArrayBuffer = (pem) => {
        const pemHeader = "-----BEGIN PUBLIC KEY-----";
        const pemFooter = "-----END PUBLIC KEY-----";
        const pemContents = pem.substring(pemHeader.length, pem.length - pemFooter.length);
        const binaryDerString = atob(pemContents.replace(/\s/g, ''));
        const binaryDer = new Uint8Array(binaryDerString.length);
        for (let i = 0; i < binaryDerString.length; i++) {
            binaryDer[i] = binaryDerString.charCodeAt(i);
        }
        return binaryDer.buffer;
    };

    // Function to import the public key
    importPublicKey = async (pem) => {
        const arrayBuffer = this.pemToArrayBuffer(pem);
        const publicKey = await crypto.subtle.importKey(
            "spki",
            arrayBuffer,
            {
                name: "RSA-OAEP",
                hash: "SHA-1"
            },
            true,
            ["encrypt"]
        );
        return publicKey;
    };

    // Function to encrypt text
    encryptText = async (text, publicKey) => {
        const encodedText = new TextEncoder().encode(text);
        const encrypted = await crypto.subtle.encrypt(
            {
                name: "RSA-OAEP"
            },
            publicKey,
            encodedText
        );
        return encrypted;
    };


    ClearDatas() {
        this.setState({
            OpenTermsPopup: false,
            AadhaarTermsCondition: false,
            OpenVerifyTermsPopup: false,
            VerifyTermsCondition: false,
            ForgotABHAClicked: false,
            aadhar_number: "",
            aadhar_otp: "",
            AbhaVerifyWith: "AADHAR_OTP",

            Fetched_FirstName: "",
            Fetched_LastName: "",
            Fetched_MiddleName: "",
            Fetched_Dob: "",
            Fetched_MobileNumber: "",
            Fetched_ABHA_Number: "",
            Fetched_ABHA_Address: "",

            abha_address: "",
        })
    }

    GetAuthDetails = () => {
        try {
            let body = {
                'url': `https://dev.abdm.gov.in/gateway/${ABHAurls.AUTH_URL}`,
                "clientId": "SBX_006716",
                "clientSecret": "3d1877f0-ecc3-4726-8b86-d947b05f612b",
                "grantType": "client_credentials"
            }
            ABHAapiServices.PostAuth(body, Serviceurls.ABHA_BASE).
                then((response) => {
                    if (response.data) {
                        this.setState({
                            AccessToken: response.data.accessToken ? response.data.accessToken : null
                        })
                    }
                }).catch((e) => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
        }
    }


    generateUUID() {
        let dt = new Date().getTime();
        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    throwCatchErrorMessage(e) {
        try {
            if (e?.response?.data?.content?.loginId === 'Invalid LoginId') {
                this.errorMessage("Invalid Credentials")
            }
            else if (e?.response?.data?.content?.error?.code === "ABDM-1204") {
                this.errorMessage(e?.response?.data?.content?.error?.message)
            }
            else if (e?.response?.data?.content?.error?.code === "ABDM-1100") {
                this.errorMessage("You have requested multiple OTPs Or Exceeded maximum number of attempts for OTP match in this transaction. Please try again in 30 minutes.")
            }
            else if (e?.response?.data?.content?.error?.message) {
                this.errorMessage(e?.response?.data?.content?.error?.message)
            }
            else if (e?.response?.data?.content?.abhaAddress) {
                this.errorMessage(e?.response?.data?.content?.abhaAddress)
            }
            else {
                this.errorMessage(e?.message)
            }
        } catch (error) {
            this.errorMessage(error?.message)
        }
    }

    generateAadharOtp = async () => {
        try {
            const publicKey = await this.importPublicKey(AbhaPublicKey);
            const encrypted = await this.encryptText(this.state.aadhar_number, publicKey);
            const EncrypedText = Buffer.from(encrypted).toString('base64')
            let body = {
                "txnId": "",
                "scope": [
                    "abha-enrol"
                ],
                "loginHint": "aadhaar",
                "loginId": EncrypedText,
                "otpSystem": "aadhaar",
                'url': `${BASE_DOMAIN}${ABHAurls.GENERATE_AADHAR_OTP}`,
            }
            ABHAapiServices.POSTABDM(body, Serviceurls.ABHA_BASE, this.state.AccessToken, new Date().toISOString(), this.generateUUID()).
                then((response) => {
                    console.log(response)
                    if (response?.status === 200) {
                        this.successMessage(response.data.message)
                        this.setState({
                            CurrentTxnId: response?.data?.txnId ? response?.data?.txnId : ""
                        })
                    } else {
                        if (response.data?.content?.error?.message) {
                            this.errorMessage(response.data?.content?.error?.message)
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }
                }).catch((e) => {
                    this.throwCatchErrorMessage(e)

                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetABHAAddressSuggestion = () => {
        try {
            ABHAapiServices.ABHA_SUGG_GET(`${Serviceurls.ABHA_BASE}?url=${BASE_DOMAIN}${ABHAurls.ABHA_ADDRESS_SUGGESTION}`, this.state.AccessToken, this.state.CurrentTxnId, new Date().toISOString(), this.generateUUID()).
                then((response) => {
                    console.log(response)
                    if (response?.status === 200) {
                        this.successMessage(response.data.message)
                        this.setState({
                            CurrentTxnId: response?.data?.txnId ? response?.data?.txnId : ""
                        })
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((e) => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    ABHAaddressPOST = () => {
        try {
            let body = {
                "txnId": this.state.CurrentTxnId,
                "abhaAddress": this.state.abha_address,
                "preferred": 1,
                'url': `${BASE_DOMAIN}${ABHAurls.ABHA_ADDRESS_POST}`,
            }
            ABHAapiServices.POSTABDM(body, Serviceurls.ABHA_BASE, this.state.AccessToken, new Date().toISOString(), this.generateUUID()).
                then((response) => {
                    console.log(response)
                    if (response?.status === 200) {
                        this.successMessage("Address Sucessfully linked to ABHA")
                        this.setState({
                            CurrentTxnId: response?.data?.txnId ? response?.data?.txnId : "",
                            Fetched_ABHA_Address: response?.data?.preferredAbhaAddress ? response?.data?.preferredAbhaAddress : ""
                        })
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((e) => {
                    this.throwCatchErrorMessage(e)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }


    getCurrentTime() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    VerfyAadharOTP = async () => {
        try {
            const publicKey = await this.importPublicKey(AbhaPublicKey);
            const encrypted = await this.encryptText(this.state.aadhar_otp, publicKey);
            const EncrypedText = Buffer.from(encrypted).toString('base64')
            let body = {
                "authData": {
                    "authMethods": [
                        "otp"
                    ],
                    "otp": {
                        "timeStamp": this.getCurrentTime(),
                        "txnId": this.state.CurrentTxnId,
                        "otpValue": EncrypedText,
                        "mobile": this.state.aadhar_mobile
                    }
                },
                "consent": {
                    "code": "abha-enrollment",
                    "version": "1.4"
                },
                "url": `${BASE_DOMAIN}${ABHAurls.VERIFY_AADHAR_OTP}`,
            }
            ABHAapiServices.POSTABDM(body, Serviceurls.ABHA_BASE, this.state.AccessToken, new Date().toISOString(), this.generateUUID()).
                then((response) => {
                    if (response?.status === 200) {
                        this.successMessage(response.data.message)
                        this.setState({
                            Xtoken: response?.data?.tokens?.token ? response?.data?.tokens?.token : "",
                            CurrentTxnId: response?.data?.txnId ? response?.data?.txnId : "",
                            Fetched_FirstName: response?.data?.ABHAProfile?.firstName ? response?.data?.ABHAProfile?.firstName : "",
                            Fetched_LastName: response?.data?.ABHAProfile?.lastName ? response?.data?.ABHAProfile?.lastName : "",
                            Fetched_MiddleName: response?.data?.ABHAProfile?.middleName ? response?.data?.ABHAProfile?.middleName : "",
                            Fetched_Dob: response?.data?.ABHAProfile?.dob ? response?.data?.ABHAProfile?.dob : "",
                            Fetched_MobileNumber: response?.data?.ABHAProfile?.mobile ? response?.data?.ABHAProfile?.mobile : "",
                            Fetched_ABHA_Number: response?.data?.ABHAProfile?.ABHANumber ? response?.data?.ABHAProfile?.ABHANumber : "",
                            Fetched_ABHA_Address: response?.data?.ABHAProfile?.phrAddress?.length > 0 ? response?.data?.ABHAProfile?.phrAddress[0] : "",
                            AadharOTPisVerified: true,
                            isNewABHA: response?.data?.isNew ? response?.data?.isNew : false,
                        }, () => {
                            if (this.state.isNewABHA) {
                                // this.GetABHAAddressSuggestion()
                            } else {
                                // this.VerifyABHAuser(this.state.Fetched_ABHA_Number)
                                // this.GetABHACard()
                            }
                        })
                    } else {
                        if (response.data?.content?.error?.message) {
                            this.errorMessage(response.data?.content?.error?.message)
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }
                }).catch((e) => {
                    this.throwCatchErrorMessage(e)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    VerifyABHAbyDemo = () => {
        try {
            let body = {
                "scope": "HIECM",
                "parameters": {
                    "abhaNumber": "91-5364-5432-6789",
                    "abhaAddress": "ranjithkumar0798@sbx",
                    "name": "Ranjithkumar",
                    "gender": "M",
                    "yearOfBirth": 1998
                },
                "url": `${BASE_DOMAIN}${ABHAurls.DEMO_AUTHENTICATION}`,
            }
            ABHAapiServices.DEMO_AUTH(body, Serviceurls.ABHA_BASE, this.state.AccessToken, new Date().toISOString(), this.generateUUID()).
                then((response) => {
                    console.log(response)
                    if (response?.status === 200) {
                        this.successMessage(response.data.message)
                        this.setState({
                            CurrentTxnId: response?.data?.txnId ? response?.data?.txnId : "",
                            Fetched_FirstName: response?.data?.ABHAProfile?.firstName ? response?.data?.ABHAProfile?.firstName : "",
                            Fetched_LastName: response?.data?.ABHAProfile?.lastName ? response?.data?.ABHAProfile?.lastName : "",
                            Fetched_MiddleName: response?.data?.ABHAProfile?.middleName ? response?.data?.ABHAProfile?.middleName : "",
                            Fetched_Dob: response?.data?.ABHAProfile?.dob ? response?.data?.ABHAProfile?.dob : "",
                            Fetched_MobileNumber: response?.data?.ABHAProfile?.mobile ? response?.data?.ABHAProfile?.mobile : "",
                            Fetched_ABHA_Number: response?.data?.ABHAProfile?.ABHANumber ? response?.data?.ABHAProfile?.ABHANumber : "",
                            AadharOTPisVerified: true,
                        }, () => {
                            this.GetABHAAddressSuggestion()
                        })
                    } else {
                        if (response.data?.content?.error?.message) {
                            this.errorMessage(response.data?.content?.error?.message)
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }
                }).catch((e) => {
                    this.throwCatchErrorMessage(e)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
        // this.successMessage("aadhar verfied")
        // this.setState({
        //     AadharOTPisVerified: true
        // })
    }

    retrivABHAfrmAadhar = async () => {
        try {
            const publicKey = await this.importPublicKey(AbhaPublicKey);
            const encrypted = await this.encryptText(this.state.abha_address_number, publicKey);
            const EncrypedText = Buffer.from(encrypted).toString('base64')
            let body = {
                "scope": [
                    "abha-login",
                    "aadhaar-verify"
                ],
                "loginHint": "aadhaar",
                "loginId": EncrypedText,
                "otpSystem": "aadhaar",
                "url": `${BASE_DOMAIN}${ABHAurls.RETRIEVE_ABHA_ACCOUNT_VIA_AADHAR}`,
            }
            if (this.state.AbhaVerifyWith === "AADHAR_OTP") {
                body['loginHint'] = "aadhaar"
                body['otpSystem'] = "aadhaar"
            } else if (this.state.AbhaVerifyWith === "MOBILE_OTP") {
                body['loginHint'] = "mobile"
                body['otpSystem'] = "abdm"
                body['scope'] = [
                    "abha-login",
                    "mobile-verify"
                ]
            } else if (this.state.AbhaVerifyWith === "ABHA_NUMBER") {
                body['loginHint'] = "abha-number"
                body['otpSystem'] = "abdm"
            }
            ABHAapiServices.POSTABDM(body, Serviceurls.ABHA_BASE, this.state.AccessToken, new Date().toISOString(), this.generateUUID()).
                then((response) => {
                    console.log(response)
                    if (response?.status === 200) {
                        this.successMessage(response.data.message)
                        this.setState({
                            CurrentTxnId: response?.data?.txnId ? response?.data?.txnId : ""
                        })
                    } else {
                        if (response.data?.content?.error?.message) {
                            this.errorMessage(response.data?.content?.error?.message)
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }
                }).catch((e) => {
                    this.throwCatchErrorMessage(e)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
        // this.successMessage("aadhar verfied")
        // this.setState({
        //     AadharOTPisVerified: true
        // })
    }

    retrivABHAfrmAadharVerfy = async () => {
        try {
            const publicKey = await this.importPublicKey(AbhaPublicKey);
            const encrypted = await this.encryptText(this.state.verfify_abha_otp, publicKey);
            // const encrypted = await this.encryptText("594697191136", publicKey);
            const EncrypedText = Buffer.from(encrypted).toString('base64')
            let body = {}
            if (this.state.AbhaVerifyWith === "AADHAR_OTP" || this.state.AbhaVerifyWith === "ABHA_NUMBER") {
                body = {
                    "scope": [
                        "abha-login",
                        "aadhaar-verify",
                    ],
                    "authData": {
                        "authMethods": [
                            "otp"
                        ],
                        "otp": {
                            "txnId": this.state.CurrentTxnId,
                            "otpValue": EncrypedText
                        }
                    },
                    "url": `${BASE_DOMAIN}${ABHAurls.VERIFY_ABHA_ACCOUNT_VIA_AADHAR}`,
                }
            } else {
                body = {
                    "scope": [
                        "abha-login",
                        "mobile-verify",
                    ],
                    "authData": {
                        "authMethods": [
                            "otp"
                        ],
                        "otp": {
                            "txnId": this.state.CurrentTxnId,
                            "otpValue": EncrypedText
                        }
                    },
                    "url": `${BASE_DOMAIN}${ABHAurls.VERIFY_ABHA_ACCOUNT_VIA_AADHAR}`,
                }
            }

            ABHAapiServices.POSTABDM(body, Serviceurls.ABHA_BASE, this.state.AccessToken, new Date().toISOString(), this.generateUUID()).
                then((response) => {
                    console.log(response)
                    if (response?.status === 200 && response?.data?.authResult !== "failed") {
                        this.successMessage(response.data.message)
                        this.setState({
                            CurrentTxnId: response?.data?.txnId ? response?.data?.txnId : "",
                            Xtoken: response?.data?.token ? response?.data?.token : "",
                        }, () => {
                            let ABHANumber = response?.data?.accounts?.length > 0 ? response?.data?.accounts[0]?.ABHANumber : ""
                            if (ABHANumber) {
                                let ABHAProfile = response?.data?.accounts[0]
                                this.setState({
                                    Fetched_FirstName: ABHAProfile?.name ? ABHAProfile?.name : "",
                                    // Fetched_LastName: ABHAProfile?.lastName ? ABHAProfile?.lastName : "",
                                    // Fetched_MiddleName: ABHAProfile?.middleName ? ABHAProfile?.middleName : "",
                                    Fetched_Dob: ABHAProfile?.dob ? ABHAProfile?.dob : "",
                                    Fetched_MobileNumber: ABHAProfile?.mobile ? ABHAProfile?.mobile : "",
                                    Fetched_ABHA_Number: ABHAProfile?.ABHANumber ? ABHAProfile?.ABHANumber : "",
                                    Fetched_ABHA_Address: ABHAProfile?.preferredAbhaAddress ? ABHAProfile?.preferredAbhaAddress : "",
                                    AadharOTPisVerified: true,
                                }, () => {
                                    if (this.state.AbhaVerifyWith === "AADHAR_OTP") {
                                        // this.GetABHACard()
                                    } else {
                                        this.VerifyABHAuser(ABHANumber)
                                    }

                                })
                            }

                        })
                    } else if (response?.data?.authResult === "failed") {
                        this.errorMessage(response.data?.message)
                    }
                }).catch((e) => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
        // this.successMessage("aadhar verfied")
        // this.setState({
        //     AadharOTPisVerified: true
        // })
    }

    VerfybyDemographics = () => {
        try {
            let body = {
                "scope": "DEMO",
                "parameters": {
                    // "abhaNumber": 91178386101731,
                    // "abhaAddress": "@sbx"91178386101731,
                    "name": "Mayur Bapu Chaskar",
                    "gender": "M",
                    "yearOfBirth": 1994
                },
                "url": `${BASE_DOMAIN}${ABHAurls.VERIFY_ABHA_ACCOUNT_VIA_DEMOGRAPHICS}`,
            }

            ABHAapiServices.VERIFY_DEMO(body, Serviceurls.ABHA_BASE, this.state.AccessToken, new Date().toISOString(), this.generateUUID()).
                then((response) => {
                    console.log(response)
                    if (response?.status === 200) {
                        this.successMessage(response.data.message)
                        // this.setState({
                        //     CurrentTxnId: response?.data?.txnId ? response?.data?.txnId : ""
                        // })
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((e) => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
        // this.successMessage("aadhar verfied")
        // this.setState({
        //     AadharOTPisVerified: true
        // })
    }

    VerifyABHAuser = (ABHANumber) => {
        try {
            let body = {
                "ABHANumber": ABHANumber,
                "txnId": this.state.CurrentTxnId,
                "url": `${BASE_DOMAIN}${ABHAurls.VERIFY_ABHA_USER}`,
            }
            ABHAapiServices.VERIFY_USER(body, Serviceurls.ABHA_BASE, this.state.AccessToken, this.state.Xtoken, new Date().toISOString(), this.generateUUID()).
                then((response) => {
                    console.log(response)
                    if (response?.status === 200) {
                        // this.successMessage(response.data.message)
                        this.setState({
                            Xtoken: response?.data?.token ? response?.data?.token : ""
                        }, () => {
                            this.GetABhaProfile()
                        })
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((e) => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
        // this.successMessage("aadhar verfied")
        // this.setState({
        //     AadharOTPisVerified: true
        // })
    }


    GetABhaProfile = () => {
        try {
            ABHAapiServices.GET_ABHA_PROFILE(Serviceurls.ABHA_BASE + `?url=${BASE_DOMAIN}${ABHAurls.GET_ABHA_PROFILE_ACC}`, this.state.AccessToken, this.state.Xtoken, new Date().toISOString(), this.generateUUID()).
                then((response) => {
                    console.log(response)
                    if (response?.status === 200) {
                        // this.successMessage(response.data.message)
                        let ABHAProfile = response?.data ? response?.data : {}
                        this.setState({
                            Fetched_FirstName: ABHAProfile?.firstName ? ABHAProfile?.firstName : "",
                            Fetched_LastName: ABHAProfile?.lastName ? ABHAProfile?.lastName : "",
                            Fetched_MiddleName: ABHAProfile?.middleName ? ABHAProfile?.middleName : "",
                            Fetched_Dob: ABHAProfile?.yearOfBirth ? ABHAProfile?.yearOfBirth : "",
                            Fetched_MobileNumber: ABHAProfile?.mobile ? ABHAProfile?.mobile : "",
                            Fetched_ABHA_Number: ABHAProfile?.ABHANumber ? ABHAProfile?.ABHANumber : "",
                            Fetched_ABHA_Address: ABHAProfile?.preferredAbhaAddress ? ABHAProfile?.preferredAbhaAddress : "",
                            AadharOTPisVerified: true,
                        }, () => {
                            // this.GetABHACard()
                        })
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((e) => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
        // this.successMessage("aadhar verfied")
        // this.setState({
        //     AadharOTPisVerified: true
        // })
    }

    GetABHACard = async () => {
        try {
            ABHAapiServices.GET_ABHA_CARD(Serviceurls.ABHA_BASE + `?url=${BASE_DOMAIN}${ABHAurls.GET_ABHA_CARD}`, this.state.AccessToken, this.state.Xtoken, new Date().toISOString(), this.generateUUID()).
                then(async (response) => {
                    console.log(response)
                    if (response?.status === 200) {
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    } else {
                        this.errorMessage(response.data.message)
                    }
                })
                .catch((e) => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
        // this.successMessage("aadhar verfied")
        // this.setState({
        //     AadharOTPisVerified: true
        // })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }


    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    handleInputChange(e, StateKey) {
        let states = this.state
        let value = e.target.value
        if (StateKey === "aadhar_number" || StateKey === "aadhar_mobile" || StateKey === "aadhar_otp" || StateKey === "mobile_number" || StateKey === "mobile_otp" || StateKey === "demo_verify_mobile" || StateKey === "demo_verify_birthYear") {
            if (CommonValidation.numberOnly(value) || value === "") {
                states[StateKey] = e.target.value
            }
        } else if (StateKey === "abha_address_number") {
            if (this.state.AbhaVerifyWith === "ABHA_NUMBER") {
                let inputValue = e.target.value.replace(/-/g, ''); // Remove existing dashes
                if (inputValue.length <= 2) {
                    inputValue = inputValue.slice(0, 2);
                } else if (inputValue.length <= 6) {
                    inputValue = inputValue.slice(0, 2) + '-' + inputValue.slice(2, 6);
                } else if (inputValue.length <= 10) {
                    inputValue = inputValue.slice(0, 2) + '-' + inputValue.slice(2, 6) + '-' + inputValue.slice(6, 10);
                } else if (inputValue.length <= 14) {
                    inputValue = inputValue.slice(0, 2) + '-' + inputValue.slice(2, 6) + '-' + inputValue.slice(6, 10) + '-' + inputValue.slice(10, 14);
                } else {
                    inputValue = inputValue.slice(0, 2) + '-' + inputValue.slice(2, 6) + '-' + inputValue.slice(6, 10) + '-' + inputValue.slice(10, 14);
                }
                states[StateKey] = inputValue
            } else {
                if (CommonValidation.numberOnly(value) || value === "") {
                    states[StateKey] = e.target.value
                }
            }

        } else {
            states[StateKey] = e.target.value
        }
        if (StateKey === "aadhar_number") {
            states["AadhaarTermsCondition"] = false
            states["aadhar_mobile"] = ""
        }
        this.setState({ states })
    }

    renderTextBox = (label, width = '10vw', StateKey, MaxLength = 35, disabled = false) => {
        let states = this.state
        return (
            <TextField
                autoComplete='off'
                label={label}
                size='small'
                disabled={disabled}
                inputProps={{ maxLength: MaxLength }}
                value={states[StateKey]}
                onChange={(e) => { this.handleInputChange(e, StateKey) }}
                sx={{ width: width }}
            />
        )
    }

    renderTextBoxReadOnly = (label, width = '10vw', StateKey) => {
        let states = this.state
        return (
            <TextField
                autoComplete='off'
                label={label}
                size='small'
                disabled={true}
                value={states[StateKey] ? states[StateKey] : ""}
                sx={{ width: width }}
                variant='standard'
            />
        )
    }

    renderDropDown = (label, DataList = [], stateKey, width = '10vw') => {
        let states = this.state
        return (
            <FormControl sx={{ width: width }} size="small">
                <InputLabel>{label}</InputLabel>
                <Select
                    value={states[stateKey]}
                    name={states[stateKey]}
                    label={label}
                    onChange={(e) => {
                        states[stateKey] = e.target.value
                        if (stateKey === "AbhaVerifyWith") {
                            states["abha_address_number"] = ""
                        }
                        this.setState({ states })
                    }}
                >
                    {
                        DataList.map((list, index) => (
                            <MenuItem key={index} value={list?.value} >{list?.label}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        )
    }

    renderRecoveryABHA = () => {
        return (
            <Box sx={{ marginTop: "1vw" }}>
                <Typography sx={{ marginLeft: "1.5vw", marginTop: "0.5vw" }} fontWeight={600}>Verify ABHA</Typography>
                <Box sx={{ width: "25vw" }} className="eMed_ABHA_Individual_Div">
                    {this.renderDropDown("Verify With", VerfifyOTPoptions, "AbhaVerifyWith", "20vw")}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between" }}>
                    <Box sx={{ width: "25vw" }} className="eMed_ABHA_Individual_Div">
                        {this.renderTextBox(this.state.AbhaVerifyWith === "AADHAR_OTP" ? "Addhar Number" : this.state.AbhaVerifyWith === "ABHA_NUMBER" ? "ABHA Number" : "Mobile Number", "20vw", 'abha_address_number', this.state.AbhaVerifyWith === "AADHAR_OTP" ? 12 : this.state.AbhaVerifyWith === "ABHA_NUMBER" ? 17 : 10)}
                        <Box >
                            <FormControlLabel
                                disabled={this.state.abha_address_number?.length !== 12}
                                sx={{ visibility: this.state.AbhaVerifyWith === "AADHAR_OTP" ? 'visible' : 'hidden' }}
                                control={<Checkbox
                                    checked={this.state.VerifyTermsCondition}
                                    onClick={(e) => {
                                        if (this.state.VerifyTermsCondition) {
                                            this.setState({ VerifyTermsCondition: false })
                                        } else {
                                            this.setState({ OpenVerifyTermsPopup: true })
                                        }
                                    }} />}
                                label="Terms & Condition" />
                            <Button size='small' variant='contained' disabled={this.state.AbhaVerifyWith === "AADHAR_OTP" ? (!this.state.VerifyTermsCondition || this.state.abha_address_number?.length !== 12) : this.state.AbhaVerifyWith === "ABHA_NUMBER" ? this.state.abha_address_number?.length !== 17 : this.state.abha_address_number?.length !== 10} onClick={() => { this.retrivABHAfrmAadhar() }}>Generate OTP</Button>
                        </Box>
                    </Box>
                    <Box sx={{ width: "25vw" }} className="eMed_ABHA_Individual_Div">
                        {this.renderTextBox("Enter OTP", "20vw", 'verfify_abha_otp', 6,)}
                        <Box sx={{ marginTop: "0.5vw" }}>
                            <Button size='small' disabled={this.state.AbhaVerifyWith === "AADHAR_OTP" ? !(this.state.VerifyTermsCondition && this.state.verfify_abha_otp?.length === 6) : !(this.state.verfify_abha_otp?.length === 6)} variant='contained' onClick={() => { this.retrivABHAfrmAadharVerfy() }}>Verify OTP</Button>
                        </Box>
                    </Box>
                </Box>

                {/* {
                    (this.state.AbhaVerifyWith === "AADHAR_OTP" || this.state.AbhaVerifyWith === "MOBILE_OTP" || this.state.AbhaVerifyWith === "ABHA_NUMBER") ?
                        <Box>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between" }}>
                                <Box sx={{ width: "25vw" }} className="eMed_ABHA_Individual_Div">
                                    {this.renderTextBox("Name", "20vw")}
                                </Box>
                                <Box sx={{ width: "25vw" }} className="eMed_ABHA_Individual_Div">
                                    {this.renderDropDown("Gender", RecoveryOptions, "RecoveryFrom", "20vw")}
                                </Box>
                                <Box sx={{ width: "25vw" }} className="eMed_ABHA_Individual_Div">
                                    {this.renderTextBox("Mobile Number", "20vw")}
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", width: "46.5vw" }}>
                                <Box sx={{ width: "25vw" }} className="eMed_ABHA_Individual_Div">
                                    {this.renderTextBox("Year of Birth", "20vw")}
                                </Box>
                                <Box sx={{ width: "25vw" }} className="eMed_ABHA_Individual_Div">
                                    <Button size='small' variant='contained' onClick={() => { this.VerifyABHAbyDemo() }}>Verify ABHA</Button>
                                </Box>
                            </Box>
                        </Box> : null
                } */}
                {
                    (this.state.Fetched_ABHA_Number || this.state.Fetched_ABHA_Address) ?
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around", marginLeft: "1vw", marginBottom: "1vw" }}>
                            <Typography display={this.state.Fetched_ABHA_Number ? "inline" : "none"}>{`ABHA Number : ${this.state.Fetched_ABHA_Number}`}</Typography>
                            <Typography display={this.state.Fetched_ABHA_Address ? "inline" : "none"}>{`ABHA Address : ${this.state.Fetched_ABHA_Address}`}</Typography>
                            {this.state.Fetched_ABHA_Number || this.state.Fetched_ABHA_Address ? <Button size='small' variant='contained' onClick={() => { this.props.SendHealthID(this.state.Fetched_ABHA_Number ? this.state.Fetched_ABHA_Number : "", this.state.Fetched_ABHA_Address ? this.state.Fetched_ABHA_Address : "") }}> Submit</Button> : null}
                            {this.state.Xtoken ? <Button size='small' variant='contained' onClick={() => { this.GetABHACard() }}> Download ABHA Card</Button> : null}
                        </Box> : null}
            </Box>
        )
    }

    render() {
        console.log(this.state);
        return (
            <Box>
                <Modal open={true}>
                    <Box component={'div'} className="eMed_ABHA_Popup" >
                        <Box component={'div'} className="eMed_ABHA_Top">
                            <Typography variant='h6'>{"Patient Registration"}</Typography>
                            <Button onClick={() => { this.props.CloseAbhaPopup() }} >
                                <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                            </Button>
                        </Box>
                        <Divider />
                        <Box sx={{ height: '76vh', overflow: 'auto' }}>
                            <Box marginLeft={"1.5vw"}>
                                <Typography>Link ABHA</Typography>
                                <FormControlLabel sx={{ marginLeft: "1vw" }} checked={this.state.isHaveABHA} control={<Checkbox />} onClick={(e) => { this.setState({ isHaveABHA: e.target.checked }, () => { this.ClearDatas() }) }} label="Do you have ABHA Number | ABHA Address" />
                                {/* {this.state.isHaveABHA ? <Typography sx={{ marginLeft: "1.5vw" }} onClick={() => { this.setState({ ForgotABHAClicked: true }) }} className='eMed_ABHA_Forgot_text'>{"Forgot ABHA ?"}</Typography> : null} */}
                            </Box>
                            <Divider />
                            {
                                this.state.isHaveABHA ? this.renderRecoveryABHA() :
                                    <Box sx={{ marginTop: "1vw" }}>
                                        <Typography sx={{ marginLeft: "1.5vw", marginTop: "0.5vw" }} fontWeight={600}>Create ABHA</Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                            <Box sx={{ width: "25vw" }} className="eMed_ABHA_Individual_Div">
                                                {this.renderTextBox("Aadhaar Number", "20vw", 'aadhar_number', 12, this.state.AadharOTPisVerified)}
                                                <Box sx={{ visibility: this.state.AadharOTPisVerified ? 'hidden' : "visible" }}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            disabled={this.state.aadhar_number?.length !== 12 || this.state.AadharOTPisVerified}
                                                            checked={this.state.AadhaarTermsCondition}
                                                            onClick={(e) => {
                                                                if (this.state.AadhaarTermsCondition) {
                                                                    this.setState({ AadhaarTermsCondition: false })
                                                                } else {
                                                                    this.setState({ OpenTermsPopup: true })
                                                                }
                                                            }} />}
                                                        label="Terms & Condition" />
                                                    {/* <Button size='small' disabled={!(this.state.aadhar_number?.length === 12 && this.state.AadhaarTermsCondition)} variant='contained' onClick={() => { this.generateAadharOtp() }}>Get Aadhar OTP</Button> */}
                                                </Box>
                                            </Box>
                                            <Box sx={{ width: "25vw" }} className="eMed_ABHA_Individual_Div">
                                                {this.renderTextBox("Mobile Number", "20vw", 'aadhar_mobile', 10, (this.state.AadharOTPisVerified || !this.state.AadhaarTermsCondition))}
                                                <Box sx={{ marginTop: "0.5vw" }}>
                                                    <Button size='small' disabled={!(this.state.aadhar_number?.length === 12 && this.state.aadhar_mobile?.length === 10 && this.state.AadhaarTermsCondition) || this.state.AadharOTPisVerified} variant='contained' onClick={() => { this.generateAadharOtp() }}>Get Aadhar OTP</Button>
                                                </Box>
                                            </Box>
                                            {/* {this.state.AadharOTPisVerified ? null : */}
                                            <Box sx={{ width: "25vw" }} className="eMed_ABHA_Individual_Div">
                                                {this.renderTextBox("Enter OTP", "20vw", 'aadhar_otp', 6, this.state.AadharOTPisVerified)}
                                                <Box sx={{ marginTop: "0.5vw" }}>
                                                    <Button size='small' variant='contained' disabled={this.state.aadhar_otp?.length !== 6 || this.state.AadharOTPisVerified} onClick={() => { this.VerfyAadharOTP() }}>Verify OTP</Button>
                                                </Box>
                                            </Box>
                                            {/* } */}

                                            {/* {this.state.AadharOTPisVerified ?
                                                <Box sx={{ width: "25vw" }} className="eMed_ABHA_Individual_Div">
                                                    {this.renderTextBox("Enter OTP", "20vw")}
                                                    <Box sx={{ marginTop: "0.5vw" }}>
                                                        <Button size='small' variant='contained' onClick={() => { this.VerfyAadharOTP() }}>Verify OTP</Button>
                                                    </Box>
                                                </Box> : null} */}
                                        </Box>
                                        <Divider />
                                        <Box sx={{ marginTop: "1vw" }}>
                                            {/* <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", margin: "1vw" }}>
                                                {this.renderTextBox("Full Name", "20vw")}
                                                {this.renderTextBox("Middle Name", "20vw")}
                                                {this.renderTextBox("Last Name", "20vw")}
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginLeft: "1vw", margin: "1vw", width: "44vw" }}>
                                                {this.renderTextBox("Password", "20vw")}
                                                {this.renderTextBox("Email", "20vw")}
                                            </Box> */}
                                            {
                                                (this.state.Fetched_ABHA_Number || this.state.Fetched_ABHA_Address) ?
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around", marginLeft: "1vw", marginBottom: "1vw" }}>
                                                        <Typography display={this.state.Fetched_ABHA_Number ? "inline" : "none"}>{`ABHA Number : ${this.state.Fetched_ABHA_Number}`}</Typography>
                                                        <Typography display={this.state.Fetched_ABHA_Address ? "inline" : "none"}>{`ABHA Address : ${this.state.Fetched_ABHA_Address}`}</Typography>
                                                        {this.state.Fetched_ABHA_Number || this.state.Fetched_ABHA_Address ? <Button size='small' variant='contained' onClick={() => { this.props.SendHealthID(this.state.Fetched_ABHA_Number ? this.state.Fetched_ABHA_Number : "", this.state.Fetched_ABHA_Address ? this.state.Fetched_ABHA_Address : "") }}> Submit</Button> : null}
                                                        {this.state.Xtoken ? <Button size='small' variant='contained' onClick={() => { this.GetABHACard() }}> Download ABHA Card</Button> : null}
                                                    </Box> : null}
                                            <Box sx={{ display: (this.state.isNewABHA) ? 'block' : 'none' }}>
                                                <Typography sx={{ marginLeft: "1.5vw", marginTop: "0.5vw" }} fontWeight={600}>{this.state.isNewABHA ? "Create ABHA Address" : "Change ABHA Address"}</Typography>
                                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'flex-start', marginLeft: "1vw", margin: "1vw", width: "44vw" }}>
                                                    {this.renderTextBox("ABHA Address", "20vw", "abha_address", 18)}
                                                    <Button disabled={this.state.abha_address?.length < 8} size='small' sx={{ marginLeft: "2vw" }} variant='contained' onClick={() => { this.ABHAaddressPOST() }}>{this.state.isNewABHA ? "Create ABHA Address" : "Change ABHA Address"}</Button>
                                                </Box>
                                            </Box>
                                            <Divider />
                                        </Box>
                                    </Box>}
                            <Divider />
                            <Box sx={{ marginTop: "1vw" }}>
                                <Typography sx={{ marginLeft: "1.5vw", marginTop: "0.5vw" }} fontWeight={600}>Aadhaar Details</Typography>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", margin: "1vw" }}>
                                    {this.renderTextBoxReadOnly("Full Name", "20vw", "Fetched_FirstName")}
                                    {this.renderTextBoxReadOnly("Middle Name", "20vw", "Fetched_MiddleName")}
                                    {this.renderTextBoxReadOnly("Last Name", "20vw", "Fetched_LastName")}
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginLeft: "1vw", margin: "1vw", width: "44vw" }}>
                                    {this.renderTextBoxReadOnly("Year of Birth", "20vw", "Fetched_Dob")}
                                    {this.renderTextBoxReadOnly("Mobile Number", "20vw", "Fetched_MobileNumber")}
                                </Box>
                            </Box>
                        </Box>
                        <Dialog open={this.state.OpenTermsPopup}>
                            <DialogTitle>{"Terms and Conditions"}</DialogTitle>
                            <DialogContent dividers sx={{ width: '30vw', height: '15vw' }}>
                                <DialogContentText emed_tid='pop_tlt' id="alert-dialog-description">
                                    {"I, hereby declare that I am voluntarily sharing my Aadhaar number and demographic information issued by UIDAI, with National Health Authority (NHA) for the sole purpose of creation of ABHA number. I understand that my ABHA number can be used and shared for purposes as may be notified by ABDM from time to time including provision of healthcare services. Further, I am aware that my personal identifiable information (Name, Address, Age, Date of Birth, Gender and Photograph) may be made available to the entities working in the National Digital Health Ecosystem (NDHE) which inter alia includes stakeholders and entities such as healthcare professionals (e.g. doctors), facilities (e.g. hospitals, laboratories) and data fiduciaries (e.g. health programmes), which are registered with or linked to the Ayushman Bharat Digital Mission (ABDM), and various processes there under. I authorize NHA to use my Aadhaar number for performing Aadhaar based authentication with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication. I have been duly informed about the option of using other IDs apart from Aadhaar; however, I consciously choose to use Aadhaar number for the purpose of availing benefits across the NDHE. I am aware that my personal identifiable information excluding Aadhaar number / VID number can be used and shared for purposes as mentioned above. I reserve the right to revoke the given consent at any point of time as per provisions of Aadhaar Act and Regulations."}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button emed_tid='pop_yes' size="small" variant="contained" disabled={this.props.disableBtn} sx={{ textTransform: "capitalize" }} onClick={() => { this.setState({ OpenTermsPopup: false }) }}>Close</Button>
                                <Button emed_tid='pop_yes' size="small" variant="contained" disabled={this.props.disableBtn} sx={{ textTransform: "capitalize" }} onClick={() => { this.setState({ AadhaarTermsCondition: true, OpenTermsPopup: false }) }}>I Agree</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={this.state.OpenVerifyTermsPopup}>
                            <DialogTitle>{"Terms and Conditions"}</DialogTitle>
                            <DialogContent dividers sx={{ width: '30vw', height: '15vw' }}>
                                <DialogContentText emed_tid='pop_tlt' id="alert-dialog-description">
                                    {"I, hereby declare that I am voluntarily sharing my Aadhaar number and demographic information issued by UIDAI, with National Health Authority (NHA) for the sole purpose of creation of ABHA number. I understand that my ABHA number can be used and shared for purposes as may be notified by ABDM from time to time including provision of healthcare services. Further, I am aware that my personal identifiable information (Name, Address, Age, Date of Birth, Gender and Photograph) may be made available to the entities working in the National Digital Health Ecosystem (NDHE) which inter alia includes stakeholders and entities such as healthcare professionals (e.g. doctors), facilities (e.g. hospitals, laboratories) and data fiduciaries (e.g. health programmes), which are registered with or linked to the Ayushman Bharat Digital Mission (ABDM), and various processes there under. I authorize NHA to use my Aadhaar number for performing Aadhaar based authentication with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication. I have been duly informed about the option of using other IDs apart from Aadhaar; however, I consciously choose to use Aadhaar number for the purpose of availing benefits across the NDHE. I am aware that my personal identifiable information excluding Aadhaar number / VID number can be used and shared for purposes as mentioned above. I reserve the right to revoke the given consent at any point of time as per provisions of Aadhaar Act and Regulations."}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button emed_tid='pop_yes' size="small" variant="contained" disabled={this.props.disableBtn} sx={{ textTransform: "capitalize" }} onClick={() => { this.setState({ OpenVerifyTermsPopup: false }) }}>Close</Button>
                                <Button emed_tid='pop_yes' size="small" variant="contained" disabled={this.props.disableBtn} sx={{ textTransform: "capitalize" }} onClick={() => { this.setState({ VerifyTermsCondition: true, OpenVerifyTermsPopup: false }) }}>I Agree</Button>
                            </DialogActions>
                        </Dialog>

                    </Box>
                </Modal>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
