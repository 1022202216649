import React, { Component } from 'react'
import ShowComponents from '../Common/ShowComponent'
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { Colors } from '../../../../Styles/Colors'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { withTranslation } from 'react-i18next';
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import RestAPIService from '../../../../Utility/Services/RestAPIService'


class DocReviewPlanLeft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appointmentId: this.props.appointmentId,
      loading: false,
      isLocked: false,
      ReviewData: [],
    }
  }

  apiCalls = () => {
    this.getReviewPlan()
  }

  componentDidUpdate(prevprops) {
    if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.getReviewPlan() }
  }

  getReviewPlan = () => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_OPT_REVIEW_PALN + `?appointment_id=${this.state.appointmentId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            let Data = [];
            if (response.data.data?.review_plan) {
              Data.push(response.data.data?.review_plan)
            }
            this.setState({
              ReviewData: Data,
              loading: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  render() {
    const { t } = this.props
    return (
      <ShowComponents onIntersection={this.apiCalls.bind(this)}>
        <Box height={'30vh'} overflow={'auto'}>
          <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'}>
            <Box component={'div'} pt={'0.5vw'} sx={{ border: '1px solid lightgray', maxHeight: '20vw', overflow: 'auto' }}>
              <Table stickyHeader size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Duration</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Review Date & Time</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Comments</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Action</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    this.state.ReviewData?.length > 0 ? this.state.ReviewData?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ fontSize: "0.9vw" }}>{item?.duration ? `${item?.duration} ${item?.duration_type}` : '-'}</TableCell>
                        <TableCell>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            <div style={{ fontSize: "0.9vw" }}>
                              {item?.review_date ? item?.review_date : "-"}
                              {item?.appointment_time ? " & " + item?.appointment_time : "-"}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {item?.comments ? item?.comments?.length > 15 ?
                            <Tooltip placement='top' title={item?.comments}>
                              <Typography fontSize={'0.9vw'}>{item?.comments?.slice(0, 15) + '...'}</Typography>
                            </Tooltip> :
                            <Typography fontSize={'0.9vw'}>{item?.comments}</Typography> : '-'}
                        </TableCell>
                        <TableCell>
                          <Box component={'div'} display={'flex'} alignItems={'center'}>
                            <Button className='Common_Btn_Min_Width'
                              disabled={this.state.isLocked || this.props.isLocked}
                              onClick={() => { this.props.EditReviewPlanData(item) }}
                            >
                              <Tooltip placement='top' title={'Edit'}>
                                {this.state.isLocked || this.props.isLocked ?
                                  <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                  :
                                  <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                }
                              </Tooltip>
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )) : <TableRow>
                      <TableCell />
                      <TableCell align='center'>No Records To Be Shown</TableCell>
                      <TableCell />
                    </TableRow>}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </ShowComponents>
    )
  }
}
export default withTranslation()(DocReviewPlanLeft)

