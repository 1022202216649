import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Autocomplete, Box, Button, FormControlLabel, Paper, Radio, RadioGroup, Stack, TextField, Tooltip, Typography } from '@mui/material'
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg'
import { CommonPatientCard, CommonPatientDetails, ConsultantPatientCard } from '../../../../../../Components/Common Components/CommonComponents'
import { getCachevalue } from '../../../../../../Utility/Services/CacheProviderService'
import Loader from '../../../../../../Components/Loader'
import { Colors } from '../../../../../../Styles/Colors'
import RestAPIService from '../../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls'

class InsuCorpoDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ipPatientData: {},
            selectedPaymentType: 'Patient credit',
            CorporateList: [],
            InsuranceList: [],
            selectedCorporate: null,
            selectedInsurance: null,
            selectedInsuranceNumber: '',
            selectedCorporateNumber: '',
            selectedCorporateId: null,
            isDisabled: false
        }
    }

    componentDidMount() {
        let ipPatientData = getCachevalue("IppatientData")
        let patientData = JSON.parse(ipPatientData)
        this.setState({
            ipPatientData: patientData
        }, () => {
            this.getInsuranceList()
            this.GetCorporateList()
            this.getPatientData()
        })
    }

    GetCorporateList() {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            CorporateList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    getInsuranceList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            InsuranceList: response.data.data,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    getPatientData = () => {
        try {
            let AdmissionId = this.state?.ipPatientData?.id ? this.state?.ipPatientData?.id : ""
            RestAPIService.getAll(Serviceurls.IP_PATIENT_DETAILS + '?admission_id=' + AdmissionId)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            selectedCorporate: response.data.data[0]?.employer_name,
                            selectedCorporateNumber: response.data.data[0]?.id_no,
                            selectedCorporateId: response.data.data[0]?.employer_id,
                            selectedInsurance: response.data.data[0]?.insurance_name,
                            selectedInsuranceNumber: response.data.data[0]?.policy_no,
                            selectedPaymentType: response.data.data[0]?.credit_type === "Corprate credit" ? "Corporate credit" : response.data.data[0]?.credit_type === "Insurance credit" ? "Insurance credit" : 'Patient credit',
                            isDisabled: response.data.data[0]?.policy_status === "Claimed" ? true : false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    handleClear = () => {
        this.setState({
            selectedCorporate: null,
            selectedInsurance: null,
            selectedInsuranceNumber: '',
            selectedCorporateNumber: '',
            selectedCorporateId: null,
            selectedPaymentType: 'Patient credit'
        })
    }

    Validation() {
        if (this.state.selectedPaymentType === 'Corporate credit' && ((this.state.selectedCorporate === null || this.state.selectedCorporate === '') || (this.state.selectedCorporateNumber === '' || this.state.selectedCorporateNumber === null))) {
            this.errorMessage("Select Corporate Details")
        } else if (this.state.selectedPaymentType === 'Insurance credit' && ((this.state.selectedInsurance === null || this.state.selectedInsurance === '') || (this.state.selectedInsuranceNumber === '' || this.state.selectedInsuranceNumber === null))) {
            this.errorMessage("Select Insurance Details")
        } else {
            this.handleSave()
        }
    }

    handleSave = () => {
        try {
            let states = this.state;
            var data = {
                "ip_admission_id": states?.ipPatientData?.id,
                "patient_id": states?.ipPatientData?.patient_id,
                "credit_type": states?.selectedPaymentType === 'Corporate credit' ? 'Corprate credit' : 'Insurance credit',
                "company_name": states?.selectedPaymentType === 'Corporate credit' ? (this.state.selectedCorporate?.company_name ? this.state.selectedCorporate?.company_name : this.state.selectedCorporateId) : null,
                "employer_id": states?.selectedPaymentType === 'Corporate credit' ? (this.state.selectedCorporate?.id ? this.state.selectedCorporate?.id : this.state.selectedCorporateId) : null,
                "employee_id": states?.selectedPaymentType === 'Corporate credit' ? states?.selectedCorporateNumber : null,
                "insurance_company_name": states?.selectedPaymentType === 'Insurance credit' ? (this.state.selectedInsurance?.insurance_name ? this.state.selectedInsurance?.insurance_name : this.state.selectedInsurance) : null,
                "policy_number": states?.selectedPaymentType === 'Insurance credit' ? states?.selectedInsuranceNumber : null,
            }
            RestAPIService.create(data, Serviceurls.POST_PATIENT_CREDIT_TYPE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.getPatientData()
                        this.successMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key = false) => {
        this.setState({
            isLoader: key
        })
    }


    renderLeftContent = () => {
        const { t } = this.props
        return (
            <div>
                <Box component={'div'} className='eMed_IP_Discharge_Header'>
                    <Box>
                        <Typography paddingLeft={"0.5vw"} fontWeight={600}>{t('Credit Type')}</Typography>
                    </Box>
                    <Box component={'div'}>
                        <RadioGroup
                            value={this.state.selectedPaymentType}
                            sx={{ display: 'flex', flexDirection: 'row', marginLeft: '1vw' }}
                            emed_tid = {"selectedPaymentType_testID"}
                            onChange={(e) => {
                                this.setState({ selectedPaymentType: e.target.value })
                            }}
                        >
                            <FormControlLabel value={"Corporate credit"} control={<Radio inputProps={{ emed_tid: "TestID_Radio"}}  disabled={this.state.isDisabled} size='small' />} label="Corporate" />
                            <FormControlLabel value={"Insurance credit"} control={<Radio  inputProps={{ emed_tid:  "Insurance_credit_TestID" }}   disabled={this.state.isDisabled} size='small' />} label="Insurance" />
                        </RadioGroup>
                    </Box>
                </Box>
                <Box component={'div'} mt={'0.5vw'}>
                    <Box component={'div'} pl={'1vw'} height={'5vh'} display={'flex'} alignItems={'center'} borderBottom={'1px solid lightgray'}>
                        <Typography fontSize={'0.9vw'} fontWeight={'600'}>{this.state.selectedPaymentType === 'Corporate credit' ? 'Corporate Details' :
                            this.state.selectedPaymentType === 'Insurance credit' ? 'Insurance Details' : 'Patient Credit'}</Typography>
                    </Box>
                </Box>
                {this.state.selectedPaymentType !== "Patient credit" ? <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "1vw" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "23vw", marginTop: "0.65vw" }}>
                        {this.state.selectedPaymentType === 'Corporate credit' ?
                            <Autocomplete
                                size='small'
                                sx={{ width: '18vw', mr: '1vw' }}
                                options={this.state.CorporateList}
                                disabled={this.state.isDisabled}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.company_name}
                                value={this.state.selectedCorporate}
                                onChange={(e, value) => {
                                    this.setState({ selectedCorporate: value })
                                }}
                                renderInput={(params) => <TextField {...params} label='Select Corporate *' inputProps={{...params.inputProps,'emed_tid': 'Corporate_credit_TestID01', }}  />}
                            /> : <Autocomplete
                                size='small'
                                sx={{ width: '18vw', mr: '1vw' }}
                                options={this.state.InsuranceList}
                                disabled={this.state.isDisabled}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.insurance_name}
                                value={this.state.selectedInsurance}
                                onChange={(e, value) => {
                                    this.setState({ selectedInsurance: value })
                                }}
                                renderInput={(params) => <TextField {...params} label='Select Insurance *'  inputProps={{...params.inputProps,'emed_tid': 'Select_Insurance_testID', }}/>}
                            />}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginTop: "0.65vw" }}>
                        {this.state.selectedPaymentType === 'Corporate credit' ?
                            <TextField
                                size='small'
                                sx={{ width: '18vw' }}
                                label='Employee ID No *'
                                inputProps={{ emed_tid: "Employee_ID_TestID" }}
                                disabled={this.state.isDisabled}
                                value={this.state.selectedCorporateNumber ? this.state.selectedCorporateNumber : ""}
                                onChange={(e) => this.setState({ selectedCorporateNumber: e.target.value })}
                            /> : <TextField
                                size='small'
                                sx={{ width: '18vw' }}
                                label='Insurance No *'
                                inputProps={{ emed_tid: "Insurance_No_ID_TestID" }}
                                disabled={this.state.isDisabled}
                                value={this.state.selectedInsuranceNumber ? this.state.selectedInsuranceNumber : ""}
                                onChange={(e) => this.setState({ selectedInsuranceNumber: e.target.value })}
                            />}
                    </div>
                </Box> : <Typography sx={{ marginTop: "1vw", marginLeft: "1vw" }} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>No Insurance Or Corporate Details</Typography>}
                {this.state.selectedPaymentType === "Patient credit" ? null :
                    <Stack spacing={2} direction="row" id='eMed_recPayment_rightBtnCon' sx={{ marginTop: "15vw", marginRight: "1vw" }} alignItems="center" justifyContent="flex-end">
                        <Button emed_tid = {"Clear_TestID_01"} disabled={this.state.isDisabled} variant='outlined' size="small" id="eMed_recPayment_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                        <Button emed_tid = {"Save01_TestID_01"} disabled={this.state.isDisabled ? this.state.isDisabled : this.state.selectedPaymentType === "Corporate credit" || this.state.selectedPaymentType === "Insurance credit" ? false : true} variant='contained' size="small" id="eMed_recPayment_btn" onClick={() => { this.Validation() }}>{t("Save")}</Button>
                    </Stack>
                }
            </div>
        )
    }

    render() {
        const { t } = this.props
        var { ipPatientData } = this.state
        let attender = {
            lineOne: t("AttenderName"),
            lineTwo: `${ipPatientData.attender_name ? ipPatientData.attender_name : '-'}`,
            lineFour: t('ContactNumber'),
            LineFive: `${ipPatientData.attender_mobile ? ipPatientData.attender_mobile : '-'}`
        }
        let consultant = {
            lineOne: t("ConsultantName"),
            lineTwo: `${ipPatientData.doctor_name ? ipPatientData.doctor_name : '-'}`,
        }
        return (
            <Box sx={{ backgroundColor: Colors.ComponentBgColor }}>
                <Box component={'div'} marginLeft={'0.5vw'} className='eMed_patient_header'>
                    <Box display={'flex'} alignItems='center'>
                        <Box className='eMed_Patient_Details_Long eMed_patient_box'>
                            <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true} />
                        </Box>
                        <CommonPatientCard details={attender} showDetailed={true} />
                        <ConsultantPatientCard name={consultant} />
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'row'} marginTop={"0.5vw"}>
                    <Paper elevation={3} className='eMed_DocNotes_Left_Main'>
                        {this.renderLeftContent()}
                    </Paper>
                </Box>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(InsuCorpoDetails);