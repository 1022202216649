import { Box, Button, Chip, Modal, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import ShowComponents from './ShowComponent';

export default class DocGeneralAdvice extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            generalAdvice: '',
            saveButton: false,
            openPopUp: false,
            templateList: [],
            adviceDescrip: '',
            postLoad: false,
            addedAdvice: '',
            comments: '',
            templateName: '',
            isLocked: false,
            selectedTemplate: ''
        }
    }

    componentDidMount() {
        // let clinicId = getCachevalue('SelectedDoctorClinic')
        // let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
        // this.setState({
        //     clinicId: clinicId,
        //     appointmentId: appointmentId?.appointment_id,
        //     patientId: appointmentId?.patient_id,
        //     isLocked: appointmentId?.is_locked
        // }, () => {
        //     this.getGeneralAdviceData()
        // })
        // this.apiCalls();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    apiCalls = () => {
        this.getGeneralAdviceData()
        this.getTemplateListData()
    }

    getTemplateListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_GENERAL_ADVICE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            templateList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getGeneralAdviceData = () => {
        try {
            let appointmentId = this.props.prevAppointmentId ? this.props.prevAppointmentId : this.props.appointmentId
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_ASSESSEMENT + `?patient_id=${this.props.patientId}&appointment_id=${appointmentId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            generalAdvice: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postTemplateData = () => {
        try {
            let data = {
                advice: this.state.comments,
                template_name: this.state.templateName
            }
            this.setState({ postLoad: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_GENERAL_ADVICE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.onClearHandler()
                        this.setState({ postLoad: false, addTemplate: false })
                        this.getTemplateListData()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ postLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ postLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    postGeneralAdviceData = () => {
        try {
            let data = {
                advice_given: this.state.adviceDescrip ? this.state.adviceDescrip : this.state.selectedTemplate,
                appointment_id: this.props.appointmentId,
                patient_id: this.props.patientId
            }
            this.setState({ postLoad: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_ASSESSEMENT)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.onClearHandler()
                        this.setState({ postLoad: false, saveButton: false, openPopUp: false })
                        this.getGeneralAdviceData()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ postLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ postLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onClearHandler = () => {
        try {
            this.setState({
                adviceDescrip: '',
                addedAdvice: '',
                templateName: '',
                comments: '',
                selectedTemplate: '',
                addTemplate: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderPopUp = () => {
        try {
            return (
                <Box>
                    <Modal open={this.state.openPopUp}>
                        <Box className='eMed_Doc_Notes_Diagonis_Pop_Up'>
                            <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>General Patient Advice</Typography>
                                <Button emed_tid='gen_cls_btn'
                                    className='Common_Btn_Min_Width'
                                    onClick={() => this.setState({ openPopUp: false }, () => this.onClearHandler())}
                                >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>
                            <Box component={'div'} height={'23vw'} overflow={'scroll'}>
                                <Box component={'div'}>
                                    {this.state.addTemplate ? null :
                                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Templates</Typography>
                                        </Box>}
                                    <Box component={'div'}>
                                        {this.state.addTemplate ? null :
                                            this.state.templateList?.length > 0 && this.state.templateList?.map((item, index) => (
                                                <Chip
                                                    className={`eMed_chip ${item?.advice === this.state.selectedTemplate ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                    size='small'
                                                    label={item.template_name}
                                                    variant={item.advice === this.state.selectedTemplate ? 'contained' : 'outlined'}
                                                    key={index}
                                                    onClick={() => {
                                                        if (item.advice === this.state.selectedTemplate) {
                                                            this.setState({ selectedTemplate: '' })
                                                        } else {
                                                            this.setState({ selectedTemplate: item?.advice, adviceDescrip: '' }, () => {
                                                                if (this.state.addTemplate) {
                                                                    this.setState({
                                                                        addTemplate: false,
                                                                        templateName: '',
                                                                        comments: '',
                                                                        postLoad: false,
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    }}
                                                    // color={'primary'}
                                                    clickable
                                                    emed_tid='gen_tmp_name'
                                                />
                                            ))}
                                    </Box>
                                    <Box component={'div'} m={'0.5vw 0.5vw'}>
                                        {this.state.addTemplate ?
                                            <Box>
                                                <TextField
                                                    label='Enter Template Name'
                                                    fullWidth
                                                    size='small'
                                                    value={this.state.templateName}
                                                    onChange={(e) => this.setState({ templateName: e.target.value })}
                                                    inputProps={{emed_tid:'gen_new_tmp_name'}}
                                                />
                                                <TextField
                                                    label='Comments'
                                                    fullWidth
                                                    sx={{ mt: '0.5vw' }}
                                                    multiline
                                                    rows={3}
                                                    value={this.state.comments}
                                                    onChange={(e) => {
                                                        let value = e.target.value
                                                        if (value?.length <= 250) {
                                                            this.setState({ comments: e.target.value })
                                                        } else {
                                                            this.errorMessage('Allow only 250 character')
                                                        }
                                                    }}
                                                    inputProps={{emed_tid:'gen_tmp_cmts'}}
                                                />
                                                <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                                                    <Button emed_tid='gen_cls_btn'
                                                        sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                                        variant='outlined'
                                                        onClick={() => this.setState({ addTemplate: false, templateName: '', comments: '', postLoad: false })}
                                                    >close</Button>
                                                    <Button emed_tid='gen_add_btn'
                                                        disabled={this.state.postLoad}
                                                        sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                                        variant='contained'
                                                        onClick={() => this.postTemplateData()}
                                                    >Add</Button>
                                                </Box>
                                            </Box> :
                                            <Button variant='contained' emed_tid="gen_tmp_add_btn"
                                                sx={{ textTransform: 'capitalize' }}
                                                disabled={this.state.selectedTemplate ? true : false}
                                                onClick={() => this.setState({ addTemplate: true })}
                                            >Add New Template</Button>
                                        }
                                    </Box>
                                </Box>
                                <Box component={'div'}>
                                    {this.state.addTemplate ? null :
                                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Advice</Typography>
                                        </Box>}
                                    <Box component={'div'} m={'0vw 0.5vw'}>
                                        {this.state.addTemplate ? null :
                                            <TextField
                                                label='Advice'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                disabled={this.state.selectedTemplate ? true : false}
                                                value={this.state.adviceDescrip ? this.state.adviceDescrip : this.state.selectedTemplate}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    if (value?.length > 0) {
                                                        this.setState({ adviceDescrip: e.target.value })
                                                    }
                                                }}
                                                inputProps={{emed_tid:'gen_adv_desp'}}
                                            />
                                        }
                                    </Box>
                                </Box>
                            </Box>
                            {this.state.addTemplate ? null :
                                <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                                    <Button emed_tid='gen_clr_btn'
                                        sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                        variant='outlined'
                                        onClick={() => this.onClearHandler()}
                                    >Clear</Button>
                                    <Button emed_tid='gen_sve_btn'
                                        sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                        variant='contained'
                                        disabled={this.state.postLoad}
                                        onClick={() => { this.postGeneralAdviceData() }}
                                    >Save</Button>
                                </Box>}
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'}>
                    <Box component={'div'} height={'2.5vw'} sx={{ backgroundColor: Colors.Background }} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography pl={'0.5vw'} fontWeight={600} fontSize={'.8vw'}>General Patient Advice</Typography>
                        <Box component={'div'} display={'flex'}>
                            {/* <Button
                                disabled={(!this.state.saveButton || this.state.postLoad || this.state.isLocked || this.props.isLocked)}
                                variant='contained'
                                sx={{ width: '5vw', height: '2vw', mr: '0.5vw', textTransform: 'capitalize' }}
                                onClick={() => this.postGeneralAdviceData()}
                            >Save</Button> */}
                            <Button emed_tid='gen_add_btn'
                                variant='contained'
                                disabled={this.state.isLocked || this.props.isLocked}
                                sx={{ height: '2vw', width: '5vw', mr: '1.5vw', textTransform: 'capitalize' }}
                                onClick={() => this.setState({ openPopUp: true })}
                            >Add</Button>
                        </Box>
                    </Box>
                    <Box component={'div'} height={'7.4vw'} sx={{ backgroundColor: Colors.white, p: '0.5vw' }}>
                        <TextField
                            multiline
                            rows={3}
                            fullWidth
                            value={this.state.addedAdvice ? this.state.addedAdvice : this.state.generalAdvice?.advice_given}
                            InputProps={{ readOnly: true,emed_tid:'gen_txt' }}
                        />
                    </Box>
                    {this.renderPopUp()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                </Box>
            </ShowComponents>
        )
    }
}
