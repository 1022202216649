import { Autocomplete, Box, Button, Chip, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import Loader from '../../../../Components/Loader';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors'
import CommonGridHeader, { CommonPatientCard, CommonPatientDetails, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { DataGrid } from '@mui/x-data-grid';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { Hospital_For_List, Medical_History_Problems } from '../../../../Utility/Constants';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';
import { CheckAccessFunc } from "../../../../Components/CommonFunctions/CommonFunctions";

export default class DocHospitalizationHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      hospitalHistoryData: [],
      hospitalForList: Hospital_For_List,
      selectedHospitalFor: '',
      medicalProblemsData: Medical_History_Problems,
      comments: '',
      selectedYear: null,
      hospitalName: '',
      selectedProblem: null,
      selectedId: null,
      isEdit: false,
      deletePopup: false,
      loading: false,
      patientDetails: {},
      clinicId: '',
      appointmentId: '',
      patientId: '',
      postLoad: false,
      // for fo 
      withOutAppointment: {},
      doctor_id: "",
    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    let ipPatientData = JSON.parse(getCachevalue("IppatientData"));
    let appointmentDetail = JSON.parse(getCachevalue("withoutAppointment"));
    const newState = {
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id || ipPatientData?.patient_id,
      doctor_id: ipPatientData?.doctor_id
    };
    if (appointmentDetail?.patient_id === ipPatientData?.patient_id) {
      newState.withOutAppointment = appointmentDetail;
    }
    this.setState({
      ...newState
    }, () => {
      this.getPatientDetailData()
      this.getHospitalHistoryData()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getHospitalHistoryData = () => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_HOSPITALIZATION_HISTORY + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              hospitalHistoryData: response?.data?.data,
              loading: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  postHospitalHistoryData = () => {
    try {
      let data = {
        appointment_id: this.state.appointmentId,
        comments: this.state.comments,
        disease_name: this.state.selectedProblem,
        hospital_name_or_description: this.state.hospitalName,
        hospitalized_year: this.state.selectedYear,
        is_surgery: this.state.selectedHospitalFor === 'Surgery' ? true : false,
        patient_id: this.state.patientId,
        id: this.state.selectedId ? this.state.selectedId : undefined
      }
      if(this.props.is_From_Fo){
        data['doctor_id'] = this.state.doctor_id
      }
      this.setState({ postLoad: true })
      RestAPIService.create(data, Serviceurls.DOC_HOSPITALIZATION_HISTORY)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ postLoad: false })
            this.successMessage(response.data.message)
            this.getHospitalHistoryData()
            this.onClearHospitalHistory()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ postLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  deleteHospitalHistoryData = () => {
    try {
      let data = {
        patient_id: this.state.patientId,
        id: this.state.selectedId
      }
      this.setState({ removeData: true })
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.DOC_HOSPITALIZATION_HISTORY_DELETE)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ removeData: false })
            this.LoaderFunction(false)
            this.successMessage(response?.data?.message)
            this.onClearHospitalHistory()
            this.getHospitalHistoryData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  getYearList = () => {
    try {
      let getYear = [];
      let today = new Date();
      let year = today.getFullYear();
      for (let i = year; i > 1979; i--) {
        getYear.push(i);
      };
      return getYear;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearHospitalHistory = () => {
    try {
      this.setState({
        selectedProblem: [],
        selectedHospitalFor: '',
        selectedYear: null,
        hospitalName: '',
        comments: '',
        selectedId: null,
        isEdit: false,
        deletePopup: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkMandatory = () => {
    try {
      let states = this.state
      let validate = false;
      if (states.selectedHospitalFor === '') {
        validate = true
      } else if (states.selectedProblem === null) {
        validate = true
      } else if (states.selectedYear === null) {
        validate = true
      } else if (states.hospitalName === '') {
        validate = true
      }
      return validate;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler = (item) => {
    try {
      this.setState({
        selectedHospitalFor: item?.is_surgery ? "Surgery" : 'Hospitalised',
        selectedProblem: item?.disease_name,
        selectedYear: item?.hospitalized_year,
        hospitalName: item?.hospital_name_or_description,
        comments: item?.comments,
        selectedId: item?.id,
        isEdit: true
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose = () => {
    try {
      this.setState({
        deletePopup: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (item) => {
    try {
      if (!this.state.isEdit) {
        this.setState({
          deletePopup: true,
          selectedId: item?.id
        })
      } else {
        this.errorMessage("You have clicked edit Button.So,You cannot able to delete")
      }

    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderLeftSide = () => {
    try {
      const columns = [
        {
          field: 'is_surgery', headerName: 'Hospitailized For', flex: 0.166,
          renderCell: (params) => (<Box>{params?.row?.is_surgery ? 'Surgery' : 'Hospitalized'}</Box>)
        },
        {
          field: 'disease_name', headerName: 'Problem', flex: 0.166,
          renderCell: (params) => (<Box>{params?.row?.disease_name ? params?.row?.disease_name?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.disease_name}><div>{params?.row?.disease_name.slice(0, 15) + '...'}</div></Tooltip> :
            params?.row?.disease_name : '-'}</Box>)
        },
        { field: 'hospitalized_year', headerName: 'Year', flex: 0.166 },
        {
          field: 'hospital_name_or_description', headerName: 'Hospital Name', flex: 0.166,
          renderCell: (params) => (<Box>{params?.row?.hospital_name_or_description ? params?.row?.hospital_name_or_description?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.hospital_name_or_description}><div>{params?.row?.hospital_name_or_description.slice(0, 15) + '...'}</div></Tooltip> :
            params?.row?.hospital_name_or_description : '-'}</Box>)
        },
        {
          field: 'comments', headerName: 'Comments', flex: 0.166,
          renderCell: (params) => (<Box>{params?.row?.comments ? params?.row?.comments?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.comments}><div>{params?.row?.comments.slice(0, 15) + '...'}</div></Tooltip> :
            params?.row?.comments : '-'}</Box>)
        },
        {
          field: 'action', headerName: 'Action', flex: 0.166, headerAlign: 'center', align: 'center',
          renderCell: (params) => (<Box>
            <Button className='Common_Btn_Min_Width'
              onClick={() => this.onEditHandler(params?.row)}
            >
              <Box height={'1.5vw'} width={'1.5vw'} component={'img'} src={ImagePaths.Edit.default} />
            </Button>
            <Button className='Common_Btn_Min_Width'
              onClick={() => this.onDeleteHandler(params?.row)}
            >
              <Box height={'1.5vw'} width={'1.5vw'} component={'img'} src={ImagePaths.Delete.default} />
            </Button>
          </Box>)
        }
      ]
      return (
        <DataGrid
          rows={this.state.hospitalHistoryData}
          sx={{ backgroundColor: Colors.white }}
          columns={columns}
          getRowId={(row) => row.id}
          page={this.state.page}
          pageSize={this.state.pageSize}
          // localeText={{
          //   toolbarColumns: "",
          //   toolbarDensity: "",
          //   toolbarExport: "",
          //   toolbarFilters: "",
          //   toolbarExportPrint: ""
          // }}
          components={{
            // Toolbar: CommonGridHeader.CommonToolBar,
            NoRowsOverlay: () => (
              <Box className='eMed_conf_nodata' height={'40vh'}>
                {'No Records To Be Shown'}
              </Box>
            )
          }}
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          hideFooter
          headerHeight={40}
          disableSelectionOnClick
          loading={this.state.loading}
          emed_tid='hos_tbl'
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRightSide = () => {
    try {
      let yearList = this.getYearList()
      const enableAdd = this.checkMandatory() || this.state.postLoad;
      const checkuserAccess = (CheckAccessFunc("front_office", "In Patients", "History", "Hospitalization History", "SubTab")?.editAccess ?
      (this.state.withOutAppointment?.is_ip || true) && !enableAdd: !enableAdd) ;
      return (
        <Box component={'div'}>
          <Box component={'div'} height={'2vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Hospitalization History</Typography>
          </Box>
          <Box component={'div'} height={'12vh'} sx={{ backgroundColor: Colors.white }} overflow={'scroll'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Box component={'div'} p={'0.5vw'} width={'80vw'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <FormControl sx={{ width: '15vw' }} size='small'>
                <InputLabel id="demo-simple-select-label">{"Hospitailized For *"}</InputLabel>
                <Select
                  value={this.state.selectedHospitalFor}
                  label={"Hospitailized For *"}
                  onChange={(event) => {
                    this.setState({
                      selectedHospitalFor: event.target.value
                    })
                  }}
                >
                  {
                    this.state.hospitalForList.map((list) => (
                      <MenuItem key={1} value={list?.value}>{list.label}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              <Autocomplete
                disabled={this.state.isEdit}
                clearIcon
                size='small'
                sx={{ width: '15vw' }}
                // freeSolo
                options={this.state.medicalProblemsData.map((item) => item.label)}
                value={this.state.selectedProblem}
                limitTags={3}
                autoComplete='off'
                onChange={(event, newValue) => {
                  this.setState({
                    selectedProblem: newValue
                  })
                }}
                onInputChange={(event, newValue) => {
                  this.setState({ selectedProblem: newValue })
                }}
                renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps,emed_tid:'slt_pblm'}} label='Select Problems *' />}
              />
              <TextField
                size='small'
                sx={{ width: '15vw' }}
                label='Hospital Name *'
                value={this.state.hospitalName}
                onChange={(e) => { this.setState({ hospitalName: e.target.value }) }}
              />
              <Autocomplete
                clearIcon
                size='small'
                sx={{ width: '12vw' }}
                autoComplete='off'
                options={yearList.map((item) => item)}
                value={this.state.selectedYear}
                onChange={(event, newValue) => this.setState({ selectedYear: newValue })}
                renderInput={(params) => <TextField {...params} label='Select Year *' />}
              />

              <TextField
                size='small'
                sx={{ width: '16vw' }}
                label='comments'
                value={this.state.comments}
                onChange={(e) => { this.setState({ comments: e.target.value }) }}
              />
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
              <Button
                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                variant='outlined'
                onClick={() => this.onClearHospitalHistory()}
              >Clear</Button>
              <Button
                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                variant='contained'
                disabled={this.props.is_From_Fo ? !checkuserAccess : enableAdd}
                onClick={() => this.postHospitalHistoryData()}
              >Add</Button>
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} mt={'0.5vw'}>
          <Box component={'div'} border={'1px solid lightgray'}>
            {this.renderRightSide()}
          </Box>
          <Box component={'div'} mt={'.5vw'} height={'44vh'}>
            {this.renderLeftSide()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletePopup ? <DeletePopup
          DeletTitle={`Are you sure you want to delete ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.deleteHospitalHistoryData.bind(this)}
          disable={this.state.removeData}
        /> : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
