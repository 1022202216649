import { Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText } from "../../../../Components/Common Components/CommonComponents";
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { AmountFormat, formatDate } from "../../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from 'luxon';


class WardwiseReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            wardWiseReportData: [],
            wardName: [],
            wardListData: [],
            wardTotalAmount:''
        }
    }

    componentDidMount() {
        this.getWardListData()
        this.getWardWiseReportData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    getWardListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.WARD_CONFIG)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            wardListData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getWardWiseReportData = () => {
        try {
            let wardName = [];
            this.state.wardName.forEach(element => wardName.push(element?.ward_name))
            RestAPIService.getAll(Serviceurls.WARD_WISE_REPORT_GET + `?ward_name=${wardName}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            wardWiseReportData: response.data.data,
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate,
                            wardTotalAmount:response.data.total_amount
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getWardWiseReportPrint = () => {
        try{
            let wardName = [];
            this.state.wardName.forEach(element => wardName.push(element?.ward_name))
            RestAPIService.getAll(Serviceurls.WARD_WISE_REPORT_GET + `?ward_name=${wardName}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&export_type=pdf`)
            .then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }).catch((error) => {
                this.errorMessage(error.response?.data?.message)
            })
        }catch(error){
            this.errorMessage(error.message)
        }
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.wardListData?.map((item) => item)}
                            getOptionLabel={(option) => (option?.ward_name)}
                            value={this.state.wardName}
                            onChange={(event, newValue) => {
                                this.setState({ wardName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('SelectWard')}
                                />
                            )}
                        />
                    </Box>
                    <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                        <Button variant='outlined' size="small"
                            onClick={() => {
                                this.setState({
                                    wardName: [],
                                    fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                    toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                    dateIndex: 1,
                                },
                                    () => this.getWardWiseReportData())
                            }}
                            id="eMed_Config_btn">{t("Clear")}</Button>
                        <Button variant='contained' size="small"
                            onClick={() => {
                                this.getWardWiseReportData()
                                this.setState({ FilterOpen: false })
                            }}
                            id="eMed_Config_btn">{t("Search")}</Button>
                    </Stack>
                </Box>

            </Box>
        )
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
            { label: "To Date", value: formatDate(this.state.FilteredToDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }
    render() {
        this.state.wardWiseReportData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "service_type", headerName: t("WardName"), flex: 0.166,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.service_type?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.service_type} arrow><div>{params?.row?.service_type.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.service_type ? params?.row?.service_type : "-"}</Box>)
            },
            {
                field: "from_date", headerName: t("FromDate"), flex: 0.166,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.from_date?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.from_date} arrow><div>{params?.row?.from_date.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.from_date ? formatDate(params?.row?.from_date) : "-"}</Box>)
            },
            {
                field: "to_date", headerName: t("ToDate"), flex: 0.166,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.from_date?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.to_date} arrow><div>{params?.row?.to_date.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.to_date ? formatDate(params?.row?.to_date) : "-"}</Box>)
            },
            {
                field: "total_amount", headerName: `${t("TotalAmount")} (₹)`, flex: 0.166, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total_amount ? AmountFormat(params?.row?.total_amount).replace("₹", "") : "0"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("TotalAmount"), this.state.wardTotalAmount)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div' display={'flex'} padding={'0.5vw'} width={"7vw"}>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                            <Button className='eMed_usrconf_btn' onClick={() => this.getWardWiseReportPrint()}>
                                <img src={ImagePaths.LabPrintIcon.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.wardWiseReportData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}


export default withTranslation()(WardwiseReport)