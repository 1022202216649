import { Box, Button, Card, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import CommonGridHeader, { CommonPatientCard, CommonPatientDetails, ConsultantPatientCard, DeletePopup } from '../../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import { getCachevalue } from '../../../../../Utility/Services/CacheProviderService'
import { Colors } from '../../../../../Styles/Colors'
import '../ClinicalNotes/DoctorNotes.css';
import { DateTime } from 'luxon';
import Loader from '../../../../../Components/Loader';
import { CheckAccessFunc } from '../../../../../Components/CommonFunctions/CommonFunctions'
import CommonDatePicker from '../../../../../Components/Common Components/CommonDatePicker'


class IPTestReports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ipPatientData: {},
      TestReportsData: [],
      page: 0,
      pageSize: 10,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
    }
  }

  GetTestReports = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_TEST_REPORT + "?patient_id=" + this.state.ipPatientData?.patient_id + "&from_ip=true" + `&from_date=${this.state.fromDate}&to_date=${this.state.toDate}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              TestReportsData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    }, () => {
      setTimeout(() => {
        this.setState({ isErrorMsg: false })
      }, 2000);
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    }, () => {
      setTimeout(() => {
        this.setState({ successMsg: false })
      }, 1500);
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key = false) => {
    this.setState({
      isLoader: key
    })
  }

  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    }, () => {

    })
  }

  componentDidMount() {
    let ipPatientData = getCachevalue("IppatientData")
    let patientData = JSON.parse(ipPatientData)
    this.setState({
      ipPatientData: patientData
    }, () => {
      this.GetTestReports()
    })
  }

  getFoLabTestReportPrint = (resultSummaryId, labId) => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + `?result_summary_id=${resultSummaryId}&laboratory_id=${labId}`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message);
    }
  }

  renderLeftContent = () => {
    this.state.TestReportsData.forEach((element, index) => element.sno = index + 1)
    const { t } = this.props
    const columns = [
      {
        field: "report_date", headerName: "Date", flex: 0.15, headerAlign: "center", align: "center",
        renderCell: (params) => {
          return (
            <Box component={'div'}>{params?.row?.report_date ? params?.row?.report_date.length > 10 ?
              <Tooltip arrow title={params?.row?.report_date} placement='top'>
                <div>{params?.row?.report_date.slice(0, 10) + '...'}</div>
              </Tooltip> :
              params?.row?.report_date : "-"}</Box>
          )
        }
      },
      {
        field: "report_type", headerName: "Reports Type", flex: 0.2, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.report_type ? params?.row?.report_type.length > 10 ?
          <Tooltip arrow title={params?.row?.report_type} placement='top'><div>{params?.row?.report_type.slice(0, 10) + '...'}</div></Tooltip> : params?.row?.report_type : "-"}</Box>)
      },
      {
        field: "report_name", headerName: "Test Details", flex: 0.43,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.report_name ? params?.row?.report_name.length > 30 ?
            <Tooltip arrow placement='top' title={params?.row?.report_name}>
              <div>{params?.row?.report_name.slice(0, 30) + '...'}</div></Tooltip> : params?.row?.report_name : "-"}</Box>)
      },
      {
        field: "status", headerName: "Status", flex: 0.29, headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'center'} paddingRight={'0.5vw'}>
          <Typography fontSize={'0.9vw'} fontWeight={600}>{row?.status ? row?.status : ""}</Typography>
          <Button sx={{ display: ((row?.status === 'Result Delivered') || (row?.status === 'Result Completed')) ? 'block' : 'none', textTransform: 'capitalize' }} className='Common_Btn_Min_Width'
            onClick={() => { this.getFoLabTestReportPrint(row?.id, row?.laboratory_id) }}
          >View</Button>
        </Box>)
      }
    ]
    return (
      <Box component={'div'} className='eMed_insulin_left_card'>
        <DataGrid
          className='eMed_nur_notes_grid'
          rows={this.state.TestReportsData}
          page={this.state.page}
          pageSize={this.state.pageSize}
          columns={columns}
          headerHeight={40}
          getRowId={(row) => row?.sno}
          disableSelectionOnClick
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                created_by: false
              }
            }
          }}
          components={{
            Toolbar: CommonGridHeader.CommonToolBar,
            NoRowsOverlay: () => {
              return (
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "40vh" }}>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }
          }}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
        />
      </Box>
    )
  }


  ClearData() {
    this.setState({
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
    })
  }

  renderRightContent = () => {
    const { t } = this.props
    return (
      <Stack component={"div"}>
        <Paper>
          <Box component={'div'} height={'58vh'}>
            <Typography className='eMed_refdoc_title'>{t("Filter")}</Typography>
            <Box sx={{ width: "28vw", margin: '2vw' }}>
              <CommonDatePicker
                FromDate={this.state.fromDate}
                ToDate={this.state.toDate}
                DateIndex={this.state.dateIndex}
                SelectedDates={this.FilterDate.bind(this)}
                HideAllMenu={true} />
            </Box>
          </Box>
          <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
            <Button emed_tid={"IP_test_report_clear"} variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
            <Button
              emed_tid={"IP_test_report_search"}
              disabled={!CheckAccessFunc("front_office", "In Patients", "Reports", "Test Report", "SubTab")?.editAccess}
              variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.GetTestReports() }}>{t("Search")}</Button>
          </Stack>
        </Paper>
      </Stack>
    )
  }

  render() {
    const { t } = this.props
    var { ipPatientData } = this.state
    let attender = {
      lineOne: t("AttenderName"),
      lineTwo: `${ipPatientData.attender_name ? ipPatientData.attender_name : '-'}`,
      lineFour: t('ContactNumber'),
      LineFive: `${ipPatientData.attender_mobile ? ipPatientData.attender_mobile : '-'}`
    }
    let consultant = {
      lineOne: t("ConsultantName"),
      lineTwo: `${ipPatientData.doctor_name ? ipPatientData.doctor_name : '-'}`,
    }
    return (
      <Box sx={{ backgroundColor: Colors.ComponentBgColor }}>
        <Box component={'div'} marginLeft={'0.5vw'} className='eMed_patient_header'>
          <Box display={'flex'} alignItems='center'>
            <Box className='eMed_Patient_Details_Long eMed_patient_box'>
              <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true} />
            </Box>
            <CommonPatientCard details={attender} showDetailed={true} />
            <ConsultantPatientCard name={consultant} />
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'row'} marginTop={"0.5vw"}>
          <Card elevation={3} className='eMed_DocNotes_Left_Main'>
            {this.renderLeftContent()}
          </Card>
          <Paper elevation={3} className='eMed_DocNotes_Right_Main' sx={{ height: "63.8vh" }}>
            {this.renderRightContent()}
          </Paper>
        </Box>
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletePopup ? <DeletePopup
          DeletTitle={`${t("deleteMsg")} ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.handleDelete.bind(this)}
        /> : null}
      </Box>
    )
  }
}

export default withTranslation()(IPTestReports)