import { Autocomplete, Box, Button, Chip, FormControl, IconButton, InputAdornment, InputLabel,
   MenuItem, Select, Stack, TextField, Tooltip, Typography, Tabs, Tab,  } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Colors } from '../../../../Styles/Colors';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';

class DocTestRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clinicId: localGetItem('SelectedDoctorClinicId'),
      labTestName: [],
      labGroupName: [],
      specialData: [],
      specialTestList: [],
      selectedLabName: [],
      selectedTestName: [],
      selectedTestGroup: [],
      searchSetName: "",
      tabValue: "one",
      radiologyList: [],
      selectedRadName: null,
      radSrvTypList: [],
      radSrvNameList: [],
      radSrvType: "",
      radSrvName: []
    }
  }

  componentDidMount() {
    this.getLabList()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  ClearData() {
    this.setState({
      specialData: [],
      specialTestList: [],
      selectedTestName: [],
      selectedTestGroup: [],
      searchSetName: "",
      radSrvType: "",
      radSrvName: []
    })
  }


  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  getLabList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_LAB_LIST + `?clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              labList: response.data.data?.laboratory_data,
              selectedLabName: response.data.data?.laboratory_data[0]?.laboratory_id
            }, () => {
              this.getTestNameList()
              this.getTestGroupList()
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getTestNameList = () => {
    try {
      this.setState({ labNameLoad: true })
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_TEST_NAME_GET + `?laboratory_id=${this.state.selectedLabName}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              labTestName: response.data.data,
              labNameLoad: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ labNameLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ labNameLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ labNameLoad: false })
      this.errorMessage(error.message)
    }
  }

  getTestGroupList = () => {
    try {
      this.setState({ labGroupLoad: true })
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_TEST_GROUP_GET + `?laboratory_id=${this.state.selectedLabName}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              labGroupName: response.data.data,
              labGroupLoad: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ labGroupLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ labGroupLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ labGroupLoad: false })
      this.errorMessage(error.message)
    }
  }

  getSpecialTestList = () => {
    try {
      this.setState({ specialLoad: true })
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_SPECIAL_TEST_SEARCH + `?search=${this.state.searchSetName}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              specialTestList: response.data.data,
              specialLoad: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ specialLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ specialLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ specialLoad: false })
      this.errorMessage(error.message)
    }
  }

  getRadiologyList = () => {
    try {
      RestAPIService.getAll(`${Serviceurls.GET_RADIOLOGY_LIST}?clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              radiologyList: response.data.data,
              selectedRadName: response.data.data[0]?.radiology_id
            }, () => {
              this.getServiceNameList()
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getServiceNameList = () => {
    try {
      this.setState({ labNameLoad: true })
      RestAPIService.getAll(`${Serviceurls.RAD_SERVICE_CONFIG}?clinic_id=${this.state.clinicId}&radiology_id=${this.state.selectedRadName}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              radSrvTypList: response.data.data,
              labNameLoad: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ labNameLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ labNameLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ labNameLoad: false })
      this.errorMessage(error.message)
    }
  }

  checkValidate = () => {
    try {
      let validate = null
      let states = this.state
      if (states.selectedLabName === '') {
        validate = 'Select Lab Name'
      } else if ((states.selectedTestName?.length > 0) || (states.selectedTestGroup?.length > 0) || (states.specialData?.length > 0)) {
        validate = null
      } else {
        validate = 'Select atleast one test'
      }
      return validate;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postTestData = () => {
    try {
      let labTests = []; let labSets = []; let specialTests = [];
      this.state.selectedTestName?.forEach(element => {
        labTests?.push({
          lab_tests_id: element?.id,
          lab_test_name: element?.test_name
        })
      })
      this.state.selectedTestGroup?.forEach(element => {
        labSets?.push({
          lab_set_id: element?.id,
          lab_set_name: element?.name
        })
      })
      this.state.specialData?.forEach(element => {
        specialTests?.push({
          special_test_id: element?.id ? element?.id : null,
          special_test_name: element?.name ? element?.name : element
        })
      })
      let data = {
        laboratory_id: this.state.selectedLabName,
        patient_id: this.props?.PatientData?.patient_id,
        appointment_id: this.props?.PatientData?.appointment_id,
        lab_sets: labSets,
        lab_tests: labTests,
        special_tests: specialTests
      }
      let validate = this.checkValidate()
      if (validate === null) {
        this.setState({ postLoading: true })
        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_TEST_POST)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.successMessage(response?.data?.message)
              this.ClearData()
              this.props.CallgetApi('test')
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.setState({ postLoading: false })
              this.errorMessage(error.response.data.message)
            } else {
              this.setState({ postLoading: false })
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage(validate)
      }
    } catch (error) {
      this.setState({ postLoading: false })
      this.errorMessage(error.message)
    }
  }

  checkValidateRadFields = () => {
    try {
      let validate = null
      let states = this.state
      if (!states.selectedRadName) {
        validate = 'Select Radiology Name'
      } else if (((states.radSrvType) && (states.radSrvName?.length > 0)) || (states.specialData?.length > 0)) {
        validate = null
      } else if (!states.radSrvType) {
        validate = 'Select Service Type'
      } else if (states.radSrvName?.length === 0) {
        validate = 'Select Service Name'
      } else if (states.specialData?.length === 0) {
        validate = 'Select atleast one service'
      }
      return validate;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postRadData = () => {
    try {
      let serviceList = [];
      this.state.radSrvName?.forEach(element => {
        serviceList?.push({
          is_special_test: false,
          special_test_name: "",
          special_test_id: null,
          transmitted_radiology_id: this.state.selectedRadName,
          service_id: element?.id,
          service_name: element?.service_name
        })
      })
      this.state.specialData?.forEach(element => {
        serviceList?.push({
          is_special_test: true,
          special_test_name: element?.name ? element?.name : element,
          special_test_id: element?.id ? element?.id : null,
          transmitted_radiology_id: this.state.selectedRadName,
          service_id: null,
          service_name: ""
        })
      })
      let data = {
        patient_id: this.props?.PatientData?.patient_id,
        appointment_id: this.props?.PatientData?.appointment_id,
        service_list: [...serviceList]
      }
      let validate = this.checkValidateRadFields()
      if (validate === null) {
        this.setState({ postLoading: true })
        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_RADIOLOGY_POST)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.successMessage(response?.data?.message)
              this.ClearData()
              this.setState({ tabValue: "one" })
              this.props.CallgetApi('test')
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.setState({ postLoading: false })
              this.errorMessage(error.response.data.message)
            } else {
              this.setState({ postLoading: false })
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage(validate)
      }
    } catch (error) {
      this.setState({ postLoading: false })
      this.errorMessage(error.message)
    }
  }

  checkValidation = () => {
    try {
      let message = null;
      if (CommonValidation.removeSpace(this.state.searchSetName) !== '') {
        this.state.specialData?.forEach(element => {
          if ((element?.name === this.state.searchSetName?.name && element?.name) || (element === this.state.searchSetName && element)) {
            message = 'Special test already exists'
          }
        })
      } else {
        message = 'Select or Enter Special Test'
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  addSpecialTest = () => {
    try {
      let validation = this.checkValidation()
      if (validation === null) {
        let data = CommonValidation.updateOrInsertList(this.state.specialData, CommonValidation.removeSpace(this.state.searchSetName))
        this.setState({
          specialData: data
        }, () => {
          this.setState({ searchSetName: '', specialTestList: [] })
        })
      } else {
        this.errorMessage(validation)
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderSpecialTest = () => {
    return (<Box component={'div'} >
      <Box component={'div'} mt={'0.5vw'}>
        <Typography fontSize={'0.8vw'} fontWeight={600} color={Colors.SecondaryText}>Special Test</Typography>
      </Box>
      <Box component={'div'} display={'flex'} mt={'0.5vw'}>
        <Autocomplete
          clearIcon
          size='small'
          freeSolo
          sx={{ width: '25vw' }}
          value={this.state.searchSetName}
          options={this.state.specialTestList}
          getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.name}
          onChange={(e, newValue) => {
            this.setState({
              searchSetName: newValue
            }, () => {
              if (CommonValidation.removeSpace(this.state.searchSetName?.name)?.length > 0) {
                this.addSpecialTest()
              } else {
                this.setState({ specialTestList: [] })
              }
            })
          }}
          renderInput={(params) => <TextField {...params}
            onChange={(e) => {
              if (CommonValidation.removeSpace(e.target.value)?.length > 0) {
                this.setState({
                  searchSetName: e.target.value
                }, () => this.getSpecialTestList())
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (CommonValidation.removeSpace(e.target.value)?.length > 0) {
                  this.addSpecialTest()
                } else {
                  this.errorMessage('Search or Enter Special Test')
                }
              }
            }}
            label={'Search or Enter Special Test'} />}
        />
      </Box>
      <Box component={'div'} mr={'1vw'} mt={'1vw'} overflow={'scroll'} maxHeight={'15vw'}>
        {
          this.state.specialData?.map((item, index) => (
            <Chip
              className='eMed_chip'
              size='small'
              label={((item.name?.length > 10) || (item?.length > 10)) ? <Tooltip placement='top' title={item?.name ? item?.name : item}><Typography fontSize={'0.8vw'} textTransform={'capitalize'}>{item?.name ? (item?.name?.slice(0, 10) + '...') : (item?.slice(0, 10) + '...')}</Typography></Tooltip> : <Typography fontSize={'0.8vw'} textTransform={'capitalize'}>{item?.name ? item?.name : item}</Typography>}
              // variant={((item.name === this.state.selectedSetName) || (item === this.state.selectedSetName)) ? 'contained' : 'outlined'}
              variant='outlined'
              key={index}
              // onClick={() => { this.setState({ selectedSetName: item?.name ? item?.name : item, selectedSpecial: item }) }}
              color={'primary'}
              clickable
              onDelete={() => {
                this.state.specialData?.splice(index, 1)
                this.setState({
                  specialData: this.state.specialData
                })
              }}
            />
          ))}
      </Box>
    </Box>)
  }

  renderLabFields = () => {
    return (<Box className="eMed_Other_DocNotes_testrgt">
      <Box component={'div'} paddingTop={"1vw"}>
        <FormControl size='small'>
          <InputLabel>Select Laboratory</InputLabel>
          <Select
            sx={{ width: '25vw', ml: '0.5vw', mb: '0.5vw' }}
            label='Select Laboratory'
            value={this.state.selectedLabName}
            onChange={(e) => this.setState({ selectedLabName: e.target.value }, () => {
              this.getTestNameList()
              this.getTestGroupList()
            })}
          >
            {this.state.labList?.map((item, index) => (
              <MenuItem key={index} value={item?.laboratory_id}>{item?.laboratory__laboratory_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box component={'div'}>
        <Box component={'div'}>
          <Typography fontSize={'0.8vw'} fontWeight={600} color={Colors.SecondaryText}>Test Name</Typography>
        </Box>
        <Box component={'div'} mt={'0.5vw'}>
          <Autocomplete
            size='small'
            clearIcon
            sx={{ width: '25vw' }}
            multiple
            limitTags={3}
            options={this.state.labTestName}
            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.test_name}
            value={this.state.selectedTestName}
            onChange={(e, value) => {
              this.setState({
                selectedTestName: value
              })
            }}
            renderInput={(params) => <TextField {...params} label='Select Test Name' />}
          />
        </Box>
      </Box>
      <Box component={'div'} mt={'0.5vw'}>
        <Box component={'div'}>
          <Typography fontSize={'0.8vw'} fontWeight={600} color={Colors.SecondaryText}>Test Group</Typography>
        </Box>
        <Box component={'div'} mt={'0.5vw'}>
          <Autocomplete
            size='small'
            clearIcon
            sx={{ width: '25vw' }}
            multiple
            limitTags={3}
            options={this.state.labGroupName}
            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.name}
            value={this.state.selectedTestGroup}
            onChange={(e, value) => {
              this.setState({
                selectedTestGroup: value
              })
            }}
            renderInput={(params) => <TextField {...params} label='Select Group Name' />}
          />
        </Box>
      </Box>
      {this.renderSpecialTest()}
    </Box>)
  }

  renderRadFields = () => {
    return (<Box className="eMed_Other_DocNotes_testrgt">
      <Box component={'div'} paddingTop={"1vw"}>
        <FormControl size='small'>
          <InputLabel>Select Radiology</InputLabel>
          <Select
            sx={{ width: '25vw', ml: '0.5vw', mb: '0.5vw' }}
            label='Select Radiology'
            value={this.state.selectedRadName}
            onChange={(e) => this.setState({ selectedRadName: e.target.value }, () => {
              this.getServiceNameList()
            })}
          >
            {this.state.radiologyList?.map((item, index) => (
              <MenuItem key={index} value={item?.radiology_id}>{item?.radiology_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box component={'div'}>
        <Box component={'div'}>
          <Typography fontSize={'0.8vw'} fontWeight={600} color={Colors.SecondaryText}>Service Type</Typography>
        </Box>
        <Box component={'div'} mt={'0.5vw'}>
          <Autocomplete
            size='small'
            clearIcon
            sx={{ width: '25vw' }}
            options={this.state.radSrvTypList}
            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.service_type}
            value={this.state.radSrvType || null}
            onChange={(e, value) => {
              const radSrvType = value?.service_type || "";
              const radSrvNameList = value?.data || [];
              this.setState({
                radSrvType,
                radSrvNameList,
                radSrvName: []
              });
            }}
            renderInput={(params) => <TextField {...params} label='Select Service Type' />}
          />
        </Box>
      </Box>
      <Box component={'div'} mt={'0.5vw'}>
        <Box component={'div'}>
          <Typography fontSize={'0.8vw'} fontWeight={600} color={Colors.SecondaryText}>Service Name</Typography>
        </Box>
        <Box component={'div'} mt={'0.5vw'}>
          <Autocomplete
            size='small'
            clearIcon
            sx={{ width: '25vw' }}
            multiple
            limitTags={3}
            disabled={this.state.radSrvNameList?.length === 0}
            options={this.state.radSrvNameList}
            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.service_name}
            value={this.state.radSrvName}
            onChange={(e, value) => {
              this.setState({
                radSrvName: value
              })
            }}
            renderInput={(params) => <TextField {...params} label='Select Service Name' />}
          />
        </Box>
      </Box>
      {this.renderSpecialTest()}
    </Box>)
  }

  render() {
    const { t } = this.props
    let states = this.state
    return (
      <Box>
        <Typography className='eMed_refdoc_title'>{t("Test")}</Typography>
        <Tabs
          sx={{ marginBottom: "0.5dvw" }}
          value={states.tabValue}
          onChange={(e, newValue) => this.setState({
            tabValue: newValue,
            specialData: [],
            searchSetName: '',
            specialTestList: []
          }, () => {
            if (newValue === "two") {
              this.getRadiologyList();
            }
          })}
        >
          <Tab value="one" label={"Laboratory"} className='eMed_tab_header' />
          <Tab value="two" label={"Radiology"} className='eMed_tab_header' />
        </Tabs>
        {states.tabValue === "two" ? this.renderRadFields() : this.renderLabFields()}
        <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
          <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
          <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { states.tabValue === "two" ? this.postRadData() : this.postTestData() }}>{t("Add")}</Button>
        </Stack>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(DocTestRight)
