import React, { Component } from 'react';
import {
    Box, Paper, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Button, Stack, Typography, Autocomplete, Grid, Tooltip, IconButton, Checkbox
} from "@mui/material";
import "./PharmaPurchase.css";
import { withTranslation } from 'react-i18next';
import { Colors } from "../../../Styles/Colors";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DateTime } from "luxon";
import { ImagePaths } from '../../../Utility/ImagePaths';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import Loader from '../../../Components/Loader';
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CurrencySymbol } from '../../../Utility/Constants';

let userAccess = null;

class PharmaPOReturn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendor_name: "",
            vendor_id: "",
            delivery_date: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
            billNo: "",
            billDate: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
            purchase_order_date: null,
            cgstAmt: "0.00",
            sgstAmt: "0.00",
            billAmt: "",
            fullBillAmt: "",
            purchase_order_number: "",
            lftTblData: [],
            vendor_list: [],
            selectedData: {},
            purchase_order_id: null,
            purchase_status: "",
            purchase_from: "directPo",
            previous_vendor_bills: [],
            bill_id: null,
            open: false,
            open1: false,
            stockData: {},
            loggedInuserInfo: JSON.parse(localGetItem("loggedInUserInfo")),
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            returnQty: '',
            is_Return: false,
            return_Data: [],
            purchase_return_number: '',
            purchase_grn_id:null
        }
    }

    componentDidMount() {
        userAccess = CheckAccessFunc(this.state.loggedInuserInfo?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy", "Purchase", 'Purchase Order', null, "Tab");
        this._getVendorList();
    }

    componentDidUpdate(prevProps) {
        let states = this.state;
        let history = prevProps.history?.location?.state;
        let purchase_order_id = history?.purchase_order_id;
        let purchase_grn_id = history?.vendor_bill_id
        let isUser = !userAccess?.editAccess;

        if (purchase_order_id !== undefined && purchase_order_id !== states.purchase_order_id) {
            this.setState({
                purchase_order_id: purchase_order_id ? purchase_order_id : null,
                purchase_status: history?.purchase_status ? history?.purchase_status : "",
                purchase_from: history?.from ? history?.from : "",
                return_Data: history?.ReturnData,
                purchase_grn_id:purchase_grn_id ? purchase_grn_id : null
            }, () => {
                this._getPurchaseDetails();
            })
        }else if(history?.ReturnData && history?.ReturnData?.po_return_number !== states.purchase_return_number){
            let data = history?.ReturnData

            this.setState({
                purchase_order_date : new Date(data.purchase_order_date),
                purchase_order_id : data.purchase_order_id,
                purchase_order_number : data.purchase_order_number,
                vendor_id : data.vendor_id,
                vendor_name : data.vendor_name,
                purchase_return_number : data.po_return_number,
                cgstAmt : data.cgst_amount,
                sgstAmt : data.sgst_amount,
                billAmt : data.net_amount,
                returnTbl : data.po_return_line,
                purchase_from: history?.from ? history?.from : "",
            })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    _getVendorList = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.PURCHASE_VENDOR_LIST}?from=purchase_order`)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            vendor_list: response.data.data,
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data.status == "fail") {
                        this.errorMessage(error.response?.data?.message);
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    _getPurchaseDetails = () => {
        try {
            let params = ''
            if(this.state.purchase_grn_id){
                params = `?purchase_order_id=${this.state.purchase_order_id}&vendor_bill_id=${this.state.purchase_grn_id}`
            }else{
                params = `?purchase_order_id=${this.state.purchase_order_id}`
            }
            this.LoaderFunction(true);
            RestAPIService.getAll(`${Serviceurls.PURCHASE_PO_DETAILS_GET}${params}`)
                .then(response => {
                    if (response.data?.status == "success") {
                        this.LoaderFunction(false);
                        this.assignPoDetails(response.data?.data);
                    }
                })
                .catch(error => {
                    if (error.response?.data?.status === "fail") {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response?.data?.message);
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    getNumberOfDaysBetweenDates(date1, date2) {
        const Date1 = new Date(date1);
        const Date2 = new Date(date2);
        const timeDiffMilliseconds = Math.abs(Date2 - Date1);
        const days = Math.ceil(timeDiffMilliseconds / (24 * 60 * 60 * 1000));
        return days;
    }

    assignPoDetails = (data) => {
        let states = this.state;
        
        let latest_vendor_bill = Object.keys(data.latest_vendor_bill).length > 0 ? data.latest_vendor_bill : {};
        let previous_vendor_bills = data.previous_vendor_bills?.length > 0 ? data.previous_vendor_bills : [];

        if (states.purchase_status !== "Completed") {
            let is_allStock_sold = !(data?.stock_items?.every(item => item.is_stocks_sold === true));

            states.lftTblData = [...data.stock_items];

        } else if (states.purchase_status === "Placed Order" || states.purchase_status === "Partially Delivered") {

            let partialArr = (data.po_line_items).length > 0 || (data.stock_items).length > 0 ? [...data.po_line_items, ...data.stock_items] : [];
            states.lftTblData = partialArr.sort((a, b) => a.serial_no - b.serial_no);
        } else if (states.purchase_status === "Returned") {
            states.delivery_date = states.return_Data.po_return_date
            states.billAmt = states.return_Data.net_amount
            states.fullBillAmt = states.return_Data.net_amount
            states.cgstAmt = states.return_Data.cgst_amount
            states.sgstAmt = states.return_Data.sgst_amount
            states.returnTbl = states.return_Data.po_return_line
            states.purchase_return_number = states.return_Data.po_return_number

        }

        states.billDate = latest_vendor_bill?.bill_date;
        states.delivery_date = states.purchase_from === "PurchaseReturn" ? latest_vendor_bill?.delivery_date : states.return_Data.po_return_date;
        states.billNo = latest_vendor_bill?.bill_no;
        states.bill_id = latest_vendor_bill?.bill_id;
        states.purchase_order_date = new Date(data.purchase_order_date);
        states.purchase_order_id = data.purchase_order_id;
        states.purchase_order_number = data.purchase_order_number;
        states.vendor_id = data.vendor_id;
        states.vendor_name = data.vendor_name;
        states.previous_vendor_bills = previous_vendor_bills;
        this.setState({
            states
        })
    }



    handleClearData = () => {
        let todate = new Date();
        this.setState({
            vendor_name: "",
            vendor_id: "",
            delivery_date: DateTime.fromJSDate(todate).toFormat("yyyy-MM-dd"),
            billNo: "",
            billDate: DateTime.fromJSDate(todate).toFormat("yyyy-MM-dd"),
            purchase_order_date: DateTime.fromJSDate(todate).toFormat("yyyy-MM-dd"),
            cgstAmt: "0.00",
            sgstAmt: "0.00",
            billAmt: "",
            fullBillAmt: '',
            purchase_order_number: "",
            lftTblData: [],
            vendor_list: [],
            selectedData: {},
            purchase_order_id: null,
            purchase_status: "",
            previous_vendor_bills: [],
            latest_vendor_bill: {},
            open: false,
            open1: false,

        })
    }


    handleOpen = (statekey) => {
        let states = this.state;
        states[statekey] = true;
        this.setState({
            states
        })
    }

    renderAutoSlt = (label, stateKey1, stateKey2, showkey, valueKey, Data = [], width) => {
        let states = this.state;
        const { t } = this.props;
        return (
            <div className="eMed_pharmadrctpo_txt">
                <Autocomplete
                    size='small'
                    clearIcon={false}
                    readOnly={true}
                    sx={{ width: width ? width : '30.80vw' }}
                    options={Data}
                    getOptionLabel={(option) => (typeof (option[showkey]) === "string" ? option[showkey] : option)}
                    value={states[stateKey1] ? states[stateKey1] : ""}
                    ListboxProps={{ style: { maxHeight: 300 } }}
                    onChange={(event, newValue) => {
                        states[stateKey1] = newValue[showkey] // value to be shown
                        states[stateKey2] = newValue[valueKey] // actual value
                        this.setState({ states })
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t(label) + " *"}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                // type: 'search',
                                emed_tid: label,
                            }}
                        />
                    )}
                />
            </div>
        )
    }

    renderDatePicker = (label, statekey, openMenu, width) => {
        const { t } = this.props;
        let states = this.state;
        return (
            <div className="eMed_pharmadrctpo_txt">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        readOnly
                        open={states[openMenu]}
                        onOpen={() => this.handleOpen(openMenu)}
                        onClose={() => {
                            states[openMenu] = false;
                            this.setState(states)
                        }}
                        inputFormat='dd-MM-yyyy'
                        label={t(label) + " *"}
                        name={statekey}
                        value={states[statekey] ? states[statekey] : null}
                        autoComplete="off"
                        maxDate={DateTime.now()}
                        onChange={(value) => {
                            states[statekey] = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd");
                            this.setState({
                                states
                            })
                        }}
                        renderInput={(params) => <TextField
                            size="small"
                            onKeyDown={(e) => { e.preventDefault() }}
                            sx={{ width: width ? width : "9.8vw" }}
                            autoComplete="off"
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                emed_tid: label,
                            }}
                            onClick={(e) => this.handleOpen(openMenu)} />}
                    />
                </LocalizationProvider>
            </div>
        )
    }

    renderText(label, statekey, Noshow = false, mandatory = false, width) {
        const { t } = this.props;
        let states = this.state;
        return (
            <div className="eMed_pharmadrctpo_txt">
                <TextField
                    label={t(label)}
                    required={mandatory}
                    sx={{ width: width ? width : "10vw" }}
                    size="small"
                    autoComplete="off"
                    InputProps={{
                        readOnly: true,
                    }}
                    name={statekey}
                    value={states[statekey] ? states[statekey] : statekey === "billNo" ? "" : "0.00"}
                    inputProps={{ emed_tid: label }}
                    onChange={this.updateState}
                />
            </div>
        )
    }

    updateState = (event) => {
        let states = this.state;
        let { name, value } = event.target;
        states[name] = value
        if (name === "billNo") {
            states.billNo = value;
        }
        this.setState({
            states
        })
    }

    getAbsoluteFix = (num) => {
        let absoluteValue = Math.abs(num);
        let formattedValue = absoluteValue.toFixed(2);
        return formattedValue;
    }

    renderReturnQty = (event, item, index, POnum) => {
        let { value } = event.target;
        let numval = CommonValidation.numberOnly(value);
        let qtycheck = POnum < (item.stock_in_quantity - item?.return_quantity) ? POnum : (item.stock_in_quantity - item?.return_quantity);
        let poQuantity = item?.pack_of_drugs * item?.sales_unit
        let updatedData = [...this.state.lftTblData];

        if ((numval || value === '') && (value <= qtycheck)) {
            let updatedItem = { ...updatedData[index] };
            updatedItem.retunQty = value;

            let gst_percentage = item.cgst_percentage ? item.cgst_percentage * 2 : 0;
            // let QtyPerUnit = item.purchase_quantity / item.sales_unit;
            let freeQty = (item?.free_quantity && item?.pack_of_drugs) ? (+item?.free_quantity * +item?.pack_of_drugs) : item?.free_quantity;
            let QtyPerUnit = item.purchase_quantity + freeQty;
            // let singleQtyPrice = item.buying_price / QtyPerUnit;
            let singleQtyPrice = (item.buying_price / QtyPerUnit);
            let disAmt = item?.discount_amount + item?.sales_discount_amount
            let POValue =  value !== '' ? +value * item?.purchase_per_Qty : 0 ;
            let GSTAmount = (POValue / 100) * gst_percentage;
            let SplitGSTamt = GSTAmount / 2;
            let NetAmount = POValue + GSTAmount;

            updatedItem.POValue = POValue;
            updatedItem.NetAmount = NetAmount;
            updatedItem.SplitGSTamt = SplitGSTamt

            updatedData[index] = updatedItem;
            // let sum = 0;
            // let sum1 = 0
            // let rowChecked = updatedData.filter((data) => data.rowCheck === true)
            // for (let i = 0; i < rowChecked.length; i++) {
            //     sum += updatedData[i].NetAmount;
            //     sum1 += updatedData[i].SplitGSTamt;

            // }
            this.setState({
                lftTblData: updatedData,
                // billAmt: sum ? Math.round(sum) : NetAmount ? NetAmount : 0,
                // fullBillAmt: sum ? this.getAbsoluteFix(sum) : NetAmount ? NetAmount.toFixed(2) : 0,
                // cgstAmt: sum1 ? this.getAbsoluteFix(sum1) : SplitGSTamt ? this.getAbsoluteFix(SplitGSTamt) : 0,
                // sgstAmt: sum1 ? this.getAbsoluteFix(sum1) : SplitGSTamt ? this.getAbsoluteFix(SplitGSTamt) : 0,
                is_Return: true,
            },()=>{
                let sum = 0;let sum1 = 0;
                this.state.lftTblData?.forEach(element => {
                    if(element.rowCheck){
                        sum = sum + element.NetAmount
                        sum1 = sum1 + element.SplitGSTamt
                    }
                })
                this.setState({
                    billAmt: sum ? Math.round(sum) : NetAmount ? NetAmount : 0,
                    fullBillAmt: sum ? this.getAbsoluteFix(sum) : NetAmount ? NetAmount.toFixed(2) : 0,
                    cgstAmt: sum1 ? this.getAbsoluteFix(sum1) : SplitGSTamt ? this.getAbsoluteFix(SplitGSTamt) : 0,
                    sgstAmt: sum1 ? this.getAbsoluteFix(sum1) : SplitGSTamt ? this.getAbsoluteFix(SplitGSTamt) : 0,
                })
            });
        }
    }

    postReturnPO = () => {
        try {
            var states = this.state
            let list = []
            let selectedRow = states.lftTblData.filter((data) => data.rowCheck === true)
            selectedRow.map((data) => {
                let QtyPerUnit = data.purchase_quantity / data.sales_unit;
                let pur_rate_per_qty = data.buying_price / QtyPerUnit;
                list.push({
                    "drug_id": data.drug_id,
                    "return_quantity": +data.retunQty,
                    "purchase_rate_per_qty": +data?.purchase_per_Qty,
                    "net_amount": data.NetAmount,
                    "cgst_amount": data.SplitGSTamt ? +this.getAbsoluteFix(data.SplitGSTamt) : 0,
                    "sgst_amount": data.SplitGSTamt ? +this.getAbsoluteFix(data.SplitGSTamt) : 0,
                    "batch_no": data.batch_no,
                    "stock_id": data.stock_id
                })
            })
            let date = new Date()
            let data = {
                "vendor_id": states.vendor_id,
                "purchase_order_id": states.purchase_order_id,
                "po_return_date": DateTime.fromJSDate(date).toFormat("yyyy-MM-dd"),
                "net_amount": +states.billAmt,
                "bill_amount": +states.fullBillAmt,
                "cgst_amount": +states.cgstAmt,
                "sgst_amount": +states.sgstAmt,
                "line_items": list,
                "bill_no": states.billNo,
            }
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.PO_RETURN)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.setState({
                            is_Return: false
                        })
                        this.successMessage(response.data.message)
                        setTimeout(() => {
                            this.props.history.push('/PharmacyPurchase/PurchaseOrder')
                        }, 1000);
                    }
                }).catch((e) => {
                    if (e?.response?.data?.message) {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    render() {
        const { t } = this.props;
        let { lftTblData, returnTbl } = this.state
        let states = this.state;
        var allRowCheckTrue = states.purchase_from === "PurchaseReturn" ? lftTblData.every((data) => data.rowCheck === true) : false
        let stockAmt = 0;
        lftTblData?.forEach(element => {
            stockAmt = stockAmt + (element.stock_in_quantity - element.return_quantity)
        })
        return (
            <Box paddingRight={'0.5vw'}>
                <Box component={'div'} height={'5.5vh'} display={'flex'} alignItems={'center'} sx={{ backgroundColor: 'white' }}>
                    <IconButton onClick={() => {
                        states.purchase_from === "PurchaseReturn" ?
                            this.props.history.push({ pathname: '/PharmacyPurchase/PurchaseOrder' }) 
                            :
                            states.purchase_from === "purchase_rejection" ? 
                            this.props.history.push({ pathname: '/PharmacyPurchase/PurchaseRejection'})
                            :
                            this.props.history.push({ pathname: '/PharmacyPurchase/PurchaseReturn' })
                    }}>
                        <ChevronLeftIcon color='primary' />
                    </IconButton>
                    <Typography color={Colors.SecondaryText} fontWeight={600}>{states.purchase_from === "purchase_rejection" ? "Purchase Rejection" :"Return PO"}</Typography>
                </Box>
                <Grid container spacing={1} id="eMed_pharmadrctpo_mainDiv" sx={{ borderTop: '1px solid lightgray' }}>
                    <Grid item xs={7.6}>
                        <div id="eMed_pharmadrctpo_rhtDiv">
                            <Paper id="eMed_pharmadrctpo_paper1" elevation={1} >
                                <Stack direction="row" justifyContent={"flex-start"} alignItems={"center"} spacing={1} sx={{ height: "7vh" }}>
                                    {this.renderAutoSlt("VendorName", "vendor_name", "vendor_id", "name", "vendor_id", states.vendor_list, states.purchase_order_number ? "29vw" : "35vw")}
                                    {states.purchase_order_number && states.purchase_from !== "directPo" ? this.renderText("Purchase No", "purchase_order_number", true, false, "9vw") : null}
                                    {states.purchase_from === "PurchaseReturn" ? this.renderDatePicker("DeliveryDate", "delivery_date", "open", "") : states.purchase_from === "purchase_rejection" ? this.renderText("PO Rejection No", "purchase_return_number", true, false, "9vw") : this.renderText("Return PO No", "purchase_return_number", true, false, "9vw")}
                                </Stack>
                                <div className="eMed_pharmadrctpo_subDiv">
                                    {states.purchase_from === "PurchaseReturn" ? this.renderText("BillNo", "billNo", true) : null}
                                    {states.purchase_from === "PurchaseReturn" ? this.renderDatePicker("BillDate", "billDate", "open1", "10vw") : states.purchase_from === "purchase_rejection" ? this.renderDatePicker("Rejection Date", "delivery_date", "open", "") : this.renderDatePicker("Return Date", "delivery_date", "open", "")}
                                    {this.renderText("CGSTAmount", "cgstAmt", true)}
                                    {this.renderText("SGSTAmount", "sgstAmt", true)}
                                    {this.renderText("BillAmount", "billAmt", true)}
                                </div>
                            </Paper>
                            <Box sx={{ backgroundColor: 'white', height: '57vh' }}>
                                <Box sx={{ backgroundColor: 'white', height: '53vh' }}>
                                    <TableContainer id='eMed_pharmaReturnPO_table' elevation={1} sx={{ backgroundColor: Colors.white }}>
                                        <Table stickyHeader size='small'>
                                            <TableHead className="eMed_pharmadrctpo_tblhead" sx={{ height: "5vh" }}>
                                                <TableRow>
                                                    {states.purchase_from === "PurchaseReturn" ?
                                                        <TableCell sx={{ flex: 0.143 }} id="eMed_pharmadrctpo_tblheadTxt">{
                                                            <Checkbox size='small' checked={allRowCheckTrue} disabled={+stockAmt === 0} onClick={(e) => {
                                                                var updatedData = [...lftTblData]
                                                                updatedData.forEach((data) => {
                                                                    data.rowCheck = data.current_stock_in_quantity ? e.target.checked : false;
                                                                    data.retunQty = ''
                                                                    data.POValue = 0
                                                                    data.NetAmount = 0
                                                                });
                                                                this.setState({
                                                                    lftTblData: updatedData,
                                                                }, () => {
                                                                    let sum = 0;
                                                                    let sum1 = 0
                                                                    let rowChecked = lftTblData.filter((data) => data.rowCheck === true)
                                                                    for (let i = 0; i < rowChecked.length; i++) {
                                                                        sum += updatedData[i].NetAmount;
                                                                        sum1 += updatedData[i].SplitGSTamt;

                                                                    }
                                                                    this.setState({
                                                                        billAmt: sum ? this.getAbsoluteFix(sum) : 0,
                                                                        fullBillAmt: sum ? this.getAbsoluteFix(sum) : 0,
                                                                        cgstAmt: sum1 ? this.getAbsoluteFix(sum1) : 0,
                                                                        sgstAmt: sum1 ? this.getAbsoluteFix(sum1) : 0,
                                                                    });
                                                                });
                                                            }} />
                                                        }</TableCell> : <TableCell sx={{ flex: 0.01 }} id="eMed_pharmadrctpo_tblheadTxt">S.No</TableCell>}
                                                    <TableCell sx={{ flex: 0.143 }} id="eMed_pharmadrctpo_tblheadTxt">{t("ProductDetails")}</TableCell>
                                                    <TableCell sx={{ flex: 0.143 }} id="eMed_pharmadrctpo_tblheadTxt">{t("Purchase Rate Per Qty  ")}</TableCell>
                                                    <TableCell sx={{ flex: 0.143 }} id="eMed_pharmadrctpo_tblheadTxt">{states.purchase_from === "PurchaseReturn" ? t("PO Qty / Ava Qty / Return Qty") : "Return Qty"}</TableCell>
                                                    <TableCell sx={{ flex: 0.143 }} id="eMed_pharmadrctpo_tblheadTxt"> {t("Purchase Discount Amt")}</TableCell>
                                                    <TableCell sx={{ flex: 0.143 }} id="eMed_pharmadrctpo_tblheadTxt"> {t("PurchaseValue")}</TableCell>
                                                    <TableCell sx={{ flex: 0.143 }} id="eMed_pharmadrctpo_tblheadTxt">{t("NetAmount") + `(${CurrencySymbol})`}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="eMed_pharmadrctpo_tblhead">
                                                {states.purchase_from === "PurchaseReturn" ? lftTblData.length > 0 && lftTblData.map((data, index) => {
                                                    let gst_percentage = data.cgst_percentage ? (data.cgst_percentage * 2) : 0;
                                                    let freeQty = (data.free_quantity && data.pack_of_drugs) ? (+data.free_quantity * +data.pack_of_drugs) : data.free_quantity;
                                                    // let QtyPerUnit = data.purchase_quantity / data.sales_unit;
                                                    // let QtyPerUnit = data.purchase_quantity + freeQty;
                                                    // let disAmt = data?.discount_amount + data?.sales_discount_amount
                                                    // let singleQtyPrice = ((data.buying_price / QtyPerUnit) * data?.sales_unit);
                                                    let QtyPerUnit = (data?.total_buying_price - (data?.cgst_amount * 2)) 
                                                    let overAllQty = data?.purchase_quantity + freeQty
                                                    let purPerQty = QtyPerUnit / overAllQty
                                                    data.purchase_per_Qty = purPerQty
                                                    return (
                                                        <TableRow key={index}>
                                                        {states.purchase_from === "PurchaseReturn" ?
                                                            <TableCell sx={{ flex: 0.143 }}>
                                                                <Checkbox size='small' disabled={(data?.stock_in_quantity - data?.return_quantity) === 0} checked={data?.rowCheck === true} onClick={(e) => {
                                                                    var updatedData = [...lftTblData]
                                                                    lftTblData[index]['rowCheck'] = e.target.checked ? e.target.checked : false
                                                                    lftTblData[index]['retunQty'] = ''
                                                                    lftTblData[index]['POValue'] = 0
                                                                    lftTblData[index]['NetAmount'] = 0
                                                                    lftTblData[index]['SplitGSTamt'] = 0
                                                                    // updatedData[index] = {
                                                                    //     ...updatedData[index],
                                                                    //     rowCheck: e.target.checked,
                                                                    //     retunQty: '',
                                                                    //     POValue: 0,
                                                                    //     NetAmount: 0,
                                                                    //     SplitGSTamt: 0
                                                                    // };
                                                                    this.setState({
                                                                        lftTblData: updatedData,
                                                                    }, () => {
                                                                        let sum = 0;
                                                                        let sum1 = 0
                                                                        // let rowChecked = lftTblData.filter((data) => data.rowCheck === true)
                                                                        lftTblData?.forEach(element => {
                                                                            if(element.rowCheck){
                                                                                sum = sum + element.NetAmount
                                                                                sum1 = sum1 + element.SplitGSTamt
                                                                            }
                                                                        })
                                                                        this.setState({
                                                                            billAmt: sum ? this.getAbsoluteFix(sum) : 0,
                                                                            fullBillAmt: sum ? this.getAbsoluteFix(sum) : 0,
                                                                            cgstAmt: sum1 ? this.getAbsoluteFix(sum1) : 0,
                                                                            sgstAmt: sum1 ? this.getAbsoluteFix(sum1) : 0,
                                                                        });
                                                                    });
                                                                }} />
                                                            </TableCell> : <TableCell sx={{ flex: 0.01 }} id="eMed_pharmadrctpo_Txtclr">{index+1}</TableCell> }
                                                            <TableCell sx={{ flex: 0.143 }}>
                                                                <Stack direction="column">
                                                                    <Typography id="eMed_pharmadrctpo_tblbodyTxt">{data.brand_name?.length > 13 ?
                                                                        <Tooltip placement="top" title={data.brand_name} arrow><div>{data.brand_name.slice(0, 12) + "..."}</div></Tooltip>
                                                                        : data.brand_name ? data.brand_name : "-"}</Typography>
                                                                    <Typography id="eMed_pharmadrctpo_tblTxt">{data.generic_name?.length > 13 ?
                                                                        <Tooltip placement="top" title={data.generic_name} arrow><div>{data.generic_name.slice(0, 12) + "..."}</div></Tooltip>
                                                                        : data.generic_name ? data.generic_name : "-"}</Typography>
                                                                    <Typography id="eMed_pharmadrctpo_tblTxt">{data.dosage_type + "|" + data.dosage_strength}</Typography>
                                                                    {data.hsn_code ?
                                                                        <Typography id="eMed_pharmadrctpo_tblTxt">HSN Code:{data.hsn_code}</Typography>
                                                                        : null}
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.143 }}>
                                                                <Typography id="eMed_pharmadrctpo_tblbodyTxt">{purPerQty ? purPerQty?.toFixed(2) : "0"}</Typography>
                                                                <Typography id="eMed_pharmadrctpo_tblTxt">Batch No:{data.batch_no ? data.batch_no : '-'}</Typography>
                                                                <Typography id="eMed_pharmadrctpo_tblTxt">Expiry Date:{data.expiry_date ? data.expiry_date : '-'}</Typography>
                                                            </TableCell>
                                                            <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.34 }}>
                                                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                                                                    <Typography sx={{ textAlign: data.quantity && !(data.stock_in_quantity) ? "center" : null }}>
                                                                        {(data?.is_free_quantity ? data?.purchase_quantity : (data?.purchase_quantity ? data?.purchase_quantity + freeQty : 0)) + ' / ' + (data?.current_stock_in_quantity ? (data?.current_stock_in_quantity) : 0) + ' / '}
                                                                    </Typography>
                                                                    <TextField
                                                                        id="re-quantity"
                                                                        variant="outlined"
                                                                        name='returnQty'
                                                                        disabled={data?.rowCheck ? false : true}
                                                                        value={data?.retunQty}
                                                                        autoComplete='off'
                                                                        inputProps={{
                                                                            style: {
                                                                                height: "1.5vw",
                                                                                padding: '0 14px',
                                                                                fontSize: '0.8vw'
                                                                            },
                                                                        }}
                                                                        onChange={(e) => { this.renderReturnQty(e, data, index, data?.purchase_quantity + freeQty) }}
                                                                        sx={{ width: '6vw', marginLeft: '0.5vw' }} />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.143 }}>{(data?.discount_amount || data?.sales_discount_amount) ? +((+data?.discount_amount) + (+data?.sales_discount_amount))?.toFixed(2) : 0}</TableCell>
                                                            <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.143 }}>{(data.POValue ? this.getAbsoluteFix(data.POValue) : 0) + " + " + (gst_percentage ? gst_percentage : 0) + "% GST"}</TableCell>
                                                            <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.143 }}>{data.NetAmount ? this.getAbsoluteFix(data.NetAmount) : 0}</TableCell>
                                                        </TableRow>
                                                    )
                                                }) :
                                                    returnTbl?.length > 0 && returnTbl?.map((data, index) => {
                                                        let QtyPerUnit = data?.purchase_quantity / data?.sales_unit
                                                        let SingleQty = data?.buying_price / QtyPerUnit
                                                        // let POValue = data?.return_quantity * SingleQty
                                                        let POValue = +(+data?.net_amount - (+data?.sgst_amount * 2))
                                                        let GST_Per = data?.sgst_percentage + data?.cgst_percentage
                                                        let overAllQty = data?.return_quantity
                                                        let purPerQty = QtyPerUnit / overAllQty
                                                        data.purchase_per_Qty = purPerQty
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.01 }}>{index + 1}</TableCell>
                                                                <TableCell sx={{ flex: 0.143 }}>
                                                                    <Stack direction="column">
                                                                        <Typography id="eMed_pharmadrctpo_tblbodyTxt">{data.drug_name?.length > 13 ?
                                                                            <Tooltip placement="top" title={data.drug_name} arrow><div>{data.drug_name.slice(0, 12) + "..."}</div></Tooltip>
                                                                            : data.drug_name ? data.drug_name : "-"}</Typography>
                                                                        <Typography id="eMed_pharmadrctpo_tblTxt">{data.generic_name?.length > 13 ?
                                                                            <Tooltip placement="top" title={data.generic_name} arrow><div>{data.generic_name.slice(0, 12) + "..."}</div></Tooltip>
                                                                            : data.generic_name ? data.generic_name : "-"}</Typography>
                                                                        <Typography id="eMed_pharmadrctpo_tblTxt">{data.dosage_type + "|" + data.drug_strength}</Typography>
                                                                        {data.hsn_code ?
                                                                            <Typography id="eMed_pharmadrctpo_tblTxt">HSN Code:{data.hsn_code}</Typography>
                                                                            : null}
                                                                    </Stack>
                                                                </TableCell>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.143 }}>
                                                                    <Typography id="eMed_pharmadrctpo_tblbodyTxt">{data.purchase_rate_per_qty ? this.getAbsoluteFix(data.purchase_rate_per_qty) : "0"}</Typography>
                                                                    <Typography id="eMed_pharmadrctpo_tblTxt">Batch No:{data.batch_no ? data.batch_no : '-'}</Typography>
                                                                    <Typography id="eMed_pharmadrctpo_tblTxt">Expiry Date:{data.expiry_date ? data.expiry_date : '-'}</Typography>
                                                                </TableCell>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.143 }}>{data?.return_quantity}</TableCell>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.143 }}>{(data?.discount_amount || data?.sales_discount_amount) ? +((+data?.discount_amount) + (+data?.sales_discount_amount))?.toFixed(2) : 0}</TableCell>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.143 }}>{(POValue ? this.getAbsoluteFix(POValue) : 0) + " + " + (GST_Per ? GST_Per : 0) + '% GST'}</TableCell>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.143 }}>{data.net_amount ? this.getAbsoluteFix(data.net_amount) : 0}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })

                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box sx={{ width: '58.2vw', display: 'flex', justifyContent: 'center', backgroundColor: '#FAF9F9' }}>
                                   {this.state.purchase_from === "purchase_rejection" ? null : <Button variant='contained' size='small' disabled={this.state.is_Return ? false : true} sx={{ textTransform: 'capitalize', width: '10vw' }} onClick={() => { this.postReturnPO() }}>{"Return"}</Button>}
                                </Box>
                            </Box>
                        </div>

                    </Grid>
                    <Grid item xs={4.4}>

                    </Grid>
                </Grid>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }

}
export default withTranslation()(PharmaPOReturn);

