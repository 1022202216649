import { Box, Button, Divider, Paper, Stack, Tooltip, Typography, Drawer, TextField, IconButton, Menu, MenuItem } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "./PharmaPurchase.css"
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { AmountFormat, formatDate } from "../../../Components/CommonFunctions/CommonFunctions";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Colors } from '../../../Styles/Colors';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { DateTime } from 'luxon';
import PharmaFilter from '../PharmacyReports/PharmaFilter';
import { CurrencySymbol } from '../../../Utility/Constants';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';

class PharmaReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PurchaseOrderData: [],
      searchkey: "",
      page: 0,
      pageSize: 10,
      rowCount: 0,
      filterOpen: false, 
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
        "days":30
    },
    pageName: 'purchaseReturnOverview',
    total_amount: 0,
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  componentDidMount() {
    this.PurchaseReturnList()
    if(this.props.history?.location?.state?.successMsg){
      this.successMessage(this.props.history?.location?.state?.successMsg)
      this.props.history.push({ state: {} })    
    }
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  PurchaseReturnList() {
    try {
      let url = ''
      if(this.props.allowAction){
        url = Serviceurls.PO_RETURN
      }else{
        url = Serviceurls.PO_RETURN + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}`
      }
      this.LoaderFunction(true);
      RestAPIService.getAll(url).
        then((response) => {
          if (response.data.status === "success") {
            this.LoaderFunction(false);
            let ReturnAPiData = response.data.data ? response.data.data : []
            let ReturnFinalData = []
            ReturnAPiData.forEach((list)=>{
              let GSTAmount = (list?.cgst_amount ? list?.cgst_amount : 0) + (list?.sgst_amount ? list?.sgst_amount : 0)
              list.total_gst_amount = GSTAmount
              list.taxable_amount = (list?.net_amount - GSTAmount)
              ReturnFinalData.push(list)
            })
            this.setState({
              PurchaseOrderData: ReturnFinalData,
              total_amount: response.data?.total_amount
            })
          }
          else {
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false);
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getPharmaRtnPrint = () => {
    try {
      let url = ''
      if(this.props.allowAction){
        url = Serviceurls.PO_RETURN + `?export=pdf`
      }else{
        url = Serviceurls.PO_RETURN + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&export=pdf`
      }
      this.setState({disableBtn: true})
      RestAPIService.getAll(url).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disableBtn: false})
        }).catch((error) => {
          if(error?.response?.data?.message){
            this.errorMessage(error?.response?.data?.message)
          }else{
            this.errorMessage(error.message)
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disableBtn: false})
    }
  }

  getPharmaRtnExcel = () => {
    try {
      let url = ''
      if(this.props.allowAction){
        url = Serviceurls.PO_RETURN
      }else{
        url = Serviceurls.PO_RETURN + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&export=excel`
      }
      this.setState({disableBtn: true})
      RestAPIService.excelGet(url).
            then((response) => {
                if (response.data) {
                    var pom = document.createElement('a');
                    var csvContent = response.data; //here we load our csv data 

                    let filename = response.headers["content-disposition"].split("filename=")[1]
                    // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                    var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                    var url = URL.createObjectURL(blob);
                    pom.href = url;
                    pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                    pom.click();
                    this.setState({disableBtn: false})
                }
            }).catch((error) => {
                if (error.response?.data?.message) {
                    this.errorMessage(error.response?.data?.message);
                } else {
                    this.errorMessage(error.message);
                }
                this.setState({disableBtn: false})
            })
    } catch (error) {
        this.errorMessage(error.message);
        this.setState({disableBtn: false})
    }
}



  gridToolBar = () => {
    const { t } = this.props;
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
      { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={this.props.allowAction ? null : TextArray} searchHolder={t("searchVendorName")} />
      </Box>
    )
  }
  MoreMenus = (data) => {
    const { t } = this.props
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            disabled={data?.status === "Completely Delivered" || data?.status === "Partially Delivered" ? false : true}
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data?.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data?.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            <MenuItem
              onClick={() => {
                this.props.history.push(
                  {
                    pathname: "/PharmacyPurchase/PurchaseOrder/ReturnPO"
                    , state: {
                      purchase_order_id: data?.id,
                      purchase_status: "Returned",
                      from: "PurchaseReturn_Report"
                    }
                  })
              }}>
              {'Return'}
            </MenuItem>
          </Menu>
          : null}
      </div>
    )
  }

  renderTable = () => {
    this.state.PurchaseOrderData.forEach((element, index) => element.serial = index + 1)
    const { t } = this.props
    const columns = [
      {
        field: "serial", flex: 0.01, headerName: t("SNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.serial ? params?.row?.serial : "-"}</Box>)
      },
      {
        field: "po_return_date", flex: 0.0714, headerName: t("Return Date"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'} emed_tid="PO Date">{params?.row?.po_return_date ? formatDate(params?.row?.po_return_date) : "-"}</Box>)
      },
      {
        field: "po_return_number", flex: 0.0714, headerName: t("PO Return Number"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.po_return_number ? params?.row?.po_return_number : "-"}</Box>)
      },
      {
        field: "po_number", flex: 0.0714, headerName: t("PO Number"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.po_number ? params?.row?.po_number?.length > 12 ? <Tooltip placement="top" title={params?.row?.po_number} arrow><div>{'...' + params?.row?.po_number?.slice(-10)}</div></Tooltip> : params?.row?.po_number : "-"}</Box>)
      },
      {
        field: "invoice_number", flex: 0.0714, headerName: t("Invoice No"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_number ? params?.row?.invoice_number?.length > 12 ? <Tooltip placement="top" title={params?.row?.invoice_number} arrow><div>{params?.row?.invoice_number?.slice(0, 10) + '...'}</div></Tooltip> : params?.row?.invoice_number : "-"}</Box>)
      },
      {
        field: "vendor_name", flex: 0.0714, headerName: t("Vendor Name"),
        renderCell: ({ row }) => (<div>{row?.vendor_name?.length > 25 ?
          <Tooltip placement="top" title={row?.vendor_name} arrow><div>{row?.vendor_name.slice(0, 24) + "..."}</div></Tooltip>
          : row?.vendor_name ? row?.vendor_name : "-"}</div>)
      },
      {
        field: "number_of_items", flex: 0.0714, headerName: t("Number of Items"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.number_of_items ? params?.row?.number_of_items : "0"}</Box>)
      },
      {
        field: "total_return_quantity", flex: 0.0714, headerName: t("Return Qty"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.total_return_quantity ? params?.row?.total_return_quantity : "0"}</Box>)
      },
      {
        field: "net_amount", flex: 0.0714, headerName: t("Return Amount"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.bill_amount ? AmountFormat(params?.row?.bill_amount).replace(`${CurrencySymbol}`, "")  : params?.row?.net_amount ? AmountFormat(params?.row?.net_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
      },
      {
        field: "taxable_amount", flex: 0.078, headerName: t("Taxable Amount"), type: "number",
        renderCell: (params) => {
          let taxableAmt = params?.row?.taxable_amount
          return(
            <Box component={'div'}>{taxableAmt ? AmountFormat(taxableAmt).replace(`${CurrencySymbol}`, "")  : "0"}</Box>
          )
      }
      },
      {
        field: "state", flex: 0.0714, headerName: t("state"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.state ? params?.row?.state?.length > 10 ? 
          <Tooltip placement='top' title={params?.row?.state}><div>{params?.row?.state?.slice(0,10)+ '...'}</div></Tooltip> : 
          params?.row?.state  : "-"}</Box>)
      },
      {
        field: "total_gst_amount", flex: 0.0714, headerName: t(`GST (${CurrencySymbol})`), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.total_gst_amount ? AmountFormat(params?.row?.total_gst_amount).replace(`${CurrencySymbol}`, "")  : "0"}</Box>)
      },
      {
        field: "cgst_amount", flex: 0.0714, headerName: t(`CGST (${CurrencySymbol})`), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.cgst_amount ? AmountFormat(params?.row?.cgst_amount).replace(`${CurrencySymbol}`, "")  : "0"}</Box>)
      },
      {
        field: "sgst_amount", flex: 0.0714, headerName: t(`SGST (${CurrencySymbol})`), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sgst_amount ? AmountFormat(params?.row?.sgst_amount).replace(`${CurrencySymbol}`, "")  : "0"}</Box>)
      },
      { field: 'igst', headerName: `${"IGST"}`, flex: 0.0714 , type: "number"},
    ]
    if (this.props.allowAction) {
      columns.push(
        {
          field: "action", flex: 0.071, headerName: t("Action"), headerAlign: "center", align: "center",
          renderCell: (params) => (
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Tooltip title={t("View")} placement="top" arrow>
                <Button  emed_tid="purchase_rtn_view" onClick={() => {
                  this.props.history.push(
                    {
                      pathname: "/PharmacyPurchase/PurchaseOrder/ReturnPO"
                      , state: {
                        purchase_order_id: params?.row?.purchase_order_id,
                        ReturnData: params?.row,
                        from: 'PurchaseReturn_Report',
                        purchase_status: 'Returned'
                      },
                    })
                }} className='eMed_usrconf_btn'>
                  <img src={ImagePaths.View.default} alt="View" className='eMed_action_img' />
                </Button>
              </Tooltip>
            </Box>
          )
        }
      )
    }

    return (
      <Box component={"div"}>
        <Stack component={"div"}>
          <Paper sx={{ height: this.props.allowAction ? '70vh': '60vh' }} className='eMed_Purchase_Table_Wrapper' >
            <DataGrid
              rows={this.state.PurchaseOrderData}
              columns={columns}
              getRowId={(row) => row['serial']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              initialState={{
                columns: {
                    columnVisibilityModel: {
                      number_of_items: false,
                      state:false,
                      igst:false
                    }
                }
            }}
              rowsPerPageOptions={[10, 20, 30]}
              disableSelectionOnClick
              pagination
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              loading={this.state.isLoader}
            />
          </Paper>
        </Stack>
      </Box>
    )
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
    }, () => { this.PurchaseReturnList() })
  }
  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }
  clearFilter = (data) => {
    this.setState({
        filterDatas: data
    })
}
  render() {
    const { t } = this.props;
    return (
      <div style={{  marginTop: this.props.allowAction ? '2vw' : '0vw', backgroundColor: this.props.allowAction ? "white" : Colors.Background }}> 
        {this.props.allowAction ? 
          <Box component={'div'} marginTop={"-2vh"} display={"flex"} marginBottom={"1.75vh"} marginRight={"2vw"} justifyContent={"flex-end"} >
            <Button emed_tid="rpt_consoli_flt" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getPharmaRtnPrint()}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='filter' height={'2.2vw'} width={'2.2vw'} />
            </Button>
          </Box>
          :
          <Box component={'div'} className='eMed_Pharma_rts_header'>
            <Box component={'div'} flex={0.8} display={'flex'}>
              {AmountsCard("Total Amount", this.state.total_amount ? this.state.total_amount : 0, false, "white", false, null, 'pur_return_total')}
            </Box>
            <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
              <Button emed_tid="rpt_purRtn_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
              </Button>
              <Button emed_tid="rpt_purRtn_flt" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getPharmaRtnPrint()}>
                <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
              </Button>
              <Button emed_tid="rpt_saleRtn_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getPharmaRtnExcel()}>
                <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
              </Button>
            </Box>
          </Box>}
        {this.renderTable()}

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
          {this.props.allowAction ? null : 
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
        </Drawer>}
      </div>
    )
  }
}
export default withTranslation()(PharmaReturn)

