import axios from "axios";

const BASE_DOMAIN = "https://devv4api.emedhub.in/" //DEV API URL
// const BASE_DOMAIN = "https://qav4api.emedhub.in/" //QA API URL
// const BASE_DOMAIN = "https://uat.v4.api.emedhub.in/"; //UAT API URL
// const BASE_DOMAIN = "https://api.emedhub.in/" ; //Production API URL

class ABHAapiServices {
    PostAuth(data, URL) {
        if (!window.navigator.onLine) {
            return Promise.reject(new Error('No internet connection available'));
        } else {
            return axios.create({
                baseURL: BASE_DOMAIN,
                headers: {
                    "Content-type": "application/json",
                }
            }).post("/" + URL, data);
        }
    }

    POSTABDM(data, URL, AccessToken, TimeStamp, RandomUUID) {
        if (!window.navigator.onLine) {
            return Promise.reject(new Error('No internet connection available'));
        } else {
            return axios.create({
                baseURL: BASE_DOMAIN,
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${AccessToken}`,
                    "Timestamp": `${TimeStamp}`,
                    "Request-Id": `${RandomUUID}`
                }
            }).post("/" + URL, data);
        }
    }

    DEMO_AUTH(data, URL, AccessToken, TimeStamp, RandomUUID) {
        if (!window.navigator.onLine) {
            return Promise.reject(new Error('No internet connection available'));
        } else {
            return axios.create({
                baseURL: BASE_DOMAIN,
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${AccessToken}`,
                    "Timestamp": `${TimeStamp}`,
                    "Request-Id": `${RandomUUID}`,
                    "REQUESTER-ID" : "SBX_006716"
                }
            }).post("/" + URL, data);
        }
    }

    VERIFY_DEMO(URL, AccessToken, TimeStamp, RandomUUID, TransID) {
        if (!window.navigator.onLine) {
            return Promise.reject(new Error('No internet connection available'));
        } else {
            return axios.create({
                baseURL: BASE_DOMAIN,
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${AccessToken}`,
                    "Timestamp": `${TimeStamp}`,
                    "Request-Id": `${RandomUUID}`,
                    "TRANSACTION_ID": `${TransID}`,
                }
            }).get("/" + URL);
        }
    }

    VERIFY_USER(data, URL, AccessToken, XToken, TimeStamp, RandomUUID) {
        if (!window.navigator.onLine) {
            return Promise.reject(new Error('No internet connection available'));
        } else {
            return axios.create({
                baseURL: BASE_DOMAIN,
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${AccessToken}`,
                    "T-Token": `Bearer ${XToken}`,
                    "Timestamp": `${TimeStamp}`,
                    "Request-Id": `${RandomUUID}`,
                }
            }).post("/" + URL, data);
        }
    }

    ABHA_SUGG_GET(URL, AccessToken, TransID, TimeStamp, RandomUUID) {
        if (!window.navigator.onLine) {
            return Promise.reject(new Error('No internet connection available'));
        } else {
            return axios.create({
                baseURL: BASE_DOMAIN,
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${AccessToken}`,
                    "Timestamp": `${TimeStamp}`,
                    "Request-Id": `${RandomUUID}`,
                    "TRANSACTION_ID": `${TransID}`,
                }
            }).get("/" + URL);
        }
    }

    GET_ABHA_PROFILE(URL, AccessToken, Xtoken, TimeStamp, RandomUUID) {
        if (!window.navigator.onLine) {
            return Promise.reject(new Error('No internet connection available'));
        } else {
            return axios.create({
                baseURL: BASE_DOMAIN,
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${AccessToken}`,
                    "Timestamp": `${TimeStamp}`,
                    "Request-Id": `${RandomUUID}`,
                    "X-token" : `Bearer ${Xtoken}`
                }
            }).get("/" + URL);
        }
    }

    GET_ABHA_CARD(URL, AccessToken, Xtoken, TimeStamp, RandomUUID) {
        if (!window.navigator.onLine) {
            return Promise.reject(new Error('No internet connection available'));
        } else {
            return axios.create({
                baseURL: BASE_DOMAIN,
                headers: {
                    "Content-type": "image/png",
                    "Authorization": `Bearer ${AccessToken}`,
                    "Timestamp": `${TimeStamp}`,
                    "Request-Id": `${RandomUUID}`,
                    "X-token" : `Bearer ${Xtoken}`
                }
            }).get("/" + URL);
        }
    }
}

export default new ABHAapiServices();