import { Autocomplete, Box, Button, Tab, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import { ImagePaths } from '../../../Utility/ImagePaths'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import { CurrencySymbol } from '../../../Utility/Constants'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { DeletePopup } from '../../../Components/Common Components/CommonComponents'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'

export default class LabDocShareConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shareListData: [],
            doctorShare: '',
            hospitalShare: '',
            selectedGrpTstPacName: null,
            grpTstPacListData: [],
            doctorListData: [],
            grpTestData: [],
            labTestData: [],
            packageTestData: [],
            selectedFilterGrp: null,
            selectedFilterPackage: null,
            selectedFilterTest: null,
            selectedTstAmt: null,
            selectedTstAmt: 0,
            grpTstPacTestCodeData: [],
            selectedTestCode: null,
            selectedDoctor: null,
            selectedId: null,
            selectedDoctorId: null,
            selectedgrpTstGrpId: null,
            selectedType: '',
            removeData: false,
            deletePopup: false,
            selectedName: '',
            tblPage: 0,
            tblPageSize: 10,
            dupShareListData: '[]',
            grpClick: false
        }
    }

    componentDidMount() {
        this.getDoctorListData()
        this.getTestListData()
        this.getGroupListData()
        this.getPackageListData()
        this.getGrpTstPackData()
        this.getDoctorShareListData()
    }

    getTestListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            labTestData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getGroupListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            grpTestData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPackageListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.NEW_LAB_PACKAGE_CONFIGURATION)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            packageTestData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDoctorListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            doctorListData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getGrpTstPackData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_GRP_TST_PACK_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            grpTstPacListData: response.data.data,
                            grpTstPacTestCodeData: response?.data?.data?.filter(item => item?.test_code)
                        }, () => {
                            this.state.grpTstPacListData?.forEach((element, index) => element.sno = index + 1)
                            this.setState({ grpTstPacListData: this.state.grpTstPacListData })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDoctorShareListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_DOCTOR_SHARE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            shareListData: response.data.data,
                            dupShareListData: JSON.stringify(response.data.data)
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkValidation = () => {
        try {
            let message = null;
            if (
                this.state.selectedGrpTstPacName === null || this.state.selectedDoctor === null ||
                this.state.doctorShare === '' || this.state.hospitalShare === ''
            ) {
                message = 'Enter all mandatory field'
            }
            return message
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postDoctorShareListData = () => {
        try {
            let states = this.state
            let method = 'POST'

            let selectedData = states.grpClick ? states.selectedGrpTstPacName : states.selectedTestCode
            let data = {
                doctor_id: states.selectedDoctor?.doctor_id ? states.selectedDoctor?.doctor_id : states.selectedDoctorId,
                lab_test_id: selectedData?.test_type === 'Test' ? selectedData?.id : states.selectedType === 'Test' ? states.selectedgrpTstGrpId : null,
                lab_grp_id: selectedData?.test_type === 'Group' ? selectedData?.id : states.selectedType === 'Group' ? states.selectedgrpTstGrpId : null,
                lab_package_id: selectedData?.test_type === 'Package' ? selectedData?.id : states.selectedType === 'Package' ? states.selectedgrpTstGrpId : null,
                total_amount: states.selectedTstAmt ? +states.selectedTstAmt : 0,
                doctor_share: states.doctorShare ? +states.doctorShare : 0,
                hospital_share: states.hospitalShare ? +states.hospitalShare : 0
            }
            if (this.state.isEdit) {
                data['id'] = this.state.selectedId ? this.state.selectedId : null
                method = 'PUT'
            }
            let check = this.checkValidation()
            if (check === null) {
                RestAPIService[method === 'POST' ? 'create' : 'updateWithOutId'](data, Serviceurls.LAB_DOCTOR_SHARE)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.getDoctorShareListData()
                            this.onClearHandler()
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.errorMessage(error?.response?.data?.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage(check)
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    deleteDoctorShareData = () => {
        try {
            let data = {}
            RestAPIService.delete(Serviceurls.LAB_DOCTOR_SHARE + '?id=' + this.state.selectedId, data)
                .then((response) => {
                    this.successMessage(response.data.message)
                    this.getDoctorShareListData()
                    this.onClearHandler()
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onChangeHandler = (e, names) => {
        try {
            let value = e.target.value
            switch (names) {
                case 'doctorShare':
                    if ((CommonValidation.numberOnly(value) && value <= this.state.selectedTstAmt) || value === '') {
                        this.setState({ doctorShare: value }, () => {
                            this.setState({ hospitalShare: value ? this.state.selectedTstAmt - (+value) : '' })
                        })
                    }
                    break;
                case 'hospitalShare':
                    if ((CommonValidation.numberOnly(value) && value <= this.state.selectedTstAmt) || value === '') {
                        this.setState({ hospitalShare: value }, () => {
                            this.setState({ doctorShare: value ? this.state.selectedTstAmt - (+value) : '' })
                        })
                    }
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    TextBox = (names, values, width, placeholder, disable) => {
        try {
            return (
                <TextField
                    size='small'
                    variant='standard'
                    sx={{ width: width }}
                    disabled={disable}
                    value={values}
                    name={names}
                    placeholder={placeholder}
                    onChange={(e) => this.onChangeHandler(e, names)}
                    inputProps={{ emed_tid: names }}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (item) => {
        try {
            this.setState({
                isEdit: true,
                selectedTestCode: item?.lab_test_code,
                selectedGrpTstPacName: item?.lab_test_name,
                selectedTstAmt: item?.total_amount,
                selectedDoctor: item?.doctor_name,
                hospitalShare: item?.hospital_share,
                doctorShare: item?.doctor_share,
                selectedId: item?.id,
                selectedDoctorId: item?.doctor_id,
                selectedgrpTstGrpId: item?.lab_test_id ? item?.lab_test_id : item?.lab_grp_id ? item?.lab_grp_id : item?.lab_package_id,
                selectedType: item?.lab_test_id ? 'Test' : item?.lab_grp_id ? 'Group' : item?.lab_package_id ? 'Package' : ''
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearHandler = () => {
        try {
            this.setState({
                doctorShare: '',
                hospitalShare: '',
                selectedGrpTstPacName: null,
                selectedTestCode: null,
                selectedDoctor: null,
                selectedTstAmt: 0,
                isEdit: false,
                selectedId: null,
                selectedDoctorId: null,
                selectedgrpTstGrpId: null,
                selectedType: '',
                selectedName: '',
                deletePopup: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderTable = () => {
        try {
            let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
            return (
                <Box component={'div'} border={'1px solid lightgray'}>
                    <Box component={'div'} height={'59.5dvh'} overflow={'auto'}>
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableRow>
                                    {showCode ?
                                        <TableCell sx={{ backgroundColor: Colors.Background }} width={'14.28%'}>
                                            <Typography fontSize={'0.9vw'} color={Colors.SecondaryText} fontWeight={600}>Test Code</Typography>
                                        </TableCell> : null}
                                    <TableCell sx={{ backgroundColor: Colors.Background }} width={'17.28%'}>
                                        <Typography fontSize={'0.9vw'} color={Colors.SecondaryText} fontWeight={600}>Group/Test/Package Name *</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }} width={'10.28%'} align='right'>
                                        <Typography fontSize={'0.9vw'} color={Colors.SecondaryText} fontWeight={600}>{`Price (${CurrencySymbol})`}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }} width={'14.28%'}>
                                        <Typography fontSize={'0.9vw'} color={Colors.SecondaryText} fontWeight={600}>Doctor Name *</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }} width={'16.28%'}>
                                        <Typography fontSize={'0.9vw'} color={Colors.SecondaryText} fontWeight={600}>{`Hospital Share * (${CurrencySymbol})`}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }} width={'16.28%'}>
                                        <Typography fontSize={'0.9vw'} color={Colors.SecondaryText} fontWeight={600}>{`Doctor Share * (${CurrencySymbol})`}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }} width={'11.28%'} align='center'>
                                        <Typography fontSize={'0.9vw'} color={Colors.SecondaryText} fontWeight={600}>Action</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    {showCode ?
                                        <TableCell>
                                            <Box component={'div'} display={'flex'}>
                                                <Button className='Common_Btn_Min_Width' emed_tid='lab_shr_clr'
                                                    onClick={() => {
                                                        this.setState({
                                                            doctorShare: '', hospitalShare: '',
                                                            selectedGrpTstPacName: null, selectedTestCode: null,
                                                            selectedTstAmt: 0, selectedDoctor: null, isEdit: false
                                                        })
                                                    }}
                                                >
                                                    <Box component={'img'} src={ImagePaths.LabDeleteButton.default} height={'1.5vw'} width={'1.5vw'} />
                                                </Button>
                                                <Autocomplete
                                                    size='small'
                                                    sx={{ width: '11vw' }}
                                                    options={this.state.grpTstPacTestCodeData}
                                                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.test_code}
                                                    value={this.state.selectedTestCode}
                                                    onChange={(e, value) => {
                                                        this.setState({
                                                            selectedTestCode: value,
                                                            selectedGrpTstPacName: value?.test_name,
                                                            selectedTstAmt: value?.test_amt,
                                                            doctorShare: '', hospitalShare: '', grpClick: false
                                                        })
                                                    }}
                                                    renderInput={(params) => <TextField {...params} variant='standard' placeholder='Test Code' InputProps={{ ...params.InputProps, emed_tid: 'lab_grp_code_srh' }} />}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option?.sno}>
                                                            <Typography fontSize={'0.9vw'}>{`${option?.test_code} - ${option?.test_type}`}</Typography>
                                                        </li>
                                                    )}
                                                />
                                            </Box>
                                        </TableCell> : null}
                                    <TableCell>
                                        <Box component={'div'}>
                                            <Autocomplete
                                                size='small'
                                                sx={{ width: '15vw' }}
                                                options={this.state.grpTstPacListData}
                                                getOptionLabel={(option) => typeof (option) === 'string' ? option : `${option?.test_name} - ${option?.test_type}`}
                                                value={this.state.selectedGrpTstPacName}
                                                onChange={(e, value) => {
                                                    this.setState({
                                                        selectedGrpTstPacName: value,
                                                        selectedTestCode: value?.test_code ? value?.test_code : null,
                                                        selectedTstAmt: value?.test_amt,
                                                        doctorShare: '', hospitalShare: '', grpClick: true
                                                    })
                                                }}
                                                renderInput={(params) => <TextField {...params} variant='standard' placeholder='Group/Test/Package Name' InputProps={{ ...params.InputProps, emed_tid: 'lab_grp_src' }} />}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option?.sno}>
                                                        <Typography fontSize={'0.9vw'}>{`${option?.test_name} - ${option?.test_type}`}</Typography>
                                                    </li>
                                                )}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Box component={'div'}>
                                            <Typography>{this.state.selectedTstAmt ? this.state.selectedTstAmt : 0}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box component={'div'}>
                                            <Autocomplete
                                                size='small'
                                                sx={{ width: '12vw' }}
                                                options={this.state.doctorListData}
                                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.doctor_name}
                                                value={this.state.selectedDoctor}
                                                onChange={(e, value) => { this.setState({ selectedDoctor: value }) }}
                                                renderInput={(params) => <TextField {...params} variant='standard' placeholder='Doctor Name' InputProps={{ ...params.InputProps, emed_tid: 'lab_doc_src' }} />}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option?.doctor_id}>
                                                        {option?.doctor_name}
                                                    </li>
                                                )}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box component={'div'}>
                                            {this.TextBox('hospitalShare', this.state.hospitalShare, '10vw', 'Hospital Share', this.state.selectedTstAmt ? false : true)}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box component={'div'}>
                                            {this.TextBox('doctorShare', this.state.doctorShare, '10vw', 'Doctor Share', this.state.selectedTstAmt ? false : true)}
                                        </Box>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Box component={'div'}>
                                            <Button className='Common_Btn_Min_Width' variant='contained' size='small'
                                                onClick={() => this.postDoctorShareListData()}
                                            >Add
                                                {/* <Box component={'img'} src={ImagePaths.AddIcon.default} height={'1.5vw'} width={'1.5vw'} /> */}
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.shareListData?.length > 0 ?
                                    this.state.shareListData?.slice(
                                        this.state.tblPage * this.state.tblPageSize,
                                        this.state.tblPage * this.state.tblPageSize +
                                        this.state.tblPageSize)?.map((item, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    {showCode ?
                                                        <TableCell>
                                                            <Typography fontSize={'0.9vw'}>{item?.lab_test_code ? `${item?.lab_test_code}${item?.lab_test_id ? ' - Test' : item?.lab_grp_id ? ' - Group' : ' - Package'}` : 'Nil'}</Typography>
                                                        </TableCell> : null}
                                                    <TableCell>
                                                        {item?.lab_test_name ? item?.lab_test_name?.length > 20 ?
                                                            <Tooltip placement='top' title={item?.lab_test_name}><Typography fontSize={'0.9vw'}>{`${item?.lab_test_name?.slice(0, 20) + '...'}${item?.lab_test_id ? ' - Test' : item?.lab_grp_id ? ' - Group' : ' - Package'}`}</Typography></Tooltip> :
                                                            <Typography fontSize={'0.9vw'}>{`${item?.lab_test_name}${item?.lab_test_id ? ' - Test' : item?.lab_grp_id ? ' - Group' : ' - Package'}`}</Typography> : '-'}
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        {item?.total_amount ? item?.total_amount?.length > 15 ?
                                                            <Tooltip placement='top' title={item?.total_amount}><Typography fontSize={'0.9vw'}>{item?.total_amount?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                                            <Typography fontSize={'0.9vw'}>{item?.total_amount}</Typography> : '0'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.doctor_name ? item?.doctor_name?.length > 20 ?
                                                            <Tooltip placement='top' title={item?.doctor_name}><Typography fontSize={'0.9vw'}>{item?.doctor_name?.slice(0, 20) + '...'}</Typography></Tooltip> :
                                                            <Typography fontSize={'0.9vw'}>{item?.doctor_name}</Typography> : '-'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.hospital_share ? item?.hospital_share?.length > 15 ?
                                                            <Tooltip placement='top' title={item?.hospital_share}><Typography fontSize={'0.9vw'}>{item?.hospital_share?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                                            <Typography fontSize={'0.9vw'}>{item?.hospital_share}</Typography> : '0'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.doctor_share ? item?.doctor_share?.length > 15 ?
                                                            <Tooltip placement='top' title={item?.doctor_share}><Typography fontSize={'0.9vw'}>{item?.doctor_share?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                                            <Typography fontSize={'0.9vw'}>{item?.doctor_share}</Typography> : '0'}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                                            <Button className='Common_Btn_Min_Width' emed_tid='lab_tbl_edit'
                                                                onClick={() => { this.onEditHandler(item) }}
                                                            >
                                                                <Box component={'img'} src={ImagePaths.LabEditButton.default} height={'1.5vw'} width={'1.5vw'} />
                                                            </Button>
                                                            <Button className='Common_Btn_Min_Width' emed_tid='lab_tbl_dlt'
                                                                disabled={this.state.isEdit}
                                                                onClick={() => this.setState({ deletePopup: true, selectedId: item?.id, selectedName: item?.lab_test_name })}
                                                            >
                                                                <Box component={'img'} src={ImagePaths.LabDeleteButton.default} height={'1.5vw'} width={'1.5vw'} />
                                                            </Button>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={7} align='center' sx={{ border: 'none', position: 'relative', top: '25dvh' }}>
                                            <Typography fontWeight={600}>No Records To Be Shown</Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Box>
                    <TablePagination
                        className='eMed_tbl_pagination'
                        sx={{ borderTop: '1px solid lightgray' }}
                        component='div'
                        rowsPerPageOptions={[10, 20, 30]}
                        count={this.state.shareListData?.length}
                        rowsPerPage={this.state.tblPageSize}
                        page={this.state.tblPage}
                        onPageChange={(event, newPage) => { this.setState({ tblPage: newPage }) }}
                        onRowsPerPageChange={(event) => { this.setState({ tblPageSize: event.target.value, tblPage: 0 }) }}
                    />
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    popupClose = () => {
        try {
            this.setState({
                deletePopup: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onSearchHandler = () => {
        try {
            let duplicateData = JSON.parse(this.state.dupShareListData)
            let filterData = duplicateData?.filter((list) => (
                (list?.lab_test_name?.toLowerCase()?.includes(this.state.selectedFilterGrp?.name?.toLowerCase())) ||
                (list?.lab_test_name?.toLowerCase()?.includes(this.state.selectedFilterTest?.test_name?.toLowerCase())) ||
                (list?.lab_test_name?.toLowerCase()?.includes(this.state.selectedFilterPackage?.package_name?.toLowerCase()))
            ))
            if (this.state.selectedFilterGrp || this.state.selectedFilterTest || this.state.selectedFilterPackage) {
                this.setState({ shareListData: filterData })
            } else {
                this.setState({ shareListData: duplicateData })
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: '79dvh' }} p={'0.5vw'}>
                <Box component={'div'} height={'9dvh'} display={'flex'} p={'0.5vw 0vw'}>
                    <Autocomplete
                        clearIcon
                        size='small'
                        sx={{ width: '15vw', mr: '0.5vw' }}
                        options={this.state.grpTestData}
                        getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.name}
                        value={this.state.selectedFilterGrp}
                        onChange={(e, value) => {
                            this.setState({ selectedFilterGrp: value },() => {
                                if(this.state.selectedFilterGrp === null){
                                    this.onSearchHandler()
                                }
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label='Select Group Name' InputProps={{ ...params.InputProps, emed_tid: 'grp_src' }} />}
                        renderOption={(props, option) => (
                            <li {...props} key={option?.id}>
                                {`${option.name}`}
                            </li>
                        )}
                    />
                    <Autocomplete
                        clearIcon
                        size='small'
                        sx={{ width: '15vw', mr: '0.5vw' }}
                        options={this.state.labTestData}
                        getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.test_name}
                        value={this.state.selectedFilterTest}
                        onChange={(e, value) => {
                            this.setState({ selectedFilterTest: value },() => {
                                if(this.state.selectedFilterTest === null){
                                    this.onSearchHandler()
                                }
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label='Select Test Name' InputProps={{ ...params.InputProps, emed_tid: 'tst_src' }} />}
                        renderOption={(props, option) => (
                            <li {...props} key={option?.id}>
                                {`${option.test_name}`}
                            </li>
                        )}
                    />
                    <Autocomplete
                        clearIcon
                        size='small'
                        sx={{ width: '15vw' }}
                        options={this.state.packageTestData}
                        getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.package_name}
                        value={this.state.selectedFilterPackage}
                        onChange={(e, value) => {
                            this.setState({ selectedFilterPackage: value },() => {
                                if(this.state.selectedFilterPackage === null){
                                    this.onSearchHandler()
                                }
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label='Select Package Name' InputProps={{ ...params.InputProps, emed_tid: 'pack_src' }} />}
                        renderOption={(props, option) => (
                            <li {...props} key={option?.id}>
                                {`${option.package_name}`}
                            </li>
                        )}
                    />
                    <Box component={'div'} ml={'1vw'}>
                        <Button variant='contained' sx={{mr:'0.5vw'}}
                            onClick={() => this.onSearchHandler()}
                        >Search</Button>
                        <Button variant='outlined'
                            onClick={() => {
                                this.setState({
                                    selectedFilterPackage: null,
                                    selectedFilterGrp: null,
                                    selectedFilterTest: null
                                }, () => this.onSearchHandler())
                            }}
                        >Clear</Button>
                    </Box>
                </Box>
                <Box component={'div'} height={'68dvh'} sx={{ backgroundColor: Colors.white }}>
                    {this.renderTable()}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`Are you sure? Do you want to Delete ${this.state.selectedName}?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deleteDoctorShareData}
                    disable={this.state.removeData}
                /> : null}
            </Box>
        )
    }
}
