import React, { Component } from "react";
import '../IpPatientDetails.css';
import {
  Box, Paper, TextField, Button, Stack, Tooltip, Grid, IconButton, Menu, FormControl, Select, MenuItem, Autocomplete, Chip, Typography, ButtonGroup,
  InputLabel, Table, TableBody, TableHead, TableRow, TableCell, TableContainer, Collapse
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { CommonPatientDetails, CommonPatientCard, CommonDeleteButton, CommonEditButton, DeletePopup, CommonPopUp, ConsultantPatientCard } from "../../../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../../../Components/CommonFunctions/CommonValidation";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ImagePaths } from "../../../../../Utility/ImagePaths";
import { Colors } from "../../../../../Styles/Colors";
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../../Utility/API/Serviceurls";
import { getCachevalue, localGetItem} from "../../../../../Utility/Services/CacheProviderService";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MaenList, DurationsList, DurationCounts, IP_routeList, Taper_Med_Dosages, Taper_Med_Frequency, Medication_dosages } from '../../../../../Utility/Constants';
import { t } from "i18next";
import { CheckAccessFunc } from "../../../../../Components/CommonFunctions/CommonFunctions";
import Loader from '../../../../../Components/Loader';


const ITEM_HEIGHT = 30;
let today = new Date();
const multi_option = [
  { value: "Medication", label: "Medication" },
];

class Medication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      medicationDetails: [],
      patientData: {},
      attenderData: {},
      pharmaData: [],
      pharma_name: "",
      pharma_id: null,
      brandnameList: [],
      genericList: [],
      mediItem: {},
      tapperItem: {},
      frequentDrugs: [],
      open: false,
      duration_mode: "Day(s)",
      duration: null,
      isTapper: false,
      instruction: "",
      tapperDuration_mode: "Day(s)",
      tapperDuration: "",
      tapperStartDate: today,
      tapperUnit_typ: "",
      tapperReduced_typ: "mg",
      tapperDetails: [],
      tapperFrequency: "",
      selectedEditIndex: null,
      morning: 0,
      afternoon: 0,
      evening: 0,
      night: 0,
      quantity: null,
      brand_name: "",
      medication_id: null,
      selectedRow: null,
      isSelected: false,
      anchorEl: null,
      isHistoryClicked: false,
      selectedOption: "",
      selectedDetails: [],
      newSelectedDetails: [],
      selectedIndex: -1,
      historyData: [],
      focusError: "",
      isEdit: false,
      drug_name: "",
      drug_ms_id: null,
      current_page: 0,
      rowsPerPage: 10,
      withOutAppointment: {},
      showSuccessResponse: false,
      showErrorResponse: false,
      responseMessage: "",
      ExternalDrug : "",
      externalBrandName : "",
      externalGenName : "",
      externalDosageType : "",
      externalDosStrength : "",
      externalRoute : "",
      mediRouteOther : "",
      externalRouteOther : "",
      clinic_id: JSON.parse(localGetItem('SelectedDoctorClinicId')),
    }
  }
  componentDidMount() {
    let patientDetails = getCachevalue("IppatientData")
    let patients = JSON.parse(patientDetails)
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    this._getPharmaList();
    // this.getFrequentDrugInPharma();
    if (details?.patient_id === patients?.patient_id) {
      this.setState({
        withOutAppointment: details
      }, () => {
        this._getIpMedicationDetails();
        this.getPatientData();
      })
    }
    this.setState({
      patientData: patients
    }, () => {
      this._getIpMedicationDetails();
      this.getPatientData();
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getPatientData = () => {
    let admission_id =  this.state.patientData?.id || this.props?.ip_admission_id || this.state.withOutAppointment?.ip_admission_id;
    let url = `${Serviceurls.IP_PATIENT_DETAILS}?admission_id=${admission_id}`;
    if (this.props.is_From_IpNotes && this.state.clinic_id) {
      url = `${url}&clinic_id=${this.state.clinic_id}`
    }
    try {
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              attenderData: response.data.data[0]
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.responseSuccessErrorMessage(error.response.data.message, false)
          } else {
            this.responseSuccessErrorMessage(error.message, false)
          }
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  _getPharmaList = () => {
    this.setState({
      pharmaData: []
    })
    try {
      let url = Serviceurls.FO_IP_PHARMALIST_GET;
      if (this.props.is_From_IpNotes && this.state.clinic_id) {
        url = `${url}?clinic_id=${this.state.clinic_id}`
      }
      RestAPIService.getAll(url)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              pharmaData: response.data.data,
              pharma_name: response.data.data[0].pharmacy_name,
              pharma_id: response.data.data[0].pharmacy_id
            }, () => {
              if(this.state.pharma_id){
                this.getFrequentDrugInPharma()
              } 
            });
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error.response.data.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }

  _getBrandList = (brand_name) => {
    const { t } = this.props;
    this.setState({
      brandnameList: []
    })
    if (brand_name && this.state.pharma_id) {
      try {
        RestAPIService.getAll(`${Serviceurls.FO_IP_BRANDNAMELIST_GET}?pharmacy_id=${this.state.pharma_id ? this.state.pharma_id : null}&drug_name=${brand_name}`)
          .then(response => {
            if (response.data.status === "success") {
              this.setState({
                brandnameList: response.data.data,
              });
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.responseSuccessErrorMessage(error.response.data.message, false);
            }
          });
      } catch (e) {
        this.responseSuccessErrorMessage(e.message, false);
      }
    }
  }

  _getBrandDetails = () => {
    let states = this.state;
    let list = [];
    this.setState({
      genericList: []
    })
    try {
      RestAPIService.getAll(`${Serviceurls.FO_IP_BRANDDETAILS_GET}?pharmacy_id=${this.state.pharma_id ? this.state.pharma_id : null}&drug_name=${states["mediItem"]["brand_name"] ? states["mediItem"]["brand_name"] : ""}`)
        .then(response => {
          if (response.data.status === "success") {
            list = response.data.data;
            if(list.length === 1){
              let dosage_type = (list[0]['dosage_type']).toUpperCase();
              states["mediItem"]["drug_ms_id"] = list[0]["drug_ms_id"];
              let strength = list[0]['drug_strength'] ? list[0]['drug_strength'] : '';
              let numericAlone = strength.match(/(\d+)/);

              states["mediItem"]["generic_name"] = list[0]["generic_name"];
              states["mediItem"]["dosage_type"] = list[0]["dosage_type"];
              states["mediItem"]["dosage_strength"] = list[0]["drug_strength"];
              states["mediItem"]["stock_in_quantity"] = list[0]["stock_in_quantity"];
              states["tapperItem"]["unit"] = ((list[0]['dosage_type'] && (dosage_type === "STEROID TABLETS")) ? numericAlone[0] : ((dosage_type === "DROP") ? 1 : ""));
              this.setState({
                states,
                tapperUnit_typ: (list[0]['dosage_type'] && (list[0]['dosage_type']).toUpperCase() === "STEROID TABLETS" ? "mg" : "Drop(s)"),
                isTapper: (dosage_type && (dosage_type === "STEROID TABLETS" || dosage_type === "DROP")) ? true : false
              })
            } else{
              this.setState({
                genericList: list
              })
            }
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error.response.data.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }

  getFrequentDrugInPharma = () => {
    this.setState({
      frequentDrugs: []
    })
    try {
      RestAPIService.getAll(`${Serviceurls.FO_IP_FREQUENTDRUG}?pharmacy_id= ${this.state.pharma_id ? this.state.pharma_id : ""}`)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              frequentDrugs: response.data.data,
            });
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error.response.data.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }
  _getIpMedicationDetails = () => {
    this.setState({
      medicationDetails: [],
      current_page: 0,
      rowsPerPage: 10
    })
    let admission_id =  this.state.patientData?.id || this.props?.ip_admission_id || this.state.withOutAppointment?.ip_admission_id;
    let url = `${Serviceurls.IP_MEDICATION_GET}?ip_admission_id=${admission_id}`;
      if (this.props.is_From_IpNotes && this.state.clinic_id) {
        url = `${url}&clinic_id=${this.state.clinic_id}`
    }
    try {
      RestAPIService.getAll(url)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              medicationDetails: response.data.data,
            }, () => {
              this.state.medicationDetails.forEach((item, i) => {
                item["index"] = i
                item["line_items"] = item["line_items"]?.map((list, index)=>({...list, "already_return_quantity" : list?.return_quantity, "return_quantity" : null}))
              })
            }
            );
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error.response.data.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }

  _getHistoryDetails = (id) => {
    if (id) {
      this.setState({
        historyData: [],
      })
      try {
        RestAPIService.getAll(`${Serviceurls.FO_IP_HISTORY}?condition=medication&row_id=${id ? id : null}`)
          .then(response => {
            if (response.data.status === "success") {
              this.setState({
                historyData: response.data.data,
              });
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.responseSuccessErrorMessage(error.response.data.message, false);
            }
          });
      } catch (e) {
        this.responseSuccessErrorMessage(e.message, false);
      }
    }
  }

  responseSuccessErrorMessage(message, type) {
    this.setState({
      showSuccessResponse: message ? type : false,
      showErrorResponse: message ? !type : false,
      responseMessage: message
    })
  }

  // left table start

  _getDetails = (data, basedOn) => {
    let result = "";
    data.length > 0 && data.forEach(element => {
      result = result ? (`${result},  ${element[basedOn]}`) : `${element[basedOn]}`
    });
    return result;
  }

  getTapperList = (data, basedOn) => {
    let result = [...new Map(data.map((item) => [item[basedOn], item])).values()];
    return result;
  }

  renderTblRow = (details, index) => {
    let { fromBillMedication, is_From_IpNotes } = this.props;
    let states = this.state;
    let dateTitle = `${t('Date')} & ${t('Time')}`;
    let brandTitle = `${t('BrandName')} & ${t("GenericName")}`;
    let medicTypTitle = `${t('Medication')} ${t('Type')}`;
    const checkUserAccess = this.props.is_From_IpNotes ? false :
     CheckAccessFunc("front_office", "In Patients","Clinical Notes", "Medication", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_ip : true;
    return (
      <>
        <TableRow key={index} className="eMed_medication_head">
          <TableCell align="center" sx={{ flex: 0.2 }}> {details.created_date} </TableCell>
          <TableCell sx={{ flex: 0.2 }} align="left">{t("BillNo") + ": "}{details.bill_no}</TableCell>
          <TableCell sx={{ flex: 0.2 }} align="left">{t("NoOfDrugs") + ": "}{details.number_of_drugs}</TableCell>
          <TableCell sx={{ flex: 0.2 }} align="left">{t("ReceiveDrug") + ": "}{details.received_drugs}</TableCell>
          <TableCell sx={{ flex: 0.2 }} align="center">
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={3}>
              {(details["is_transmit"] || fromBillMedication )? null :
                <Tooltip title={t("Transmit")} placement='top' arrow>
                  <IconButton emed_tid = {"handleMedicTransmit_TestID001"} disabled={checkUserAccess} size="small" onClick={() => this.handleMedicTransmit(details["medication_summary_id"])}> <img className="eMed_medication_Img" src={this.state.withOutAppointment?.is_ip ? ImagePaths.TransmitOff.default : ImagePaths.medicationTransmitOn.default} alt="transmit" /></IconButton>
                </Tooltip>}
              <div style={{display: (fromBillMedication || is_From_IpNotes) ? "none" : "block"}}>
                <Tooltip title={t("More")} placement='top' arrow><IconButton size="small" disabled = {this.state.withOutAppointment?.is_ip} onClick={(event) => { this.setState({ anchorEl: event.currentTarget, selectedRow: details.index, isSelected: true }) }}><MoreVertIcon /></IconButton></Tooltip>
                {(this.state.isSelected && this.state.selectedRow === details.index) ?
                  <Menu
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={(e) => {
                      this.setState({
                        anchorEl: null
                      })
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '16ch',
                        marginLeft: "-7vw"
                      },
                    }}
                  >
                    {multi_option.map((option) => (
                      <MenuItem emed_tid = {option.label} key={option} disabled={details["bill_no"] === null} onClick={() => { this.setState({ anchorEl: null, selectedOption: option.value, selectedDetails: details ,newSelectedDetails: JSON.stringify(details)}) }}>{t(option.label)}</MenuItem>))}
                  </Menu>
                  : null}
              </div>
              <Tooltip title={t("Details")} placement='top' arrow>
                <IconButton emed_tid = {"_getIpMedicationDetails"} size="small" disabled = {this.state.withOutAppointment?.is_ip} onClick={() => this.setState({ selectedIndex: this.state.selectedIndex === index ? null : index }, () => this._getIpMedicationDetails())}>
                  <img className="eMed_Medication_arrowImg" src={states["selectedIndex"] === index ? ImagePaths.upArrow.default : ImagePaths.downArrow.default} alt="Arrow" />
                </IconButton>
              </Tooltip>
            </Stack>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={this.state.selectedIndex === index} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }} className='eMed_Medication_tableDiv'>
                <Table stickyHeader size='small'>
                  <TableHead className="eMed_medication_head" sx={{ height: "6vh" }}>
                    <TableRow>
                      <TableCell id="eMed_Medication_tableTxt" sx={{ flex: 0.11 }} align="center">{dateTitle.length > 10 ? <Tooltip placement="top" title={dateTitle} arrow>
                        <div>{dateTitle.slice(0, 8) + "..."}</div></Tooltip> : dateTitle}</TableCell>
                      <TableCell id="eMed_Medication_tableTxt" sx={{ flex: 0.21 }} align='left'>{brandTitle.length > 12 ? <Tooltip placement="top" title={brandTitle} arrow>
                        <div>{brandTitle.slice(0, 10) + "..."}</div></Tooltip> : brandTitle}</TableCell>
                      <TableCell id="eMed_Medication_tableTxt" sx={{ flex: 0.11 }} align='left'>{`${t('Route')}`}</TableCell>
                      <TableCell id="eMed_Medication_tableTxt" sx={{ flex: 0.11 }} align='left'>{medicTypTitle.length > 10 ? <Tooltip placement="top" title={medicTypTitle} arrow>
                        <div>{medicTypTitle.slice(0, 8) + "..."}</div></Tooltip> : medicTypTitle}</TableCell>
                      <TableCell id="eMed_Medication_tableTxt" sx={{ flex: 0.12 }} align='left'>{`${t('Duration')}`}</TableCell>
                      <TableCell id="eMed_Medication_tableTxt" sx={{ flex: 0.12 }} align='left'>{`${t('MAEN')}`}</TableCell>
                      <TableCell id="eMed_Medication_tableTxt" sx={{ flex: 0.12 }} align='left'>{`${t('Usage')}`}</TableCell>
                      {(details.bill_no || fromBillMedication) ? null : <TableCell id="eMed_Medication_tableTxt" sx={{ flex: 0.10 }} align="center">{`${t('Action')}`}</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {details.line_items.map((dataRow) => {
                      //generic name;
                      let generic_name = `${dataRow.generic_name ? dataRow.generic_name : "-"} | ${dataRow.drug_strength ? dataRow.drug_strength : "-"}`;
                      //medication type;
                      let medicationTyp = (dataRow["tapper"] && dataRow["tapper"].length > 0) ? "Tapper" : "Regular";
                      // duration;
                      let tapperDuration = dataRow["tapper"].length > 0 ? this.getTapperList(dataRow["tapper"], "duration") : [];
                      let duration = tapperDuration.length > 0 ? this._getDetails(tapperDuration, "duration") : "-";
                      let durationTyp = dataRow["tapper"].length === 0 ? `${dataRow.duration ? dataRow.duration : "-"} ${dataRow.duration_type ? dataRow.duration_type : "-"}` : `${duration ? duration : "-"}`;
                      // MAEN;
                      let tapperMaen = dataRow["tapper"].length > 0 ? this.getTapperList(dataRow["tapper"], "no_of_dosage") : [];
                      let tapperMorning = tapperMaen.length > 0 ? this._getDetails(tapperMaen, "no_of_dosage") : "-";
                      let maen = dataRow["tapper"].length === 0 ? `${dataRow.morning ? dataRow.morning : 0}-${dataRow.afternoon ? dataRow.afternoon : 0}-${dataRow.evening ? dataRow.evening : 0}-${dataRow.night ? dataRow.night : 0}` : `${tapperMorning ? tapperMorning : "-"}`;
                      // Usage;
                      let tapperInstru = dataRow["tapper"].length > 0 ? this.getTapperList(dataRow["tapper"], "frequency") : [];
                      let tapperFreq = tapperInstru.length > 0 ? this._getDetails(tapperInstru, "frequency") : "-";
                      let instructions = dataRow["tapper"].length === 0 ? `${dataRow.instructions ? dataRow.instructions : "-"}` : `${tapperFreq ? tapperFreq : "-"}`;
                      return (
                        <TableRow key={dataRow.created_date} className="eMed_medication_head">
                          <TableCell align="center" sx={{ flex: 0.11 }}> {dataRow.created_date} </TableCell>
                          <TableCell align='left' sx={{ flex: 0.21 }}>
                            <Stack direction={"column"}>
                              <Typography sx={{ color: dataRow["schedule"] ? Colors.BillInProcessTxt : null, fontWeight: 600 }}>{dataRow.brand_name.length > 15 ? <Tooltip placement="top" title={dataRow.brand_name}><Typography sx={{ color: dataRow["schedule"] !== null ? Colors.BillInProcessTxt : null, fontWeight: 600 }}>{dataRow.brand_name.slice(0, 12) + "..."}</Typography></Tooltip> : dataRow.brand_name ? dataRow.brand_name : "-"}</Typography>
                              <div>{generic_name.length > 15 ? <Tooltip placement="top" title={generic_name} arrow><div>{generic_name.slice(0, 12) + "..."}</div></Tooltip> : generic_name ? generic_name : "-"}</div>
                            </Stack>
                          </TableCell>
                          <TableCell align='left' sx={{ flex: 0.11 }}>{<div>{dataRow.route.length > 10 ? <Tooltip placement="top" title={dataRow.route} arrow><div>{dataRow.route.slice(0, 8) + "..."}</div></Tooltip> : dataRow.route ? dataRow.route : "-"}</div>}</TableCell>
                          <TableCell align='left' sx={{ flex: 0.11 }}>{<div>{medicationTyp.length > 10 ? <Tooltip placement="top" title={medicationTyp} arrow><div>{medicationTyp.slice(0, 8) + "..."}</div></Tooltip> : medicationTyp ? medicationTyp : "-"}</div>}</TableCell>
                          <TableCell align='left' sx={{ flex: 0.12 }}>{<div>{durationTyp?.length > 10 ? <Tooltip placement="top" title={durationTyp} arrow><div>{durationTyp.slice(0, 8) + "..."}</div></Tooltip> : durationTyp ? durationTyp : "-"}</div>}</TableCell>
                          <TableCell align='left' sx={{ flex: 0.12 }}>{<div>{maen?.length > 10 ? <Tooltip placement="top" title={maen} arrow><div>{maen.slice(0, 8) + "..."}</div></Tooltip> : maen ? maen : "-"}</div>}</TableCell>
                          <TableCell align='left' sx={{ flex: 0.12 }}>{<div>{instructions?.length > 10 ? <Tooltip placement="top" title={instructions} arrow><div>{instructions.slice(0, 8) + "..."}</div></Tooltip> : instructions ? instructions : "-"}</div>}</TableCell>
                          {(details["bill_no"]  ||  fromBillMedication )? null : <TableCell sx={{ flex: 0.10 }} align="center">
                            <Stack direction={"row"} justifyContent="space-between" alignItems="center">
                              <CommonEditButton disable = {this.state.withOutAppointment?.is_ip} size="1.5vw" onClick={this.handleEdit.bind(this, dataRow, details["pharmacy_name"], details["pharmacy_id"])} />
                              <CommonDeleteButton disable = {this.state.withOutAppointment?.is_ip} size="1.5vw" onClick={this.handleDelete.bind(this, dataRow)} />
                            </Stack>
                          </TableCell>}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  handleMedicTransmit = (id) => {
    if (id) {
      let data = {
        "medication_summary_id": id
      }
      try {
        RestAPIService.patch(data, Serviceurls.IP_MEDICATION_GET)
          .then(response => {
            if (response.data.status === "success") {
              this.responseSuccessErrorMessage(response.data.message, true);
              this._getIpMedicationDetails();
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.responseSuccessErrorMessage(error.response.data.message, false);
            }
          });
      } catch (e) {
        this.responseSuccessErrorMessage(e.message, false);
      }
    }
  }

  MedicPopUpClose = () => {
    this.state.medicationDetails.forEach((item, i) => {
      item["line_items"] = item["line_items"]?.map((list, index)=>({...list, "return_quantity" : null}))
    })
    this.setState({
      selectedOption: "",
      selectedDetails: [],
      newSelectedDetails: [],
      medicationDetails : this.state.medicationDetails
    })
  }

  MedicPopUpClear = () => {
   this.setState({
    selectedDetails: JSON.parse(this.state.newSelectedDetails)
   })
  }

  _showReturnDetails = () => {
    let { selectedDetails } = this.state;
    let date_time = `${selectedDetails.bill_date ? selectedDetails.bill_date : "-"} | ${selectedDetails.invoice_date ? selectedDetails.invoice_date : "-"}`
    return (
      <Stack sx={{ width: "100%" }} direction="column" alignItems="flex-start" justifyContent="center" spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={5} m={1}>
          <Typography>{`${t("Pharmacy")} : `}<span style={{ fontWeight: 600 }}>{selectedDetails.pharmacy_name ? selectedDetails.pharmacy_name : "-"}</span></Typography>
          <Typography>{`${t("BillNo")} : ${selectedDetails.bill_no ? selectedDetails.bill_no : "-"}`}</Typography>
          <Typography>{`${t("BillDate")} : ${date_time ? date_time : "-"}`}</Typography>
        </Stack>
        <TableContainer id = "eMed_medication_rtnTbl" component={Paper} elevation={0} sx={{ backgroundColor: "#FFFF" }}>
          <Table>
            <TableHead stickyHeader size='small' sx={{ height: "4vh", backgroundColor: Colors.DataTblHeaderbg }}>
              <TableRow className="eMed_medication_head">
                <TableCell id = "eMed_medication_headTxt" sx={{ flex: 0.16 }}>{`${t('CreatedBy')}`}</TableCell>
                <TableCell id = "eMed_medication_headTxt" sx={{ flex: 0.18 }}>{`${t('BrandName')} ${t('and')} ${t("GenericName")}`}</TableCell>
                <TableCell id = "eMed_medication_headTxt" sx={{ flex: 0.18 }}>{`${t('Received')}/${t("BilledQuantity")}`}</TableCell>
                <TableCell id = "eMed_medication_headTxt" sx={{ flex: 0.16 }}>{`${t('ReturnQuantity')}`}</TableCell>
                <TableCell id = "eMed_medication_headTxt" sx={{ flex: 0.16 }}>{`${t('Status')}`}</TableCell>
                <TableCell id = "eMed_medication_headTxt" sx={{ flex: 0.16 }}>{`${t('ModifiedBy')}`}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedDetails["line_items"].map((row, index) => {
                let createdBy = `${row.created_by ? row.created_by : "-"} | ${row.created_date ? row.created_date : "-"}`;
                let brand_name = `${row.brand_name ? row.brand_name : "-"} | ${row.generic_name ? row.generic_name : "-"}`;
                let modifiedBy = `${row.modified_by ? row.modified_by : "-"} | ${row.modified_date ? row.modified_date : "-"}`;
                return (
                  <TableRow key={row.created_date}>
                    <TableCell sx={{ flex: 0.16 }}><div>{createdBy.length > 20 ? <Tooltip placement="top" title={createdBy} arrow><div>{createdBy.slice(0, 17) + "..."}</div></Tooltip> : createdBy ? createdBy : "-"}</div></TableCell>
                    <TableCell sx={{ flex: 0.18 }}><Typography sx={{ color: row["schedule"] ? Colors.BillInProcessTxt : null, fontWeight: 600 }}>{brand_name.length > 20 ? <Tooltip placement="top" title={brand_name} arrow>
                      <Typography sx={{ color: row["schedule"]  ? Colors.BillInProcessTxt : null, fontWeight: 600 }}>{brand_name.slice(0, 17) + "..."}</Typography></Tooltip> : brand_name ? brand_name : "-"}</Typography>
                    </TableCell>
                    <TableCell sx={{ flex: 0.18 }}><Typography>{`${row.received_quantity ? row.received_quantity : ""} / ${row.quantity ? row.quantity : ""}`}</Typography></TableCell>
                    <TableCell sx={{ flex: 0.16, }}>
                      <Box sx={{ display: 'flex' }}>
                        <TextField
                          name="return_quantity"
                          value={row["return_quantity"] ? row["return_quantity"] : ""}
                          size="small"
                          autoComplete="off"
                          disabled={!selectedDetails["line_items"][index]["drug_ms_id"]}
                          onChange={(e) => {
                            let value = e.target.value;
                            let isValid = CommonValidation.numberOnly(value);
                            if (value === "" || (isValid && value > 0 && parseInt(value) <= parseInt(row.received_quantity))) {
                              selectedDetails["line_items"][index]["return_quantity"] = value;
                              this.setState({
                                selectedDetails
                              })
                            }
                          }}
                          sx={{ width: "5vw" }}
                        />
                        <TextField
                          name="return_quantity"
                          value={row["already_return_quantity"] ? row["already_return_quantity"] : ""}
                          size="small"
                          autoComplete="off"
                          disabled={true}
                          sx={{ width: "5vw" }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell sx={{ flex: 0.16 }}>{<div>{row.status.length > 20 ? <Tooltip placement="top" title={row.status} arrow><div>{row.status.slice(0, 18) + "..."}</div></Tooltip> : row.status ? row.status : "-"}</div>}</TableCell>
                    <TableCell sx={{ flex: 0.16 }}>{<div>{modifiedBy.length > 20 ? <Tooltip placement="top" title={modifiedBy} arrow><div>{modifiedBy.slice(0, 18) + "..."}</div></Tooltip> : modifiedBy ? modifiedBy : "-"}</div>}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    )
  }

  handleMedicSave = () => {
    const { t } = this.props;
    let { selectedDetails } = this.state;
    let isAvl = true;
    let Message = "";
    let isReturnAvl = selectedDetails["line_items"].every(item => (item["return_quantity"] === 0 || item["return_quantity"] === null));
    if (isReturnAvl) { isAvl = false; Message = `${t("Enter")} ${t("ReturnQuantity")}` }
    if (isAvl) {
      // api call
      let returnList = selectedDetails["line_items"].filter(item => item["return_quantity"] !== 0 || item["return_quantity"] !== null);
      let data = {
        "is_return": true,
        "return_detail": []
      }
      returnList.forEach(item => {
        let temp = {
          "line_item_id": item["line_item_id"],
          "return_quantity": +item["return_quantity"]
        }
        data["return_detail"].push(temp)
      });
      try {
        RestAPIService.updateWithOutId(data, Serviceurls.IP_MEDICATION_GET)
          .then(response => {
            if (response.data.status === "success") {
              this.MedicPopUpClose();
              this.responseSuccessErrorMessage(response.data.message, true);
              this._getIpMedicationDetails();
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.responseSuccessErrorMessage(error.response.data.message, false);
            }
          });
      } catch (e) {
        this.responseSuccessErrorMessage(e.message, false);
      }
    } else {
      this.responseSuccessErrorMessage(Message, false);
    }
  }

  // left table end

  handleDuration = (statekey, value) => {
    let states = this.state;
    let isValid = false;

    if (!(this.state.isTapper) && (statekey !== "tapperDuration" || statekey !== "tapperDuration_mode")) {
       if(states[statekey] === value){
        states[statekey] = ""
        this.setState({states})
       }
       else{
        states[statekey] = value;
        this.setState({states})
       }
    }

    if (statekey === "tapperDuration") {
      isValid = CommonValidation.numberOnly(value);
      if (value === "" || (isValid && value.length <= 2 && value <= 31)) {
        states["tapperDuration"] = value;
      }
    }
    if (statekey === "tapperDuration_mode") {
      states["tapperDuration_mode"] = value;
    }

    let qty = Math.round(states["morning"]) + Math.round(states["afternoon"]) + Math.round(states["evening"]) + Math.round(states["night"]);
    let duration_mode = this.state.isTapper ? states["tapperDuration_mode"] : states["duration_mode"];
    let duration = this.state.isTapper ? states["tapperDuration"] : states["duration"];

    switch (duration_mode) {
      case "Week(s)":
        states["quantity"] = +(qty ? qty : 1) * +(duration) * 7;
        break;
      case "Month(s)":
        states["quantity"] = +(qty ? qty : 1) * +(duration) * 30;
        break;
      default:
        states["quantity"] = +(qty ? qty : 1) * +(duration) * 1;
    }
    this.setState({
      states
    })
  }

  handleChipClk = (value) => {
    let states = this.state;
    if(value.drug_name){
      states["mediItem"]["brand_name"] = value?.drug_name;
      states["mediItem"]["drug_ms_id"] = "";
      states["mediItem"]["generic_name"] = "";
      states["mediItem"]["dosage_type"] = "";
      states["mediItem"]["dosage_strength"] = "";
      this.setState({
        states,
        genericList: []
      }, () => {
        this._getBrandDetails();
      })
    }
  }

  historyPopUpClose = () => {
    this.setState({
      isHistoryClicked: false,
      historyData: []
    })
  }

  _showHistory(historydata) {
    const { t } = this.props;
    const historyColumn = [
      {
        field: 'modified_by_from', headerName: t('ModifiedBy'), sortable: false, hideable: false, width: 200, renderCell: ({ row }) => {
          return (<div>{row?.modified_by_from?.length > 20 ? <Tooltip placement="top" title={row?.modified_by_from} arrow><div>{row?.modified_by_from.slice(0, 15) + "..."}</div></Tooltip> : row?.modified_by_from ? row?.modified_by_from : "-"}</div>)
        }
      },
      {
        field: 'modified_date', headerAlign: "center", headerName: t('ModifiedDateAndTime'), sortable: false, hideable: false, width: 250, align: "center", renderCell: ({ row }) => {
          return (<Typography>{row?.modified_date ? row?.modified_date : "-"}</Typography>)
        }
      },
      {
        field: 'dosage_type_from', headerName: `${t('Previous')}  ${t('DosageType')}`, width: 250, sortable: false, hideable: false, renderCell: ({ row }) => {
          return (<div>{row?.dosage_type_from?.length > 20 ? <Tooltip placement="top" title={row?.dosage_type_from} arrow><div>{row?.dosage_type_from.slice(0, 15) + "..."}</div></Tooltip> : row?.dosage_type_from ? row?.dosage_type_from : "-"}</div>)
        }
      },
      {
        field: 'route_from', headerName: `${t('Previous')}  ${t('Route')}`, width: 200, sortable: false, hideable: false, renderCell: ({ row }) => {
          return (<div>{row?.route_from?.length > 20 ? <Tooltip placement="top" title={row?.route_from} arrow><div>{row?.route_from.slice(0, 15) + "..."}</div></Tooltip> : row?.route_from ? row?.route_from : "-"}</div>)
        }
      },
      {
        field: 'instructions_from', headerName: `${t('Previous')}  ${t('Usage')}`, width: 200, sortable: false, hideable: false, renderCell: ({ row }) => {
          return (<div>{row?.instructions_from?.length > 20 ? <Tooltip placement="top" title={row?.instructions_from} arrow><div>{row?.instructions_from.slice(0, 15) + "..."}</div></Tooltip> : row?.instructions_from ? row?.instructions_from : "-"}</div>)
        }
      },
      {
        field: 'duration_from', headerAlign: "center", headerName: `${t('Previous')}  ${t('Duration')}`, width: 200, align: "center", sortable: false, hideable: false, renderCell: ({ row }) => {
          return (<div>{row?.duration_from?.length > 20 ? <Tooltip placement="top" title={row?.duration_from} arrow><div>{row?.duration_from.slice(0, 15) + "..."}</div></Tooltip> : row?.duration_from ? row?.duration_from : "-"}</div>)
        }
      },
      {
        field: 'previous_maen', headerName: `${t('Previous')}  ${t('MAEN')}`, width: 200, sortable: false, hideable: false, renderCell: ({ row }) => {
          let Previous_maen = `${row.morning_from ? row.morning_from : 0}-${row.afternoon_from ? row.afternoon_from : 0}-${row.evening_from ? row.evening_from : 0}-${row.night_from ? row.night_from : 0}`
          return (<div>{Previous_maen?.length > 20 ? <Tooltip placement="top" title={Previous_maen} arrow><div>{Previous_maen.slice(0, 15) + "..."}</div></Tooltip> : Previous_maen !== "0-0-0-0" ? Previous_maen : "-"}</div>)
        }
      }];
    return (
      <Box id="eMed_ipsrvConfig_accTbl">
        <DataGrid
          rows={historydata ? historydata : []}
          columns={historyColumn}
          getRowId={(row) => row.modified_date}
          disableColumnMenu
          hideFooter
          components={{
            NoRowsOverlay: () => {
              return (
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "40vh" }}>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }
          }}
          headerHeight={40}
        />
      </Box>
    )
  }

  onChangeHandler = (statekey, value) => {
    let states = this.state;
    let { tapperItem } = this.state;
    let valueTrimed = value.trimStart();
    let isValid;
    switch (statekey) {
      case "tapperUnit_typ":
        states["tapperUnit_typ"] = valueTrimed;
        break;
      case "tapperReduced_typ":
        states["tapperReduced_typ"] = valueTrimed;
        break;
      default:
        isValid = CommonValidation.NumberWithDot(valueTrimed);
        if (valueTrimed === "" || isValid) {
          tapperItem[statekey] = valueTrimed;
        }
        break;
    }
    this.setState({
      tapperItem,
      states
    })
  }

  handleEdit = (value, pharmacy_name, pharmacy_id) => {
    let states = this.state;
    if(value.brand_name){
      states["mediItem"]["line_item_id"] = value["line_item_id"];
      if (value.drug_ms_id) {
        states["ExternalDrug"] = false;
        states["mediItem"]["brand_name"] = value?.brand_name;
        states["mediItem"]["drug_ms_id"] = value?.drug_ms_id;
        states["mediItem"]["generic_name"] = value?.generic_name;
        states["mediItem"]["dosage_type"] = value?.dosag_type;
        states["mediItem"]["dosage_strength"] = value?.drug_strength;
        if (IP_routeList.find((item) => (item?.value === value["route"]))) {
          states["mediItem"]["route"] = value["route"];
        } else {
          states["mediItem"]["route"] = "Others";
          states["mediRouteOther"] = value["route"];
        }
      } else {
        states["ExternalDrug"] = true;
        states["externalBrandName"] = value.brand_name;
        states["externalGenName"] = value.generic_name;
        states["externalDosageType"] = value.dosag_type;
        states["externalDosStrength"] = value.drug_strength;
        states["externalRoute"] = value.route;
        if (IP_routeList.find((item) => (item?.value === value["route"]))) {
          states["externalRoute"] = value["route"];
        } else {
          states["externalRoute"] = "Others";
          states["externalRouteOther"] = value["route"];
        }
      }
      states["duration"] = value.duration ? value.duration : null;
      states["morning"] = value.morning;
      states["afternoon"] = value.afternoon;
      states["evening"] = value.evening;
      states["night"] = value.night;
      states["duration_mode"] = value.duration_type;
      states["quantity"] = value.quantity;
      states["instruction"] = value.instructions;
      states["pharma_id"] = pharmacy_id;
      states["pharma_name"] = pharmacy_name;
      states["medication_id"] = value.id;
      states["isEdit"] = true;
      states["tapperDetails"] = value.tapper;
      

      this.setState({
        states,
        isTapper: Object.values(value.tapper).length > 0,
        genericList: []
      }, () => {
        this._getBrandDetails();
      })
    }}

  handleDelete = (value) => {
    if (!(this.state.isEdit)) {
      this.setState({
        deletedPopup: true,
        brand_name: value.brand_name,
        medication_id: value.line_item_id
      })
    }
  }

  handleClear = () => {
    this.setState({
      pharma_name: "",
      pharma_id: null,
      mediItem: {},
      tapperItem: {},
      open: false,
      duration_mode: "Day(s)",
      duration: null,
      isTapper: false,
      instruction: "",
      tapperDuration_mode: "Day(s)",
      tapperDuration: "",
      tapperStartDate: today,
      tapperUnit_typ: "",
      tapperReduced_typ: "mg",
      tapperDetails: [],
      selectedEditIndex: null,
      tapperFrequency: "",
      focusError: "",
      isEdit: false,
      morning: 0,
      afternoon: 0,
      evening: 0,
      night: 0,
      quantity: null,
      ExternalDrug : "",
      externalBrandName : "",
      externalGenName : "",
      externalDosageType : "",
      externalDosStrength : "",
      externalRoute : "",
    }, ()=>{
      this._getPharmaList()
    })
  }

  handleAdd = () => {
    const { t } = this.props;
    let states = this.state;
    let { mediItem } = this.state;
    let isAvl = true;
    let Message = "";
    let MAEN = (states["morning"] == 0 && states["afternoon"] == 0 && states["evening"] == 0 && states["night"] == 0)
    if (!states["pharma_name"] && !mediItem["drug_ms"] && (!mediItem["route"] || (mediItem["route"] === "Others" && !states["mediRouteOther"]) ) && MAEN && !states["duration"] && !states["instruction"]) { isAvl = false; Message = t("Fillmandatory") }
    else if (!states["pharma_name"]) { isAvl = false; Message = t("SelectPharmacy") }
    else if (!mediItem["drug_ms_id"] && !states.ExternalDrug) { isAvl = false; Message = `${t("Enter")} ${t("BrandName")}` }
    // else if (!mediItem["route"]) { isAvl = false; Message = `${t("Enter")} ${t("Route")}` }
    else if (states["isTapper"] === false && MAEN) { isAvl = false; Message = `${t("Enter")} ${t("MAEN")}` }
    else if (states["isTapper"] === false && !states["duration"]) { isAvl = false; Message = `${t("Enter")} ${t("Duration")}` }
    else if (states["isTapper"] === false && !states["instruction"]) { isAvl = false; Message = `${t("Enter")} ${t("Instruction")}` }
    else if (states["isTapper"]) {
      if (!states["tapperDetails"].length === 0) { isAvl = false; Message = `${t("Enter")} ${t("TapperDetails")}` }
    }
    else if (states.ExternalDrug && (!states["externalBrandName"] || !states["externalGenName"] || !states["externalDosageType"] || !states["externalDosStrength"] || (!states["externalRoute"] || (states["externalRoute"] === "Others" && !states["externalRouteOther"])))) {
      if (!states["externalBrandName"]) { isAvl = false; Message = `${t("Enter")} ${t("Brand Name")}` }
      else if(!states["externalGenName"]) { isAvl = false; Message = `${t("Enter")} ${t("Generic Name")}` }
      else if(!states["externalDosageType"]) { isAvl = false; Message = `${t("Select")} ${t("Dosage Type")}` }
      else if(!states["externalDosStrength"]) { isAvl = false; Message = `${t("Enter")} ${t("Dosage Strength")}` }
      else if(!states["externalRoute"]) { isAvl = false; Message = `${t("Select")} ${t("Route")}` }
      else if(states["externalRoute"] === "Others" && !states["externalRouteOther"]) { isAvl = false; Message = `${t("Select")} ${t("Route")}` }
    }
    if (isAvl) {
      // api call
      let datas = {}
      let method = ""
      if (mediItem["line_item_id"] && states["isEdit"]) {
        method = "updateWithOutId"
        datas = {
          "line_item_id": mediItem["line_item_id"] ? mediItem["line_item_id"] : null,
          "drug_ms_id": mediItem["drug_ms_id"] ? mediItem["drug_ms_id"] : null,
          "morning": states["morning"] && (!states["isTapper"]) ? +(states["morning"]) : 0,
          "afternoon": states["afternoon"] && (!states["isTapper"]) ? +(states["afternoon"]) : 0,
          "evening": states["evening"] && (!states["isTapper"]) ? +(states["evening"]) : 0,
          "night": states["night"] && (!states["isTapper"]) ? +(states["night"]) : 0,
          "route": states?.ExternalDrug ? states["externalRoute"] ? (states["externalRoute"] === "Others" ? states["externalRouteOther"] : states["externalRoute"]) : "" : mediItem["route"] ? (mediItem["route"] === "Others" ? states["mediRouteOther"] :mediItem["route"]) : "",
          "duration": states["duration"] && (!states["isTapper"]) ? +(states["duration"]) : 0,
          "duration_type": states["duration_mode"] && (!states["isTapper"]) ? states["duration_mode"] : states["isTapper"] ? "" : "Day(s)",
          "quantity": states["quantity"] ? +(states["quantity"]) : 0,
          "instructions": states["instruction"] && (!states["isTapper"]) ? states["instruction"] : "",
          "tapper": states["tapperDetails"].length > 0 && states["isTapper"] ? states["tapperDetails"] : [],
          "drug_name": states["externalBrandName"] ? states["externalBrandName"] : "",
          "generic_name": states["externalGenName"] ? states["externalGenName"] : "",
          "dosage_type": states["externalDosageType"] ? states["externalDosageType"] : "",
          "dosage_strength": states["externalDosStrength"] ? states["externalDosStrength"] : "",
        }
      } else {
        method = "create"
        datas = {
          "pharmacy_id": states["pharma_id"] ? states["pharma_id"] : null,
          "ip_admission_id": states["patientData"]["id"] ? states["patientData"]["id"] : null,
          "line_items": {
            "is_tapper": states["isTapper"],
            "drug_ms": mediItem["drug_ms_id"] ? mediItem["drug_ms_id"] : null,
            "morning": states["morning"] && (!states["isTapper"]) ? +(states["morning"]) : 0,
            "afternoon": states["afternoon"] && (!states["isTapper"]) ? +(states["afternoon"]) : 0,
            "evening": states["evening"] && (!states["isTapper"]) ? +(states["evening"]) : 0,
            "night": states["night"] && (!states["isTapper"]) ? +(states["night"]) : 0,
            "route": states?.ExternalDrug ? states["externalRoute"] ? (states["externalRoute"] === "Others" ? states["externalRouteOther"] : states["externalRoute"]) : "" : mediItem["route"] ? (mediItem["route"] === "Others" ? states["mediRouteOther"] :mediItem["route"]) : "",
            "duration": states["duration"] && (!states["isTapper"]) ? +(states["duration"]) : 0,
            "duration_type": states["duration_mode"] && (!states["isTapper"]) ? states["duration_mode"] : states["isTapper"] ? "" : "Day(s)",
            "quantity": states["quantity"] ? +(states["quantity"]) : 0,
            "instructions": states["instruction"] && (!states["isTapper"]) ? states["instruction"] : "",
            "tapper": states["tapperDetails"].length > 0 && states["isTapper"] ? states["tapperDetails"] : [],
            "drug_name": states["externalBrandName"] ? states["externalBrandName"] : "",
            "generic_name": states["externalGenName"] ? states["externalGenName"] : "",
            "dosage_type": states["externalDosageType"] ? states["externalDosageType"] : "",
            "dosage_strength": states["externalDosStrength"] ? states["externalDosStrength"] : "",
          }
        }
      }
      try {
        this.LoaderFunction(true)
        RestAPIService[method](datas, Serviceurls.IP_MEDICATION_GET)
          .then(response => {
            if (response.data.status === "success") {
              this.handleClear();
              this._getIpMedicationDetails();
              this.LoaderFunction(false)
              this.setState({isAdd: false})
              this.responseSuccessErrorMessage(response.data.message, true);
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.LoaderFunction(false)
              this.setState({isAdd: false})
              this.responseSuccessErrorMessage(error.response.data.message, false);
            }
          });
      } catch (e) {
        this.setState({isAdd: false})
        this.responseSuccessErrorMessage(e.message, false);
      }
    } else {
      this.setState({isAdd: false})
      this.responseSuccessErrorMessage(Message, false)
    }
  }

  PopupClose = () => {
    this.setState({
      deletedPopup: false,
      brand_name: "",
      medication_id: null
    })
  }

  removeDataList = () => {
    let { medication_id } = this.state;
    try {
      RestAPIService.delete(`${Serviceurls.IP_MEDICATION_GET}?line_item_id=${medication_id ? medication_id : null}`)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              deletedPopup: false,
              brand_name: "",
              medication_id: null
            }, () => {
              this.responseSuccessErrorMessage(response.data.message, true);
              this._getIpMedicationDetails();
            })
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error.response.data.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }
  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value.trimStart();
    let isValid = false;
    let states = this.state;
    switch (name) {
      case "quantity":
        isValid = CommonValidation.NumberWithDot(value);
        if (value === "" || isValid) {
          states["quantity"] = value;
        }
        break;
      default:
        states[name] = value;
        break;
    }
    this.setState({
      states
    })
  }

  DisableRouteOption = (option, IP_routeList) => {
    let Dosage_Type = this.state.mediItem.dosage_type
    if (Dosage_Type === "Ampules" || Dosage_Type === "Injection") {
      return (
        option === IP_routeList[3] || option === IP_routeList[4] || option === IP_routeList[5] || option === IP_routeList[6] || option === IP_routeList[7] || option === IP_routeList[8] || option === IP_routeList[9] || option === IP_routeList[10] || option === IP_routeList[11] || option === IP_routeList[12]
      )
    } else if (Dosage_Type === "Tablet") {
      return (
        option === IP_routeList[0] || option === IP_routeList[3] || option === IP_routeList[4] || option === IP_routeList[5] || option === IP_routeList[6] || option === IP_routeList[9] || option === IP_routeList[1] || option === IP_routeList[2] || option === IP_routeList[10] || option === IP_routeList[11] || option === IP_routeList[12]
      )
    } else if (Dosage_Type === "Steroid Tablets" || Dosage_Type === "Capsule Sr" || Dosage_Type === "Syrup" || Dosage_Type === "Biscuit") {
      return (
        option === IP_routeList[0] || option === IP_routeList[3] || option === IP_routeList[4] || option === IP_routeList[5] || option === IP_routeList[6] || option === IP_routeList[8] || option === IP_routeList[1] || option === IP_routeList[2] || option === IP_routeList[9] || option === IP_routeList[10] || option === IP_routeList[11] || option === IP_routeList[12]
      )
    } else if (Dosage_Type === "Capsule") {
      return (
        option === IP_routeList[0] || option === IP_routeList[1] || option === IP_routeList[2] || option === IP_routeList[3] || option === IP_routeList[8] || option === IP_routeList[9] || option === IP_routeList[6] || option === IP_routeList[10] || option === IP_routeList[11] || option === IP_routeList[12]
      )
    }
  }

  handleFocus = () => {
    const { t } = this.props;
    let { pharma_name } = this.state;
    if (pharma_name === "") {
      this.setState({ focusError: "Select Pharmacy" });
      this.responseSuccessErrorMessage(t("SelectPharmacy"), false);
    }
  }
  renderFreeTextField(label, statekey, value, width) {
    const { t } = this.props;
    let states = this.state;
    return (
      statekey === "externalDosageType" ?
        <Autocomplete
          disableClearable
          value={value ? states[value] : ""}
          sx={{ width: width ? width : "13.4vw" }}
          size="small"
          options={Medication_dosages}
          getOptionLabel={option => typeof (option) === "string" ? option : option.label}
          onChange={(event, newRoute) => {
            states["externalDosageType"] = newRoute.label
            this.setState({
              states
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(label)}
              inputProps={{...params.inputProps,'emed_tid': label, }}
              autoComplete="off"
              size="small"
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        /> :
      statekey === "externalRoute" ?
        <Autocomplete
          disableClearable
          value={value ? states[value] : ""}
          sx={{ width: width ? width : "13.4vw" }}
          size="small"
          options={IP_routeList}
          getOptionLabel={option => typeof (option) === "string" ? option : option.value}
          onChange={(event, newRoute) => {
            states["externalRoute"] = newRoute.value
            states["externalRouteOther"] = ""
            this.setState({
              states
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(label)}
              autoComplete="off"
              size="small"
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
        :
        <TextField
          sx={{ width: width ? width : "10vw" }}
          size="small"
          autoComplete="off"
          label={t(label)}
          inputProps={{ emed_tid: `cb_${label}` }}
          name={statekey}
          value={value ? states[value] : ""}
          onChange={this.handleChange}
        />
    )
  }
  renderTextBox(label, statekey, value, width) {
    const { t } = this.props;
    let states = this.state;
    return (
      <div id="eMed_srvConfig_divs">
        {statekey === "pharma_name" ?
          <Autocomplete
            disableClearable
            value={value ? value : ""}
            sx={{ width: width ? width : "13.4vw", fontSize: "0.9vw", marginRight: "0.5vw" }}
            size="small"
            options={states.pharmaData.length != 0 ? states["pharmaData"] : []}
            getOptionLabel={option => typeof (option) === "string" ? option : option.pharmacy_name}
            onChange={(event, newPharma) => {
              this.setState({
                pharma_name: newPharma["pharmacy_name"],
                pharma_id: newPharma["pharmacy_id"],
                focusError: "",
              })
            }}
            //  defaultValue={states.pharmaData.length != 0 ? states.pharmaData[0].pharmacy_name : value ? value : ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${t(label)} (${states["pharmaData"].length}) *`}
                autoComplete="off"
                size="small"
                inputProps={{...params.inputProps,'emed_tid':  label, }}
                InputProps={{
                  ...params.InputProps,
                }}
                error={value === "" && states["focusError"].length > 0}
              />
            )}
          />
          : statekey === "brand_name" ?
            <Autocomplete
              disableClearable
              value={value ? value : ""}
              sx={{ width: width ? width : "13.4vw" }}
              size="small"
              options={states.brandnameList.length != 0 ? states["brandnameList"] : []}
              getOptionLabel={option => typeof (option) === "string" ? option : option}
              onChange={(event, newbrand) => {
                states["mediItem"]["brand_name"] = newbrand;
                this.setState({
                  states
                }, () => {
                  this._getBrandDetails();
                })
              }}
              onInputChange={(event, newBrand) => {
                let previousBrand_name = JSON.stringify(states["mediItem"]["brand_name"]);
                if(states["mediItem"]["brand_name"] !== newBrand){
                  states["mediItem"]["brand_name"] = ''
                };
                if (previousBrand_name != JSON.stringify(newBrand)) {
                  states["mediItem"]["drug_ms_id"] = null;
                  states["mediItem"]["generic_name"] = "";
                  states["mediItem"]["dosage_type"] = "";
                  states["mediItem"]["dosage_strength"] = "";
                  states["tapperItem"]["unit"] = "";
                  states["tapperUnit_typ"] = "";
                  states["tapperReduced_typ"] = "mg";
                  states["tapperDetails"] = "";
                  states["isTapper"]= false;
                }
                this.setState({
                  states,
                  genericList : previousBrand_name != JSON.stringify(newBrand) ? [] : this.state.genericList
                })
                this._getBrandList(newBrand);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(label) + "*"}
                  autoComplete="off"
                  size="small"
                  inputProps={{...params.inputProps,'emed_tid': label,}}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  onFocus={this.handleFocus}
                />
              )}
            />
            : statekey === "generic_name" ?
              <Autocomplete
                disableClearable
                value={value ? value : ""}
                sx={{ width: width ? width : "13.4vw" }}
                size="small"
                options={states.genericList.length != 0 ? states["genericList"] : []}
                getOptionLabel={option => typeof (option) === "string" ? option : option.display_name}
                onChange={(event, newGeneric) => {
                  let strength = newGeneric["drug_strength"] ? newGeneric["drug_strength"] : '';
                  let numericAlone = strength.match(/(\d+)/);
                  let dosage_type = (newGeneric['dosage_type']).toUpperCase();
                  states["mediItem"]["drug_ms_id"] = newGeneric["drug_ms_id"];
                  states["mediItem"]["generic_name"] = newGeneric["generic_name"];
                  states["mediItem"]["dosage_type"] = newGeneric["dosage_type"];
                  states["mediItem"]["dosage_strength"] = newGeneric["drug_strength"];
                  states["mediItem"]["stock_in_quantity"] = newGeneric["stock_in_quantity"];
                  states["tapperItem"]["unit"] = (newGeneric["dosage_type"] && (dosage_type === "STEROID TABLETS") ? numericAlone[0] : (dosage_type=== "DROP") ? 1 : "");
                  states["tapperUnit_typ"] = (newGeneric['dosage_type'] && (dosage_type === "STEROID TABLETS") ? "mg" : "Drop(s)");
                  states["isTapper"] = (newGeneric['dosage_type'] && (dosage_type === "STEROID TABLETS" || dosage_type === "DROP")) ? true : false;
                  this.setState({
                    states
                  })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(label)}
                    autoComplete="off"
                    size="small"
                    inputProps={{...params.inputProps,'emed_tid': label, }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    onFocus={this.handleFocus}
                  />
                )}
              />
              : statekey === "route" ?
                <Autocomplete
                  disableClearable
                  value={value ? value : ""}
                  sx={{ width: width ? width : "13.4vw" }}
                  size="small"
                  options={(IP_routeList.length != 0 && this.state.pharma_name !== "") ? IP_routeList : []}
                  getOptionDisabled={
                    (option) =>  this.DisableRouteOption(option, IP_routeList)
                  }
                  getOptionLabel={option => typeof (option) === "string" ? option : option.value}
                  onChange={(event, newRoute) => {
                    states["mediItem"]["route"] = newRoute.value
                    states["mediRouteOther"] = ""
                    this.setState({
                      states
                    })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(label)}
                      autoComplete="off"
                      size="small"
                      inputProps={{...params.inputProps,'emed_tid': label, }}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      onFocus={this.handleFocus}
                    />
                  )}
                />
                : statekey === "duration_mode" ?
                  <FormControl sx={{ width: width ? width : "10vw", marginRight: "0.5vw" }} size="small">
                    <Select
                      value={value ? value : "Day(s)"}
                      inputProps={{ emed_tid: `cb_${label}` }}
                      name={statekey}
                      label={t(label)}
                      onChange={(event) => {
                        if (!this.state.isTapper) {
                          this.handleDuration("duration_mode", event.target.value)
                        }
                      }}
                      sx={{ fontSize: "0.9vw" }}
                    >
                      {
                        DurationsList.map((list, index) => (
                          <MenuItem key={index} value={list.label} sx={{ fontSize: "0.8vw" }} >{list.label}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                  :
                  <TextField
                    sx={{ width: width ? width : "10vw" }}
                    size="small"
                    autoComplete="off"
                    label={t(label)}
                    inputProps={{ emed_tid: `cb_${label}` }}
                    name={statekey}
                    value={value ? value : ""}
                    InputProps={{
                      readOnly: (statekey === "dosage_type" || statekey === "dosage_strength" || (statekey === "quantity" && !(this.state.isTapper)))
                    }}
                    onChange={this.handleChange}
                  />}
      </div>
    )
  }

  renderInstruction = (image, statekey) => {
    const { t } = this.props;
    let { mediItem, pharma_name } = this.state;
    let disable = (pharma_name === "" || ((mediItem["drug_ms_id"] !== "") && (mediItem["dosage_type"]) === 'Steroid Tablets' || (mediItem["dosage_type"]) === "Drop")|| this.state.withOutAppointment?.is_ip);
    return (
      <IconButton emed_tid = {"dosage_type_TestID"}  size="small" disabled={disable} onClick={() => this.setState({ instruction: statekey })}>
        <img className="eMed_medication_instruImg" src={image} />
      </IconButton>
    );
  }

  renderMaen = (label, statekey) => {
    const { t } = this.props;
    let { mediItem, pharma_name } = this.state;
    let disable = (pharma_name === "" || ((mediItem["drug_ms_id"] !== "") && (mediItem["dosage_type"]) === 'Steroid Tablets' || (mediItem["dosage_type"]) === "Drop"));
    return (
      <Stack className="eMed_medication_tapperSubDiv" direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
        <Typography id="eMed_medication_maenChipTxt">{t(label)}</Typography>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
          {MaenList && MaenList.length > 0 ? MaenList.map((item, index) => {
            let selectedValue = (statekey === "morning" && item.value === this.state.morning || statekey === "afternoon" && item.value === this.state.afternoon || statekey === "evening" && item.value === this.state.evening || statekey === "night" && item.value === this.state.night)
            return (
              <Box
                key={index}
                sx={{ backgroundColor: (selectedValue && !disable) ? Colors.themeDark : "#FAF9F9", color: (selectedValue && !disable) ? Colors.white : Colors.black, borderColor: (selectedValue && !disable) ? Colors.themeDark : Colors.lightGray }}
                id="eMed_medication_maenChip"  emed_tid = {"dosage_type_TestID"}  onClick={() => !disable ? this.handleDuration(statekey, item.value) : null}>{item.label}</Box>
            )
          }) : null}
        </Stack>
      </Stack>
    )
  }
  renderTextBoxs = (label1, statekey1, value1, width, statekey2, value2, width1) => {
    const { t } = this.props;
    let states = this.state;
    const currentDate = new Date();
    const admissionDate = states?.patientData?.admitted_date
      ? new Date(states.patientData?.admitted_date)
      : states?.patientData?.admission_date
        ? new Date(states.patientData?.admission_date)
        : currentDate;

    return (
      <div>
        {
          statekey1 === "tapperStartDate" ?
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // open={states["open"]}
                // onOpen={() => { this.setState({ open: true }) }}
                // onClose={() => { this.setState({ open: false }) }}
                inputFormat="dd-MM-yyyy"
                minDate={admissionDate}
                label={label1 ? t(label1) + "*" : ""}

                name={statekey1}
                value={value1 ? value1 : null}
                autoComplete="off"
                onChange={(value) => {
                  states["tapperStartDate"] = value;
                  this.setState({
                    states
                  })
                }
                }
                renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} size="small" sx={{ width: width ? width : "13.4vw", margin: "0.2vw" }} {...params}  inputProps={{...params.inputProps,'emed_tid': label1, }} onClick={() => { this.setState({ open: true }) }} />}
              />
            </LocalizationProvider>
            : statekey1 === "tapperFrequency" ?
              <FormControl sx={{ width: width ? width : "13.2vw" }} size="small">
                <InputLabel>{t(label1)}</InputLabel>
                <Select
                  value={value1 ? value1 : ""}
                  inputProps={{ emed_tid: `cb_${label1}`}}
                  name={statekey1}
                  label={t(label1)}
                  onChange={(event) => { this.setState({ tapperFrequency: event.target.value }) }}
                  sx={{ fontSize: "0.9vw" }}
                >
                  {
                    Taper_Med_Frequency.map((list, index) => (
                      <MenuItem key={index} value={list.value} sx={{ fontSize: "0.8vw" }} >{list.label}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              : <Box component={'div'} className=" eMed_medication_txtbox">
                <TextField
                  sx={{ width: width ? width : "10vw" }}
                  size='small'
                  autoComplete="off"
                  className='eMed_temp_txt'
                  label={label1 ? t(label1) : ""}
                  name={statekey1}
                  inputProps={{ emed_tid: `cb_${label1}` }}
                  value={value1 ? value1 : ""}
                  onChange={(e) => statekey1 === "tapperDuration" ? this.handleDuration(statekey1, e.target.value) : this.onChangeHandler(statekey1, e.target.value)}
                />
                {(statekey2 === "tapperUnit_typ" || statekey2 === "tapperReduced_typ") ? <TextField
                  sx={{ width: width1 ? width1 : "10vw" }}
                  size='small'
                  autoComplete="off"
                  className='eMed_temp_txt'
                  name={statekey2}
                  value={value2 ? value2 : ""}
                  InputProps={{
                    readOnly: true
                  }}
                // onChange={(e) => this.onChangeHandler(statekey2, e.target.value)}
                /> :
                  <FormControl sx={{ width: width1 ? width1 : "3.4vw" }} size='small'>
                    <Select
                      name={statekey2}
                      value={value2 ? value2 : ""}
                      onChange={(e) => { statekey2 === "tapperDuration_mode" ? this.handleDuration(statekey2, e.target.value) : this.onChangeHandler(statekey2, e.target.value) }}
                      inputProps={{ emed_tid: `cb_${"tapperDuration_mode_TestID"}` }}
                      sx={{ fontSize: "0.95vw" }}
                    >
                      {statekey2 === "tapperDuration_mode" ?
                        DurationsList.map((list, index) => (
                          <MenuItem key={index} value={list.label} sx={{ fontSize: "0.75vw" }} >{list.label}</MenuItem>
                        )) : null
                      }
                    </Select>
                  </FormControl>
                }
              </Box>}
      </div>
    )
  }

  handleTapperAdd = () => {
    const { t } = this.props;
    let states = this.state;
    let Message = "";
    let tapperDosage = states["mediItem"]["dosage_type"];
    let frequency = (tapperDosage).toUpperCase() === "STEROID TABLETS" ? "" : states.tapperFrequency;
    let reducedBy = (tapperDosage).toUpperCase() === "STEROID TABLETS" ? states["tapperItem"]["reduced_by"] : "";
    let validDate = false;

    let d = new Date(states["tapperStartDate"])
    if (d.toString() != "Invalid Date") {
      validDate = true
    }

    if (validDate && ((states["tapperItem"]["unit"]) !== "") &&
      ((states["tapperDuration"]) !== "") &&
      (((tapperDosage).toUpperCase() === "DROP" && (states.tapperFrequency) !== "") || ((tapperDosage).toUpperCase() === "STEROID TABLETS" && (states.tapperReduced_typ).trim() != ""))
    ) {
      let dosageCountLabel = (tapperDosage).toUpperCase() === "STEROID TABLETS" ? 'mg' : 'Drop(s)'
      let frequencyLabel = dosageCountLabel === "Drop(s)" ? (frequency == 1) ? ' time a day' : ' times a day' : 'morning - after food'
      let duration = states["tapperDuration"];

      if (states.tapperDuration_mode === "Week(s)") {
        duration = parseInt(states.tapperDuration) * 7
      } else if (states.tapperDuration_mode === "Month(s)") {
        duration = parseInt(states.tapperDuration) * 30
      }

      let date = new Date(states["tapperStartDate"]);
      date.setDate(date.getDate() + parseInt(duration));

      if ((states.selectedEditIndex || states.selectedEditIndex == 0)) {
        let details = {
          duration: `${states["tapperDuration"]} ${states["tapperDuration_mode"]}`,
          start_date: states["tapperStartDate"],
          end_date: date,
          frequency: `${frequency} ${frequencyLabel}`,
          no_of_dosage: `${states["tapperItem"]["unit"]} ${dosageCountLabel}`,
          reducedBy: reducedBy
        }
        let newList = states["tapperDetails"].map((item, index) => {
          return index === states.selectedEditIndex ? details : item
        })
        states["tapperDetails"] = JSON.parse(JSON.stringify(newList))
      } else if (!states.selectedEditIndex && states.selectedEditIndex != 0) {
        let details = {
          duration: `${states["tapperDuration"]} ${states["tapperDuration_mode"]}`,
          start_date: states["tapperStartDate"],
          end_date: date,
          frequency: `${frequency} ${frequencyLabel}`,
          no_of_dosage: `${states["tapperItem"]["unit"]} ${dosageCountLabel}`,
          reducedBy: reducedBy
        }
        states["tapperDetails"] = [...this.state.tapperDetails, details];
      }

      if (parseInt(frequency) === 1) {
        frequency = frequency
      }else if (parseInt(frequency) === 12) {
        frequency = 10
      }else if (parseInt(frequency) === 10) {
        frequency = 6
      } else {
        frequency = parseInt(frequency) - 1
      }

      let unitVal = ""
      let calc = parseInt(states["tapperItem"]["unit"]) - parseInt(states["tapperItem"]["reduced_by"])
      if (states["tapperUnit_typ"] === "mg" && calc > 0) {
        unitVal = calc;
      } else {
        unitVal = states["tapperItem"]["unit"];
      }

      let nextStartDate = new Date(date)
      nextStartDate.setDate(nextStartDate.getDate() + 1);
      states["tapperItem"]["unit"] = unitVal + "";
      this.setState({
        states,
        tapperStartDate: nextStartDate,
        tapperFrequency: frequency + "",
        selectedEditIndex: null
      })
    } else {
      let isDrop = states["mediItem"]["dosage_type"] && (states["mediItem"]["dosage_type"]).toUpperCase();
      if (!states["tapperStartDate"]) { Message = `${t("Enter")} ${t("StartDate")}` }
      else if (!states["tapperDuration"]) { Message = `${t("Enter")} ${t("Duration")}` }
      else if (!states["tapperItem"]["unit"]) { Message = `${t("Enter")} ${t("Unit")}` }
      else if (isDrop !== "DROP" && !states["tapperItem"]["reduced_by"]) { Message = `${t("Enter")} ${t("Reduceby")}` }
      else if (isDrop !== "DROP" && !states["tapperReduced_typ"]) { Message = `${t("Enter")} ${t("ReducedType")}` }
      else if (isDrop === "DROP" && !states["tapperFrequency"]) { Message = `${t("Enter")} ${t("Frequency")}` }
      if (Message.length > 0) {
        this.responseSuccessErrorMessage(Message, false);
      }
    }
  }

  onEditTaperMed = (item, index) => {
    let states = this.state;
    let tapperDosage = states["mediItem"]["dosage_type"];
    let taperUnit = (item.no_of_dosage).split(" ");
    let duration = (item.duration).split(" ");
    let frequency = (tapperDosage).toUpperCase() !== "STEROID TABLETS" ? (item.frequency).split(" ") : [""];
    states["tapperItem"]["unit"] = taperUnit[0];
    states["tapperItem"]["reduced_by"] = item.reducedBy;
    states["tapperStartDate"] = item.start_date;
    this.setState({
      states,
      tapperDuration: duration[0],
      tapperDuration_mode: duration[1],
      tapperFrequency: frequency[0],
      selectedEditIndex: index,
    })
  }

  onDeleteTaperMed = (item, index) => {
    let { tapperDetails } = this.state;
    let deletedArr = [...tapperDetails]
    deletedArr.splice(index, 1);
    this.setState({
      tapperDetails: JSON.parse(JSON.stringify(deletedArr)),
      selectedEditIndex: null
    })
  }
  renderTapper = () => {
    const { t } = this.props;
    let { tapperItem, mediItem, pharma_name } = this.state;
    let states = this.state;
    let disable = (pharma_name === "" || ((mediItem["drug_ms_id"] !== "") && (mediItem["dosage_type"]) === 'Steroid Tablets' || (mediItem["dosage_type"]) === "Drop"));
    return (
      (states["isTapper"] && states["mediItem"]["drug_ms_id"] !== "" && states["pharma_name"] !== "") ?
        <Stack className="eMed_medication_tapperDiv">
          <div className='eMed_medication_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
            <p className='eMed_recPayment_rightHead_txt'>{t("DosageType")}</p>
          </div>
          <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} spacing={1}>
            {Taper_Med_Dosages.length > 0 ?
              <Stack id="eMed_medication_chipDiv" direction="row" justifyContent="flex-start" alignItems="center" spacing={1} flexWrap="wrap">
                {Taper_Med_Dosages.map((item, i) => {
                  if (item["drug_name"] !== "") {
                    return (<Chip key={i} id="eMed_medication_chip" label={item.label} sx={{ '& .MuiChip-label': { fontSize: "0.8vw" }, '&.MuiChip-icon': { width: "1.5vw", height: "1.5vw" } }}
                      color={'primary'} variant={(states["mediItem"]["dosage_type"]) ? (states["mediItem"]["dosage_type"]).toUpperCase() === item.value ? 'contained' : "outlined" : ""} />)
                  }
                })}
              </Stack> : null}
            <Stack direction="row" alignItems="center" spacing={1} className="eMed_medication_tapperSubDiv">
              {this.renderTextBoxs("StartDate", "tapperStartDate", states["tapperStartDate"], "13.2vw")}
              {this.renderTextBoxs("Duration", "tapperDuration", states["tapperDuration"], "6vw", "tapperDuration_mode", states["tapperDuration_mode"], "7vw")}
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} className="eMed_medication_tapperSubDiv">
              {this.renderTextBoxs("Unit", "unit", tapperItem["unit"], "7vw", "tapperUnit_typ", states["tapperUnit_typ"], "6.2vw")}
              {(states["mediItem"]["dosage_type"]) ? (states["mediItem"]["dosage_type"]).toUpperCase() === "DROP" ?
                this.renderTextBoxs("Frequency", "tapperFrequency", states["tapperFrequency"], "13.2vw")
                : this.renderTextBoxs("Reducedby", "reduced_by", tapperItem["reduced_by"], "7.5vw", "tapperReduced_typ", states["tapperReduced_typ"], "4.7vw") : ""}
            </Stack>
            <Button emed_tid = {"Add_TestID"} variant='contained' disabled = {this.state.withOutAppointment?.is_ip} size="small" id="eMed_recPayment_btn" onClick={() => { this.handleTapperAdd() }}>{t("Add")}</Button>
            {
              states["tapperDetails"].length > 0 ?
                <div style={{ width: "100%" }}>
                  <div className='eMed_medication_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                    <p className='eMed_recPayment_rightHead_txt'>{t("AddedDetails")}</p>
                  </div>
                  <Stack className="emedhub_recPayment_RightContentBox">
                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                      {states["tapperDetails"].map((details, index) => {
                        let newStart = details.start_date ? new Date(details.start_date) : "";
                        let newEnd = details.end_date ? new Date(details.end_date) : "";
                        return (
                          <Stack key={index} direction="row" flex={1} sx={{ width: "100%", marginBottom: '0.6vw' }}>
                            <Stack flex={0.45}>
                              <Typography style={{ fontSize: '0.9vw' }}>{details.duration}</Typography>
                              <Typography style={{ fontSize: '0.9vw' }}>{"(" + DateTime.fromJSDate(newStart).toFormat("dd-MM-yyyy") + " - " + DateTime.fromJSDate(newEnd).toFormat("dd-MM-yyyy") + ")"}</Typography>
                            </Stack>
                            <Stack flex={0.40}>
                              <Typography style={{ fontSize: '0.9vw' }}>{details.frequency}</Typography>
                              <Typography style={{ fontSize: '0.9vw' }}>{details.no_of_dosage}</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" flex={0.15}>
                              <CommonEditButton  disable = {this.state.withOutAppointment?.is_ip} size="1.5vw" onClick={this.onEditTaperMed.bind(this, details, index)} />
                              <CommonDeleteButton disabled = {this.state.withOutAppointment?.is_ip} size="1.5vw" onClick={this.onDeleteTaperMed.bind(this, details, index)} />
                            </Stack>
                          </Stack>
                        )
                      })}
                    </Stack>
                  </Stack>
                </div>
                : null}
          </Stack>
        </Stack> :
        <div>
          <div className='eMed_medication_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
            <p className='eMed_recPayment_rightHead_txt'>{t("Duration")}</p>
            {this.renderTextBox("", "duration_mode", states["duration_mode"], "8vw")}
          </div>
          <Stack className="emedhub_recPayment_RightContentBox">
            <Stack className="eMed_medication_durChipDiv" direction="row" justifyContent="flex-start" alignItems="center" spacing={1} flexWrap={"wrap"}>
              {DurationCounts.map((count, index) => (
                <Box
                  key={index} emed_tid ={"duration_testID"}
                  sx={{ backgroundColor: count === this.state.duration && !disable ? Colors.themeDark : "#FAF9F9", color: count === this.state.duration && !disable ? Colors.white : Colors.black, borderColor: count === this.state.duration && !disable ? Colors.themeDark : Colors.lightGray }}
                  id="eMed_medication_durChip" onClick={() => !disable ? this.handleDuration("duration", count) : null}>{count}</Box>
              )
              )}
            </Stack>
          </Stack>
        </div>
    )
  }

  msgClose(){
    this.setState({
        showSuccessResponse: false,
        showErrorResponse: false,
        responseMessage: ''
    })
  }
  render() {
    const { t , fromBillMedication} = this.props;
    let states = this.state;
    let { mediItem, medicationDetails } = this.state;
    let disable = (states["pharma_name"] === "" || ((mediItem["drug_ms_id"] !== "") && (mediItem["dosage_type"]) === 'Steroid Tablets' || (mediItem["dosage_type"]) === "Drop"));

    let data = {
      lineOne: fromBillMedication ? t("Emergency Contact") : t("AttenderName"),
      lineTwo: `${states["attenderData"]["attender_name"] ? states["attenderData"]["attender_name"] : "-"}`,
      lineFour: t('ContactNumber'),
      LineFive: `${states["attenderData"]["attender_mobile"] ? states["attenderData"]["attender_mobile"] : "-"}`
    }
    
    let Consultant = {
      image: ImagePaths.Doctor_Icon.default,
      width: "2.3vw",
      height: "2.3vw",
      lineOne: t("ConsultantName"),
      lineTwo: `${states["attenderData"]["doctor_name"] ? states["attenderData"]["doctor_name"] : "-"}`
    }
    return (
      <Box id= {fromBillMedication ? "eMed_AdHistory_Container" : "eMed_medication_Container"} sx={{ backgroundColor: Colors.configHomeBg }}>
        <Grid container className= "eMed_medication_Box" >
          <Stack className="eMed_medicationCard_header" direction="row" justifyContent="space-evenly" alignItems="center" spacing={1.2} >
            <Stack className="eMed_recCard_div" sx={{ width: "23vw", backgroundColor: Colors.white }} >
              <CommonPatientDetails data={this.state.patientData} showDetailed={!fromBillMedication} />
            </Stack>
            <CommonPatientCard details={data} showDetailed={true} />
            {fromBillMedication ? null :<ConsultantPatientCard name={Consultant} width={"20dvw"}/>}
          </Stack>
          <Grid container item spacing={2} className= "eMed_medication_mainDiv">
            <Grid item xs={ fromBillMedication ? 12 : 8}>
              <Paper className="eMed_medication_Div" elevation={fromBillMedication ? 0 : 2}>
                <TableContainer className="eMed_medication_TblDiv">
                  <Table stickyHeader size='small' >
                    <TableHead >
                    </TableHead>
                    <TableBody className="eMed_medication_Container">
                      {
                        medicationDetails.length > 0 ? medicationDetails.map((row, index) => (
                          this.renderTblRow(row, index)
                        ))
                          : <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                            {t("NoRecordsFound")}
                          </Stack>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            {fromBillMedication ? null : 
            <Grid item xs={4}>
              <Paper className="eMed_medication_Div" elevation={2}>
                <div className='eMed_medication_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                  <p className='eMed_recPayment_rightHead_txt'>{t("DetailsForDrug")}</p>
                  {this.renderTextBox("SelectPharmacy", "pharma_name", states["pharma_name"], "14vw")}
                </div>
                <div className="eMed_medication_subDiv">
                    <Box sx={{display:'flex', justifyContent:"flex-end", marginRight:"0.5vw"}}>
                      <Button emed_tid = {"ExternalDrug_TestID"} size="small" variant="outlined" onClick={() => { this.setState({ ExternalDrug: !this.state.ExternalDrug }) }}>{this.state.ExternalDrug ? "Phramacy Drugs" : "External drugs"}</Button>
                    </Box>
                    <Stack className="emedhub_medication_RightContentBox" direction="column" justifyContent="center" alignItems="center" spacing={0.5}>
                      {
                        this.state.ExternalDrug ?
                          <>
                            {this.renderFreeTextField("BrandName", "externalBrandName", "externalBrandName", "27.5vw")}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.5}>
                              {this.renderFreeTextField("GenericName", "externalGenName", "externalGenName", "13.4vw")}
                              {this.renderFreeTextField("DosageType", "externalDosageType", "externalDosageType", "13.2vw")}
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.5}>
                              {this.renderFreeTextField("DosageStrength", "externalDosStrength", "externalDosStrength", "13.4vw")}
                              {this.renderFreeTextField("Route", "externalRoute", "externalRoute", "13.2vw")}
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" width={'100%'}>
                              <TextField
                                sx={{ marginLeft:'15vw', width:"10vw", display: this.state.externalRoute === "Others" ? 'block' : "none"}}
                                size="small"
                                autoComplete="off"
                                label={t("Route *")}
                                value={this.state.externalRouteOther}
                                onChange={(e)=>{
                                  let states = this.state
                                  states["externalRouteOther"] = e.target.value
                                  this.setState({
                                    states
                                  })
                                }}
                              />
                            </Stack>
                          </>
                          :
                          <>
                            {this.renderTextBox("BrandName", "brand_name", mediItem["brand_name"], "27.5vw")}

                            {states["frequentDrugs"].length > 0 ?
                              <Stack id="eMed_medication_chipDiv" direction="row" justifyContent="flex-start" alignItems="center" spacing={1} flexWrap="wrap">
                                {states["frequentDrugs"].map((item, i) => {
                                  if (item["drug_name"] !== "") {
                                    return (<Chip emed_tid = {item.drug_name} key={i} id="eMed_medication_chip" label={item.drug_name} sx={{ '& .MuiChip-label': { fontSize: "0.8vw" }, '&.MuiChip-icon': { width: "1.5vw", height: "1.5vw" } }}
                                      color={states["pharma_name"] !== "" ? 'primary' : "default"} variant={states["mediItem"]["brand_name"] === item.drug_name ? 'contained' : "outlined"} onClick={() => states["pharma_name"] !== "" ? this.handleChipClk(item) : ""} />)
                                  }
                                })}
                              </Stack> : null}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.5}>
                              {this.renderTextBox("GenericName", "generic_name", mediItem["generic_name"], "13.4vw")}
                              {this.renderTextBox("DosageType", "dosage_type", mediItem["dosage_type"], "13.2vw")}
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.5} marginBottom={"0.5vw"}>
                              {this.renderTextBox("DosageStrength", "dosage_strength", mediItem["dosage_strength"], "13.4vw")}
                              {this.renderTextBox("Route", "route", mediItem["route"], "13.2vw")}
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" width={'100%'}>
                              <TextField
                                sx={{ marginLeft:'15vw', width:"10vw", display: mediItem["route"] === "Others" ? 'block' : "none"}}
                                size="small"
                                autoComplete="off"
                                label={t("Route *")}
                                value={this.state.mediRouteOther}
                                onChange={(e)=>{
                                  let states = this.state
                                  states["mediRouteOther"] = e.target.value
                                  this.setState({
                                    states
                                  })
                                }}
                              />
                            </Stack>
                          </>
                      }
                    {(!states["isTapper"]) ?
                      <Stack className="eMed_medication_maenDiv" direction="column" justifyContent="center" alignItems="flex-start" spacing={0.8}>
                        {this.renderMaen("Morning", "morning")}
                        {this.renderMaen("Afternoon", "afternoon")}
                        {this.renderMaen("Evening", "evening")}
                        {this.renderMaen("Night", "night")}
                      </Stack>
                      : null}
                    <ButtonGroup disableElevation className="eMed_medication_btnDiv">
                      <Button emed_tid = {"Normal_TestID"} disabled = {this.state.withOutAppointment?.is_ip} variant={states["isTapper"] ? "outlined" : "contained"} id="eMed_medication_btn" onClick={() => this.setState({ isTapper: false })}>{t("Normal")}</Button>
                      <Button emed_tid = {"Tapper_TestID"} disabled = {this.state.withOutAppointment?.is_ip} variant={states["isTapper"] ? "contained" : "outlined"} id="eMed_medication_btn" onClick={() => ((states["mediItem"]["drug_ms_id"] !== "" && states["pharma_name"] !== "") && ((mediItem["drug_ms_id"] !== "") && (mediItem["dosage_type"]) === 'Steroid Tablets' || (mediItem["dosage_type"]) === "Drop")) ? this.setState({ isTapper: true }) : null}>{t("Tapper")}</Button>
                    </ButtonGroup>
                  </Stack>
                  {this.renderTapper()}
                  <Stack className="emedhub_recPayment_RightContentBox" direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
                    <Stack direction = "row" justifyContent = "center" alignItems="center" spacing={2}>
                      {this.renderTextBox("Quantity", "quantity", states["quantity"], "13.2vw")}
                      {mediItem["drug_ms_id"] && <p>{`${t("stockInQty")} : ( ${mediItem["stock_in_quantity"] ? mediItem["stock_in_quantity"] : 0} )`}</p>}
                      </Stack> 
                    {(!states["isTapper"]) ?
                      <Stack id="eMed_medication_instruDiv" direction="row" justifyContent="space-between" alignItems="center" style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                        {this.renderInstruction(states["instruction"] === "Empty Stomach" && !disable ? ImagePaths.EmptyOn.default : ImagePaths.EmptyOff.default, "Empty Stomach")}
                        {this.renderInstruction(states["instruction"] === "Before Food" && !disable ? ImagePaths.BeforeOn.default : ImagePaths.BeforeOff.default, "Before Food")}
                        {this.renderInstruction(states["instruction"] === "With Food" && !disable ? ImagePaths.WithFoodOn.default : ImagePaths.WithFoodOff.default, "With Food")}
                        {this.renderInstruction(states["instruction"] === "After Food" && !disable ? ImagePaths.AfterOn.default : ImagePaths.AfterOff.default, "After Food")}
                        {this.renderInstruction(states["instruction"] === "External Use" && !disable ? ImagePaths.ExternalOn.default : ImagePaths.ExternalOff.default, "External Use")}
                      </Stack> : null}
                  </Stack>
                </div>
                <Stack spacing={2} direction="row" id='eMed_recPayment_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg}} alignItems="center" justifyContent="center"> 
                  <Button emed_tid = {"Clear_testID_001"} variant='outlined' disabled = {this.state.withOutAppointment?.is_ip} size="small" id="eMed_recPayment_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                  <Button emed_tid = {"Add_testID_001"} disabled={this.props.is_From_IpNotes ? false : (CheckAccessFunc("front_office", "In Patients","Clinical Notes", "Medication", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_ip : true) || this.state.isAdd} variant='contained' size="small" id="eMed_recPayment_btn" onClick={() => { this.setState({isAdd: true,},()=>{this.handleAdd()}) }}>{t("Add")}</Button>
                </Stack>
              </Paper>
            </Grid>}
          </Grid>
        </Grid>
        {!(this.state.isEdit) && this.state.deletedPopup ?
          <DeletePopup DeletTitle={`${t("deleteMsg")}  ${states["brand_name"] ? states["brand_name"] : "-"} ?`}
            deleteAlertPopupClose={this.PopupClose.bind(this)}
            removeData={this.removeDataList.bind(this)}
          />
          : null}
        {
          this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
        }
        {
          this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
        }
        {
          states["selectedOption"] === "Medication" ?
            <CommonPopUp width={"75vw"} popUpClose={this.MedicPopUpClose.bind(this)} title={t("Medication")} component={this._showReturnDetails.bind(this)} buttonOne={t("Clear")}
              buttonOneAction={this.MedicPopUpClear.bind(this)} buttonTwo={t("Save")} buttonTwoAction={this.handleMedicSave.bind(this)} />
            : null
        }
        {
          states["isHistoryClicked"] ?
            <CommonPopUp width={"75vw"} backgroundColor={Colors.white} popUpClose={this.historyPopUpClose.bind(this)} title={t("History")} component={this._showHistory.bind(this, states["historyData"])} />
            : null
        }
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
export default withTranslation()(Medication);