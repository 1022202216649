import React, { Component } from "react";
import { Box , Stack, Button, Typography, IconButton, Tooltip} from "@mui/material";
import { ImagePaths } from '../../../Utility/ImagePaths';
import {  DataGrid } from '@mui/x-data-grid';
import { Trans, withTranslation } from 'react-i18next';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { DateTime } from "luxon";
import CommonGridHeader from "../../../Components/Common Components/CommonComponents";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";


class PurchaseOrder extends Component{
    constructor(props) {
        super(props);
        this.state = {
            Purchasedata: {
                result: [],
                no_of_pages: 0,
                total_count: 10,
            },
            current_page: 0,
            pageSize: 10,
            purchase_order_id: null,
            status: "",
            searchKey: '',
        }
    }
    componentDidMount() {
        this._getPurchaseList();
    }

    componentWillReceiveProps(props){
        if(props.search_key != this.state.searchKey){
            this.setState({
                searchKey: props.search_key
            },() => {
                this._getPurchaseList()
            })
        }
    }
    
    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }
    _getPurchaseList = () =>{
        this.LoaderFunction(true)
        let{ Purchasedata, pageSize,current_page,searchKey  } = this.state
        let recentPage = current_page + 1;
        RestAPIService.getAll(Serviceurls.LAB_PURCHASEORDER_LIST + "?page_number=" +recentPage+ "&page_size=" +pageSize + "&sort_by="+ 2 +"&search_term=" + searchKey )
        .then(response => {
            if (response.data.status == "success") {
                Purchasedata['result'] = response.data.data ? response.data.data.result : [];
                Purchasedata['total_count'] = response.data.data ? response.data.data.total_count : 0;
                Purchasedata['no_of_pages'] = response.data.data ? response.data.data.no_of_pages : 0;
                this.setState({
                    Purchasedata
                },()=>{this.LoaderFunction(false)});
            }
        })
        .catch(error => {
            this.LoaderFunction(false)
            if (error.response.data.status == "fail") {
                this.SuccessErrorBox(error.response.data.message, false);
            }
        });
    }

    getPurchaseOrderexcel = () => {
        try {
          RestAPIService.excelGet(Serviceurls.LAB_PURCHASEORDER_LIST + "?export_type=excel" + "&search_key=" + this.state.searchkey).
            then((response) => {
              if(response.data){
              var pom = document.createElement('a');
              var csvContent = response.data; //here we load our csv data 
        
              let filename = response.headers["content-disposition"].split("filename=")[1]
        
              var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
              var url = URL.createObjectURL(blob);
              pom.href = url;
              pom.setAttribute('download', filename ? filename : 'registry.xlsx');
              pom.click();
              }
            }).catch((error) => {
                this.SuccessErrorBox(error.response.data.message, false);
            })
        } catch (error) {
            this.SuccessErrorBox(error.message, false);
        }
      }

    handleDelivery = (event, values) => {
        this.setState({
            purchase_order_id: values.row.purchase_order_id,
            status: values.row.status,
        },()=>{this.props.goBack("directPo" , null, this.state.purchase_order_id, this.state.status, "directPo")})
    }

    handleCellClick = (param,event) => {event.stopPropagation()}

    SuccessErrorBox = (message,type) => {
        this.props.responseSuccessErrorMessage(message,type)
    }

    PurchaseOrderPrint = () => {
        RestAPIService.getAll(`${Serviceurls.LAB_PURCHASEORDER_LIST}?export_type=pdf`)
        .then((response)=>{
            const file = new Blob(
                [response.data],
                {type : 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }).catch((error)=>{
            this.SuccessErrorBox(error.response.data.message, false); 
        })
    }

    render() {
        const {t} = this.props
        let {Purchasedata , pageSize,current_page} = this.state;
        const columns = [
            { field: 's.no', headerClassName: 'grid--header', headerName: t('SNo'), flex: 0.02, sortable: false, hideable: false, renderCell: (index) => index.api.getRowIndex(index.row.purchase_order_id) + 1},
            { field: "purchase_order_date", headerClassName: 'grid--header', headerAlign: 'center', headerName: t('PODate'), flex: 0.05, sortable: false,type: 'date', align:"center", renderCell : (cellvalues)=>{
                let purchase_order_date = cellvalues.row.purchase_order_date ? new Date(cellvalues.row.purchase_order_date) : ""
                return(
                    <Typography>{cellvalues.row.purchase_order_date ? cellvalues.row.purchase_order_date : "-"}</Typography>
                )}
            },
            { field: "purchase_order_number", headerClassName: 'grid--header', headerName: t('GRNNo'), flex: 0.05, sortable: false,type:"number",
            renderCell:(params)=>(<div>{params?.row?.purchase_order_number ? params?.row?.purchase_order_number : "-"}</div>)},
            { field: "vendor_name", headerClassName: 'grid--header',headerAlign:"center", headerName: t('VendorName'), flex: 0.05, sortable: false, align:"center",
            renderCell:(params)=>(<div>{params?.row?.vendor_name ? params?.row?.vendor_name : "-"}</div>)},
            { field: "number_of_items", headerClassName: 'grid--header', headerName: t('NoOfItems'), flex: 0.05, sortable: false,type:"number",
            renderCell:(params)=>(<div>{params?.row?.number_of_items ? params?.row?.number_of_items : "-"}</div>)},
            { field: "delivery_date", headerClassName: 'grid--header', headerAlign: 'center',headerName: t('DeliveryDate'), flex: 0.05, sortable: false,type: 'date', align:"center", renderCell : (cellvalues)=>{
                let delivery_date = cellvalues.row.delivery_date ? new Date(cellvalues.row.delivery_date) : ""
                return(
                    <Typography>{cellvalues.row.delivery_date ? cellvalues.row.delivery_date : "-"}</Typography>
                )}
            },
            { field: "status", headerClassName: 'grid--header', headerAlign: 'center', headerName: t('status'), flex: 0.05, sortable: false, align:"center", renderCell : (cellvalues)=>{
                return(
                    <Stack sx={{padding:"0.4vw", borderRadius:"0.2vw", backgroundColor: cellvalues.row["status"] === "Completely Delivered" ? "#ECFFE9" : cellvalues.row["status"] === "Partially Delivered" ? "#FFE9E9" : null }}>
                        <Typography sx={{ color: cellvalues.row["status"] === "Completely Delivered" ? "#1E972A" : cellvalues.row["status"] === "Partially Delivered" ? "#B82121" : null }}>
                            {cellvalues.row["status"] === "Completely Delivered" ? "Delivered" : cellvalues.row["status"] === "Partially Delivered" ? "Partially Delivered" : "Place order"}
                        </Typography>
                    </Stack>
                );
               }},
            { field: 'actions', headerClassName: 'grid--header', headerName: t('Action'),type :'actions', flex: 0.05, sortable: false, hideable: false,
           renderCell : (cellvalues)=>{
            return(
                <Tooltip title={"Receive Order"} placement="top" arrow>
                    <IconButton disabled={!CheckAccessFunc("Laboratory", "Purchase", "Purchase Order", null, "Tab")?.editAccess} sx={{ borderRadius:"0.3vw"}} size="small"  emed_tid = "handleDelivery_TestID" onClick = {(event)=>{this.handleDelivery(event,cellvalues)}}><img className="eMed_pur_deliv_img" src={ImagePaths.deliveryBox.default} alt={"Delivery Box"}/></IconButton>
                </Tooltip>
            );
           }
        }
        ]

        return (
            <Box className = "emedhub__purchase_page" sx={{'& .grid--header': {backgroundColor: '#FAF9F9'}}}>
               <Stack sx={{margin:"1vw"}} spacing={2} direction="row"  justifyContent="flex-end" alignItems="center">
                    <div className= "emedhub_vendor_btnDiv">
                        <Button variant="contained" size="small" disabled={!CheckAccessFunc("Laboratory", "Purchase", "Purchase Order", null, "Tab")?.editAccess} sx={{marginRight:"0.5vw",textTransform:"capitalize"}} emed_tid = "directPo_Test_Id" onClick = {()=>{this.props.goBack("directPo","","","","directPo")}}>{t("DirectPO")}</Button>
                    </div>
                    
                    <Stack direction= "row" spacing={1}>
                        <IconButton size="small" emed_tid='PurchaseOrderPrint_test_id' onClick={()=>{this.PurchaseOrderPrint()}}><img className="emedhub_directPo_img"  src={ImagePaths.LabPrintIcon.default} alt='print' /></IconButton>
                        <IconButton size="small" emed_tid='getPurchaseOrderexcel_testId'><img className="emedhub_directPo_img"  src={ImagePaths.LabUploadButton.default} onClick={()=>this.getPurchaseOrderexcel()}  alt='upload'/></IconButton>
                    </Stack>
                </Stack>
                <div id = "emedhub_ventor_Table">
                <DataGrid 
                        rows={Purchasedata.result}
                        columns={columns}
                        initialState={{
                            pagination: {
                                page: current_page,
                              }
                          }}
                          components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: ()=>{
                                return(
                                    <Stack direction="row" justifyContent = "center" alignItems = "center" sx={{height:"53vh"}}>
                                    {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }
                        }}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                          }}
                        getRowId={(row) => row.purchase_order_id}
                        onCellClick = {this.handleCellClick}
                        loading={this.state.isLoader}
                        onRowClick = {this.handleCellClick}
                        rowCount={Purchasedata['total_count']}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>this.setState({pageSize: newPageSize},()=>{this._getPurchaseList()})}
                        onPageChange={(newPage) => this.setState({current_page: newPage},()=>{this._getPurchaseList()})}
                        rowsPerPageOptions={[10, 20, 30]}
                        paginationMode="server"
                        pagination />
                </div>
            </Box>
        )
    }
}

export default withTranslation()(PurchaseOrder);