import { Box, Button, Collapse, IconButton, Menu, MenuItem, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import './PatientBill.css'
import { Colors } from '../../../../../Styles/Colors'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ImagePaths } from '../../../../../Utility/ImagePaths'
import { t } from 'i18next'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import { getCachevalue, localGetItem, setCachevalue } from '../../../../../Utility/Services/CacheProviderService'
import { BillSuccessPoPUp, CommonPatientCard, CommonPatientDetails, CommonPopUp } from '../../../../../Components/Common Components/CommonComponents'
import CreditBillPopUp from '../../../BillingTransaction/CreditBillPopUp'
import { CheckAccessFunc, formatDate, AmountFormat, FindCreditType } from '../../../../../Components/CommonFunctions/CommonFunctions'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreditTypeChangePop from '../../../BillingTransaction/CreditTypeChangePop';
import Loader from '../../../../../Components/Loader';
import EditHistoryPopup from '../../../BillingTransaction/EditHistoryPopup'
import CreditBillCompletePoP from '../../../BillingTransaction/CreditBillCompletePoP'
import { CurrencySymbol } from '../../../../../Utility/Constants'

class PatientCreditBill extends Component {
    constructor(props) {
        super(props)
        this.state = {
            OpPatientData: {},
            creditBillPopUp: false,
            attenderName: "",
            attenerNumber: "",
            firstIndex: null,
            secondIndex: null,
            creditBillData: [],
            opCompletedBillId: "",
            opCreditBill: {},
            withOutAppointment: {},
            creditBillId: "",
            isBillView: false,
            viewServiceList: [],
            selectedBillDetails: {},
            CreditTypeChangePopup: false,
            dataForTypeChage: {},
            receiptID: null,
            pharmacReceipt: null,
            BillModifyHistoryItem: {}
        }
    }

    componentDidMount() {
        let patientData = getCachevalue("patientData")
        let OpPatientData = JSON.parse(patientData)
        let appointmentDetail = getCachevalue("withoutAppointment");
        let details = JSON.parse(appointmentDetail);
        if (details?.patient_id === OpPatientData?.patient_id) {
            this.setState({
                withOutAppointment: details
            }, () => {
                this.getPatientDatails();
                this.getPatientCompletedBillData();
            })
        }
        this.setState({
            OpPatientData: OpPatientData
        }, () => {
            this.getPatientDatails()
            this.getPatientCompletedBillData()
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    creditBillPost = (message = "", receiptNo = null, phrReceipt = null, error = false) => {
        this.setState({
            creditBillPopUp: false,
            receiptID: receiptNo,
            pharmacReceipt: phrReceipt
        }, () => {
            if(error){
                this.errorMessage(message)
            }else{
                this.successMessage(message)
            }
            this.getPatientCompletedBillData()
        })
    }

    CrebillTypeChaged = (message) => {
        this.setState({
            creditBillPopUp: false,
            CreditTypeChangePopup: false,
            dataForTypeChage: {}
        }, () => {
            this.successMessage(message)
            this.getPatientCompletedBillData()
        })
    }

    ClosePopUp() {
        this.setState({
            creditBillPopUp: false,
            CreditTypeChangePopup: false,
            dataForTypeChage: {},
            receiptID: null,
            pharmacReceipt: null
        })
    }

    getPatientDatails = (AppointId = null) => {
        let appointment_id = AppointId || this.state.OpPatientData?.appointment_id || this.state.withOutAppointment?.appointment_id;
        try {
            if(appointment_id){
                RestAPIService.getAll(Serviceurls.FO_PATIENT_APPOINTMENT_DETAILS + "?appointment_id=" + appointment_id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            attenderName: response.data?.data?.caretaker_name,
                            attenerNumber: response.data?.data?.caretaker_number,
                            PatientDetails: response.data?.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPatientCompletedBillData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_PATIENT_BILL_DETAILS_GET + "?patient_id=" + this.state.OpPatientData?.patient_id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            creditBillData: response.data.data
                        }, () => {
                            if (this.state.creditBillData.length > 0) {
                                this.getPatientDatails(this.state.creditBillData[this.state.creditBillData.length - 1]?.appointment_id)
                            } 
                            this.LoaderFunction(false)
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    renderPatientHeader = () => {
        const { t } = this.props
        let attenderDetails = {
            lineOne: t("AttenderName"),
            lineTwo: `${this.state.attenderName ? this.state.attenderName : "-"}`,
            lineFour: t("ContactNumber"),
            LineFive: `${this.state.attenerNumber ? this.state.attenerNumber : "-"}`
        }
        let appointment_id = this.state.OpPatientData?.appointment_id || this.state.withOutAppointment?.appointment_id;
        return (
            <Box component={'div'} display={'flex'}>
                <Box component={'div'} className='eMed_nur_notes_details' ml={'0.8vw'}>
                    <CommonPatientDetails data={this.state.OpPatientData} />
                </Box>
                {appointment_id ? <CommonPatientCard details={attenderDetails} showDetailed={true} /> : null }
            </Box>
        )
    }

    getCapitalize = (data) => {
        const capitalized = data.charAt(0).toUpperCase() + data.slice(1);
        return capitalized;
    }

    getCreditBillPrint = (Module, LabId = null) => {
        try {
            let APIURL = ""
            if (Module === "OP") {
                APIURL = Serviceurls.OP_INVOICE_PRINT + "?invoce_id=" + +this.state.creditBillId
            } else if (Module === "PHARMACY") {
                APIURL = Serviceurls.PHARMACY_INVOICE_PRINT + "?id=" + +this.state.creditBillId + "&export=pdf"
            } else if (Module === "LAB") {
                APIURL = Serviceurls.LAB_CREATE_BILL_COMPLETED_PRINT + "?bill_summary_id=" + +this.state.creditBillId + "&export_type=pdf" + "&laboratory_id=" + LabId;
            } else if (Module === "RADIOLOGY") {
                APIURL = Serviceurls.RAD_BILL_POST + '?bill_summary_id=' + +this.state.creditBillId + "&export_type=pdf"
            }
            RestAPIService.getAll(APIURL).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.errorMessage(error.response?.data?.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    PaidIn(RowData) {
        let data = RowData?.paid_in ? RowData?.paid_in.split(",") : []
        let isFo = data.includes("FRONT OFFICE")
        let ispharma = data.includes("PHARMACY")
        let isLab = data.includes("LABORATORY")
        if (isFo || ispharma || isLab) {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <Typography display={isFo ? "inline" : "none"} textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"}>Front Office</Typography>
                    <Typography display={ispharma ? "inline" : "none"} textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"}>Pharmacy</Typography>
                    <Typography display={isLab ? "inline" : "none"} textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"}>Lab</Typography>
                </Box>
            )
        } else {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"}>-</Typography>
                </Box>
            )
        }
    }

    renderTableOne = (item, firstIndex) => {
        let appointment_id = this.state.OpPatientData?.appointment_id || this.state.withOutAppointment?.appointment_id;
        return (
            <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse
                    in={this.state.selectedIndex === firstIndex}
                    timeout={'auto'}
                    unmountOnExit
                >
                    <Box margin={1} className='eMed_Pat_Bill_Tbl_Two'>
                        <Table>
                            <TableHead sx={{ backgroundColor: Colors.Background }}>
                                <TableRow>
                                    {/* <TableCell /> */}
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("DateAndTime")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("BillNumber")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("BillType")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("Paid In")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("Module")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("DoctorNurseName")}</TableCell>
                                    {/* <TableCell className='eMed_Pat_Bill_Tbl_Header'>{`${t("CreditType")}`}</TableCell> */}
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{`${t("TotalBillAmount")} (${CurrencySymbol})`}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("Action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item?.bill_data?.length > 0 ? item?.bill_data.map((i, secondIndex) => {
                                    let billType = i?.bill_type ? this.getCapitalize(i.bill_type) : "-";
                                    let PayStatus = i?.payment_status ? i.payment_status : "-";
                                    return (
                                        <><TableRow key={secondIndex}>
                                            {/* <TableCell className='eMed_Pat_Tbl_one_style'>
                                            <IconButton
                                                disabled = {this.state.withOutAppointment?.is_op}
                                                size='small'
                                                onClick={() => {
                                                    this.setState({
                                                        selectedSubIndex: this.state.selectedSubIndex === secondIndex ? null : secondIndex
                                                    })
                                                }}
                                            >
                                                {this.state.selectedSubIndex === secondIndex && this.state.selectedIndex === firstIndex ?
                                                    <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell> */}
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{i?.invoice_date ? i.invoice_date : "-"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{i?.bill_number ? i.bill_number : "-"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"} >{billType ? (billType === "Credit") ? `Credit - ( ${i?.credit_type ? i?.credit_type : "-"} )` : billType : '-'}</Typography>
                                                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"} >{(i?.bill_type === "Bill" || i?.bill_type === "Regular") ? "Paid" : (i?.payment_status ? i?.payment_status : "-")}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center' sx={{ textTransform: 'capitalize'}}>{this.PaidIn(i)}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{i?.module ? i.module : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{i?.doctor__first_name ? i.doctor__first_name : i.nurse_name ? i.nurse_name : "-"}</TableCell>
                                            {/* <TableCell className='eMed_Pat_Tbl_one_style'>{i?.credit_type ? i.credit_type : "-"}</TableCell> */}
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{i?.amount_net ? AmountFormat(i?.amount_net)?.replace(`${CurrencySymbol}`, "") : "-"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'>
                                                {/* {Credit Bill Flow Change} */}
                                                {/* <Button
                                               disabled={CheckAccessFunc("front_office", "Home", "Billing", "Credit Bill", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_op : true}
                                                className='eMed_Pat_tbl_two_btn'
                                                onClick={() => {
                                                    this.setState({ creditBillPopUp: true, opCreditBill: i })
                                                }}
                                            >
                                                <Tooltip placement='top' title={'Complete Bill'} arrow>
                                                    <img src={CheckAccessFunc("front_office", "Home", "Billing", "Credit Bill", "SubTab")?.editAccess ? ImagePaths.CreateBill.default : ImagePaths.DisabledCreateBill.default}  className='eMed_Pat_Bill_img' alt='credit' />
                                                </Tooltip>
                                            </Button> */}
                                                <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                                    <Button
                                                        //    disabled={CheckAccessFunc("front_office", "Home", "Billing", "Credit Bill", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_op : true}
                                                        className='eMed_Pat_tbl_two_btn'
                                                        onClick={() => {
                                                            this.setState({ creditBillId: i?.id }, () => { this.getCreditBillPrint(i?.module, i?.laboratory_id ? i?.laboratory_id : null) })
                                                        }}
                                                    >
                                                        <Tooltip placement='top' title={'Print'} arrow>
                                                            <img src={ImagePaths.PrintIcons.default} className='eMed_Pat_Bill_img' alt='print' />
                                                        </Tooltip>
                                                    </Button>
                                                    <Button
                                                        className='eMed_Pat_tbl_two_btn'
                                                        onClick={() => {
                                                            this.setState({
                                                                isBillView: true,
                                                                viewServiceList: i.serivce_data,
                                                                selectedBillDetails: i
                                                            })
                                                        }}
                                                    >
                                                        <Tooltip placement='top' title={'View Bill'} arrow>
                                                            <img src={ImagePaths.View.default} className='eMed_Pat_Bill_img' alt='viewBill' />
                                                        </Tooltip>
                                                    </Button>
                                                    {appointment_id ? this.MenuItemTableOne(i, secondIndex, i.module) : null}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                            {/* <TableRow>
                                            {this.renderTableTwo(i, secondIndex,firstIndex)}
                                        </TableRow> */}
                                        </>
                                    )
                                }) : <TableRow>
                                    <TableCell /><TableCell />
                                    <TableCell /><TableCell align='center'>
                                        {t("NoRecordsFound")}</TableCell><TableCell />
                                    <TableCell /><TableCell />
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell></>
        )
    }

    renderTableTwo = (i, secondIndex, firstIndex) => {
        return (
            <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse
                    in={this.state.selectedSubIndex === secondIndex && this.state.selectedIndex === firstIndex}
                    timeout={'auto'}
                >
                    <Box margin={1} className='eMed_Pat_Bill_Tbl_Two'>
                        <Table>
                            <TableHead>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("DateAndTime")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("receiptNumber")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("PaymentModeType")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("CreatedBy")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("TotalBillAmount")}</TableCell>
                            </TableHead>
                            <TableBody>
                                {i?.receipt_data?.length > 0 ? i?.receipt_data.map((val, thirdIndex) => (
                                    <TableRow key={thirdIndex}>
                                        <TableCell className='eMed_Pat_Tbl_one_style'>{val?.receipt_date ? val.receipt_date : "-"}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style'>{val?.receipt_number ? val.receipt_number : "-"}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style'>{val?.payment_mode_types ? val.payment_mode_types : "-"}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style'>{val?.created_by ? val.created_by : "-"}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style'>{val?.amount_received ? val.amount_received : "-"}</TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell /><TableCell />
                                    <TableCell align='center'>{t("NoRecordsFound")}</TableCell>
                                    <TableCell />
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell></>
        )
    }

    MenuItem = (data, index) => {
        const { t } = this.props
        const multi_option = [
            { value: "ChangeCreditType", label: t("Change Credit Type") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        disabled={data?.balance_amount >= 0}
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: index,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == index) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option} onClick={() => {
                                this.setState({ anchorEl: null }, () => {
                                    if (option.value === "ChangeCreditType") {
                                        let dataobj = {
                                            "patient_name": this.state.OpPatientData?.patient_name,
                                            "uhid": this.state.OpPatientData?.patient_account_numbers,
                                            "mobile_number": this.state.OpPatientData?.patient_mobile_number,
                                            "patient_age": this.state.OpPatientData?.patient_age,
                                            "patient_id": this.state.OpPatientData?.patient_id,
                                            "module_id": this.state.OpPatientData?.appointment_id,
                                            "total_amount": data?.total_amount ? data?.total_amount : 0,
                                            "pending_amount": data?.balance_amount ? Math.abs(data?.balance_amount) : 0,
                                            "bill_types": "Out Patient",
                                            "credit_type": FindCreditType(data?.bill_data),
                                            "employee_id": this.state.OpPatientData?.employee_id,
                                            "employer_id": this.state.OpPatientData?.employer_id,
                                        }
                                        this.setState({ CreditTypeChangePopup: true, dataForTypeChage: dataobj })
                                    }
                                })
                            }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    MenuItemTableOne = (data, index, ModuleName) => {
        const { t } = this.props
        let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        let ModifyBillAccess = RoleData?.is_user ? (RoleData?.permission_access?.common?.modify_credit_bill ? RoleData?.permission_access?.common?.modify_credit_bill : false) : true
        let cancelBillApproval = RoleData?.is_user ? (RoleData?.permission_access?.common?.cancel_bill_approval ? RoleData?.permission_access?.common?.cancel_bill_approval : false) : true
        const multi_option = [
            { value: "cancelBill", label: t("Cancel Bill") },
            { value: "ModifyBill", label: t("Modify Bill") }, // will be sliced when no access
            { value: "ModifyHistory", label: t("Modify History") }
        ]
        if (!ModifyBillAccess) {
            multi_option.splice(1, 1);
        } 
        if(!cancelBillApproval){
            multi_option.splice(0, 1);
        }
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        disabled={ModuleName === "PHARMACY" || data?.bill_type === "cancelled"}
                        onClick={(e) => {
                            this.setState({
                                anchorElTblOne: e.currentTarget,
                                selectedRowTblOne: index,
                                isSelectedTblOne: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelectedTblOne && this.state.selectedRowTblOne == index) ?
                    <Menu
                        anchorEl={this.state.anchorElTblOne}
                        open={Boolean(this.state.anchorElTblOne)}
                        onClose={() => {
                            this.setState({ anchorElTblOne: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option}
                                disabled={option?.value === "ModifyBill" ? (((data?.bill_type === "credit") && data?.payment_status === "not paid" && (data?.module !== "LAB" || data?.result_status === "Result Inprogress")) ? false : true) : false}
                                onClick={() => {
                                    this.setState({ anchorElTblOne: null }, () => {
                                        if (option?.value === "cancelBill" || option?.value === "ModifyBill") {
                                            if (ModuleName === "OP") {
                                                setCachevalue(true, "isForCancelBill")
                                                setCachevalue(JSON.stringify({ AppointmentID: data?.appointment_id, InvoiceID: data?.id, ForCancel: true, AmountNet: data?.amount_net, FromBills: true, isForEditBill: option?.value === "ModifyBill" }), "OPCancelBilldata")
                                                this.props?.history.push({ pathname: "/OPHome/PatientDetails/Billing", state: { AppointmentID: data?.appointment_id, InvoiceID: data?.id, ForCancel: true, AmountNet: data?.amount_net, FromBills: true, isForEditBill: option?.value === "ModifyBill", Reset:true } })
                                            } else if (ModuleName === "LAB") {
                                                setCachevalue(true, "isForCancelBill")
                                                setCachevalue(JSON.stringify({ ForCancel: true, cancelBillData: data, FromBills: true, isForEditBill: option?.value === "ModifyBill" }), "LabCancelBilldata")
                                                this.props?.history.push({ pathname: "/Billing/LabBilling", state: { ForCancel: true, FromBills: true, cancelBillData: data, isForEditBill: option?.value === "ModifyBill" } })
                                            } else if (ModuleName === "RADIOLOGY") {
                                                setCachevalue(true, "isForCancelBill")
                                                setCachevalue(JSON.stringify({ fromModifyBill:  option?.value === "ModifyBill", billSummaryId: data?.id, ForCancel: true, AmountNet: data?.amount_net, FromBills: true, isForEditBill: option?.value === "ModifyBill" }), "RadCancelBillData")
                                                this.props.history?.push({ pathname: '/Billing/RadiologyBilling', state: { fromModifyBill: true, billSummaryId: data?.id, ModifyFromFO: option?.value === "ModifyBill", CancelFromFO: option?.value === "cancelBill", patientData: data, FromOpPatientBillsScreen : true } })
                                            }
                                        } else if (option?.value === "ModifyHistory") {
                                            this.setState({ BillModifyHistoryItem: data })
                                        }
                                    })
                                }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    renderTable = () => {
        let appointment_id = this.state.OpPatientData?.appointment_id || this.state.withOutAppointment?.appointment_id;
        return (
            <TableContainer component={'div'} className='eMed_Pat_Bill_Table'>
                <Table aria-label="collapsible table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' />
                            <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("AppointmentNumberAndDate")}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{t("NoOfBill")}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{`${t("TotalBillAmount")} (${CurrencySymbol})`}</TableCell>
                            {/* <TableCell className='eMed_Pat_Bill_Tbl_Header'>{`${t("PaidAmount")} (₹)`}</TableCell> */}
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{`${t("BalAmt")} (${CurrencySymbol})`}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{getCachevalue("isForCancelBill") === "true" ? "" : t("Action")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.creditBillData?.length > 0 ? this.state.creditBillData.map((item, firstIndex) => (
                            <><TableRow
                                sx={{ backgroundColor: this.state.selectedIndex === firstIndex ? Colors?.ThemeLightColor : 'white', cursor: "pointer" }}
                                key={firstIndex}
                                onClick={() => {
                                    this.setState({
                                        selectedIndex: this.state.selectedIndex === firstIndex ? null : firstIndex
                                    })
                                }}>
                                <TableCell className='eMed_Pat_Tbl_one_style'>
                                    <IconButton
                                        emed_tid ="selectedIndex_03_testID"
                                        size='small'
                                        onClick={() => {
                                            this.setState({
                                                selectedIndex: this.state.selectedIndex === firstIndex ? null : firstIndex
                                            })
                                        }}
                                    >
                                        {this.state.selectedIndex === firstIndex ?
                                            <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style'>{`${item?.appointment_number ? item?.appointment_number : "-"} 
                                | ${item?.appointment_date ? formatDate(item?.appointment_date) : "-"}`}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{item?.bill_count ? item?.bill_count : "-"}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{item?.total_amount ? AmountFormat(item?.total_amount)?.replace(`${CurrencySymbol}`, "") : "-"}</TableCell>
                                {/* <TableCell className='eMed_Pat_Tbl_one_style'>{item?.amount_paid ? item?.amount_paid : "-"}</TableCell> */}
                                <TableCell className='eMed_Pat_Tbl_one_style' sx={{ color: item?.balance_amount > 0 ? "green" : "red" }} align='right'>{item?.balance_amount ? AmountFormat(item?.balance_amount)?.replace(`${CurrencySymbol}`, "") : "-"}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='center' sx={{ display: 'flex', justifyContent: "center", alignItems: "center", visibility: getCachevalue("isForCancelBill") === "true" ? "hidden" : "visible" }}>
                                    <Button
                                        disabled={CheckAccessFunc("front_office", "Home", "Billing", "Bills", "SubTab")?.editAccess && appointment_id ? ((item?.balance_amount == 0 || this.state.creditBillPopUp) ? true : this.state.withOutAppointment?.is_op) : true}
                                        className='eMed_Pat_tbl_two_btn'
                                        onClick={() => {
                                            item.credit_type = FindCreditType(item?.bill_data)

                                            this.setState({ creditBillPopUp: true, opCreditBill: item })
                                        }}
                                    >
                                        <Tooltip placement='top' title={'Receive Payment'} arrow>
                                            <img src={CheckAccessFunc("front_office", "Home", "Billing", "Bills", "SubTab")?.editAccess && appointment_id && item?.balance_amount != 0 ? ImagePaths.Payment.default : ImagePaths.DisPayment.default} className='eMed_Pat_Bill_img' alt='credit' />
                                        </Tooltip>
                                    </Button>
                                    {appointment_id ? this.MenuItem(item, firstIndex) : null}
                                    {/* <Button
                                            //    disabled={CheckAccessFunc("front_office", "Home", "Billing", "Credit Bill", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_op : true}
                                                className='eMed_Pat_tbl_two_btn'
                                                onClick={() => {
                                                    this.setState({ creditBillId:item?.id },()=>{this.getCreditBillPrint()})
                                                }}
                                            >
                                                <Tooltip placement='top' title={'Print'} arrow>
                                                    <img src={ImagePaths.PrintIcons.default}  className='eMed_Pat_Bill_img' alt='print' />
                                                </Tooltip>
                                            </Button> */}
                                    {/* <Button className='eMed_Pat_Bill_more'><MoreVert /></Button> */}
                                </TableCell>
                            </TableRow>
                                <TableRow>
                                    {this.renderTableOne(item, firstIndex)}
                                </TableRow></>
                        )) : <TableRow sx={{ position: 'relative', top: "12vw" }}>
                            <TableCell sx={{ borderBottom: "none" }} />
                            <TableCell sx={{ borderBottom: "none" }} />
                            <TableCell align='center' sx={{ borderBottom: "none" }}>{t("NoRecordsFound")}</TableCell>
                            <TableCell sx={{ borderBottom: "none" }} />
                            <TableCell sx={{ borderBottom: "none" }} />
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    billingCalculation = () => {
        let params = this.state.viewServiceList?.length > 0 ? this.state.viewServiceList : []
        var subAmt = 0
        let amount = 0;

        params.forEach(element => {
            amount = amount + (element.amount_gross - element.service_discount_amount)
            subAmt = amount ? amount.toFixed(2) : 0
        })

        return subAmt
    }
    customFooter = () => {
        var { t } = this.props
        var { selectedBillDetails } = this.state
        return (
            <Box component={'div'} className='eMed_billview_table_footer'>
                <Box component={'div'} position={'relative'} left={"1.1vw"}>
                    <Box component={'div'} className='eMed_bill_view_tbl_footer'>
                        <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.85vw" }}>{t("SubAmount")}</Typography>
                        <Typography mt={0.5} fontSize={"0.85vw"} mr={'0.8vw'}>{`${CurrencySymbol} `} {this.billingCalculation()}</Typography>
                    </Box>
                    <Box component={'div'} className='eMed_bill_view_tbl_footer'>
                        <Typography mt={0.5} fontSize={"0.85vw"} sx={{ color: Colors.SecondaryText, fontSize: "0.8vw" }}>{t("concession") + " %"}</Typography>
                        <Typography mt={0.5} fontSize={"0.85vw"} mr={'0.8vw'}>{selectedBillDetails.discount_percent}</Typography>
                        <Typography mt={0.5} fontSize={"0.85vw"} mr={'0.8vw'}>{`${CurrencySymbol} ` + selectedBillDetails.discount}</Typography>
                    </Box>
                    <Box component={'div'} className='eMed_bill_view_tbl_footer' borderTop={"2px solid gray"}>
                        <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.8vw" }} fontWeight='600'>{t("TotalAmount")}</Typography>
                        <Typography mt={0.5} fontSize={"0.8vw"} mr={'0.8vw'}>{`${CurrencySymbol} ` + selectedBillDetails.amount_net}</Typography>
                    </Box>
                </Box>
            </Box>
        )
    }
    renderViewBillComponent = () => {
        var { t } = this.props
        return (
            <Box margin={1} className='eMed_bill_View_card'>
                <TableContainer className='eMed_bill_view_table'>
                    <Table stickyHeader>
                        <TableHead sx={{ backgroundColor: Colors.Background }}>
                            <TableRow>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Description")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Unit")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{`${t("RateUnit")} (${CurrencySymbol})`}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{`${t("concession")} (${CurrencySymbol})`}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{`${t("TotalAmount")} (${CurrencySymbol})`}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.viewServiceList?.length > 0 && this.state.viewServiceList.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className='eMed_Pat_Tbl_one_style'>{item.service_type}</TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style'>{item.service_unit_count}</TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style'>{item.service_rate_per_unit}</TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style'>{item.service_discount_amount ?
                                        <Tooltip title={item.individual_discount_comments ? `Discount Comments : ${item.individual_discount_comments}` : ""} placement='top' arrow>
                                            <div style={{ fontSize: "0.8rem", fontWeight:600 }}>{(item.service_discount_amount).toFixed(2)}</div>
                                        </Tooltip>
                                        : 0}</TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{item.amount_net ? (item.amount_net).toFixed(2) : 0}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.customFooter()}
            </Box>
        )
    }
    viewBillPopupClose = () => {
        this.setState({
            isBillView: false
        })
    }
    ClosesuccessPopUp = () => {
        this.setState({
            receiptID: null,
            pharmacReceipt: null
        })
    }
    HistoryPopClose() { this.setState({ BillModifyHistoryItem: {} }) }

    render() {
        return (
            <Box component={'div'} className='eMed_Pat_Bill_Container' sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pat_Bill_Header'>
                    {this.renderPatientHeader()}
                </Box>
                <Box component={'div'} className='eMed_Pat_Bill_content'>
                    {this.renderTable()}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.creditBillPopUp ?
                    <CreditBillCompletePoP
                        Module_ID_Key_Name={"appointment_id"}
                        Module_ID_Key_Value={this.state.opCreditBill?.appointment_id}
                        modules={['op', 'lab', 'pharmacy', 'ot', 'radiology']}
                        CrebillPosted={this.creditBillPost}
                        BillData={this.state.PatientDetails}
                        ClosePopUp={this.ClosePopUp.bind(this)}
                        CollectedIn={"OP"}
                    /> : null}
                {this.state.isBillView ?
                    <CommonPopUp width={"57vw"} height={"75vh"}
                        backgroundColor={Colors.white}
                        popUpClose={this.viewBillPopupClose.bind(this)}
                        title={"View Bill Details"}
                        component={this.renderViewBillComponent.bind(this)}
                    />
                    : null
                }
                {
                    this.state.CreditTypeChangePopup ? <CreditTypeChangePop BillData={this.state.dataForTypeChage} CrebillTypeChaged={this.CrebillTypeChaged.bind(this)} ClosePopUp={this.ClosePopUp.bind(this)} /> : null
                }
                {
                    Object.keys(this.state.BillModifyHistoryItem).length > 0 ? <EditHistoryPopup BillType={this.state.BillModifyHistoryItem?.module} BillId={this.state.BillModifyHistoryItem?.id} SelectedBill={this.state.BillModifyHistoryItem} HistoryPopClose={this.HistoryPopClose.bind(this)} /> : null
                }
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.receiptID !== null || this.state.pharmacReceipt !== null ?
                    <BillSuccessPoPUp creditreceipt={this.state.receiptID} phrma_creditreceipt={this.state.pharmacReceipt} ClosePopUp={this.ClosesuccessPopUp.bind(this)} /> : null}
            </Box>
        )
    }
}


export default withTranslation()(PatientCreditBill)