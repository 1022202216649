import React, { Component } from "react";
import { Box, Button, Stack, TextField, Typography, IconButton, Tooltip } from "@mui/material";
import { ImagePaths } from '../../../Utility/ImagePaths';
import './purchase.css';
import { DataGrid } from '@mui/x-data-grid';
import { Trans, withTranslation } from 'react-i18next';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { CurrencySymbol } from "../../../Utility/Constants";

class OrderList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isBtnClicked: false,
            pageSize:10,
            current_page:0,
            searchKey: '',
            disableBtn: false,
        }
    }
    componentDidMount() {
        this._getOrderList();
    }

    componentWillReceiveProps(props){
        if(props.search_key != this.state.searchKey){
            this.setState({
                searchKey: props.search_key
            },() => {
                this._getOrderList()
            })
        }
    }

    _getOrderList = () => {
        this.setState({
            data: [],
            pageSize:10,
            current_page:0
        })
        RestAPIService.getAll(`${Serviceurls.LAB_ORDER_LIST}?search_key=${this.state.searchKey}`)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        data: response.data.data,
                    });
                }
            })
            .catch(error => {
                if (error.response.data.status == "fail") {
                    this.SuccessErrorBox(error.response.data.message, false);
                }
            });
    }

    SuccessErrorBox = (message,type) => {
        this.props.responseSuccessErrorMessage(message,type)
    }  

    orderListPrint = () => {
        this.setState({disableBtn: true})
        RestAPIService.getAll(`${Serviceurls.LAB_ORDER_LIST}?export_type=pdf`)
        .then((response)=>{
            const file = new Blob(
                [response.data],
                {type : 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            this.setState({disableBtn: false})
        }).catch((error)=>{
            this.SuccessErrorBox(error.response.data.message, false); 
            this.setState({disableBtn: false})
        })
    }

    getOrderListexcel = () => {
        try {
          this.setState({disableBtn: true})
          RestAPIService.excelGet(Serviceurls.LAB_ORDER_LIST + "?export_type=excel" + "&search_key=" + this.state.searchKey).
            then((response) => {
              if(response.data){
              var pom = document.createElement('a');
              var csvContent = response.data; //here we load our csv data 
        
              let filename = response.headers["content-disposition"].split("filename=")[1]
        
              var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
              var url = URL.createObjectURL(blob);
              pom.href = url;
              pom.setAttribute('download', filename ? filename : 'registry.xlsx');
              pom.click();
              }
              this.setState({disableBtn: false})
            }).catch((error) => {
                this.SuccessErrorBox(error.response.data.message, false);
                this.setState({disableBtn: false})
            })
        } catch (error) {
            this.SuccessErrorBox(error.message, false);
            this.setState({disableBtn: false})
        }
      }

    render() {
        const { t } = this.props;
        const columns = [
            { field: 's.no', headerClassName: 'grid--header', headerName: t('SNo'), flex: 0.2, sortable: false, hideable: false, renderCell: (index) => index.api.getRowIndex(index.row.vendor_id) + 1 },
            { field: "vendor_name", headerClassName: 'grid--header', headerName: t('VendorName'), flex: 0.8, sortable: false, hideable: false },
            { field: "no_of_product", headerClassName: 'grid--header', headerName: t('NoOfProduct'), flex: 0.7, sortable: false, type: "number" },
            { field: "estimated_price", headerClassName: 'grid--header', headerName: t('EstimatedPrice')+(`(${CurrencySymbol})`), flex: 0.7, sortable: false, hideable: false, type: "number" },
            {
                field: 'actions', headerClassName: 'grid--header', headerName: t('Action'), type: 'actions', flex: 0.7, sortable: false, hideable: false,
                renderCell: (cellvalues) => {
                    return (
                        <Stack direction="row" justifyContent="space-around">
                            <Tooltip title={t("PlaceOrder")} placement="top" arrow><IconButton  emed_tid='vendor_id_Btn_Test_id' size="small" sx={{ borderRadius:"0.3vw"}} onClick={() => {this.setState({isBtnClicked: true ,vendor_id : cellvalues.row.vendor_id},()=>{this.props.goBack("confirmOrder_list", this.state.vendor_id)})  }}><img className="emedhub_vendor_img1" src={ImagePaths.deliveryClose.default} alt={"delivery box"} /></IconButton></Tooltip>
                        </Stack>
                    );
                }
            }
        ]
        let { data, pageSize,current_page} = this.state;
        return (
            <Box className="emedhub__purchase_page" sx={{ '& .grid--header': { backgroundColor: '#FAF9F9' } }}>
                <Stack sx={{ margin: "1vw" }} spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction= "row" spacing={1} alignItems="center"><IconButton size="small" onClick={() => { 
                        if(this.props?.isFromPharmacy){
                            this.props.history.push({ pathname: '/PharmacyPurchase/WantedList'})
                        }else{
                            this.props.goBack("wanted_list")
                        }
                         }} ><img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"}/></IconButton><Typography sx={{color: "#616161"}}> {t("Back To Wanted List")}</Typography></Stack>
                    <Stack direction= "row" spacing={1} alignItems="center">
                    <IconButton size="small" emed_tid='Order_List_Print_Btn' disabled={this.state.disableBtn} onClick={()=>{this.orderListPrint()}}><img className="emedhub_directPo_img"  src={ImagePaths.LabPrintIcon.default} /></IconButton>
                    <IconButton size="small" emed_tid='Order_List_Export_Btn' disabled={this.state.disableBtn} onClick={()=>this.getOrderListexcel()}><img className="emedhub_directPo_img"  src={ImagePaths.LabUploadButton.default} /></IconButton>
                    </Stack>
                </Stack>
                <div id="emedhub_ventor_Table">
                    <DataGrid
                        rows={data}
                        columns={columns}
                        disableColumnFilter
                        initialState={{
                            pagination: {
                                page: current_page,
                              }
                          }}
                          hideFooterSelectedRowCount
                          components={{
                            NoRowsOverlay: ()=>{
                                return(
                                    <Stack direction="row" justifyContent = "center" alignItems = "center" sx={{height:"53vh"}}>
                                    {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }
                        }}
                        getRowId={(row) => row.vendor_id}
                        onCellClick={this.handleCellClick}
                        onRowClick={this.handleCellClick}
                        rowCount={data.length}
                        pageSize={pageSize}
                        onPageChange={(newPage)=>this.setState({ current_page: newPage })}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize})}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination />
                </div>
            </Box>
        )
    }
}

export default withTranslation()(OrderList);