import React, { Component } from 'react'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Autocomplete, Box, Button, Chip, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { Colors } from '../../../../Styles/Colors'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import ShowComponents from './ShowComponent';

export default class DocTest extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            labGroupName: [],
            labTestName: [],
            labList: [],
            specialTestList: [],
            selectedLabName: '',
            selectedTestName: [],
            selectedTestGroup: [],
            selectedSetName: '',
            searchTestName: '',
            searchGroupName: '',
            searchSetName: '',
            labNameLoad: false,
            labGroupLoad: false,
            specialLoad: false,
            postLoading: false,
            specialData: [],
            selectedSpecialData: '',
            selectedGroup: null,
            selectedTest: [],
            selectedSpecial: null,
            transmitPopupOpen: false,
            transmittedLab: null,
            removeData: false,
            selectedId: null,
            isLocked: false,
            laboratory_id: '',
            transmitted: false
        }
    }

    componentDidMount() {
        let clinicId = getCachevalue('SelectedDoctorClinic')
        let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
        this.setState({
            clinicId: clinicId,
            appointmentId: appointmentId?.appointment_id,
            patientId: appointmentId?.patient_id,
            isLocked: appointmentId?.is_locked
        }, () => {
            this.getTestData()
            this.getLabList()
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getLabList = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_LAB_LIST + `?clinic_id=${this.state.clinicId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            labList: response.data.data?.laboratory_data,
                            selectedLabName: response.data.data?.laboratory_data[0]?.laboratory_id
                        }, () => {
                            this.getTestNameList()
                            this.getTestGroupList()
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getTestNameList = () => {
        try {
            this.setState({ labNameLoad: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_TEST_NAME_GET + `?laboratory_id=${this.state.selectedLabName}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            labTestName: response.data.data,
                            labNameLoad: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ labNameLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ labNameLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ labNameLoad: false })
            this.errorMessage(error.message)
        }
    }

    getTestGroupList = () => {
        try {
            this.setState({ labGroupLoad: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_TEST_GROUP_GET + `?laboratory_id=${this.state.selectedLabName}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            labGroupName: response.data.data,
                            labGroupLoad: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ labGroupLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ labGroupLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ labGroupLoad: false })
            this.errorMessage(error.message)
        }
    }

    getSpecialTestList = () => {
        try {
            this.setState({ specialLoad: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_SPECIAL_TEST_SEARCH + `?search=${this.state.searchSetName}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            specialTestList: response.data.data,
                            specialLoad: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ specialLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ specialLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ specialLoad: false })
            this.errorMessage(error.message)
        }
    }

    getTestData = () => {
        try {
            let appointmentId = this.props.prevAppointmentId ? this.props.prevAppointmentId : this.state.appointmentId
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_TEST_GET + `${this.state.patientId}/?appointment_id=${appointmentId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            testData: response.data.data,
                            laboratory_id: response.data.laboratory_id,
                            transmitted: response.data.is_transmitted
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postTestData = () => {
        try {
            let labTests = []; let labSets = []; let specialTests = [];
            this.state.selectedTestName?.forEach(element => {
                labTests?.push({
                    lab_tests_id: element?.id,
                    lab_test_name: element?.test_name
                })
            })
            this.state.selectedTestGroup?.forEach(element => {
                labSets?.push({
                    lab_set_id: element?.id,
                    lab_set_name: element?.name
                })
            })
            this.state.specialData?.forEach(element => {
                specialTests?.push({
                    special_test_id: element?.id ? element?.id : null,
                    special_test_name: element?.name ? element?.name : element
                })
            })
            let data = {
                laboratory_id: this.state.selectedLabName,
                patient_id: this.state.patientId,
                appointment_id: this.state.appointmentId,
                lab_sets: labSets,
                lab_tests: labTests,
                special_tests: specialTests
            }
            let validate = this.checkValidate()
            if (validate === null) {
                this.setState({ postLoading: true })
                RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_TEST_POST)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.onClearHandler()
                            this.getTestData()
                            this.setState({ openPopUp: false, postLoading: false, isEdit: false })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage(validate)
            }
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }

    postTestTransmitData = () => {
        try {
            let testIds = [];
            this.state.testData?.forEach(element => {
                testIds?.push(element?.id)
            });
            let data = {
                appointment_id: this.state.appointmentId,
                // laboratory_id: +this.state.laboratory_id,
                patient_id: this.state.patientId,
                line_item_ids: this.state.testData
            }
            this.setState({ postLoading: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_TEST_TRANSMIT)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.getTestData()
                        this.setState({ transmitPopupOpen: false, postLoading: false, isEdit: false, transmittedLab: null, transmitted: true })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ postLoading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ postLoading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    deleteTestData = () => {
        try {
            let data = {
                appointment_id: this.state.appointmentId,
                tests_id: this.state.selectedId
            }
            this.setState({ removeData: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_TEST_DELETE)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.getTestData()
                        this.setState({ transmitPopupOpen: false, deletePopup: false, removeData: false, selectedId: null })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ removeData: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ removeData: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    printTestData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_TEST_PRINT + `?appointment_id=${this.state.appointmentId}&patient_id=${this.state.patientId}`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    popupClose = () => {
        try {
            this.setState({
                deletePopup: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearHandler = () => {
        try {
            this.setState({
                selectedTestName: [],
                selectedTestGroup: [],
                selectedSetName: '',
                searchTestName: '',
                searchGroupName: '',
                searchSetName: '',
                specialData: [],
                selectedSpecialData: '',
                selectedGroup: null,
                selectedTest: null,
                selectedSpecial: null,
                selectedId: null
            }
            , () => this.setState({ openPopUp: true }, () => this.getLabList())
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkValidation = () => {
        try {
            let message = null;
            if (CommonValidation.removeSpace(this.state.searchSetName) !== '') {
                this.state.specialData?.forEach(element => {
                    if ((element?.name === this.state.searchSetName?.name) || (element === this.state.searchSetName)) {
                        message = 'Special test already exists'
                    }
                })
            } else {
                message = 'Select or Enter Special Test'
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    addSpecialTest = () => {
        try {
            let validation = this.checkValidation()
            if (validation === null) {
                let data = CommonValidation.updateOrInsertList(this.state.specialData, CommonValidation.removeSpace(this.state.searchSetName))
                this.setState({
                    specialData: data
                }, () => {
                    this.setState({ searchSetName: '', specialTestList: [] })
                })
            } else {
                this.errorMessage(validation)
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (item) => {
        try {
            if (!this.state.isEdit) {
                this.setState({
                    deletePopup: true,
                    selectedId: item?.id
                })
            } else {
                this.errorMessage("You have clicked edit Button.So,You cannot able to delete")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkValidate = () => {
        try {
            let validate = null
            let states = this.state
            if (states.selectedLabName === '') {
                validate = 'Select Lab Name'
            } else if ((states.selectedTestName?.length > 0) || (states.selectedTestGroup?.length > 0) || (states.specialData?.length > 0)) {
                validate = null
            } else {
                validate = 'Select atleast one test'
            }
            return validate;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderPopUpScreen = () => {
        try {
            return (
                <Box>
                    <Modal
                        open={this.state.openPopUp}
                    >
                        <Box component={'div'} className='eMed_Doc_Notes_Test_Pop_Up'>
                            <Box pl={'1vw'} component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={'0.8vw'} fontWeight={600} color={Colors.SecondaryText}>Add Test</Typography>
                                <Button
                                    className='Common_Btn_Min_Width'
                                    onClick={() => this.setState({ openPopUp: false, isEdit: false })}
                                >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>
                            <Box component={'div'} overflow={'scroll'} pl={'1vw'}>
                                <Box component={'div'}>
                                    <Box component={'div'} height={'2vw'} display={'flex'} justifyContent={'flex-end'} mb={'1vw'} alignItems={'center'}>
                                        <Box component={'div'}>
                                            <FormControl size='small' sx={{ mt: '1.5vw', mr: '1vw' }}>
                                                <InputLabel>Select Laboratory</InputLabel>
                                                <Select
                                                    sx={{ width: '15vw' }}
                                                    label='Select Laboratory'
                                                    value={this.state.selectedLabName}
                                                    onChange={(e) => this.setState({ selectedLabName: e.target.value }, () => {
                                                        this.getTestNameList()
                                                        this.getTestGroupList()
                                                    })}
                                                >
                                                    {this.state.labList?.map((item, index) => (
                                                        <MenuItem key={index} value={item?.laboratory_id}>{item?.laboratory__laboratory_name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box component={'div'}>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.8vw'} fontWeight={600} color={Colors.SecondaryText}>Test Name</Typography>
                                    </Box>
                                    <Box component={'div'} mt={'0.5vw'}>
                                        <Autocomplete
                                            size='small'
                                            clearIcon
                                            sx={{ width: '25vw' }}
                                            multiple
                                            limitTags={3}
                                            options={this.state.labTestName}
                                            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.test_name}
                                            value={this.state.selectedTestName}
                                            onChange={(e, value) => {
                                                this.setState({
                                                    selectedTestName: value
                                                })
                                            }}
                                            renderInput={(params) => <TextField {...params} label='Select Test Name' />}
                                        />
                                    </Box>
                                </Box>
                                <Box component={'div'} mt={'0.5vw'}>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.8vw'} fontWeight={600} color={Colors.SecondaryText}>Test Group</Typography>
                                    </Box>
                                    <Box component={'div'} mt={'0.5vw'}>
                                        <Autocomplete
                                            size='small'
                                            clearIcon
                                            sx={{ width: '25vw' }}
                                            multiple
                                            limitTags={3}
                                            options={this.state.labGroupName}
                                            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.name}
                                            value={this.state.selectedTestGroup}
                                            onChange={(e, value) => {
                                                this.setState({
                                                    selectedTestGroup: value
                                                })
                                            }}
                                            renderInput={(params) => <TextField {...params} label='Select Group Name' />}
                                        />
                                    </Box>
                                </Box>
                                <Box component={'div'} >
                                    <Box component={'div'} mt={'0.5vw'}>
                                        <Typography fontSize={'0.8vw'} fontWeight={600} color={Colors.SecondaryText}>Special Test</Typography>
                                    </Box>
                                    <Box component={'div'} display={'flex'} mt={'0.5vw'}>
                                        <Autocomplete
                                            clearIcon
                                            size='small'
                                            freeSolo
                                            sx={{ width: '25vw', mr: '1vw' }}
                                            value={this.state.searchSetName}
                                            options={this.state.specialTestList}
                                            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.name}
                                            onChange={(e, newValue) => {
                                                this.setState({
                                                    searchSetName: newValue
                                                }, () => {
                                                    if (CommonValidation.removeSpace(this.state.searchSetName?.name)?.length > 0) {
                                                        this.addSpecialTest()
                                                    } else {
                                                        this.setState({ specialTestList: [] })
                                                    }
                                                })
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                onChange={(e) => {
                                                    if (CommonValidation.removeSpace(e.target.value)?.length > 0) {
                                                        this.setState({
                                                            searchSetName: e.target.value
                                                        }, () => this.getSpecialTestList())
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        if (CommonValidation.removeSpace(e.target.value)?.length > 0) {
                                                            this.addSpecialTest()
                                                        } else {
                                                            this.errorMessage('Search or Enter Special Test')
                                                        }
                                                    }
                                                }}
                                                label={'Search or Enter Special Test'} />}
                                        />
                                        {/* <Button variant='contained'
                                            sx={{ textTransform: 'capitalize' }}
                                            onClick={() => this.addSpecialTest()}
                                        >Add</Button> */}
                                    </Box>
                                    <Box component={'div'} mr={'1vw'} mt={'1vw'} overflow={'scroll'} maxHeight={'15vw'}>
                                        {
                                            this.state.specialData?.map((item, index) => (
                                                <Chip
                                                    className='eMed_chip'
                                                    size='small'
                                                    label={((item.name?.length > 10) || (item?.length > 10)) ? <Tooltip placement='top' title={item?.name ? item?.name : item}><Typography fontSize={'0.8vw'} textTransform={'capitalize'}>{item?.name ? (item?.name?.slice(0, 10) + '...') : (item?.slice(0, 10) + '...')}</Typography></Tooltip> : <Typography fontSize={'0.8vw'} textTransform={'capitalize'}>{item?.name ? item?.name : item}</Typography>}
                                                    // variant={((item.name === this.state.selectedSetName) || (item === this.state.selectedSetName)) ? 'contained' : 'outlined'}
                                                    variant='outlined'
                                                    key={index}
                                                    // onClick={() => { this.setState({ selectedSetName: item?.name ? item?.name : item, selectedSpecial: item }) }}
                                                    color={'primary'}
                                                    clickable
                                                    onDelete={() => {
                                                        this.state.specialData?.splice(index, 1)
                                                        this.setState({
                                                            specialData: this.state.specialData
                                                        })
                                                    }}
                                                />
                                            ))}
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => this.onClearHandler()}
                                >Clear</Button>
                                <Button
                                    disabled={this.state.postLoading}
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    variant='contained'
                                    onClick={() => this.postTestData()}
                                >Save</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    // renderTransmitPopup = () => {
    //     try {
    //         return (
    //             <Box>
    //                 <Modal open={this.state.transmitPopupOpen}>
    //                     <Box component={'div'} className='eMed_Doc_Notes_Test_Trans_Pop_Up'>
    //                         <Box pl={'1vw'} component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
    //                             <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Transmit Test</Typography>
    //                             <Button
    //                                 className='Common_Btn_Min_Width'
    //                                 onClick={() => this.setState({ transmitPopupOpen: false })}
    //                             >
    //                                 <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
    //                             </Button>
    //                         </Box>
    //                         <Box component={'div'} display={'flex'} justifyContent={'center'} mt={'1vw'}>
    //                             <Autocomplete
    //                                 clearIcon
    //                                 size='small'
    //                                 sx={{ width: '20vw' }}
    //                                 value={this.state.transmittedLab}
    //                                 options={this.state.labList}
    //                                 getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.laboratory__laboratory_name}
    //                                 onChange={(e, value) => this.setState({ transmittedLab: value })}
    //                                 renderInput={(params) => <TextField {...params} label={'Select Laboratory'} />}
    //                             />
    //                         </Box>
    //                         <Box component={'div'}>
    //                             <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
    //                                 <Button
    //                                     sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
    //                                     variant='outlined'
    //                                     onClick={() => this.setState({ transmittedLab: null })}
    //                                 >Clear</Button>
    //                                 <Button
    //                                     disabled={this.state.postLoading}
    //                                     sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
    //                                     variant='contained'
    //                                     onClick={() => this.postTestTransmitData()}
    //                                 >Add</Button>
    //                             </Box>
    //                         </Box>
    //                     </Box>
    //                 </Modal>
    //             </Box>
    //         )
    //     } catch (error) {
    //         this.errorMessage(error.message)
    //     }
    // }
    render() {
        return (
            <ShowComponents onIntersection={this.getTestData.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.Background }} p={'0.5vw'}>
                    <Box component={'div'} height={'2.8vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                        <Box component={'div'}>
                            <Typography fontWeight={600} fontSize={'0.8vw'}>Test
                                <Box component={'span'}>
                                    <Button emed_tid='tst_trs_btn'
                                        className='Common_Btn_Min_Width'
                                        disabled={this.state.isLocked || this.props.isLocked}
                                        onClick={() => { this.postTestTransmitData() }}
                                    >
                                        <Tooltip placement='top' title='Transfer'>
                                            <Box component={'img'} src={ImagePaths.medicationTransmitOn.default} height={'1.1vw'} width={'1.1vw'} />
                                        </Tooltip>
                                    </Button>
                                </Box></Typography>
                            <Typography fontSize={'0.8vw'} color={'gray'}>Entered by
                                <Typography component={'span'} fontWeight={600} fontSize={'0.8vw'} color={Colors.SecondaryText}> Hospital Nurse</Typography>
                            </Typography>
                        </Box>
                        <Box component={'div'}>
                            <FormControl size='small'>
                                <InputLabel>Select Lab</InputLabel>
                                <Select
                                    sx={{ width: '10vw' }}
                                    label='Select Lab'
                                    value={this.state.selectedLabName}
                                    onChange={(e) => this.setState({ selectedLabName: e.target.value }, () => {
                                        this.getTestNameList()
                                        this.getTestGroupList()
                                    })}
                                >
                                    {this.state.labList?.map((item, index) => (
                                        <MenuItem key={index} value={item?.laboratory_id}>{item?.laboratory__laboratory_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button emed_tid='tst_prt_btn'
                                className='Common_Btn_Min_Width'
                                onClick={() => this.printTestData()}
                            >
                                <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
                            </Button>
                            <Button emed_tid='tst_clr_btn'
                                variant='contained'
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                onClick={() => this.onClearHandler()}
                                disabled={this.state.isLocked || this.props.isLocked || this.state.transmitted}
                            >Add</Button>
                        </Box>
                    </Box>
                    <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Autocomplete
                            size='small'
                            clearIcon
                            sx={{ width: '10vw' }}
                            multiple
                            limitTags={3}
                            options={this.state.labTestName}
                            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.test_name}
                            value={this.state.selectedTestName}
                            onChange={(e, value) => {
                                this.setState({
                                    selectedTestName: value
                                })
                            }}
                            renderInput={(params) => <TextField {...params} label='Test Name' />}
                        />
                        <Autocomplete
                            size='small'
                            clearIcon
                            sx={{ width: '10vw' }}
                            multiple
                            limitTags={3}
                            options={this.state.labGroupName}
                            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.name}
                            value={this.state.selectedTestGroup}
                            onChange={(e, value) => {
                                this.setState({
                                    selectedTestGroup: value
                                })
                            }}
                            renderInput={(params) => <TextField {...params} label='Group Name' />}
                        />
                        <Autocomplete
                            clearIcon
                            size='small'
                            freeSolo
                            sx={{ width: '10vw' }}
                            value={this.state.searchSetName}
                            options={this.state.specialTestList}
                            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.name}
                            onChange={(e, newValue) => {
                                this.setState({
                                    searchSetName: newValue
                                }, () => {
                                    if (CommonValidation.removeSpace(this.state.searchSetName?.name)?.length > 0) {
                                        this.addSpecialTest()
                                    } else {
                                        this.setState({ specialTestList: [] })
                                    }
                                })
                            }}
                            renderInput={(params) => <TextField {...params}
                                onChange={(e) => {
                                    if (CommonValidation.removeSpace(e.target.value)?.length > 0) {
                                        this.setState({
                                            searchSetName: e.target.value
                                        }, () => this.getSpecialTestList())
                                    }
                                }}
                                // onKeyDown={(e) => {
                                //     if (e.key === 'Enter') {
                                //         if (CommonValidation.removeSpace(e.target.value)?.length > 0) {
                                //             this.addSpecialTest()
                                //         } else {
                                //             this.errorMessage('Special Test')
                                //         }
                                //     }
                                // }}
                                label={'Search or Enter Special Test'} />}
                        />
                    </Box>
                        <Box component={'div'} sx={{ border: '1px solid lightgray', maxHeight: '20vw', overflow: 'auto' }}>
                            <Table stickyHeader size='small' emed_tid='tst_tbl_data'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.8vw'} color={Colors.SecondaryText}>Test Name</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.8vw'} color={Colors.SecondaryText}>Status</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.testData?.length > 0 ? this.state.testData?.map((item, index) => {
                                        let testName = '';
                                        if (item?.lab_test_names) {

                                            testName = item?.lab_test_names?.length > 10 ?
                                                <Tooltip placement='top' title={item?.lab_test_names}>
                                                    <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.lab_test_names?.slice(0, 10) + '...'}</Typography>
                                                </Tooltip> :
                                                item?.lab_test_names

                                        } else if (item?.lab_set_names) {

                                            testName = item?.lab_set_names?.length > 10 ?
                                                <Tooltip placement='top' title={item?.lab_set_names}>
                                                    <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.lab_set_names?.slice(0, 10) + '...'}</Typography>
                                                </Tooltip> :
                                                item?.lab_set_names

                                        } else if (item?.special_test_name) {

                                            testName = item?.special_test_name?.length > 10 ?
                                                <Tooltip placement='top' title={item?.special_test_name}>
                                                    <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.special_test_name?.slice(0, 10) + '...'}</Typography>
                                                </Tooltip> :
                                                item?.special_test_name
                                        }
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Box component={'div'} display={'flex'} alignItems={'center'}>
                                                        <Button className='Common_Btn_Min_Width' emed_tid='tst_del_btn'
                                                            onClick={() => this.onDeleteHandler(item)}
                                                            disabled={this.state.isLocked || this.props.isLocked}
                                                        >
                                                            <Box height={'1vw'} width={'1vw'} component={'img'} src={ImagePaths.DeleteOutline.default} />
                                                        </Button>
                                                        <Typography fontWeight={600} fontSize={'0.8vw'}>
                                                            {testName}
                                                        </Typography>
                                                        <Tooltip placement='top' title={item?.laboratory_name}>
                                                            <Box height={'1vw'} width={'1vw'} component={'img'} ml={"0.5vw"} src={ImagePaths.Info.default} />
                                                        </Tooltip>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography fontWeight={600} fontSize={'0.8vw'}>
                                                        {`${item?.status ? item?.status : "In Progress"}`}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }) :
                                        <TableRow>
                                            <TableCell align='center'>No Records To Be Shown</TableCell>
                                            <TableCell />
                                        </TableRow>}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                    {this.renderPopUpScreen()}
                    {/* {this.renderTransmitPopup()} */}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.deletePopup ? <DeletePopup
                        DeletTitle={`Are you sure you want to delete ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.deleteTestData.bind(this)}
                        disable={this.state.removeData}
                    /> : null}
                </Box>
            </ShowComponents>
        )
    }
}
