import React, { Component } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { Colors } from '../../../../Styles/Colors'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import ShowComponents from './ShowComponent';

export default class DocProvisionalDiagonisis extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            get_provisional_diagnosis: '',
            diagonisData: [],
            editProvisional: false,
            postLoading: false,
            provisional_diagnosis: ""
        }
    }

    apiCalls = () => {
        this.getDiagonisData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onClearHandler = () => {
        try {
            this.setState({
                shortIcd: '',
                longIcd: '',
                icd: '',
                selectedIcd: null,
                provisional_diagnosis: '',
                searchedIcdCode: '',
                searchedShortIcd: ''
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDiagonisData = () => {
        try {
            let appointmentId = this.props.prevAppointmentId ? this.props.prevAppointmentId : this.state.appointmentId
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_ASSESSEMENT + `?patient_id=${this.state.patientId}&appointment_id=${appointmentId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            get_provisional_diagnosis: response.data.data?.provisional_diagnosis ? response.data.data?.provisional_diagnosis : ''
                        }, () => {
                            if (this.state.get_provisional_diagnosis) {
                                this.setState({
                                    editProvisional: true
                                })
                            }
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postDiagonisData = () => {
        try {
            let data = {
                appointment_id: this.state.appointmentId,
                patient_id: this.state.patientId,
                id: this.state.selectedId ? this.state.selectedId : null,
                provisional_diagnosis: this.state.provisional_diagnosis
            }
            this.setState({ postLoading: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_ASSESSEMENT)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.onClearHandler()
                        this.getDiagonisData()
                        this.setState({ postLoading: false, openPopUp: false, selectedId: null })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ postLoading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ postLoading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} height={'2.5vw'} sx={{ backgroundColor: Colors.Background, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingX: "0.5vw" }}>
                        <Typography fontWeight={600}>Provisional Diagnosis</Typography>
                        <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            {
                                this.state.editProvisional ?
                                    <Button className='Common_Btn_Min_Width'
                                        sx={{ mr: '1vw' }}
                                        onClick={() => this.setState({ editProvisional: false })}
                                        disabled={this.props.isLocked}
                                    >
                                        {this.props.isLocked ?
                                            <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                            :
                                            <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                        }
                                    </Button> :
                                    <Button className='Common_Btn_Min_Width'
                                        disabled={this.props.isLocked || this.state.postLoading || (this.state.provisional_diagnosis === '')}
                                        sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                        variant='contained'
                                        onClick={() => this.postDiagonisData()}
                                    >Add
                                    </Button>
                            }
                        </Box>
                    </Box>
                    <Box component={'div'} m={'0.5vw'}>
                        <TextField
                            multiline
                            rows={3}
                            fullWidth
                            disabled={this.props.isLocked}
                            value={this.state.provisional_diagnosis ? this.state.provisional_diagnosis : this.state.get_provisional_diagnosis}
                            InputProps={{ readOnly: this.state.editProvisional }}
                            onChange={(e) => {
                                let value = e.target.value
                                if (value?.length <= 250) {
                                    this.setState({
                                        provisional_diagnosis: value
                                    })
                                } else {
                                    this.errorMessage('Allowed only 250 characters')
                                }
                            }}
                        />
                    </Box>
                    {
                        this.state.isErrorMsg ?
                            <ToastMsg
                                severity={'error'}
                                msg={this.state.isErrorMsgText}
                                msgPop={this.msgClose.bind(this)}
                            />
                            : null
                    }
                    {
                        this.state.successMsg ?
                            <ToastMsg
                                severity={'success'}
                                msg={this.state.successMsgText}
                                msgPop={this.msgClose.bind(this)}
                            />
                            : null
                    }
                </Box>
            </ShowComponents>
        )
    }
}