import { Box, Button, Checkbox, Chip, FormControlLabel, Modal, Radio, RadioGroup, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import ShowComponents from './ShowComponent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default class DocDiagonisis extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            appointmentDate: this.props.appointmentDate,
            openPopUp: false,
            postLoading: false,
            isLocked: this.props.isLocked,
            selectedTab: "BeforeDilation",
            selectedEye: "RightEye",
            getAvailableEyeTypes: [],
            getAvailableEyeTypesId: [],
            B_R_Inferior: "",
            B_R_Nasal: "",
            B_R_Superior: "",
            B_R_Temporal: "",
            B_L_Inferior: "",
            B_L_Nasal: "",
            B_L_Superior: "",
            B_L_Temporal: "",
            A_R_Inferior: "",
            A_R_Nasal: "",
            A_R_Superior: "",
            A_R_Temporal: "",
            A_L_Inferior: "",
            A_L_Nasal: "",
            A_L_Superior: "",
            A_L_Temporal: "",
            itemList: [],
            itemListAfter: [],
            PreitemList: [],
            PreitemListAfter: [],
            RE_Inferior: "",
            RE_Nasal: "",
            RE_Superior: "",
            RE_Temporal: "",
            LE_Inferior: "",
            LE_Nasal: "",
            LE_Superior: "",
            LE_Temporal: "",
            comments: "",
            Leftchecked: false,
            Rightchecked: false,
            isEdit: false,
            disableAfter: false,
            disableBefore: false,
            comparePopup: false,
            selectedType: null
        }
    }

    apiCalls = () => {
        this.getGonioscopyData()
        // this.getPreviousGonioscopyData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getGonioscopyData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_GONIOSCOPY + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let { itemList } = this.state;
                        itemList = response.data && response.data.Gonioscopy_data ? response.data.Gonioscopy_data : [];
                        var data = itemList;
                        var availableEyeId = []

                        for (var i = 0; i < data.length; i++) {
                            var prepareData = {
                                "eye_type": data[i].eye_type,
                                "id": data[i].id,
                                "dilation": data[i].before_dilation == true ? "BeforeDilation" : "AfterDilation"
                            }
                            availableEyeId.push(prepareData);
                        }

                        var BeforeDilationRE = []
                        var AfterDilationRE = []
                        var BeforeDilationLE = []
                        var AfterDilationLE = []
                        for (var i = 0; i < data.length; i++) {
                            if (data[i].before_dilation == true && data[i].eye_type == "RE") {
                                BeforeDilationRE.push(data[i])
                            } else if (data[i].before_dilation == true && data[i].eye_type == "LE") {
                                BeforeDilationLE.push(data[i])
                            } else if (data[i].before_dilation == false && data[i].eye_type == "RE") {
                                AfterDilationRE.push(data[i])
                            } else if (data[i].before_dilation == false && data[i].eye_type == "LE") {
                                AfterDilationLE.push(data[i])
                            }
                        }

                        this.setState({
                            B_R_Inferior: BeforeDilationRE[0]?.inferior_grade ? BeforeDilationRE[0].inferior_grade : "",
                            B_R_Nasal: BeforeDilationRE[0]?.nasal_grade ? BeforeDilationRE[0].nasal_grade : "",
                            B_R_Superior: BeforeDilationRE[0]?.superior_grade ? BeforeDilationRE[0].superior_grade : "",
                            B_R_Temporal: BeforeDilationRE[0]?.temporal_grade ? BeforeDilationRE[0].temporal_grade : "",
                            B_Comments: BeforeDilationRE[0]?.comments ? BeforeDilationRE[0].comments : BeforeDilationLE[0]?.comments ? BeforeDilationLE[0].comments : "",
                            B_L_Inferior: BeforeDilationLE[0]?.inferior_grade ? BeforeDilationLE[0].inferior_grade : "",
                            B_L_Nasal: BeforeDilationLE[0]?.nasal_grade ? BeforeDilationLE[0].nasal_grade : "",
                            B_L_Superior: BeforeDilationLE[0]?.superior_grade ? BeforeDilationLE[0].superior_grade : "",
                            B_L_Temporal: BeforeDilationLE[0]?.temporal_grade ? BeforeDilationLE[0].temporal_grade : "",
                            A_R_Inferior: AfterDilationRE[0]?.inferior_grade ? AfterDilationRE[0].inferior_grade : "",
                            A_R_Nasal: AfterDilationRE[0]?.nasal_grade ? AfterDilationRE[0].nasal_grade : "",
                            A_R_Superior: AfterDilationRE[0]?.superior_grade ? AfterDilationRE[0].superior_grade : '',
                            A_R_Temporal: AfterDilationRE[0]?.temporal_grade ? AfterDilationRE[0].temporal_grade : "",
                            A_Comments: AfterDilationRE[0]?.comments ? AfterDilationRE[0].comments : AfterDilationLE[0]?.comments ? AfterDilationLE[0].comments : "",
                            A_L_Inferior: AfterDilationLE[0]?.inferior_grade ? AfterDilationLE[0].inferior_grade : "",
                            A_L_Nasal: AfterDilationLE[0]?.nasal_grade ? AfterDilationLE[0].nasal_grade : "",
                            A_L_Superior: AfterDilationLE[0]?.superior_grade ? AfterDilationLE[0].superior_grade : "",
                            A_L_Temporal: AfterDilationLE[0]?.temporal_grade ? AfterDilationLE[0].temporal_grade : "",
                            getAvailableEyeTypesId: availableEyeId,
                            itemList: { 0: BeforeDilationRE[0], 1: BeforeDilationLE[0] },
                            itemListAfter: { 0: AfterDilationRE[0], 1: AfterDilationLE[0] }
                        })

                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPreviousGonioscopyData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PREVIOUS_GONIOSCOPY + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&appointment_date=${this.state.appointmentDate}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let { itemList } = this.state;
                        itemList = response.data && response.data.data ? response.data.data : [];
                        var data = itemList;

                        var BeforeDilationRE = []
                        var AfterDilationRE = []
                        var BeforeDilationLE = []
                        var AfterDilationLE = []
                        for (var i = 0; i < data.length; i++) {
                            if (data[i].before_dilation == true && data[i].eye_type == "RE") {
                                BeforeDilationRE.push(data[i])
                            } else if (data[i].before_dilation == true && data[i].eye_type == "LE") {
                                BeforeDilationLE.push(data[i])
                            } else if (data[i].before_dilation == false && data[i].eye_type == "RE") {
                                AfterDilationRE.push(data[i])
                            } else if (data[i].before_dilation == false && data[i].eye_type == "LE") {
                                AfterDilationLE.push(data[i])
                            }
                        }
                        this.setState({
                            PreitemList: { 0: BeforeDilationRE[0], 1: BeforeDilationLE[0] },
                            PreitemListAfter: { 0: AfterDilationRE[0], 1: AfterDilationLE[0] }
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PostGonioScopyData = () => {
        try {
            var data = []; let { BL_ID, BR_ID, AL_ID, AR_ID } = this.state
            let states = this.state
            if (
                (states.LE_Inferior != "" || states.LE_Nasal != "" || states.LE_Superior != "" || states.LE_Temporal != "") &&
                (states.RE_Inferior != "" || states.RE_Nasal != "" || states.RE_Superior != "" || states.RE_Temporal != "")
            ) {
                data = [
                    {
                        "appointment_id": states.appointmentId,
                        "eye_type": "RE",
                        "before_dilation": states.selectedTab === "BeforeDilation" ? true : false,
                        "inferior_grade": states.RE_Inferior,
                        "superior_grade": states.RE_Superior,
                        "temporal_grade": states.RE_Temporal,
                        "nasal_grade": states.RE_Nasal,
                        "comments": states.comments
                    },
                    {
                        "appointment_id": states.appointmentId,
                        "eye_type": "LE",
                        "before_dilation": states.selectedTab === "BeforeDilation" ? true : false,
                        "inferior_grade": states.LE_Inferior,
                        "superior_grade": states.LE_Superior,
                        "temporal_grade": states.LE_Temporal,
                        "nasal_grade": states.LE_Nasal,
                        "comments": states.comments
                    }
                ];
                if (BR_ID && states.selectedTab === "BeforeDilation") { data[0]["id"] = BR_ID; }
                else if (AR_ID && states.selectedTab != "BeforeDilation") { data[0]["id"] = AR_ID; }
                if (BL_ID && states.selectedTab === "BeforeDilation") { data[1]["id"] = BL_ID; }
                else if (AL_ID && states.selectedTab != "BeforeDilation") { data[1]["id"] = AL_ID; }
            }
            else if (this.state.selectedEye == "RightEye") {
                data = [
                    {
                        "appointment_id": states.appointmentId,
                        "eye_type": "RE",
                        "before_dilation": states.selectedTab === "BeforeDilation" ? true : false,
                        "inferior_grade": states.RE_Inferior,
                        "superior_grade": states.RE_Superior,
                        "temporal_grade": states.RE_Temporal,
                        "nasal_grade": states.RE_Nasal,
                        "comments": states.comments
                    }
                ];
                if (BR_ID && states.selectedTab === "BeforeDilation") { data[0]["id"] = BR_ID; }
                else if (AR_ID && states.selectedTab != "BeforeDilation") { data[0]["id"] = AR_ID; }
            }
            else if (this.state.selectedEye == "LeftEye") {
                data = [
                    {
                        "appointment_id": states.appointmentId,
                        "eye_type": "LE",
                        "before_dilation": states.selectedTab === "BeforeDilation" ? true : false,
                        "inferior_grade": states.LE_Inferior,
                        "superior_grade": states.LE_Superior,
                        "temporal_grade": states.LE_Temporal,
                        "nasal_grade": states.LE_Nasal,
                        "comments": states.comments
                    }
                ];
                if (BL_ID && states.selectedTab === "BeforeDilation") { data[0]["id"] = BL_ID; }
                else if (AL_ID && states.selectedTab != "BeforeDilation") { data[0]["id"] = AL_ID; }
            }
            let validate = this.checkValidation()
            if (validate == null) {
                this.setState({ postLoading: true })
                RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_GONIOSCOPY)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.handleClear()
                            this.setState({
                                postLoading: false, openPopUp: false,
                                BL_ID: "", BR_ID: "", AL_ID: "", AR_ID: ""
                            }, () => {
                                this.getGonioscopyData()
                            })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.message)
                        }
                    })
            }
            else { this.errorMessage(validate) }
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }

    checkValidation = () => {
        var states = this.state
        if ((states.LE_Inferior == "" && states.LE_Nasal == "" && states.LE_Superior == "" && states.LE_Temporal == "") &&
            (states.RE_Inferior == "" && states.RE_Nasal == "" && states.RE_Superior == "" && states.RE_Temporal == "")) {
            return "Enter Any Eye Value"
        } else {
            return null
        }
    }

    handleClear = () => {
        if (this.state.isEdit && this.state.selectedType == "After") {
            this.setState({
                RE_Inferior: "",
                RE_Nasal: "",
                RE_Superior: "",
                RE_Temporal: "",
                LE_Inferior: "",
                LE_Nasal: "",
                LE_Superior: "",
                LE_Temporal: "",
                comments: "",
                selectedTab: "AfterDilation",
                selectedEye: "RightEye",
                Leftchecked: false,
                Rightchecked: false,
            })
        } else {
            this.setState({
                RE_Inferior: "",
                RE_Nasal: "",
                RE_Superior: "",
                RE_Temporal: "",
                LE_Inferior: "",
                LE_Nasal: "",
                LE_Superior: "",
                LE_Temporal: "",
                comments: "",
                selectedTab: "BeforeDilation",
                selectedEye: "RightEye",
                Leftchecked: false,
                Rightchecked: false,
            })
        }
    }

    CopyLefttoRight = (value) => {
        var states = this.state
        if (value) {
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                RE_Inferior: states.LE_Inferior,
                RE_Nasal: states.LE_Nasal,
                RE_Superior: states.LE_Superior,
                RE_Temporal: states.LE_Temporal,
                comments: states.comments,
                selectedEye: "RightEye"
            })
        } else {
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                RE_Inferior: "",
                RE_Nasal: "",
                RE_Superior: "",
                RE_Temporal: "",
            })
        }
    }

    CopyRighttoLeft = (value) => {
        let states = this.state
        if (value) {
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                LE_Inferior: states.RE_Inferior,
                LE_Nasal: states.RE_Nasal,
                LE_Superior: states.RE_Superior,
                LE_Temporal: states.RE_Temporal,
                comments: states.comments,
                selectedEye: "LeftEye"
            })
        } else {
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                LE_Inferior: "",
                LE_Nasal: "",
                LE_Superior: "",
                LE_Temporal: "",
            })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onEditHandler = (Type) => {
        let states = this.state
        let data = states.getAvailableEyeTypesId
        for (var i = 0; i < data.length; i++) {
            if (data[i].eye_type == "LE" && data[i].dilation == "BeforeDilation") {
                states["BL_ID"] = data[i].id
            } if (data[i].eye_type == "RE" && data[i].dilation == "BeforeDilation") {
                states["BR_ID"] = data[i].id
            } if (data[i].eye_type == "LE" && data[i].dilation == "AfterDilation") {
                states["AL_ID"] = data[i].id
            } if (data[i].eye_type == "RE" && data[i].dilation == "AfterDilation") {
                states["AR_ID"] = data[i].id
            }
        }
        if (Type == "Before") {
            states["RE_Inferior"] = states.B_R_Inferior
            states["RE_Nasal"] = states.B_R_Nasal
            states["RE_Superior"] = states.B_R_Superior
            states["RE_Temporal"] = states.B_R_Temporal
            states["LE_Inferior"] = states.B_L_Inferior
            states["LE_Nasal"] = states.B_L_Nasal
            states["LE_Superior"] = states.B_L_Superior
            states["LE_Temporal"] = states.B_L_Temporal
            states["selectedTab"] = "BeforeDilation"
            states["selectedEye"] = "RightEye"
            states["comments"] = states.B_Comments
            this.setState({
                states, openPopUp: true,
                disableAfter: true,
                disableBefore: false,
                isEdit: true,
                selectedType: "Before"
            })
        } else {
            states["RE_Inferior"] = states.A_R_Inferior
            states["RE_Nasal"] = states.A_R_Nasal
            states["RE_Superior"] = states.A_R_Superior
            states["RE_Temporal"] = states.A_R_Temporal
            states["LE_Inferior"] = states.A_L_Inferior
            states["LE_Nasal"] = states.A_L_Nasal
            states["LE_Superior"] = states.A_L_Superior
            states["LE_Temporal"] = states.A_L_Temporal
            states["selectedTab"] = "AfterDilation"
            states["selectedEye"] = "RightEye"
            states["comments"] = states.A_Comments
            this.setState({
                states, openPopUp: true,
                disableBefore: true,
                disableAfter: false,
                isEdit: true,
                selectedType: "After"
            })
        }
    }

    renderGonioscopyImage = (label, Inferior, Nasal, Superior, Temporal) => {
        return (
            <Box>

                <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative", height: "30vh", margin: "1vw" }}>
                    {Superior.length > 15 ?
                        <Tooltip placement="top" title={Superior}>
                            <Typography sx={{ position: "absolute", top: "0vw", zIndex: 20 }}>{Superior.slice(0, 15)}</Typography>
                        </Tooltip> :
                        <Typography sx={{ position: "absolute", top: "0vw", zIndex: 20 }}>{Superior}</Typography>}
                    {Nasal.length > 15 ?
                        <Tooltip placement="top" title={Nasal}>
                            <Typography sx={{ position: "absolute", left: "-2.5vw", top: "6.3vw", zIndex: 20 }}>{Nasal.slice(0, 15)}</Typography>
                        </Tooltip> :
                        <Typography sx={{ position: "absolute", left: "-2.5vw", top: "6.3vw", zIndex: 20 }}>{Nasal}</Typography>}
                    {Temporal.length > 15 ?
                        <Tooltip placement="top" title={Temporal}>
                            <Typography sx={{ position: "absolute", right: "-2.5vw", top: "6.3vw", zIndex: 20 }}>{Temporal.slice(0, 15)}</Typography>
                        </Tooltip> :
                        <Typography sx={{ position: "absolute", right: "-2.5vw", top: "6.3vw", zIndex: 20 }}>{Temporal}</Typography>}
                    {Inferior.length > 15 ?
                        <Tooltip placement="top" title={Inferior}>
                            <Typography sx={{ position: "absolute", bottom: "0vw", zIndex: 20 }}>{Inferior.slice(0, 15)}</Typography>
                        </Tooltip> :
                        <Typography sx={{ position: "absolute", bottom: "0vw", zIndex: 20 }}>{Inferior}</Typography>}
                    <Box>
                        <img src={ImagePaths.GonioscopyImage.default} alt="GonioscopyImage" style={{ width: '12vw', height: '20vh' }} />
                    </Box>

                </Box>
                <Typography fontSize={'0.9vw'} fontWeight={600} padding={"0.5vw"}>{label}</Typography>
            </Box>

        )
    }

    PreviousPowerPopup = () => {
        return (
            <div>
                <Modal open={this.state.comparePopup}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "65vw", height: "95vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.75vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ borderBottom: "1px solid gray" }}>
                            <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>View Auto Refractometry</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ comparePopup: false })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'} style={{ paddingLeft: "0.5vw", paddingRight: "0.5vw" }}>
                            <Box component={'div'}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <Typography fontSize={'0.95vw'} fontWeight={600} style={{ marginBottom: "0.5vw" }} color={Colors.SecondaryText}>Current Auto Refractometry</Typography>
                                    {this.renderAutoRefractometryTable()}
                                </div>
                            </Box>
                            <Box component={'div'}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <div style={{ marginBottom: "0.5vw" }}>
                                        <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>Previous Auto Refractometry</Typography>
                                    </div>
                                    <div>{this.renderOldGlassPowerTable()}</div>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    GonioscopyPopup = () => {
        return (
            <div>
                <Modal open={this.state.openPopUp}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "37vw", height: "65vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Gonioscopy</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ openPopUp: false, disableBefore: false, disableAfter: false }, () => { this.handleClear() })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'}>
                            <div style={{ width: "100%" }}>
                                <Box sx={{ marginLeft: "0.5vw" }}>
                                    <Button size='small' disabled={this.state.disableBefore} onClick={() => { this.setState({ selectedTab: "BeforeDilation" }) }} sx={{ color: this.state.selectedTab === "BeforeDilation" ? Colors.themeDark : Colors.disableLabelColor, textTransform: 'capitalize', fontSize: "1vw" }}>Before Dilation</Button>
                                    <Button size='small' disabled={this.state.disableAfter} onClick={() => { this.setState({ selectedTab: "AfterDilation" }) }} sx={{ color: this.state.selectedTab === "AfterDilation" ? Colors.themeDark : Colors.disableLabelColor, textTransform: 'capitalize', fontSize: "1vw", marginLeft: "0.5vw" }}>After Dilation</Button>
                                </Box>
                                <Box sx={{ marginLeft: "1vw", marginTop: "0.5vw" }}>
                                    <Button size='small' variant={this.state.selectedEye == "RightEye" ? "contained" : "outlined"} onClick={() => { this.setState({ selectedEye: "RightEye" }) }} style={{ textTransform: 'capitalize' }} >{"Right Eye (OD)"}</Button>
                                    <Button size='small' variant={this.state.selectedEye == "LeftEye" ? "contained" : "outlined"} onClick={() => { this.setState({ selectedEye: "LeftEye" }) }} style={{ textTransform: 'capitalize', marginLeft: "0.65vw" }} >{"Left Eye (OS)"}</Button>
                                </Box>
                                <Box component={"div"} style={{ padding: "0.75vw" }}>
                                    <Box className="eMedhub_Shadow_Properties" sx={{ width: "100%", paddingLeft: "0.5vw" }}>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "0.5vw" }}>
                                            <TextField
                                                size='small'
                                                label="Inferior Grade"
                                                sx={{ width: '17vw', marginTop: "0.5vw" }}
                                                value={this.state.selectedEye == "LeftEye" ? this.state.LE_Inferior : this.state.RE_Inferior}
                                                onChange={(e) => {
                                                    if (this.state.selectedEye == "LeftEye") {
                                                        this.setState({ LE_Inferior: e.target.value })
                                                    } else if (this.state.selectedEye == "RightEye") {
                                                        this.setState({ RE_Inferior: e.target.value })
                                                    } else { this.errorMessage("Select Any Eye Type") }
                                                    if (this.state.selectedEye == "LeftEye" && this.state.Leftchecked) {
                                                        this.CopyLefttoRight(!this.state.Leftchecked)
                                                    } else if (this.state.selectedEye == "RightEye" && this.state.Rightchecked) {
                                                        this.CopyRighttoLeft(!this.state.Rightchecked)
                                                    }
                                                }}
                                            />
                                            <TextField
                                                size='small'
                                                label='Superior Grade'
                                                sx={{ width: '17vw', marginLeft: "0.65vw", marginTop: "0.5vw" }}
                                                value={this.state.selectedEye == "LeftEye" ? this.state.LE_Superior : this.state.RE_Superior}
                                                onChange={(e) => {
                                                    if (this.state.selectedEye == "LeftEye") {
                                                        this.setState({ LE_Superior: e.target.value })
                                                    } else if (this.state.selectedEye == "RightEye") {
                                                        this.setState({ RE_Superior: e.target.value })
                                                    } else { this.errorMessage("Select Any Eye Type") }
                                                    if (this.state.selectedEye == "LeftEye" && this.state.Leftchecked) {
                                                        this.CopyLefttoRight(!this.state.Leftchecked)
                                                    } else if (this.state.selectedEye == "RightEye" && this.state.Rightchecked) {
                                                        this.CopyRighttoLeft(!this.state.Rightchecked)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw" }}>
                                            <TextField
                                                size='small'
                                                label='Temporal Grade'
                                                sx={{ width: '17vw' }}
                                                value={this.state.selectedEye == "LeftEye" ? this.state.LE_Temporal : this.state.RE_Temporal}
                                                onChange={(e) => {
                                                    if (this.state.selectedEye == "LeftEye") {
                                                        this.setState({ LE_Temporal: e.target.value })
                                                    } else if (this.state.selectedEye == "RightEye") {
                                                        this.setState({ RE_Temporal: e.target.value })
                                                    } else { this.errorMessage("Select Any Eye Type") }
                                                    if (this.state.selectedEye == "LeftEye" && this.state.Leftchecked) {
                                                        this.CopyLefttoRight(!this.state.Leftchecked)
                                                    } else if (this.state.selectedEye == "RightEye" && this.state.Rightchecked) {
                                                        this.CopyRighttoLeft(!this.state.Rightchecked)
                                                    }
                                                }}
                                            />
                                            <TextField
                                                size='small'
                                                label='Nasal Grade'
                                                sx={{ width: '17vw', marginLeft: "0.65vw" }}
                                                value={this.state.selectedEye == "LeftEye" ? this.state.LE_Nasal : this.state.RE_Nasal}
                                                onChange={(e) => {
                                                    if (this.state.selectedEye == "LeftEye") {
                                                        this.setState({ LE_Nasal: e.target.value })
                                                    } else if (this.state.selectedEye == "RightEye") {
                                                        this.setState({ RE_Nasal: e.target.value })
                                                    } else { this.errorMessage("Select Any Eye Type") }
                                                    if (this.state.selectedEye == "LeftEye" && this.state.Leftchecked) {
                                                        this.CopyLefttoRight(!this.state.Leftchecked)
                                                    } else if (this.state.selectedEye == "RightEye" && this.state.Rightchecked) {
                                                        this.CopyRighttoLeft(!this.state.Rightchecked)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div style={{ marginTop: "1vw" }}>
                                            <div>
                                                <TextField
                                                    size='small'
                                                    label={("Enter Comments")}
                                                    style={{ marginTop: "0.5vw", width: "34.6vw" }}
                                                    multiline={true}
                                                    rows={3}
                                                    variant="outlined"
                                                    value={this.state.comments}
                                                    onChange={(event) => {
                                                        let value = event.target.value
                                                        if (value?.length <= 250) {
                                                            this.setState({ comments: value })
                                                        } else {
                                                            this.errorMessage('Allowed only 250 characters')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {this.state.selectedEye == "LeftEye" ? <Box sx={{ marginTop: "1vw", marginLeft: "0.5vw" }}>
                                            <FormControlLabel checked={this.state.Leftchecked} control={<Checkbox checked={this.state.Leftchecked} onClick={(e) => { this.CopyLefttoRight(!this.state.Leftchecked) }} />} label="Copy to Right Eye (RE)" />
                                        </Box> : this.state.selectedEye == "RightEye" ?
                                            <Box sx={{ marginTop: "1vw", marginLeft: "0.5vw" }}>
                                                <FormControlLabel checked={this.state.Rightchecked} control={<Checkbox checked={this.state.Rightchecked} onClick={(e) => { this.CopyRighttoLeft(!this.state.Rightchecked) }} />} label="Copy to Left Eye (LE)" />
                                            </Box> : <Box sx={{ height: "4vw" }}></Box>}
                                    </Box>
                                </Box>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "0.75vw" }}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => { this.handleClear() }}
                                >Clear</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    disabled={this.state.postLoading}
                                    variant='contained'
                                    onClick={() => { this.PostGonioScopyData() }}
                                >Add</Button>
                            </div>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    render() {
        let states = this.state
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ backgroundColor: Colors.Background, height: "1.7vw" }}
                        >
                            <Box component={'div'} height={'2.5vw'} sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }} pl={'0.5vw'} alignItems={'center'}>
                                <Typography fontWeight={600}>Gonioscopy</Typography>
                                <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                                    <Button variant='contained' size='small' sx={{ marginRight: "0.5vw", textTransform: "capitalize" }} disabled={this.state.isLocked || this.props.isLocked ||
                                        (states.B_R_Inferior && states.B_R_Nasal && states.B_R_Superior && states.B_R_Temporal && states.B_L_Inferior && states.B_L_Nasal && states.B_L_Superior && states.B_L_Temporal &&
                                            states.A_R_Inferior && states.A_R_Nasal && states.A_R_Superior && states.A_R_Temporal && states.A_L_Inferior && states.A_L_Nasal && states.A_L_Superior && states.A_L_Temporal)} onClick={() => { this.setState({ openPopUp: true }) }}>Add</Button>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box component={'div'} m={'0.5vw'}>
                                <Box border={'1px solid lightgray'}>
                                    <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: Colors.Background, justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <Typography fontWeight={600} padding={"0.5vw"}>Before Dilation</Typography>
                                            <Box component={'div'} sx={{ marginLeft: "0.5vw", marginTop: "0.3vw", cursor: "pointer" }} onClick={() => { if (this.state.isLocked || this.props.isLocked) { } else { this.onEditHandler("Before") } }}>
                                                {this.state.isLocked || this.props.isLocked ?
                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Box>
                                        </div>
                                        {/* {this.state.comparePopup ? null : <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.7vw" }}>
                                            <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreitemList?.length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreitemList?.length > 0) { this.CopyPreviousData("BD") } }}>
                                                {this.state.PreitemList?.length > 0 ?
                                                    <Tooltip placement='top' title='Copy Previous Auto Refractometry'>
                                                        <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                                    </Tooltip> :
                                                    <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                                }
                                            </div>
                                            <div className="eMed_icon_styles" style={{ backgroundColor: ((this.state.itemList?.length > 0) && (this.state.PreitemList)) ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if ((this.state.itemList?.length > 0) && (this.state.PreitemList.length > 0)) { this.setState({ comparePopup: true }) } }}>
                                                {((this.state.itemList?.length > 0) && (this.state.PreitemList.length > 0)) ?
                                                    <Tooltip placement='top' title='Compare'>
                                                        <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                                    </Tooltip> :
                                                    <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                                }
                                            </div>
                                        </div>} */}
                                    </Box>
                                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-around'}>
                                        {this.renderGonioscopyImage("Right Eye (OS)", states.B_R_Inferior, states.B_R_Nasal, states.B_R_Superior, states.B_R_Temporal)}
                                        {this.renderGonioscopyImage("Left Eye (OS)", states.B_L_Inferior, states.B_L_Nasal, states.B_L_Superior, states.B_L_Temporal)}
                                    </Box>
                                </Box>
                                <Box border={'1px solid lightgray'} marginTop={"1.5vw"}>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: Colors.Background, justifyContent: "space-between", width: "100%" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <Typography fontWeight={600} padding={"0.5vw"}>After Dilation</Typography>
                                            <Box component={'div'} sx={{ marginLeft: "0.5vw", marginTop: "0.3vw", cursor: "pointer" }} onClick={() => { if (this.state.isLocked || this.props.isLocked) { } else { this.onEditHandler("After") } }}>
                                                {this.state.isLocked || this.props.isLocked ?
                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Box>
                                        </div>
                                        {/* {this.state.comparePopup ? null : <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.7vw" }}>
                                            <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreitemListAfter?.length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreitemListAfter?.length > 0) { this.CopyPreviousData("BD") } }}>
                                                {this.state.PreitemListAfter?.length > 0 ?
                                                    <Tooltip placement='top' title='Copy Previous Auto Refractometry'>
                                                        <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                                    </Tooltip> :
                                                    <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                                }
                                            </div>
                                            <div className="eMed_icon_styles" style={{ backgroundColor: ((this.state.itemListAfter?.length > 0) && (this.state.PreitemListAfter)) ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if ((this.state.itemListAfter?.length > 0) && (this.state.PreitemListAfter.length > 0)) { this.setState({ comparePopup: true }) } }}>
                                                {((this.state.itemListAfter?.length > 0) && (this.state.PreitemListAfter.length > 0)) ?
                                                    <Tooltip placement='top' title='Compare'>
                                                        <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                                    </Tooltip> :
                                                    <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                                }
                                            </div>
                                        </div>} */}
                                    </Box>
                                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-around'}>
                                        {this.renderGonioscopyImage("Right Eye (OD)", states.A_R_Inferior, states.A_R_Nasal, states.A_R_Superior, states.A_R_Temporal)}
                                        {this.renderGonioscopyImage("Left Eye (OD)", states.A_L_Inferior, states.A_L_Nasal, states.A_L_Superior, states.A_L_Temporal)}
                                    </Box>
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    {this.GonioscopyPopup()}
                    {/* {this.PreviousPowerPopup()} */}
                    {
                        this.state.isErrorMsg ?
                            <ToastMsg
                                severity={'error'}
                                msg={this.state.isErrorMsgText}
                                msgPop={this.msgClose.bind(this)}
                            />
                            : null
                    }
                    {
                        this.state.successMsg ?
                            <ToastMsg
                                severity={'success'}
                                msg={this.state.successMsgText}
                                msgPop={this.msgClose.bind(this)}
                            />
                            : null
                    }
                </Box>
            </ShowComponents>
        )
    }
}
