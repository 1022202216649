import React, { Component } from "react";
import { Box, Stack, TextField, Typography, IconButton, Tooltip, Paper, Divider, Dialog, Button } from "@mui/material";
import { ImagePaths } from '../../../Utility/ImagePaths';
import '../../Laboratory/Purchase/purchase.css';
import CommonGridHeader, { CommonPopUp, ReasonPopup } from '../../../Components/Common Components/CommonComponents';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { DateTime } from "luxon";
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import Loader from '../../../Components/Loader';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import Success from '../../../Assets/Animation/Success.gif';
import { CurrencySymbol } from "../../../Utility/Constants";


let userAccess = null;
let RoleData = null;

class PharmaConfirmOrderList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            confirmPopData: [],
            isBtnClicked: false,
            purchase_order_number: null,
            pageSize: 10,
            current_page: 0,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            isPoClicked: false,
            isCompleted:false,
            disableBtn: false,
            selectedData: {},
            mailLink: '',
            vendorMailID: "",
            senderName: "",
            purchase_no: null,
        }
    }
    componentDidMount() {
        RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
        userAccess = CheckAccessFunc(RoleData?.pharmacy_type === 'Inhouse Pharmacy'? "Pharmacy" : "Standalone Pharmacy", "Purchase", 'Wanted List', 'Confirm Order List', "SubTab");
        this._getConfirmOrderList();
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key, stateKey = "isLoader") => {
        let states = this.state;
        states[stateKey] = key
        this.setState({
          states
        })
      }

    _getConfirmOrderList = () => {
        this.setState({
            data: [],
            pageSize: 10,
            current_page: 0
        })
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(`${Serviceurls.PURCHASE_CONFIRM_ORDER_LIST}`)
                .then(response => {
                    if (response.data.status == "Success") {
                        this.LoaderFunction(false);
                        let list = response.data?.data?.length > 0 ? response.data.data : [];
                        list.length > 0 && list?.forEach((item, i) => {
                            item["sno"] = i + 1;
                        })
                        this.setState({
                            data: list,isCompleted:false
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data.status == "Fail") {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    getConfirmOdrpopup = (fromDelete) => {
        let { vendor_id } = this.state;
        try {
            this.LoaderFunction(true);
            this.setState({disableBtn: true})
            RestAPIService.getAll(`${Serviceurls.PURCHASE_CONFIRMORDER_POPUP}?vendor_id=${vendor_id}`)
                .then(response => {
                    if (response?.data?.status === "sucess") {
                        this.LoaderFunction(false);
                        let list = response.data?.data?.length > 0 ? response.data.data : [];
                        list.length > 0 && list?.forEach((item, i) => {
                            item["sno"] = i + 1;
                        })

                        if (fromDelete && list.length === 0) {
                            this._getConfirmOrderList();
                        }

                        this.setState({
                            confirmPopData: list,
                            disableBtn: false,
                            mailLink: response?.data?.mail_link,
                            vendorMailID: response?.data?.vendor_mail,
                            senderName: response?.data?.user_name,
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response?.data?.message);
                        this.setState({disableBtn: false})
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
            this.setState({disableBtn: false})
        }
    }

    // popup start
    PopUpClose = () => {
        this.setState({
            isBtnClicked: false,
            confirmPopData: [],
        })
    }

    deleteItem = (id) => {
        if (id) {
            try {
                this.LoaderFunction(true);
                this.setState({disableBtn: true})
                RestAPIService.delete(`${Serviceurls.PURCHASE_CONFIRMORDER_POPUP}?order_id=${id}`)
                    .then(response => {
                        if (response.data.status == "sucess") {
                            this.LoaderFunction(false);
                            this.successMessage(response.data.message);
                            this.getConfirmOdrpopup(true);
                            this.setState({disableBtn: false})
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status == "fail") {
                            this.LoaderFunction(false);
                            this.errorMessage(error.response.data.message);
                            this.setState({disableBtn: false})
                        }
                    })
            } catch (e) {
                this.errorMessage(e.message);
                this.setState({disableBtn: false})
            }
        }

    }

    confirmOrderPopup = () => {
        const { t } = this.props;
        let { confirmPopData } = this.state;
        const popColumn = [
            {
                field: "action", flex: 0.06, headerName: "", headerAlign: "center", align: "center", sortable: false,
                renderCell: ({ row }) => (
                    <Tooltip title={t("Delete")} placement="top" arrow>
                        <IconButton emed_tid= "cnfmord_dlt" size='small' disabled={this.state.disableBtn || this.state.isLoader} onClick={() => { this.deleteItem(row.order_id) }}>
                            <img src={ImagePaths.Delete.default} alt='Delete' className='eMed_dir_del' />
                        </IconButton>
                    </Tooltip>)
            },
            {
                field: "sno", flex: 0.08, headerName: t("SNo"), headerAlign: "center", align: "center"
            },
            {
                field: "drug_name", flex: 0.18, headerName: t("BrandName"),
                renderCell: ({ row }) => (<Box component={'div'}>{row?.drug_name ? row?.drug_name : "-"}</Box>)
            },
            {
                field: "generic_name", flex: 0.18, headerName: t("Generic / Combo"),
                renderCell: ({ row }) => (<Box component={'div'}>{row?.generic_name ? row?.generic_name : "-"}</Box>)
            },
            {
                field: "dosagetype", flex: 0.18, headerName: t("DosageType"),
                renderCell: ({ row }) => (<Box component={'div'}>{row?.dosagetype ? row?.dosagetype : "-"}</Box>)
            },
            {
                field: "drug_strength", flex: 0.18, headerName: t("DosageStrength"),
                renderCell: ({ row }) => (<Box component={'div'}>{row?.drug_strength ? row?.drug_strength : "-"}</Box>)
            },
            {
                field: "order_quantity", flex: 0.14, headerName: t("Purchase Quantity"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'} emed_tid = {`cnfm_ordqty`}>
                    <TextField
                        inputProps={{
                            style: {
                                height: "2vw",
                                padding: '0 14px',
                            },
                        }}
                        autoComplete='off'
                        value={params?.row?.order_quantity ? +params?.row?.order_quantity : null}
                        onChange={(e) => {
                            let { value } = e.target;
                            let number = CommonValidation.numberOnly(value);
                            if (number && value > 0 || value === "") {
                                this.state.confirmPopData[params.id - 1]["order_quantity"] = +value.trimStart()
                                this.setState({
                                    confirmPopData: this.state.confirmPopData
                                })
                            }

                        }}
                        className='eMed_DataGrid_TextField' />
                </Box>)
            }
        ]
        return (<Box className="eMed_PharmacfrmOrd_mainDiv">
            <Paper id="eMed_PharmacfrmOrd_subDiv1" elevation={0}>
                <Stack direction={"row"} spacing={2} justifyContent={"flex-start"} alignItems={"center"}>
                    <Typography sx={{ fontSize: "0.9vw" }}>{t("VendorName")}</Typography>
                    <Typography id="emedhub_directpoTable_cell1">{confirmPopData[0]["vendor_name"] ? confirmPopData[0]["vendor_name"] : "-"}</Typography>
                </Stack>
            </Paper>
            <Divider />
            <div id="emedhub_confirmOrder_subDiv2">
                <DataGrid
                    rows={confirmPopData}
                    columns={popColumn}
                    getRowId={(row) => row['sno']}
                    hideFooter
                    headerHeight={40}
                    components={{
                        NoRowsOverlay: () => ""
                    }}
                    disableSelectionOnClick
                />
            </div>
        </Box>)
    }

    checkAllDetailsAreAvailable(newEntry) {
        const { t } = this.props
        var defaultReturn = true
        for (const data of newEntry) {
            if (!data.order_quantity) {
                this.errorMessage(t("KindlyaddQuantity"));
                defaultReturn = false
            }
        }
        return defaultReturn
    }

    getPoNumber = () => {
    let { confirmPopData } = this.state;
    let isValid = this.checkAllDetailsAreAvailable(confirmPopData);
    if (isValid) {
        try {
            this.LoaderFunction(true, "isPoClicked");
            RestAPIService.getAll(`${Serviceurls.PURCHASE_PO_NUMBER_GET}`)
                .then(response => {
                    if (response.data.status == "success") {
                        this.LoaderFunction(false, "isPoClicked");
                        this.setState({
                            purchase_order_number: response.data?.data?.po_number,
                        }, () => {
                            this.handlePlaceOrder();
                        });
                    }
                })
                .catch(error => {
                    if (error.response?.data?.status === "fail") {
                        this.LoaderFunction(false, "isPoClicked");
                        this.errorMessage(error.response?.data?.message);
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
        }
    }}

    handlePlaceOrder = () => {
        let { confirmPopData, purchase_order_number } = this.state;
        if (purchase_order_number) {
            let datas = {
                line_items: [],
                purchase_order_date: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
                purchase_order_number: purchase_order_number,
                vendor_id: confirmPopData[0]["vendor_id"]
            }
            confirmPopData.forEach(item => {
                let tempObj = {
                    buying_price: +item.buying_price,
                    discount_percentage: +item.discount_percentage,
                    drug_ms_id: item.drug_id,
                    estimated_epr: +item.estimated_epr,
                    free_quantity: +item.free_quantity,
                    order_id: item.order_id,
                    order_quantity: +item.order_quantity,
                    serial_no: item.sno,
                }
                datas["line_items"].push(tempObj)
            })

            try {
                this.LoaderFunction(true, "isPoClicked");
                RestAPIService.create(datas, Serviceurls.PURCHASE_PLACEORDER_POST)
                    .then(response => {
                        if (response.data.status === "success") {
                            this.setState({
                                purchase_no: response?.data?.data?.po_number 
                            })
                            this.LoaderFunction(false, "isPoClicked");
                            this.successMessage(response.data.message);
                            // this.setState({isCompleted:true})
                            this.PopUpClose();
                            this._getConfirmOrderList();
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false, "isPoClicked");
                            this.errorMessage(error.response.data.message);
                        }
                    });
            } catch (e) {
                this.errorMessage(e.message);
            }
        }
    }
    // popup end

    getConfirmOrderPrint = () => {
        try{
            this.setState({disableBtn: true})
            RestAPIService.getAll(`${Serviceurls.PURCHASE_CONFIRMORDER_PRINT}`).
            then((response) => {
              const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
              this.setState({disableBtn: false})
            }).catch((error) => {
              this.errorMessage(error.message)
              this.setState({disableBtn: false})
            })
        }catch(error){
            this.errorMessage(error.message)
            this.setState({disableBtn: false})
        }
    }

    getConfirmOrderMailPrint = async () => {
        const { vendor_id } = this.state;
        try {
            this.setState({ disableBtn: true });
            const response = await RestAPIService.getAll(`${Serviceurls.COMFIRM_ORDER_EMAILPRINT}?export=pdf&vendor_id=${vendor_id}`, { responseType: 'arraybuffer' });
    
            // Convert the response data into a Blob
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    
            // Read the PDF blob as a data URL
            const reader = new FileReader();
            reader.readAsDataURL(pdfBlob);
            
            // Promisify the FileReader.onload event
            const dataPromise = new Promise((resolve, reject) => {
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
            });
    
            // Wait for the FileReader to finish reading
            const base64Data = await dataPromise;
    
            this.setState({ disableBtn: false });
            return base64Data; // Return the PDF data as base64
        } catch (error) {
            this.errorMessage(error.message);
            this.setState({ disableBtn: false });
            return null;
        }
    };

    sendMail = async () => {
        try {
            let states = this.state
            let PODate = DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd")
            const message = `Dear ${states.selectedData.vendor_name ? states.selectedData.vendor_name : ''},

                   We hope this email finds you well.
                   We are pleased to inform you that a purchase order request has been generated and link has mentioned below.
                   Click here : ${states.mailLink ? states.mailLink : '-'} 
                   Please review the purchase order request details carefully. 
                   If there are any discrepancies or if you require any further clarification, kindly let us know at your earliest convenience. 
                   Upon confirmation of the purchase order, please proceed with the necessary arrangements for the fulfilment and delivery of the items by the specified delivery date.
                   Thank you for your prompt attention to this matter. We look forward to your confirmation and the successful completion of this transaction.
Best Regards,
${states.senderName}.`;
            const toMail = states.vendorMailID
            const subject = encodeURIComponent(`Purchase Request Details - ${PODate}`);
            const body = encodeURIComponent(`${message}`);
            const mailtoLink = `mailto:${toMail}?subject=${subject}&body=${body}`;
            window.open(mailtoLink);
        } catch (error) {
            this.errorMessage("Error sending email:", error);
        }
    };

    render() {
        const { t } = this.props;
        const columns = [
            { field: 'sno', headerName: t('SNo'), flex: 0.08, sortable: false, hideable: false },
            { field: "vendor_name", headerName: t('VendorName'), flex: 0.28, sortable: false, hideable: false },
            { field: "no_of_product", headerName: t('NoOfProduct'), flex: 0.28, sortable: false, type: "number" },
            { field: "estimated_prize", headerName: t('EstimatedPrice')+(`(${CurrencySymbol})`), flex: 0.24, sortable: false, hideable: false, type: "number" },
        ]

        if (userAccess?.editAccess) {
            columns.push(
                {
                    field: 'actions', headerName: t('Action'), type: 'actions', flex: 0.12, sortable: false, hideable: false,
                    renderCell: (cellvalues) => {
                        return (
                            <Stack direction="row" justifyContent="space-around">
                                <Tooltip title={t("PlaceOrder")} placement="top" arrow>
                                    <IconButton emed_tid="cnfmord_plc" size="small" sx={{ borderRadius: "0.3vw" }} onClick={() => {
                                        this.setState({
                                            isBtnClicked: true,
                                            vendor_id: cellvalues.row.vendor_id,
                                            selectedData: cellvalues.row
                                        }, () => { this.getConfirmOdrpopup() })
                                    }}>
                                        <img className="emedhub_vendor_img1" src={ImagePaths.deliveryClose.default} alt={"delivery box"} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        );
                    }
                }
            );
        }

        let { data, pageSize, current_page, confirmPopData } = this.state;
        return (
            <Box className="emedhub__purchase_page">
                <div className='eMed_Purchase_Table_top'>
                    <Tooltip title="Print" placement='top' arrow>
                        <Button emed_tid="vnd_print" className='eMed_usrconf_btn' disabled={this.state.disableBtn} onClick={() => this.getConfirmOrderPrint()}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button></Tooltip>
                </div>
                <div className="eMed_pharma_usrconfig_tbl">
                    <DataGrid
                        rows={data}
                        columns={columns}
                        disableColumnFilter
                        page={current_page}
                        hideFooterSelectedRowCount
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => {
                                return (
                                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }
                        }}
                        getRowId={(row) => row.vendor_id}
                        rowCount={data.length}
                        pageSize={pageSize}
                        onPageChange={(newPage) => this.setState({ current_page: newPage })}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination />
                </div>
                {
                    this.state.isBtnClicked && confirmPopData.length > 0 ?
                        <CommonPopUp width={"80vw"}
                            testID = {"cnfmord"}
                            popUpClose={this.PopUpClose.bind(this)}
                            title={t("Purchase Order")}
                            component={this.confirmOrderPopup.bind(this)}
                            footerTitle1={t("Note")}
                            footerContent1={t("NoteOfCnfrm")}
                            buttonOne={t("Cancel")}
                            buttonOneAction={this.PopUpClose.bind(this)}
                            buttonTwo={t("PlaceOrder")}
                            buttonTwoAction={this.getPoNumber.bind(this)}
                            mailBtn={'Send Mail'}
                            mailBtnAction={this.sendMail.bind(this)}
                            disableBtn2 = {this.state.isPoClicked}
                        />
                        : null
                }
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                        testId={this.state.purchase_no}
                    />
                    : null}
                {this.state.isCompleted ?
                    <Dialog
                        className="emed_DialogboxOrder"
                        open={this.state.isCompleted}
                        onClose={this.ClosePurchaseRequest}
                        maxWidth={"md"}
                    >
                        <div className='Lab_Home_Dialogboxwidth'>
                            <img className="eMed_dilboxAnimations" alt="Sucess" src={Success} />
                            <h3>{'Confirm Order List Successfully Completed'}</h3>
                            {/* <FormControlLabel labelPlacement="start" className='eMed_create_switch' control={<Switch size="small" onClick={() => { this.setState({ smsActive: true }, () => this.postSms()) }} />} label="Receive SMS" /> */}
                            <div className="Lab_home_pop_buttom_view">
                                <Button variant="outlined" size="small" sx={{ height: '2vw' }} id="eMed_Btn_Text"
                                    onClick={() => {
                                        this.PopUpClose();
                                        this._getConfirmOrderList();
                                    }}>{'Close'}</Button>
                                {/* <Button variant="contained" size="small" sx={{ marginLeft: "0.5vw", height: '2vw' }}
                                    id="eMed_Btn_Text"
                                    onClick={() => {
                                        this.getConfirmOrderPrint()
                                    }}
                                >{"Print"}</Button> */}
                                {/* <Tooltip title={"Print Patient Barcode"} placement="top">
                                    <IconButton id="eMed_Btn_Text" sx={{ height: '2vw' }} variant="contained" size="small"
                                        onClick={() => {
                                        }}
                                    ><img style={{ width: '1.8vw', height: '1.8vw' }} src={ImagePaths.PrintIcons.default} alt="print" /></IconButton>
                                </Tooltip> */}
                            </div>
                        </div>
                    </Dialog>
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(PharmaConfirmOrderList);