import { Autocomplete, Box, Button, Checkbox, Chip, FormControlLabel, Modal, Tab, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { Search } from '@material-ui/icons'
import ShowComponents from './ShowComponent';

export default class DocDiagonisis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clinicId: this.props.clinicId,
      patientId: this.props.patientId,
      appointmentId: this.props.appointmentId,
      appointmentDate: this.props.appointmentDate,
      openPopUp: false,
      postLoading: false,

      rightEye: false,
      lefytEye: false,
      normal: false,

      discRightEye: "",
      maculaRightEye: "",
      mediaRightEye: "",
      vesselsRightEye: "",
      peripheryRightEye: "",

      discLeftEye: "",
      maculaLeftEye: "",
      mediaLeftEye: "",
      vesselsLeftEye: "",
      peripheryLeftEye: "",

      PreviousData: [],
      currentData: [],
      comparePopup: false
    }
  }

  apiCalls = () => {
    this.getPosteriorSegment()
    this.getPreviousPosteriorSegment()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLocked !== this.props.isLocked) {
      this.setState({ isLocked: this.props.isLocked })
    }
  }

  getPosteriorSegment = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_POSTERIOR_SEGMENT + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}`)
        .then((response) => {
          if (response.data.status === 'success') {
            let PosteriorData = response.data?.data ? response.data?.data : []
            if (PosteriorData.length > 0) {
              for (let i = 0; i < PosteriorData.length; i++) {
                if (PosteriorData[i].eye_type == "RE") {
                  var states = this.state
                  states["discRightEye"] = PosteriorData[i].disc;
                  states["maculaRightEye"] = PosteriorData[i].macula;
                  states["mediaRightEye"] = PosteriorData[i].media;
                  states["vesselsRightEye"] = PosteriorData[i].vessels;
                  states["peripheryRightEye"] = PosteriorData[i].periphery;
                  this.setState({ states })
                } else if (PosteriorData[i].eye_type == "LE") {
                  var states = this.state
                  states["discLeftEye"] = PosteriorData[i].disc;
                  states["maculaLeftEye"] = PosteriorData[i].macula;
                  states["mediaLeftEye"] = PosteriorData[i].media;
                  states["vesselsLeftEye"] = PosteriorData[i].vessels;
                  states["peripheryLeftEye"] = PosteriorData[i].periphery;
                  this.setState({ states })
                }
              }
              this.setState({
                currentData: PosteriorData
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPreviousPosteriorSegment = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PREVIOUS_POSTERIOR_SEGMENT + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&appointment_date=${this.state.appointmentDate}&doctor_id=${this.props.doctorID}`)
        .then((response) => {
          if (response.data.status === 'success') {
            let PosteriorData = response.data?.data ? response.data?.data : []
            if (PosteriorData.length > 0) {
              for (let i = 0; i < PosteriorData.length; i++) {
                if (PosteriorData[i].eye_type == "RE") {
                  var states = this.state
                  states["PrediscRightEye"] = PosteriorData[i].disc;
                  states["PremaculaRightEye"] = PosteriorData[i].macula;
                  states["PremediaRightEye"] = PosteriorData[i].media;
                  states["PrevesselsRightEye"] = PosteriorData[i].vessels;
                  states["PreperipheryRightEye"] = PosteriorData[i].periphery;
                  this.setState({ states })
                } else if (PosteriorData[i].eye_type == "LE") {
                  var states = this.state
                  states["PrediscLeftEye"] = PosteriorData[i].disc;
                  states["PremaculaLeftEye"] = PosteriorData[i].macula;
                  states["PremediaLeftEye"] = PosteriorData[i].media;
                  states["PrevesselsLeftEye"] = PosteriorData[i].vessels;
                  states["PreperipheryLeftEye"] = PosteriorData[i].periphery;
                  this.setState({ states })
                }
              }
              this.setState({
                PreviousData: PosteriorData
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getIcdCodeSearch = (searchKey, searchValue) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_REGISTRY_ICD_SEARCH + `?${searchKey}=${searchValue}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              icdSearchData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  PostPosteriorSegement = () => {
    try {
      const data = new FormData();
      var states = this.state
      data.append('appointment_id', states.appointmentId);
      data.append('patient_id', states.patientId);
      data.append('le_disc', states.discLeftEye);
      data.append('le_macula', states.maculaLeftEye);
      data.append('le_media', states.mediaLeftEye);
      data.append('le_vessels', states.vesselsLeftEye);
      data.append('le_periphery', states.peripheryLeftEye);
      data.append('le_anterior_image_key', "");
      data.append('le_image_json', "");
      data.append('re_disc', states.discRightEye);
      data.append('re_macula', states.maculaRightEye);
      data.append('re_media', states.mediaRightEye);
      data.append('re_vessels', states.vesselsRightEye);
      data.append('re_periphery', states.peripheryRightEye);

      data.append('re_anterior_image_key', "");
      data.append('re_image_json', "");
      this.setState({ postLoading: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_POSTERIOR_SEGMENT)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getPosteriorSegment()
            this.setState({ postLoading: false, openPopUp: false, selectedId: null, comparePopup: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ postLoading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoading: false })
      this.errorMessage(error.message)
    }
  }


  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  CopyPreviousData = () => {
    var states = this.state
    states["discLeftEye"] = states.PrediscLeftEye ? states.PrediscLeftEye : "";
    states["maculaLeftEye"] = states.PremaculaLeftEye ? states.PremaculaLeftEye : "";
    states["mediaLeftEye"] = states.PremediaLeftEye ? states.PremediaLeftEye : "";
    states["vesselsLeftEye"] = states.PrevesselsLeftEye ? states.PrevesselsLeftEye : "";
    states["peripheryLeftEye"] = states.PreperipheryLeftEye ? states.PreperipheryLeftEye : "";
    states["discRightEye"] = states.PrediscRightEye ? states.PrediscRightEye : "";
    states["maculaRightEye"] = states.PremaculaRightEye ? states.PremaculaRightEye : "";
    states["mediaRightEye"] = states.PremediaRightEye ? states.PremediaRightEye : "";
    states["vesselsRightEye"] = states.PrevesselsRightEye ? states.PrevesselsRightEye : "";
    states["peripheryRightEye"] = states.PreperipheryRightEye ? states.PreperipheryRightEye : "";
    this.setState({ states }, () => { this.PostPosteriorSegement() })
  }

  copyCheckBoxValues(key) {
    if (key == "rightEye") {
      if (this.state.lefytEye) {
        this.setState({ rightEye: !this.state.rightEye, normal: true }, () => {
          if (!this.state.rightEye) {
            this.setState({ normal: false }, () => { this.copyValues("rightEye") })
          } else {
            this.copyValues("rightEye")
          }
        })
      } else {
        this.setState({ rightEye: !this.state.rightEye }, () => { this.copyValues("rightEye") })
      }
    } else if (key == "leftEye") {
      if (this.state.rightEye) {
        this.setState({ lefytEye: !this.state.lefytEye, normal: true }, () => {
          if (!this.state.lefytEye) {
            this.setState({ normal: false }, () => { this.copyValues("leftEye") })
          } else {
            this.copyValues("leftEye")
          }
        })
      } else {
        this.setState({ lefytEye: !this.state.lefytEye }, () => { this.copyValues("leftEye") })
      }
    } else if (key == "normal") {
      if (this.state.rightEye && this.state.lefytEye) {
        this.setState({
          rightEye: false,
          lefytEye: false,
          normal: false
        }, () => { this.copyValues("normal") })
      } else {
        this.setState({
          rightEye: true,
          lefytEye: true,
          normal: true
        }, () => { this.copyValues("normal") })
      }
    }
  }

  copyValues = (type) => {
    if (type == "leftEye") {
      if (this.state.lefytEye) {
        var states = this.state
        states["discLeftEye"] = "Normal";
        states["maculaLeftEye"] = "Normal";
        states["mediaLeftEye"] = "Clear";
        states["vesselsLeftEye"] = "Normal";
        states["peripheryLeftEye"] = "Normal";
        this.setState({ states })
      } else {
        var states = this.state
        states["discLeftEye"] = "";
        states["maculaLeftEye"] = "";
        states["mediaLeftEye"] = "";
        states["vesselsLeftEye"] = "";
        states["peripheryLeftEye"] = "";
        this.setState({ states })
      }
    } else if (type == "rightEye") {
      if (this.state.rightEye) {
        var states = this.state
        states["discRightEye"] = "Normal";
        states["maculaRightEye"] = "Normal";
        states["mediaRightEye"] = "Clear";
        states["vesselsRightEye"] = "Normal";
        states["peripheryRightEye"] = "Normal";
        this.setState({ states })
      } else {
        var states = this.state
        states["discRightEye"] = "";
        states["maculaRightEye"] = "";
        states["mediaRightEye"] = "";
        states["vesselsRightEye"] = "";
        states["peripheryRightEye"] = "";
        this.setState({ states })
      }
    } else if (type == "normal") {
      if (this.state.normal) {
        var states = this.state;
        states["discRightEye"] = "Normal";
        states["maculaRightEye"] = "Normal";
        states["mediaRightEye"] = "Clear";
        states["vesselsRightEye"] = "Normal";
        states["peripheryRightEye"] = "Normal";

        states["discLeftEye"] = "Normal";
        states["maculaLeftEye"] = "Normal";
        states["mediaLeftEye"] = "Clear";
        states["vesselsLeftEye"] = "Normal";
        states["peripheryLeftEye"] = "Normal";
        this.setState({ states })
      } else {
        var states = this.state;
        states["discRightEye"] = "";
        states["maculaRightEye"] = "";
        states["mediaRightEye"] = "";
        states["vesselsRightEye"] = "";
        states["peripheryRightEye"] = "";

        states["discLeftEye"] = "";
        states["maculaLeftEye"] = "";
        states["mediaLeftEye"] = "";
        states["vesselsLeftEye"] = "";
        states["peripheryLeftEye"] = "";
        this.setState({ states })
      }
    }
  }

  renderOldPowerTable = () => {
    return (
      <Box width={"50vw"} marginLeft={"1.5vw"}>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography fontWeight={600} flex={0.2}></Typography>
          <Typography fontWeight={600} flex={0.4} paddingLeft={"7vw"}>Right Eye</Typography>
          <Typography fontWeight={600} flex={0.4} paddingLeft={"7vw"}>Left Eye</Typography>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Disc</Typography>
          <Box flex={0.4}>{this.renderTextBox("PrediscRightEye")}</Box>
          <Box flex={0.4}>{this.renderTextBox("PrediscLeftEye")}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Macula</Typography>
          <Box flex={0.4}>{this.renderTextBox("PremaculaRightEye")}</Box>
          <Box flex={0.4}>{this.renderTextBox("PremaculaLeftEye")}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Media</Typography>
          <Box flex={0.4}>{this.renderTextBox("PremediaRightEye")}</Box>
          <Box flex={0.4}>{this.renderTextBox("PremediaLeftEye")}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Vessels</Typography>
          <Box flex={0.4}>{this.renderTextBox("PrevesselsRightEye")}</Box>
          <Box flex={0.4}>{this.renderTextBox("PrevesselsLeftEye")}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Periphery</Typography>
          <Box flex={0.4}>{this.renderTextBox("PreperipheryRightEye")}</Box>
          <Box flex={0.4}>{this.renderTextBox("PreperipheryLeftEye")}</Box>
        </Box>
      </Box>
    )
  }

  PreviousPowerPopup = () => {
    return (
      <div>
        <Modal open={this.state.comparePopup}>
          <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "65vw", height: "95vh", maxHeight: "100%" }}>
            <Box component={'div'} p={'0.75vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ borderBottom: "1px solid gray" }}>
              <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>View Posterior Segment</Typography>
              <Button
                className='Common_Btn_Min_Width'
                onClick={() => this.setState({ comparePopup: false })}
              >
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
              </Button>
            </Box>
            <Box component={'div'} style={{ paddingLeft: "0.5vw", paddingRight: "0.5vw" }}>
              <Box component={'div'}>
                <div style={{ marginTop: "0.65vw" }}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "0.5vw" }}>
                    <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>Current Posterior Segment</Typography>
                    <div className="eMed_icon_styles" style={{ backgroundColor: Colors.ThemeColor, marginLeft: "0.65vw", marginTop: "-0.2vw" }} onClick={() => { this.CopyPreviousData() }}>
                      <Tooltip placement='top' title='Copy'>
                        <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                      </Tooltip>
                    </div>
                  </div>
                  <div>{this.renderOldPowerTable()}</div>
                </div>
              </Box>
              <Box component={'div'}>
                <div style={{ marginTop: "0.65vw" }}>
                  <div style={{ marginTop: "0.65vw" }}>
                    <Typography fontSize={'0.95vw'} fontWeight={600} style={{ marginBottom: "0.5vw" }} color={Colors.SecondaryText}>New Posterior Segment</Typography>
                    {this.renderPosteriorSegmentTable()}
                  </div>
                </div>
              </Box>

            </Box>
          </Box>
        </Modal>
      </div>
    )
  }

  renderPosteriorSegmentTable = () => {
    return (
      <Box width={"35vw"} marginLeft={"0.5vw"}>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography fontWeight={600} flex={0.2}></Typography>
          <Typography fontWeight={600} flex={0.4} paddingLeft={"7vw"}>Right Eye</Typography>
          <Typography fontWeight={600} flex={0.4} paddingLeft={"7vw"}>Left Eye</Typography>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Disc</Typography>
          <Box flex={0.4}>{this.renderTextBox("discRightEye")}</Box>
          <Box flex={0.4}>{this.renderTextBox("discLeftEye")}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Macula</Typography>
          <Box flex={0.4}>{this.renderTextBox("maculaRightEye")}</Box>
          <Box flex={0.4}>{this.renderTextBox("maculaLeftEye")}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Media</Typography>
          <Box flex={0.4}>{this.renderTextBox("mediaRightEye")}</Box>
          <Box flex={0.4}>{this.renderTextBox("mediaLeftEye")}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Vessels</Typography>
          <Box flex={0.4}>{this.renderTextBox("vesselsRightEye")}</Box>
          <Box flex={0.4}>{this.renderTextBox("vesselsLeftEye")}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Periphery</Typography>
          <Box flex={0.4}>{this.renderTextBox("peripheryRightEye")}</Box>
          <Box flex={0.4}>{this.renderTextBox("peripheryLeftEye")}</Box>
        </Box>
      </Box>
    )
  }

  renderTextBox = (key) => {
    let states = this.state
    return (
      <TextField
        inputProps={{ emed_tid: "" }}
        sx={{ width: '80%' }}
        disabled={this.state.isLocked || this.props.isLocked || this.state.comparePopup}
        size='small'
        placeholder='Type Remark'
        autoComplete='off'
        value={states[key]}
        onChange={(e) => {
          states[key] = e.target.value
          this.setState({ states })
        }}
      />
    )
  }

  render() {
    return (
      <ShowComponents onIntersection={this.apiCalls.bind(this)}>
        <Box component={'div'} sx={{ backgroundColor: Colors.white }} paddingBottom={"1vw"}>
          <Box component={'div'} height={'3vw'} sx={{ backgroundColor: Colors.Background }} display={'flex'} pl={'0.5vw'} alignItems={'center'} flexDirection={'row'}>
            <Typography fontWeight={600}>Posterior Segment</Typography>
            <div style={{ display: "flex", flexDirection: "row", marginLeft: "1vw" }}>
              <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreviousData?.length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && !this.props.isLocked) { this.CopyPreviousData() } }}>
                <Tooltip placement='top' title='Copy Previous Posterior Segment'>
                  <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                </Tooltip>
              </div>
              {/* <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0 && !this.props.isLocked) { this.setState({ comparePopup: true }) } }}>
                <Tooltip placement='top' title='Compare'>
                  <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                </Tooltip>
              </div> */}
            </div>
          </Box>
          <Box component={'div'} m={'0.5vw'}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={"space-between"}>
              <Box marginLeft={'2vw'}>
                <FormControlLabel width={"10vw"} control={<Checkbox emed_tid="cash_checkbox" checked={this.state.rightEye} onChange={(e) => { this.copyCheckBoxValues("rightEye") }} size='small' />} label={"Right Eye"} />
                <FormControlLabel width={"10vw"} control={<Checkbox emed_tid="upi_checkbox" checked={this.state.lefytEye} onChange={(e) => { this.copyCheckBoxValues("leftEye") }} size='small' />} label={"Left Eye"} />
                <FormControlLabel width={"10vw"} control={<Checkbox emed_tid="bank_trans_checkbox" checked={this.state.normal} onChange={(e) => { this.copyCheckBoxValues("normal") }} size='small' />} label={"Normal"} />
              </Box>
              <Box>
                <Button variant='outlined' size='small'
                  sx={{ textTransform: 'capitalize', marginRight: '1vw' }}
                  onClick={() => {
                    this.setState({
                      rightEye: false, lefytEye: false, normal: false
                    }, () => { this.copyValues("normal") })
                  }}>Clear</Button>
                <Button disabled={this.state.isLocked || this.props.isLocked} variant='contained' size='small' sx={{ textTransform: 'capitalize' }} onClick={() => { this.PostPosteriorSegement() }}>Save</Button>
              </Box>
            </Box>
            {this.renderPosteriorSegmentTable()}
          </Box>
          {this.PreviousPowerPopup()}
          {
            this.state.isErrorMsg ?
              <ToastMsg
                severity={'error'}
                msg={this.state.isErrorMsgText}
                msgPop={this.msgClose.bind(this)}
              />
              : null
          }
          {
            this.state.successMsg ?
              <ToastMsg
                severity={'success'}
                msg={this.state.successMsgText}
                msgPop={this.msgClose.bind(this)}
              />
              : null
          }
        </Box>
      </ShowComponents>
    )
  }
}
