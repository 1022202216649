import { Box, Button, ButtonGroup, Tooltip} from '@mui/material'
import React, { Component } from 'react'
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import ToastMsg from '../ToastMsg/ToastMsg';
export default class CustomTextEditorTool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            savedContent: this.props.value ? this.props.value : '',
            boldActive: false,
            italicActive: false,
            underlineActive: false,
            leftAlignActive: false,
            centerAlignActive: false,
            rightAlignActive: false,
            highlightActive: false,
            stateUpdated: false,
            disable: true,
            isOutFocus: false,
        }
        this.editorRef = React.createRef();

    }
    
    componentDidUpdate(prevProps) {
        if (this.props?.fromTemplate && this.props.value !== prevProps.value && (!this.state.stateUpdated || this.props.changeTemplate)) {
            this.setState({
                savedContent: this.props.value,
                stateUpdated: true
            })
        }
        else if (this.props.value !== prevProps.value && this.props.value === "") { //For Clear all states
            this.setState({
                savedContent: "",
            })
            this.editorRef.current.innerHTML = ''
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }


    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    handleBold = () => {
        try {
            this.setState({ boldActive: !this.state.boldActive }, () => {
                this.editorRef.current.focus();
                document.execCommand('bold', false, null);
            });
        } catch (error) {
            this.errorMessage(error.message)
        }
    };

    handleItalic = () => {
        try {
            this.setState({ italicActive: !this.state.italicActive }, () => {
                this.editorRef.current.focus();
                document.execCommand('italic', false, null);
            });
        } catch (error) {
            this.errorMessage(error.message)
        }
    };

    handleAlignment = (alignment) => {
        try {
            document.execCommand('justify' + alignment, false, null);
            this.editorRef.current.focus();
            const alignStateKey = `${alignment.toLowerCase()}AlignActive`;
            this.setState(prevState => ({
                leftAlignActive: alignment === 'Left' ? !prevState[alignStateKey] : false,
                centerAlignActive: alignment === 'Center' ? !prevState[alignStateKey] : false,
                rightAlignActive: alignment === 'Right' ? !prevState[alignStateKey] : false,
            }),()=>{
                if(this.state.centerAlignActive === false && this.state.rightAlignActive === false){
                    document.execCommand('justifyLeft', false, null);
                }
            });
        } catch (error) {
            this.errorMessage(error.message)
        }
    };

    handleHighlight = () => {
        try {
            this.setState({ highlightActive: !this.state.highlightActive }, () => {
                document.execCommand('hiliteColor', false, 'yellow');
                this.editorRef.current.focus();
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    };

    handleUnderline = () => {
        try {
            this.setState({ underlineActive: !this.state.underlineActive }, () => {
                document.execCommand('underline', false, null);
                this.editorRef.current.focus();
            });
        } catch (error) {
            this.errorMessage(error.message)
        }
    };

    clearStyleState = () => {
        this.setState({
            disable: true,
            italicActive: false,
            boldActive: false,
            underlineActive: false,
            leftAlignActive: false,
            centerAlignActive: false,
            rightAlignActive: false,
        })
    }

    getTextStylesAtCursor = () => {
        this.setState({
            italicActive: false,
            boldActive: false,
            underlineActive: false,
            leftAlignActive: false,
            centerAlignActive: false,
            rightAlignActive: false,
            disable: false,
        },()=>{
            const selection = window.getSelection();
            if (!selection.rangeCount) return null;
        
            const range = selection.getRangeAt(0);
            const node = range.commonAncestorContainer;
            const textNode = node.nodeType === Node.TEXT_NODE ? node.parentElement : node;
            const computedStyle = window.getComputedStyle(textNode);
        
            const textStyle = {
                fontWeight: computedStyle.fontWeight,
                fontStyle: computedStyle.fontStyle,
                textDecoration: computedStyle.textDecoration,
                textAlign: computedStyle.textAlign
            };
    
            if (textStyle.fontStyle === "italic") {
                this.setState({ italicActive: true });
            } 
            if (textStyle.fontWeight === '700') {
                this.setState({ boldActive: true });
            }
            if (textStyle.textDecoration.includes("underline") || document.queryCommandState('underline')) {
                this.setState({ underlineActive: true });
            }
            if (textStyle.textAlign === 'left') {
                this.setState({ leftAlignActive: true });
            }
            if (textStyle.textAlign === 'center') {
                this.setState({ centerAlignActive: true });
            }
            if (textStyle.textAlign === 'right') {
                this.setState({ rightAlignActive: true });
            }
        })
    }

    render() {
        const { boldActive, italicActive, underlineActive, highlightActive, leftAlignActive, centerAlignActive, rightAlignActive } = this.state;
        return (
            <Box component={'div'} border={'1px solid lightgray'} width={this.props.width ? this.props.width : '100%'} marginBottom={'2vw'} marginTop={this.props.marginTop ? this.props.marginTop : 0} onMouseLeave={()=>{this.setState({isOutFocus: true})}} onMouseOver={()=>{this.setState({isOutFocus: false})} }>
                <Box component={'div'} display={'flex'} alignItems={'center'} borderBottom={'2px solid lightgray'} height={'3vw'} justifyContent={'space-between'} sx={{ padding: '0.5vw' }}>
                    <Box component={'div'} width={'70vw'} onClick={()=>{this.clearStyleState()}}>{this.props.label}</Box>
                    <Box component={'div'} sx={{ display: 'flex' }}>
                        <Box component={'div'} ml={'0.5vw'} mr={'0.3vw'}>
                            <ButtonGroup sx={{gap:'0.5vw'}} disabled={this.state.disable}>
                                <Tooltip placement='top' title='Bold' arrow>
                                    <Button sx={{maxWidth:'2.5vw', minWidth:'2.5vw'}}
                                        variant={boldActive ? 'contained' : 'text'}
                                        onClick={() => this.handleBold()}>
                                        <FormatBoldIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement='top' title='Italic' arrow>
                                    <Button sx={{maxWidth:'2.5vw', minWidth:'2.5vw'}} onClick={() => this.handleItalic()} variant={italicActive ? 'contained' : 'text'}>
                                        <FormatItalicIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement='top' title='Underline' arrow>
                                    <Button sx={{maxWidth:'2.5vw', minWidth:'2.5vw'}}
                                        variant={underlineActive ? 'contained' : 'text'}
                                        onClick={() => this.handleUnderline()}>
                                        <FormatUnderlinedIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement='top' title='Align Left' arrow>
                                    <Button sx={{maxWidth:'2.5vw', minWidth:'2.5vw'}}
                                        variant={leftAlignActive ? 'contained' : 'text'}
                                        onClick={() => this.handleAlignment('Left')}>
                                        <FormatAlignLeftIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement='top' title='Align Center' arrow>
                                    <Button sx={{maxWidth:'2.5vw', minWidth:'2.5vw'}}
                                        variant={centerAlignActive ? 'contained' : 'text'}
                                        onClick={() => this.handleAlignment('Center')}>
                                        <FormatAlignCenterIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement='top' title='Align Right' arrow>
                                    <Button sx={{maxWidth:'2.5vw', minWidth:'2.5vw'}}
                                        variant={rightAlignActive ? 'contained' : 'text'}
                                        onClick={() => this.handleAlignment('Right')}>
                                        <FormatAlignRightIcon />
                                    </Button>
                                </Tooltip>
                            </ButtonGroup>
                        </Box>
                        {/* <Box component={'div'} mr={'0.3vw'}>
                            <Tooltip placement='top' title='Highlight' arrow>
                                <Button className={`Common_Btn_Min_Width ${highlightActive ? 'active' : ''}`}
                                    variant={highlightActive ? 'contained' : 'text'}
                                    onClick={() => this.handleHighlight()}>
                                    <HighlightIcon />
                                </Button>
                            </Tooltip>
                        </Box> */}
                    </Box>
                </Box>
                <Box component={'div'}>
                    <Box
                        height={'auto'}
                        component={'div'}
                        ref={this.editorRef}
                        className="editor"
                        contentEditable="true"
                        style={{ border: '1px solid white', minHeight: '100px', padding: '5px' }}
                        dangerouslySetInnerHTML={{ __html: this.state.savedContent }}
                        onBlur={() => {
                            this.props.setValue(this.props.stateName, this.editorRef.current.innerHTML, this.props.postState)
                            if(this.state.isOutFocus === true){
                                this.clearStyleState()
                            } 
                        }}
                        onMouseUp={this.getTextStylesAtCursor}
                        onSelect={this.getTextStylesAtCursor}
                    >
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
