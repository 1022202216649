import { QuestionMark } from '@mui/icons-material'
import { Box, Button, Fab, Grid, Modal, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { ImagePaths } from '../../../Utility/ImagePaths'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import './reports.css'
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import { Colors } from '../../../Styles/Colors'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'

const reportsList = [
  {
    title: 'Financial Reports', icon: ImagePaths.opConfig.default, Access : CheckAccessFunc("Laboratory","Reports","Financial Reports", null, "Tab"),
    reports: [
      { report_names: 'Bill Report', path: '/LabReports/FinancialReport/BillReport' },
      { report_names: 'Receipt Bills', path: '/LabReports/FinancialReport/LabReceiptBills' },
      { report_names: 'LabCensusReport',path:"/LabReports/FinancialReport/LabCensusReport"},
      { report_names: 'Day End Report',path:"/LabReports/FinancialReport/LabDayEndReport"},
      { report_names: 'Corporate Bill Report',path:"/LabReports/FinancialReport/CorporateBillReport"},
      { report_names: 'Lab Share Report',path:"/LabReports/FinancialReport/LabShareReport"},
    ]
  },
  {
    title: 'Patient Report', icon: ImagePaths.opConfig.default, Access : CheckAccessFunc("Laboratory","Reports","Patient Reports", null, "Tab"),
    reports: [
      { report_names: 'Patient Report', path: '/LabReports/PatientReport/LabPatientReport' },
      { report_names: 'Diagnostic Census Report', path: '/LabReports/PatientReport/LabResultCensusReport' },
      { report_names: 'IP Admission List', path: '/LabReports/PatientReport/IpAdmissionReport'},
      { report_names: 'Culture Test Report', path: '/LabReports/PatientReport/CultureTestReport'},
      { report_names: 'Antibiotic Suscptiability Report', path: '/LabReports/PatientReport/AntibioticSusReport'},
    ]
  },
  {
    title: 'Business Report', icon: ImagePaths.corporateConfig.default, Access: CheckAccessFunc("Laboratory", "Reports", "Business Reports", null, "Tab"),
    reports: [
      { report_names: 'Referred Doctor Report', path: '/LabReports/BusinessReport/DoctorReport' },
      { report_names: 'Outsource Report', path: '/LabReports/BusinessReport/OutSourceReport' },
      { report_names: 'Group Wise Report', path: '/LabReports/BusinessReport/GroupwiseReport' },
      { report_names: 'Group Wise Summary', path: '/LabReports/BusinessReport/GroupwiseSummaryReport' },
      { report_names: 'Test Wise Report', path: '/LabReports/BusinessReport/TestwiseReport' },
      { report_names: 'Test Wise Summary', path: '/LabReports/BusinessReport/TestwiseSummaryReport' },
      { report_names: 'Package Wise Report', path: '/LabReports/BusinessReport/PackageWiseReport' },
    ]
  }
]

class LabReportsHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openPopUp: false,
      newReport: "",
      isOPT: false
    }
  }

  componentDidMount = () => {
    var loginInfo = JSON.parse(localGetItem("loggedInUserInfo"))
    this.setState({
      isOPT : loginInfo?.is_opt
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value
    })
  }

  reportsList = (item) => {
    return (
      <>
        <Box component={'div'} className='eMed_rts_card'>
          <Paper className='eMed_rts_content'>
            <Grid container className='eMed_rts_content_top'>
              <Grid item xs={12} className='eMed_rts_txt_one'>
                <Stack display={'flex'} flexDirection='row'>
                  <img src={item.icon} alt="" className='eMed_rts_icon' />
                  <Typography fontWeight={'600'}>{item.title}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} className='eMed_rts_content_btm'>
                {item?.reports?.map((list, index) => {
                    return <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <Typography display={index === 0 ? "none" : 'inline'} color={Colors.themeDark}>|</Typography>
                      <Button key={index} className='eMed_reports_text_btn'
                        onClick={() => {
                          this.props.history.push({ pathname: list.path })
                        }}>{list.report_names}</Button>
                    </Box>
                })}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </>
    )
  }

  handleOpen = () => {
    this.setState({ openPopUp: true })
  }

  handleClose = () => {
    this.setState({ openPopUp: false, newReport: "" })
  }

  PostExtraReportText = () => {
    try {
      let data = { "new_report": this.state.newReport }
      RestAPIService.create(data, Serviceurls.FO_REPORTS_POST)
        .then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.setState({
              openPopUp: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderReportsPopup = () => {
    const { t } = this.props
    return (
      <>
        <Modal open={this.state.openPopUp} >
          <Box className='eMed_rts_popup'>
            <Box className='eMed_rts_home_header'>
              <Typography component={'label'} variant='h6' fontWeight={600}>{t("extraReports")}</Typography>
              <Typography component={'p'} className='eMed_rts_home_txt_one'>{t("reportsNeedType")}</Typography>
            </Box>
            <Box component={'div'} margin="1vw" >
              <TextField
                variant='standard'
                rows={6}
                multiline
                fullWidth
                placeholder='Write what type of report you need'
                className='eMed_rts_home_txt'
                value={this.state.newReport}
                name='newReport'
                onChange={(e) => this.onChangeHandler(e)}
              />
            </Box>
            <Box component='div' margin={'1vw'} display={'flex'} justifyContent='flex-end'>
              <Button variant='outlined'
                className='eMed_rts_home_btn'
                size='small'
                onClick={() => { this.handleClose() }} >{t("Back")}</Button>
              <Button
                variant='contained'
                className='eMed_rts_home_btn_two'
                size='small'
                onClick={() => { this.PostExtraReportText() }}
              >{t("Send")}</Button>
            </Box>
          </Box>
        </Modal>
      </>
    )
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    const { t } = this.props
    return (
      <>
        <Box component={'div'} className='eMed_rts_home_container' overflow={'scroll'}>
          <Box position={'static'}>
            <Typography fontWeight={'600'} padding='1vw' borderBottom={'1px solid lightgray'}>{t("Reports")}</Typography>
          </Box>
          <Box>
            {reportsList.map((item, index) => {
              if ((CheckAccessFunc("front_office", "Reports", item.title, null, "Tab").viewAccess && (item.title !== "Optometrist Reports")) || ( this.state.isOPT && item.title === "Optometrist Reports")) {
                return (<Stack key={index}>{this.reportsList(item)}</Stack>)
              }
            })}
          </Box>
          <Tooltip placement='top' title='Raise New Reports' arrow><Fab color="primary"
            size='medium'
            className='eMed_rts_fab'
            onClick={() => { this.handleOpen() }}
          >
            <QuestionMark />
          </Fab></Tooltip>
          {this.renderReportsPopup()}
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
        </Box>
      </>
    )
  }
}

export default withTranslation()(LabReportsHome)