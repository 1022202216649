import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { DataGrid } from '@mui/x-data-grid';
import CommonGridHeader from '../../../Components/Common Components/CommonComponents';
import { Autocomplete, Box, Button, Drawer, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';

class BedAvailability extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            BedList: [],
            BlockList: [],
            BlockName: [],
            FloorList: [],
            Floorno: [],
            WardName: [],
            WardType: [],
            WardList: [],
            RoomList: null,
            TotalPatient: 0
        }
    }
    componentDidMount() {
        this.GetBedList()
        this.GetConstBedList()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetBedList() {
        try {
            this.LoaderFunction(true)
            var states = this.state
            var wardName = []; states.WardName?.forEach(element => { if (element.ward_name) { wardName.push(element.ward_name) } });
            var blockName = []; states.BlockName?.forEach(element => { if (element.block_name) { blockName.push(element.block_name) } });
            var wardType = []; states.WardType?.forEach(element => { if (element.value) { wardType.push(element.value) } });
            var floorNum = []; states.Floorno?.forEach(element => { if (element.floor_number) { floorNum.push(element.floor_number) } });

            RestAPIService.getAll(Serviceurls.BED_AVAIL + "?ward_name=" + states.WardName + "&block_name=" + states?.BlockName + "&floor_number=" + states.Floorno).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            BedList: response.data.data,
                            TotalPatient: response?.data?.total_patients ? response?.data?.total_patients : 0
                        },()=>{this.LoaderFunction(false)})
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (error) {
            this.LoaderFunction(false)
            if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message)
            } else {
                this.errorMessage(error.message)
            }
        }
    }

    GetConstBedList() {
        try {
            RestAPIService.getAll(Serviceurls.BED_AVAIL).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            ConstBedList: response.data.data
                        }, () => {
                            const Block = this.state.ConstBedList.map(item => item.block_name)
                            const uniqueBlock = [...new Set(Block)];
                            const Ward = this.state.ConstBedList.map(item => item.ward_name)
                            const uniqueWard = [...new Set(Ward)];
                            const Floor = this.state.ConstBedList.map(item => item.floor_number)
                            const uniqueFloor = [...new Set(Floor)];
                            this.setState({
                                BlockList: uniqueBlock,
                                WardList: uniqueWard,
                                FloorList: uniqueFloor
                            })
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (error) {
            if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message)
            } else {
                this.errorMessage(error.message)
            }
        }
    }

    GetBedListexcel = () => {
        const { t } = this.props
        try {
            var states = this.state
            var wardName = []; states.WardName?.forEach(element => { if (element.ward_name) { wardName.push(element.ward_name) } });
            var blockName = []; states.BlockName?.forEach(element => { if (element.block_name) { blockName.push(element.block_name) } });
            var wardType = []; states.WardType?.forEach(element => { if (element.value) { wardType.push(element.value) } });
            var floorNum = []; states.Floorno?.forEach(element => { if (element.floor_number) { floorNum.push(element.floor_number) } });

            RestAPIService.excelGet(Serviceurls.BED_AVAIL + "?ward_name=" + states?.WardName + "&block_name=" + states?.BlockName + "&floor_number=" + states?.Floorno + "&export_type=excel").
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message)
            } else {
                this.errorMessage(error.message)
            }
        }
    }

    // GetConfigList = () => {
    //     try {
    //         RestAPIService.getAll(Serviceurls.HOSPITAL_CONFIG).
    //             then((response) => {
    //                 if (response.data.status === "success") {
    //                     this.setState({
    //                         BlockList: response.data.data
    //                     })
    //                 }
    //                 else {
    //                     this.errorMessage(response.data.message)
    //                 }
    //             }).catch((error) => {
    //                 if (error?.response?.data?.status === "fail") {
    //                     this.errorMessage(error.response.data.message)
    //                 } else {
    //                     this.errorMessage(error.message)
    //                 }
    //             })

    //         RestAPIService.getAll(Serviceurls.WARD_CONFIG).
    //             then((response) => {
    //                 if (response.data.status === "success") {
    //                     this.setState({
    //                         WardList: response.data.data
    //                     })
    //                 }
    //                 else {
    //                     this.errorMessage(response.data.message)
    //                 }
    //             }).catch((error) => {
    //                 if (error?.response?.data?.status === "fail") {
    //                     this.errorMessage(error.response.data.message)
    //                 } else {
    //                     this.errorMessage(error.message)
    //                 }
    //             })
    //     }
    //     catch (error) {
    //         if (error?.response?.data?.status === "fail") {
    //             this.errorMessage(error.response.data.message)
    //         } else {
    //             this.errorMessage(error.message)
    //         }
    //     }
    // }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    closeFilter() {
        this.setState({
            FilterOpen: false
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    // renderFloorList(Floor = []) {
    //     try {
    //         const FloorCountList = [];
    //         for (let i = 0; i <= 20; i++) {
    //             let Object = { id: i, value: i, floor_number: i }
    //             FloorCountList.push(Object)
    //         }
    //         this.setState({
    //             FloorList: FloorCountList
    //         }, () => { this.setState({ Floorno: Floor }) })
    //     }
    //     catch (e) {
    //         this.errorMessage(e.message)
    //     }
    // }

    renderFilterScreen = () => {
        const { t } = this.props
        // const RoomTypes = [
        //     { Roomtype: "Unoccupied Rooms", value: "" },
        //     { Roomtype: "Occupied Rooms", value: "occupied" },
        //     { Roomtype: "All", value: "all" }
        // ]
        // const WardType = [
        //     { ward_type: "NON AC", value: "NON AC" },
        //     { ward_type: "AC", value: "AC" }
        // ]
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button emed_tid = {"LabCloseButton_testID01"} onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    {/* <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            options={RoomTypes}
                            getOptionLabel={(option) => (option?.Roomtype)}
                            value={this.state?.RoomList}
                            onChange={(event, newValue) => {
                                this.setState({ RoomList: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Room Type')}
                                />
                            )}
                        />
                    </Box> */}
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            size='small'
                            options={this.state?.BlockList}
                            getOptionLabel={(option) => (option)}
                            value={this.state?.BlockName}
                            onChange={(event, newValue) => {
                                this.setState({ BlockName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('SelectBlock')}
                                    inputProps={{...params.inputProps,'emed_tid': 'SelectBlock_TestID', }}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            size='small'
                            options={this.state?.FloorList}
                            getOptionLabel={(option) => (option)}
                            value={this.state?.Floorno}
                            onChange={(event, newValue) => {
                                this.setState({ Floorno: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('SelectFloor')}
                                    inputProps={{...params.inputProps,'emed_tid': 'SelectFloor_TestID', }}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            size='small'
                            options={this.state?.WardList}
                            getOptionLabel={(option) => (option)}
                            value={this.state?.WardName}
                            onChange={(event, newValue) => {
                                this.setState({ WardName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('SelectWard')}
                                    inputProps={{...params.inputProps,'emed_tid': 'SelectWard_TestID', }}
                                />
                            )}
                        />
                    </Box>
                    {/* <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            size='small'
                            options={WardType}
                            getOptionLabel={(option) => (option?.ward_type)}
                            value={this.state?.WardType}
                            onChange={(event, newValue) => {
                                this.setState({ WardType: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Ward Type')}
                                />
                            )}
                        />
                    </Box> */}
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small" emed_tid ={"Clear_testID03"}
                        onClick={() => {
                            this.setState({
                                BlockName: [],
                                Floorno: [],
                                WardName: [],
                                WardType: [],
                                WardList: [],
                                RoomList: null
                            }, () => this.GetBedList())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button emed_tid ={"Search_testID03"} variant='contained' size="small"
                        onClick={() => {
                            this.GetBedList()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    renderBedList = () => {
        try {
            this.state.BedList?.forEach((element, index) => { element.sno = index + 1 })
            const { t } = this.props
            const columns = [
                { field: "sno", sortable: false, flex: 0.06, headerName: t("SNo") },
                {
                    field: "block_name", flex: 0.22, headerName: t("BlockName"),
                    renderCell: (params) => (<Box component={'div'}>{params?.row?.block_name?.length > 22 ?
                        <Tooltip placement="top" title={params?.row?.block_name} arrow><div>{params?.row?.block_name.slice(0, 22) + "..."}</div></Tooltip>
                        : params?.row?.block_name ? params?.row?.block_name : "-"}</Box>)
                },
                {
                    field: "floor_number", flex: 0.12, headerName: t("Floor No"), headerAlign: "center", align: "center",
                    renderCell: (params) => (<Box component={'div'}>{(params?.row?.floor_number || params?.row?.floor_number === 0) ? params?.row?.floor_number : "-"}</Box>)
                },
                {
                    field: "ward_name", flex: 0.17, headerName: t("WardName"),
                    renderCell: (params) => (<Box component={'div'}>{params?.row?.ward_name ? params?.row?.ward_name : "-"}</Box>)
                },
                {
                    field: "no_of_patient", flex: 0.17, headerName: t("No of Patient"), headerAlign: "center", align: "center",
                    renderCell: (params) => (<Box component={'div'}>{(params?.row?.no_of_patient || params?.row?.no_of_patient === 0) ? params?.row?.no_of_patient : "-"}</Box>)
                },
                // {
                //     field: "room_number", flex: 0.12, headerName: t("Room No"), headerAlign: "center", align: "center",
                //     renderCell: (params) => (<Box component={'div'}>{(params?.row?.room_number || params?.row?.room_number === 0) ? params?.row?.room_number : "-"}</Box>)
                // },
                // {
                //     field: "bed_number", flex: 0.12, headerName: t("BedNo"), headerAlign: "center", align: "center",
                //     renderCell: (params) => (<Box component={'div'}>{(params?.row?.bed_number || params?.row?.bed_number === 0) ? params?.row?.bed_number : "-"}</Box>)
                // }
            ]
            return (
                <Stack component={"div"}>
                    <Paper className='eMed_Table_Wrapper'>
                        <DataGrid
                            rows={this.state.BedList}
                            columns={columns}
                            getRowId={(row) => row['sno']}
                            page={this.state.page}
                            pageSize={this.state.pageSize}
                            hideFooterSelectedRowCount
                            localeText={{
                                toolbarColumns: "",
                                toolbarDensity: "",
                                toolbarExport: "",
                                toolbarFilters: "",
                                toolbarExportPrint: ""
                            }}
                            headerHeight={40}
                            components={{
                                Toolbar: CommonGridHeader.CommonToolBar,
                                NoRowsOverlay: () => (
                                    <Stack className='eMed_conf_nodata'>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }}
                            rowsPerPageOptions={[10, 20, 30]}
                            pagination
                            disableSelectionOnClick
                            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                            loading={this.state.isLoader}
                        />
                    </Paper>
                </Stack>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {

        return (
            <Box component={"div"} className='eMed_Main_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard("Total Patients", this.state.TotalPatient, true)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Tooltip title="Filter" placement='top' arrow>
                                <Button className='eMed_usrconf_btn' emed_tid = {"Filter_testID001"} onClick={() => { this.setState({ FilterOpen: true }) }}>
                                    <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>
                        <Tooltip title="Export" placement='top' arrow>
                            <Button className='eMed_usrconf_btn' emed_tid = {"LabUploadButton_testID001"}  onClick={() => { this.GetBedListexcel() }}>
                                <img src={ImagePaths.LabUploadButton.default} alt="print" className='eMed_action_img' />
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
                <Box component={"div"} className='eMed_Table_Container'>
                    {this.renderBedList()}
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter.bind(this) }}
                >{this.renderFilterScreen()}</Drawer>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
            </Box>
        )
    }
}
export default withTranslation()(BedAvailability);