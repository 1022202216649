import { Autocomplete, Box, Button, Chip, CircularProgress, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import Loader from '../../../../Components/Loader';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { Colors } from '../../../../Styles/Colors';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import {
  Activity_Level, Blood_GLucouse_Test, Blood_Glucouse_Symptoms,
  Family_Member_List, High_Glucouse_Test, Intensity_Level, Name_of_Bg_Meter,
  Nutrition, Treated_of_Diabetic, Type_Exercise, Types_of_Diabetic, WaterConsumption,
  Years_of_Diabetics, Yes_Or_No
} from '../../../../Utility/Constants';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTime } from 'luxon';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';

export default class DocDiabiaticHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      patientDetails: [],
      clinicId: "",
      appointmentId: "",
      patientId: "",
      yesorNoList: Yes_Or_No,
      diabeticYearsList: Years_of_Diabetics,
      diabeticFamilyList: Family_Member_List,
      diabeticBgMeterList: Name_of_Bg_Meter,
      diabeticTreatedList: Treated_of_Diabetic,
      diabeticTypeList: Types_of_Diabetic,
      nutritionList: Nutrition,
      waterConsumption: WaterConsumption,
      activityLevel: Activity_Level,
      intensityLevel: Intensity_Level,
      exerciseType: Type_Exercise,
      bloodGlucouseTest: Blood_GLucouse_Test,
      highGlucouseTest: High_Glucouse_Test,
      bloodGlucouseSymptomns: Blood_Glucouse_Symptoms,

      //DIABETIC HISTROY
      selectedDiabeticYesOrNo: "",
      selectedBloodGlucouseYesOrNo: "",
      DiabeticBloodComments: "",
      selectedLowGlucouseYesOrNo: "",
      DiabeticLowBloodComments: "",
      selectedDiabeticYears: "",
      selectedDiabeticFamily: "",
      selectedDiabeticType: "",
      selectedDiabeticTreatment: [],
      selectedDiabeticBgMeter: "",
      diabeticUsualResult: "",
      diabeticResult: "",
      diabeticDate: null,
      diabeticHistoryEdit: false,
      loading: false,

      //NUTRITION
      selectedNutritionYesOrNo: "",
      counsellingDescrip: "",
      selectedTypeNutrition: "",
      earlyDescrip: "",
      breakfastDescrip: "",
      midDescrip: "",
      lunchDescrip: "",
      eveningDescrip: "",
      dinnerDescrip: "",
      lateNightDescrip: "",
      selectedTypeNutritionValue: "",
      selectedWaterConsumption: null,
      nutritionHistoryEdit: false,
      nutritionLoading: false,

      //PHYSICAL ACTIVITY
      selectedPhyYesOrNo: "",
      selectedPhyactivity: null,
      selectedPhyDays: null,
      phyworkout: "",
      selectedPhyIntensity: null,
      phyYesDescrip: "",
      selectedPhyTypeExercise: null,
      phyExerciseDescrip: "",
      phySkipReason: "",
      phyHistoryEdit: false,
      phyLoading: false,
      physicalId: null,

      //BLOOD GLUCOUSE
      selectedBloodMonth: null,
      selectedBloodAbv: "",
      selectedBloodBel: "",
      selectedHighBlood: [],
      selectedLowBlood: [],
      selectedBloodFeet: "",
      feetDescrip: "",
      SelectedBloodVision: "",
      visionDescrip: "",
      bloodGlucoseEdit: false,
      bloodGluLoading: false,
      bgMonitoringId: null,

      //Menstrual
      AgeatMenarche: "",
      AgeatMenopause: "",
      ChildBirthAbortion: "",
      MensuralCycle: "",
    };
    this.chidComponentIds = ['diabetic', 'nutrition', 'physical', 'bloodGlucouse', 'mensuralHistory']
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    this.setState({
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id
    }, () => {
      this.getPatientDetailData()
      // this.getDiabeticHistoryData()
      // this.getNutritionHistoryData()
      // this.getPhysicalHistoryData()
      // this.getBloodGlouseHistoryData()
    })
  }

  setupIntersectionObserver = () => {
    try {
      const option = {
        threshold: 0.25
      }
      this.observer = new IntersectionObserver(this.InterSerctionHandler, option)
      this.chidComponentIds?.forEach(element => {
        const childElement = document.getElementById(element)
        if (childElement) {
          this.observer?.observe(childElement)
        }
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  componentWillUnmount() {
    this.observer?.disconnect()
  }

  InterSerctionHandler = (entry) => {
    try {
      entry?.forEach(element => {
        if (element.isIntersecting) {
          const childId = element.target.id
          this.diagnosisHistoryAPICall(childId)
          this.observer?.unobserve(element.target)
        }
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  diagnosisHistoryAPICall = (item) => {
    try {
      switch (item) {
        case 'diabetic':
          this.getDiabeticHistoryData()
          break;
        case 'nutrition':
          this.getNutritionHistoryData()
          break;
        case 'physical':
          this.getPhysicalHistoryData()
          break;
        case 'bloodGlucouse':
          this.getBloodGlouseHistoryData()
          break;
        case 'mensuralHistory':
          this.getMensuralHistoryData()
          break;
        default: break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
            this.setupIntersectionObserver()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  //DIABETIC HISTORY API

  getDiabeticHistoryData = () => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_DIABETIC_HISTORY + `?patient_id=${this.state.patientId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ loading: false })
            let data = response?.data?.data
            if (Object.keys(data)?.length > 0) {
              this.setState({
                selectedDiabeticYesOrNo: data?.new_diabetes_diagnosed === null ? '' : data?.new_diabetes_diagnosed ? 'Yes' : 'No',
                selectedBloodGlucouseYesOrNo: data?.blood_glucose_check === null ? '' : data?.blood_glucose_check ? 'Yes' : 'No',
                DiabeticBloodComments: data?.bg_check_frequency,
                selectedLowGlucouseYesOrNo: data?.low_blood_glucose === null ? '' : data?.low_blood_glucose ? 'Yes' : 'No',
                DiabeticLowBloodComments: data?.low_bg_date_time,
                selectedDiabeticYears: data?.diabetes_duration,
                selectedDiabeticFamily: data?.diabetes_family_history,
                selectedDiabeticType: data?.diabetes_type === 1 ? 'Type 1' : data?.diabetes_type === 2 ? 'Type 2' : data?.diabetes_type === 0 ? 'Not Sure' : data?.diabetes_type,
                selectedDiabeticTreatment: data?.treatment_method[0]?.includes(',') ? data?.treatment_method[0]?.split(',') : data?.treatment_method,
                selectedDiabeticBgMeter: data?.bg_check_meter,
                diabeticUsualResult: data?.bg_usual_result,
                diabeticResult: data?.last_a1c_result,
                diabeticDate: data?.last_ac1_date,
                diabeticHistoryEdit: true
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  postDiabeticHistoryData = () => {
    try {
      let date = new Date(this.state.diabeticDate)
      let diabeticDate = this.state.diabeticDate !== null ? DateTime.fromJSDate(date).toFormat('yyyy-MM-dd') : null
      let data = {
        bg_check_frequency: this.state.DiabeticBloodComments,
        bg_check_meter: this.state.selectedDiabeticBgMeter,
        bg_usual_result: +this.state.diabeticUsualResult,
        blood_glucose_check: (this.state.selectedBloodGlucouseYesOrNo === '') ? null : (this.state.selectedBloodGlucouseYesOrNo === 'Yes') ? true : false,
        diabetes_duration: +this.state.selectedDiabeticYears,
        diabetes_family_history: this.state.selectedDiabeticFamily,
        diabetes_type: this.state.selectedDiabeticType === 'Type 1' ? 1 : this.state.selectedDiabeticType === 'Type 2' ? 2 : this.state.selectedDiabeticType === 'Not Sure' ? 0 : this.state.selectedDiabeticType,
        last_a1c_result: this.state.diabeticResult === '' ? null : +this.state.diabeticResult,
        last_ac1_date: diabeticDate,
        low_bg_date_time: this.state.DiabeticLowBloodComments,
        low_blood_glucose: (this.state.selectedLowGlucouseYesOrNo === '') ? null : (this.state.selectedLowGlucouseYesOrNo === 'Yes') ? true : false,
        new_diabetes_diagnosed: (this.state.selectedDiabeticYesOrNo === '') ? null : (this.state.selectedDiabeticYesOrNo === 'Yes') ? true : false,
        patient_id: this.state.patientId,
        treatment_method: this.state.selectedDiabeticTreatment
      }
      this.setState({ loading: true })
      RestAPIService.create(data, Serviceurls.DOC_DIABETIC_HISTORY)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ loading: false })
            this.successMessage(response?.data?.message)
            this.onClearDiabeticHistory()
            this.setState({ diabeticHistoryEdit: false })
            this.getDiabeticHistoryData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  //NUTRITION HISTORY API

  getNutritionHistoryData = () => {
    try {
      this.setState({ nutritionLoading: true })
      RestAPIService.getAll(Serviceurls.DOC_NUTRITION_HISTORY + `?patient_id=${this.state.patientId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ nutritionLoading: false })
            let data = response?.data?.data
            if (Object.keys(data)?.length > 0) {
              this.setState({
                breakfastDescrip: data?.food_breakfast_amount,
                dinnerDescrip: data?.food_dinner_amount,
                earlyDescrip: data?.food_early_morning_amount,
                eveningDescrip: data?.food_evening_amount,
                lateNightDescrip: data?.food_late_night_amount,
                lunchDescrip: data?.food_lunch_amount,
                midDescrip: data?.food_mid_morning_amount,
                nutritionFrozen: data?.is_frozen,
                counsellingDescrip: data?.nutrition_counselling_date,
                selectedNutritionYesOrNo: data?.nutritional_counselling === null ? '' : data?.nutritional_counselling ? 'Yes' : 'No',
                selectedWaterConsumption: data?.water_consumption,
                selectedTypeNutrition: (data?.nutrition_type).toString(),
                nutritionHistoryEdit: true,
                selectedTypeNutritionValue: (data?.nutrition_type).toString()
              })
            }
          }
        }).catch(error => {
          if (error?.response?.data?.message) {
            this.setState({ nutritionLoading: false })
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.setState({ nutritionLoading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ nutritionLoading: false })
      this.errorMessage(error.message)
    }
  }

  postNutritionHistoryData = () => {
    try {
      let data = {
        food_breakfast_amount: this.state.breakfastDescrip,
        food_dinner_amount: this.state.dinnerDescrip,
        food_early_morning_amount: this.state.earlyDescrip,
        food_evening_amount: this.state.eveningDescrip,
        food_late_night_amount: this.state.lateNightDescrip,
        food_lunch_amount: this.state.lunchDescrip,
        food_mid_morning_amount: this.state.midDescrip,
        nutrition_counselling_date: this.state.counsellingDescrip,
        nutrition_type: this.state.selectedTypeNutritionValue,
        nutritional_counselling: this.state.selectedNutritionYesOrNo === '' ? null : this.state.selectedNutritionYesOrNo === 'Yes' ? true : false,
        patient_id: this.state.patientId,
        water_consumption: this.state.selectedWaterConsumption
      }
      this.setState({ nutritionLoading: true })
      RestAPIService.create(data, Serviceurls.DOC_NUTRITION_HISTORY)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ nutritionLoading: false })
            this.successMessage(response.data.message)
            this.onClearNutritionData()
            this.getNutritionHistoryData()
          }
        }).catch(error => {
          if (error?.response?.data?.message) {
            this.setState({ nutritionLoading: false })
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.setState({ nutritionLoading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ nutritionLoading: false })
      this.errorMessage(error.message)
    }
  }

  //PHYSICAL HISTORY API

  getPhysicalHistoryData = () => {
    try {
      this.setState({ phyLoading: true })
      RestAPIService.getAll(Serviceurls.DOC_PHYSICAL_HISTORY_GET + `?patient_id=${this.state.patientId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ phyLoading: false })
            let data = response?.data?.data
            if (Object.keys(data)?.length > 0) {
              this.setState({
                phyHistoryEdit: true,
                selectedPhyactivity: data?.current_activity_level,
                selectedPhyYesOrNo: data?.exercise_regularly === '' ? '' : data?.exercise_regularly ? 'Yes' : 'No',
                selectedPhyDays: data?.exercise_frequency_weeks,
                phyworkout: data?.exercise_duration_minutes,
                selectedPhyTypeExercise: data?.exercise_types,
                selectedPhyIntensity: this.state.intensityLevel?.find((item) => item?.value === ('0' + (data?.exercise_intensity)?.toString()) ? item : null),
                phyYesDescrip: data?.exercise_types_desc,
                phySkipReason: data?.exercise_skip_reason,
                physicalId: data?.physical_activity_id
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ phyLoading: false })
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.setState({ phyLoading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ phyLoading: false })
      this.errorMessage(error.message)
    }
  }

  postPhysicalHistoryData = () => {
    try {
      let data = {
        current_activity_level: this.state.selectedPhyactivity,
        exercise_duration_minutes: this.state.phyworkout,
        exercise_frequency_weeks: this.state.selectedPhyDays,
        exercise_intensity: this.state.selectedPhyIntensity?.value,
        exercise_regularly: this.state.selectedPhyYesOrNo,
        exercise_skip_reason: this.state.phySkipReason,
        exercise_types: this.state.selectedPhyTypeExercise,
        exercise_types_desc: this.state.phyYesDescrip,
        patient_id: this.state.patientId,
        physical_activity_id: this.state.physicalId ? this.state.physicalId : null
      }
      this.setState({ phyLoading: true })
      RestAPIService.create(data, Serviceurls.DOC_PHYSICAL_HISTORY_POST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.onClearPhysicalHistoryData()
            this.getPhysicalHistoryData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ phyLoading: false })
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.setState({ phyLoading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ phyLoading: false })
      this.errorMessage(error.message)
    }
  }

  //BLOOD GLUCOSE API

  getBloodGlouseHistoryData = () => {
    try {
      this.setState({ bloodGluLoading: true })
      RestAPIService.getAll(Serviceurls.DOC_BLOOD_GLUCOSE_HISTORY_GET + `?patient_id=${this.state.patientId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ bloodGluLoading: false })
            let data = response?.data?.data
            if (Object.keys(data)?.length > 0) {
              this.setState({
                bloodGlucoseEdit: true,
                selectedBloodAbv: data?.bg_above_300 === null ? '' : data?.bg_above_300 ? 'Yes' : 'No',
                selectedBloodBel: data?.bg_below_70 === null ? '' : data?.bg_below_70 ? 'Yes' : 'No',
                selectedBloodMonth: data?.bg_check_frequency,
                bgMonitoringId: data?.bg_monitor_id,
                selectedBloodFeet: data?.check_your_feet === null ? '' : data?.check_your_feet ? 'Yes' : 'No',
                selectedHighBlood: data?.high_blood_glucose[0]?.includes(',') ? data?.high_blood_glucose[0]?.split(',') : data?.high_blood_glucose,
                selectedLowBlood: data?.low_blood_glucose[0]?.includes(',') ? data?.low_blood_glucose[0]?.split(',') : data?.low_blood_glucose,
                feetDescrip: data?.how_freq,
                SelectedBloodVision: data?.vision_problem === null ? '' : data?.vision_problem ? 'Yes' : 'No',
                visionDescrip: data?.vision_problem_desc
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ bloodGluLoading: false })
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.setState({ bloodGluLoading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ bloodGluLoading: false })
      this.errorMessage(error.message)
    }
  }


  getMensuralHistoryData = () => {
    try {
      this.setState({ mensuralloading: true })
      RestAPIService.getAll(Serviceurls.DOC_BLOOD_MENSURAL_HISTORY_GET + `?patient_id=${this.state.patientId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ mensuralloading: false })
            let data = response?.data?.data
            if (Object.keys(data)?.length > 0) {
              this.setState({
                MensuralEdit : true,
                AgeatMenarche: data?.age_at_menarche ? data?.age_at_menarche : "" ,
                AgeatMenopause: data?.age_at_menopause ? data?.age_at_menopause : "" ,
                ChildBirthAbortion: data?.child_birth ? data?.child_birth : "" ,
                MensuralCycle: data?.mensural_cycle ? data?.mensural_cycle : "" ,
                MensuralHistoryID : data?.id ? data?.id : null
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ mensuralloading: false })
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.setState({ mensuralloading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ mensuralloading: false })
      this.errorMessage(error.message)
    }
  }

  postBloodGlouseHistoryData = () => {
    try {
      let data = {
        bg_above_300: this.state.selectedBloodAbv === '' ? null : this.state.selectedBloodAbv === 'Yes' ? true : false,
        bg_below_70: this.state.selectedBloodBel === '' ? null : this.state.selectedBloodBel === 'Yes' ? true : false,
        bg_check_frequency: this.state.selectedBloodMonth,
        check_your_feet: this.state.selectedBloodFeet === '' ? null : this.state.selectedBloodFeet === 'Yes' ? true : false,
        high_blood_glucose: this.state.selectedHighBlood,
        how_freq: this.state.feetDescrip,
        low_blood_glucose: this.state.selectedLowBlood,
        patient_id: this.state.patientId,
        vision_problem: this.state.SelectedBloodVision === '' ? null : this.state.SelectedBloodVision === 'Yes' ? true : false,
        vision_problem_desc: this.state.visionDescrip,
        bg_monitor_id: this.state.bgMonitoringId ? this.state.bgMonitoringId : null
      }
      this.setState({ bloodGluLoading: true })
      RestAPIService.create(data, Serviceurls.DOC_BLOOD_GLUCOSE_HISTORY_POST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.onClearBloodGlucoseData()
            this.getBloodGlouseHistoryData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ bloodGluLoading: false })
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.setState({ bloodGluLoading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ bloodGluLoading: false })
      this.errorMessage(error.message)
    }
  }

  postMensuralHistoryData = () => {
    try {
      let data = {
        patient_id: this.state.patientId,
        age_at_menarche: this.state.AgeatMenarche ? this.state.AgeatMenarche : "",
        age_at_menopause: this.state.AgeatMenopause ? this.state.AgeatMenopause : "",
        child_birth: this.state.ChildBirthAbortion ? this.state.ChildBirthAbortion : "",
        mensural_cycle: this.state.MensuralCycle ? this.state.MensuralCycle : "",
        menstrual_history_id : this.state.MensuralHistoryID ? this.state.MensuralHistoryID : null
      }
      this.setState({ mensuralloading: true })
      RestAPIService.create(data, Serviceurls.DOC_BLOOD_MENSURAL_HISTORY_GET)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.onClearMensuralData()
            this.getMensuralHistoryData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ mensuralloading: false })
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.setState({ mensuralloading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ mensuralloading: false })
      this.errorMessage(error.message)
    }
  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  onClearDiabeticHistory = () => {
    try {
      this.setState({
        DiabeticBloodComments: '',
        selectedDiabeticBgMeter: '',
        diabeticUsualResult: '',
        selectedBloodGlucouseYesOrNo: '',
        selectedDiabeticYears: '',
        selectedDiabeticFamily: '',
        selectedDiabeticType: '',
        diabeticResult: '',
        diabeticDate: null,
        selectedLowGlucouseYesOrNo: '',
        selectedDiabeticTreatment: [],
        DiabeticLowBloodComments: '',
        selectedDiabeticYesOrNo: '',
        bgMonitoringId: null,
        physicalId: null
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearNutritionData = () => {
    try {
      this.setState({
        breakfastDescrip: '',
        earlyDescrip: '',
        midDescrip: '',
        lunchDescrip: '',
        lateNightDescrip: '',
        eveningDescrip: '',
        dinnerDescrip: '',
        counsellingDescrip: '',
        selectedWaterConsumption: '',
        selectedNutritionYesOrNo: '',
        selectedTypeNutrition: '',
        selectedTypeNutritionValue: ''
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearPhysicalHistoryData = () => {
    try {
      this.setState({
        selectedPhyactivity: '',
        selectedPhyYesOrNo: '',
        selectedPhyDays: '',
        phyworkout: '',
        selectedPhyTypeExercise: '',
        selectedPhyIntensity: '',
        phyYesDescrip: '',
        phySkipReason: ''
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearBloodGlucoseData = () => {
    try {
      this.setState({
        selectedBloodAbv: '',
        selectedBloodBel: '',
        selectedBloodMonth: '',
        bgMonitoringId: '',
        selectedBloodFeet: '',
        selectedHighBlood: [],
        selectedLowBlood: [],
        feetDescrip: '',
        SelectedBloodVision: '',
        visionDescrip: ''
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearMensuralData = () => {
    try {
      this.setState({
        AgeatMenarche: '',
        AgeatMenopause: '',
        ChildBirthAbortion: '',
        MensuralCycle: '',
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderDiabeticBox = () => {
    try {
      return (
        <Box id='diabetic' component={'div'} border={'1px solid lightgray'} mb={'1vw'} sx={{ backgroundColor: Colors.white }}>
          <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Diabetic History'}</Typography>
            {this.state.diabeticHistoryEdit ?
              <Button className='Common_Btn_Min_Width' emed_tid='dia_edit_btn'
                onClick={() => this.setState({ diabeticHistoryEdit: false })}
              >
                <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.5vw'} width={'1.5vw'} />
              </Button> :
              <Box display={'flex'}>
                <Button variant='contained' emed_tid='dia_add_btn'
                  disabled={this.state.loading}
                  sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1vw' }}
                  onClick={() => this.postDiabeticHistoryData()}
                >Add</Button>
                <Button variant='outlined' emed_tid='dia_clr_btn'
                  sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1vw' }}
                  onClick={() => this.onClearDiabeticHistory()}
                >Clear</Button>
              </Box>
            }
          </Box>
          {this.state.loading ? <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'} height={'20vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}><CircularProgress color='primary' /></Box> :
            <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'}>
              <Box component={'div'} mt={'0.5vw'} width={'100%'}>
                <Box component={'div'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                  <Box component={'div'} width={'25vw'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'New to diagnosis ? *'}</Typography>
                    <Box component={'div'} emed_tid='dia_new'>
                      {this.state.yesorNoList?.map((item, index) => {
                        return (
                          <Chip
                            disabled={this.state.diabeticHistoryEdit}
                            className={`eMed_chip ${item.label === this.state.selectedDiabeticYesOrNo ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                            label={item.label}
                            variant={item.label === this.state.selectedDiabeticYesOrNo ? 'contained' : 'outlined'}
                            key={index}
                            onClick={() => {
                              if (item.label === this.state.selectedDiabeticYesOrNo) {
                                this.setState({ selectedDiabeticYesOrNo: '' })
                              } else {
                                this.setState({ selectedDiabeticYesOrNo: item?.label }, () => {
                                  if (this.state.selectedDiabeticYesOrNo === 'Yes') {
                                    this.setState({
                                      selectedBloodGlucouseYesOrNo: '',
                                      DiabeticBloodComments: '',
                                      selectedLowGlucouseYesOrNo: '',
                                      DiabeticLowBloodComments: '',
                                      selectedDiabeticYears: '',
                                      selectedDiabeticTreatment: [],
                                      selectedDiabeticBgMeter: '',
                                      diabeticUsualResult: '',
                                      diabeticResult: '',
                                      diabeticDate: null
                                    })
                                  }
                                })
                              }
                            }}
                            // color={'primary'}
                            clickable
                          />
                        )
                      })}
                    </Box>
                  </Box>
                  <Box component={'div'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={this.state.selectedDiabeticYesOrNo === 'Yes' ? 'lightgray' : Colors.SecondaryText}>{'Do you check your blood glucouse level ?'}</Typography>
                    <Box component={'div'} emed_tid='dia_bld_lvl'>
                      {this.state.yesorNoList?.map((item, index) => {
                        return (
                          <Chip
                            disabled={((this.state.selectedDiabeticYesOrNo === 'Yes') || (this.state.diabeticHistoryEdit)) ? true : false}
                            className={`eMed_chip ${item.label === this.state.selectedBloodGlucouseYesOrNo ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                            label={item.label}
                            variant={item.label === this.state.selectedBloodGlucouseYesOrNo ? 'contained' : 'outlined'}
                            key={index}
                            onClick={() => {
                              if (item.label === this.state.selectedBloodGlucouseYesOrNo) {
                                this.setState({ selectedBloodGlucouseYesOrNo: '' })
                              } else {
                                this.setState({ selectedBloodGlucouseYesOrNo: item?.label })
                              }
                            }}
                            // color={'primary'}
                            clickable
                          />
                        )
                      })}
                    </Box>
                    <TextField
                      disabled={this.state.selectedDiabeticYesOrNo === 'Yes' ? true : false}
                      InputProps={{
                        readOnly: this.state.diabeticHistoryEdit,
                        emed_tid:'dia_cmt'
                      }}
                      multiline
                      rows={2}
                      label='comments'
                      sx={{ width: '25vw', mt: '0.5vw' }}
                      value={this.state.DiabeticBloodComments}
                      onChange={(e) => {
                        let value = e.target.value
                        if (value?.length <= 250) {
                          this.setState({ DiabeticBloodComments: e.target.value })
                        } else {
                          this.errorMessage('Allowed Only 250 Characters')
                        }
                      }}
                    />
                  </Box>
                  <Box component={'div'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={this.state.selectedDiabeticYesOrNo === 'Yes' ? 'lightgray' : Colors.SecondaryText}>{'Do you get low glucouse ?'}</Typography>
                    <Box component={'div'} emed_tid='dia_low_glu'>
                      {this.state.yesorNoList?.map((item, index) => {
                        return (
                          <Chip
                            disabled={((this.state.selectedDiabeticYesOrNo === 'Yes') || (this.state.diabeticHistoryEdit)) ? true : false}
                            className={`eMed_chip ${item.label === this.state.selectedLowGlucouseYesOrNo ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                            label={item.label}
                            variant={item.label === this.state.selectedLowGlucouseYesOrNo ? 'contained' : 'outlined'}
                            key={index}
                            onClick={() => {
                              if (item.label === this.state.selectedLowGlucouseYesOrNo) {
                                this.setState({ selectedLowGlucouseYesOrNo: '' })
                              } else {
                                this.setState({ selectedLowGlucouseYesOrNo: item?.label })
                              }
                            }}
                            // color={'primary'}
                            clickable
                          />
                        )
                      })}
                    </Box>
                    <TextField
                      disabled={this.state.selectedDiabeticYesOrNo === 'Yes' ? true : false}
                      InputProps={{
                        readOnly: this.state.diabeticHistoryEdit,
                        emed_tid:'dia_low_cmt'
                      }}
                      multiline
                      rows={2}
                      label='comments'
                      sx={{ width: '25vw', mt: '0.5vw' }}
                      value={this.state.DiabeticLowBloodComments}
                      onChange={(e) => {
                        let value = e.target.value
                        if (value?.length <= 250) {
                          this.setState({ DiabeticLowBloodComments: e.target.value })
                        } else {
                          this.errorMessage('Allowed Only 250 Characters')
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box component={'div'} mt={'0.5vw'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                <Box component={'div'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={this.state.selectedDiabeticYesOrNo === 'Yes' ? 'lightgray' : Colors.SecondaryText}>{'How many years have you had diabetics ? *'}</Typography>
                  <Box mt={'0.5vw'}>
                    <Autocomplete
                      disabled={this.state.selectedDiabeticYesOrNo === 'Yes' ? true : false}
                      clearIcon
                      size='small'
                      freeSolo
                      sx={{ width: '25vw' }}
                      options={this.state.diabeticYearsList.map((item) => item.label)}
                      value={this.state.selectedDiabeticYears}
                      limitTags={3}
                      autoComplete='off'
                      onChange={(event, newValue) => {
                        this.setState({
                          selectedDiabeticYears: newValue
                        })
                      }}
                      onInputChange={(event, newValue) => {
                        this.setState({ selectedDiabeticYears: newValue })
                      }}
                      readOnly={this.state.diabeticHistoryEdit}
                      renderInput={(params) => <TextField {...params} label='Select Diabetic Years' inputProps={{...params.inputProps,emed_tid:'dia_slt_yrs'}} />}
                    />
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Who is your family has/had diabetics ?'}</Typography>
                  <Box mt={'0.5vw'}>
                    <Autocomplete
                      clearIcon
                      size='small'
                      freeSolo
                      sx={{ width: '25vw' }}
                      options={this.state.diabeticFamilyList.map((item) => item.label)}
                      value={this.state.selectedDiabeticFamily}
                      limitTags={3}
                      autoComplete='off'
                      onChange={(event, newValue) => {
                        this.setState({
                          selectedDiabeticFamily: newValue
                        })
                      }}
                      readOnly={this.state.diabeticHistoryEdit}
                      onInputChange={(event, newValue) => {
                        this.setState({ selectedDiabeticFamily: newValue })
                      }}
                      renderInput={(params) => <TextField {...params} label='Select Family Member' inputProps={{...params.inputProps,emed_tid:'dia_slt_fmly'}} />}
                    />
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'What type of diabetic do you have ?'}</Typography>
                  <Box mt={'0.5vw'}>
                    <Autocomplete
                      clearIcon
                      size='small'
                      freeSolo
                      sx={{ width: '25vw' }}
                      options={this.state.diabeticTypeList.map((item) => item.label)}
                      value={this.state.selectedDiabeticType}
                      limitTags={3}
                      autoComplete='off'
                      onChange={(event, newValue) => {
                        this.setState({
                          selectedDiabeticType: newValue
                        })
                      }}
                      readOnly={this.state.diabeticHistoryEdit}
                      onInputChange={(event, newValue) => {
                        this.setState({ selectedDiabeticType: newValue })
                      }}
                      renderInput={(params) => <TextField {...params} label='Select Diabetic Type' inputProps={{...params.inputProps,emed_tid:'slt_dia_type'}}/>}
                    />
                  </Box>
                </Box>
              </Box>
              <Box component={'div'} mt={'0.5vw'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={this.state.selectedDiabeticYesOrNo === 'Yes' ? 'lightgray' : Colors.SecondaryText}>{'How is your diabetic treated ?'}</Typography>
                  <Box mt={'0.5vw'}>
                    <Autocomplete
                      disabled={this.state.selectedDiabeticYesOrNo === 'Yes' ? true : false}
                      clearIcon
                      size='small'
                      multiple
                      sx={{ width: '25vw' }}
                      freeSolo
                      options={this.state.diabeticTreatedList.map((item) => item.label)}
                      value={this.state.selectedDiabeticTreatment}
                      limitTags={3}
                      autoComplete='off'
                      onChange={(event, newValue) => {
                        this.setState({ selectedDiabeticTreatment: newValue })
                      }}
                      readOnly={this.state.diabeticHistoryEdit}
                      renderTags={(value, getTagProps) => (
                        value.map((option, index) => (<Chip variant="outlined" size='small' label={option} {...getTagProps({ index })} />))
                      )}
                      renderInput={(params) => <TextField {...params} label='Select Diabetic Treated' inputProps={{...params.inputProps,emed_tid:'slt_dia_trt'}} />}
                    />
                  </Box>
                </Box>
                <Box width={'25vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={this.state.selectedDiabeticYesOrNo === 'Yes' ? 'lightgray' : Colors.SecondaryText}>{'Name of the Blood Glucouse Meter'}</Typography>
                  <Box emed_tid='dia_bg_mtr'>
                    {this.state.diabeticBgMeterList?.map((item, index) => {
                      return (
                        <Chip
                          disabled={((this.state.selectedDiabeticYesOrNo === 'Yes') || (this.state.diabeticHistoryEdit)) ? true : false}
                          className={`eMed_chip ${item.label === this.state.selectedDiabeticBgMeter ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={item.label === this.state.selectedDiabeticBgMeter ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => {
                            if (item.label === this.state.selectedDiabeticBgMeter) {
                              this.setState({ selectedDiabeticBgMeter: '' })
                            } else {
                              this.setState({ selectedDiabeticBgMeter: item?.label })
                            }
                          }}
                          // color={'primary'}
                          clickable
                        />
                      )
                    })}
                  </Box>
                </Box>
                <Box mt={'0.5vw'} width={'25vw'}>
                  <Box component={'div'} mr={'1vw'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={this.state.selectedDiabeticYesOrNo === 'Yes' ? 'lightgray' : Colors.SecondaryText}>{'Usual Result'}</Typography>
                    <TextField
                      disabled={this.state.selectedDiabeticYesOrNo === 'Yes' ? true : false}
                      InputProps={{
                        endAdornment: <Typography fontSize={'0.9vw'} fontWeight={600} color={this.state.selectedDiabeticYesOrNo === 'Yes' ? 'lightgray' : Colors.SecondaryText}>mg/dl</Typography>,
                        readOnly: this.state.diabeticHistoryEdit
                      }}
                      size='small'
                      sx={{ width: '10vw' }}
                      value={this.state.diabeticUsualResult}
                      onChange={(e) => {
                        let value = e.target.value
                        if (CommonValidation.NumberWithDot(value) || value === '') {
                          this.setState({ diabeticUsualResult: e.target.value })
                        }
                      }}
                      inputProps={{emed_tid:'dia_usl_rst'}}
                    />
                  </Box>
                  <Box component={'div'} mt={'0.5vw'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={this.state.selectedDiabeticYesOrNo === 'Yes' ? 'lightgray' : Colors.SecondaryText}>{'Last A1c Result,Date *'}</Typography>
                    <TextField
                      disabled={this.state.selectedDiabeticYesOrNo === 'Yes' ? true : false}
                      InputProps={{
                        endAdornment: <Typography fontSize={'0.9vw'} fontWeight={600} color={this.state.selectedDiabeticYesOrNo === 'Yes' ? 'lightgray' : Colors.SecondaryText}>%</Typography>,
                        readOnly: this.state.diabeticHistoryEdit,
                        emed_tid:'dia_rst'
                      }}
                      size='small'
                      sx={{ width: '10vw' }}
                      value={this.state.diabeticResult}
                      onChange={(e) => {
                        let value = e.target.value
                        if (CommonValidation.NumberWithDot(value) || value === '') {
                          this.setState({ diabeticResult: e.target.value })
                        }
                      }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        emed_tid='dia_rst_date'
                        disabled={this.state.selectedDiabeticYesOrNo === 'Yes' ? true : false}
                        open={this.state.open}
                        onOpen={() => this.setState({ open: true })}
                        onClose={() => { this.setState({ open: false }) }}
                        inputFormat={"DD-MM-YYYY"}
                        autoComplete="off"
                        InputProps={{
                          disableUnderline: true
                        }}
                        // maxDate={DateTime.now()}
                        value={this.state.diabeticDate}
                        onChange={(newDate) => {
                          this.setState({
                            diabeticDate: newDate
                          })
                        }}
                        readOnly={this.state.diabeticHistoryEdit}
                        renderInput={(params) => <TextField {...params} placeholder='DD-MM-YYYY'
                          sx={{ width: '10vw', ml: '1vw' }} size='small'
                          onClick={() => { this.setState({ open: true }) }}
                        />}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Box>
            </Box>}
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHandler = (e, names) => {
    try {
      let value = e.target.value
      switch (names) {
        case 'counsellingDescrip':
          if (value?.length <= 250) {
            this.setState({ counsellingDescrip: value })
          } else {
            this.errorMessage('Allowed Only 250 Characters')
          }
          break;
        case 'earlyDescrip':
          if (value?.length <= 250) {
            this.setState({ earlyDescrip: value })
          } else {
            this.errorMessage('Allowed Only 250 Characters')
          }
          break;
        case 'breakfastDescrip':
          if (value?.length <= 250) {
            this.setState({ breakfastDescrip: value })
          } else {
            this.errorMessage('Allowed Only 250 Characters')
          }
          break;
        case 'midDescrip':
          if (value?.length <= 250) {
            this.setState({ midDescrip: value })
          } else {
            this.errorMessage('Allowed Only 250 Characters')
          }
          break;
        case 'lunchDescrip':
          if (value?.length <= 250) {
            this.setState({ lunchDescrip: value })
          } else {
            this.errorMessage('Allowed Only 250 Characters')
          }
          break;
        case 'eveningDescrip':
          if (value?.length <= 250) {
            this.setState({ eveningDescrip: value })
          } else {
            this.errorMessage('Allowed Only 250 Characters')
          }
          break
        case 'dinnerDescrip':
          if (value?.length <= 250) {
            this.setState({ dinnerDescrip: value })
          } else {
            this.errorMessage('Allowed Only 250 Characters')
          }
          break;
        case 'lateNightDescrip':
          if (value?.length <= 250) {
            this.setState({ lateNightDescrip: value })
          } else {
            this.errorMessage('Allowed Only 250 Characters')
          }
          break;
        case 'phyExerciseDescrip':
          if (value?.length <= 250) {
            this.setState({ phyExerciseDescrip: value })
          } else {
            this.errorMessage('Allowed Only 250 Characters')
          }
          break;
        case 'phyYesDescrip':
          if (value?.length <= 250) {
            this.setState({ phyYesDescrip: value })
          } else {
            this.errorMessage('Allowed Only 250 Characters')
          }
          break;
        case 'feetDescrip':
          if (value?.length <= 250) {
            this.setState({ feetDescrip: value })
          } else {
            this.errorMessage('Allowed Only 250 Characters')
          }
          break;
        case 'visionDescrip':
          if (value?.length <= 250) {
            this.setState({ visionDescrip: value })
          } else {
            this.errorMessage('Allowed Only 250 Characters')
          }
          break;
        default: break;
      }

    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTextBox = (names, value, label, width, disable) => {
    try {
      return (
        <TextField
          size='small'
          label={label}
          InputProps={{ readOnly: disable }}
          sx={{ width: width, mr: '0.5vw', mt: '0.5vw' }}
          value={value}
          name={names}
          onChange={(e) => this.onChangeHandler(e, names)}
          inputProps={{emed_tid:names}}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderNutritionBox = () => {
    let disable = this.state.nutritionHistoryEdit ? true : false
    try {
      return (
        <Box id='nutrition' component={'div'} border={'1px solid lightgray'} mb={'1vw'} sx={{ backgroundColor: Colors.white }}>
          <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Nutrition'}</Typography>
            {this.state.nutritionHistoryEdit ?
              <Button className='Common_Btn_Min_Width' emed_tid='nut_edit_btn'
                onClick={() => this.setState({ nutritionHistoryEdit: false })}
              >
                <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.5vw'} width={'1.5vw'} />
              </Button> :
              <Box display={'flex'}>
                <Button variant='contained' emed_tid='nut_add_btn'
                  disabled={this.state.nutritionLoading}
                  sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1vw' }}
                  onClick={() => this.postNutritionHistoryData()}
                >Add</Button>
                <Button variant='outlined' emed_tid='nut_clr_btn'
                  sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1vw' }}
                  onClick={() => this.onClearNutritionData()}
                >Clear</Button>
              </Box>
            }
          </Box>
          {this.state.nutritionLoading ? <Box component={'div'} ml={'0.5vw'} mt={'0.5vw'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'20vw'}><CircularProgress color='primary' /></Box> :
            <Box component={'div'} ml={'0.5vw'} mt={'0.5vw'}>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                <Box width={'25vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Have you ever had Nutritional Counselling ?'}</Typography>
                  <Box component={'div'} emed_tid='nut_counsel'>
                    {this.state.yesorNoList?.map((item, index) => {
                      return (
                        <Chip
                          className={`eMed_chip ${item.label === this.state.selectedNutritionYesOrNo ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          disabled={this.state.nutritionHistoryEdit}
                          label={item.label}
                          variant={item.label === this.state.selectedNutritionYesOrNo ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => {
                            if (item.label === this.state.selectedNutritionYesOrNo) {
                              this.setState({ selectedNutritionYesOrNo: '' })
                            } else {
                              this.setState({ selectedNutritionYesOrNo: item?.label })
                            }
                          }}
                          // color={'primary'}
                          clickable
                        />
                      )
                    })}
                    <Box component={'div'} mt={'0.5vw'}>
                      {this.renderTextBox('counsellingDescrip', this.state.counsellingDescrip, 'Description', '25vw', disable)}
                    </Box>
                  </Box>
                </Box>
                <Box width={'25vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Types of Nutrition'}</Typography>
                  <Box component={'div'} emed_tid='nut_type'>
                    {this.state.nutritionList?.map((item, index) => {
                      return (
                        <Chip
                          className={`eMed_chip ${item.value === this.state.selectedTypeNutrition ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          disabled={this.state.nutritionHistoryEdit}
                          label={item.label}
                          variant={item.value === this.state.selectedTypeNutrition ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => {
                            if (item.value === this.state.selectedTypeNutrition) {
                              this.setState({ selectedTypeNutrition: '', selectedTypeNutritionValue: '' })
                            } else {
                              this.setState({ selectedTypeNutrition: item?.value, selectedTypeNutritionValue: item?.value })
                            }
                          }}
                          // color={'primary'}
                          clickable
                        />
                      )
                    })}
                  </Box>
                </Box>
                <Box width={'25vw'}></Box>
              </Box>
              <Box component={'div'} mt={'0.5vw'} mb={'0.5vw'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Please List the typical foods and amounts that you eat and drink on a typical day'}</Typography>
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Early Morning'}</Typography>
                  {this.renderTextBox('earlyDescrip', this.state.earlyDescrip, 'Description', '25vw', disable)}
                </Box>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Breakfast '}</Typography>
                  {this.renderTextBox('breakfastDescrip', this.state.breakfastDescrip, 'Description', '25vw', disable)}
                </Box>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Mid-Morning'}</Typography>
                  {this.renderTextBox('midDescrip', this.state.midDescrip, 'Description', '25vw', disable)}
                </Box>
              </Box>
              <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} flexWrap={'wrap'}>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Lunch'}</Typography>
                  {this.renderTextBox('lunchDescrip', this.state.lunchDescrip, 'Description', '25vw', disable)}
                </Box>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Evening'}</Typography>
                  {this.renderTextBox('eveningDescrip', this.state.eveningDescrip, 'Description', '25vw', disable)}
                </Box>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Dinner'}</Typography>
                  {this.renderTextBox('dinnerDescrip', this.state.dinnerDescrip, 'Description', '25vw', disable)}
                </Box>
              </Box>
              <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} flexWrap={'wrap'} mb={'1vw'}>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Late Night'}</Typography>
                  {this.renderTextBox('lateNightDescrip', this.state.lateNightDescrip, 'Description', '25vw', disable)}
                </Box>
                <Box>
                  <Typography fontSize={'0.9vw'} mr={'0.5vw'} fontWeight={600} color={Colors.SecondaryText}>{'Water Consumption(In Liters)'}</Typography>
                  <Autocomplete
                    clearIcon
                    size='small'
                    freeSolo
                    sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                    options={this.state.waterConsumption.map((item) => item.label)}
                    value={this.state.selectedWaterConsumption}
                    limitTags={3}
                    autoComplete='off'
                    onChange={(event, newValue) => {
                      this.setState({
                        selectedWaterConsumption: newValue
                      })
                    }}
                    readOnly={this.state.nutritionHistoryEdit}
                    onInputChange={(event, newValue) => {
                      this.setState({ selectedWaterConsumption: newValue })
                    }}
                    renderInput={(params) => <TextField {...params} label='Select Water Consumption' inputProps={{...params.inputProps,emed_tid:'water_consp'}} />}
                  />
                </Box>
                <Box width={'25vw'}></Box>
              </Box>
            </Box>
          }
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderPhysicalActivityBox = () => {
    try {
      let disable = this.state.phyHistoryEdit ? true : false
      return (
        <Box id='physical' component={'div'} border={'1px solid lightgray'} mb={'1vw'} sx={{ backgroundColor: Colors.white }}>
          <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Physical Activity'}</Typography>
            {this.state.phyHistoryEdit ?
              <Button className='Common_Btn_Min_Width' emed_tid='phy_edit_btn'
                onClick={() => this.setState({ phyHistoryEdit: false })}
              >
                <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.5vw'} width={'1.5vw'} />
              </Button> :
              <Box display={'flex'}>
                <Button variant='contained' emed_tid='phy_add_btn'
                  disabled={this.state.phyLoading}
                  sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1vw' }}
                  onClick={() => this.postPhysicalHistoryData()}
                >Add</Button>
                <Button variant='outlined' emed_tid='phy_clr_btn'
                  sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1vw' }}
                  onClick={() => this.onClearPhysicalHistoryData()}
                >Clear</Button>
              </Box>
            }
          </Box>
          {this.state.phyLoading ? <Box mt={'0.5vw'} ml={'0.5vw'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'20vw'}><CircularProgress color='primary' /></Box> :
            <Box mt={'0.5vw'} ml={'0.5vw'}>
              <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Do you exercise Daily ?'}</Typography>
                  <Box component={'div'} emed_tid='phy_exe_dly'>
                    {this.state.yesorNoList?.map((item, index) => {
                      return (
                        <Chip
                          disabled={this.state.phyHistoryEdit}
                          className={`eMed_chip ${item.value === this.state.selectedPhyYesOrNo ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={item.label === this.state.selectedPhyYesOrNo ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => {
                            if (item.label === this.state.selectedPhyYesOrNo) {
                              this.setState({ selectedPhyYesOrNo: '' })
                            } else {
                              this.setState({ selectedPhyYesOrNo: item?.label })
                            }
                          }}
                          // color={'primary'}
                          clickable
                        />
                      )
                    })}
                    <Box component={'div'}>
                      {this.renderTextBox('phyYesDescrip', this.state.phyYesDescrip, 'Description', '25vw', disable)}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Please rate your current daily activity level'}</Typography>
                  <Autocomplete
                    clearIcon
                    size='small'
                    freeSolo
                    sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                    options={this.state.activityLevel.map((item) => item.label)}
                    value={this.state.selectedPhyactivity}
                    limitTags={3}
                    autoComplete='off'
                    onChange={(event, newValue) => {
                      this.setState({
                        selectedPhyactivity: newValue
                      })
                    }}
                    onInputChange={(event, newValue) => {
                      this.setState({ selectedPhyactivity: newValue })
                    }}
                    readOnly={this.state.phyHistoryEdit}
                    renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps,emed_tid:'phy_act_lvl'}} />}
                  />
                </Box>
                <Box component={'div'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'How many days a week do you exercise ?'}</Typography>
                  <Autocomplete
                    clearIcon
                    size='small'
                    freeSolo
                    sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                    options={this.state.waterConsumption.map((item) => item.label)}
                    value={this.state.selectedPhyDays}
                    limitTags={3}
                    autoComplete='off'
                    onChange={(event, newValue) => {
                      this.setState({
                        selectedPhyDays: newValue
                      })
                    }}
                    readOnly={this.state.phyHistoryEdit}
                    onInputChange={(event, newValue) => {
                      this.setState({ selectedPhyDays: newValue })
                    }}
                    renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps,emed_tid:'phy_wek_exe'}}/>}
                  />
                </Box>
              </Box>
              <Box component={'div'} mb={'1vw'} mt={'0.5vw'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                <Box width={'25vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'How many minutes per workout session ?'}</Typography>
                  <TextField
                    size='small'
                    sx={{ width: '10vw', mt: '0.5vw' }}
                    value={this.state.phyworkout}
                    InputProps={{
                      endAdornment: <Typography fontSize={'0.9vw'} color={Colors.SecondaryText} fontWeight={600}>Mins</Typography>,
                      readOnly: this.state.phyHistoryEdit
                    }}
                    onChange={(e) => { this.setState({ phyworkout: e.target.value }) }}
                    inputProps={{emed_tid:'phy_wrkout'}}
                  />
                </Box>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Types of exercise'}</Typography>
                  <Autocomplete
                    clearIcon
                    size='small'
                    freeSolo
                    sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                    options={this.state.exerciseType.map((item) => item.label)}
                    value={this.state.selectedPhyTypeExercise}
                    limitTags={3}
                    autoComplete='off'
                    onChange={(event, newValue) => {
                      this.setState({
                        selectedPhyTypeExercise: newValue
                      })
                    }}
                    readOnly={this.state.phyHistoryEdit}
                    onInputChange={(event, newValue) => {
                      this.setState({ selectedPhyTypeExercise: newValue })
                    }}
                    renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps,emed_tid:'phy_exe_type'}} />}
                  />
                  <Box component={'div'}>
                    {this.renderTextBox('phyExerciseDescrip', this.state.phyExerciseDescrip, 'Description', '25vw', disable)}
                  </Box>
                </Box>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Intensity Level'}</Typography>
                  <Autocomplete
                    clearIcon
                    size='small'
                    freeSolo
                    sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                    options={this.state.intensityLevel}
                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.label}
                    value={this.state.selectedPhyIntensity}
                    limitTags={3}
                    autoComplete='off'
                    onChange={(event, newValue) => {
                      this.setState({
                        selectedPhyIntensity: newValue
                      })
                    }}
                    readOnly={this.state.phyHistoryEdit}
                    // onInputChange={(event, newValue) => {
                    //   this.setState({ selectedPhyIntensity: newValue })
                    // }}
                    renderInput={(params) => <TextField {...params}inputProps={{...params.inputProps,emed_tid:'phy_int_lvl'}}/>}
                  />
                </Box>
              </Box>
            </Box>}

        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderBloodGlucouseBox = () => {
    try {
      let disable = this.state.bloodGlucoseEdit ? true : false
      return (
        <Box id='bloodGlucouse' component={'div'} border={'1px solid lightgray'} mb={'1vw'} sx={{ backgroundColor: Colors.white }}>
          <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Blood Glucose Monitoring'}</Typography>
            {this.state.bloodGlucoseEdit ?
              <Button className='Common_Btn_Min_Width' emed_tid='bld_edit_btn'
                onClick={() => this.setState({ bloodGlucoseEdit: false })}
              >
                <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.5vw'} width={'1.5vw'} />
              </Button> :
              <Box display={'flex'}>
                <Button variant='contained' emed_tid='bld_add_btn'
                  disabled={this.state.phyLoading}
                  sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1vw' }}
                  onClick={() => this.postBloodGlouseHistoryData()}
                >Add</Button>
                <Button variant='outlined' emed_tid='bld_clr_btn'
                  sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1vw' }}
                  onClick={() => this.onClearBloodGlucoseData()}
                >Clear</Button>
              </Box>
            }
          </Box>
          {this.state.bloodGluLoading ? <Box mt={'0.5vw'} mb={'1vw'} ml={'0.5vw'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'20vw'}><CircularProgress color='primary' /></Box> :
            <Box mt={'0.5vw'} mb={'1vw'} ml={'0.5vw'}>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'How often do you test your blood glucose per month ?'}</Typography>
                  <Autocomplete
                    clearIcon
                    size='small'
                    freeSolo
                    sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                    options={this.state.bloodGlucouseTest.map((item) => item.label)}
                    value={this.state.selectedBloodMonth}
                    limitTags={3}
                    autoComplete='off'
                    onChange={(event, newValue) => {
                      this.setState({
                        selectedBloodMonth: newValue
                      })
                    }}
                    readOnly={this.state.bloodGlucoseEdit}
                    onInputChange={(event, newValue) => {
                      this.setState({ selectedBloodMonth: newValue })
                    }}
                    renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps,emed_tid:'bld_mth'}} />}
                  />
                </Box>
                <Box width={'25vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Any recent blood glucose above 300 ?'}</Typography>
                  <Box component={'div'} emed_tid='bld_abv_glu'>
                    {this.state.yesorNoList?.map((item, index) => {
                      return (
                        <Chip
                          disabled={this.state.bloodGlucoseEdit}
                          className={`eMed_chip ${item.label === this.state.selectedBloodAbv ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={item.label === this.state.selectedBloodAbv ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => {
                            if (item.label === this.state.selectedBloodAbv) {
                              this.setState({ selectedBloodAbv: '' })
                            } else {
                              this.setState({ selectedBloodAbv: item?.label })
                            }
                          }}
                          // color={'primary'}
                          clickable
                        />
                      )
                    })}
                  </Box>
                </Box>
                <Box width={'25vw'} mr={'0.5vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Any recent blood glucose below 80 ?'}</Typography>
                  <Box component={'div'} mr={'0.5vw'} emed_tid='bld_blw_glu'>
                    {this.state.yesorNoList?.map((item, index) => {
                      return (
                        <Chip
                          disabled={this.state.bloodGlucoseEdit}
                          className={`eMed_chip ${item.label === this.state.selectedBloodBel ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={item.label === this.state.selectedBloodBel ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => {
                            if (item.label === this.state.selectedBloodBel) {
                              this.setState({ selectedBloodBel: '' })
                            } else {
                              this.setState({ selectedBloodBel: item?.label })
                            }
                          }}
                          // color={'primary'}
                          clickable
                        />
                      )
                    })}
                  </Box>
                </Box>
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} mt={'0.5vw'}>
                <Box width={'25vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Have you had any of the following signs of high blood glucose in the past 3 months ?'}</Typography>
                  <Box mt={'0.5vw'}>
                    <Autocomplete
                      clearIcon
                      size='small'
                      multiple
                      sx={{ width: '25vw' }}
                      freeSolo
                      options={this.state.highGlucouseTest.map((item) => item.label)}
                      value={this.state.selectedHighBlood}
                      limitTags={3}
                      autoComplete='off'
                      onChange={(event, newValue) => {
                        this.setState({ selectedHighBlood: newValue })
                      }}
                      renderTags={(value, getTagProps) => (
                        value.map((option, index) => (<Chip variant="outlined" size='small' label={option} {...getTagProps({ index })} />))
                      )}
                      readOnly={this.state.bloodGlucoseEdit}
                      renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps,emed_tid:'bld_high_glu'}}/>}
                    />
                  </Box>
                </Box>
                <Box width={'25vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Have you had any of the following signs of Low blood glucose in the past 3 months ?'}</Typography>
                  <Box mt={'0.5vw'}>
                    <Autocomplete
                      clearIcon
                      size='small'
                      multiple
                      sx={{ width: '25vw' }}
                      freeSolo
                      options={this.state.bloodGlucouseSymptomns.map((item) => item.label)}
                      value={this.state.selectedLowBlood}
                      limitTags={3}
                      autoComplete='off'
                      onChange={(event, newValue) => {
                        this.setState({ selectedLowBlood: newValue })
                      }}
                      renderTags={(value, getTagProps) => (
                        value.map((option, index) => (<Chip variant="outlined" size='small' label={option} {...getTagProps({ index })} />))
                      )}
                      readOnly={this.state.bloodGlucoseEdit}
                      renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps,emed_tid:'bld_low_glu'}}/>}
                    />
                  </Box>
                </Box>
                <Box width={'25vw'} mr={'0.5vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Do you check your feet ?'}</Typography>
                  <Box component={'div'} mr={'0.5vw'} emed_tid='bld_feet'>
                    {this.state.yesorNoList?.map((item, index) => {
                      return (
                        <Chip
                          disabled={this.state.bloodGlucoseEdit}
                          className={`eMed_chip ${item.label === this.state.selectedBloodFeet ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={item.label === this.state.selectedBloodFeet ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => {
                            if (item.label === this.state.selectedBloodFeet) {
                              this.setState({ selectedBloodFeet: '' })
                            } else {
                              this.setState({ selectedBloodFeet: item?.label })
                            }
                          }}
                          // color={'primary'}
                          clickable
                        />
                      )
                    })}
                    <Box component={'div'}>
                      {this.renderTextBox('feetDescrip', this.state.feetDescrip, 'Description', '25vw', disable)}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                <Box width={'25vw'} mr={'0.5vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Do you have any problem in your vision ?'}</Typography>
                  <Box component={'div'} mr={'0.5vw'} emed_tid='bld_vision'>
                    {this.state.yesorNoList?.map((item, index) => {
                      return (
                        <Chip
                          disabled={this.state.bloodGlucoseEdit}
                          className={`eMed_chip ${item.label === this.state.SelectedBloodVision ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={item.label === this.state.SelectedBloodVision ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => {
                            if (item.label === this.state.SelectedBloodVision) {
                              this.setState({ SelectedBloodVision: '' })
                            } else {
                              this.setState({ SelectedBloodVision: item?.label })
                            }
                          }}
                          // color={'primary'}
                          clickable
                        />
                      )
                    })}
                    <Box component={'div'}>
                      {this.renderTextBox('visionDescrip', this.state.visionDescrip, 'Description', '25vw', disable)}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          }

        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderMenstrualHistory = () => {
    try {
      let disable = this.state.MensuralEdit ? true : false
      return (
        <Box id='mensuralHistory' component={'div'} border={'1px solid lightgray'} mb={'1vw'} sx={{ backgroundColor: Colors.white }}>
          <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Menstrual History'}</Typography>
            {this.state.MensuralEdit ?
              <Button className='Common_Btn_Min_Width'
                onClick={() => this.setState({ MensuralEdit: false })}
              >
                <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.5vw'} width={'1.5vw'} />
              </Button> :
              <Box display={'flex'}>
                <Button variant='contained'
                  disabled={this.state.phyLoading}
                  sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1vw' }}
                  onClick={() => this.postMensuralHistoryData()}
                >Add</Button>
                <Button variant='outlined'
                  sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1vw' }}
                  onClick={() => this.onClearMensuralData()}
                >Clear</Button>
              </Box>
            }
          </Box>
          {this.state.mensuralloading ? <Box mt={'0.5vw'} mb={'1vw'} ml={'0.5vw'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'20vw'}><CircularProgress color='primary' /></Box> :
            <Box mt={'0.5vw'} mb={'1vw'} ml={'0.5vw'}>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Age at Menarche'}</Typography>
                  <TextField
                    disabled={this.state.MensuralEdit}
                    size='small'
                    sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                    value={this.state.AgeatMenarche}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => {
                      let value = e.target.value
                      if (CommonValidation.numberOnly(value) || value === '') {
                        this.setState({ AgeatMenarche: value })
                      }
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Menstrual cycle'}</Typography>
                  <TextField
                    disabled={this.state.MensuralEdit}
                    size='small'
                    sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                    value={this.state.MensuralCycle}
                    inputProps={{ maxLength: 150 }}
                    onChange={(e) => {
                        this.setState({ MensuralCycle: e.target.value })
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Child birth/abortion'}</Typography>
                  <TextField
                    disabled={this.state.MensuralEdit}
                    size='small'
                    sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                    value={this.state.ChildBirthAbortion}
                    inputProps={{ maxLength: 150 }}
                    onChange={(e) => {
                        this.setState({ ChildBirthAbortion: e.target.value })
                    }}
                  />
                </Box>
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                <Box>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Age at menopause'}</Typography>
                  <TextField
                    disabled={this.state.MensuralEdit}
                    size='small'
                    sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                    value={this.state.AgeatMenopause}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => {
                      let value = e.target.value
                      if (CommonValidation.numberOnly(value) || value === '') {
                        this.setState({ AgeatMenopause: value })
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>
          }

        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} mt={'0.5vw'} height={'61.5vh'} overflow={'scroll'}>
          {this.renderDiabeticBox()}
          {this.renderNutritionBox()}
          {this.renderPhysicalActivityBox()}
          {this.renderBloodGlucouseBox()}
          {this.renderMenstrualHistory()}
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
