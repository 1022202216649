import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, IconButton, InputLabel, Menu, MenuItem, Modal, Paper, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './OThome.css'
import { Colors } from '../../Styles/Colors';
import CommonDatePicker from '../../Components/Common Components/CommonDatePicker';
import { DateTime } from 'luxon';
import { ImagePaths } from '../../Utility/ImagePaths';
import CommonGridHeader, { AppointmentCard, CommonGridToolBarWithFilterTextCustom, CommonPatientDetails, ReasonPopup } from '../../Components/Common Components/CommonComponents';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import { DataGrid } from '@mui/x-data-grid';
import { localGetItem, setCachevalue } from '../../Utility/Services/CacheProviderService';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import PackageDeatailPop from '../../Components/Common Components/PackageDeatailPop';
import CommonValidation from '../../Components/CommonFunctions/CommonValidation';
import { timeOnlyConvert } from '../../Components/CommonFunctions/CommonFunctions';

class OThome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            page: 0,
            pageSize: 10,
            selectedCard: "",
            AppointmentList: [],
            selectedOption: "",
            SelectedPatientDetails: {},
            AppointmentCount: [],
            comments: "",
            isCancelled: false,
            isRescheduled: false,
            ClinicID: "",
            OTid: "",
            historyPopup: false,
            isFO: false,
            WarningPopUp: false,
            isDoctor: false,
            OTEntryPopup: false,
            DoctorData: [],
            SurgeonOne: "",
            SurgeonTwo: "",
            SurgeonThree: "",
            Technician: "",
            Assistant: "",
            JrAnaesthetist: "",
            anaesthesia_type: "",
            Duration: "",
            durationType: "Hours",
            BloodRequirement: "",
            historyData: [],
            isOT: false,
            isEmergency: false,
            MovetoOTpopup: false,
            SelectedData: null,
            isDisable: false,
            AppointListLoading: false,
            NurseList: [],
            Tec_Ana_List: [],
            anesthesiest : [],
        }
    }

    componentDidMount() {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var clinic_id = myUser?.clinic_id ? myUser?.clinic_id : localGetItem("SelectedDoctorClinicId") ? localGetItem("SelectedDoctorClinicId") : null
        if (clinic_id) {
            this.setState({
                ClinicID: clinic_id,
                OTid: myUser?.ot_id,
                isFO: myUser?.module_name === "front_office",
                isDoctor: myUser?.module_name === "Doctor",
                isOT: myUser?.module_name === "ot_management"
            }, () => {
                this.getOTappointmentList()
                this.getDoctorList()
            })
        }
        if (this.props.history?.location?.state?.fromIP) {
            this.successMessage(this.props.history?.location?.state?.success)
            this.UpdateAppointmentStatus(this.props.history?.location?.state, "Arrived")
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.Request !== prevProps.Request) {
            this.getOTappointmentList()
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    gridToolBar = () => {
        // let TextArray = [
        //   { label: "From Date", value: formatDate(this.state.fromDate) },
        //   { label: "To Date", value: formatDate(this.state.toDate) },
        // ]
        var ExportData = []
        this.state.AppointmentList?.length > 0 && this.state.AppointmentList.map((item) => 
        ExportData.push({"Patient Name":item?.patient_name,"OT Name":item?.ot_name,"Surgery/Speciality":item?.surgery_name,"Surgery Details":item?.power,"Surgeon Name":item?.surgeon_name, "Anasthesiatist": item?.anaesthetist,"Package Details" :item?.package_name ,
            "Status": item?.status === "Approved"? "Approved" :item?.status === "Reschedule"?"Rescheduled" : item?.status ===  "Cancelled" ? "Cancelled" : item?.status === "NoShow" ? "NoShow"   : item?.status === "Arrived"?"Arrived"   : item?.status === "In Surgery" ? "In Surgery" :item?.status === "Completed" ?"Completed" : item?.status === "Booked"? "Booked" : "Booked", })
        )
    
        return (
          <Box>
            <CommonGridToolBarWithFilterTextCustom  data={ExportData} title={"OT Home"} filename={"OT Home"}/>
          </Box>
    
        )
      }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    getOTappointmentList = () => {
        try {
            this.setState({ AppointListLoading: true })
            setCachevalue(this.state.fromDate,'OTFromDate')
            setCachevalue(this.state.toDate,'OTToDate')
            setCachevalue(this.state.selectedCard,'OTStatus')
            RestAPIService.getAll(Serviceurls.GET_OT_APPOINTMENT_LIST + "?from_date=" + this.state.fromDate + "&to_date=" + this.state.toDate + "&status=" + this.state.selectedCard).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            AppointmentList: response.data.data.ot_list,
                            AppointmentCount: response.data.data,
                            AppointListLoading: false
                        }, ()=>{
                            this.state.AppointmentList.forEach((item)=>{
                                item.PatientDetails = `${item?.patient_name ? item?.patient_name :""} ${item?.patients_mobile_number ? item?.patients_mobile_number :""} ${item?.uhid ? item?.uhid :""}`
                            })
                        })
                    }
                    else {
                        this.setState({ AppointListLoading: false })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.setState({ AppointListLoading: false })
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    getDoctorList = () => {
        try {
            RestAPIService.getAll(Serviceurls.HOME_GET_CLINIC_DOCTORS + "?clinic_id=" + this.state.ClinicID).
                then((response) => {
                    if (response.data.status === "success") {
                        let data = response?.data?.data?.length > 0 ? JSON.parse(JSON.stringify(response.data.data)) :[]
                        data?.unshift({ initial: 'N/A', title: '', doctor_id: null })
                        this.setState({
                            DoctorData: response.data.data,
                            anesthesiest: data
                        },()=>{
                            this.getNurseList()
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }
    getNurseList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_STAFF_LIST + "?clinic_id=" + this.state.ClinicID).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            NurseList: response.data.data,
                        },()=>{
                            if(this.state.DoctorData.length > 0){
                                let TecList =this.state.DoctorData.concat(response.data.data)
                                this.setState({
                                    Tec_Ana_List: TecList
                                })
                            }else{
                                this.setState({
                                    Tec_Ana_List: response.data.data
                                })
                            }
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    getHistoryDetails = (data) => {
        try {
            RestAPIService.getAll(Serviceurls.GET_HISTORY_LIST + "?surgery_id=" + data?.surgery_request_id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            historyData: response.data.data,
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, () => {
            this.getOTappointmentList()
        })
    }

    renderPatientType = () => {
        const { t } = this.props
        var states = this.state.AppointmentCount
        return (
            <div className='eMed_OT_Apt_Cards'>
                <AppointmentCard onClick={() => { this.setState({ selectedCard: '' }, () => { this.getOTappointmentList() }) }} count={states.total_patients ? states.total_patients : 0} label={t("All")} color={"#616161"} isSelectedCard={this.state.selectedCard === "" ? true : false} />
                <AppointmentCard onClick={() => { this.setState({ selectedCard: 'upcoming' }, () => { this.getOTappointmentList() }) }} count={states.upcoming ? states.upcoming : 0} label={t("Upcoming Appointments")} color={"#48C9B0"} isSelectedCard={this.state.selectedCard === "upcoming" ? true : false} />
                <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Booked' }, () => { this.getOTappointmentList() }) }} count={states.booked ? states.booked : 0} label={t("Booked")} color={"#D35400"} isSelectedCard={this.state.selectedCard === "Booked" ? true : false} />
                <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Approved' }, () => { this.getOTappointmentList() }) }} count={`${states.approved ? states.approved : 0}`} label={t("Approved")} color={"#1E972A"} isSelectedCard={this.state.selectedCard === "Approved" ? true : false} />
                <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Reschedule' }, () => { this.getOTappointmentList() }) }} count={`${states.reschedule ? states.reschedule : 0}`} label={t("Reschedule")} color={"#0461B7"} isSelectedCard={this.state.selectedCard === "Reschedule" ? true : false} />
                <AppointmentCard onClick={() => { this.setState({ selectedCard: 'NoShow' }, () => { this.getOTappointmentList() }) }} count={`${states.no_show ? states.no_show : 0}`} label={t("NoShow")} color={"#DE7D24"} isSelectedCard={this.state.selectedCard === "NoShow" ? true : false} />
                <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Cancelled' }, () => { this.getOTappointmentList() }) }} count={`${states.cancelled ? states.cancelled : 0}`} label={t("Cancelled")} color={"#B8170D"} isSelectedCard={this.state.selectedCard === "Cancelled" ? true : false} />
                <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Completed' }, () => { this.getOTappointmentList() }) }} count={`${states.completed ? states.completed : 0}`} label={t("Completed")} color={"#48C9B0"} isSelectedCard={this.state.selectedCard === "Completed" ? true : false} />
            </div>
        )
    }

    createOTAppointment = (id, status) => {
        try {
            let data = {
                id: id,
                additional_detail: {
                    "surgeon1": this.state.SurgeonOne,
                    "surgeon2": this.state.SurgeonTwo,
                    "surgeon3": this.state.SurgeonThree,
                    "Technician": this.state.Technician?.doctor_id ? this.state.Technician?.doctor_id : null,
                    "Technician_nurse": this.state.Technician?.staff_id ? this.state.Technician?.staff_id : null,
                    "Assistant_nurse": this.state.Assistant?.staff_id ? this.state.Assistant?.staff_id : null,
                    "Assistant": this.state.Assistant?.doctor_id ? this.state.Assistant?.doctor_id : null,
                    "JrAnaesthetist": this.state.JrAnaesthetist,
                    "anaesthesia_type": this.state.anaesthesia_type,
                    "Duration": this.state.Duration,
                    "durationType": this.state.durationType,
                    "BloodRequirement": this.state.BloodRequirement,
                }
            }
            RestAPIService.updateWithOutId(data, Serviceurls.DOC_OT_REQUEST_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        if (status === "Draft") {
                            this.ClearOTEntry()
                            this.getOTappointmentList()
                        } else {
                            this.ClearOTEntry("Arrived")
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    UpdateAppointmentStatus = (item, status, list) => {
        try {
            this.setState({ isDisable: true })
            var dateOfSurgery = DateTime.fromJSDate(list?.Date).toFormat('yyyy-MM-dd')
            var timeOfSurgery = DateTime.fromJSDate(list?.Time).toFormat('HH:mm:ss')
            if (dateOfSurgery != "Invalid Date" && timeOfSurgery != "Invalid Time") {
                let data = {
                    "id": item.id,
                    "status": status,
                    "reschedule_reason": list?.Comments ? list?.Comments : null,
                    "cancel_reason": this.state.comments,
                    "reschedule_date": dateOfSurgery ? dateOfSurgery : null,
                    "reschedule_time": timeOfSurgery ? timeOfSurgery : null,
                    // "package_name": this.state.packageName,
                    // "eye_type": this.state.EyeType,
                    // "iol_power": this.state.IOLPower
                }
                if (this.state.isEmergency) {
                    data["is_emergency"] = true
                }
                RestAPIService.create(data, Serviceurls.UPDATE_OT_APPOINTMENT_LIST).
                    then((response) => {
                        if (response.data.status === 'success') {
                            this.setState({
                                selectedOption: "",
                                OTEntryPopup: false,
                                isEmergency: false,
                                isDisable: false
                            }, () => { this.getOTappointmentList() })
                            this.successMessage(response.data.message)
                            this.props.history.push({ state: { fromIP: false } })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.errorMessage(error.response.data.message)
                            this.props.history.push({ state: { fromIP: false } })
                        } else {
                            this.errorMessage(error.message)
                            this.props.history.push({ state: { fromIP: false } })
                        }
                        this.setState({ isDisable: false })
                    })
            } else {
                if (dateOfSurgery == "Invalid Date") {
                    this.errorMessage("Invalid Date")
                } else if (timeOfSurgery == "Invalid Time") {
                    this.errorMessage("Invalid Time")
                }
                this.setState({ isDisable: false })
            }
        }
        catch (error) {
            this.errorMessage(error.message)
            this.props.history.push({ state: { fromIP: false } })
            this.setState({ isDisable: false })
        }
    }

    routeToPatientDetails = (params) => {
        let { selectedOption } = this.state;
        let { history } = this.props;
        if (selectedOption === "SurgeryNotes" || selectedOption === "Demographics") {
            setCachevalue(JSON.stringify(params.row), "OTpatientData");
            setCachevalue(this.state.ClinicID, "SelectedDoctorClinic");
            if (selectedOption === "Demographics") {
                history.push({ pathname: "/OTHome/Demographics", state: { AppointmentDetails: params.row } })
            } else {
                history.push({ pathname: "/OTHome/SurgeryNotes", state: { AppointmentDetails: params.row } })
            }
        } else if (selectedOption === "History") {
            this.setState({
                historyPopup: true,
                SelectedItem: params.row,
            }, () => {
                this.getHistoryDetails(params.row)
            })
        } else if (selectedOption === "MovetoIP") {
            if (params?.row?.ip_admission) {
                this.errorMessage("Patient Already In IP")
            } else if (!params?.row?.ip_admission && params?.row?.appointment_status) {
                this.props.history.push({ pathname: "/IPHome/CreateAppointment", state: { OTPatientDetails: params?.row, PatientClicked: true, OTid: this.state.OTid, ClinicId: this.state.ClinicID, fromOTModule: this.state.isFO ? false : true } })
            } else if (!params?.row?.ip_admission && !params?.row?.appointment_status) {
                this.errorMessage("Patient not Arrived")
            }
        }
    }

    clearPopup = () => {
        this.setState({
            OTEntryPopup: false
        })
    }

    renderAction = (data) => {
        if (data?.is_doctor_approved && data?.is_fo_approved && data?.appointment_status !== "Arrived") {
            this.setState({ SelectedPatientDetails: data }, () => {
                if (data?.additional_detail) {
                    this.setState({
                        SurgeonOne: data?.additional_detail?.surgeon1,
                        SurgeonTwo: data?.additional_detail?.surgeon2,
                        SurgeonThree: data?.additional_detail?.surgeon3,
                        Technician: data?.additional_detail?.Technician,
                        Assistant: data?.additional_detail?.Assistant,
                        JrAnaesthetist: data?.additional_detail?.JrAnaesthetist,
                        anaesthesia_type: data?.additional_detail?.anaesthesia_type,
                        Duration: data?.additional_detail?.Duration,
                        durationType: data?.additional_detail?.durationType,
                        BloodRequirement: data?.additional_detail?.BloodRequirement
                    }, () => { this.setState({ OTEntryPopup: true }) })
                } else {
                    this.setState({ OTEntryPopup: true })
                }
            })
        } else if ((!data?.is_doctor_approved && !data?.is_fo_approved) || (data?.is_doctor_approved && !data?.is_fo_approved) || (!data?.is_doctor_approved && data?.is_fo_approved)) {
            this.setState({ WarningPopUp: true, SelectedPatientDetails: data })
        }
    }

    ActionBtn = (data) => {
        const { t } = this.props;
        const multi_option = []
        if (this.state.isFO) {
            multi_option.push({ label: t("History"), value: "History" })
            multi_option.push({ label: t("Move To IP"), value: "MovetoIP" })
        } else if (!this.state.isOT) {
            multi_option.push({ label: t("History"), value: "History" })
        } else {
            multi_option.push({ label: t("Cancel"), value: "Cancel" })
            multi_option.push({ label: t("Reschedule"), value: "Reschedule" })
            multi_option.push({ label: t("SurgeryNotes"), value: "SurgeryNotes" })
            multi_option.push({ label: t("History"), value: "History" })
            multi_option.push({ label: t("Move To IP"), value: "MovetoIP" })
        }

        return (
            <Stack direction="row" justifyContent="center" alignItems="center">
                {/* <Tooltip title={`${t('Approve')}`} placement='top' arrow>
                    <div>
                        <IconButton size="small" disabled={false} onClick={() => { this.setState({ selectedOption: "ApproveOT" }) }}><img className='eMed_IP_AptTbl_Status_Icon' src={ImagePaths.Check.default} alt='approve' /></IconButton>
                    </div>
                </Tooltip>
                <Tooltip title={t('Reschedule')} placement='top' arrow>
                    <div>
                        <IconButton size="small" disabled={(data.row.status === "Cancelled" || data.row.status === "Checked Out") ? true : false} onClick={() => { this.setState({ isCheckout: true, SelectedPatientDetails: data.row }) }}>
                            <img className='eMed_IP_AptTbl_Status_Icon' src={ImagePaths.CallenderIcon.default} alt='Checkout' />
                        </IconButton>
                    </div>
                </Tooltip> */}
                <ButtonGroup variant="outlined" size='small' aria-label="outlined button group" sx={{ backgroundColor: "rgb(228, 242, 240)" }}>
                    {this.state.isFO || this.state.isDoctor ? <Tooltip title={(this.state.isFO && !data.row.is_fo_approved) ? t("Approve") : (this.state.isDoctor && !data.row.is_doctor_approved) ? t("Approve") : t("Approved")} placement='top' arrow><Button disabled={data.row.appointment_status != null || data.row.status === "Cancelled" || data.row.status === "Reschedule"} onClick={() => { if ((this.state.isFO && !data.row.is_fo_approved) || (this.state.isDoctor && !data.row.is_doctor_approved)) { this.UpdateAppointmentStatus(data.row, "Approved") } }}>
                        <img className='eMed_IP_AptTbl_Status_Icon' src={((this.state.isFO && data.row.is_fo_approved) || (this.state.isDoctor && data.row.is_doctor_approved) || (data.row.appointment_status != null)) ? ImagePaths.ApproveActive.default : ImagePaths.ApproveDisable.default} alt='approve' />
                    </Button></Tooltip> : null}
                    <Tooltip title={t("Arrived")} placement='top' arrow><Button disabled={data.row.appointment_status != null || data.row.status === "Cancelled" || data.row.status === "Reschedule" || !this.state.isOT} onClick={() => { this.renderAction(data.row) }}>
                        <img className='eMed_IP_AptTbl_Status_Icon' src={(data.row.status === "Cancelled" || data.row.status === "Reschedule") ? ImagePaths.SurgAprrovedDisable.default : data.row.appointment_status != null ? ImagePaths.SurgAprroved.default : ImagePaths.SurgAprrovedDefault.default} alt='approve' />
                    </Button></Tooltip>
                    <Tooltip title={t("In Surgery")} placement='top' arrow><Button disabled={data.row.appointment_status != "Arrived" || data.row.status === "Cancelled" || data.row.status === "Reschedule" || !this.state.isOT} onClick={() => { if (data.row.ip_admission == null) { this.setState({ MovetoOTpopup: true, SelectedData: data.row }) } else { this.UpdateAppointmentStatus(data.row, "In Surgery") } }}>
                        <img className='eMed_IP_AptTbl_Status_Icon' src={(data.row.status === "Cancelled" || data.row.status === "Reschedule") ? ImagePaths.WithDocDisable.default : data.row.appointment_status === "In Surgery" || data.row.appointment_status === "Completed" ? ImagePaths.WithDoc.default : ImagePaths.WithDocDefault.default} alt='approve' />
                    </Button></Tooltip>
                    <Tooltip title={t("Completed")} placement='top' arrow><Button disabled={data.row.appointment_status != "In Surgery" || data.row.status === "Cancelled" || data.row.status === "Reschedule" || !this.state.isOT} onClick={() => { this.UpdateAppointmentStatus(data.row, "Completed") }}>
                        <img className='eMed_IP_AptTbl_Status_Icon' src={(data.row.status === "Cancelled" || data.row.status === "Reschedule") ? ImagePaths.TickDisable.default : data.row.appointment_status === "Completed" ? ImagePaths.Tick.default : ImagePaths.TickDefault.default} alt='approve' />
                    </Button></Tooltip>
                </ButtonGroup>
                <div>
                    <Tooltip title={t("More")} placement='top' arrow>
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                this.setState({
                                    anchorEl: e.currentTarget,
                                    selectedRow: data.id,
                                    isSelected: true
                                })
                            }}
                            disabled={false}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Tooltip>
                    {(this.state.isSelected && this.state.selectedRow == data.id) ?
                        <Menu
                            anchorEl={this.state.anchorEl}
                            open={Boolean(this.state.anchorEl)}
                            onClose={() => {
                                this.setState({ anchorEl: null, })
                            }}
                        >
                            {multi_option.map((option, index) => (
                                <MenuItem key={index} disabled={((option.value === "Cancel" || option.value === "Reschedule") && (data.row.appointment_status != null || data.row.status === "Cancelled" || data.row.status === "Reschedule")) || (option.value === "SurgeryNotes" && data.row.ip_admission == null) || (option.value === "MovetoIP" && data.row.ip_admission != null)}
                                    onClick={() => { this.setState({ selectedOption: option.value, anchorEl: null, SelectedPatientDetails: data.row }, () => this.routeToPatientDetails(data)) }}>
                                    {t(option.label)}
                                </MenuItem>
                            ))}
                        </Menu> : null}
                </div>
            </Stack>
        )
    }

    returnStatus(status,data) {
        const { t } = this.props
        switch (status) {
            case "Approved":
                return <Tooltip placement='top' title={t("Approved")} arrow>
                    <div className='eMed_OT_APtTbl_PayPending'>
                        <img className='eMed_OT_AptTbl_PayIcon' src={ImagePaths.CheckGreenIcon.default}></img>
                        <Typography id='eMed_OT_AptTbl_PayText' sx={{ color: "#1E972A" }}>{t("Approved")}</Typography>
                    </div></Tooltip>

            case "Reschedule":
                return <Tooltip placement='top' title={data?.reschedule_reason} arrow>
                    <div className='eMed_OT_APtTbl_PayPending'>
                        <img className='eMed_OT_AptTbl_PayIcon' src={ImagePaths.RescheduleIcon.default}></img>
                        <Typography id='eMed_OT_AptTbl_PayText' sx={{ color: "#0461B7" }}>{t("Rescheduled")}</Typography>
                    </div></Tooltip>

            case "Cancelled":
                return <Tooltip placement='top' title={data?.cancel_reason} arrow>
                    <div className='eMed_OT_APtTbl_PayPending'>
                        <img className='eMed_OT_AptTbl_PayIcon' src={ImagePaths.CancelIcon.default}></img>
                        <Typography id='eMed_OT_AptTbl_PayText' sx={{ color: "#B8170D" }}>{t("Cancelled")}</Typography>
                    </div></Tooltip>

            case "No Show":
                return <Tooltip placement='top' title={t("NoShow")} arrow>
                    <div className='eMed_OT_APtTbl_PayPending'>
                        <img className='eMed_OT_AptTbl_PayIcon' src={ImagePaths.NoshowIcon.default}></img>
                        <Typography id='eMed_OT_AptTbl_PayText' sx={{ color: "#DE7D24" }}>{t("NoShow")}</Typography>
                    </div></Tooltip>
            case "Arrived":
                return <Tooltip placement='top' title={t("Arrived")} arrow>
                    <div className='eMed_OT_APtTbl_PayPending'>
                        <Typography id='eMed_OT_AptTbl_PayText' sx={{ color: "#DE7D24" }}>{t("Arrived")}</Typography>
                    </div></Tooltip>
            case "In Surgery":
                return <Tooltip placement='top' title={t("In Surgery")} arrow>
                    <div className='eMed_OT_APtTbl_PayPending'>
                        <Typography id='eMed_OT_AptTbl_PayText' sx={{ color: "#DE7D24" }}>{t("In Surgery")}</Typography>
                    </div></Tooltip>
            case "Completed":
                return <Tooltip placement='top' title={t("Completed")} arrow>
                    <div className='eMed_OT_APtTbl_PayPending'>
                        <img className='eMed_OT_AptTbl_PayIcon' src={ImagePaths.CheckGreenIcon.default}></img>
                        <Typography id='eMed_OT_AptTbl_PayText' sx={{ color: "#1E972A" }}>{t("Completed")}</Typography>
                    </div></Tooltip>

            default:
                return <Tooltip placement='top' title={t("Booked")} arrow>
                    <div className='eMed_OT_APtTbl_PayPending'>
                        <img className='eMed_OT_AptTbl_PayIcon' src={ImagePaths.BookedPatient.default}></img>
                        <Typography id='eMed_OT_AptTbl_PayText'>{t("Booked")}</Typography>
                    </div></Tooltip>

        }
    }

    closePopup = () => {
        this.setState({
            selectedOption: ""
        })
    }

    ErrorToast(data) {
        this.errorMessage(data)
    }
    SuccessToast(data) {
        this.successMessage(data)
    }

    addCancelComments = (value) => {
        this.setState({
            comments: value,
            isCancelled: true,
            selectedOption: ""
        }, () => { this.UpdateAppointmentStatus(this.state.SelectedPatientDetails, "Cancelled") })
    }

    RescheduleAppointment = (date, time, comments) => {
        let RescheduleDetails = {
            Date: date,
            Time: time,
            Comments: comments
        }
        this.setState({
            isRescheduled: true
        }, () => { this.UpdateAppointmentStatus(this.state.SelectedPatientDetails, "Reschedule", RescheduleDetails) })
    }

    ClearOTEntry = (status) => {
        this.setState({
            OTEntryPopup: false,
            SurgeonOne: "",
            SurgeonTwo: "",
            SurgeonThree: "",
            Technician: "",
            Assistant: "",
            JrAnaesthetist: "",
            anaesthesia_type: "",
            Duration: "",
            durationType: "Hours",
            BloodRequirement: ""
        }, () => {
            if (status === "Arrived") {
                this.UpdateAppointmentStatus(this.state?.SelectedPatientDetails, "Arrived")
            }
        })
    }

    renderAllocationValues = (Heading, value) => {
        return (
            <div style={{ marginLeft: "0.85vw", marginTop: "0.75vw" }}>
                <div style={{ color: Colors.SecondaryText, fontSize: "0.85vw" }}>{Heading}</div>
                <div style={{ fontWeight: 600, marginTop: "0.5vw" }}>
                    {value?.length > 15 ?
                        <Tooltip placement='top' title={value}>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{value?.slice(0, 15) + '...'}</Typography>
                        </Tooltip> :
                        <Typography fontSize={'0.9vw'} fontWeight={600}>{value ? value : "-"}</Typography>
                    }
                </div>
            </div>
        )
    }

    renderTextInput = (Title, value, key, width) => {
        let states = this.state
        const renderMenuItems = () => {
            if (key === "Technician" || key === "Assistant") {
              if (states?.Tec_Ana_List?.length > 0) {
                return states?.Tec_Ana_List.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item?.staff_id 
                      ? item.nurse || "" 
                      : `${item.title || ""} ${item.initial || ""} ${item.first_name || ""}`}
                  </MenuItem>
                ));
              } else if (states?.DoctorData?.length > 0) {
                return states?.DoctorData.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {`${item.title || ""} ${item.initial || ""} ${item.first_name || ""}`}
                  </MenuItem>
                ));
              }
            }else{
                if(Title === 'Jr Anaesthetist'){
                    return states?.anesthesiest?.map((item,index) => (
                        <MenuItem key={index} value={item?.doctor_id ? item?.doctor_id : item?.initial}>
                      {`${item.title || ""} ${item.initial || ""} ${item.first_name || ""}`}
                    </MenuItem>
                    ))
                }
                else{
                return states?.DoctorData.map((item, index) => (
                    <MenuItem key={index} value={item?.doctor_id}>
                      {`${item.title || ""} ${item.initial || ""} ${item.first_name || ""}`}
                    </MenuItem>
                  ));
            }
           }
            return null;
          };
        return (
            <FormControl size='small' style={{ width: width ? width : '28%', margin: "0.65vw" }}>
                <InputLabel>{Title}</InputLabel>
                <Select
                    inputProps={{ emed_tid: `cb_${Title}` }}
                    value={value ? value : ""}
                    label={Title}
                    onChange={(event) => {
                        states[key] = event.target.value;
                        this.setState({
                            states
                        })
                    }}
                    MenuProps={{
                        style: { maxHeight: 350 },
                    }}
                >
                    <MenuItem value={""}>{"Select"}</MenuItem>
                    {renderMenuItems()}
                </Select>
            </FormControl>
        )
    }

    SurgeonDetailPopup = () => {
        const { t } = this.props;
        let states = this.state;
        return (
            <Modal open={this.state.OTEntryPopup}>
                <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "48vw", height: "90%", overflow: "scroll" }}>
                    <div id="emedhub_popup_header" style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                        <Typography sx={{ color: "#616161", fontWeight: 600 }}>{t("Operation Theatre Entry")}</Typography>
                        <IconButton onClick={() => { this.ClearOTEntry() }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                    </div>
                    <div id="emedhub_popup_container">
                        <div>
                            <div>Patient Details</div>
                            <CommonPatientDetails data={states?.SelectedPatientDetails} showAdmission={false} isFromOTList={true} />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            {this.renderAllocationValues("Surgery", states?.SelectedPatientDetails?.surgery_name)}
                            {this.renderAllocationValues("Operation Theatre", states?.SelectedPatientDetails?.surgery_name)}
                            {this.renderAllocationValues("Surgeon Doctor", states?.SelectedPatientDetails?.surgeon_name)}
                            {this.renderAllocationValues("Anaesthetist", states?.SelectedPatientDetails?.anaesthetist)}
                        </div>
                        <hr />
                        <div style={{ marginTop: "0.5vw", marginBottom: "0.5vw" }}>
                            <div>Add OT Team</div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {this.renderTextInput("Assistant Surgeon 1", this.state.SurgeonOne, "SurgeonOne")}
                                {this.renderTextInput("Assistant Surgeon 2", this.state.SurgeonTwo, "SurgeonTwo")}
                                {this.renderTextInput("Assistant Surgeon 3", this.state.SurgeonThree, "SurgeonThree")}
                                {this.renderTextInput("OT Technician", this.state.Technician, "Technician")}
                                {this.renderTextInput("OT Assistant", this.state.Assistant, "Assistant")}
                            </div>
                        </div>
                        <hr />
                        <div style={{ marginTop: "0.5vw", marginBottom: "0.5vw" }}>
                            <div>Anaesthetist Details</div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {this.renderTextInput("Jr Anaesthetist", this.state.JrAnaesthetist, "JrAnaesthetist")}
                                <TextField
                                    inputProps={{ maxLength: 30 ,emed_tid : "Anaesthesia_Type_TestID"}}
                                    sx={{ width: "28%", marginTop: "0.65vw" }}
                                    size='small'
                                    label={"Anaesthesia Type"}
                                    value={this.state.anaesthesia_type}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        this.setState({
                                            anaesthesia_type: e.target.value
                                        })
                                    }}
                                />
                                <div style={{ display: "flex", flexDirection: "row", width: "35%", marginTop: "0.65vw", marginLeft: "1vw" }}>
                                    <TextField
                                        size='small'
                                        sx={{ width: '12vw' }}
                                        value={this.state.Duration}
                                        inputProps={{ maxLength: 30 ,emed_tid : "Approx_Duration_TestID"}}
                                        label='Approx Duration'
                                        onChange={(e) => {
                                            let isValid = CommonValidation.numberOnly(e.target.value)
                                            if (isValid || e.target.value == "") {
                                                this.setState({ Duration: e.target.value })
                                            }
                                        }}
                                    />
                                    <FormControl sx={{ width: "8vw", marginLeft: "-0.1vw" }}>
                                        <Select
                                            value={this.state.durationType}
                                            onChange={(event) => { this.setState({ durationType: event.target.value }) }}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' , emed_tid : "durationType_hrs_TestID" }}
                                            size='small'
                                        >
                                            <MenuItem value="Hours">Hours</MenuItem>
                                            <MenuItem value="Minutes">Minutes</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div style={{ marginTop: "0.5vw", marginBottom: "0.5vw" }}>
                            <div>Other Details</div>
                            <div style={{ marginLeft: "1vw" }}>
                                <TextField
                                    inputProps={{ maxLength: 30 ,}}
                                    sx={{ width: "40%", marginTop: "0.65vw" ,emed_tid :"Blood_Requirements_made_By_TestID"}}
                                    size='small'
                                    label={"Blood Requirements made By"}
                                    value={this.state.BloodRequirement}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        this.setState({
                                            BloodRequirement: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <Stack id="emedhub_popup_footer" direction="row" justifyContent={"space-between"} alignItems="center">
                        <div>
                            <Button variant="outlined" disabled={this.state.isDisable} size="small" sx={{ textTransform: "capitalize" }} onClick={() => { this.createOTAppointment(states?.SelectedPatientDetails?.id, "Draft") }}>{t("Save As Draft")}</Button>
                        </div>
                        <div>
                            <Button size="small" disabled={this.state.isDisable} sx={{ marginRight: "1vw", textTransform: "capitalize" }} variant={"contained"} onClick={() => { this.createOTAppointment(states?.SelectedPatientDetails?.id, "Arrived") }}>{t("Confirm and Start")}</Button>
                            <Button variant="outlined" size="small" sx={{ textTransform: "capitalize" }} onClick={() => { this.ClearOTEntry() }}>{t("Cancel")}</Button>
                        </div>
                    </Stack>
                </Paper>
            </Modal>
        )
    }

    WarningWindow = () => {
        let additionalDetail = this.state?.SelectedPatientDetails?.additional_detail
        return (
            <Dialog
                open={this.state.WarningPopUp}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent dividers sx={{ width: '30vw', height: '6vw' }}>
                    <DialogContentText id="alert-dialog-description">
                        This Appointment has not been approved by all. Are you sure you want to change the status?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="outlined" onClick={() => {
                        this.setState({ WarningPopUp: false })
                    }}>No</Button>
                    <Button size="small" variant="contained" onClick={() => {
                        if (additionalDetail) {
                            this.setState({
                                SurgeonOne: additionalDetail?.surgeon1,
                                SurgeonTwo: additionalDetail?.surgeon2,
                                SurgeonThree: additionalDetail?.surgeon3,
                                Technician: additionalDetail?.Technician,
                                Assistant: additionalDetail?.Assistant,
                                JrAnaesthetist: additionalDetail?.JrAnaesthetist,
                                anaesthesia_type: additionalDetail?.anaesthesia_type,
                                Duration: additionalDetail?.Duration,
                                durationType: additionalDetail?.durationType,
                                BloodRequirement: additionalDetail?.BloodRequirement
                            }, () => { this.setState({ OTEntryPopup: true, WarningPopUp: false, isEmergency: true }) })
                        } else {
                            this.setState({ OTEntryPopup: true, WarningPopUp: false, isEmergency: true })
                        }
                    }}>Yes</Button>
                </DialogActions>
            </Dialog>
        )
    }

    MovetoOTWindow = () => {
        return (
            <Dialog
                open={this.state.MovetoOTpopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent dividers sx={{ width: '30vw', height: '6vw' }}>
                    <DialogContentText id="alert-dialog-description">
                        This Appointment has no IP Admission. Do you want to create IP ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="outlined" onClick={() => {
                        this.setState({ MovetoOTpopup: false })
                    }}>No</Button>
                    <Button size="small" variant="contained" onClick={() => {
                        this.setState({ MovetoOTpopup: false }, () => {
                            this.props.history.push({ pathname: "/IPHome/CreateAppointment", state: { OTPatientDetails: this.state.SelectedData, PatientClicked: true, OTid: this.state.OTid, ClinicId: this.state.ClinicID, fromOTModule: this.state.isFO ? false : true } })
                        })
                    }}>Create IP</Button>
                </DialogActions>
            </Dialog>
        )
    }

    HistoryPopup = () => {
        return (
            <div>
                <Modal open={this.state.historyPopup}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "70vw", height: "70vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.75vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ borderBottom: "1px solid gray" }}>
                            <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>History - {`${this.state.SelectedItem?.patient_name} - Surgery Date : ${this.state.SelectedItem?.surgery_date ? `${(this.state.SelectedItem?.surgery_date.split("T")[0]).split("-").reverse().join("-")} | ${timeOnlyConvert(this.state.SelectedItem?.surgery_date.split("T")[1])}` : "-"}`}</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ historyPopup: false })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box sx={{ maxHeight: '60vh', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={'20%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Updated Date & Time</TableCell>
                                        <TableCell width={'20%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Rescheduled Date & Time</TableCell>
                                        <TableCell width={'15%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>From Status</TableCell>
                                        <TableCell width={'15%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>To Status</TableCell>
                                        <TableCell width={'15%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Comments</TableCell>
                                        <TableCell width={'15%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Updated By</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.historyData?.length > 0 ? this.state.historyData?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.surgery_date ? `${(item?.surgery_date.split("T")[0]).split("-").reverse().join("-")} | ${timeOnlyConvert(item?.surgery_date.split("T")[1])}` : "-"}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.reschedule_date ? `${(item?.reschedule_date.split("T")[0]).split("-").reverse().join("-")} | ${timeOnlyConvert(item?.reschedule_date.split("T")[1])}` : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.from_status ? item?.from_status : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.to_status ? item?.to_status : '-'}</TableCell>
                                            <TableCell>
                                                {item?.comment ? item?.comment?.length > 20 ?
                                                    <Tooltip placement='top' title={item?.comment}>
                                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{(item?.comment)?.slice(1, -1)?.slice(0, 20) + '...'}</Typography>
                                                    </Tooltip> :
                                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.comment}</Typography> : '-'}
                                            </TableCell>
                                            <TableCell>
                                                {item?.updated_by ? item?.updated_by?.length > 20 ?
                                                    <Tooltip placement='top' title={item?.updated_by}>
                                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{(item?.updated_by)?.slice(1, -1)?.slice(0, 20) + '...'}</Typography>
                                                    </Tooltip> :
                                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.updated_by}</Typography> : '-'}
                                            </TableCell>
                                        </TableRow>
                                    )) : null}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    renderAptTable = () => {
        const { t } = this.props;
        let states = this.state;
        this.state.AppointmentList?.forEach((element, index) => element.sno = index + 1)
        const columns = [
            { field: 'PatientDetails', headerName: t('Patient Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.18, renderCell: ({ row }) => (<CommonPatientDetails data={row} showAdmission={false} isFromOTList={true} />), density: 'compact' },
            {
                field: 'ot_name', headerName: t('OTName'), headerClassName: 'eMed_Apt_TblHead', flex: 0.08, density: 'compact',
                renderCell: ({ row }) => (<div>{row?.ot_name?.length > 15 ? <Tooltip placement="top" title={row?.ot_name} arrow><div>{row?.ot_name.slice(0, 13) + "..."}</div></Tooltip> : row.ot_name ? row.ot_name : "-"}</div>)
            },
            {
                field: 'surgery_name', headerName: t('Surgery/Speciality'), headerClassName: 'eMed_Apt_TblHead', flex: 0.118, density: 'compact',
                renderCell: ({ row }) => (
                    <div className='rendercell'>
                        <div style={{ fontSize: "1.1vw" }}>{row?.surgery_name?.length > 20 ? <Tooltip placement="top" title={row?.surgery_name} arrow><div>{row?.surgery_name.slice(0, 18) + "..."}</div></Tooltip> : row.surgery_name ? row.surgery_name : "-"}</div>
                        <div style={{ color: "black", fontSize: "0.9vw", display: "flex", flexDirection: "row" }}>{row?.doctor_speciality?.length > 20 ? <Tooltip placement="top" title={row?.doctor_speciality} arrow><div style={{ color: "black", fontWeight: "normal", fontSize: "0.9vw", paddingLeft: "0.05vw" }}>{row?.doctor_speciality.slice(0, 19) + "..."}</div></Tooltip> : row.doctor_speciality ? row.doctor_speciality : "-"}</div>
                    </div>
                )
            },
            {
                field: 'iol_power', headerName: t('Surgery Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.08, density: 'compact', headerAlign: 'center', align: "center",
                renderCell: ({ row }) => (<div>{row?.power?.length > 20 ? <Tooltip placement="top" title={row?.iol_power} arrow><div>{row?.iol_power.slice(0, 18) + "..."}</div></Tooltip> : row.iol_power ? row.iol_power : "-"}</div>)
            },
            {
                field: 'surgeon_name', headerName: t('SurgeonName'), headerClassName: 'eMed_Apt_TblHead', flex: 0.115, density: 'compact',
                renderCell: ({ row }) => (<div>{row?.surgeon_name?.length > 15 ? <Tooltip placement="top" title={row?.surgeon_name} arrow><div>{row?.surgeon_name.slice(0, 15) + ".."}</div></Tooltip> : row.surgeon_name ? row.surgeon_name : "-"}</div>)
            },
            {
                field: 'anaesthetist', headerName: t('Anaesthesiatist'), headerClassName: 'eMed_Apt_TblHead', flex: 0.115, density: 'compact',
                renderCell: ({ row }) => (<div>{row?.anaesthetist?.length > 15 ? <Tooltip placement="top" title={row?.anaesthetist} arrow><div>{row?.anaesthetist.slice(0, 15) + ".."}</div></Tooltip> : row.anaesthetist ? row.anaesthetist : "-"}</div>)
            },
            {
                field: 'package_name', headerName: t('PackageDetails'), headerClassName: 'eMed_Apt_TblHead', flex: 0.16, density: 'compact',
                renderCell: ({ row }) => (<div>{row?.package_name?.length > 20 ? <Tooltip placement="top" title={row?.package_name} arrow><div>{row?.package_name.slice(0, 20) + ".."}</div></Tooltip> : row.package_name ? row.package_name : "-"}</div>)
            },
            {
                field: 'status', headerName: t('Status'), headerClassName: 'eMed_Apt_TblHead', flex: 0.095, density: 'compact', headerAlign: 'center', align: "center",
                renderCell: ({ row }) => (<div> {this.returnStatus(row?.status,row)} </div>)
            },
            {
                field: 'action', headerName: t('Action'), headerClassName: 'eMed_Apt_TblHead', flex: 0.145, headerAlign: 'center', align: "center", hideable: false, sortable: false, density: 'compact',
                renderCell: (row) => (<div>{this.ActionBtn(row)}</div>)
            }
        ];
        return (
            <div>
                <Box className='eMed_IPAptTbl_Con' sx={{height: this.state?.isFO ? "60vh" : "68vh"}}>
                    <DataGrid
                        rows={states.AppointmentList}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        pageSize={this.state.pageSize}
                        page={this.state.page}
                        rowsPerPageOptions={[10, 20, 30]}
                        components={{
                            Toolbar:  this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        density={'compact'}
                        rowHeight={100}
                        onCellClick={(params) => {
                            if (params.field !== "action" && params.row.ip_admission != null && this.state.isOT) {
                                this.setState({
                                    selectedOption: "Demographics"
                                }, () => { this.routeToPatientDetails(params) })
                            }
                        }}
                        pagination
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => { this.setState({ pageSize: newPageSize }) }}
                        disableSelectionOnClick
                        loading={this.state.AppointListLoading}
                    />
                </Box>
            </div>
        )
    }


    render() {
        const { t } = this.props;
        return (
            <Box sx={{ backgroundColor: Colors.ComponentBgColor }} className='eMed_OT_Main_Container'>
                <Box className="eMed_OT_page_header">
                    <div className='eMed_Price_Card_Icon'>
                        <p>{t('OTAppointments')}</p>
                    </div>
                    <Box className='eMed_IP_AptDateFlt'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            Future={true}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                </Box>
                <Box>
                    {this.renderPatientType()}
                    {this.renderAptTable()}
                </Box>
                {this.SurgeonDetailPopup()}
                {this.HistoryPopup()}
                {this.state.selectedOption === "Reschedule" ? <PackageDeatailPop
                    PatientDetails={this.state.SelectedPatientDetails}
                    ClinicID={this.state.ClinicID}
                    OTHome={true}
                    ErrorMsg={this.ErrorToast.bind(this)}
                    SuccessMsg={this.SuccessToast.bind(this)}
                    RescheduleAppointment={this.RescheduleAppointment.bind(this)}
                    handleClose={this.closePopup.bind(this)} /> : null}
                {this.state.selectedOption === "Cancel" ? <ReasonPopup
                    title={"Are you sure? Do you want to cancel the Bill"}
                    AlertPopupClose={this.closePopup.bind(this)}
                    label={"Enter the Cancel Reason"}
                    btntext={'Cancel'}
                    btnvarient={'outlined'}
                    btncolor={'error'}
                    btntext1={'Confirm'}
                    btnvarient1={'contained'}
                    sendCmt={this.addCancelComments.bind(this)} /> : null}
                {this.WarningWindow()}
                {this.MovetoOTWindow()}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(OThome)
