import React, { Component } from 'react';
import './IPPatientsHome.css';
import {
    TextField, IconButton, Tooltip, Box, Typography, MenuItem, Stack, FormControl,
    InputLabel, Select, InputAdornment, Autocomplete, Modal, Divider, FormControlLabel, Checkbox
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { ImagePaths } from '../../.././Utility/ImagePaths';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { withTranslation } from 'react-i18next';
import { Colors } from '../../.././Styles/Colors';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import CommonGridHeader, { CommonPopUp, AppointmentCard, CommonPatientDetails, ReasonPopup } from "../../../Components/Common Components/CommonComponents";
import { DataGrid } from '@mui/x-data-grid';
import RestAPIService from '../../.././Utility/Services/RestAPIService';
import { Serviceurls } from '../../.././Utility/API/Serviceurls';
import ToastMsg from '../../.././Components/ToastMsg/ToastMsg';
import { DateTime } from 'luxon';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';

class RoomAvailability extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reservation_data: [],
            selectedCard: "",
            isReserve: false,
            PatientBlockList: [],
            PatientFloorList: [],
            PatientWardList: [],
            AttenderBlockList: [],
            AttenderFloorList: [],
            AttenderWardList: [],
            WardList: [],
            doctorList: [],
            filteredDocList: [],
            specList: [],
            ipPatientList: [],
            mobile_no: "",
            uhid: "",
            patient_name: "",
            patient_id: "",
            ip_No: "",
            ip_admission_id: "",
            admission_date: new Date(),
            reserv_doctor: null,
            reserv_spec: null,
            complaints: "",
            preference: "",
            reserv_block: "",
            reserv_floor: "",
            reserv_ward: "",
            attender_reserv_block: "",
            attender_reserv_floor: "",
            attender_reserv_ward: "",
            isCancel: false,
            SelectedPatientDetails: {},
            ComplaintsList: localGetItem("ipComplaintsDataList") === null ? [] : JSON.parse(localGetItem("ipComplaintsDataList")),
            all_total: 0,
            ip_total: 0,
            cancel_total: 0,
            page: 0,
            pageSize: 10,
            DateTimerOpen: false,
            isErrorMsg: false,
            isErrorMsgText: "",
            successMsg: false,
            successMsgText: "",
            isAlsoforAttender: false
        }
    }

    componentDidMount() {
        this._getDoctorDetails();
        this._getSpecInfo();
        this._getWardInfo();
        this.getReserveDetails();
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    CheckExistingPatients() {
        try {
            RestAPIService.getAll(Serviceurls.FO_PATIENT_SEARCH + `?search_key=${this.state.mobile_no}&address_key=&father_husband_key=`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            ipPatientList: response.data.data
                        })
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }
    _getDoctorDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            doctorList: response.data.data.filter((item) => (item.specialization_active === true)),
                        });
                    }
                })
                .catch(error => { });
        } catch (e) { }
    }

    _getSpecInfo = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            specList: response.data.data.filter((item) => (item.is_active === true)),
                        });
                    }
                })
                .catch(error => { });
        } catch (e) { }
    }

    RemoveDuplicates(Array, key) {
        const FilteredArray = Array.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item[key] === current[key])) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);
        return FilteredArray
    }

    _getWardInfo = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_RESERVATION_BEDSLT)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            PatientWardList: this.RemoveDuplicates(response.data.data, "ward_id"),
                            WardList: response.data.data
                        }, () => {
                            if (this.state.PatientWardList?.length == 1) {
                                this.setState({
                                    reserv_ward: this.state.PatientWardList[0].ward_id,
                                    PatientBlockList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWardList[0].ward_id)), "block_id")
                                }, () => {
                                    if (this.state.PatientBlockList?.length == 1) {
                                        this.setState({
                                            reserv_block: this.state.PatientBlockList[0].block_id,
                                            PatientFloorList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlockList[0].block_id)), "floor")
                                        }, () => {
                                            if (this.state.PatientFloorList?.length == 1) {
                                                this.setState({
                                                    reserv_floor: this.state.PatientFloorList[0].floor
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        });
                    }
                })
                .catch(error => {
                });
        } catch (e) {
        }
    }

    getReserveDetails = () => {
        this.setState({
            reservation_data: []
        })
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(`${Serviceurls.FO_RESERVATION_GET}?filter_data=${this.state.selectedCard ? this.state.selectedCard : ""}`)
                .then(response => {
                    let data = response?.data;
                    if (response.data.status === "success") {
                        this.setState({
                            all_total: data?.all_total,
                            ip_total: data?.ip_total,
                            cancel_total: data?.cancel_total,
                            reservation_data: data.data
                        }, () => {
                            this.state["reservation_data"].forEach((item, i) => item["index"] = i);
                            this.LoaderFunction(false);
                        });
                    }
                })
                .catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response?.data?.message, false);
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message, false);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message, false);
        }
    }

    confirmReserve = () => {
        let states = this.state;
        let startDate = new Date(states.admission_date)
        let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        // api call
        let datas = {
            "patient_id": states.patient_id,
            "doctor_id": states.reserv_doctor?.doctor_id,
            "speciality": states.reserv_spec?.id,
            "ip_admission_id": states.ip_admission_id ? states.ip_admission_id : null,
            "ward_id": states.reserv_ward ? states.reserv_ward : null,
            "floor": states.reserv_floor ? states.reserv_floor : null,
            "block_id": states.reserv_block ? states.reserv_block : null,
            "complaints": states.complaints ? states.complaints : "",
            "approx_check_in_date": states.admission_date ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}` : "",
            "attender_ward_id": states.attender_reserv_ward ? states.attender_reserv_ward : null,
            "attender_floor": states.attender_reserv_floor ? states.attender_reserv_floor : null,
            "attender_block_id": states.attender_reserv_block ? states.attender_reserv_block : null,
            "reserve_for_attender" : states.isAlsoforAttender
        }
        try {
            this.LoaderFunction(true)
            RestAPIService.create(datas, Serviceurls.FO_RESERVATION_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.reserveClosePop();
                        this.getReserveDetails();
                        this.LoaderFunction(false)
                        this.successMessage(response.data?.message);
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data?.message);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message);
        }
    }

    reasonPopupClose = () => {
        this.setState({
            isCancel: false,
            SelectedPatientDetails: {}
        })
    }

    addCancelComments = () => {
        let states = this.state;
        let id = states.SelectedPatientDetails.id ? states.SelectedPatientDetails.id : null;
        try {
            RestAPIService.delete(`${Serviceurls.FO_RESERVATION_GET}?id=${id}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.reasonPopupClose();
                        this.errorMessage(response.data?.message);
                        this.getReserveDetails();
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data?.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e?.message, false);
        }
    }

    renderReserveType = () => {
        const { t } = this.props
        var states = this.state
        return (
            <div className='eMed_OP_AddPatient'>
                <div className='eMed_OP_PatientType_CreateBtn'>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Tooltip title={t('CreateReservation')} placement='top' arrow>
                            <IconButton emed_tid = {"ReserveRoom_TestID"} size="small" onClick={() => { this.setState({ isReserve: true }) }}>
                                <img className='eMed_OP_AddPatient_Icon' src={ImagePaths.ReserveRoom.default} alt={"Add Patient"} />
                            </IconButton>
                        </Tooltip>
                        <Typography sx={{ fontSize: "0.8vw", color: "#04B7B1", fontWeight: 600 }}>{t("Create Reservation")}</Typography>
                    </Stack>
                </div>
                <div>
                    <div className='eMed_OP_Apt_Cards'>
                        <AppointmentCard emed_tid = {"All_testID"} onClick={() => { this.setState({ selectedCard: '' }, () => { this.getReserveDetails() }) }} count={`${states.all_total ? states.all_total : 0}`} label={t("All")} color={"#616161"} isSelectedCard={states.selectedCard === "" ? true : false} />
                        <AppointmentCard emed_tid = {"In_IP_testID"} onClick={() => { this.setState({ selectedCard: 'IP' }, () => { this.getReserveDetails() }) }} count={`${states.ip_total ? states.ip_total : 0}`} label={t("In IP")} color={"#04B7B1"} isSelectedCard={states.selectedCard === "IP" ? true : false} />
                        <AppointmentCard emed_tid = {"Cancelled_testID"} onClick={() => { this.setState({ selectedCard: 'cancel' }, () => { this.getReserveDetails() }) }} count={`${states.cancel_total ? states.cancel_total : 0}`} label={t("Cancelled")} color={"#B8170D"} isSelectedCard={states.selectedCard === "cancel" ? true : false} />
                    </div>
                </div>
            </div>
        )
    }

    renderReserveTable = () => {
        const { t } = this.props;
        let states = this.state;
        const columns = [
            { field: 'patient_name', headerName: t('Patient Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.20, renderCell: ({ row }) => (<CommonPatientDetails data={row} showAdmission={true} showAproxDate={true} />), density: 'compact' },
            {
                field: 'ipRecords', headerName: t('IPRecords'), headerClassName: 'eMed_Apt_TblHead', flex: 0.17, density: 'compact',
                renderCell: ({ row }) => (
                    <div>
                        <div className='eMed_OP_PatientType'> <Typography id='eMed_OP_AptTbl_AptText' sx={{ fontWeight: '600' }}>{'IP No:' + (row.ip_admission__ip_number ? row.ip_admission__ip_number : '-')}</Typography></div>
                        <div>{row.complaints && row.complaints?.length > 18 ?
                            <Tooltip title={row.complaints ? row.complaints : null} placement={'top'} arrow>
                                <Typography id='eMed_OP_AptTbl_AptText1'>{'Diagnosis :' + (row.complaints ? row.complaints.slice(0, 15) + '...' : '-')}</Typography>
                            </Tooltip> : <Typography id='eMed_OP_AptTbl_AptText1'>{'Diagnosis :' + (row.complaints ? row.complaints : '-')}</Typography>} </div>
                    </div>)
            },
            {
                field: 'doctor_name', headerName: t('Consultant'), headerClassName: 'eMed_Apt_TblHead', flex: 0.15, density: 'compact',
                renderCell: ({ row }) => (<div>{row?.doctor_name?.length > 20 ? <Tooltip placement="top" title={row?.doctor_name} arrow><div>{row?.doctor_name.slice(0, 18) + "..."}</div></Tooltip> : row.doctor_name ? row.doctor_name : "-"}</div>)
            },
            {
                field: 'speciality__name__name', headerName: t('Speciality'), headerClassName: 'eMed_Apt_TblHead', flex: 0.17, density: 'compact',
                renderCell: ({ row }) => (<div>{row?.doctor_name?.length > 20 ? <Tooltip placement="top" title={row?.speciality__name__name} arrow><div>{row?.speciality__name__name.slice(0, 18) + "..."}</div></Tooltip> : row.speciality__name__name ? row.speciality__name__name : "-"}</div>)
            },
            {
                field: 'is_attender', headerName: t('For'), headerClassName: 'eMed_Apt_TblHead', flex: 0.1, density: 'compact', headerAlign: 'center', align: "center",
                renderCell: ({ row }) => (<div>{row?.is_attender ? "Attender" : "Patient"}</div>)
            },
            {
                field: 'ward_block_floor', headerName: t('Ward/Block/Floor'), headerClassName: 'eMed_Apt_TblHead', flex: 0.24, density: 'compact',
                renderCell: ({ row }) => {
                    let ward_block_floor = `${row.ward__ward_name ? row.ward__ward_name : "-"}/${row.block_no__block_name ? row.block_no__block_name : "-"}/${row.floor_number ? row.floor_number : (row.floor_number == 0 ? 0 : "-")}`
                    return (<div>{ward_block_floor.length > 30 ? <Tooltip placement="top" title={ward_block_floor} arrow><div>{ward_block_floor.slice(0, 28) + "..."}</div></Tooltip> : ward_block_floor ? ward_block_floor : "-"}</div>)
                }
            },
            {
                field: 'action', headerName: t('Action'), headerClassName: 'eMed_Apt_TblHead', flex: 0.11, headerAlign: 'center', align: "center", hideable: false, sortable: false, density: 'compact',
                renderCell: ({ row }) => (
                    <Stack direction="row">
                        <Tooltip title={row.is_active ? t('Create Admission') : t('Cancelled')} placement='top' arrow>
                            <IconButton emed_tid ={"LabEditButton_TestID"} size="small" disabled={!row.is_active} onClick={() => { this.props.history.push({ pathname: "/IPHome/CreateAppointment", state: { SelectedPatientDetails: row, ReservationClicked: true } }) }}>
                                <img className='eMed_OP_AptTbl_Status_Icon' src={row?.is_active ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default} alt='Cancel Appointments' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={row.is_active ? t('Cancel Reservation') : t('Cancelled')} placement='top' arrow>
                            <IconButton emed_tid ={"CancelAppoinment_TestID"} size="small" disabled={!row.is_active} onClick={() => this.setState({ isCancel: true, SelectedPatientDetails: row })}>
                                <img className='eMed_OP_AptTbl_Status_Icon' src={row?.is_active ? ImagePaths.CancelAppoinment.default : ImagePaths.CancelAppDisabled.default} alt='Cancel Appointments' />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )
            }
        ];
        return (
            <div>
                <Box className='eMed_IPAptTbl_Con'>
                    <DataGrid
                        rows={states?.reservation_data}
                        columns={columns}
                        getRowId={(row) => row.index}
                        pageSize={this.state.pageSize}
                        page={this.state.page}
                        rowsPerPageOptions={[10, 20, 30]}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        density={'compact'}
                        rowHeight={100}
                        pagination
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => { this.setState({ pageSize: newPageSize }) }}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
            </div>
        )
    }

    handleText = (e) => {
        let states = this.state
        let name = e.target.name;
        let value = e.target.value.trimStart();
        switch (name) {
            case "mobile_no":
                let isValid = CommonValidation.numberOnly(value);
                if ((isValid && value.length < 11) || value === "") {
                    states[name] = value
                    if (value.length === 10) { this.CheckExistingPatients() }
                    if (states.mobile_no) {
                        states.patient_id = "";
                        states.uhid = "";
                        states.patient_name = "";
                        states.ip_No = "";
                        states.ip_admission_id = "";
                    }
                }
                break;
            default:
                if (value.length <= 150 || value === "") {
                    states[name] = value
                }
                break;
        }
        this.setState({
            states
        })
    }

    renderTextBox = (label, statekey, width) => {
        const { t } = this.props;
        let states = this.state;
        return (
            statekey === "admission_date" ?
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        open={states.DateTimerOpen}
                        onOpen={() => { this.setState({ DateTimerOpen: true }) }}
                        onClose={() => { this.setState({ DateTimerOpen: false }) }}
                        label={t(label) + " *"}
                        value={states.admission_date ? states.admission_date : null}
                        inputFormat='DD-MM-YYYY & hh:mm A'
                        minDate={new Date()}
                        onChange={(value) => {
                            if (value === null) {
                                this.setState({ admission_date: new Date() })
                            } else {
                                this.setState({ admission_date: value })
                            }
                        }}
                        renderInput={(params) => <TextField size="small" sx={{ width: "14.8vw" }} {...params}
                            onKeyDown={(e) => e.preventDefault()}
                            onClick={() => { this.setState({ DateTimerOpen: true }) }}
                            inputProps={{ ...params.inputProps,'emed_tid': 'DateTimerOpen_TestID001'}}
                        />}
                    />
                </LocalizationProvider>
                :
                <TextField
                    sx={{ width: width ? width : "14.8vw" }}
                    size="small"
                    autoComplete="off"
                    label={t(label)}
                    name={statekey}
                    inputProps={{ emed_tid: `cb_${label}` }}
                    value={states[statekey] ? states[statekey] : ""}
                    required={statekey === "complaints" || statekey === "mobile_no"}
                    disabled={(statekey === "mobile_no" || statekey === "complaints" || statekey === "preference") ? false : true}
                    InputProps={{
                        startAdornment: statekey === "mobile_no" ? <InputAdornment position="start">{"+91"}</InputAdornment> : null
                    }}
                    onChange={this.handleText}
                />
        )
    }

    renderDropdown(label, Key, List, disable, LabelKey, ValueKey, Type) {
        let states = this.state;
        const { t } = this.props;
        try {
            return (
                <FormControl size='small' sx={{ width: "14.8vw" }}>
                    <InputLabel>{Key === ("reserv_ward" || "attender_reserv_ward") ? `${t(label)} *` : t(label)}</InputLabel>
                    <Select
                        disabled={disable}
                        value={states[Key]}
                        inputProps={{ emed_tid: `cb_${label}` }}
                        label={Key === ("reserv_ward" || "attender_reserv_ward") ? `${t(label)} *` : t(label)}
                        onChange={(e) => {
                            states[Key] = e.target.value;
                            if(Type === "patient"){
                                if (ValueKey === "ward_id") {
                                    states["PatientBlockList"] = this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === e.target.value)), "block_id")
                                } else if (ValueKey === "block_id") {
                                    states["PatientFloorList"] = this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.reserv_ward && item.block_id === this.state.reserv_block)), "floor")
                                }
                            }else if (Type === "attender"){
                                if (ValueKey === "ward_id") {
                                    states["AttenderBlockList"] = this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === e.target.value)), "block_id")
                                } else if (ValueKey === "block_id") {
                                    states["AttenderFloorList"] = this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.attender_reserv_ward && item.block_id === this.state.attender_reserv_block)), "floor")
                                }
                            }
                            this.setState({ states })
                        }}
                        MenuProps={{
                            style: { maxHeight: 300, maxWidth: 150 }
                        }}
                    >
                        {
                            List?.length > 0 ?
                                List.map((list, index) => (
                                    <MenuItem key={index} value={list[ValueKey]}>{list[LabelKey]}</MenuItem>
                                )) : null
                        }
                    </Select>
                </FormControl>
            )
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    renderAutoDropdown = (label, stateKey, Data = [], width) => {
        let states = this.state
        const { t } = this.props;
        return (
            <Autocomplete
                size='small'
                clearIcon={false}
                sx={{ width: width ? width : '14.8vw' }}
                options={Data}
                getOptionLabel={(item) => ((label === "DoctorName" ? (typeof (item.doctor_name) === "string" ? item.doctor_name : "") : label === "Speciality" ? (typeof (item.name) === "string" ? item.name : "") : item))}
                value={states[stateKey] ? states[stateKey] : ""}
                ListboxProps={{ style: { maxHeight: 300 } }}
                onChange={(event, newValue) => {
                    states[stateKey] = newValue
                    if (typeof newValue === 'object' && newValue !== null) {
                        if (newValue !== null && "doctor_id" in newValue) {
                            let docDetail = { ...newValue }
                            docDetail["name"] = docDetail["specialization_name"];
                            delete docDetail.specialization_name;
                            docDetail["id"] = docDetail["specialization_id"];
                            delete docDetail.specialization_id;
                            states["reserv_spec"] = docDetail;
                        }
                        if (newValue !== null && "name" in newValue) {
                            let filteredLst = states["doctorList"].length > 0 && states["doctorList"].filter(item => item["specialization_id"] === newValue["id"])
                            states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
                            states["reserv_doctor"] = null;
                        }
                        if (newValue === null && label === "Speciality") {
                            states["filteredDocList"] = states["doctorList"];
                        }
                    }
                    this.setState({ states })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{...params.inputProps,'emed_tid': 'complaints_testID', }}
                        label={stateKey === "complaints" ? t(label) + " *" : t(label)}
                        placeholder={label}
                        onChange={(event) => {
                            let value = event.target.value.trimStart();
                            if (stateKey === "complaints" && (value.length <= 150 || value === "")) {
                                this.setState({
                                    complaints: value
                                })
                            }
                        }}
                    />
                )}
            />
        )
    }

    // ReservePopup
    reserveClosePop = () => {
        this.setState({
            isReserve: false,
            patient_id: "",
            mobile_no: "",
            uhid: "",
            patient_name: "",
            ip_No: "",
            admission_date: new Date(),
            reserv_doctor: null,
            reserv_spec: null,
            preference: "",
            complaints: "",
            reserv_block: "",
            reserv_floor: "",
            reserv_ward: "",
            attender_reserv_block: "",
            attender_reserv_floor: "",
            attender_reserv_ward: "",
            isAlsoforAttender:false,
            ip_admission_id: "",
        })
    }

    reserveClear = () => {
        this.setState({
            patient_id: "",
            mobile_no: "",
            uhid: "",
            patient_name: "",
            ip_No: "",
            admission_date: new Date(),
            reserv_doctor: null,
            reserv_spec: null,
            preference: "",
            complaints: "",
            reserv_block: "",
            reserv_floor: "",
            reserv_ward: "",
            attender_reserv_block: "",
            attender_reserv_floor: "",
            attender_reserv_ward: "",
            isAlsoforAttender:false,
            ip_admission_id: "",
        })
    }

    alsoForAttender = (checked) => {
        this.setState({
            isAlsoforAttender: checked,
            AttenderWardList: this.RemoveDuplicates(this.state.WardList, "ward_id").filter((item) => (item?.ward__attender === true)),
        }, ()=>{
            if (!this.state.isAlsoforAttender) {
                this.setState({
                    attender_reserv_block: "",
                    attender_reserv_floor: "",
                    attender_reserv_ward: "",
                })
            }
        });
    }

    ReservePopUp = () => {
        let states = this.state;
        return (
            <Box className="eMed_reservePop_Con">
                <div className="eMed_reservePop_subDiv">
                    {this.renderTextBox("PatientMobileNo", "mobile_no")}
                    {this.renderTextBox("PatientUHID", "uhid")}
                    {this.renderTextBox("PatientName", "patient_name")}
                    {this.renderTextBox("Ip No", "ip_No")}
                </div>
                <div className="eMed_reservePop_subDiv">
                    {this.renderAutoDropdown("DoctorName", "reserv_doctor", states.filteredDocList?.length > 0 ? states.filteredDocList : states.doctorList)}
                    {this.renderAutoDropdown("Speciality", "reserv_spec", states.specList)}
                    {this.renderTextBox("Preference", "preference", "30.95vw")}
                </div>
                <div className="eMed_reservePop_subDiv">
                    {this.renderDropdown("SelectWard", "reserv_ward", states.PatientWardList, false, "ward_name", "ward_id","patient")}
                    {this.renderDropdown("SelectBlock", "reserv_block", states.PatientBlockList, states.reserv_ward ? false : true, "block_name", "block_id","patient")}
                    {this.renderDropdown("SelectFloor", "reserv_floor", states.PatientFloorList, states.reserv_block ? false : true, "floor", "floor","patient")}
                    {this.renderTextBox("AdmissionDate", "admission_date")}
                </div>
                <div className="eMed_reservePop_subDiv" >
                    {this.renderAutoDropdown("Reason / Diagnosis", "complaints", states.ComplaintsList, "31vw")}
                    <Box display={"flex"} flexDirection={"row"} marginLeft={"1vw"} width={"30vw"}>
                        <FormControlLabel control={<Checkbox inputProps={{ emed_tid: "alsoForAttender_testID"}} size='small' checked={this.state.isAlsoforAttender} onChange={(e) => { this.alsoForAttender(e.target.checked) }} />} label={"Reserve Room also for Attender"} />
                    </Box>
                </div>
                {
                    this.state.isAlsoforAttender ?
                        <div className="eMed_reservePop_subDiv">
                            {this.renderDropdown("SelectWardAttender", "attender_reserv_ward", states.AttenderWardList, false, "ward_name", "ward_id","attender")}
                            {this.renderDropdown("SelectBlockAttender", "attender_reserv_block", states.AttenderBlockList, states.attender_reserv_ward ? false : true, "block_name", "block_id","attender")}
                            {this.renderDropdown("SelectFloorAttender", "attender_reserv_floor", states.AttenderFloorList, states.attender_reserv_block ? false : true, "floor", "floor","attender")}
                        </div> : null
                }
            </Box>
        )
    }

    SameNumberPatientPop() {
        let { t } = this.props;
        return (
            <Modal open={true}>
                <Box className="eMed_MobileNum_Main">
                    <Box component={'div'} className="eMed_MobileNum_Header">
                        <Typography variant='h6'>{"Patient List"}</Typography>
                        <IconButton emed_tid = {"LabCloseButton_TestID002"} onClick={() => { this.setState({ ipPatientList: [] }) }} marginRight={'4vw'}>
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                        </IconButton>
                    </Box>
                    <Box component={'div'} className="eMed_MobileNum_wrapper">
                        {
                            this.state.ipPatientList.length > 0 ?
                                this.state.ipPatientList.map((list) => {
                                    let PatientFullName = `${list.first_name} ${list.last_name}`
                                    list["patient_name"] = PatientFullName
                                    list["patient_mobile_number"] = list.mobile_number
                                    list["patient_photo_url"] = list.photo_url
                                    return (
                                        <Box>
                                            <Box className="eMed_Patient_Num_Search_pop">
                                                <CommonPatientDetails data={list} />
                                                <Tooltip title={t("Create Reservation")} arrow>
                                                    <IconButton emed_tid = {"Create_Reservation_TestID"} position="end" onClick={() => {
                                                        this.setState({
                                                            ip_admission_id: list.ip_admission_id,
                                                            patient_id: list.patient_id,
                                                            uhid: list.patient_account_number,
                                                            patient_name: list.patient_name,
                                                            ip_No: list.ip_number,
                                                            ipPatientList: []
                                                        })
                                                    }}>
                                                        <PersonAddAlt1Icon sx={{ color: Colors.themeDark }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                            <Divider />
                                        </Box>
                                    )
                                }) : null
                        }
                    </Box>
                </Box>
            </Modal>
        )
    }

    CheckAllFields = () => {
        let states = this.state;
        if (states.patient_id !== "" && states.complaints !== "" && states.admission_date !== "Invalid DateTime" && states.reserv_ward !== "" && (!states.isAlsoforAttender || states.attender_reserv_ward !== "")) {
            return true;
        }
        return false;
    }

    render() {
        const { t } = this.props;
        let states = this.state;
        return (
            <div id='eMed_IP_Appointment'>
                {this.renderReserveType()}
                {this.renderReserveTable()}

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {states.ipPatientList?.length > 0 ? this.SameNumberPatientPop() : null}

                {states.isReserve ?
                    <CommonPopUp width={"66vw"}
                        backgroundColor={Colors.white} justifyRight={true}
                        popUpClose={this.reserveClosePop} title={t("Reservation")}
                        component={this.ReservePopUp} buttonOne={t("Clear")}
                        buttonOneAction={this.reserveClear} buttonTwo={t("Reserve")}
                        buttonTwoAction={this.confirmReserve} disableBtn2={this.CheckAllFields() ? false : true}
                    />
                    : null
                }
                {
                    states.isCancel ? <ReasonPopup
                        title={"Are you sure? Do you want to cancel the reservation"}
                        AlertPopupClose={this.reasonPopupClose.bind(this)}
                        label={"Enter the Reason"}
                        btntext={'Cancel'}
                        btnvarient={'outlined'}
                        btncolor={'error'}
                        btntext1={'Confirm'}
                        btnvarient1={'contained'}
                        sendCmt={this.addCancelComments.bind(this)} /> : null
                }
            </div>
        )
    }
}
export default withTranslation()(RoomAvailability);