import React, { Component } from "react";
import { Autocomplete, Box, Button, Chip, Modal, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from '@mui/material'
import { Colors } from "../../../../Styles/Colors";
import ShowComponents from "./ShowComponent";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import { CommonDeleteButton, DeletePopup } from "../../../../Components/Common Components/CommonComponents";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import CommonValidation from "../../../../Components/CommonFunctions/CommonValidation";

const Eye_Type = [
    { value: "Right Eye (OD)", label: "Right Eye (OD)" },
    { value: "Left Eye (OS)", label: "Left Eye (OS)" },
    { value: "Both Eye (OU)", label: "Both Eye (OU)" }
]
export default class DocOcularHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientId: this.props.patientId,
            clinicId: this.props.clinicId,
            appointmentId: this.props.appointmentId,
            openPopUp: false,
            loading: false,
            deletePopup: false,
            removeData: false,
            isEdit: false,
            postLoading: false,
            isLocked: false,
            complainDescrp: "",
            Years: "",
            Months: "",
            Days: "",
            updateId: "",
            AddNew: false,
            DrugList: [],
            SurgeryList: [],
            value: "One",
            Medication: [],
            Surgery: "",
            MedicationData: [],
            SurgeryData: [],
            SurgeryYears: "",
            disableMedi: false,
            disableSurg: false,
            isEyeTypeSelected: false,
            TimePeriod: [
                {
                    eye_type: null,
                    no_of_years: null,
                    months: null,
                    days: null
                }
            ],
            surgeryID: null,
            SelectedHistory: null
        }
    }

    apiCalls = () => {
        this.getFavoriteDrugs()
        this.getSurgeryFavoriteDrugs()
        this.getMedicationData()
        this.getOcularSurgeryData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getFavoriteDrugs = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_OPTHALMOLOGY_FREQUENT_DRUG)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            DrugList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getSurgeryFavoriteDrugs = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_SURGERY_FREQUENT_DRUG)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            SurgeryList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getMedicationData = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.DOC_OCULAR_MEDICATION_GET + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&doctor_id=${this.props.doctorID}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            MedicationData: response.data.data,
                            loading: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    getOcularSurgeryData = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.DOC_OCULAR_SURGERY_GET + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            SurgeryData: response.data.data,
                            loading: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    checkValidation = () => {
        if (this.state.Medication != null) {
            return null
        } else {
            if (this.state.Medication != null && this.state.Medication?.length === 0) {
                return "Please Choose Medication"
            } 
        }
    }

    isFieldEmpty = (field) => {
        return field === null || field === undefined || field === '';
    };

    validateTimePeriod = (timePeriod) => {
        if (!timePeriod?.eye_type) {
            return false;
        } else if (this.isFieldEmpty(timePeriod?.no_of_years)) {
            return false;
        } else {
            return true;
        }
    };

    checkSurgeryValidation = () => {
        let eyeTypeSelected = false;

        // let validTimePeriods = this.state.TimePeriod.every((timePeriod) => {
        //     if (!timePeriod?.eye_type) {
        //         eyeTypeSelected = true;
        //     }
        //     return this.validateTimePeriod(timePeriod);
        // });

        if (this.state.Surgery !== "") {
            return null;
        } else {
            if (this.state.Surgery == "") {
                return "Please Choose Surgery";
            } 
            // else if (eyeTypeSelected) {
            //     return "Please select Eye Type";
            // } else {
            //     return "Enter Surgery Year";
            // }
        }
    }

    DeleteComplaintsData = () => {
        try {
            if (this.state.SelectedHistory) {
                let SeriveURL = this.state.value === "One" ? `${Serviceurls.DOC_OPTHALMOLOGY_FREQUENT_DRUG}?drug_name=${this.state.SelectedHistory}` : `${Serviceurls.DOC_SURGERY_FREQUENT_DRUG}?surgery_name=${this.state.SelectedHistory}`
                this.setState({ removeData: true })
                RestAPIService.delete(SeriveURL)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.state.value === "One" ? this.getFavoriteDrugs() : this.getSurgeryFavoriteDrugs()
                            let filteredArray =this.state.value === "One" ? this.state.Medication.filter((item)=>(item !== this.state.SelectedHistory)) : null
                            if( this.state.value === "Two" && this.state.Surgery === this.state.SelectedHistory ){
                                this.setState ({Surgery : ""})
                            }
                            this.setState({ deletePopup: false, SelectedHistory: null, removeData: false,  Medication : filteredArray })
                           
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ removeData: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ removeData: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                let data = {}
                let SeriveURL = this.state.selectedField == "Medication" ? Serviceurls.DOC_OCULAR_MEDICATION_DELETE : Serviceurls.DOC_OCULAR_SURGERY_DELETE
                this.setState({ removeData: true })
                RestAPIService.create(data, SeriveURL + `${this.state.updateId}/`)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.state.selectedField == "Medication" ? this.getMedicationData() : this.getOcularSurgeryData()
                            this.setState({ deletePopup: false, removeData: false, updateId: "", surgeryID: null })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ removeData: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ removeData: false })
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
        }
    }

    addOcularMedication = () => {
        let states = this.state
        try {
            let data = {
                "id": states.updateId ? states.updateId : null,
                "appointment_id": states.appointmentId,
                "years": states.Years || 0,
                "months": states.Months || 0,
                "days": states.Days || 0,
                "comments": states.complainDescrp
            }
            var datas = []
            if (states.updateId) {
                datas.push(states.Medication)
                data["medications"] = datas;
            } else {
                for (var i = 0; i < states.Medication.length; i++) {
                    datas.push(states.Medication[i])
                }
                data["medications"] = datas;
            }
            let validate = this.checkValidation()
            if (validate === null) {
                this.setState({ postLoading: true })
                RestAPIService.create(data, Serviceurls.DOC_OCULAR_MEDICATION_GET)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.onClearHandler()
                            this.getMedicationData()
                            this.setState({ openPopUp: false, postLoading: false, isEdit: false, updateId: "", surgeryID: null })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage(validate)
            }
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }

    addOcularSurgery = () => {
        let states = this.state
        try {
            let validate = this.checkSurgeryValidation()
            if (validate === null) {
                const myObjectsWithStaticKeys = states?.TimePeriod.map(obj => ({
                    ...obj,
                    'surgery_name': states?.Surgery,
                    'reason': states?.complainDescrp,
                    'id': states?.surgeryID
                }));
                let data = {
                    "patient_id": states.patientId,
                    'appointment_id': states.appointmentId,
                    "surgery": myObjectsWithStaticKeys
                }
                this.setState({ postLoading: true })
                RestAPIService.create(data, Serviceurls.DOC_OCULAR_SURGERY_GET)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.onClearHandler()
                            this.getOcularSurgeryData()
                            this.setState({ openPopUp: false, postLoading: false, isEdit: false, updateId: "", surgeryID: null })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage(validate)
            }
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }

    renderChip = (values, key, Type) => {
        let states = this.state
        return (
            <div style={{ maxHeight: "6vw", overflow: "scroll" }}>
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
                    {
                        values && values.map((item, index) => (
                            <Chip
                                className={`eMed_chip ${key.includes(item) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                style={{ width: "8.5vw", display: 'flex', justifyContent: 'space-between' }}
                                label={item}
                                variant={key.includes(item) ? 'contained' : 'outlined'}
                                key={index}
                                onClick={() => {
                                    if (Type == "Surgery") {
                                        if (item === key) {
                                            states[Type] = ""
                                            this.setState({ states })
                                        } else {
                                            states[Type] = item
                                            this.setState({ states })
                                        }
                                    } else {
                                        const selectedIndex = states[Type].indexOf(item);
                                        let newSelected = [...states[Type]];
                                        if (selectedIndex === -1) {
                                            if (states.updateId) {
                                                newSelected = item
                                            } else {
                                                newSelected.push(item);
                                            }
                                        } else {
                                            newSelected = newSelected.filter((_, index) => index !== selectedIndex);
                                        }
                                        states[Type] = newSelected
                                        this.setState({ states });
                                    }
                                }}
                                clickable
                                onDelete={() => { this.setState({ deletePopup: true, SelectedHistory: item }) }}
                            />
                        ))
                    }
                </div>
            </div>
        )
    }

    onClearHandler = () => {
        this.setState({
            complainDescrp: "",
            Years: "",
            Months: "",
            Days: "",
            AddNew: false,
            NewText: "",
            Medication: [],
            Surgery: "",
            SurgeryYears: "",
            disableMedi: false,
            disableSurg: false,
            surgeryID: null,
            TimePeriod: [
                {
                    eye_type: null,
                    no_of_years: null,
                    months: null,
                    days: null
                }
            ]
        })
    }

    renderTextField = (label) => {
        let states = this.state
        return (
            <div>
                <TextField
                    label={label}
                    size="small"
                    style={{ marginLeft: "0.5vw", marginRight: label == "Days" ? "0.5vw" : 0 }}
                    variant="outlined"
                    value={states[label]}
                    onChange={(event) => {
                        let value = event.target.value
                        let isValid = CommonValidation.numberOnly(value)
                        if ((value === "" || isValid) && value.length < 5 && label == "Years") {
                            states[label] = value
                            this.setState({ states })
                        } else if ((value === "" || isValid) && value <= 12 && label == "Months") {
                            states[label] = value
                            this.setState({ states })
                        } else if ((value === "" || isValid) && value <= 31 && label == "Days") {
                            states[label] = value
                            this.setState({ states })
                        }
                    }}
                />
            </div>
        )
    }

    TimePeriodDelete(index, item) {
        let states = this.state
        if (index === 0 && this.state.TimePeriod?.length === 1) {
            states.TimePeriod = [
                {
                    eye_type: null,
                    no_of_years: null,
                    months: null,
                    days: null
                }
            ]
        } else {
            states.TimePeriod.splice(index, 1)
        }
        this.setState({ states })
    }

    AddNewTimePeriod(index) {
        let obj = { eye_type: null, no_of_years: null, months: null, days: null }
        if (this.state.TimePeriod[index].no_of_years != null || this.state.TimePeriod[index]?.months != null || this.state.TimePeriod[index]?.days != null) {
            this.state.TimePeriod.push(obj)
            this.setState({ TimePeriod: this.state.TimePeriod })
        } else if (this.state.TimePeriod[index].eye_type == null) {
            this.errorMessage("Select Eye Type")
        } else {
            this.errorMessage("Enter Previous Time Period")
        }
    }

    renderPopUpScreen = () => {
        let states = this.state
        try {
            return (
                <Box>
                    <Modal open={this.state.openPopUp}>
                        <Box component={'div'} className='eMed_Doc_Notes_Pop_Up' style={{ width: "38.5vw", height: "80vh" }}>
                            <Box p={'1vw'} component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Add Ocular History</Typography>
                                <Button
                                    className='Common_Btn_Min_Width'
                                    onClick={() => this.setState({ openPopUp: false, isEdit: false, updateId: "", surgeryID: null }, () => { this.onClearHandler() })}
                                >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>

                            <Box sx={{ padding: "0.5vw" }}>
                                <Tabs
                                    value={this.state.value}
                                    onChange={(e, newValue) => this.setState({
                                        value: newValue
                                    }, () => { this.onClearHandler() })}
                                >
                                    <Tab value="One" disabled={this.state.disableMedi} label={"Medication"} className='eMed_tab_header' />
                                    <Tab value="Two" disabled={this.state.disableSurg} label={"Surgery"} className='eMed_tab_header' />
                                </Tabs>

                                {
                                    this.state.value === "One" ?
                                        <div>
                                            {this.renderChip(this.state.DrugList, this.state.Medication, "Medication")}
                                            {this.state.AddNew ? null :
                                                <div style={{ height: '2.5vw', marginBottom: "0.65vw", marginTop: "-0.25vw", paddingRight: "1vw" }}>
                                                    <Button
                                                        sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                                        variant='contained'
                                                        onClick={() => { this.setState({ AddNew: true }) }}
                                                    >Add</Button>
                                                </div>}
                                            {this.state.AddNew ?
                                                <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5vw", height: "2.5vw", marginBottom: "0.65vw", marginTop: "-0.25vw" }}>
                                                    <TextField
                                                        label={(`Add Medication`)}
                                                        size="small"
                                                        style={{ marginTop: "0.5vw", width: "20vw" }}
                                                        variant="outlined"
                                                        value={states.NewText}
                                                        onChange={(event) => {
                                                            let value = event.target.value
                                                            this.setState({ NewText: value })
                                                        }}
                                                    />
                                                    <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                                                        onClick={() => {
                                                            if (this.state.NewText) {
                                                                let isHaveItem = states.DrugList.find((item) => (item?.toLowerCase().trim() === this.state.NewText?.toLowerCase().trim()))
                                                                if (!isHaveItem) {
                                                                    states.DrugList.push(CommonValidation.removeSpace(this.state.NewText))
                                                                    states["Medication"] = [...this.state.Medication, this.state.NewText.trim()]
                                                                } else {
                                                                    states["Medication"] = [...this.state.Medication, isHaveItem]
                                                                }
                                                                this.setState({ states }, () => { this.setState({ AddNew: false, NewText: "" }) })
                                                            }
                                                        }}>
                                                        <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.Tick.default} alt={"Tick"} />
                                                    </div>
                                                    <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                                                        onClick={() => {
                                                            this.setState({ AddNew: false, NewText: "" })
                                                        }}>
                                                        <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.CancelIcon.default} alt={"Close"} />
                                                    </div>
                                                </div> : null
                                            }
                                            <Box component={'div'} height={'2vw'} display={'flex'} ml={"0.5vw"} alignItems={'center'}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Duration</Typography>
                                            </Box>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "0.65vw" }}>
                                                {this.renderTextField("Years")}
                                                {this.renderTextField("Months")}
                                                {this.renderTextField("Days")}
                                            </div>
                                        </div> :
                                        <div>
                                            <div>
                                                {this.renderChip(this.state.SurgeryList, this.state.Surgery, "Surgery")}
                                                {this.state.AddNew ? null :
                                                    <div style={{ height: '2.5vw', marginBottom: "0.65vw", marginTop: "-0.25vw", paddingRight: "1vw" }}>
                                                        <Button
                                                            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                                            variant='contained'
                                                            onClick={() => { this.setState({ AddNew: true }) }}
                                                        >Add</Button>
                                                    </div>}
                                                {this.state.AddNew ?
                                                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5vw", height: "2.5vw", marginBottom: "0.65vw", marginTop: "-0.25vw" }}>
                                                        <TextField
                                                            label={(`Add Surgery`)}
                                                            size="small"
                                                            style={{ marginTop: "0.5vw", width: "20vw" }}
                                                            variant="outlined"
                                                            value={states.NewText}
                                                            onChange={(event) => {
                                                                let value = event.target.value
                                                                this.setState({ NewText: value })
                                                            }}
                                                        />
                                                        <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                                                            onClick={() => {
                                                                if (this.state.NewText) {
                                                                    let isHaveItem = states.SurgeryList.find((item) => (item?.toLowerCase().trim() === this.state.NewText?.toLowerCase().trim()))
                                                                    if (!isHaveItem) {
                                                                        states.SurgeryList.push(CommonValidation.removeSpace(this.state.NewText))
                                                                        states["Surgery"] = this.state.NewText.trim()
                                                                    } else {
                                                                        states["Surgery"] = isHaveItem
                                                                    }
                                                                    this.setState({ states }, () => { this.setState({ AddNew: false, NewText: "" }) })
                                                                }
                                                            }}>
                                                            <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.Tick.default} alt={"Tick"} />
                                                        </div>
                                                        <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                                                            onClick={() => {
                                                                this.setState({ AddNew: false, NewText: "" })
                                                            }}>
                                                            <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.CancelIcon.default} alt={"Close"} />
                                                        </div>
                                                    </div> : null
                                                }
                                                <Box component={'div'} height={'2vw'} display={'flex'} ml={"0.5vw"} alignItems={'center'}>
                                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Time Period</Typography>
                                                </Box>
                                                {this.state.TimePeriod.map((item, index) => (
                                                    <Box key={index}>
                                                        <div style={{ padding: "0.65vw", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <Autocomplete
                                                                clearIcon
                                                                size='small'
                                                                sx={{ width: "90%" }}
                                                                options={Eye_Type?.map((item) => item?.label)}
                                                                value={states?.TimePeriod[index].eye_type}
                                                                limitTags={3}
                                                                autoComplete='off'
                                                                onChange={(event, newValue) => {
                                                                    states.TimePeriod[index].eye_type = newValue
                                                                    this.setState({
                                                                        states
                                                                    })
                                                                }}
                                                                renderInput={(params) => <TextField {...params}
                                                                    label='Select Eye Type'
                                                                />}
                                                            />
                                                            <TextField
                                                                label={"Year"}
                                                                size="small"
                                                                style={{ marginLeft: "0.5vw" }}
                                                                variant="outlined"
                                                                value={states?.TimePeriod[index].no_of_years ? states?.TimePeriod[index].no_of_years : ""}
                                                                onChange={(event) => {
                                                                    let value = event.target.value
                                                                    let isValid = CommonValidation.NumberWithDot(value)
                                                                    if ((value === "" || isValid) && value.length < 5) {
                                                                        states.TimePeriod[index].no_of_years = value
                                                                        this.setState({ states })
                                                                    }
                                                                }}
                                                            />
                                                            <TextField
                                                                label={"Month"}
                                                                size="small"
                                                                style={{ marginLeft: "0.5vw" }}
                                                                variant="outlined"
                                                                value={states?.TimePeriod[index].months ? states?.TimePeriod[index].months : ""}
                                                                onChange={(event) => {
                                                                    let value = event.target.value
                                                                    let isValid = CommonValidation.NumberWithDot(value)
                                                                    if ((value === "" || isValid) && value <= 12) {
                                                                        states.TimePeriod[index].months = value
                                                                        this.setState({ states })
                                                                    }
                                                                }}
                                                            />
                                                            <TextField
                                                                label={"Date"}
                                                                size="small"
                                                                style={{ marginLeft: "0.5vw" }}
                                                                variant="outlined"
                                                                value={states?.TimePeriod[index].days ? states?.TimePeriod[index].days : ""}
                                                                onChange={(event) => {
                                                                    let value = event.target.value
                                                                    let isValid = CommonValidation.NumberWithDot(value)
                                                                    if ((value === "" || isValid) && value <= 31) {
                                                                        states.TimePeriod[index].days = value
                                                                        this.setState({ states })
                                                                    }
                                                                }}
                                                            />
                                                            <Box marginLeft={"0.65vw"}><CommonDeleteButton size={"1.5vw"} onClick={this.TimePeriodDelete.bind(this, index, item)} /></Box>
                                                        </div>
                                                        {
                                                            index === states.TimePeriod.length - 1 &&
                                                            <Button sx={{ marginTop: '0.5vw', marginLeft: "0.25vw", borderRadius: "1vw" }} className='eMed_usrconf_btn' onClick={() => { this.AddNewTimePeriod(index) }}>
                                                                <img src={ImagePaths.AddIcon.default} alt="add" style={{ height: "1.5vw", width: "1.5vw", borderRadius: "1vw" }} />
                                                                <span style={{ marginLeft: "0.5vw", textTransform: "capitalize" }}>Add New</span>
                                                            </Button>
                                                        }
                                                    </Box>
                                                ))}
                                            </div>
                                        </div>
                                }
                            </Box>
                            <Box component={'div'} style={{ display: "flex", flexDirection: "column", paddingLeft: "1vw" }}>
                                <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Comments</Typography>
                                </Box>
                                <Box component={'div'} style={{ marginLeft: "0.5vw" }}>
                                    <TextField
                                        multiline
                                        label='Reason'
                                        rows={3}
                                        sx={{ width: '35.3vw', marginTop: "0.5vw" }}
                                        value={this.state.complainDescrp}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            if (value?.length < 250) {
                                                this.setState({ complainDescrp: e.target.value })
                                            } else {
                                                this.errorMessage('Allowed only 250 characters')
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'} mt={"0.5vw"}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => this.onClearHandler()}
                                >Clear</Button>
                                <Button
                                    disabled={this.state.postLoading}
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    variant='contained'
                                    onClick={() => { this.state.value == "One" ? this.addOcularMedication() : this.addOcularSurgery() }}
                                >Add</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    popupClose = () => {
        try {
            this.setState({
                deletePopup: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (item) => {
        if (item?.drug_name) {
            let List = this.state.DrugList.filter((data) => data === item?.drug_name)
            if (!List.length > 0) {
                this.state.DrugList.push(item?.drug_name)
            }
            this.setState({
                updateId: item?.id,
                complainDescrp: item?.comments,
                Years: item?.years ? item?.years : "",
                Months: item?.months ? item?.months : "",
                Days: item?.days ? item?.days : "",
                Medication: item?.drug_name,
                openPopUp: true,
                disableSurg: true,
                value: "One",
                DrugList: this.state.DrugList.includes(item?.drug_name) ? this.state.DrugList : [...this.state.DrugList, item?.drug_name]
            })
        } else {
            let List = this.state.SurgeryList.filter((data) => data === item?.surgery_name)
            if (!List.length > 0) {
                this.state.SurgeryList.push(item?.surgery_name)
            }
            this.setState({
                updateId: item?.id,
                surgeryID: item?.id,
                complainDescrp: item?.reason,
                TimePeriod: [
                    {
                        eye_type: item?.eye_type,
                        no_of_years: item?.no_of_years,
                        months: item?.months,
                        days: item?.days
                    }
                ],
                Surgery: item?.surgery_name,
                openPopUp: true,
                disableMedi: true,
                value: "Two",
                SurgeryList: this.state.SurgeryList.includes(item?.surgery_name) ? this.state.SurgeryList : [...this.state.SurgeryList, item?.surgery_name]
            })
        }
    }

    onDeleteHandler = (item) => {
        try {
            this.setState({
                deletePopup: true,
                updateId: item?.id,
                selectedField: item?.drug_name ? "Medication" : "Surgery"
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'}>
                    <Box component={'div'} height={'3vw'} display={'flex'} p={'0.5vw'} justifyContent={'space-between'} alignItems={'center'} >
                        <Box component={'div'}>
                            <Typography fontWeight={600}>Ocular History</Typography>
                        </Box>
                        <Box component={'div'}>
                            <Button
                                variant='contained'
                                sx={{ height: '2vw', width: '5vw', textTransform: "capitalize" }}
                                onClick={() => { this.setState({ openPopUp: true, value: "One" }) }}
                                disabled={this.state.isLocked || this.props.isLocked}
                            >Add</Button>
                        </Box>
                    </Box>
                    <Box component={'div'} sx={{ backgroundColor: Colors.white, paddingX: "0.5vw" }} pt={'0.5vw'} pb={'0.2vw'}>
                        <Box component={'div'} sx={{ border: '1px solid lightgray', maxHeight: '12vh', overflow: 'auto' }}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Medication</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Comments</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Action</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.MedicationData?.length > 0 ? this.state.MedicationData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell style={{ fontWeight: 600 }}>
                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                        <div style={{ fontSize: "0.9vw" }}>{item?.drug_name}</div>
                                                        <div style={{ fontSize: "0.8vw" }}>{(item.years || item?.months || item.days) ? `${item?.years ? item.years : "0"}Y ${item?.months ? item?.months : "0"}M ${item.days ? item.days : "0"}D` : "-"}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {item?.comments ? item?.comments?.length > 15 ?
                                                        <Tooltip placement='top' title={item?.comments}>
                                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.comments?.slice(0, 15) + '...'}</Typography>
                                                        </Tooltip> :
                                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.comments}</Typography> : '-'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <Box component={'div'} display={'flex'} justifyContent={"flex-start"}>
                                                        <Button
                                                            className='Common_Btn_Min_Width'
                                                            onClick={() => { this.setState({ isEdit: true }, () => { this.onEditHandler(item) }) }}
                                                            disabled={this.state.isLocked || this.props.isLocked}
                                                        >
                                                            {this.state.isLocked || this.props.isLocked ?
                                                                <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                                :
                                                                <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                            }
                                                        </Button>
                                                        <Button
                                                            className='Common_Btn_Min_Width'
                                                            onClick={() => this.onDeleteHandler(item)}
                                                            disabled={this.state.isLocked || this.props.isLocked}
                                                        >
                                                            {this.state.isLocked || this.props.isLocked ?
                                                                <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                                                :
                                                                <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                                                            }
                                                        </Button>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell />
                                            <TableCell>No Records To Be Shown</TableCell>
                                            <TableCell />
                                        </TableRow>}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box component={'div'} sx={{ border: '1px solid lightgray', marginTop: "0.65vw", maxHeight: '12vh', overflow: 'auto' }}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Eye</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Surgery</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Comments</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Action</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.SurgeryData?.length > 0 ? this.state.SurgeryData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.eye_type === "Right Eye (OD)" ? "Right" : item?.eye_type === "Left Eye (OS)" ? "Left" : item?.eye_type === "Both Eye (OU)" ? "Both" : "-"}</Typography>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 600 }}>
                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                        <div style={{ fontSize: "0.9vw" }}>{item?.surgery_name}</div>
                                                        <div style={{ fontSize: "0.8vw" }}>{item?.no_of_years ? `${item?.no_of_years ? item.no_of_years : "0"}Y` : "-"}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {item?.reason ? item?.reason?.length > 15 ?
                                                        <Tooltip placement='top' title={item?.reason}>
                                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.reason?.slice(0, 15) + '...'}</Typography>
                                                        </Tooltip> :
                                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.reason}</Typography> : '-'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <Box component={'div'} display={'flex'} justifyContent={"flex-start"}>
                                                        <Button
                                                            className='Common_Btn_Min_Width'
                                                            onClick={() => { this.setState({ isEdit: true }, () => { this.onEditHandler(item) }) }}
                                                            disabled={this.state.isLocked || this.props.isLocked}
                                                        >
                                                            {this.state.isLocked || this.props.isLocked ?
                                                                <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                                :
                                                                <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                            }
                                                        </Button>
                                                        <Button
                                                            className='Common_Btn_Min_Width'
                                                            onClick={() => this.onDeleteHandler(item)}
                                                            disabled={this.state.isLocked || this.props.isLocked}
                                                        >
                                                            {this.state.isLocked || this.props.isLocked ?
                                                                <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                                                :
                                                                <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                                                            }
                                                        </Button>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell />
                                            <TableCell>No Records To Be Shown</TableCell>
                                            <TableCell />
                                        </TableRow>}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                    {this.renderPopUpScreen()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.deletePopup ? <DeletePopup
                        DeletTitle={this.state.SelectedHistory ? `Are you sure ? Do you want to remove ${this.state.SelectedHistory} from suggestion ?` : `Are you sure you want to delete ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.DeleteComplaintsData.bind(this)}
                        disable={this.state.removeData}
                    /> : null}
                </Box>
            </ShowComponents>
        )
    }
}