import React, { Component } from "react";
import './ClinicConfig.css';
import { Box, Paper, TextField, Button, Stack,Grid } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup } from "../../../Components/Common Components/CommonComponents";
import { Colors } from "../../../Styles/Colors";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";

class ExpenseCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expCategoryDetails: [],
            expCategory: "",
            description: "",
            deletedPopup: false,
            deleteId: null,
            dltExpense: "",
            isEdit: false,
            edit_id: null,
            page: 0,
            pageSize: 10,
            isErrorMsg: false,
            isErrorMsgText: "",
            successMsg: false,
            successMsgText: "",
        }
    }

    componentDidMount() {
        this._getExpenseCategoryDetails();
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    _getExpenseCategoryDetails = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.EXPENSE_CATEGORY_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            expCategoryDetails: response.data.data,
                        }, () => {
                            this.state.expCategoryDetails.forEach((item, i) => {
                                item["index"] = i + 1
                            })
                            this.LoaderFunction(false)
                        });
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.successMessage(error.response.data.message);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message);
        }
    }

    handleEdit = (item) => {
        this.setState({
            expCategory: item.name,
            description: item.description,
            edit_id: item.id,
            isEdit: true,
        })
    }

    handleDelete = (value) => {
        if (!this.state.isEdit) {
            this.setState({
                deletedPopup: true,
                deleteId: value.id,
                dltExpense: value.name
            })
        }
    }

    handleClear = () => {
        this.setState({
            expCategory: "",
            description: "",
            isEdit: false,
            edit_id: null,
        })
    }

    PopupClose = () => {
        this.setState({
            deletedPopup: false,
            deleteId: null,
            dltExpense: ""
        })
    }

    removeDataList = () => {
        try {
            RestAPIService.delete(`${Serviceurls.EXPENSE_CATEGORY_GET}?id=${this.state.deleteId ? this.state.deleteId : 0}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.PopupClose();
                        this.successMessage(response.data.message);
                        this._getExpenseCategoryDetails();
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message, false);
        }
    }

    handleChange = (event) => {
        let states = this.state
        let name = event.target.name;
        let value = event.target.value.trimStart();
        if (value === "" || value.length <= 150) {
            states[name] = value;
        }
        this.setState({
            states
        })
    }

    renderTextBox(label, statekey,) {
        const { t } = this.props;
        let states = this.state;
        return (
            <div className="eMed_expConfig_txtbox">
                <TextField
                    fullWidth
                    size="small"
                    autoComplete="off"
                    label={t(label)}
                    inputProps={{ emed_tid: `cb_${label}` }}
                    name={statekey}
                    required={statekey !== "description"}
                    multiline={statekey === "description"}
                    rows={3}
                    value={states[statekey] ? states[statekey] : ""}
                    onChange={this.handleChange}
                />
            </div>
        )
    }
    handleAdd = () => {
        const { t } = this.props
        let states = this.state
        let datas = {};
        let msg = "";
        if (!states.expCategory) { msg = t("Fillmandatory") }

        if (msg === "") {

            datas = {
                "category_name": states.expCategory,
                "description": states.description
            }
            if (states.edit_id) {
                datas["id"] = states.edit_id
            }

            try {
                this.LoaderFunction(true)
                RestAPIService.create(datas, Serviceurls.EXPENSE_CATEGORY_GET)
                    .then(response => {
                        if (response.data.status === "successs") {
                            this.handleClear();
                            this._getExpenseCategoryDetails();
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.errorMessage(error.response.data.message);
                        }
                    });
            } catch (e) {
                this.LoaderFunction(false)
                this.errorMessage(e.message);
            }
        } else {
            this.errorMessage(msg);
        }
    }

    render() {
        const { t } = this.props;
        let states = this.state;
        const columns = [
            { field: 'index', headerName: t('SNo'), sortable: false, flex: 0.15 },
            { field: 'name', headerName: t('ExpenseCategory'), flex: 0.60, renderCell: ({ row }) => (<div>{row?.name ? row?.name : "-"}</div>) },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.25, sortable: false, hideable: false, align: "center", renderCell: ({ row }) => {
                    return (
                        <Stack direction="row" alignItems="center" spacing={4}>
                            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                                <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Expense Category", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleEdit.bind(this, row)} />
                                <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Expense Category", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleDelete.bind(this, row)} />
                            </Stack>
                        </Stack>
                    )
                }
            }
        ]
        return (
            <Box id="eMed_expConfig_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container spacing={3} className="eMed_expConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_expConfig_Div" >
                            <DataGrid
                                rows={states.expCategoryDetails}
                                columns={columns}
                                getRowId={(row) => row.index}
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: CommonGridHeader.CommonToolBar,
                                    NoRowsOverlay: () => {
                                        return (
                                            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }
                                }}
                                rowCount={states.expCategoryDetails?.length}
                                pagination
                                page={states.page}
                                pageSize={states.pageSize}
                                onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                                onPageSizeChange={(newPageSize) => { this.setState({ pageSize: newPageSize }) }}
                                rowsPerPageOptions={[10, 20, 30]}
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_expConfig_Div">
                            <div className='eMed_expConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_expConfig_rightHead_txt'>{t("ExpenseCategory")}</p>
                            </div>
                            <div className="eMed_expConfig_SubDiv">
                                {this.renderTextBox("ExpenseCategory", "expCategory")}
                                {this.renderTextBox("Description", "description")}
                            </div>
                            <Stack id='eMed_expConfig_rightBtnCon' direction="row" justifyContent={"center"} alignItems={"center"} spacing={2} sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <Button emed_tid ={"handleClear_TestID001"} variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                <Button emed_tid ={"handleAdd_TestID001"} disabled={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Expense Category", "SubTab")?.editAccess}
                                    variant='contained'
                                    size="small"
                                    id="eMed_Config_btn"
                                    onClick={() => { this.handleAdd() }}>{states.edit_id ? t("Update") : t("Add")}</Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={`${t("deleteMsg")}  ${states.dltExpense ? states.dltExpense : "-"} ?`}
                        DeleteNotify={t("effectEntireSetting")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(ExpenseCategory);
