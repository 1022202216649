import React, { Component } from 'react';
import { Autocomplete, Box, Button, Stack, Tooltip, Typography, TextField, Drawer } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import Loader from '../../../Components/Loader';
import { DateTime } from 'luxon';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';

class LabCensusReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            censusReportData: [],
            departmentData: [],
            groupData: [],
            testNameData: [],
            departmentName: null,
            groupName: null,
            testName: null,
            FilterOpen: false,
            total_test_count: 0,
            total_outsource: 0,
            total_in_house: 0,
            total_Inpatient: 0,
            total_Outpatient: 0,
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getDeptList()
        this.getGroupNameList()
        this.getTestNameList()
        this.getCensusReport()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    removeDuplicate(data) {
        let nameListJsonObject = data.map(JSON.stringify);
        let nameListUniqueSet = new Set(nameListJsonObject);
        let nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    getDeptList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_DEPT_LIST + "?dept_name=")
                .then((response) => {
                    if (response.data.status === 'success' && response?.data?.data?.length > 0) {
                        this.setState({
                            departmentData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getGroupNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET)
                .then((response) => {
                    if (response.data.status === 'success' && response?.data?.data?.length > 0) {
                        this.setState({
                            groupData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                        }, () => console.log(this.state.groupData))
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error?.message)
        }
    }

    getTestNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET+ "?only_test_name=true")
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            testNameData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getCensusReport = () => {
        try {
            let { departmentName, groupName, testName } = this.state;
            let dept_name = departmentName?.dept_name ? departmentName?.dept_name : "";
            let grp_name = groupName?.name ? groupName?.name : "";
            let test_name = testName?.test_name ? testName?.test_name : "";
            this.LoaderFunction(true);
            this.setState({disableBtn: true})
            RestAPIService.getAll(`${Serviceurls.LAB_CENSUS_REPORT}?dept_name=${dept_name}&grp_name=${grp_name}&test_name=${test_name}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        let Data = response?.data?.data;
                        this.LoaderFunction(false);
                        this.setState({
                            censusReportData: Data?.result?.length > 0 ? Data?.result : [],
                            total_test_count: Data?.total_test_count ? Data?.total_test_count : 0,
                            total_outsource: Data?.total_outsource ? Data?.total_outsource : 0,
                            total_in_house: Data?.total_in_house ? Data?.total_in_house : 0,
                            total_Inpatient: Data?.total_Inpatient ? Data?.total_Inpatient : 0,
                            total_Outpatient: Data?.total_Outpatient ? Data?.total_Outpatient : 0,
                            disableBtn: false,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({disableBtn: false})
        }
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }


    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    closeFilter = () => {
        this.setState({
            FilterOpen: false
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button   emed_tid='eMed_usrconf_btn__TestID' onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>

                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon={false}
                            options={this.state.departmentData}
                            
                            getOptionLabel={(option) => option?.dept_name}
                            value={this.state.departmentName}
                            onChange={(e, newValue) => {
                                this.setState({ departmentName: newValue })
                            }}
                            sx={{ width: "20vw" }}
                            renderInput={(params) => (<TextField {...params} size='small' label='Select Department' inputProps={{emed_tid:'Select_Department_TestID'}} />)}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon
                            options={this.state.groupData?.length > 0 ? this.state.groupData : []}
                            getOptionLabel={(option) => option?.name}
                            value={this.state.groupName ? this.state.groupName : null}
                            onChange={(e, newValue) => {
                                this.setState({ groupName: newValue })
                            }}
                            sx={{ width: '20vw' }}
                            renderInput={(params) => (<TextField {...params} label='Select Group Name' inputProps={{emed_tid:'Select_Group_Name_TestID'}}/>)}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon
                            options={this.state.testNameData?.length > 0 ? this.state.testNameData : []}
                            getOptionLabel={(option) => option?.test_name}
                            value={this.state?.testName ? this.state?.testName : null}
                            onChange={(e, newValue) => {
                                this.setState({ testName: newValue })
                            }}
                            sx={{ width: '20vw' }}
                            renderInput={(params) => (<TextField {...params} label='Select Test Name' inputProps={{emed_tid:'Select_Test_Name_TestID'}} />)}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small" emed_tid = "eMed_rts_btn_Test_ID"
                        onClick={() => {
                            this.setState({
                                departmentName: null,
                                groupName: null,
                                testName: null,
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                            },
                                () => this.getCensusReport())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid='Census_Report_FilterSearch_btn' disabled={this.state.disableBtn}
                        onClick={() => {
                            this.getCensusReport()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    render() {
        this.state.censusReportData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "dept_name", headerName: t("Department"), flex: 0.16,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.dept_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.dept_name} arrow><div>{params?.row?.dept_name.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.dept_name ? params?.row?.dept_name : "-"}</Box>)
            },
            {
                field: "grp_name", headerName: t("Group"), flex: 0.16,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.grp_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.grp_name} arrow><div>{params?.row?.grp_name.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.grp_name ? params?.row?.grp_name : "-"}</Box>)
            },
            {
                field: "test_name", headerName: t("Test Name"), flex: 0.16,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.test_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.test_name} arrow><div>{params?.row?.test_name.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.test_name ? params?.row?.test_name : "-"}</Box>)
            },
            {
                field: "ip_count", headerName: `${t("Inpatient")}`, flex: 0.10, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.ip_count ? params?.row?.ip_count : "0"}</Box>)
            },
            {
                field: "op_count", headerName: `${t("Outpatient")}`, flex: 0.10, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.op_count ? params?.row?.op_count : "0"}</Box>)
            },
            {
                field: "in_house", headerName: `${t("Inhouse")}`, flex: 0.10, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.in_house ? params?.row?.in_house : "0"}</Box>)
            },
            {
                field: "outsource", headerName: `${t("Outsource")}`, flex: 0.10, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.outsource ? params?.row?.outsource : "0"}</Box>)
            },
            {
                field: "test_count", headerName: `${t("Total")}`, flex: 0.10, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.test_count ? params?.row?.test_count : "0"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("Inpatient Count"), this.state.total_Inpatient, true)}
                        {AmountsCard(t("Outpatient Count"), this.state.total_Outpatient, true)}
                        {AmountsCard(t("Inhouse Count"), this.state.total_in_house, true)}
                        {AmountsCard(t("Outsource Count"), this.state.total_outsource, true)}
                        {AmountsCard(t("Total TestCount"), this.state.total_test_count, true)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' emed_tid='eMed_usrconf_btn_Test_ID' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.censusReportData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    emed_tid='FilterOpen_Test_ID'
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(LabCensusReport)
