import React, { Component } from 'react';
import './Config.css';
import {
    Button,
    Stack,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Autocomplete,
    IconButton,
    Tooltip,
    InputAdornment,
    Box
} from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { DeletePopup } from '../../../Components/Common Components/CommonComponents';
import Loader from '../../../Components/Loader';
import SearchIcon from '@mui/icons-material/Search';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';


class ConsumableMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 10,
            selectConsumables: null,
            consumableTableData: [],
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            selectGroupName: '',
            selectTestName: '',
            testQuantity: '',
            testUnit: '',
            testNameTableValue: [],
            testNameGet: [],
            testGroupGet: [],
            consumableList: [],
            selectedEditID: '',
            editTestID: '',
            editSetID: '',
            deletedPopup: false,
            deletedID: '',
            searchKey: '',
            selectedIndex: '',
            SearchText: "",
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getTestNameData()
        this.getTestGroupData()
        this.getConsumableDataList()
        this.getConsumableTableData()
    }
    //Commented for Changed the router Path

    // componentWillReceiveProps(props) {
    //     if (props.search_key != this.state.searchKey) {
    //         this.setState({
    //             searchKey: props.search_key
    //         }, () => {
    //             this.getConsumableTableData()
    //         })
    //     }
    // }

    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: "",
        })
      }

    getTestNameData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            testNameGet: response.data.data ? response.data.data : []
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    getTestGroupData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            testGroupGet: response.data.data ? response.data.data : []
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    getConsumableDataList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_CONSUMABLES_LIST_GET + '?search_key=')
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            consumableList: response.data.data ? response.data.data : []
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }
    getConsumableTableData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.LAB_CONSUMABLES_GET + "?search_key=" + this.state.searchKey)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            consumableTableData: response.data.data ? response.data.data : []
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch(e => {
                    this.LoaderFunction(false)
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.LoaderFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    postConsumablesMappingData = () => {
        try {
            var states = this.state
            var TestNameID = states.selectTestName ? states.selectTestName.id : null
            var TestName = states.selectTestName ? states.selectTestName.name ? states.selectTestName.name : states.selectTestName.test_name : null
            var TestGroupID = states.selectGroupName ? states.selectGroupName.id : null
            var TestGroup = states.selectGroupName ? states.selectGroupName.name : null
            var data = {
                "lab_set_id": TestGroupID,
                "lab_test_id": TestNameID,
                "set_name": TestGroup,
                "test_name": TestName,
                "consumable_data": states.testNameTableValue ? states.testNameTableValue : []
            }
            if (this.state.selectedEditID) {
                data['id'] = this.state.selectedEditID
            }
            this.setState({disableBtn: true})
            RestAPIService.create(data, Serviceurls.LAB_CONSUMABLES_GET)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        }, () => {
                            this.getConsumableTableData()
                            this.setState({
                                selectTestName: null,
                                selectGroupName: null,
                                testNameTableValue: [],
                                selectedEditID: '',
                                disableBtn: false,
                            })
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message,
                        disableBtn: false,
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message,
                disableBtn: false,
            })
        }
    }

    deleteConsumableMappingData = (ID) => {
        try {
            var data = {
                "id": ID
            }
            RestAPIService.delete(Serviceurls.LAB_CONSUMABLES_GET, data)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        }, () => {
                            this.getConsumableTableData()
                        })
                    }
                }).catch((e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                }))

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    editConsumableMappingData = (item, index) => {
        this.setState({
            selectedEditID: item.id,
            selectTestName: item.test_name,
            editTestID: item.lab_test_id,
            editSetID: item.lab_set_id,
            selectGroupName: item.set_name,
            testNameTableValue: item.consumables,
            selectedIndex: index
        })
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    textInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(value.length <= 150){
        this.setState({
            [name]: value
        })};
    };

    numInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value) && value.length <= 10) {
            this.setState({
                [name]: value,
            });
        }
    };

    renderTextBox = (label, width, value, name) => {
        return (
            <div>
                <TextField
                    disabled={name === "testUnit" ? true : false}
                    size={'small'}
                    sx={{ width: width }}
                    label={label + "*"}
                    rows={3}
                    name={name}
                    emed_tid ={`${name}`}
                    value={value}
                    variant="outlined"
                    onChange={name === 'testQuantity' ? this.numInputcheck : this.textInputcheck}
                />
            </div>
        )
    }

    renderLeftTable = () => {
        var states = this.state
        const { t } = this.props
        const filtereddata = this.state.consumableTableData.filter((data) => {
            return (
                (data.test_name === null ? null : data.test_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.consumables === null ? null : JSON.stringify(data.consumables).toLowerCase().includes(this.state.SearchText.toLowerCase()))
            );
        });
        return (
            <div>
                <Paper elevation={0}>
                <Box className="eMed_Search_Box_">
                        <TextField
                            variant='standard'
                            placeholder='Search'
                            value={this.state.SearchText}
                            onChange={(e) => { this.setState({ SearchText: e.target.value }) }}
                            inputProps={{ emed_tid : "eMed_Search_Box__TestID"}}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start"  emed_tid =  "eMed_SearchIcon__TestID" >
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{width:'15vw'}} />
                    </Box>
                    <TableContainer className='eMed_ConfigTable_Paper'>
                        <Table stickyHeader size='small'>
                            <TableHead sx={{ height: '7.3vh' }}>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='left' sx={{ color: Colors.HeadText }} >{t('SNo')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t("ServiceName")}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('Consumables')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('Action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {filtereddata.slice(states.page * states.rowsPerPage, states.page * states.rowsPerPage + states.rowsPerPage)
                                .map((data, index) => {
                                    var sno = (states.page * states.rowsPerPage) + (index + 1)
                                    var consumables = []
                                    const Datas = data.consumables
                                    Datas.forEach(element => {
                                        consumables.push((element.brand_name ? element.brand_name + ', ' : '-'))
                                    });
                                    return (
                                        <TableBody>
                                            <TableRow sx={{ bgcolor: this.state.selectedEditID && this.state.selectedIndex == index ? '#e2e2e2' : 'white' }}>
                                                <TableCell align='left'><div>{sno}</div></TableCell>
                                                <TableCell align='left'><div>{data.set_name ? data.set_name : data.test_name ? data.test_name : '-'}</div></TableCell>
                                                <TableCell align='left'>
                                                    {consumables.length > 15 ?
                                                        <Tooltip placement="top" title={consumables} arrow>
                                                            <div >{consumables ? consumables.slice(0, 15) + '...' : '-'}</div>
                                                        </Tooltip> : <div> {consumables ? consumables : '-'}</div   >}</TableCell>
                                                <TableCell align='center'>
                                                    <div className='eMed_TestConfig_Con'>
                                                        <IconButton size='small' emed_tid = "editConsumableMappingData_TestId"  disabled={!CheckAccessFunc("Laboratory", "Configuration", "Consumable Mapping",  null, "Tab")?.editAccess}>
                                                            <img src={CheckAccessFunc("Laboratory", "Configuration", "Consumable Mapping",  null, "Tab")?.editAccess ? ImagePaths.Edit.default : ImagePaths.DisabledEdit.default} className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                                                onClick={() => { this.editConsumableMappingData(data, index) }}
                                                            />
                                                        </IconButton>
                                                        <IconButton size='small' emed_tid = "TestConfig_DeleteIcon_TestId"  disabled={!CheckAccessFunc("Laboratory", "Configuration", "Consumable Mapping",  null, "Tab")?.editAccess}>
                                                            <img src={CheckAccessFunc("Laboratory", "Configuration", "Consumable Mapping",  null, "Tab")?.editAccess ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                                                onClick={() => {
                                                                    this.setState({
                                                                        deletedPopup: true,
                                                                        deletedID: data.id
                                                                    })
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                })
                            }
                        </Table>
                        {filtereddata.length === 0 ?
                            <div className='eMed_TestConfig_NoRecords'>
                                {t('NoRecordsFound')}
                            </div> : null}
                    </TableContainer>
                </Paper>
                <div className='eMed_TestConfig_LeftPageination'>
                    <TablePagination
                        component='div'
                        id="eMed_tbl_pagination"
                        rowsPerPageOptions={[10, 20, 30]}
                        count={filtereddata.length}
                        rowsPerPage={states.rowsPerPage}
                        page={states.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </div>
            </div>
        )
    }
    renderRightTable = () => {
        var { testNameTableValue } = this.state
        const { t } = this.props
        return (
            <div>
                <Paper elevation={0}>
                    <TableContainer className='eMed_ConfigRightTable_Paper' sx={{ width: '29vw' }}>
                        <Table stickyHeader size='small'>
                            <TableHead sx={{ flex: 1, }}>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='left'>{t('SNo')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left'>{t('Consumables')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left'>{t('Quantity')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left'>{t('Units')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left'>{t('Action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {testNameTableValue && testNameTableValue.length > 0 ? testNameTableValue.map((item, index) => {
                                return (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align='left'><div>{index + 1}</div></TableCell>
                                            <TableCell align='left'>
                                                {item.brand_name.length > 10 ?
                                                    <Tooltip placement="top" title={item.brand_name} arrow>
                                                        <div >{item.brand_name ? item.brand_name.slice(0, 10) + '...' : '-'}</div>
                                                    </Tooltip> : <div> {item.brand_name ? item.brand_name : '-'}</div>}</TableCell>
                                            <TableCell align='left'><div>{item.quantity ? item.quantity : '-'}</div></TableCell>
                                            <TableCell align='left'><div>{item.unit ? item.unit : '-'}</div></TableCell>
                                            <TableCell align='left'>
                                                <Tooltip title='Delete' placement='top' arrow>
                                                    <img className='eMed_TestConfig_DeleteIcon eMed_cursor' emed_tid = {"Delete__TestID"} role={"button"} src={ImagePaths.Delete.default} alt="Delete"
                                                        onClick={() => {
                                                            testNameTableValue.splice(index, 1)
                                                            this.setState({
                                                                testNameTableValue
                                                            })
                                                        }}
                                                    />
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )
                            }) : null}
                        </Table>
                        {testNameTableValue.length === 0 ?
                            <div className='eMed_TestConfig_NoRecords'>
                                {t("NoRecordsFound")}
                            </div> : null}
                    </TableContainer>
                </Paper>
            </div>
        )
    }

    clearValue = () => {
        this.setState({
            testNameTableValue: [],
            selectConsumables: null,
            testQuantity: '',
            testUnit: '',
            selectedEditID: '',
        })
    }

    validCheck = () => {
        const {t} = this.props
        var saveErrorMsg = ''
        var states = this.state
        if ((states.testNameTableValue).length == 0) {
            saveErrorMsg = t('PleaseAddConsumableDetails')
        }
        return saveErrorMsg
    }

    onSave = () => {
        var inValid = this.validCheck()
        if (inValid) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: inValid
            })
        } else {
            this.postConsumablesMappingData()
        }
    }

    duplicateCheck = () => {
        var { selectConsumables, testNameTableValue } = this.state
        var duplicate = false
        if (selectConsumables?.brand_name) {
            for (let i = 0; i < testNameTableValue.length; i++) {
                if (testNameTableValue[i].product_ms_id == selectConsumables.product_ms_id) {
                    duplicate = true
                }
            }
        }
        return duplicate
    }


    addTestName = () => {
        const {t} = this.props;
        var states = this.state
        var duplicate = this.duplicateCheck()
        if ((states.selectTestName || states.selectGroupName) && states.selectConsumables &&
            states.testQuantity &&
            states.testUnit && !duplicate) {
                states['testNameTableValue'].push({
                    "product_ms_id": states.selectConsumables.product_ms_id,
                    "brand_name": states.selectConsumables.product_name,
                    "quantity": states.testQuantity,
                    "unit": states.testUnit,
                })
                this.setState({
                    states
                }, () => {
                    this.setState({
                        selectConsumables: null,
                        testQuantity: '',
                        testUnit: ''
                    })
                })
        } else if (duplicate) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t('ConsumablesExist')
            })
        } else {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t('Fillmandatory')
            })
        }
    }

    PopupClose = () => {
        this.setState({
            deletedPopup: false
        })
    }
    removeDataList = () => {
        this.deleteConsumableMappingData(this.state.deletedID)
        this.setState({
            deletedPopup: false
        })
    }
    render() {
        const { t } = this.props
        return (
            <div className='eMed_TestConfig_MainContainer'>
                <div className='eMed_TestConfig_Con'>
                    <div className='eMed_TestConfig_leftSide'>
                        {this.renderLeftTable()}
                    </div>
                    <div className='eMed_TestConfig_RightSide'>
                        <div className='eMed_TestConfig_RightHead'>
                            <p className='eMed_Testconfig_RightHead_text'>{t('ConsumableMapping')}</p>
                        </div>
                        <div className='eMed_TestConfig_RightContentBox'>
                            <div className='eMed_TestConfig_RightMethod'>
                                <Autocomplete
                                    disabled={this.state.selectGroupName && this.state.selectGroupName || this.state.selectedEditID ? true : false}
                                    options={this.state.testNameGet}
                                    getOptionLabel={(options) => typeof (options) === "string" ? options : options.test_name}
                                    onChange={(e, value) => {

                                        this.setState({
                                            selectTestName: value,
                                        })
                                    }}
                                    value={this.state.selectTestName}
                                    id="combo-box-demo"
                                    sx={{ width: "29vw" }}
                                    renderInput={(params) => <TextField {...params} label={t("TestName")+ "*"} size='small'   emed_tid = "TestName_Test_ID001"  />}
                                />
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                <Autocomplete
                                    disabled={this.state.selectTestName && this.state.selectTestName || this.state.selectedEditID ? true : false}
                                    options={this.state.testGroupGet}
                                    getOptionLabel={(options) => typeof (options) === "string" ? options : options.name}
                                    onChange={(e, value) => {

                                        this.setState({
                                            selectGroupName: value
                                        })
                                    }}
                                    value={this.state.selectGroupName}
                                    id="combo-box-demo"
                                    sx={{ width: "29vw" }}
                                    renderInput={(params) => <TextField {...params} label={t("TestGroup") + "*" }  emed_tid = "TestGroup_Test_ID001" size='small' />}
                                />
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                <Autocomplete
                                    options={this.state.consumableList}
                                    getOptionLabel={(options) => options.product_name}
                                    onChange={(e, value) => {

                                        this.setState({
                                            testUnit: value ? value.unit : value,
                                            selectConsumables: value
                                        })
                                    }}
                                    value={this.state.selectConsumables}
                                    id="combo-box-demo"
                                    sx={{ width: "29vw" }}
                                    renderInput={(params) => <TextField {...params} label={t("Consumables") + "*"}  emed_tid = "Consumables_Test_ID002" size='small' />}
                                />
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("Quantity"), '10vw', this.state.testQuantity, 'testQuantity')}
                                {this.renderTextBox(t("Unit"), '10vw', this.state.testUnit, 'testUnit')}
                                <Button  emed_tid = "addTestName_TestID" id='eMed_Btn_Text' variant='contained' sx={{ width: '5vw' }}
                                    onClick={() => { this.addTestName() }}
                                >{t('Add')}</Button>
                            </div>
                            <div className='eMed_TestConfig_Right_SideTable'>
                                {this.renderRightTable()}
                            </div>
                        </div>
                        <div className='eMed_TestConfig_RightBtnCon'>
                            <Stack spacing={2} direction="row">
                                <Button id='eMed_Btn_Text' emed_tid='ConsumableMap_Config_clearValue' variant='outlined' onClick={() => { this.clearValue() }}>{t("Clear")}</Button>
                                <Button id='eMed_Btn_Text' emed_tid='ConsumableMap_Config_Save_Btn' disabled={this.state.disableBtn ? true : !CheckAccessFunc("Laboratory", "Configuration", "Consumable Mapping",  null, "Tab")?.editAccess}  variant='contained' onClick={() => { this.onSave() }}>{t("Save")}</Button>
                            </Stack>
                        </div>
                    </div>
                </div>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={t("ConfirmRemovalConsumableMap")}
                        DeleteNotify={t("effectEntireConfig")}
                        DeletContent={t("linkedConsumableMap")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
                      <Loader loaderOpen={this.state.isLoader} />
            </div>
        );
    }
}

export default withTranslation()(ConsumableMap);