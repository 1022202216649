import React from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Colors } from "../Styles/Colors";
import { Box, Typography } from "@mui/material";
import { ImagePaths } from "../Utility/ImagePaths";

class BillingTransaction extends React.Component {

    render() {
        return (
            <div>
                <Backdrop
                    sx={{ color: Colors.ThemeColor, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.props.loaderOpen}
                    onClick={this.dropHandleClose}
                >
                    <Box display={'flex'} flexDirection={'column'} gap={'1vh'}>
                    {this.props.forPrint ? <img src={ImagePaths.PrintAnimation.default}/> : <CircularProgress color="inherit" />}
                    {this.props.label ? <Typography fontWeight={'600'} color={"whitesmoke"} align="center">{this.props.label}</Typography> : null}
                    </Box>
                </Backdrop>
            </div>
        )
    }
}

export default BillingTransaction;