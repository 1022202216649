import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import {
    Box, Button, Chip, CircularProgress, Typography, MenuItem, Divider, List,
    ListItem, ListItemButton, ListItemText, FormControl, InputLabel, Select
} from '@mui/material'
import { Colors } from '../../../../Styles/Colors'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { AddNewListItemPop, DeletePopup, ReasonPopup } from "../../../../Components/Common Components/CommonComponents"
import ShowComponents from './ShowComponent';

export default class DocPatientCategory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clinicId: this.props.clinicId,
            appointmentId: this.props.appointmentId,
            patientId: this.props.patientId,
            selectedPatientCategory: '',
            patientCategoryData: [],
            categoryList: [],
            listLoad: false,
            categoryName: '',
            selectedCategory: "",
            selectedCategoryId: null,
            dataLoad: false,
            isLocked: false,
            categorySlct: false,
            categorySlctId: null,
            AddnewCategory: false,
            dltCategoryClked: false,
            deletePopup: false,
            removeData: false,
            selectedId: null
        }
    }

    componentDidMount() {
        // let clinicId = getCachevalue('SelectedDoctorClinic')
        // let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
        // this.setState({
        //     clinicId: clinicId,
        //     appointmentId: appointmentId?.appointment_id,
        //     patientId: appointmentId?.patient_id,
        //     isLocked: appointmentId?.is_locked
        // }, () => {
        //     this.getPatientCategoryData()
        // })
        // this.apiCalls();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    apiCalls = () => {
        this.getPatientCategoryData()
        this.getPatientCategoryList()
    }

    getPatientCategoryList = () => {
        try {
            this.setState({ listLoad: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY_WISE)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            categoryList: response.data.data,
                            listLoad: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ listLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ listLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ listLoad: false })
            this.errorMessage(error.message)
        }
    }

    getPatientCategoryData = () => {
        try {
            let appointmentId = this.props.prevAppointmentId ? this.props.prevAppointmentId : this.props.appointmentId
            this.setState({ dataLoad: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY + `?patient_id=${this.props.patientId}&appointment_id=${appointmentId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            patientCategoryData: response.data.data,
                            dataLoad: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ dataLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ dataLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ dataLoad: false })
            this.errorMessage(error.message)
        }
    }

    postPatientCategoryList = (newCategory) => {
        try {
            let data = {
                doctor_category: newCategory
            }
            this.setState({ loading: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY_WISE)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.ClosePopUp();
                        this.onClearHandler()
                        this.getPatientCategoryList()
                        this.setState({ loading: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    postPatientCategoryData = () => {
        try {
            let data = {
                appointment_id: this.props.appointmentId,
                category_id: this.state.selectedCategoryId,
                patient_category: this.state.selectedCategory,
                patient_id: this.props.patientId
            }
            if (this.state.selectedCategory === "") {
                this.errorMessage('Select category to add')
            } else {
                this.setState({ loading: true })
                RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.onClearHandler()
                            this.getPatientCategoryData()
                            this.setState({ loading: false, categorySlct: false })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ loading: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ loading: false })
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    detetePatientCategory = () => {
        try {
            let data = {}
            this.setState({ removeData: true })
            RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY + '?id=' + this.state.selectedId, data)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.getPatientCategoryData()
                        this.setState({ deletePopup: false, removeData: false, selectedId: null })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ removeData: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ removeData: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    DltCategoryListItem = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.deleteAll(`${Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY_WISE}?id=${this.state.selectedCategoryId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.ClosePopUp();
                        this.onClearHandler()
                        this.getPatientCategoryList()
                        this.setState({ loading: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    popupClose = () => {
        try {
            this.setState({
                deletePopup: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearHandler = () => {
        try {
            this.setState({
                selectedCategory: '',
                selectedCategoryId: null
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    ClosePopUp() {
        this.setState({
            AddnewCategory: false,
            dltCategoryClked: false,
            categorySlct: false
        })
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'}>
                    <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                        <Typography fontWeight={600} fontSize={'0.8vw'}>Patient Category</Typography>
                    </Box>
                    <Box component={'div'} sx={{ backgroundColor: Colors.white }} p={'0.5vw'} borderBottom={'1px solid lightgray'}>
                        <Box component={'div'} display={'flex'} alignItems={'center'} >
                            <FormControl size='small' sx={{ width: '20vw', mr: '1vw' }} emed_tid='cat_list'>
                                <InputLabel>{"Category"}</InputLabel>
                                <Select
                                    MenuProps={{ style: { maxHeight: 350 } }}
                                    value={this.state.selectedCategory}
                                    label={"Category"}
                                    disabled={this.state.isLocked}
                                    onChange={(e) => {
                                        this.setState({ selectedCategory: e.target.value }, () => {
                                            if (this.state.selectedCategory && this.state.selectedCategoryId) {
                                                this.setState({
                                                    categorySlct: true,
                                                    categorySlctId: this.state.categoryList.filter(list => list['id'] === this.state.selectedCategoryId)
                                                })
                                            } else {
                                                this.setState({
                                                    categorySlct: false
                                                })
                                            }
                                        })
                                    }}
                                >
                                    {
                                        this.state.categoryList?.length > 0 ?
                                            this.state.categoryList.map((list, index) => (
                                                <MenuItem key={index} value={list.category} onClick={() => {
                                                    this.setState({ selectedCategoryId: list.id })
                                                }}>{list.category}</MenuItem>
                                            )) : "No Data Found..."
                                    }
                                    <Divider />
                                    <List sx={{ padding: '0vw', maxHeight: '15vw' }}>
                                        <ListItem disablePadding>
                                            <ListItemButton emed_tid='new_cat_add_btn' onClick={() => { this.setState({ AddnewCategory: true }) }}>
                                                <ListItemText primary="Add New Patient Category" />
                                            </ListItemButton>
                                        </ListItem>
                                        {
                                            this.state.categorySlct ?
                                                <ListItem disablePadding>
                                                    <ListItemButton emed_tid='cat_del_btn' onClick={() => { this.setState({ dltCategoryClked: true }) }}>
                                                        <ListItemText primary="Delete" />
                                                    </ListItemButton>
                                                </ListItem> : null
                                        }
                                    </List>
                                </Select>
                            </FormControl>

                            <Button emed_tid='add_cat_btn'
                                disabled={this.state.loading || this.state.isLocked || this.props.isLocked}
                                variant='contained'
                                sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                                onClick={() => this.postPatientCategoryData()}
                            >Add</Button>
                        </Box>
                    </Box>
                    <Box component={'div'}>
                        <Box component={'div'} height={'2.5vw'} pl={'0.5vw'} display={'flex'} alignItems={'center'} sx={{ backgroundColor: Colors.Background }}>
                            <Typography fontSize={'0.8vw'} fontWeight={600} color={Colors.SecondaryText}>Added Patient Categories</Typography>
                        </Box>
                        <Box component={'div'} display={'flex'} flexWrap={'wrap'} sx={{ backgroundColor: Colors.white }}>
                            {this.state.listLoad ? <CircularProgress size={'small'} color='primary' /> :
                                this.state.patientCategoryData?.length > 0 && this.state.patientCategoryData?.map((item, index) => (
                                    <Chip
                                        className='eMed_chip eMed_Chip_Border'
                                        size="small"
                                        label={item.category}
                                        key={index}
                                        variant='outlined'
                                        disabled={this.props.isLocked}
                                        onDelete={() => this.setState({ deletePopup: true, selectedId: item?.id })}
                                        emed_tid='del_cat_btn'
                                    />
                                ))}
                        </Box>
                    </Box>
                    {
                        this.state.AddnewCategory ?
                            <AddNewListItemPop
                                title={"Patient Category"}
                                ClosePopUp={this.ClosePopUp.bind(this)}
                                sendNewData={this.postPatientCategoryList.bind(this)} />
                            : null
                    }

                    {this.state.dltCategoryClked ? <ReasonPopup
                        title={`Are you sure? Do you want to Delete the ${this.state.selectedCategory}`}
                        AlertPopupClose={this.ClosePopUp.bind(this)}
                        label={"Enter the Reason"}
                        btntext={'Cancel'}
                        btnvarient={'outlined'}
                        btncolor={'error'}
                        btntext1={'Confirm'}
                        btnvarient1={'contained'}
                        sendCmt={this.DltCategoryListItem.bind(this)}
                    /> : null}

                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.deletePopup ? <DeletePopup
                        DeletTitle={`Are you sure you want to delete ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.detetePatientCategory.bind(this)}
                        disable={this.state.removeData}
                    /> : null}
                </Box>
            </ShowComponents>
        )
    }
}
