import React, { Component } from "react";
import '../Configuration.css';
import { Box, Paper, TextField, Button, Stack, Grid, Tooltip } from "@mui/material";
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup } from "../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { Colors } from "../../../Styles/Colors";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
class TagConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color :'#000000',
            tagDetails: [],
            selectedItem: {},
            deletedPopup: false,
            deleteId: null,
            tag_name: "",
            isEdit: false,
            current_page: 0,
            rowsPerPage: 10,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ""
        }
    }

    componentDidMount() {
        this._getTagDetails();
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    _getTagDetails = () => {
        this.setState({
            tagDetails: [],
            current_page: 0,
            rowsPerPage: 10
        })
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPPATIENTTAG)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            tagDetails: response.data.data,
                        },()=>{this.LoaderFunction(false)});
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false);
        }
    }
    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }

     renderTextBox(label, statekey, value, width) {
        const { t } = this.props;
        let { selectedItem } = this.state;
        return (
            <div id="eMed_srvConfig_divs">
                <TextField
                    sx={{ width: width ? width : "20vw" }}
                    autoComplete="off"
                    label={t(label)}
                    inputProps={{ emed_tid: `cb_${label}` }}
                    name={statekey}
                    value={value ? value : ""}
                    required
                    size="small"
                    onChange={(event) => {
                        let value = event.target.value.trimStart();
                        let isValid = CommonValidation.alphabetOnly(value)
                        if (value === "" || isValid && value.length <= 50) {
                            selectedItem[event.target.name] = value;
                        }
                        this.setState({
                            selectedItem
                        })
                    }}
                />
            </div>
        )
    }
    handleChangeColor = (event)=>{
        this.setState({
            color :event.target.value
        })      
    }

    handleEdit = (item) => {
        let states = this.state
        states["selectedItem"] = JSON.parse(JSON.stringify(item));
        states.color = JSON.parse(JSON.stringify(item.patient_tag_color))
        this.setState({
            states,
            isEdit: true
        })
    }

    handleDelete = (value) => {
     if(!this.state.isEdit){
        this.setState({
            deletedPopup: true,
            deleteId: value.id,
            tag_name: value.patient_tag_name
        })
    }}

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    PopupClose = () => {
        this.setState({
            deletedPopup: false,
            deleteId: null,
            tag_name: ""
        })
    }

    removeDataList = () => {
        try {
            RestAPIService.delete(`${Serviceurls.FO_CONFIG_OPPATIENTTAG}?tag_id=${this.state.deleteId ? this.state.deleteId : 0}`)
                .then(response => {
                    if (response.data.status === "successs") {
                        this.setState({
                            deletedPopup: false,
                            deleteId: null,
                            tag_name: ""
                        }, () => {
                            this.responseSuccessErrorMessage(response.data.message, true);
                            this._getTagDetails();
                        })
                    }
                else{
                    this.responseSuccessErrorMessage(response.data.message)
                        }
                    
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }
    handleClear = () => {
        this.setState({
            selectedItem: {},
            isEdit: false,
             color :'#000000',
        })
    }
    handleSave = () => {
        const { t } = this.props
        let { selectedItem, tagDetails ,color,isEdit} = this.state;       
        let isAvl = true;
        let Message = "";

 
        if (!selectedItem.patient_tag_name) { isAvl = false; Message = t("Fillmandatory") }
           let existvalue = tagDetails?.find(item => (item["patient_tag_name"]?.toLowerCase() === selectedItem["patient_tag_name"]?.toLowerCase() && item["id"] !== selectedItem["id"]));  
           if(existvalue  && isEdit){
            isAvl = false;
             Message = `${t("PatientTag")} ${t("AlreadyExist")}`;
            this.setState({
                selectedItem: {},
                color :'#000000'
            })
           }   
    
        if (isAvl) {
            selectedItem["tag_id"] = selectedItem["id"];
            delete selectedItem.id;
            
            // api call
            const trimmedTagName = selectedItem.patient_tag_name.trim();
            selectedItem["patient_tag_name"] = trimmedTagName;
            selectedItem["patient_tag_color"] = color
            let datas = JSON.parse(JSON.stringify(selectedItem))         
            try {
                this.LoaderFunction(true)
                this.setState({disableBtn: true})
                RestAPIService.create(datas, Serviceurls.FO_CONFIG_OPPATIENTTAG)
                    .then(response => {
                        if (response.data.status === "successs") {
                            this.handleClear();
                            this._getTagDetails();
                            this.LoaderFunction(false)
                            this.responseSuccessErrorMessage(response.data.message, true);
                            this.setState({disableBtn: false})
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.setState({disableBtn: false})
                            this.responseSuccessErrorMessage(error.response.data.message, false);
                        }
                    });
            } catch (e) {
                this.responseSuccessErrorMessage(e.message, false);
                this.setState({disableBtn: false})
            }
        } else {
            this.responseSuccessErrorMessage(Message, false)
        }
    }
    msgClose(){
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
      }
      handleChangeComplete=(e)=>{
           let requiredColor = e.target.value
          this.setState({color :  requiredColor})
      }

    render() {
        const { t } = this.props;
        let { rowsPerPage, current_page, tagDetails, selectedItem } = this.state;
        const columns = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.15, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            { field: 'patient_tag_name', headerName: t('PatientTag'), flex: 0.50 ,},
            { field : 'patient_tag_color', headerName: t("Patient Tag Color"), flex :0.15, renderCell: (params) => (
                <div className="emed_ColorShowgrid" type="color" name="favourite Color"  style={{ backgroundColor: params.row.patient_tag_color ? params.row.patient_tag_color : "#000000" , width :"2vw" , height : "1.5vw"}} > </div>
              )},
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.20, sortable: false, hideable: false, align: "center", renderCell: (cellvalues) => {
                     let emergy = cellvalues?.row?.patient_tag_name === "Emergency" ? true : false
                    return (
                        
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                            <CommonEditButton disable={(!CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Tag", "SubTab")?.editAccess)|| emergy} size="1.5vw" onClick={this.handleEdit.bind(this, cellvalues.row)} />
                            <CommonDeleteButton disable={(!CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Tag", "SubTab")?.editAccess) || emergy } size="1.5vw" onClick={this.handleDelete.bind(this, cellvalues.row)} />
                        </Stack>
                    )
                }
            }
        ]
        return (
            <Box  id="eMed_srvConfig_Container" sx={{backgroundColor: Colors.configHomeBg}}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_srvConfig_Div" >
                            <DataGrid
                                rows={tagDetails}
                                columns={columns}
                                getRowId={(row) => row.id}
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: CommonGridHeader.CommonToolBar,
                                    NoRowsOverlay: () => {
                                        return (
                                            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }
                                }}
                                headerHeight = {40}
                                rowCount={tagDetails.length}
                                page={current_page}
                                onPageChange={(newPage) => this.setState({ current_page: newPage })}
                                pageSize={rowsPerPage}
                                onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                                rowsPerPageOptions={[10, 20, 30]}
                                pagination
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_srvConfig_Div">
                            <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_srvConfig_rightHead_txt'>{t('PatientTag')}</p>
                            </div>
                            <div className="eMed_srvConfig_subDiv" style={{display: "flex",flexDirection: "row",  height: "57vh",overflow: "scroll"}}>
                                {this.renderTextBox('PatientTag', "patient_tag_name", selectedItem.patient_tag_name)}
                                <div className="emed_ColorShow" type="color" name="favourite Color" value ={this.state.color} style={{ backgroundColor: this.state.color}}    > </div>   
                            </div>                        
                            <div className="eMed_Colour_text">Select Your color</div>
                            <div className="eMed_Colour_Container">
                                <button className="eMed_Colorchoser" type="button" id="favourite Color" name="favourite Color"   style={{ backgroundColor: "#000000" }} onClick={(e)=>{this.setState({color : "#000000"})}}></button>
                                <button className="eMed_Colorchoser" type="button" id="favourite Color" name="favourite Color"   style={{ backgroundColor: "#ffcab5" }} onClick={(e)=>{this.setState({color : "#ffcab5"})}}></button>
                                <button className="eMed_Colorchoser" type="button" id="favourite Color" name="favourite Color"   style={{ backgroundColor: "#caffbf" }}  onClick={(e)=>{this.setState({color :  "#caffbf"})}} ></button>
                                <button className="eMed_Colorchoser" type="button" id="favourite Color" name="favourite Color" style={{ backgroundColor: "#d3dbfd" }} onClick={(e) => { this.setState({ color: "#d3dbfd" }) }} ></button>
                                <Tooltip title={"select Color"} placement={'top'}
                                 PopperProps={{
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -14],  
                                            },
                                        },
                                    ],
                                }}
                                >
                                    <div>
                                        <input className="favouriteColor" type="color" name="favourite Color" value={this.state.color} onChange={(event) => { this.handleChangeColor(event) }}></input>
                                    </div>
                                </Tooltip>
                            </div>                         
                            <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg , marginTop : "45vh"  }} alignItems="center" justifyContent="center">
                                <Button emed_tid ={"handleClear_TestID"} variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                <Button disabled={this.state.disableBtn ? true : !CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Tag", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" emed_tid="Tag_Save_Btn" onClick={() => { this.handleSave() }}>{t("Save")}</Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={`${t("deleteMsg")}  ${this.state.tag_name ? this.state.tag_name : "-"} ?`}
                        DeleteNotify={t("effectEntireSetting")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
            </Box>
        )
    }
}
export default withTranslation()(TagConfig);