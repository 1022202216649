import React, { Component } from 'react'
import { CommonDeleteButton, CommonEditButton, CommonPopUp, DeletePopup } from '../../../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { Box, IconButton, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import RestAPIService from '../../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Colors } from '../../../../../../Styles/Colors';
import { CheckAccessFunc } from '../../../../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../../../../Utility/Services/CacheProviderService';
import { DateTime } from 'luxon';

class ExaminationLeft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ExaminationData: [],
      isDeleteCliked: false,
      SelectedList: {},
      IpPatientData: this.props?.IpPatientData,
      isHistoryClicked: false,
      SelectedHistory: [],
      withOutAppointment : this.props?.withOutAppointment,
    }
  }

  componentDidMount() {
    // this.GetExaminationData()
  }
  componentDidUpdate(prevprops) {
    if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetExaminationData() }
    if (prevprops.IpPatientData !== this.props.IpPatientData) { this.setState({ IpPatientData: this.props.IpPatientData }, () => { this.GetExaminationData() }) }
    if (prevprops.withOutAppointment?.patient_id !== this.props.withOutAppointment?.patient_id) { this.setState({ withOutAppointment: this.props.withOutAppointment }, () => { this.GetExaminationData() }) }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  GetExaminationData() {
    let admission_id =   this.state.IpPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
    const {from_date, to_date, doctorIds} = this.props;
    let url = Serviceurls.IP_DOCNOTES_EXAMINATION + "?ip_admission_id=" + admission_id +
      "&from_date=" + (from_date ? from_date : null) +
      "&to_date=" + (to_date ? to_date : null);
    if (doctorIds?.length > 0) {
      url = `${url}&doctor_id=${doctorIds}`
    }
    try {
      RestAPIService.getAll(url).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              ExaminationData: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    } finally {
      this.props.onApiTrigger();
    }
  }

  onEditHandler(data) {
    this.props.EditExaminationsData(data.row)
  }
  onDeleteHandler(data) {
    try {
      this.setState({
        isDeleteCliked: true,
        SelectedList: data.row,
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ isDeleteCliked: false })
  }
  DeleteExamination() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.IP_DOCNOTES_EXAMINATION + "?exam_id=" + states.SelectedList.id).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.setState({ isDeleteCliked: false }, () => { this.GetExaminationData() })
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }


  GetHistoryData = (id) => {
    try {
      let url = Serviceurls.IP_CLINICNOTES_HISTORY + "?condition=examination" + "&row_id=" + id;
      if (this.props.clinic_id) {
        url = `${url}&clinic_id=${this.props.clinic_id}`
      }
      RestAPIService.getAll(url).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SelectedHistory: response.data.data,
              isHistoryClicked: true,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  MenuItem = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "History", label: t("History") },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
          disabled = {this.state.withOutAppointment?.is_ip}
          emed_tid = {"More_testID_02"}
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            <MenuItem  emed_tid = {"GetHistoryData_testID_02"} onClick={() => { this.setState({ anchorEl: null }, () => { this.GetHistoryData(data?.row?.id) }) }}>
              {t("History")}
            </MenuItem>
          </Menu> : null}
      </div>
    )
  }
  historyPopUpClose() {
    this.setState({
      isHistoryClicked: false,
      SelectedHistory: []
    })
  }

  _getHistory(historydata) {
    historydata.forEach((element, index) => { element.sno = index + 1 })
    const { t } = this.props;
    let { anchorEl2 } = this.state;
    let open = Boolean(anchorEl2);
    const historyColumn = [
      { field: "sno", width: 70, headerName: t("SNo") },
      {
        field: "modified_by_to", width: 200, headerName: t("User"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_by_to ? params?.row?.modified_by_to : "-"}</Box>)
      },
      {
        field: "modified_date", width: 200, headerName: t("ModifiedDate"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_date ? params?.row?.modified_date : "-"}</Box>)
      },
      {
        field: "exam_name_from", width: 200, headerName: t("PreviousExamination"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.exam_name_from ? params?.row?.exam_name_from?.length > 18 ?
            <Tooltip placement='top' title={params?.row?.exam_name_from} arrow>
              <div>{params?.row?.exam_name_from.slice(0, 18) + "..."}</div></Tooltip> :
            params?.row?.exam_name_from : "-"}
        </Box>)
      },
      {
        field: "exam_name_to", width: 200, headerName: t("ChangedExamination"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.exam_name_to ? params?.row?.exam_name_to?.length > 18 ?
            <Tooltip placement='top' title={params?.row?.exam_name_to} arrow>
              <div>{params?.row?.exam_name_to.slice(0, 18) + "..."}</div></Tooltip> :
            params?.row?.exam_name_to : "-"}
        </Box>)
      },
      {
        field: "description_to", width: 200, headerName: t("PreviousDescription"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.description_to ? params?.row?.description_to?.length > 18 ?
            <Tooltip placement='top' title={params?.row?.description_to} arrow>
              <div>{params?.row?.description_to.slice(0, 18) + "..."}</div></Tooltip> :
            params?.row?.description_to : "-"}
        </Box>)
      },
      {
        field: "description_from", width: 200, headerName: t("ChangedDescription"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.description_from ? params?.row?.description_from?.length > 18 ?
            <Tooltip placement='top' title={params?.row?.description_from} arrow>
              <div>{params?.row?.description_from.slice(0, 18) + "..."}</div></Tooltip> :
            params?.row?.description_from : "-"}
        </Box>)
      },
    ];
    return (
      <Box id="eMed_ipsrvConfig_accTbl">
        <DataGrid
          rows={historydata}
          columns={historyColumn}
          getRowId={(row) => row.sno}
          disableColumnMenu
          hideFooter
          components={{
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          headerHeight={40}
          disableSelectionOnClick
        />
      </Box>
    )
  }
  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  formatDateTo12hr = (dateStr) => {
    if (!dateStr) return "";
    const dateTime = DateTime.fromISO(dateStr);
    return dateTime.toFormat('yyyy-MM-dd hh:mm:ss a');
  }

  render() {
    try {
      const { t } = this.props;
      const checkuserAccess =  CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Doctor Notes", "SubTab")?.editAccess ?
          this.state.withOutAppointment?.is_ip : true;
      let myUser = JSON.parse(localGetItem("loggedInUserInfo"));
      this.state.ExaminationData.forEach((element, index) => { element.sno = index + 1 })
      const columns = [
        {
          field: "examination_date", flex: 0.15, headerName: t("Date"),
          renderCell: (params) => (
            <div>
              {params?.row?.doctor_name?.length > 10 ?
                <Tooltip title={params?.row?.doctor_name} placement='top' arrow>
                  <div className='eMed_DocNotes_Txt'>{params?.row?.doctor_name ? params.row.doctor_name.slice(0, 10) + '...' : '-'}</div>
                </Tooltip> : <div className='eMed_DocNotes_Txt'>{params?.row?.doctor_name ? params.row.doctor_name : '-'}</div>}
              {params?.row?.examination_date?.length > 10 ?
                <Tooltip title={this.formatDateTo12hr(params?.row?.examination_date || "")} placement='top' arrow>
                  <div className='eMed_DocNotes_DateTxt'>{params?.row?.examination_date ? this.formatDateTo12hr(params.row.examination_date).slice(0, 10) + '...' : '-'}</div>
                </Tooltip> : <div className='eMed_DocNotes_DateTxt'>{params?.row?.examination_date ? this.formatDateTo12hr(params.row.examination_date) : '-'}</div>}
            </div>
          )
        },
        {
          field: "exam_name", flex: 0.16, headerName: t("Name"),
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.exam_name ? params?.row?.exam_name?.length > 18 ?
              <Tooltip placement='top' title={params?.row?.exam_name} arrow>
                <div>{params?.row?.exam_name.slice(0, 18) + "..."}</div></Tooltip> :
              params?.row?.exam_name : "-"}
          </Box>)
        },
        {
          field: "description", flex: 0.18, headerName: t("Description"),
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.description ? params?.row?.description?.length > 18 ?
              <Tooltip placement='top' title={params?.row?.description} arrow>
                <div>{params?.row?.description.slice(0, 18) + "..."}</div></Tooltip> :
              params?.row?.description : "-"}
          </Box>)
        },
        {
          field: "created_by", flex: 0.14, headerName: t("CreatedBy"),
          renderCell: (params) => (<Box component={'div'}>{params?.row?.created_by ? params?.row?.created_by : "-"}</Box>)
        },
        {
          field: "modified_by", flex: 0.14, headerName: t("UpdatedBy"),
          renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_by ? params?.row?.modified_by : "-"}</Box>)
        },
        {
          field: "action", flex: 0.14, headerName: t("Action"), headerAlign: "center", align: "center",
          renderCell: (params) => {
            const isDocCanEdit = this.props.is_From_IpNotes ? !(myUser?.doctor_id === params.row.doctor_id) : checkuserAccess;
            return(<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <CommonEditButton disable={isDocCanEdit} size={"1.5vw"} onClick={this.onEditHandler.bind(this, params)} />
            <CommonDeleteButton disable={isDocCanEdit} size={"1.5vw"} onClick={this.onDeleteHandler.bind(this, params)} />
            {this.MenuItem(params)}
          </Box>)}
        }
      ]
      return (
        <Box height={"35vh"}>
          <DataGrid
            rows={this.state.ExaminationData}
            columns={columns}
            getRowId={(row) => row['sno']}
            headerHeight={40}
            hideFooter={true}
            components={{
              NoRowsOverlay: () => (
                <Stack className='eMed_DocNotes_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            disableSelectionOnClick
          />
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.isDeleteCliked ? <DeletePopup
            DeletTitle={`${t("deleteMsg")}  ${t("Examination")} ${this.state.SelectedList ? this.state.SelectedList?.exam_name : "-"} ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.DeleteExamination.bind(this)}
          // DeleteNotify={t("effectEntireSetting")}
          /> : null}
          {(this.state.isHistoryClicked) ?
            <CommonPopUp width={"75vw"}
              backgroundColor={Colors.white}
              popUpClose={this.historyPopUpClose.bind(this)}
              title={t("History")}
              component={this._getHistory.bind(this, this.state.SelectedHistory)} />
            : null
          }
        </Box>
      )
    }
    catch (e) {

    }
  }
}
export default withTranslation()(ExaminationLeft)
