import { Box, Button, Divider, IconButton, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material"
import { ImagePaths } from "../../../Utility/ImagePaths"
import React,{ Component } from "react"
import RestAPIService from "../../../Utility/Services/RestAPIService"
import { Serviceurls } from "../../../Utility/API/Serviceurls"
import { localGetItem } from "../../../Utility/Services/CacheProviderService"
import ToastMsg from "../../../Components/ToastMsg/ToastMsg"
import { DateTime } from "luxon"
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation"
import Loader from '../../../Components/Loader';

export class PharmaBranchCommonPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            pharmacyId: null,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText:'',
            isBtnClick: false,
            Cancel_cmt: ''
        }
    }

    componentDidMount(){
        if (this.props.RowId) {
            let subLocationDetails = localGetItem("loggedInUserInfo")
            let detail = JSON.parse(subLocationDetails)
            this.setState({
                pharmacyId: detail?.pharmacy_id,
            }, () => {
                this.getProductData(this.props.RowId)
            })
        }

    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    getProductData = (ID) => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.PHA_BRANCH_LINE_OF_ITEM + "?request_id=" + ID + "&pharmacy_id=" + this.state.pharmacyId).
                then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false)
                      var Data = response?.data?.data ? response.data.data : []
                        this.setState({
                            tableData: Data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    numberValidation(num) {
        return /^\d+$/.test(num);
    }

    inputChange = (event, Index) => {
        var name = event.target.name
        var value = event.target.value
        let isNum = CommonValidation.numberOnly(value)
        var {tableData} = this.state

        if(((name === "reorder_threshold")) && (isNum || value === "")){
            tableData[Index][name] = value
            tableData[Index]['batch_detail'].every(item => item[name] = value)
        }else if(name === "physical_box_no"){
            tableData[Index][name] = value
            tableData[Index]['batch_detail'].every(item => item[name] = value)
        }else if((name === 'approved_quantity') && (isNum || value === '')){
            tableData[Index][name] = value
        }
        
        this.setState({
            tableData
        })
    }
    
    postSecondBtn = () => {
        try {
            if (this.props.title === "Inbound Details") {
                const { Data, title } = this.props
                var states = this.state
                var valid = states.tableData.filter(ele => !ele.is_delete && ele.approved_quantity > 0).every((item) => item.batch_detail[0]?.physical_box_no && item.batch_detail[0]?.reorder_threshold)
                if (valid) {
                    let finalDrugList = []
                    states.tableData.forEach((list, index) => {
                        finalDrugList.push({
                            'drug_ms_id': list.drug_ms_id,
                            'request_quantity': list.request_quantity,
                            'received_quantity': list.transfer_quantity,
                            'transfer_quantity': list.transfer_quantity,
                            'batch_detail': list.batch_detail,
                            'is_delete': list.is_delete,
                            'command': list.command ? list.command : '',
                            'id': list.id
                        })
                    })
                    var transDate = new Date()
                    var data = {
                        'main_pharmacy_id': states.tableData[0].main_pharmacy_id,
                        'req_to_location': Data.req_to_location_id,
                        'transit_number': Data.transit_number,
                        'transit_name': Data.transit_name,
                        'transfer_date': DateTime.fromJSDate(transDate).toFormat('yyyy-MM-dd'),
                        'transfer_type': Data.transfer_type,
                        'line_item': finalDrugList,
                        'req_from_location': Data.req_from_location_id,
                        'inbound_id': Data.id,
                        'status': 'Received',
                        'cancel_reason': '',
                        'is_cancel': false,
                        'transfer_from': Data.transfer_from,
                        'transfer_to': Data.transfer_to,
                    }
                    this.LoaderFunction(true)
                    this.setState({
                        isBtnClick: true
                    })
                    RestAPIService.create(data, Serviceurls.PHA_BRANCH_REQ_POST)
                        .then((response) => {
                            if (response.data.status === 'success') {
                                this.LoaderFunction(false)
                                this.successMessage(response.data.message)
                                setTimeout(() => {
                                    this.props.handlePopUpClose()
                                }, 1000);
                            }
                        }).catch((error) => {
                            if (error?.response?.data?.message) {
                                this.errorMessage(error?.response?.data?.message)
                            } else {
                                this.errorMessage(error.message)
                            }
                            this.LoaderFunction(false)
                            this.setState({
                                isBtnClick: false
                            })
                        })
                } else {
                    this.LoaderFunction(false)
                    this.errorMessage("Please Enter the Physical Box No and Reorder Thershold")
                }
            }else{
                this.postApprove()
            }
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }
    postApprove = () => {
        try {
            let states = this.state
            let finalDrugList = []
            const { Data } = this.props
            states.tableData?.forEach((element, index) => {
                    finalDrugList.push({
                        "drug_ms_id": element.drug_ms_id,
                        "request_quantity": +element.request_quantity,
                        "received_quantity": 0,
                        "approved_quantity": +element.approved_quantity,
                        'transfer_quantity': +element.approved_quantity,
                        'batch_detail': element.batch_detail,
                        'is_delete': element.is_delete,
                        'command': ''
                    })
                    if(element?.id){
                        finalDrugList[index].id = element?.id ?  element?.id : null
                    }
                })
                var transDate = new Date()
            let data = {
                'main_pharmacy_id': states.tableData[0].main_pharmacy_id,
                'req_to_location': Data.req_to_location_id,
                'transit_number': '',
                'transit_name': '',
                'transfer_type': Data.transfer_type,
                'line_item': finalDrugList,
                'req_from_location': Data.req_from_location_id,
                'inbound_id': Data.id,
                'status': 'In Transit',
                'cancel_reason': '',
                'is_cancel': false,
                'transfer_from' :  Data.transfer_from,
                'transfer_to': Data.transfer_to,
                'transfer_date': DateTime.fromJSDate(transDate).toFormat('yyyy-MM-dd')
            }
            this.LoaderFunction(true)
            this.setState({
                isBtnClick: true
            })
                RestAPIService.create(data, Serviceurls.PHA_BRANCH_REQ_POST)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                            setTimeout(() => {
                                this.props.handlePopUpClose()
                            }, 1000);
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {

                            this.errorMessage(error.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                        this.LoaderFunction(false)
                        this.setState({
                            isBtnClick: false
                        })
                    })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    cancelRequest = () => {
        try {
            var states = this.state
            if ((states.Cancel_cmt.trim()) != '') {
                let data = {
                    "status_data": "Cancelled",
                    "request_id": this.props.RowId,
                    "cancel_reason": states.Cancel_cmt.replace(/\s+/g, ' ').trim(),
                    "is_cancel": true,
                }
                this.LoaderFunction(true)
                RestAPIService.create(data, Serviceurls.PHA_BRANCH_STATUS_CHANGE)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                            setTimeout(() => {
                                this.props.handlePopUpClose()
                            }, 1000);
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage("Please Enter the Comments")
            }

        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    render() {
        const { Data, title , isCancel} = this.props
        var {tableData} = this.state
        return(
            <Box>
            <Modal open={true}>
                <Paper elevation={3} id="emedhub_vendorDetails_mainBox" sx={{ width: "95vw", maxHeight:"60vw" }}>
                    <div id="emedhub_vendorDetails_header">
                        <Typography>{title}</Typography>
                        <IconButton size="small" onClick={() => { this.props.handlePopUpClose() }}><img className="emedhub_popupIcon_img" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                    </div>
                    <Box className="emedhub_confirmOrder_mainDiv">
                        <Paper id="emedhub_confirmOrder_subDiv1" elevation={0}>
                            <div className="emedhub_confirmOrder_Div">
                                <Typography sx={{ fontSize: "0.9vw" }}>{"Transfer From"}</Typography>
                                <Typography id="emedhub_directpoTable_cell1">{Data.transfer_from ? Data.transfer_from : "-"}</Typography>
                            </div>
                            <div className="emedhub_confirmOrder_Div">
                                <Typography sx={{ fontSize: "0.9vw" }}>{"Transfer To"}</Typography>
                                <Typography id="emedhub_directpoTable_cell1">{Data.transfer_to ? Data.transfer_to : "-"}</Typography>
                            </div>
                            <div className="emedhub_confirmOrder_Div">
                                <Typography sx={{ fontSize: "0.9vw" }}>{"Requested Date"}</Typography>
                                <Typography id="emedhub_directpoTable_cell1">{Data.request_date ? Data.request_date : "-"}</Typography>
                            </div>
                            {title === 'Transfer' || isCancel ? null : 
                            <div className="emedhub_confirmOrder_Div">
                                <Typography sx={{ fontSize: "0.9vw" }}>{"Transfered Date"}</Typography>
                                <Typography id="emedhub_directpoTable_cell1">{Data.transfer_date ? Data.transfer_date : "-"}</Typography>
                            </div> }
                            <div className="emedhub_confirmOrder_Div">
                                <Typography sx={{ fontSize: "0.9vw" }}>{"Request Number"}</Typography>
                                <Typography id="emedhub_directpoTable_cell1">{Data.request_number ? Data.request_number : "-"}</Typography>
                            </div>
                            {title === "Transfer Details" ? 
                            <div className="emedhub_confirmOrder_Div">
                                <Typography sx={{ fontSize: "0.9vw" }}>{"Total Cost"}</Typography>
                                <Typography id="emedhub_directpoTable_cell1">{Data.total_cost ? Data.total_cost : "0"}</Typography>
                            </div> : null}
                        </Paper>
                        <Divider />
                        <div id="emedhub_confirmOrder_subDiv2">
                            <TableContainer className='emedhub_subloc_stkTranfer_Table'>
                                <Table stickyHeader size='small'>
                                    <TableHead className="emedhub_directpoTable_head" sx={{ height: "5vh" }}>
                                        <TableRow>
                                            <TableCell sx={{ flex: 0.04 }} id="emedhub_directpoTable_headTxt">{"S.No"}</TableCell>
                                            <TableCell sx={{ flex: 0.12 }} id="emedhub_directpoTable_headTxt">{"Brand Name"}</TableCell>
                                            <TableCell sx={{ flex: 0.11 }} id="emedhub_directpoTable_headTxt">{"Generic Name"}</TableCell>
                                            <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{"Type/Strength"}</TableCell>
                                            { ((title === "Inbound Details" && !isCancel) || title === "Transfer Details" || title === "Return Details" || title === "Return Details") ?
                                            <TableCell sx={{ flex: 0.2 }} id="emedhub_directpoTable_headTxt"> {"Batch/Expiry"}</TableCell>
                                            :null }
                                            <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{ ((title === "Inbound Details" && !isCancel) || title === "Return Details") ? "Transfered Qty" : title === "Transfer" ? "Approved Qty" : "Requested Qty"}</TableCell>
                                            {/* {title != "Return Details" ?
                                            <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{ title === "Inbound Details" ? "Received Qty" : "Approved Qty"}</TableCell>
                                            : null
                                            } */}
                                            { title === "Inbound Details" && !isCancel ?
                                            <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{"Phy Box No"}</TableCell>
                                            : null}
                                            { title === "Inbound Details" && !isCancel ?
                                            <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{"Threshold"}</TableCell>
                                            : null}
                                            {title === "Transfer" ? 
                                            <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{"Batch Details"}</TableCell>
                                            : null}
                                            {title === "Transfer Details" ? 
                                            <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{"Received Qty"}</TableCell>
                                            : null}
                                            {title === "Transfer Details" ? 
                                            <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{"Total Amount"}</TableCell>
                                            : null}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="emedhub_directpoTable_head">
                                        {tableData?.length > 0 && tableData.map((item, index) => {
                                            var batchNo = item.is_delete ? "Item Deleted" : item.batch_detail.map((item) => (<Box>{' ' + item.batch_no + ' / ' + item.expiry_date?.slice(0,7) + ' / ' + item.quantity}<br/></Box> ))
                                            let sno = (index + 1)
                                            return (
                                                <TableRow key={index} sx={{bgcolor: ((title === "Inbound Details" || title === "Transfer Details") && item.transfer_quantity === 0) ? '#e2e2e2' : 'white'}}>
                                                    <TableCell sx={{ flex: 0.04 }}>{sno}</TableCell>
                                                    <TableCell sx={{ flex: 0.12 }}>{item.drug_name}</TableCell>
                                                    <TableCell sx={{ flex: 0.11 }}>{item.generic_name}</TableCell>
                                                    <TableCell sx={{ flex: 0.1 }}>{item.dosage_type + "/" + item.dosage_strength}</TableCell>
                                                    { ((title === "Inbound Details" && !isCancel) || title === "Transfer Details" || title === "Return Details") ?
                                                    <TableCell sx={{ flex: 0.2 }}>{batchNo}</TableCell>
                                                    : null }
                                                    <TableCell sx={{ flex: 0.1 }}>{((title === "Inbound Details" && !isCancel) || title === "Return Details")  ? item.transfer_quantity : title === "Transfer" ? item.approved_quantity : item.request_quantity}</TableCell>
                                                    {/* {(title === "Transfer Details" && title != "Return Details") ? 
                                                    <TableCell sx={{ flex: 0.1 }}>{item.received_quantity}</TableCell>
                                                    : title != "Return Details" ?
                                                    <TableCell sx={{ flex: 0.1 }}>
                                                        <Stack>
                                                            <TextField
                                                                name="received_quntity"
                                                                value={item.received_quantity}
                                                                size='small'
                                                                sx={{ width: "6vw" }}
                                                                onChange={(e) => this.inputChange(e, index)}
                                                            /></Stack>
                                                    </TableCell>
                                                    : null
                                                    } */}
                                                    { title === "Inbound Details" && !isCancel ?
                                                    <TableCell sx={{ flex: 0.1 }}>
                                                        <Stack>
                                                            <TextField
                                                                name="physical_box_no"
                                                                value={item.physical_box_no}
                                                                inputProps={{ maxLength: 10 }}
                                                                disabled={(item.is_delete || item?.transfer_quantity === 0) ? true : false}
                                                                size="small"
                                                                sx={{ width: "6vw" }}
                                                                onChange={(e) => this.inputChange(e, index)}
                                                            /></Stack>
                                                    </TableCell>
                                                    : null }
                                                    { title === "Inbound Details" && !isCancel ?
                                                    <TableCell sx={{ flex: 0.1 }}>
                                                        <Stack>
                                                            <TextField
                                                                name="reorder_threshold"
                                                                inputProps={{ maxLength: 6 }}
                                                                disabled={(item.is_delete || item?.transfer_quantity === 0) ? true : false}
                                                                value={item.reorder_threshold}
                                                                size="small"
                                                                sx={{ width: "6vw" }}
                                                                onChange={(e) => this.inputChange(e, index)}
                                                            /></Stack>
                                                    </TableCell>
                                                    : null }
                                                    { title === "Transfer" ?
                                                    <TableCell sx={{ flex: 0.2 }}>{batchNo}</TableCell>
                                                    : null }
                                                    {title === "Transfer Details" ?
                                                        <TableCell sx={{ flex: 0.2 }}>{item.received_quantity}</TableCell>
                                                        : null}
                                                    {title === "Transfer Details" ?
                                                        <TableCell sx={{ flex: 0.1 }}>{item.total_cost ? item.total_cost : '0'}</TableCell>
                                                        : null}
                                                </TableRow>
                                            )
                                        })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Box>
                        {isCancel ?
                            <Box>
                                <TextField
                                    fullWidth
                                    onChange={(e)=>{
                                        this.setState({
                                            Cancel_cmt: e.target.value
                                        })
                                    }}
                                    size="small"
                                    id="inbound-cancel-text"
                                    label="Comments"
                                    multiline
                                    rows={2}
                                />
                            </Box> : null}
                    <div id="emedhub_vendorDetails_footer" >
                    {(title != "Transfer Details" && title != "Return Details") ?
                    <Stack direction="row" spacing={2} sx={{width: '100%', justifyContent: 'flex-end',}}>
                    <Button size="small" sx={{textTransform: 'capitalize'}} color={isCancel ? 'error' : 'primary'} disabled={this.state.isBtnClick} variant= {isCancel ? "outlined" : "contained"} onClick={() => { isCancel ? this.cancelRequest() : this.postSecondBtn() }}>{title === "Inbound Details" && !isCancel ? "Complete Request" : isCancel ? "Cancel Request" : "Transfer"}</Button>
                    </Stack>
                    : 
                    // title === "Return Details" ? 
                    // <Stack direction="row" spacing={2} sx={{width: '100%', justifyContent: 'flex-end'}}>
                    // <Button size="small" color={"error"} variant={"outlined"} onClick={() => {this.cancelRequest() }}>{"CANCEL REQUEST"}</Button>
                    // {/* <Button size="small" variant="contained" onClick={() => { }}>{title === "Inbound Details" ? "COMPLETE REQUEST" : "APPROVE REQUEST"}</Button> */}
                    // </Stack>
                    // : 
                    null
                    }
                        
                    </div>
                </Paper>
            </Modal>
            {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                    <Loader loaderOpen={this.state.isLoader} />
            </Box> 
        )
    }
}