import { Autocomplete, Box, Button, Drawer, FormControl, Grow, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import CommonGridHeader, { CommonGridToolBarWithFilterText, CommonPatientDetails, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { formatDate, AmountFormat, timeOnlyConvert, Time_convert, formatDateAndTimeMeth2 } from '../../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../../Styles/Colors';
import { CurrencySymbol } from '../../../../Utility/Constants';
import Loader from '../../../../Components/Loader';

const PatientType = [
    {patient_type : "New Patients", value : "new_patient"},
    {patient_type : "Review Patients", value : "review_patient"},
    {patient_type : "Old Patients", value : "established_patient"}
]
class AppointmentPatientReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            doctorName: [],
            specialization: [],
            appointmentType: [],
            startDate: null,
            dateIndex:1,
            appointmentPatientData: [],
            appointmentDoctors: null,
            appointmentTotalAmount: null,
            appointmentTotalPatient: null,
            appointmentTypeList: [],
            doctorNameList: [],
            specializationList: [],
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            PatientsCount: null,
            PatientsAmount: null,
            showBox: false,
            showNPcard: false,
            patientType: [],
            FilterDatas: {
                "startTime":null,
                "endTime":null,
                "onError":false,
            },
            timeError: false,
            printLoading: false,
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getAppointmentPatientdata()
        this.getAppointmentTypeList()
        this.getDoctorNameList()
        this.getSpecializationList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, ()=> {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas:this.state.FilterDatas
            })
        })
    }

    getAppointmentPatientdata = () => {
        try {
            this.LoaderFunction(true)
            let appointmentDate = this.state.startDate ? DateTime.fromJSDate(this.state.startDate).toFormat('yyyy-MM-dd') : "";
            let doctorId = []; let specializationId = [];let appoinmentType = [];let patientType = [];
            this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
            this.state.specialization.forEach(element => specializationId.push(element.id))
            this.state.appointmentType.forEach(element => appoinmentType.push(element.appoinment_type))
            this.state.patientType.forEach(element => patientType.push(element.value))
            var {FilterDatas} = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.getAll(Serviceurls.FO_REPORTS_APPOINTMENT_PATIENT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&appointment_date=${appointmentDate}&doctor_id=${doctorId}&specialization=${specializationId}&appointment_type=${appoinmentType}&patient_type=${patientType}&start_time=${setStartTime}&end_time=${setEndTime}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            appointmentPatientData: response.data.data,
                            appointmentTotalPatient: response.data.total_patient,
                            appointmentTotalAmount: response.data.total_amount,
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate,
                            PatientsCount: response.data.patient_count,
                            PatientsAmount: response.data.patient_amount,
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }
    printReports = () => {
        try {
            let appointmentDate = this.state.startDate ? DateTime.fromJSDate(this.state.startDate).toFormat('yyyy-MM-dd') : "";
            let doctorId = []; let specializationId = [];let appoinmentType = [];
            this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
            this.state.specialization.forEach(element => specializationId.push(element.id))
            this.state.appointmentType.forEach(element => appoinmentType.push(element.appoinment_type))
            var {FilterDatas} = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            this.setState({disableBtn: true, printLoading: true})
            RestAPIService.getAll(Serviceurls.FO_REPORTS_APPOINTMENT_PATIENT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&appointment_date=${appointmentDate}&doctor_id=${doctorId}&specialization=${specializationId}&appointment_type=${appoinmentType}&export_type=pdf&start_time=${setStartTime}&end_time=${setEndTime}`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({disableBtn: false, printLoading: false})
                }).catch((error) => {
                    this.errorMessage(error.message)
                    this.setState({disableBtn: false, printLoading: false})
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({disableBtn: false, printLoading: false})
        }
    }
    getDoctorNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            doctorNameList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getAppointmentTypeList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPAPPOINTMENT)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            appointmentTypeList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getSpecializationList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPLGET)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            specializationList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    selectedTime = (start, end,error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button  emed_tid = {"LabCloseButton_TestID"}  onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            emed_tid = {"eMed_date_filter_TestID"} 
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)} 
                            HideAllMenu={true}/>
                    </Box>
                    <Box sx={{width: '22vw'}}>
                    <CommonTimeRangePicker
                        startTime={this.state.FilterDatas?.startTime}
                        endTime={this.state.FilterDatas?.endTime}
                        selectedTime={this.selectedTime.bind(this)}
                        FromDate={this.state.fromDate}
                        emed_tid = {"eMed_selectedTime_TestID"} 
                        ToDate={this.state.toDate}
                        onError={this.state.FilterDatas.onError}
                    />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.doctorNameList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={this.state.doctorName}
                            onChange={(event, newValue) => {
                                this.setState({ doctorName: newValue })
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.doctor_id}>
                                  {option?.doctor_name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{...params.inputProps,'emed_tid': 'select_doctor', }}
                                    label={t('selectDoctor')}
                                    emed_tid = {"selectDoctor_TestID"} 
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker
                                inputProps={{ 'emed_tid': 'datePicker_TestID_'}}
                                inputFormat='DD-MM-YYYY'
                                label={t('SelectFrom')}
                                value={this.state.startDate}
                                name="startDate"
                                emed_tid = {"startDate_TestID"} 
                                onChange={(newDate) => { this.setState({ startDate: newDate.$d }) }}
                                renderInput={(params) => <TextField style={{ width: '20vw' }} size='small' {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.specializationList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.name)}
                            value={this.state.specialization}
                            onChange={(event, newValue) => {
                                this.setState({ specialization: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'select_special'}}
                                    label={t('selectSpecial')}
                                    emed_tid = {"selectSpecial_TestID"} 
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.appointmentTypeList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.appoinment_type)}
                            value={this.state.appointmentType}
                            onChange={(event, newValue) => {
                                this.setState({ appointmentType: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'select_appointment'}}
                                    label={t('selectAppointType')}
                                    emed_tid = {"selectAppointType_TestID"} 
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={PatientType?.map((item) => item)}
                            getOptionLabel={(option) => (option?.patient_type)}
                            value={this.state.patientType}
                            onChange={(event, newValue) => {
                                this.setState({ patientType: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'select_patient_type'}}
                                    label={t('selectPatientType')}
                                    emed_tid = {"selectPatientType_TestID"} 
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"  emed_tid = {"getAppointmentPatientdata_TestID"} 
                        onClick={() => {
                            this.setState({
                                doctorName: [], startDate: null,
                                specialization: [], appointmentType: [], patientType: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime":null,
                                    "endTime":null,
                                    "onError": false
                                },
                                timeError: false,
                            }, () => this.getAppointmentPatientdata())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid = {"Search_TestID"} 
                    disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getAppointmentPatientdata()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            {label:"From Date", value: formatDate(this.state.FilteredFromDate)},
            {label:"To Date", value:formatDate(this.state.FilteredToDate)},
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        return(
          <Box>
             <CommonGridToolBarWithFilterText FilterTextArray={TextArray}/>
          </Box>
    
        )
      }

    render() {
        this.state.appointmentPatientData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "patient_name", headerName: t("PatientName"), flex: 0.172,
                renderCell: (params) => ( <CommonPatientDetails data={params?.row} showDetailed={true}/> )
            },
            {
                field: "invoice_date", headerName: t("BillDate"), flex: 0.122, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? formatDate(params?.row?.invoice_date) : '-'}</Box>)
            },
            {
                field: "doctor_name", headerName: t("DoctorName"), flex: 0.131,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.doctor_name ? params?.row?.doctor_name?.length > 20 ?
                    <div><Tooltip placement='top' title={params?.row?.doctor_name} arrow><div>{params?.row?.doctor_name.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.doctor_name : "-"}</Box>)
            },
            {
                field: "specialization", headerName: t("Specialization"), flex: 0.141,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.specialization?.length > 20 ?
                    <div><Tooltip placement='top' title={params?.row?.specialization} arrow><div>{params?.row?.specialization.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.specialization}</Box>)
            },
            {
                field: "appointment_type", headerName: t("AppointmentType"), flex: 0.141,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.appointment_type ? params?.row?.appointment_type?.length > 20 ?
                    <div><Tooltip placement='top' title={params?.row?.appointment_type} arrow><div>{params?.row?.appointment_type.slice(0, 20) + "..."}</div></Tooltip></div> : params?.row?.appointment_type : "-"}</Box>)
            },
            {
                field: "amount_net", headerName: `${t("BillAmount")} (${CurrencySymbol})`, flex: 0.111, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.amount_net ? AmountFormat(params?.row?.amount_net).replace(`${CurrencySymbol}`, "") : '0'}</Box>)
            },
            {
                field: "appointment_date", headerName: t("Appointment Date & Time"), flex: 0.161, align: "center", headerAlign: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.appointment_date ? formatDateAndTimeMeth2(params?.row?.appointment_date) : "-"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        <Button className='eMed_Recpt_header_amt_card'  emed_tid = {"appointmentTotalPatient_TEstID"} onClick={() => { this.setState({ showBox: !this.state.showBox, showNPcard: false }) }}>
                            {AmountsCard(t("TotalPatients"), this.state.appointmentTotalPatient, true, this.state.showBox ? Colors.Primary : "white", true, this.state.showBox ? "white" : Colors.Primary)}
                        </Button>
                        <Button className='eMed_Recpt_header_amt_card' emed_tid = {"appointmentTotalPatient_TEstID02"}  onClick={() => { this.setState({ showBox: false, showNPcard: !this.state.showNPcard }) }}>
                            {AmountsCard(t("TotalAmount"), this.state.appointmentTotalAmount, false, this.state.showNPcard ? Colors.Primary : "white", true, this.state.showNPcard ? "white" : Colors.Primary)}
                        </Button>
                        <Box component={'div'} sx={{width: '50vw', overflow: 'scroll',marginLeft: '1vw', height: "5.5vw", display: 'flex', alignItems: 'center'}} >
                            {this.state.showBox ?
                                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 500 } : {})}>{AmountsCard(t("NewPatients"), this.state.PatientsCount.new_patient, true)}</Grow>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 1000 } : {})}>{AmountsCard(t("ReviewPatients"), this.state.PatientsCount.review_patient, true)}</Grow>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 1500 } : {})}>{AmountsCard(t("EstablishedPatient"), this.state.PatientsCount.establish_patient, true)}</Grow>
                                </Box> : null}
                            {this.state.showNPcard ?
                                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                    <Grow in={this.state.showNPcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showNPcard ? { timeout: 500 } : {})}>{AmountsCard(t("NewPatients"), this.state.PatientsAmount.new_patient_amount)}</Grow>
                                    <Grow in={this.state.showNPcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showNPcard ? { timeout: 1000 } : {})}>{AmountsCard(t("ReviewPatients"), this.state.PatientsAmount.review_patient_amount)}</Grow>
                                    <Grow in={this.state.showNPcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showNPcard ? { timeout: 1500 } : {})}>{AmountsCard(t("EstablishedPatient"), this.state.PatientsAmount.establish_patient_amount)}</Grow>
                                </Box> : null}
                        </Box>
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn'  emed_tid = {"filter_TEstID02"} onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' emed_tid = {"eMed_usrconf_btn_TestID"}  disabled={this.state.disableBtn} onClick={() => { this.printReports() }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        {/* <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.appointmentPatientData}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar:this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.printLoading} forPrint={true} label={"Building PDF..."} />
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}


export default withTranslation()(AppointmentPatientReport)