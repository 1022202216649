import React, { Component } from 'react';
import {
  Box, Button, Tooltip, TableCell, TableBody, Table, TableHead, TableRow, TableContainer, Checkbox,
  Typography
} from '@mui/material';
import { withTranslation } from 'react-i18next'
import '../FrontOffice/BillingTransaction/Billings.css';
import { ImagePaths } from '../../Utility/ImagePaths';
import Drawer from '@mui/material/Drawer';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import Loader from '../../Components/Loader';

class PharmacyStockConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      pha_Stock_List: [],
      isAccess: false,
    }
  }

  componentDidMount() {
    this.getPharmacyStockConfig()
    this.getCustomConfig()
  }


  getCustomConfig = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.BRANCH_MOD_CUSTOM_CONFIG).
        then((response) => {
          if (response.data?.status === "success") {
            this.LoaderFunction(false)
            this.setState({
              isAccess: response.data.data[0]?.branch_to_branch_stock_transfer
            })
          }
        }).catch((e) => {
          this.LoaderFunction(false)
          if (e?.response?.data?.message) {
            this.errorMessage(e?.response?.data?.message)
          } else {
            this.errorMessage(e?.message)
          }
        })
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e?.message)
    }
  }

  getPharmacyStockConfig = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.PHARMACY_STOCK_CONFIG)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.setState({
              pha_Stock_List: response?.data?.data || []
            })
          }
        })
        .catch((e) => {
          this.LoaderFunction(false)
          if (e.response.data.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }

  postPharmaStockConfig = () => {
    try {
      let data = this.state.pha_Stock_List
      RestAPIService.create(data, Serviceurls.PHARMACY_STOCK_CONFIG)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data?.message)
            this.getPharmacyStockConfig()
          }
        })
        .catch((e) => {
          if (e.response.data.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  BlockBills = (item, i) => {
    const checked = item?.is_po_enabled
    return (
      <Checkbox onChange={(e) => { this.switchFunction(e.target.checked, i) }} checked={checked} />
    )
  }

  switchFunction = (newValue = null, i) => {
    let { pha_Stock_List } = this.state
    pha_Stock_List[i]["is_po_enabled"] = newValue
    this.setState({ pha_Stock_List })
  }

  renderTbl = () => {
    try {
      return (
        <TableContainer component={'div'} sx={{ height: '69vh', backgroundColor: 'white' }}>
          <Table size='small' aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '5vw' }} align='center' className="eMed_ABill_Head">{'S.No'}</TableCell>
                <TableCell sx={{ width: '20vw' }} className="eMed_ABill_Head">{'Pharmacy Name'}</TableCell>
                <TableCell sx={{ width: '15vw' }} className="eMed_ABill_Head">{"Pharmacy Reg No"}</TableCell>
                <TableCell sx={{ width: '20vw' }} className="eMed_ABill_Head">{"GST No"}</TableCell>
                <TableCell sx={{ width: '12vw' }} className="eMed_ABill_Head">{"Area"}</TableCell>
                <TableCell sx={{ width: '12vw' }} className="eMed_ABill_Head">{"Contact No"}</TableCell>
                <TableCell sx={{ width: '12vw' }} className="eMed_ABill_Head">{"Email ID"}</TableCell>
                <TableCell sx={{ width: '4vw' }} className="eMed_ABill_Head">{"Enable PO"}</TableCell>
              </TableRow>
            </TableHead>
            {this.state.pha_Stock_List?.map((item, index) => {
              return (
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ width: '5vw', }} align='center' className='eMed_ABill_Tblcell'>{index + 1}</TableCell>
                    <TableCell sx={{ width: '20vw', }} className='eMed_ABill_Tblcell'>
                      {item?.is_main_pharmacy ? <Box display={'flex'} flexDirection={'row'}>
                        <Typography sx={{ fontSize: '.9vw' }}>{item?.pharmacy_name}</Typography>
                        <img style={{ width: '1.2vw', height: '1.2vw', marginRight: '.5vw' }} src={ImagePaths.StarFillColor.default} alt='star' />
                      </Box> : <Typography sx={{ fontSize: '.9vw' }}>{item?.pharmacy_name || '-'}</Typography>}
                    </TableCell>
                    <TableCell sx={{ width: '20vw', }} className='eMed_ABill_Tblcell'>{item?.pharmacy_registration_no || '-'}</TableCell>
                    <TableCell sx={{ width: '20vw', }} className='eMed_ABill_Tblcell'>{item?.pharmacy_gstn_number || '-'}</TableCell>
                    <TableCell sx={{ width: '10vw', }} className='eMed_ABill_Tblcell'>{item?.pharmacy_area || '-'}</TableCell>
                    <TableCell sx={{ width: '10vw', }} className='eMed_ABill_Tblcell'>{item?.pharmacy_phone_no || '-'}</TableCell>
                    <TableCell sx={{ width: '10vw', }} className='eMed_ABill_Tblcell'>{item?.pharmacy_email_id || '-'}</TableCell>
                    <TableCell sx={{ width: '10vw', }} className='eMed_ABill_Tblcell'>
                      {item?.is_main_pharmacy ? "" : this.BlockBills(item, index)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )
            }
            )}
          </Table>
        </TableContainer>
      )
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  render() {
    return (
      <Box component={"div"} sx={{ marginTop: '0.5vw', padding: '.5vw', backgroundColor: '#F5F8F7' }}>
        {this.state.isAccess ?
          <Box>
            <Box component={"div"} sx={{ height: '69vh', }}>
              {this.renderTbl()}
            </Box>
            <Box sx={{ height: '7vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button size='small' variant="contained" sx={{ textTransform: 'capitalize', height: '2vw' }}
                onClick={() => {
                  this.postPharmaStockConfig()
                }}
              >Save</Button>
            </Box>
          </Box> :
          <Box component={"div"} sx={{ height: '76vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '1vw', fontWeight: 'bold' }}>{'No Access for this Screen'}</Typography>
            <Typography sx={{ fontSize: '.8vw', color: '#888888' }}>{'(Go to Custom Configuration and enable the option for Branch-to-Branch Stock Transfer.)'}</Typography>
          </Box>
        }
        {
          this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null
        }
        {
          this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null
        }
        <Loader loaderOpen={this.state.isLoader} />

      </Box >
    )
  }
}

export default withTranslation()(PharmacyStockConfig)


