import React, { Component } from 'react';
import {
    Box, Button, MenuItem, Paper, Stack, Tooltip, Menu, IconButton, Grow, Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next'
import '../../FrontOffice/BillingTransaction/Billings.css';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import Drawer from '@mui/material/Drawer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BillingsFilter from '../../FrontOffice/BillingTransaction/BillingsFilter';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import PrintPreviewPop from '../../../Components/Common Components/PrintPreviewPop';
import { localGetItem, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import { CheckAccessFunc, formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../Components/Loader';
import CommonGridHeader, { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { AmountFormat } from "../../../Components/CommonFunctions/CommonFunctions";
import { Colors } from '../../../Styles/Colors';
import ChangePaymentMode from '../../FrontOffice/BillingTransaction/ChangePaymentMode';
import { CurrencySymbol } from '../../../Utility/Constants';

class LabReceiptBills extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            BillList: [],
            FilterOpen: false,
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
                "PaidAmount": "",
                "BillNumber": "",
                "uhidNo": "",
                "PatientName": "",
                "InPatientSelected": true,
                "OutPatientSelected": true,
                "PharmacySelected": true,
                "LaboratorySelected": true,
                "BillType": ['op', 'ip', 'lab'],
                "PayMethods": [1, 2, 3, 4, 5, 6],
                "CardSelected": true,
                "CashSelected": true,
                "UPISelected": true,
                "BankTransferSelected": true,
                "InsuranceSelected": true,
                "ChequeSelected": true,
                "combined": true,
                "BillReceiptSelected": true,
                "AdvanceTypeSelected": true,
                "RefundTypeSelected": true,
                "CancelledTypeSelected": true,
                "startTime":null,
                "endTime":null,
                "AgainstCredit":'both',
                "includeFObills" : false,
                'UserList': [],
                'UserDetails': [],
                "RecieptType": ['bill', 'advance', 'refund', 'cancelled']
            },
            totalAmount: 0,
            noofReceipt: 0,
            BillPreviewPop: false,
            showBox: false,
            cash_amount: 0,
            card_amount: 0,
            upi_amount: 0,
            cheque_amount: 0,
            bank_amount: 0,
            insurance_amount:0,
            showRFcard: false,
            ChangePaymentPopup: false,
        }
    }

    GetUserDetails = () => {
        let states = this.state
        try {
            RestAPIService.getAll(Serviceurls.DAY_END_USER_DETAIL_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        states.FilterDatas["UserList"] =  response.data.data
                        this.setState({
                            states
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }

                })
        } catch (error) {
            this.errorMessage(error)
        }
    }

    componentDidMount() {
        this.getReceiptBills()
        this.GetUserDetails()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getReceiptBills = () => {
        try {
            var states = this.state

            let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
            let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let againstCredit = states.FilterDatas.AgainstCredit === 'both' ? '' : "&against_credit=" + states.FilterDatas.AgainstCredit
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.LAB_RECEIPT_REPORT +
                "?recept_number=" + states.FilterDatas.BillNumber +
                "&from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                "&paid_amount=" + states.FilterDatas.PaidAmount +
                "&patient_name=" + states.FilterDatas.PatientName +
                "&payment_method=" + states.FilterDatas.PayMethods +
                "&is_combined=" + states.FilterDatas.combined +
                "&uhid=" + states.FilterDatas.uhidNo +
                "&receipt_type=" + states.FilterDatas.RecieptType + 
                "&start_time=" + setStartTime + 
                "&end_time=" + setEndTime + againstCredit +
                "&collected_in=" + (states.FilterDatas.includeFObills ? ["FO LAB", "LAB"] : ["LAB"])
                
            )
                .then(response => {
                    if (response.data.status === 'success') {
                        this.setState({
                            BillList: response.data.data ? response.data.data : [],
                            totalAmount: response.data.total_amount ? response.data.total_amount : 0,
                            noofReceipt: response.data.total_receipt ? response.data.total_receipt : 0,
                            cash_amount: response.data.cash_amount ? response.data.cash_amount : 0,
                            card_amount: response.data.card_amount ? response.data.card_amount : 0,
                            upi_amount: response.data.upi_amount ? response.data.upi_amount : 0,
                            bank_amount: response.data.bank_amount ? response.data.bank_amount : 0,
                            cheque_amount: response.data.cheque_amount ? response.data.cheque_amount : 0,
                            insurance_amount:response.data.insurance_amount ? response.data.insurance_amount : 0
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch(e => {
                    if (e?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false)
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(e.message)
                    }
                })

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    getReceiptBillPrint = () => {
        try {
            var states = this.state
            let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
            let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let againstCredit = states.FilterDatas.AgainstCredit === 'both' ? '' : "&against_credit=" + states.FilterDatas.AgainstCredit
          this.setState({disableBtn: true})
          RestAPIService.getAll(Serviceurls.LAB_RECEIPT_REPORT + 
                "?recept_number=" + states.FilterDatas.BillNumber +
                "&from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                "&paid_amount=" + states.FilterDatas.PaidAmount +
                "&patient_name=" + states.FilterDatas.PatientName +
                "&payment_method=" + states.FilterDatas.PayMethods +
                "&is_combined=" + states.FilterDatas.combined +
                "&uhid=" + states.FilterDatas.uhidNo +
                "&receipt_type=" + states.FilterDatas.RecieptType + 
                "&start_time=" + setStartTime + 
                "&end_time=" + setEndTime + againstCredit +
                "&collected_in=" + (states.FilterDatas.includeFObills ? ["FO LAB", "LAB"] : ["LAB"]) +
                "&export=pdf"
          ).
            then((response) => {
              const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
              this.setState({disableBtn: false})
            }).catch((e) => {
            this.errorMessage(e.message)
            })
        } catch (e) {
          this.errorMessage(e.message)
        }
      }


    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
            ChangePaymentPopup : false
        }, () => {
            if (Getapicall && message !== "") {
                this.successMessage(message)
                this.getReceiptBills()
            }
        })
    }

    MenuItem = (data) => {
        const { t } = this.props
        let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        let PaymentChangeAccess = RoleData?.is_user ? (RoleData?.permission_access?.common?.change_payment_mode ? RoleData?.permission_access?.common?.change_payment_mode : false) : true
        const multi_option = [
            { value: "ChangePaymentMode", label: t("Change Payment Mode") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        disabled={this.state.ChangePaymentPopup}
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option} 
                            disabled={option.value === "ChangePaymentMode" ? ((data?.row?.amount_received && PaymentChangeAccess) ? false : true) : false}
                            onClick={() => { 
                                this.setState({ anchorEl: null }, ()=>{
                                    if(option.value === "ChangePaymentMode"){
                                        this.setState({ ChangePaymentPopup : true, SelectedBill: data?.row })
                                    }
                                }) }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => { this.getReceiptBills() })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

        let TextArray = [
            {label:"From Date", value: formatDate(this.state.FilterDatas.FromDate)},
            {label:"To Date", value:formatDate(this.state.FilterDatas.ToDate)},
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        return(
          <Box>
             <CommonGridToolBarWithFilterText  FilterTextArray={TextArray}/>
          </Box>
    
        )
      }

      clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    PaymentModeChanged(message) {
        this.setState({
            ChangePaymentPopup : false,
        }, () => {
            this.successMessage(message)
            this.getReceiptBills()
        })
    }

    render() {
        const { t } = this.props
        this.state.BillList.forEach((element, index) => element.sno = index + 1)
        // const columns = [
        //     {
        //         field: "invoice_date", flex: 0.111, headerName: t("RDate"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? params?.row?.invoice_date : "-"}</Box>)
        //     },
        //     {
        //         field: "receipt_number", flex: 0.111, headerName: t("RNo"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.receipt_number ? params.row.receipt_number : "-"}</Box>)
        //     },
        //     {
        //         field: "service_id", flex: 0.111, headerName: `${t("Service ID")}`, headerAlign: "center", align: "center",
        //         renderCell: (params) => {
        //             const billArr = params?.row?.service_id ? params?.row?.service_id?.split("/") : [];
        //             return (
        //                 <Tooltip placement='top' title={params?.row?.service_id} arrow>
        //                     <div>{billArr?.length > 0 ? '...' + billArr?.slice(-2)?.join("/") : "-"}</div>
        //                 </Tooltip>
        //             )}
        //     },
        //     {
        //         field: "patient_uhid", flex: 0.111, headerName: t("UHID"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_uhid ? params?.row?.patient_uhid : "-"}</Box>)
        //     },
        //     {
        //         field: "patient_name", flex: 0.111, headerName: t("PatientName"),
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_name ? params?.row?.patient_name?.length > 17 ?
        //             <Tooltip placement='top' title={params?.row?.patient_name} arrow>
        //                 <div>{params?.row?.patient_name.slice(0, 15) + "..."}</div></Tooltip> :
        //             params?.row?.patient_name : "-"}</Box>)
        //     },
        //     {
        //         field: "payment_mode_types", flex: 0.111, headerName: t("PaymentType"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.payment_mode_types ? params.row.payment_mode_types : "-"}</Box>)
        //     },
        //     {
        //         field: "amount_received", flex: 0.111, headerName: `${t("Amount")} (${CurrencySymbol})`, type: "number",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.amount_received ? AmountFormat(params.row.amount_received)?.replace(`${CurrencySymbol}`, "") : "-"}</Box>)
        //     },
        //     {
        //         field: "created_by", flex: 0.111, headerName: t("CollecBy"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.created_by ? params.row.created_by : "-"}</Box>)
        //     },
        //     {
        //         field: "action", flex: 0.111, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        //         renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        //             <Tooltip title={t("PrintBill")} placement="top" arrow>
        //                 <Button
        //                     onClick={() => { this.setState({ BillPreviewPop: true, SelectedBill: params.row }) }} 
        //                     className='eMed_usrconf_btn'>
        //                     <img src={ImagePaths.PrintIcons.default} alt="Create Bill" className='eMed_action_img' />
        //                 </Button>
        //             </Tooltip>
        //             {this.MenuItem(params)}
        //         </Box>
        //     }
        // ]
        const columns = [
            {
                field: 'receipt_number', headerName: 'Receipt Details', flex: 0.125,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_lab_rcpt_dtls'>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.receipt_number ?
                                params?.row?.receipt_number?.length > 18 ?
                                    <Tooltip placement='top' title={params?.row?.receipt_number}><div style={{ fontSize: '0.9vw', fontWeight: 600 }}>{ '...' + params?.row?.receipt_number?.slice(4)}</div></Tooltip> :
                                    params?.row?.receipt_number : '-'}</Typography>
                            <Typography color={Colors.grayShade} fontSize={'0.8vw'}>{params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: 'patient_name', headerName: 'Patient Details', flex: 0.125,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid={'emed_lab_pat_dtls'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.patient_name ?
                                params?.row?.patient_name?.length > 15 ?
                                    <Tooltip placement='top' title={params?.row?.patient_name}><div style={{ fontWeight: 600, fontSize: '0.9vw' }}>{params?.row?.patient_name?.slice(0, 15) + '...'}</div></Tooltip> :
                                    params?.row?.patient_name : '-'}</Typography>
                            <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.patient_uhid ? params?.row?.patient_uhid : '-'}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: "description", flex: 0.2, headerName: `${t("Bill No.")}`, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    const billArr = params?.row?.description ? params?.row?.description?.split("/") : [];
                    return (
                        <Tooltip placement='top' title={params?.row?.description} arrow>
                            <Box component={'div'} emed_tid='emed_fo_descp' fontSize={'0.9vw'} fontWeight={600}>{billArr?.length > 0 ? '...' + billArr?.slice(-2)?.join("/") : "-"}</Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'receipt_type', headerName: 'Bill Type', flex: 0.11,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid={'emed_lab_bill_type'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.receipt_type}</Typography>
                            <Typography fontSize={"0.8vw"} color={Colors?.grayShade}>{` Paid in : ${params?.row?.collected_in ? params?.row?.collected_in : "-"}`}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: 'receipt_for', headerName: 'Receipt For', flex: 0.10,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_lab_rcpt_type'>
                            {params?.row?.receipt_for ? (params?.row?.receipt_for === "Bill Receipt" && params?.row?.against_credit) ? <Box>
                                <Typography fontSize={"0.9vw"} fontWeight={600}>Bill Receipt</Typography>
                                <Typography fontSize={"0.8vw"} color={Colors?.grayShade}>Against Credit</Typography>
                            </Box> : <Typography fontWeight={600} fontSize={'0.9vw'}>{params?.row?.receipt_for ? params?.row?.receipt_for : '-'}</Typography> : "-"}
                        </Box>
                    )
                }
            },
            {
                field: 'amount_received', headerName: `${'Payment Details'} (${CurrencySymbol})`, flex: 0.125, headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_lab_pay_dtls'>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.amount_received ? AmountFormat(params.row.amount_received)?.replace(`${CurrencySymbol}`, "") : "0"}</Typography>
                            <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.payment_mode_types ? params?.row?.payment_mode_types?.length > 14 ?
                                <Tooltip placement='top' title={params?.row?.payment_mode_types} arrow>
                                    <div style={{fontSize:'0.8vw',color:Colors.grayShade}}>{params?.row?.payment_mode_types.slice(0, 12) + "..."}</div></Tooltip> :
                                params?.row?.payment_mode_types : "-"}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: 'created_by', headerName: 'Collected By', flex: 0.11,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_lab_coll_by'>
                            <Typography fontWeight={600} fontSize={'0.9vw'}>{params?.row?.created_by ? params?.row?.created_by?.length > 15 ?
                                <Tooltip placement='top' title={params?.row?.created_by}><div style={{ fontWeight: 600, fontSize: '0.9vw' }}>{params?.row?.created_by?.slice(0, 15) + '...'}</div></Tooltip> :
                                params?.row?.created_by : '-'}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: "action", flex: 0.105, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
                renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <Tooltip title={t("PrintBill")} placement="top" arrow>
                        <Button onClick={() => { this.setState({ BillPreviewPop: true, SelectedBill: params.row }) }} className='eMed_usrconf_btn' emed_tid='eMed_lab_prt_btn'>
                            <img src={ImagePaths.PrintIcons.default} alt="Create Bill" className='eMed_action_img' />
                        </Button>
                    </Tooltip>
                    {this.MenuItem(params)}
                </Box>
            }
        ]
        return (
            <Box component={"div"} className='eMed_Main_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container' sx={{ flex: 0.97 }}>
                        {AmountsCard(t("NR"), this.state.noofReceipt, true)}
                        <Button className='eMed_Recpt_header_amt_card' emed_tid='eMed_Recpt_header_amt_card_TestID' onClick={() => { this.setState({ showBox: !this.state.showBox, showRFcard: false }) }}>{AmountsCard(t("PaidAmount"), this.state.totalAmount, false, this.state.showBox ? Colors.Primary : "white", true, this.state.showBox ? "white" : Colors.Primary)}</Button>
                        {/* <Button className='eMed_Recpt_header_amt_card' onClick={() => { this.setState({ showRFcard: !this.state.showRFcard, showBox: false }) }}>{AmountsCard(t("RefundAmount"), this.state.totalAmount, false, this.state.showRFcard ? Colors.Primary : "white", true, this.state.showRFcard ? "white" : Colors.Primary)}</Button> */}
                        <Box component={'div'} sx={{ width: '63vw', overflow: 'scroll', marginLeft: '1vw', height: "5.5vw", display: 'flex', alignItems: 'center'}} >
                            {this.state.showBox ?
                                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 500 } : {})}>{AmountsCard(t("Cash"), this.state.cash_amount)}</Grow>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 1000 } : {})}>{AmountsCard(t("Card"), this.state.card_amount)}</Grow>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 1500 } : {})}>{AmountsCard(t("UPI"), this.state.upi_amount)}</Grow>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 2000 } : {})}>{AmountsCard(t("Bank"), this.state.bank_amount)}</Grow>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 2500 } : {})}>{AmountsCard(t("Cheque"), this.state.cheque_amount)}</Grow>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 3000 } : {})}>{AmountsCard(t("Insurance"), this.state.insurance_amount)}</Grow>
                                </Box> : null}
                            {/* {this.state.showRFcard ?
                                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                    <Grow in={this.state.showRFcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showRFcard ? { timeout: 500 } : {})}>{AmountsCard(t("Cash"), this.state.cash_amount)}</Grow>
                                    <Grow in={this.state.showRFcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showRFcard ? { timeout: 1000 } : {})}>{AmountsCard(t("UPI"), this.state.cash_amount)}</Grow>
                                    <Grow in={this.state.showRFcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showRFcard ? { timeout: 1500 } : {})}>{AmountsCard(t("Bank"), this.state.cash_amount)}</Grow>
                                </Box> : null} */}
                        </Box>
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container' flex={0.03}>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Tooltip title="Filter" placement='top' arrow>
                                <Button className='eMed_usrconf_btn'  emed_tid = "eMed_usrconf_btn_testId_01" onClick={() => { this.setState({ FilterOpen: true }) }}>
                                    <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>
                         <Tooltip title="Print" placement='top' arrow>
                            <Button className='eMed_usrconf_btn' onClick={()=>{this.getReceiptBillPrint()}}>
                                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                            </Button></Tooltip>
                        {/*<Tooltip title="Export" placement='top' arrow>
                            <Button className='eMed_usrconf_btn'>
                                <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                            </Button></Tooltip> */}
                    </Box>
                </Box>
                <Box component={"div"} className='eMed_Table_Container'>
                    <Stack component={"div"}>
                        <Paper className='eMed_Table_Wrapper' >
                            <DataGrid
                                rows={this.state.BillList}
                                columns={columns}
                                getRowId={(row) => row['sno']}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                hideFooterSelectedRowCount
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                components={{
                                    Toolbar: this.gridToolBar,
                                    NoRowsOverlay: () => (
                                        <Stack className='eMed_conf_nodata'>
                                            {t("NoRecordsFound")}
                                        </Stack>
                                    )
                                }}
                                rowsPerPageOptions={[10, 20, 30]}
                                disableSelectionOnClick
                                pagination
                                onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                            />
                        </Paper>
                    </Stack>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <BillingsFilter PageName={"ReceiptBills"} labReceipt={true} FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} fromReceipt={true}  fromLab={true} ClearData={this.clearFilter.bind(this)} />
                </Drawer>
                {
                    this.state.BillPreviewPop ?
                        <PrintPreviewPop
                            URL={Serviceurls.INVOICE_PRINT}
                            Title={t("BillPreview")}
                            BillData={this.state.SelectedBill}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            ModifyBillButton={false}
                            cancelBillButton={false}
                            PrintButton={false}
                            history={this.props.history} /> : null
                }
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                <Loader loaderOpen={this.state.isLoader} />
                {
                    this.state.ChangePaymentPopup ? <ChangePaymentMode BillData={this.state.SelectedBill} PaymentModeChanged={this.PaymentModeChanged.bind(this)} ClosePopUp={this.ClosePopUp.bind(this)} /> : null
                }
            </Box >
        )
    }
}

export default withTranslation()(LabReceiptBills)


